import { ShopService } from 'src/ShopService/shop.service';
import { userService } from './../../ShopService/user.service';
import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss'],
})
export class UserHistoryComponent implements OnInit {

  historyMeet: any = [];


  @Input() user;
  @Input() barber: string;
  @Input() local = false;

  constructor(private userS: userService, private shopS: ShopService, private modalController: ModalController) { }

  ngOnInit() {
    // this.shopS.getUserMeetings(this.userS.user.key).then(res => {
    //   this.historyMeet = [];
    //   let meetings_businesses = this.shopS.json2array(res.val());
    //   let now = new Date().getTime();
    //   meetings_businesses.forEach(business => {
    //     let meetings = this.shopS.json2array(business);
    //     console.log(meetings);

    //     let filterMeetings = meetings.filter(meet => meet.Date < now);
    //     this.historyMeet.concat(filterMeetings);
    //   });
    //   console.log(this.historyMeet);

    // if (meetings.length > 0) {
    //   console.log(meetings);
    //   this.historyMeet = meetings.filter(meet => meet.Date < now);
    //   console.log(this.historyMeet);
    // }
    // });
  }


  closeModal() {
    this.modalController.dismiss()
  }


  ionViewDidEnter() {
    console.log("history load ", this.user, this.barber)
    if (this.user && this.barber) {
      if (this.local) {
        this.loadLocalUserHistory();
      } else {
        this.loadHistoryByUserAndBarber(this.user, this.barber);
      }
    } else {
      this.loadHistory();
    }
  }

  get_year_from_key(key) {
    // console.log(key)
    let s = key.split('-');
    return s[2];
  }
  get_month_from_key(key) {
    //console.log(key)
    let s = key.split('-');
    return this.monthsList[parseInt(s[1]) - 1];
  }
  monthsList = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];

  loadLocalUserHistory() {
    this.shopS.loadLocalUserHistory(this.shopS.shopData.Setting.BarberID, this.user).then(res => {
      console.log(res.val());
      var beforeSort = this.shopS.json2array(res.val());
      beforeSort.sort(this.compare);
      this.createMonth(beforeSort.reverse());
    });
  }

  loadHistoryByUserAndBarber(user, barber) {

    this.shopS.loadUserHistoryByBarber(user.key, barber).then(res => {

      console.log("all my history ", res.val());
      console.log('snap.val()', res.val());

      var beforeSort = this.shopS.json2array(res.val());

      beforeSort.sort(this.compare);


      // this.historyMeet=beforeSort.reverse();
      this.createMonth(beforeSort.reverse());

    })
  }

  loadHistory() {
    console.log("user s", this.userS.user.key);
    var loadUserHistory = this.shopS.loadUserHistory(this.userS.user.key).subscribe(res => {
      console.log("all my history ", res);
      if (res) {
        let beforeSort = this.shopS.json2array(res);
        beforeSort.sort(this.compare);
        this.createMonth(beforeSort.reverse())
      } else {
        this.historyMeet = [];
      }
      // this.historyMeet=beforeSort.reverse();
      console.log("this.historyMeet ", this.historyMeet);
      loadUserHistory.unsubscribe();
    })
  }


  createMonth(dataMeets) {
    var objec = {
      "dates": "",
      "meets": []
    }

    console.log("------->dataMeets", dataMeets);

    dataMeets.forEach((element, index) => {
      if (objec.dates == this.get_month_from_key(element.meetDate) + " ," + this.get_year_from_key(element.meetDate)) {
        objec.meets.push(element);
      } else {
        if (objec.dates) {
          this.historyMeet.push(objec);
        }
        objec = {
          "dates": this.get_month_from_key(element.meetDate) + " ," + this.get_year_from_key(element.meetDate),
          "meets": [element]
        }
        // objec.meets.push(element);
      }

      if (index == dataMeets.length - 1) {
        this.historyMeet.push(objec);
      }
    });

    console.log("historyMeet", this.historyMeet)

  }


  compare(a, b) {
    // Use toUpperCase() to ignore character casing

    // var d1=this.convertDate(a.startDate);
    // var d2=this.convertDate(b.startDate);

    const bandA = new Date(a.Date);
    const bandB = new Date(b.Date);

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  convertDate(inputFormat) {

    var today: any = new Date(inputFormat);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '-' + mm + '-' + yyyy;

  }

}
