import { AlertExample } from 'src/providers/alert.service';
import { Component, OnInit } from '@angular/core';
import { ShopService } from 'src/ShopService/shop.service';
import { userService } from 'src/ShopService/user.service';
import { Platform, NavController, MenuController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PremiumService } from '../premium/premium-service/premium.service';
import { ModalController } from '@ionic/angular';
import { ChangeUserModal } from 'src/modals/changeUser/changeUser.modal';
import { AboutModalComponent } from '../about-modal/about-modal.component';
import { AuthService } from 'src/providers/auth-service';
import { SocialSharingPage } from 'src/components/social-sharing/social-sharing.page';
import { LoadingService } from 'src/providers/loading.service';
import { InvoiceServiceService } from '../invoice/invoice-service/invoice-service.service';
import { WalletServiceService } from '../wallet/wallet-service/wallet-service.service';
import { LeadModalComponent } from '../lead-modal/lead-modal.component';
import { ChoosePackageComponent } from '../choose-package/choose-package.component';
import { WalletPopoverComponent } from '../wallet-popover/wallet-popover.component';
import { ExclusivePopoverComponent } from '../exclusive-popover/exclusive-popover.component';

@Component({
  selector: 'app-dmenu',
  templateUrl: './dmenu.component.html',
  styleUrls: ['./dmenu.component.scss'],
})
export class DmenuComponent implements OnInit {
  logOutStatus = false;
  shopSub;
  userData;
  ChoosePackageComponent = ChoosePackageComponent;
  LeadModalComponent = LeadModalComponent;
  is_webc_account = false;
  google_calendar = false;

  constructor(
    public walletS: WalletServiceService,
    public invoiceS: InvoiceServiceService,
    public shopS: ShopService,
    public us: userService,
    private MenuController: MenuController,
    private router: Router,
    private alertController: AlertController,
    private modalController: ModalController,
    public authState: AuthService,
    private premiumService: PremiumService,
    private loadingService: LoadingService

  ) { }

  ngOnInit() {
    console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
    setTimeout(() => {
      if (this.shopS.shopData.Setting.BarberID == '056538ed-563b-46d1-b001-a90e69b50f4b') {
        this.is_webc_account = true;
      }
      if (this.shopS.configDATA && this.shopS.configDATA.google_calendar_users) {
        let google_calendar_users_included = this.shopS.configDATA.google_calendar_users.includes(this.shopS.shopData.Setting.BarberID)
        console.log('google_calendar_users_included', google_calendar_users_included);
        if (google_calendar_users_included) {
          this.google_calendar = true;
        }
      }
      console.log(this.shopS.configDATA);

    }, 5000)

  }

  route_to(url) {
    this.shopS.menuTogglle('close');
    this.shopS.route_to(url);
  }

  async presentModalAboutUs(): Promise<HTMLIonModalElement> {
    this.MenuController.close();
    this.shopS.menuTogglle('close');

    const modal = await this.modalController.create({
      component: AboutModalComponent,
      swipeToClose: true,
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",

      // Get the top-most ion-modal

    });


    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data) {
      if (data.dismissed == "finish") {

      }
    }
    return modal;

  }








  toggleModalSetting(event) {
    event.stopPropagation();
    this.router.navigate(['tabsAdmin/business'], { replaceUrl: true });



    // this.shopS.calendarSettingFLAG = !this.shopS.calendarSettingFLAG;
    // console.log('toggleModal=======this.shopS.calendarSettingFLAG',this.shopS.calendarSettingFLAG);
    this.MenuController.close(); this.shopS.menuTogglle('close')
    setTimeout(() => {
      this.shopS.AdminEditMode = false;

    }, 500);

  }

  async toggleModalShare_new(event) {
    this.MenuController.close(); this.shopS.menuTogglle('close')

    const modal = await this.modalController.create({
      component: SocialSharingPage,
      swipeToClose: true,
      cssClass: 'popupModal',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",

      // Get the top-most ion-modal

    });


    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data) {
      if (data.dismissed == "finish") {

      }
    }
    return modal;

  }

  gg() {
    console.log(this.shopS.currentUrl);

  }


  toggleModalShare(event) {
    event.stopPropagation();
    this.shopS.SharemodalFLAG = !this.shopS.SharemodalFLAG;
    // console.log('toggleModal=======this.shopS.SharemodalFLAG',this.shopS.SharemodalFLAG);
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }

  // toggleExplainer(event) {
  //   event.stopPropagation();
  //   this.shopS.explainerFLAF = !this.shopS.explainerFLAF;
  //   // console.log('toggleModal=======this.shopS.SharemodalFLAG',this.shopS.SharemodalFLAG);
  //   this.MenuController.close(); this.shopS.menuTogglle('close')
  // }



  toggleModalUserShareToBiz(event) {
    event.stopPropagation();
    this.shopS.UserShareToBizFLAG = !this.shopS.UserShareToBizFLAG;
    // console.log('toggleModal=======this.shopS.UserShareToBizFLAG',this.shopS.UserShareToBizFLAG);
    this.MenuController.close();
    this.shopS.menuTogglle('close');
    console.log(this.shopS.UserShareToBizFLAG);

  }


  toggleModalContact(event) {
    event.stopPropagation();
    this.shopS.contactUS = !this.shopS.contactUS;
    console.log('toggleModal=======this.shopS.contactUS', this.shopS.contactUS);
    this.MenuController.close();
    this.shopS.menuTogglle('close')
  }





  ggg() {
    var gtafusmeet = this.shopS.getDateTafus('18-04-2022').valueChanges() // adj
      .subscribe(data2 => {
        console.log(data2);

        var gtafusevent = this.shopS.getEventTafus('18-04-2022').valueChanges() //adj
          .subscribe(data3 => {
            console.log(data3);
            let total: any = data2.concat(data3);
            let meets_arr = [];

            // GET FREE & TAKEN WINDOWS
            let takenWindows = [];
            let start_of_day = new Date().setHours(0, 0, 0, 0); //adj
            let end_of_day = new Date().setHours(23, 59, 59, 999); //adj
            total.forEach(meet => {
              let obj = {
                start: new Date(meet.startDate).getTime(),
                end: new Date(meet.endTime).getTime()
              }
              meets_arr.push(obj);
            });

            meets_arr.sort((a, b) => a.start - b.start);
            let freeWindows = [];
            let last_taken_window: any = {
              start: start_of_day,
              end: start_of_day
            };
            let last_free_window: any = {
              start: start_of_day,
              end: start_of_day
            };
            meets_arr.forEach(meet => {
              if (last_taken_window.end < meet.start) {
                console.log(meet);
                let window: any = {};
                window.start = meet.start;
                window.end = meet.end;
                for (let i = 0; i < meets_arr.length; i++) {
                  console.log(i);
                  if (meets_arr[i].start < window.end && meets_arr[i].end > window.end) {
                    window.end = meets_arr[i].end;
                  }
                }
                console.log(window);
                last_taken_window = window;
                takenWindows.push(window);
              }
            });

            console.log(meets_arr);
            console.log(takenWindows);
            takenWindows = takenWindows.sort((a, b) => a.start - b.start);
            for (let i = 0; i <= takenWindows.length; i++) {
              if (i == 0) {
                last_free_window = {
                  start: start_of_day,
                  end: takenWindows[i].start
                }
              } else if (i == takenWindows.length) {
                last_free_window = {
                  start: takenWindows[i - 1].end,
                  end: end_of_day
                }
              } else {
                last_free_window = {
                  start: takenWindows[i - 1].end,
                  end: takenWindows[i].start
                }
              }
              freeWindows.push(last_free_window);
            }
            console.log(freeWindows);

            // GET FREE HOURS
            let freeHours = [];
            let meetTime = 20 * 60000; //adj
            let space = 15 * 60000; //adj
            let deletespace = false; //adj
            let activeHours = { //adj
              start: '6:30',
              end: '18:30'
            }

            let activeHoursMS = { //adj
              start: new Date().setHours(parseInt(activeHours.start.split(':')[0]), parseInt(activeHours.start.split(':')[1]), 0, 0),
              end: new Date().setHours(parseInt(activeHours.end.split(':')[0]), parseInt(activeHours.end.split(':')[1]), 0, 0)
            }
            console.log(activeHoursMS);

            freeWindows.forEach(window => {
              let window_duration = window.end - window.start;
              let loops = Math.floor(window_duration / space);
              let last_hour = window.start;
              if (!deletespace) {
                let minutes = new Date(last_hour).getMinutes();
                if (minutes % 15) {
                  if (minutes < 15) {
                    last_hour = new Date(last_hour).setMinutes(15);
                  } else {
                    last_hour = new Date(last_hour).setMinutes(Math.ceil(minutes / 15) * 15);
                  }
                }
              }

              for (let i = 0; i <= loops; i++) {
                let freeHourCheck = last_hour + meetTime;
                if (freeHourCheck <= window.end) {
                  // freeHours.push({ a: last_hour, b: new Date(last_hour).getHours() + ':' + new Date(last_hour).getMinutes() });
                  freeHours.push({ a: last_hour });
                  last_hour = last_hour + space;
                } else {
                  break;
                }
              }
            });
            freeHours = freeHours.filter(hours => hours.a >= activeHoursMS.start && hours.a <= (activeHoursMS.end - meetTime) && hours.a > new Date().getTime());
            console.log(freeHours);
            gtafusevent.unsubscribe();
          });
        gtafusmeet.unsubscribe();
      });
  }



  goToCalendar() {
    // this.shopS.openBusinessMeetingsSub();
    this.router.navigate(['tabsAdmin/calendar'], { replaceUrl: true });
    console.log(this.shopS.currentUrl)
    this.MenuController.close(); this.shopS.menuTogglle('close');
    console.log(this.shopS.shopData);
  }


  goToClients() {
    this.router.navigate(['tabsAdmin/clients'], { replaceUrl: true });
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }
  async aleryByTextConfirm(str, title) {
    const alert = await this.alertController.create({
      header: title,
      subHeader: '',
      message: str,
      buttons: [
        {
          text: 'ביטול',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return false;
          }
        }, {
          text: 'כן אני רוצה!',
          handler: () => {
            this.loadingService.present_for(10000);
            this.shopS.changeButtonState(-1);
            this.authState.changeToAdmin();

          }
        }
      ]
    });

    await alert.present();

  }

  changeToAdmin() {
    this.shopS.menuTogglle_bol = false;
    // if (!this.shopS.us.user.shopOwnerData) {
    if (!this.shopS.us.user.shopOwnerKey) {
      this.aleryByTextConfirm("אם אתם בעלי עסק הצטרפו לעסקים של Point! שנתחיל בתהליך פתיחת העסק שלכם?", "פתיחת עסק חדש");
    } else {
      this.authState.changeToAdmin();
    }

  }

  changeToUser() {
    this.shopS.menuTogglle_bol = false;
    this.authState.changeToUser();
  }

  goToServices() {
    this.router.navigate(['tabsAdmin/about'], { replaceUrl: true });
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }




  goToGoogleCalendar() {
    this.MenuController.close(); this.shopS.menuTogglle('close')

    if (this.shopS.shopData.Setting.Premium.exclusive) {
      this.router.navigate(['google-calendar'], { replaceUrl: true });
    } else {
      this.shopS.openModal(ExclusivePopoverComponent, 'modalLookfullWidth', false, null);
    }
  }

  goToPayments() {
    this.router.navigate(['payments'], { replaceUrl: true });
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }

  goToStatistics() {
    this.MenuController.close(); this.shopS.menuTogglle('close')

    if (this.shopS.shopData.Setting.Premium.Statistics == "premium") {
      this.router.navigate(['statistics'], { replaceUrl: true });
    } else {
      this.premiumService.PremiumLock("go");
    }
  }

  async goToWallet() {
    console.log(this.walletS);

    if (this.walletS.check_user_wallet()) {
      this.router.navigate(['wallet'], { replaceUrl: true });
    } else {
      // this.walletS.setup_wallet();
      this.shopS.openModal(WalletPopoverComponent, 'modalLookfullWidth', false, null);
    }
    this.MenuController.close(); this.shopS.menuTogglle('close')

  }

  // notif() {
  // let sms_data = {
  //   message_name: "message_name",
  //   to_phone: "0524854129",
  //   message: "saldkjsalkdjals"
  // }
  // this.shopS.send_sms(sms_data);
  // let notif_data = {
  //   type: 'new_meet',
  //   users: this.shopS.shopData.Users[this.meetForBuis.uId],
  //   business: {
  //     logo: this.shopS.shopData.Setting.logo,
  //     name: this.shopS.shopData.Setting.name,
  //   },
  //   meet: {
  //     meetType: this.meetForBuis.meetType,
  //     startTimeView: this.meetForBuis.startTimeView,
  //     meetDate: this.meetForBuis.meetDate
  //   }
  // }
  // this.shopS.send_notif(notif_data);
  // }


  goToInvoice() {
    if (this.invoiceS.check_user_invoice()) {
      this.router.navigate(['invoice'], { replaceUrl: true });
    }
    this.MenuController.close(); this.shopS.menuTogglle('close')

  }

  logNotif() {
    // console.log(this.shopS.notificationsArray);
    // console.log(this.shopS.shopData);
    console.log(this.shopS.currentMeet);
    console.log(this.shopS.shopData.Users);



  }

  goToBuis() {
    this.router.navigate(['Business'], { replaceUrl: true });
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }
  goToBuisA() {

    this.router.navigate(['tabsAdmin/business'], { replaceUrl: true });
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }

  homees() {
    this.router.navigate(['tabs'], { replaceUrl: true });
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }
  // openChangeUserModal() {

  //   this.MenuController.close();      this.shopS.menuTogglle('close')
  // }
  // changeBuiss() {

  //   // this.nav.navigateRoot('addBusiness');
  //   this.us.superNew = true;
  //   this.router.navigate(['addBusiness'], { replaceUrl: true });
  //   this.shopS.changeButtonState(-1);
  //   this.MenuController.close(); this.shopS.menuTogglle('close')
  //   setTimeout(() => {
  //     this.shopS.changeButtonState(-1);
  //   }, 1000);
  // }

  async openChangeUserModal() {
    const modal = await this.modalController.create({
      component: ChangeUserModal,
      swipeToClose: true,
      backdropDismiss: true,
      mode: 'ios',
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',
    });
    this.MenuController.close(); this.shopS.menuTogglle('close')
    return await modal.present();
  }

  problems = {};
  // gggg(aa) {
  //   aa.forEach(ff => {
  //     console.log(ff);

  //   })
  // }
  // bbb = [];
  async ttt() {
    console.log('ttt');

    console.log('this.shopS.shopData - ', this.shopS.shopData);
    console.log('this.shopS.businessArray - ', this.shopS.businessArray);
    console.log('this.us.user.bizArray - ', this.us.user.bizArray);
    console.log('this.router.url - ', this.router.url);
    console.log('this.shopS.external_login - ', this.shopS.external_login);
    console.log('this.shopS.current_biz - ', this.shopS.current_biz);
    console.log('this.shopS.currentMeet - ', this.shopS.currentMeet);
    console.log('this.shopS.configDATA - ', this.shopS.configDATA);
    console.log(this.shopS.open_event_subscription);
    console.log(this.shopS.open_meet_subscription);
    this.shopS.testtest();
  }

  // ttt2() {
  //   console.log(this.bbb);
  //   console.log(this.bbb.length);
    
  //   this.bbb.forEach((buis, i) => {
  //     if (i <= 200) {
  //       this.bbb = this.bbb.slice(1);
  //       this.shopS.gg_basic(buis);
  //     }

  //   });
  // }
  // async toggleModal_packages() {
  //   const modal = await this.modalController.create({
  //     component: ChoosePackageComponent,
  //     swipeToClose: true,
  //     cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',
  //     mode: "ios",

  //   });

  //   await modal.present();
  //   return modal;
  // }



  // async toggleModalLead() {
  //   const modal = await this.modalController.create({
  //     component: LeadModalComponent,
  //     swipeToClose: true,
  //     cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',
  //     mode: "ios",

  //   });

  //   await modal.present();
  //   return modal;
  // }

  sss() {
    this.shopS.AdminFLAG = !this.shopS.AdminFLAG;
    if (this.shopS.AdminFLAG) {
      this.router.navigate(['tabsAdmin/clients'], { replaceUrl: true })
    } else {
      this.router.navigate(['tabs/home'], { replaceUrl: true })
    }
  }

  // compare(a, b) {
  //   const bandA = a.toLowerCase();
  //   const bandB = b.toLowerCase();

  //   let comparison = 0;
  //   if (bandA > bandA) {
  //     comparison = 1;
  //   } else if (bandA < bandA) {
  //     comparison = -1;
  //   }
  //   return comparison;
  // }



  // goBackAddBussnies(toNav) {
  //   //console.log('toNav',toNav);

  //   if (toNav == "login") {
  //     if (this.us.superNew == false) {
  //       this.authState.logout();

  //     } else {
  //       this.us.superNew = false;
  //     }
  //   } else {
  //     if (toNav == "tabs") {
  //       this.router.navigate(['tabs/home'], { replaceUrl: true });

  //     } else {
  //       this.router.navigate(['/' + toNav + ''], { replaceUrl: true });

  //     }

  //   }

  // }



  logout() {
    this.logOutStatus = true;


    this.shopS.changeButtonState(-1);
    console.log('logout');
    this.authState.logout();


    // this.shopS.shopData="";
    // this.shopS.shopData={};


    this.MenuController.close(); this.shopS.menuTogglle('close')



    // if (this.authState.BARBERliveDataFlow) {
    //   this.authState.BARBERliveDataFlow.unsubscribe();
    // }
    this.shopS.shopData.Setting.BarberID = "";
    if (this.userData) {
      this.userData.unsubscribe();

    }
    if (this.shopSub) {
      this.shopSub.unsubscribe();

    }
    localStorage.clear();


  }

  facebook() {
    // this.inAppBrowser.create(this.shopS.shopData.Setting.facebook_link,'_system');
    // this.shopS.openAppUrl('facebook', this.shopS.shopData.Setting.facebook_link, this.shopS.shopData.Setting.facebook_link);
    this.shopS.openAppUrl('facebook', 'PointAppointment', 'PointAppointment');
    // window.open(this.shopS.shopData.Setting.facebook_link, "_blank",'location=yes');
  }

  instagram() {
    // this.shopS.openAppUrl('instagram', this.shopS.shopData.Setting.instagram_link)
    this.shopS.openAppUrl('instagram', 'point_appointment');

    // this.inAppBrowser.create(this.shopS.shopData.Setting.facebook_link,'_system');

    // window.open(this.shopS.shopData.Setting.instagram_link, "_blank");
  }




}
