import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { userService } from '../../ShopService/user.service';
import { ShopService } from '../../ShopService/shop.service';
import { AlertController, ModalController, Platform, MenuController } from '@ionic/angular';
import { LoadingService } from '../../providers/loading.service';
import { AuthService } from 'src/providers/auth-service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { PrivacypolicyComponent } from 'src/app/privacypolicy/privacypolicy.component';
import { Router } from '@angular/router';
// import { AboutModalComponent } from 'src/app/about-modal/about-modal.component';


@Component({
  selector: 'changeUser',
  templateUrl: './changeUser.modal.html',
  styleUrls: ['./changeUser.modal.scss']
})


export class ChangeUserModal implements OnInit {

  public updateUserForm: FormGroup;
  public userData: any;
  currentImg: any;
  mySetting: any;
  ProfilePic: any;
  profFlag = false;
  menuOpen = false;
  public message: string;
  constructor(private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    public userSer: userService,
    private LoadingService: LoadingService,
    public shopS: ShopService,
    public authState: AuthService,
    private platform: Platform,
    private inappbrowser: InAppBrowser,
    private alertController: AlertController,
    private MenuController: MenuController,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.getCopyUserData();
    this.generateUpdateForm();
    // console.log('change User Modal Page');
  }

  // openContactModal() {
  //   this.shopS.openModal(AboutModalComponent, null, true, null);
  // }

  generateUpdateForm() {
    console.log('generateUpdateForm');

    this.updateUserForm = this.formBuilder.group({
      fname: [`${this.userData.fname}`, Validators.compose([Validators.maxLength(20), Validators.required
      ])],
      lname: [`${this.userData.lname}`, Validators.compose([Validators.maxLength(20), Validators.required
      ])],
      email: [`${this.userData.email}`, Validators.compose([Validators.maxLength(50), Validators.required
      ])],
      phone: [`${this.userData.phone}`]
    });
    console.log(this.updateUserForm);

  }

  openMenu(ev) {
    ev.stopPropagation();
    this.menuOpen = !this.menuOpen;
    console.log(this.menuOpen);

  }

  closeMenu() {
    this.menuOpen = false;
  }

  // menuClick(ev) {
  //   ev.stopPropagation();
  // }

  async presentPrivacypolicy(): Promise<HTMLIonModalElement> {
    if (this.platform.is("ios")) {
      var modal = await this.modalCtrl.create({
        component: PrivacypolicyComponent,
        swipeToClose: false,
        cssClass: '',
        // presentingElement: this.routerOutlet.nativeEl,
        mode: "ios",
        //   presentingElement: await this.modalCtrl.getTop() // Get the top-most ion-modal
        // Get the top-most ion-modal
      });
    } else {
      var modal = await this.modalCtrl.create({
        component: PrivacypolicyComponent,
        swipeToClose: false,
        mode: 'ios',
        cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',
        //   presentingElement: await this.modalCtrl.getTop() // Get the top-most ion-modal
        // Get the top-most ion-modal
      });
    }
    // });
    await modal.present();
    return modal;
  }


  deleteuser() {
    // this.inappbrowser.create("https://point-app.net/data-deletion/", "_blank", "toolbar: 'yes'");

    // console.log('subs alert',this.shopS.shopData.Setting );
    if (this.shopS.shopData.Setting.BarberID) {
      if (this.shopS.shopData.Setting.Premium.status != 'Basic') {
        // console.log('subscription_alert',this.shopS.shopData.Setting.Premium.status );
        this.subscription_alert();
      } else {
        if (!this.shopS.shopData.Setting.name.includes('סגור')) {
          this.business_alert();
        } else {
          this.user_alert();
        }
      }
    } else {
      this.user_alert();
    }
  }

  subscription_alert() {
    var textT = '<span class="aaaa" style="">';
    var text = 'לפני מחיקת החשבון יש לבטל את המנוי. יש להיכנס לתשלומים ולבטל את המנוי';
    // console.log('textT', textT, text);
    textT += text + '</span>';
    this.alertModal(textT, 'בטל את המנוי שלך', 'subscription', 'כן, בטל לי את המנוי', 'לא, התחרטתי');
  }

  business_alert() {
    var textT = '<span class="aaaa" style="">';
    var text = ' האם ברצונך להפוך את העסק ללא פעיל?';
    // console.log('textT', textT, text);
    textT += text + '</span>';
    this.alertModal(textT, 'סגירת העסק', 'business', 'כן, סגור את העסק ', 'לא, אל תסגור');
  }

  user_alert() {
    var textT = '<span class="aaaa" style="">';
    var text = ' האם ברצונך למחוק את המשתמש מהמערכת?';
    // console.log('textT', textT, text);
    textT += text + '</span>';
    this.alertModal(textT, 'מחיקת משתמש', 'user', 'כן, מחק את המשתמש שלי ', 'לא, אל תמחק');

  }

  deleteUserauth() {//delete the user from authentication, adding to user name לקוח נמחק
    // console.log('deleteUser', this.UserIdDelete);
    let data = {
      type: 'delete user',
      UserId: this.shopS.us.user.key
    };

    this.shopS.ManageUsers(data, cb => {
      this.authState.logout();
    });
    this.modalCtrl.dismiss();

  }

  closeBuis() {//closing buis hours, total score=-100, canceling search by users, adding סגור to buis name
    let data = {
      type: 'close buis',
      BarberID: this.shopS.shopData.Setting.BarberID
    };

    this.shopS.ManageUsers(data, cb => {
      this.user_alert();
    });
  }

  goToPayments() {
    this.router.navigate(['payments'], { replaceUrl: true });
    this.MenuController.close();
    this.shopS.menuTogglle('close');
    this.modalCtrl.dismiss();

  }

  async alertModal(textT, header, action, btnTxt, cnlBtn) {

    const alert = await this.alertController.create({
      header: header,
      message: textT,
      buttons: [
        {
          text: btnTxt,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            switch (action) {

              case 'subscription':
                this.goToPayments();
                break;

              case 'business':
                this.closeBuis();
                break;

              case 'user':
                this.deleteUserauth();
                break;

              default:
                // return false;
                break;
            }

          }
        },
        {
          text: cnlBtn,
          handler: () => {

            console.log('Okay: Okay');
            //confirm order 

          }
        }
      ]
    });
    await alert.present();

  }


  preview(event, where) {
    // this.typeSetting = where;
    this.currentImg = event;
    console.log('event:', event);
    var filess = event.srcElement.files;
    console.log('filess:', filess);

    if (filess.length === 0)
      return;

    var mimeType = filess[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(filess[0]);
    reader.onload = (_event) => {
      //console.log('reader.result',reader.result);
      console.log('this.mySetting', this.mySetting);
      this.ProfilePic = reader.result;
      this.profFlag = true;
      this.updateProfileNOclose();
      this.validateButtonFunc()
      // this.shopS.UploadFlags[where] = true;
    }
  }


  validatephone() {
    this.shopS.validStatus = "edit";
    // this.shopS.isVerifyEdit(); 
    this.shopS.modalS.openValidNumber('userUpdate');
  }
  getCopyUserData() {

    this.userData = this.userSer.copyUserObject();
    this.ProfilePic = this.userData.ProfilePic;
    if (!this.userData.fname) {
      this.userData.fname = "";
    }
    if (!this.userData.lname) {
      this.userData.lname = "";
    }
    if (!this.userData.email) {
      this.userData.email = "";
    }
    // if(!this.userData.phone){
    //   this.userData.phone="";
    // }

    // console.log("this.userData",this.userData);
  }

  validateButtonFunc() {
    return false;
    return JSON.stringify(this.userData) === JSON.stringify(this.updateUserForm.value);
  }



  updateProfileNOclose() {
    this.LoadingService.present();

    this.userSer.updateUserData(this.updateUserForm.value).then((result) => {


      this.userSer.user.fname = this.updateUserForm.value.fname
      this.userSer.user.lname = this.updateUserForm.value.lname
      // this.userSer.user.phone=this.updateUserForm.value.phone
      this.userSer.user.email = this.updateUserForm.value.email;
      this.userSer.user.profilePic = this.updateUserForm.value.profilePic;
    });

    this.shopS.uploadImg(this.currentImg, '/Users/' + this.userSer.key + '/', 'ProfilePic').then(data => {
      // console.log("this.UPLOADIMAGE DATA,",data)
      //  let filePath:any = data.task;
      //  console.log("filePath ******  ,",filePath.uploadUrl_)
      //   console.log("this.updateUserForm.value,",this.updateUserForm.value)

      setTimeout(() => {
        this.LoadingService.dismiss();

      }, 500);





    });
  }
  updateProfile() {
    this.LoadingService.presentforever();

    if (this.profFlag == true) {
      this.LoadingService.present();

      this.userSer.updateUserData(this.updateUserForm.value).then((result) => {


        this.userSer.user.fname = this.updateUserForm.value.fname
        this.userSer.user.lname = this.updateUserForm.value.lname
        // this.userSer.user.phone=this.updateUserForm.value.phone
        this.userSer.user.email = this.updateUserForm.value.email;
        this.userSer.user.profilePic = this.updateUserForm.value.profilePic;
      });

      this.shopS.uploadImg(this.currentImg, '/Users/' + this.userSer.key + '/', 'ProfilePic').then(data => {
        // console.log("this.UPLOADIMAGE DATA,",data)
        //  let filePath:any = data.task;
        //  console.log("filePath ******  ,",filePath.uploadUrl_)
        //   console.log("this.updateUserForm.value,",this.updateUserForm.value)

        setTimeout(() => {
          this.LoadingService.dismiss();
          this.modalCtrl.dismiss().then(x => {
            this.userSer.emptyUser = false;
            console.log("close close close");
          })
        }, 500);





      });
      //   this.updateUserForm.value.ProfilePic=this.ProfilePic;

    } else {
      // not image wait needed

      // console.log("this.updateUserForm.value,",this.updateUserForm.value)
      this.userSer.updateUserData(this.updateUserForm.value).then((result) => {

        //  this.userSer.user=this.updateUserForm.value;

        this.userSer.user.fname = this.updateUserForm.value.fname
        this.userSer.user.lname = this.updateUserForm.value.lname
        // this.userSer.user.phone=this.updateUserForm.value.phone
        this.userSer.user.email = this.updateUserForm.value.email
        //  console.log("results result",result)
        this.modalCtrl.dismiss().then(x => {
          this.userSer.emptyUser = false;
          console.log("close close close");
          this.LoadingService.dismiss();
        })


      });

    }




  }

  validateButtonFuncEmpty() {

    //  console.log(this.updateUserForm);


    if (this.updateUserForm.status == "VALID") {
      return false;
    } else {
      return true;
    }


  }
  goBack() {
    this.modalCtrl.dismiss();

  }



  focusFLAG = false;
  focusFLAGassist = false;
  focus(type) {
    console.log('focus type', type);
    if (type == 'in') {
      this.focusFLAGassist = true;
      this.focusFLAG = true;

    } else {
      this.focusFLAGassist = false;
      console.log('this.focusFLAGassist', this.focusFLAGassist);

      setTimeout(() => {
        console.log('this.focusFLAGassist', this.focusFLAGassist);

        if (!this.focusFLAGassist) {
          this.focusFLAG = false;
        }
      }, 500);


    }
  }




  shopSub;



  logout() {
    console.log('logout');
    this.shopS.shopData.Setting.BarberID = "";
    this.authState.logout();
  }



}
