import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

  saveToLocalStorage(key: string, object: {}) {
    const finalObject = JSON.stringify(object);
    localStorage.setItem(`${key}`, finalObject);
  }

  cleanLocalStorage() {
    let not_delete = [
      { name: 'calendarTimeInterval', value: localStorage.getItem('calendarTimeInterval') },
      { name: 'calendarMode', value: localStorage.getItem('calendarMode') },
      { name: 'point_geo_location_premission', value: localStorage.getItem('point_geo_location_premission') },
      { name: 'app_env', value: localStorage.getItem('app_env') },
      { name: 'welcome_screen', value: localStorage.getItem('welcome_screen') },
      { name: 'type', value: localStorage.getItem('type') }
    ];

    localStorage.clear();

    not_delete.forEach(item => {
      if (item.value) {
        localStorage.setItem(item.name, item.value);
      }
    });
  }
}

