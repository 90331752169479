import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortlistComponent } from './sortlist.component';
import { SortlistService } from './sortlist-service/sortlist.service';
import { NgpSortModule } from 'ngp-sort-pipe';
import { IonicModule } from '@ionic/angular';





@NgModule({
  declarations: [SortlistComponent],
  providers:[SortlistService],
  imports: [
    CommonModule,
    NgpSortModule,
    IonicModule
  ],
  exports:[NgpSortModule],
  entryComponents:[SortlistComponent]
})
export class SortlistModule { }
