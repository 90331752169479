import { Component, OnInit } from '@angular/core';
import { ShopService } from 'src/ShopService/shop.service';
import { WalletServiceService } from '../wallet-service/wallet-service.service';

@Component({
  selector: 'app-wallet-document-send-popover',
  templateUrl: './wallet-document-send-popover.component.html',
  styleUrls: ['./wallet-document-send-popover.component.scss'],
})
export class WalletDocumentSendPopoverComponent implements OnInit {

  constructor(
    public walletS:WalletServiceService,
    private shopS:ShopService
  ) { }

  ngOnInit() {
    console.log('current_document: ',this.walletS.current_document);
    this.walletS.current_document_popover = true;
  }



doAction(action){

if(action == 'doc_view'){
  console.log('doc_view == '+this.walletS.current_document,this.shopS.shopData.Wallet.documents);
  
 window.open(this.shopS.shopData.Wallet.documents[this.walletS.current_document].url, '_blank');
  
}

if(action == 'upload_new'){
  document.getElementById(this.walletS.current_document).click();

}



}
}
