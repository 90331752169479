import { Injectable } from '@angular/core';
import { AnimationController, IonContent } from '@ionic/angular';






@Injectable({
  providedIn: 'root'
})
export class ModalAnimationService {

  constructor() { }

// Defaults for the card swipe animation
 SwipeToCloseDefaults = {
  MIN_PRESENTING_SCALE: 0.93,
};

/**
 * iOS Modal Enter Animation for the Card presentation style
 */
  iosEnterAnimation_point = (baseEl, presentingEl) => {
  const AnimationC = new AnimationController;

  const backdropAnimation = AnimationC.create()
      .addElement(baseEl.querySelector('ion-backdrop'))
      .fromTo('opacity', 0.01, 'var(--backdrop-opacity)')
      .beforeStyles({
      'pointer-events': 'none',
  })
      .afterClearStyles(['pointer-events']);
  const wrapperAnimation = AnimationC.create()
      .addElement(baseEl.querySelectorAll('.modal-wrapper, .modal-shadow'))
      .beforeStyles({ 'opacity': 1 })
      .fromTo('transform', 'translateY(100vh)', 'translateY(0vh)');
  const baseAnimation = AnimationC.create()
      .addElement(baseEl)
      .easing('cubic-bezier(0.32,0.72,0,1)')
      .duration(500)
      // .fromTo('backdrop-filter', 'blur(20px)', 'blur(0px)')
      // .beforeStyles({
      //   'backdrop-filter': 'blur(20px)'
      // })
      // .beforeAddClass('backdrop-filter-modal')
      .addAnimation(wrapperAnimation);
  if (presentingEl) {
      const isMobile = window.innerWidth < 768;
      const hasCardModal = (presentingEl.tagName === 'ION-MODAL' && presentingEl.presentingElement !== undefined);
      const presentingAnimation = AnimationC.create()
          .beforeStyles({
          'transform': 'translateY(0)',
          'transform-origin': 'top center',
          'overflow': 'hidden'
      });
      const bodyEl = document.body;
      if (isMobile) {
          /**
           * Fallback for browsers that does not support `max()` (ex: Firefox)
           * No need to worry about statusbar padding since engines like Gecko
           * are not used as the engine for standlone Cordova/Capacitor apps
           */
          const transformOffset = (!CSS.supports('width', 'max(0px, 1px)')) ? '30px' : 'max(30px, var(--ion-safe-area-top))';
          const modalTransform = hasCardModal ? '-10px' : transformOffset;
          const toPresentingScale = this.SwipeToCloseDefaults.MIN_PRESENTING_SCALE;
          const finalTransform = `translateY(${modalTransform}) scale(${toPresentingScale})`;
          presentingAnimation
              .afterStyles({
              'transform': finalTransform
          }) .afterStyles({
            'backdrop-filter': 'blur('+modalTransform+')'
        })
              .beforeAddWrite(() => bodyEl.style.setProperty('background-color', 'black'))
              .addElement(presentingEl)
              .keyframes([
              { offset: 0, filter: 'contrast(1)', transform: 'translateY(0px) scale(1)', borderRadius: '0px' },
              { offset: 1, filter: 'contrast(0.85)', transform: finalTransform, borderRadius: '10px 10px 0 0' }
          ]);
          baseAnimation.addAnimation(presentingAnimation);
      }
      else {
          baseAnimation.addAnimation(backdropAnimation);
          if (!hasCardModal) {
              wrapperAnimation.fromTo('opacity', '0', '1');
          }
          else {
              const toPresentingScale = (hasCardModal) ? this.SwipeToCloseDefaults.MIN_PRESENTING_SCALE : 1;
              const finalTransform = `translateY(-10px) scale(${toPresentingScale})`;
              presentingAnimation
                  .afterStyles({
                  'transform': finalTransform
              }).afterStyles({
            'backdrop-filter': 'blur(20px)'
        })
                  .addElement(presentingEl.querySelector('.modal-wrapper'))
                  .keyframes([
                  { offset: 0, filter: 'contrast(1)', transform: 'translateY(0) scale(1)' },
                  { offset: 1, filter: 'contrast(0.85)', transform: finalTransform }
              ]);
              const shadowAnimation = AnimationC.create()
                  .afterStyles({
                  'transform': finalTransform
              })
                  .addElement(presentingEl.querySelector('.modal-shadow'))
                  .keyframes([
                  { offset: 0, opacity: '1', transform: 'translateY(0) scale(1)' },
                  { offset: 1, opacity: '0', transform: finalTransform }
              ]);
              baseAnimation.addAnimation([presentingAnimation, shadowAnimation]);
          }
      }
  }
  else {
      baseAnimation.addAnimation(backdropAnimation);
  }
  return baseAnimation;
};


/**
 * iOS Modal Leave Animation
 */
  iosLeaveAnimation_point = (baseEl, presentingEl, duration = 500) => {
  const AnimationC = new AnimationController;

  const backdropAnimation = AnimationC.create()
      .addElement(baseEl.querySelector('ion-backdrop'))
      .fromTo('opacity', 'var(--backdrop-opacity)', 0.0);

  const wrapperAnimation = AnimationC.create()
      .addElement(baseEl.querySelectorAll('.modal-wrapper, .modal-shadow'))
      .beforeStyles({ 'opacity': 1 })
      .fromTo('transform', 'translateY(0vh)', 'translateY(100vh)');
  const baseAnimation = AnimationC.create()
      .addElement(baseEl)
      .easing('cubic-bezier(0.32,0.72,0,1)')
      .duration(duration)
      .fromTo('backdrop-filter', 'blur(20px)', 'blur(0px)')
      // .afterStyles({
      //   'backdrop-filter': 'blur(0px)'
      // })
      // .afterRemoveClass('backdrop-filter-modal')
      .addAnimation(wrapperAnimation);
  if (presentingEl) {
      const isMobile = window.innerWidth < 768;
      const hasCardModal = (presentingEl.tagName === 'ION-MODAL' && presentingEl.presentingElement !== undefined);
      const presentingAnimation = AnimationC.create()
          .beforeClearStyles(['transform','backdrop-filter'])
          .afterClearStyles(['transform'])
          .onFinish(currentStep => {
          // only reset background color if this is the last card-style modal
          if (currentStep !== 1) {
              return;
          }
          presentingEl.style.setProperty('overflow', '');
          const numModals = Array.from(bodyEl.querySelectorAll('ion-modal')).filter(m => m.presentingElement !== undefined).length;
          if (numModals <= 1) {
              bodyEl.style.setProperty('background-color', '');
          }
      });
      const bodyEl = document.body;
      if (isMobile) {
          const transformOffset = (!CSS.supports('width', 'max(0px, 1px)')) ? '30px' : 'max(30px, var(--ion-safe-area-top))';
          const modalTransform = hasCardModal ? '-10px' : transformOffset;
          const toPresentingScale = this.SwipeToCloseDefaults.MIN_PRESENTING_SCALE;
          const finalTransform = `translateY(${modalTransform}) scale(${toPresentingScale})`;
          presentingAnimation
              .addElement(presentingEl)
              .keyframes([
              { offset: 0, filter: 'contrast(0.85)', transform: finalTransform, borderRadius: '10px 10px 0 0' },
              { offset: 1, filter: 'contrast(1)', transform: 'translateY(0px) scale(1)', borderRadius: '0px' }
          ]);
          baseAnimation.addAnimation(presentingAnimation);
      }
      else {
          baseAnimation.addAnimation(backdropAnimation);
          if (!hasCardModal) {
              wrapperAnimation.fromTo('opacity', '1', '0');
          }
          else {
              const toPresentingScale = (hasCardModal) ? this.SwipeToCloseDefaults.MIN_PRESENTING_SCALE : 1;
              const finalTransform = `translateY(-10px) scale(${toPresentingScale})`;
              presentingAnimation
                  .addElement(presentingEl.querySelector('.modal-wrapper'))
                  .afterStyles({
                  'transform': 'translate3d(0, 0, 0)'
              })
                  .keyframes([
                  { offset: 0, filter: 'contrast(0.85)', transform: finalTransform },
                  { offset: 1, filter: 'contrast(1)', transform: 'translateY(0) scale(1)' }
              ]);
              const shadowAnimation = AnimationC.create()
                  .addElement(presentingEl.querySelector('.modal-shadow'))
                  .afterStyles({
                  'transform': 'translateY(0) scale(1)'
              })
                  .keyframes([
                  { offset: 0, opacity: '0', transform: finalTransform },
                  { offset: 1, opacity: '1', transform: 'translateY(0) scale(1)' }
              ]);
              baseAnimation.addAnimation([presentingAnimation, shadowAnimation]);
          }
      }
  }
  else {
      baseAnimation.addAnimation(backdropAnimation);
  }
  return baseAnimation;
};


}
