import { KnoknowledgeModalComponent } from '../app/components/knoknowledge-modal/knoknowledge-modal.component';
//import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';
import { LoginPhoneComponent } from './login-phone/login-phone.component';
// import { FunctionComponent } from './function/function.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { SwitchBusinessComponent } from './switch-business/switch-business.component';
import { CoronavirusComponent } from './coronavirus/coronavirus.component';
import { SAdminRegComponent } from './s-admin-reg/s-admin-reg.component';
import { BuissnesPageComponent } from './buissnes-page/buissnes-page.component';

import { VersionUpdateComponent } from "./version-update/version-update.component";
import { TutorialComponent } from './tutorial/tutorial.component';
import { AlertExample } from './../providers/alert.service';
import { LoadingService } from 'src/providers/loading.service';
import { ErrorHandlerService } from 'src/providers/error-handler.service';
// import { AppointmentPage } from './../pages/appointment/appointment';
import { NgModule, LOCALE_ID, ErrorHandler, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { MyApp } from './app.component';
import { loginPage } from '../pages/login/login';
// import { LoginadminPage } from '../pages/loginadmin/loginadmin.page';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { registerU } from '../pages/register/registerUser';
// import { choseShop } from '../pages/chooseShop/chooseShop'; 
// import { chooseDate } from '../pages/chooseDate/chooseDate';
// import { MainPage } from '../pages/main/main';
//import { NgCalendarModule } from 'ionic2-calendar';
import { HttpModule, JsonpModule } from '@angular/http';
import { NavController, NavParams } from '@ionic/angular';
// import { RatingModule } from 'ngx-rating';
// import { menuComp } from './menu';
import { CommonModule } from '@angular/common';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/storage';
// import { File } from '@ionic-native/file/ngx';
// import { Appsflyer } from "@ionic-native/appsflyer/ngx";
// import { IonicSelectableModule } from 'ionic-selectable';
// import { NgxChartsModule } from '@swimlane/ngx-charts/release';
// import { GoogleCalendarPageModule } from './google-calendar/google-calendar.module';

//import {ColorPickerComponent} from '../components/color-picker/color-picker.component';
// import { Observable } from 'rxjs-compat';
// import { map } from 'rxjs-compat/operators';


import { AuthGuard } from '../services/auth-guard.service';

// import { OrderButtonComponent } from '../components/order-button/order-button.component';

// SERVICES
import { LocalStorageService } from '../providers/local-storage.service';
import { AuthService } from '../providers/auth-service';
import { ToastService } from '../providers/toast-service';
// import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { userService } from '../ShopService/user.service';
import { meetService } from '../ShopService/meetS.service';
import { AlertController } from '@ionic/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { ShopService } from '../ShopService/shop.service';
import { NotificationService } from './notification.service';
// import { AppsflyerService } from "./appsflyer.service";

// PAGES
import { SocialLoginPage } from '../pages/socialLogin/socialLogin.page';
// import { SetPasswordPage } from '../pages/setPassword/setPassword.page';
// import { SmsCodePage } from '../pages/smsCode/smsCode.page';
// import { AskSmsCodePage } from '../pages/askSmsCode/askSmsCode.page';
import { AddBusinessPage } from '../pages/addBusiness/addBusiness.page';
// import { ClientsPage } from '../pages/clients/clients.page';
import { TabsPageModule } from '../pages/tabs/tabs.module';
import { TabsAdminPageModule } from '../pages/tabsAdmin/tabsAdmin.module';
// import { BLinkPage } from '../pages/b-link/b-link.page';

import { Contacts } from '@ionic-native/contacts/ngx';


// import { ColorPickerModule } from './color-picker/color-picker.module';
import { AppRoutingModule } from './app-routing.module';
// import { AddLineAdminPage } from '../pages/add-line-admin/add-line-admin.page';
//import { CalendarPage } from '../pages/calendar/calendar.page';
// import { BusinessProfileAdminPage } from '../pages/business-profile-admin/business-profile-admin.page';

import { ChangeUserModal } from '../modals/changeUser/changeUser.modal';
// import { Routes, RouterModule } from '@angular/router';

// Components
//import { NotificationBellComponent } from '../components/notification-bell/notification-bell.component';
import { ContactModalComponent } from '../components/contact-modal/contact-modal.component';
//import { KeysPipe } from './keys.pipe';

// import { Ng2SearchPipeModule } from 'ng2-search-filter';
//import { MeetcalendarPipe } from './meetcalendar.pipe';
//import { PipesModule } from "../pipes/pipes.module";
// import { IonicHeaderParallaxModule } from 'ionic-header-parallax';
// import { AnimateItemsDirective } from './directives/animate-items.directive';
import { ModalNewmeetComponent } from './modal-newmeet/modal-newmeet.component';
// import { PopoverComponent } from './popover/popover.component';

import { CoronaVirusService } from './corona-virus.service';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { DateAgoPipe } from './pipes/date-ago.pipe';
//import { ClientKeyPipe } from './client-key.pipe';
import { environment } from '../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { AboutModalComponent } from "./about-modal/about-modal.component";
import { SplashScreenComponent } from './splash-screen/splash-screen.component';

import { CryptoService } from "./crypto.service";

// import { GooglemapsComponent } from "../app/googlemaps/googlemaps.component";
import { AdminRegisterPage } from 'src/pages/admin-register/admin-register.page';

// import { GoogleMapsModule } from '@angular/google-maps'
import { AskSmsCodePage } from "../pages/askSmsCode/askSmsCode.page";
import { BLinkPage } from "../pages/b-link/b-link.page";
import { SetPasswordPage } from "../pages/setPassword/setPassword.page";
import { SmsCodePage } from "../pages/smsCode/smsCode.page";
//import { PaymentComponent } from "./payment/payment.component";
import { PaymentService } from "../app/payments/payment-service/payment.service";

import { SuperTabsModule } from "@ionic-super-tabs/angular";

import { PaymentsPageModule } from "../app/payments/payments.module";


import { FingerprintAIO } from "@ionic-native/fingerprint-aio/ngx";

//import { File } from '@ionic-native/file/ngx';
// import { FileOpener } from '@ionic-native/file-opener/ngx';

import { AngularFirestoreModule } from '@angular/fire/firestore';

import { PipeModule } from "./pipe/pipe.module";
// import { DmenuComponent } from './dmenu/dmenu.component';
import { SocialSharingPage } from 'src/components/social-sharing/social-sharing.page';

// import { ContactListModule } from "./contact-list/contact-list.module";
import { ImgPreviewComponent } from './img-preview/img-preview.component';
import { SwichmodalComponent } from './swichmodal/swichmodal.component';
import { SomethingwentwrongComponent } from './somethingwentwrong/somethingwentwrong.component';

//import { CodePush } from '@ionic-native/code-push/ngx';
//import { UpdatePushComponent } from './update-push/update-push';
// import { UserShareToBizComponent } from 'src/components/user-share-to-biz/user-share-to-biz.component';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';


// import { NetworkProvider } from '../providers/network/network';
import { Network } from '@ionic-native/network/ngx';
// import { NetworkStatusAngularModule } from 'network-status-angular';

import { ConnectionServiceModule } from 'ng-connection-service';
import { MainModule } from './main/main.module';
// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { InvoiceMigrateService } from './invoice/invoice-migrate/invoice-migrate.service';
// import { InvoiceNewDocumentComponent } from "./invoice/invoice-new-document/invoice-new-document.component";
import { InvoiceViewDocumentComponent } from "./invoice/invoice-view-document/invoice-view-document.component";
// import { InvoicePopupComponent } from "./invoice/invoice-popup/invoice-popup.component";
// import { Ionic4DatepickerModule } from
//     '@logisticinfotech/ionic4-datepicker';
import { BuisProfileComponent } from './buis-profile/buis-profile.component';
import { PhoneLoginComponent } from './phone-login/phone-login.component';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';

import { AngularFireAnalyticsModule, AngularFireAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';

import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { FullscreenAlertComponent } from './fullscreen-alert/fullscreen-alert.component';
import { UserHistoryComponent } from './user-history/user-history.component';
// import { AboutPage } from 'src/pages/about/about';

import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { WelcomeScreenComponent } from './welcome-screen/welcome-screen.component';
import { FirebaseAuthentication } from '@awesome-cordova-plugins/firebase-authentication/ngx';
import { PaymentLinkComponent } from './wallet/payment-link/payment-link.component';
// import { ChoosePackageComponent } from './choose-package/choose-package.component';
// import { ErrorHandlerService } from './error-handler.service';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
export function playerFactory() {
  return player;
}


var DEBUG = false;
// ENABLE/DISABLE Console Logs
if (!DEBUG) {
  window.console.log = function () { }
}
export const env = environment.firebase_dev
environment.currentEnv = env.name;
console.log('****   environment:   ' + environment.currentEnv);
console.log('********************************************:');


@NgModule({
  declarations: [
    MyApp,
    WelcomeScreenComponent,
    // TabsPage,
    // PaymentComponent, 
    //UpdatePushComponent,
    // menuComp,
    // GooglemapsComponent,
    FullscreenAlertComponent,
    ChangeUserModal,
    // DmenuComponent,
    //  ColorPickerComponent,
    // FunctionComponent,
    SAdminRegComponent,
    PrivacypolicyComponent,
    ModalNewmeetComponent,
    TutorialComponent,
    // ChoosePackageComponent,
    LoginPhoneComponent,
    PhoneLoginComponent,
    SwitchBusinessComponent,
    UserHistoryComponent,
    VersionUpdateComponent,
    // OrderButtonComponent,
    SocialSharingPage,
    // PopoverComponent,
    InvoiceViewDocumentComponent,
    // InvoiceNewDocumentComponent,

    // chooseDate,
    // choseShop,
    //  NotificationBellComponent,
    // AppointmentPage,
    // AboutPage,
    // ContactPage,
    // HomePage,
    // MainPage,
    PaymentLinkComponent,
    ContactModalComponent,
    AdminRegisterPage,
    SocialLoginPage,
    AboutModalComponent,
    SplashScreenComponent,
    // UserShareToBizComponent,
    ImgPreviewComponent,
    SomethingwentwrongComponent,
    // SetPasswordPage,
    loginPage,
    // LoginadminPage,
    // SmsCodePage,
    // AskSmsCodePage,
    CoronavirusComponent,
    SwichmodalComponent,
    AddBusinessPage,
    // InvoicePopupComponent,

    // ClientsPage,
    // WalletSetupComponent,
    BuisProfileComponent,
    // SetupInvoiceSupplierComponent,
    // BLinkPage,
    BuissnesPageComponent,
    KnoknowledgeModalComponent,
    AlertExample,
    //AddLineAdminPage,
    //  CalendarPage,
    // BusinessProfileAdminPage,
    registerU,
    //  KeysPipe,
    // AnimateItemsDirective,
    //  DateAgoPipe,
    //  ClientKeyPipe,
    //   MeetcalendarPipe,
    //    MeetnotifiPipe
    AskSmsCodePage,
    BLinkPage,
    SetPasswordPage,
    SmsCodePage
  ],
  imports: [
    BrowserModule,
    // InfiniteScrollModule,
    // RatingModule,
    CommonModule,
    // ContactListModule,
    // GoogleCalendarPageModule,
    // ShareButtonsModule.withConfig({
    //   debug: true
    // }),
    // GooglemapsComponent,
    BrowserAnimationsModule,
    ConnectionServiceModule,
    // IonicSelectableModule,
    AppRoutingModule,
    MainModule,
    LottieModule.forRoot({ player: playerFactory }),

    TabsPageModule,
    SuperTabsModule.forRoot(),

    TabsAdminPageModule,
    // NgxChartsModule,
    HttpModule,
    //PipesModule,
    PaymentsPageModule,
    // IonicHeaderParallaxModule,
    JsonpModule,
    // Ionic4DatepickerModule,

    //  NgCalendarModule,
    // ColorPickerModule,
    IonicModule.forRoot({
      // "eBackEnabled": false,
      "swipeBackEnabled": false,

    }),
    AgmCoreModule.forRoot({
      language: 'iw',
      apiKey: "AIzaSyDJYGsppHU_r_BjvfYFw-lwaQsbPqVV2zw",
      libraries: ["places"],
    }),
    AngularFireModule.initializeApp(env),
    AngularFirestoreModule.enablePersistence(),
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    AngularFireStorageModule,
    AngularFireAuthModule, FormsModule, ReactiveFormsModule,
    PipeModule,
  ],
  exports: [
    CommonModule,
    // GoogleCalendarPageModule,
    PaymentsPageModule,
    //  NotificationBellComponent,
    // ColorPickerModule,
    // MainModule,
    // DmenuComponent,
    VersionUpdateComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [MyApp],
  entryComponents: [
    MyApp,
    SocialSharingPage,
    SwichmodalComponent,
    ContactModalComponent,
    // GooglemapsComponent,
    ImgPreviewComponent,
    // TabsPage,
    KnoknowledgeModalComponent,
    // PopoverComponent,
    BuissnesPageComponent,
    PrivacypolicyComponent,
    SomethingwentwrongComponent,
    // DmenuComponent,
    //  NotificationBellComponent,
    // choseShop,
    CoronavirusComponent,
    ChangeUserModal,
    SAdminRegComponent,
    WelcomeScreenComponent,
    //   PaymentComponent,
    // InvoicePopupComponent,
    FullscreenAlertComponent,
    VersionUpdateComponent,
    registerU,
    ModalNewmeetComponent,
    // AppointmentPage,
    // menuComp,
    SwitchBusinessComponent,
    SocialLoginPage,
    AboutModalComponent,
    SplashScreenComponent,
    // chooseDate,
    UserHistoryComponent,
    // MainPage,
    // ContactPage,
    // HomePage,
    loginPage,
    // SetPasswordPage,
    // AskSmsCodePage,
    AddBusinessPage,
    // SmsCodePage
    // InvoiceNewDocumentComponent,
    InvoiceViewDocumentComponent,

  ],
  providers: [SocialSharing,
    // ErrorHandler,
    // File,
    Network,
    FirebaseAuthentication,
    // ErrorHandlerService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    OneSignal, CallNumber,
    //,FirebaseAuthentication,
    // { provide: LOCALE_ID, useValue: 'iw' },
    userService,
    Facebook,
    GooglePlus,
    InAppBrowser,
    AppAvailability,
    // Appsflyer,
    //CodePush,
    FingerprintAIO,
    // FileOpener,
    AuthGuard,
    AlertExample,
    AuthService,
    // OrderButtonComponent,
    Contacts,
    LocalStorageService,
    CryptoService,
    VersionUpdateComponent,
    ShopService,
    NotificationService,
    meetService,
    ToastService,
    PaymentService,
    CoronaVirusService,
    // AppsflyerService,
    AngularFireDatabase,
    AngularFireStorage,
    InvoiceMigrateService,
    AngularFireAuth,
    SplashScreen,
    AlertController,
    StatusBar,
    LoadingService,
    AngularFireAnalytics,
    ScreenTrackingService,
    UserTrackingService,
    Geolocation,
    LocationAccuracy,
    AndroidPermissions
    // KeysPipe,

  ],

})
export class AppModule { }
//
