import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { WalletPageRoutingModule } from "./wallet-routing.module";
import { PipeModule } from "../pipe/pipe.module";

import { WalletPage } from "./wallet.page";
import { NotificationBellModule } from "src/components/notification-bell/notification-bell.module";
import { SuperTabsModule } from "@ionic-super-tabs/angular";
import { HttpClientModule } from "@angular/common/http";
import { WalletShowNewSalePage } from "./wallet-show-new-sale/wallet-show-new-sale.page";
import { WalletGenerateSalePage } from "./wallet-generate-sale/wallet-generate-sale.page";
import { WalletGenerateSubscriptionComponent } from "./wallet-generate-subscription/wallet-generate-subscription.component";
import { WalletModalInfoComponent } from "./wallet-modal-info/wallet-modal-info.component";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { WalletViewDocComponent } from "./wallet-view-doc/wallet-view-doc.component";
import { MainModule } from "../main/main.module";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastPointComponent } from '../toast-point/toast-point.component'
import { WalletMeetSettingsComponent } from "./wallet-meet-settings/wallet-meet-settings.component";
import { WalletDocumentSendComponent } from "./wallet-document-send/wallet-document-send.component";
import { WalletDocumentSendPopoverComponent } from "./wallet-document-send-popover/wallet-document-send-popover.component";
import { WalletPolicyComponent } from "./wallet-policy/wallet-policy.component";
import { GooglemapsComponent } from "../googlemaps/googlemaps.component";
import { AppModule } from "../app.module";
// import { GooglemapsComponent } from 'src/app/googlemaps/googlemaps.component';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    SuperTabsModule,
    HttpClientModule,
    NotificationBellModule,
    MainModule,
    // BrowserAnimationsModule,
    PipeModule,
    Ng2SearchPipeModule,
    NgxChartsModule,
    WalletPageRoutingModule
  ],
  declarations: [
    WalletPage,
    WalletShowNewSalePage,
    WalletGenerateSalePage,
    WalletModalInfoComponent,
    WalletMeetSettingsComponent,
    WalletDocumentSendComponent,
    WalletViewDocComponent,
    WalletGenerateSubscriptionComponent,
    WalletDocumentSendPopoverComponent,
    ToastPointComponent,
    WalletPolicyComponent
  ],
  entryComponents: [
    WalletShowNewSalePage,
    WalletModalInfoComponent,
    WalletMeetSettingsComponent,
    WalletDocumentSendComponent,
    WalletGenerateSalePage,
    WalletViewDocComponent,
    WalletDocumentSendPopoverComponent,
    WalletGenerateSubscriptionComponent
  ],
})
export class WalletPageModule { }
