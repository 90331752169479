import { BuisMeet } from './../../app/buis-meet';
import { UserHistoryComponent } from './../../app/user-history/user-history.component';
import { ToastService } from './../../providers/toast-service';
// import { SwitchBusinessComponent } from './../../app/switch-business/switch-business.component';
import { OrderButtonComponent } from './../../components/order-button/order-button.component';
import { LoadingService } from './../../providers/loading.service';
import { meetService } from './../../ShopService/meetS.service';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { FormGroup } from '@angular/forms';
import { userService } from '../../ShopService/user.service';
import { Router } from '@angular/router';
import { AuthService } from '../../providers/auth-service';
import { ModalController } from '@ionic/angular';
import { ChangeUserModal } from '../../modals/changeUser/changeUser.modal';
import { MenuController } from '@ionic/angular';
import { AlertExample } from '../../providers/alert.service';
import { IonRouterOutlet } from '@ionic/angular';
import { CoronaVirusService } from '../../app/corona-virus.service';
import { AlertController } from '@ionic/angular';
import { PaymentService } from 'src/app/payments/payment-service/payment.service';
import { Subscription } from 'rxjs';
import { ShopService } from 'src/ShopService/shop.service';

@Component({
    selector: 'Setting',
    templateUrl: 'Setting.html',
    styleUrls: ['./setting.page.scss']



})



export class SettingsPage implements OnInit, OnDestroy {
    ProfilePic;
    meets = [];




    view_invoice_from_meet(meet) {
        console.log('meet', meet);
        if (meet.invoice_data) {

            let arr = this.shopS.json2array(meet.invoice_data);
            if (arr.length == 1) {
                if (arr[0].doc_url) {
                    window.open(arr[0].doc_url, '_blank');
                }
                if (arr[0].url) {
                    window.open(arr[0].url.origin, '_blank');
                }
                //  this.invoiceM.doc_list(arr,this.shopS.shopData.Setting.invoice_supplier,cb=>{
                //    this.invoiceS.view_document(cb[0])
                //  })
            } else {
                //// show alert with options
            }

        } else {
            this.shopS.showfailToast('משהו השתבש נסו שנית')
        }
    }

    slideOpts = {
        initialSlide: 0,
        speed: 400,
        loop: false
    };

    notShowBannerArray = [];
    showBaner = true;
    showCancel = true;
    // meetsData: any;
    barberName = "";
    modalFLAG = false;
    userDeitls: userService;
    myGroup: FormGroup;
    // barberSetting = {
    //     "Message": "הודעה",
    //     "about": "",
    //     "addres": "",
    //     "bussniesPostImg": "",
    //     "code": 12345,
    //     "color": "",
    //     "cover": "https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/c0a3b521-7812-4de5-bdbe-580c5e62727e?alt=media&token=e7626068-9380-4e6d-9138-462cde770461",
    //     "daysOrderBlock": "",
    //     "history": "",
    //     "limitMeet": 14,
    //     "location": "",
    //     "logo": "https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/6b2b71bf-1c70-4fad-b50d-2f2534caa25c?alt=media&token=15a7a458-389d-48c6-9109-30e8268c5e2b",
    //     "name": "",
    //     "periodic": 3,
    //     "phone": "",
    //     "photos": [""],
    //     "profilePic": "pro.png",
    //     "template": 1,
    // }
    pageReday = false;
    businessArray: any = [];
    // arrayOfmeets = [];
    modalData: any = {
        "BarberID": "",
        "Date": "",
        "endTime": "",
        "key": "",
        "meetDate": "",
        "meetDay": "",
        "meetStatus": 0,
        "meetTime": "",
        "meetType": null,
        "meetTypeName": "",
        "startTimeView": "",
        "uId": "",
        "payment_status": null,
        reminder: '',
        "moreInfo": '',
        'biz': {
            'Setting': {
                'name': '',
                'phone': '',
                'logo': ''
            }
        }
    }

    number_of_recent_to_show = 7;


    // arrayOfmeets2 = [];
    // arrayOfmeets3 = [];


    // arrayOfmeets4 = [];

    pages = ['editProf', 'choseShop', 'appOptions'];
    todo = {

    };
    // sekeletonFLAG = false;
    lengthUserId;
    userRef;
    localUserObject: any;
    isconnectSubscribe: any;

    @ViewChild('busiListCont', { static: false }) busiListCont;


    constructor(
        public navCtrl: NavController,
        private MenuController: MenuController,
        private router: Router,
        public us: userService,
        private AlertExample: AlertExample,
        private modalController: ModalController,
        private authService: AuthService,
        private platform: Platform,
        private orderButtonComponent: OrderButtonComponent,
        private meetService: meetService,
        private LoadingService: LoadingService,
        public shopS: ShopService,
        public CoronaVirusService: CoronaVirusService,
        public routerOutlet: IonRouterOutlet,
        private ToastService: ToastService,
        private alertController: AlertController,
        private PaymentService: PaymentService) {
        // this.isconnectSubscribe = this.authService.isconnect.subscribe(value => {
        //     // this.LoadingService.dismiss();
        //     this.arrayOfmeets = [];
        //     this.arrayOfmeets2 = [];
        //     this.arrayOfmeets3 = [];
        //     this.arrayOfmeets4 = [];

        //     console.log('this.authService.isconnect.subscribe');
        //     if (value == true) {

        //         console.log('---gotostartWork');

        //         this.StartWork();
        //         this.authService.FirestTime = false;

        //         if (this.isconnectSubscribe) {
        //             this.isconnectSubscribe.unsubscribe();
        //         }
        //     }
        // })

        // let ss = localStorage.getItem('type');
        // if (ss == 'admin') {
        //     this.router.navigate(['tabsAdmin/calendar'], { replaceUrl: true });
        // }
    }
    currentReq = 0;
    totalRequests = [];
    requests = [];
    requestsSub: Subscription;
    userSub: Subscription;
    businessSub: Subscription;


    check_min_sub(){
        console.log('######################@@@@@!!!!!!!!!!!!!!!!!!!----- - - - - --  - -- - -- - - - - - -- -- - - - - - -- - -- - -- - -- -- - - -- -  -- - - -- - ---- - - - -  check_min_sub');
        let refd= this.shopS.afDb.database.ref("/BarberShop/e77079a6-b7b4-4ef2-b0b2-799d6c4f2b1d/Meetings/").child("2022-11-24");
        refd.orderByChild("Date").equalTo(1669278600000).on("value", function(snapshot) {
         snapshot.forEach(function(childSnapshot) {
          var childData = childSnapshot.val();
          console.log('######################@@@@@!!!!!!!!!!!!!!!!!!!----- - - - - --  - -- - -- - - - - - -- -- - - - - - -- - -- - -- - -- -- - - -- -  -- - - -- - ---- - - - -  check_min_sub');
          console.log(childSnapshot);
          console.log('######################@@@@@!!!!!!!!!!!!!!!!!!!----- - - - - --  - -- - -- - - - - - -- -- - - - - - -- - -- - -- - -- -- - - -- -  -- - - -- - ---- - - - -  check_min_sub');

         });
        })
        
            let dbbb =  this.shopS.afDb.database;
            console.log('dbb',dbbb);
            let ref =  dbbb.ref('/BarberShop/e77079a6-b7b4-4ef2-b0b2-799d6c4f2b1d/Meetings/2022-11-24');
            console.log('ref',ref);
            
        
        
            this.shopS.afDb.database
            .ref('/BarberShop/e77079a6-b7b4-4ef2-b0b2-799d6c4f2b1d/Meetings/2022-11-24').orderByKey().limitToFirst(1)
            .once('value', function (snapshot) {
                console.log('######################@@@@@!!!!!!!!!!!!!!!!!!!----- - - - - --  - -- - -- - - - - - -- -- - - - - - -- - -- - -- - -- -- - - -- -  -- - - -- - ---- - - - -  check_min_sub');

                  console.log('last!!!!!!',snapshot.val() )
                  console.log('######################@@@@@!!!!!!!!!!!!!!!!!!!----- - - - - --  - -- - -- - - - - - -- -- - - - - - -- - -- - -- - -- -- - - -- -  -- - - -- - ---- - - - -  check_min_sub');

        
            })  
        
            console.log('######################@@@@@!!!!!!!!!!!!!!!!!!!----- - - - - --  - -- - -- - - - - - -- -- - - - - - -- - -- - -- - -- -- - - -- -  -- - - -- - ---- - - - -  check_min_sub');

        }

    ngOnInit() {


        this.check_min_sub()




        if (this.shopS.external_login) {
            this.shopS.external_login = false;
            this.authService.checkType(this.us.user);
        }

        console.log('SettingInit');
        this.isconnectSubscribe = this.authService.isconnect.subscribe(value => {

            console.log('this.authService.isconnect.subscribe', value, this.pageReday);
            if (value == true && !this.pageReday) {
                console.log('---gotostartWork');
                this.pageReday = true;
                this.StartWork();
                if (this.isconnectSubscribe && !this.isconnectSubscribe.closed) {
                    this.isconnectSubscribe.unsubscribe();
                }
            }
        });

        this.shopS.changeButtonState(0);

        this.userSub = this.us.userLoaded.subscribe(user => {
            if (user) {
                // this.userSub.unsubscribe();
                this.shopS.getPaymentRequests('userID', this.us.user.key).then(res => {
                    let requests = res.val();
                    console.log(res.val());
                    this.totalRequests = this.shopS.json2array(requests);
                    this.requests = this.totalRequests.filter(req => req.payment_status == 'pending');
                    this.requests.forEach(req => {
                        this.shopS.getBuisLogo(req.BarberID).then(ress => {
                            console.log(ress.val());
                            let logo = ress.val();
                            if (logo) {
                                req.buis_logo = logo;
                            }

                        });
                    });
                    console.log(this.requests);

                    this.requestsSub = this.shopS.newPaymentRequest.subscribe(req => {
                        console.log(req);
                        if (req) {
                            this.requests.push(req);
                            this.shopS.reRender();
                        }
                    });
                })
            }
        });

        // setTimeout(() => {
        //     if (this.authService.config.userUpdate) {
        //         // this.shopS.updateUserOnBarber();
        //     }

        //     this.us.lastSeen();
        // }, 4000);
    }


    ionViewDidEnter() {
        console.log("ionViewDidEnter", this.us.user.isValid);

        // this.StartWork();

        this.shopS.changeButtonState(0);
        this.MenuController.enable(true)

        if (this.shopS.socialLogin == true) {
            this.shopS.socialLogin = false;
        }
        this.routerOutlet.swipeGesture = false;

        this.shopS.loaderoff = false;


        if (this.shopS.shopMessage.ok == true) {


            this.ToastService.presentDarkToastByText(this.shopS.shopMessage.message);
        }



        this.LoadingService.dismiss()
    }

    async presentAlertDelete(meet) {
        const alert = await this.AlertExample.alertController.create({
            header: 'מחיקת תור',
            message: 'האם אתה בטוח שברצונך למחוק',
            buttons: [
                {
                    text: 'לא',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'כן',

                    handler: () => {

                        this.deleteMeet(meet);
                        // if (meet.meetStatus != 5) {
                        //     this.shopS.meetForBuis.updateToCancelAdmin();
                        //     this.shopS.addNotificationBell('meet_cancel', 'business', { meet: this.shopS.meetForBuis.exportMeetAdmin() });
                        //     this.shopS.removeReminder(this.shopS.meetForBuis.key);
                        // }
                        // this.shopS.meetForBuis.removeFromUser();
                        // this.ToastService.presentDarkToastByText("התור נמחק בהצלחה");
                        // this.modalFLAG = false;
                        // this.shopS.changeButtonState(0);
                        // this.meetService.removeTafus(meet, false);
                        // this.shopS.meetForBuis.resetCLass();
                    }
                }
            ]
        });
        await alert.present();
    }

    ccc() {
        console.log(this.modalData.reminder);

    }

    deleteMeet(meet) {
        // this.LoadingService.present_for(5000);
        // this.shopS.meetForBuis.removeFromUser();
        if (meet.meetStatus != 5) {
            this.shopS.meetForBuis.meetStatus = 5;
            this.shopS.meet_api('cancel_meet_user', { meet: { user_meet: this.shopS.meetForBuis.exportMeetUser(), admin_meet: this.shopS.meetForBuis.exportMeetAdmin() }, notif_type: 'ClientDeleteLine', ShopSetting: this.shopS.shopData.Setting, function: 'deleteMeet' });
            // this.shopS.meetForBuis.updateToCancelAdmin();
            // BELL
            this.shopS.addNotificationBell('meet_cancel', 'business', { meet: this.shopS.meetForBuis.exportMeetUser() });
            // DELETE REMINDER
            // this.shopS.removeReminder(meet.key);
            // NOTIFICATION
            if (this.shopS.shopData.admin) {
                let notif_data = {
                    type: 'ClientDeleteLine',
                    send_to: [this.shopS.shopData.admin],
                    user: this.us.user,
                    meet: {
                        name: meet.meetType.name,
                        startTimeView: meet.startTimeView,
                        meetDate: meet.meetDate
                    }
                }
                this.shopS.send_notif(notif_data);
            }
        } else {
            this.shopS.meet_api('delete_meet_user', { meet: this.shopS.meetForBuis.exportMeetUser(), ShopSetting: this.shopS.shopData.Setting, function: 'deleteMeet' });
        }
        this.shopS.showToast('התור נמחק בהצלחה');
        this.modalFLAG = false;
        this.shopS.changeButtonState(0);
        this.shopS.meetForBuis.resetCLass();
    }

    goToIndex() {
        this.router.navigate(['/tabs/discover'], { replaceUrl: true });
        this.shopS.buttonHide = true;
    }


    async presentAlertEventDelete(meet) {
        console.log(meet);

        const alert = await this.AlertExample.alertController.create({
            header: 'ביטול השתתפות',
            message: 'האם אתה בטוח שברצונך לבטל את השתתפותך במפגש ?',
            buttons: [
                {
                    text: 'לא',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'כן',

                    handler: () => {
                        if (meet.eventStatus != 5) {
                            this.shopS.meet_api('delete_event_user', { meet: this.modalData, ShopSetting: this.shopS.shopData.Setting, function: 'presentAlertEventDelete' });
                            // this.shopS.loadEventById(this.modalData).then(res => {
                            this.shopS.deleteUserAndUpdateEvent(this.modalData, this.modalData.uId, 'user');
                            // });
                            // BELL
                            this.shopS.addNotificationBell('user_cancel_event', 'business', { meet: this.modalData });
                            // DELETE REMINDER
                            // this.shopS.removeReminder(this.modalData.key + '-' + this.modalData.uId);
                            //NOTIFICATION
                            if (this.shopS.shopData.admin) {
                                let notif_data = {
                                    type: 'user_cancel_event',
                                    send_to: [this.shopS.shopData.admin],
                                    user: this.us.user,
                                    meet: {
                                        name: meet.eventName,
                                        startTimeView: meet.startTimeView,
                                        meetDate: meet.meetDate
                                    }
                                }
                                console.log(notif_data);

                                this.shopS.send_notif(notif_data);
                            }
                        } else {
                            this.shopS.meet_api('delete_event_user', { meet: this.modalData, ShopSetting: this.shopS.shopData.Setting, function: 'presentAlertEventDelete' });
                        }
                        this.finishDeleteEvent();
                    }
                }
            ]
        });
        await alert.present();
    }

    finishDeleteEvent() {
        this.shopS.showToast('מפגש נמחק בהצלחה');
        this.shopS.changeButtonState(0);
        this.shopS.eventForBuis.resetCLass();
        this.modalFLAG = false;
    }

    openmenu() {
        this.MenuController.toggle();
    }

    closeMenu() {
        this.shopS.changeButtonState(0);
    }

    getUserData() {
        this.localUserObject = this.us.getUserObj();
        console.log("this.localUserObject ", this.localUserObject);
        if (this.localUserObject.key) {
            this.us.key = this.localUserObject.key;
        }
    }


    closeBanner() {

        this.notShowBannerArray.push(this.shopS.shopData.Setting.BarberID);
        this.showBaner = false;
    }
    logout() {
        this.authService.logout();
    }

    async openUserHistoryModal() {

        const modal = await this.modalController.create({
            component: UserHistoryComponent,
            swipeToClose: true,
            cssClass: 'modalForPayments modalGreyBG modal-half-hight',
            //  presentingElement: this.routerOutlet.nativeEl,
            mode: "ios",
            // Get the top-most ion-modal
        });

        await modal.present();
        const { data } = await modal.onWillDismiss();


    }

    sss(s) {
        console.log(s);

    }

    payNow() {
        console.log(this.modalData);
        let data: any = this.modalData;
        // let fixedPrice = data.price;

        // if (data.payment_status == 'rest_payment') {
        let paymentRespone = this.shopS.json2array(data.paymentRespone);
        console.log(paymentRespone);
        let totalPayed = paymentRespone.filter(res => res.sale_status == 'completed').map(res => res.price);
        console.log(totalPayed);
        let sum = totalPayed.reduce((partialSum, a) => partialSum + a, 0);
        sum = sum / 100;
        let fixedPrice = (parseFloat(data.price) - sum).toString();
        // }

        let obj = {
            name: data.meetType.name,
            price: fixedPrice,
            BarberID: data.BarberID,
            userid: data.uId,
            key: 'MEET-' + data.BarberID,
            type: 'meet',
            meetID: data.key,
            meet_date: this.shopS.meetForBuis.convertDateToYYYY(data.meetDate)
        }

        this.PaymentService.payNowModal(obj);
        let sub = this.PaymentService.paymentStatus.subscribe(status => {
            if (status) {
                if (status == 'success') {
                    this.modalData.payment_status = 'completed';
                }

                sub.unsubscribe();
                this.PaymentService.paymentStatus.next(null);
            }
        })
    }

    payRequest(request) {
        // let request = this.requests[this.currentReq];
        console.log(request);

        let obj = {
            name: request.cause,
            price: request.price,
            BarberID: request.BarberID,
            userid: request.userID,
            key: 'REQUEST-' + request.BarberID,
            type: 'meet',
            meetID: request.meet && request.meet.key,
            meet_date: request.meet && this.shopS.meetForBuis.convertDateToYYYY(request.meet.meetDate),
            requestID: request.meet && request.key
        }

        console.log(obj);

        this.PaymentService.payNowModal(obj);

        let sub = this.PaymentService.paymentStatus.subscribe(status => {
            if (status) {
                if (status == 'success') {
                    this.requests = this.requests.filter(req => req.key != request.key);
                    this.shopS.updatePaymentRequest(request.key, { payment_status: 'completed' });

                } else if (status == 'fail') {
                    console.log("fail");
                    this.ToastService.showTostDanger('אירעה שגיאה');
                }

                sub.unsubscribe();
                this.PaymentService.paymentStatus.next(null);
            }
        });
    }

    refuseRequest(request) {
        console.log(request);

        this.shopS.updatePaymentRequest(request.key, { payment_status: 'refused' }).then(val => {
            console.log(val);
            this.requests = this.requests.filter(req => req.key != request.key);
            this.shopS.log(this.us.user.key, 'paymentRequest_refused');
            if (request.meet) {
                this.shopS.updateMeetRequest(request, 'refused');
            }
        });
    }

    nextReq() {
        if (this.currentReq < this.requests.length - 1) {
            this.currentReq++;
        }
    }

    prevReq() {
        if (this.currentReq != 0) {
            this.currentReq--;
        }
    }

    zoomopen(data) {

        window.open(data, "_blank");
    }
    async openChangeUserModal() {
        const modal = await this.modalController.create({
            component: ChangeUserModal,
            mode: 'ios',
            cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'my-custom-modal-css',
        });
        return await modal.present();
    }

    dismissChageUserModal() {
        this.modalController.dismiss();
    }

    checkIfBizArrayEmpty() {
        return false;
    }

    // addBizToArray() {
    //     console.log('add BIZ');
    //     this.presentModalBusiness()
    // }

    // async presentModalBusiness(): Promise<HTMLIonModalElement> {
    //     if (this.platform.is("ios")) {

    //         var modal = await this.modalController.create({
    //             component: SwitchBusinessComponent,
    //             swipeToClose: true,
    //             cssClass: '',
    //             presentingElement: this.routerOutlet.nativeEl,
    //             mode: "ios",
    //         });
    //     } else {

    //         var modal = await this.modalController.create({
    //             component: SwitchBusinessComponent,
    //             swipeToClose: true,
    //             cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',
    //             mode: "ios",
    //             //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal


    //             // Get the top-most ion-modal

    //         });
    //     }

    //     await modal.present();

    //     const { data } = await modal.onWillDismiss();

    //     if (data) {
    //         if (data.dismissed == "finish") {
    //             this.getAllBarbers();
    //             if (this.us.user.bizArray) {
    //                 setTimeout(() => {
    //                     this.shopS.changeButtonState(0);
    //                     this.shopS.buttonHide = false;
    //                 }, 2000)
    //                 setTimeout(() => {
    //                     this.shopS.changeButtonState(0);
    //                     this.shopS.buttonHide = false;
    //                     console.log(this.shopS.buttonHide)
    //                 }, 3000);
    //             }
    //         }
    //     }
    //     return modal;

    // }

    async noBizModalMeet(buis) {
        // console.log(meet);
        // this.shopS.meetForBuis.importMeet(meet);

        // var headerText = "כן התחבר";

        const alert = await this.alertController.create({
            header: 'זיהינו כי אינך מקושר לבית העסק',
            subHeader: '',
            backdropDismiss: false,
            animated: true,
            keyboardClose: true,
            message: ' אפשר להוסיף את העסק חזרה לעסקים שלי, או למחוק את התור',
            buttons: [
                {
                    text: 'עבור לדף הפרופיל של העסק והוסף אותו חזרה',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        this.shopS.goToBuis(buis);


                        // if (this.us.user.bizArray && this.us.user.bizArray.length > 0) {
                        //     this.us.user.bizArray.push(this.shopS.meetForBuis.BarberID);
                        //     // this.us.updateUserDataNew(this.us.user);
                        //     console.log('treueeeeeee')
                        //     this.us.updateUserDataNew(this.us.user.bizArray, this.shopS.meetForBuis.BarberID, this.us.user.key);
                        //     this.shopS.getAllBarbers();

                        // } else {
                        //     this.us.user.bizArray = [this.shopS.meetForBuis.BarberID];
                        //     this.us.user.BarberID = this.shopS.meetForBuis.BarberID;
                        //     this.shopS.shopData.Setting.BarberID = this.shopS.meetForBuis.BarberID;
                        //     console.log('falseee')

                        //     this.us.updateUserDataNew(this.us.user.bizArray, this.shopS.meetForBuis.BarberID, this.us.user.key);

                        //     // this.us.updateUserDataNew(this.us.user);
                        //     let self = this;
                        //     this.shopS.getAllBarbers().then(value => {
                        //         console.log('valval', value);
                        //         //this.changeBizFromID(this.shopS.meetForBuis.BarberID)
                        //         // this.authService.logOutForLogin().then(data => {
                        //         //     console.log('dadadad', data);
                        //         //     self.shopS.changeButtonState(0)
                        //         // })
                        //         this.authService.checkType(this.us.user);
                        //     });

                        // }
                        // console.log(this.shopS.meetForBuis.BarberID, this.us.user.bizArray);



                        // console.log('Confirm Cancel: blah');

                    }
                }, {
                    text: 'מחיקת התור',

                    handler: () => {

                        this.deleteMeet(this.shopS.meetForBuis.exportMeetAdmin());

                        // if (meet.meetStatus != 5) {
                        //     this.shopS.meetForBuis.updateToCancelAdmin();
                        // } else {
                        // }
                        // this.shopS.meetForBuis.removeFromUser();
                        // this.ToastService.presentDarkToastByText("התור נמחק בהצלחה");
                        // this.modalFLAG = false;
                        // this.shopS.changeButtonState(0);
                        // this.meetService.removeTafus(meet, false);
                        // this.shopS.meetForBuis.resetCLass();

                    }
                }
            ]
        });

        await alert.present();

    }

    reminderChange(meet, reminder) {
        console.log(this.us.user);
        meet.reminder = reminder;
        let newMeet = {
            uId: this.us.user.key,
            meetTypeData: meet,
            startDate: new Date(meet.Date),
            uIdata: this.us.user

        }

        console.log('reminderChange', newMeet);

        this.shopS.setReminderClient(newMeet);
    }

    // currentBizIndex = 0;

    async changeBiz(biz, el) {

        console.log('changeBiz', biz, el);
        // this.currentBizIndex = this.shopS.businessArray.indexOf(biz);

        this.shopS.changeButtonState(0);
        console.log(this.us.user);
        if (this.shopS.shopData.Setting.BarberID != biz.Setting.BarberID) {
            // this.shopS.CloseLiveSub(this.shopS.shopData.Setting.BarberID);
            // this.shopS.openLiveSub(biz.Setting.BarberID, 'user');
            this.shopS.closeSettingSub();
            this.shopS.openSettingSub(biz.Setting.BarberID);
            console.log("4444");
            console.log(el);

            el.scrollTo(0, 0);
            this.shopS.shopData = biz;
            this.shopS.shopData.BarberId = biz.Setting.BarberID;
            this.shopS.getBusinessDataForUser(biz.Setting.BarberID);
            this.us.user.BarberID = this.shopS.shopData.Setting.BarberID;
            this.shopS.updateStrFull('/Users/' + this.us.user.key + '/',
                { BarberID: this.shopS.shopData.Setting.BarberID });
            this.shopS.shopID = this.shopS.shopData.Setting.BarberID;

            let index = this.notShowBannerArray.findIndex(x => x === biz.Setting.BarberID);
            if (index == -1) {
                this.showBaner = true;
            } else {
                this.showBaner = false;
            }

        } else {

            var textT = "<img class='imgalert' src=" + biz.Setting.logo + ">";
            var headerText = "כן התחבר";

            const alert = await this.alertController.create({
                header: 'פרופיל העסק',
                subHeader: 'תרצו לעבור',

                animated: true,
                keyboardClose: true,
                message: textT,
                buttons: [
                    {
                        text: 'לא',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: (blah) => {
                            console.log('Confirm Cancel: blah');
                        }
                    }, {
                        text: 'כניסה לפרופיל',

                        handler: () => {
                            this.shopS.goToBuis(biz.Setting);
                            // this.shopS.goToBuis(biz.Setting);
                        }
                    }
                ]
            });

            await alert.present();

        }


    }

    goToPage3() {
        this.router.navigate(['tabs/business-profile'], { replaceUrl: true });
    }
    // this.currentBizIndex = this.shopS.businessArray.indexOf(biz);

    // async getAllBarbers() {
    //     console.log('getAllBarbers');
    //     console.log(this.us.user.bizArray);

    //     if (this.us.user.bizArray) {
    //         const self = this;
    //         this.shopS.businessArray = [];
    //         let arr = [];
    //         let counter = 0;
    //         this.shopS.buttonHide = false;
    //         this.us.user.bizArray.forEach(element => {
    //             self.shopS.getBizSetting(element).then(bizData => {
    //                 arr.push({ Setting: bizData.val() });
    //                 counter++;

    //                 if (counter == this.us.user.bizArray.length) {
    //                     self.shopS.businessArray = arr.sort(this.compare);
    //                 }

    //                 // self.shopS.getShopWorkDaysForUser(element).then(
    //                 //     (shopWorkDays: any) => {
    //                 //         // console.log(bizData.val());
    //                 //         // console.log(shopWorkDays.val());
    //                 //         // console.log('shopWorkDays', shopWorkDays)
    //                 //         // Workdaysvar = shopWorkDays.val();
    //                 //         // let index = 0;
    //                 //         // let confirm = (this.us.user.needConfirmBuis && this.us.user.needConfirmBuis.includes(element)) ? false : true;
    //                 //         let data: any = {
    //                 //             BarberId: element,
    //                 //             Setting: bizData.val(),
    //                 //             WorkDays: shopWorkDays.val()
    //                 //             // confirmed: confirm
    //                 //         }
    //                 //         // console.log(data);

    //                 //         // index = arr.findIndex(x => x.Setting.BarberID == data.Setting['BarberID']);
    //                 //         // if (index == -1) {
    //                 //         arr.push(data);
    //                 //         // self.shopS.businessArray = self.shopS.businessArray.sort(this.compare);
    //                 //         // console.log(arr);

    //                 //         counter++;
    //                 //         // }
    //                 //         if (counter == this.us.user.bizArray.length) {
    //                 //             self.shopS.businessArray = arr.sort(this.compare);
    //                 //             // self.shopS.businessArray.find(busi => busi.BarberId == this.shopS.shopID);
    //                 //             // let foundBus = this.shopS.businessArray.find(busi => busi.Setting.BarberID == this.shopS.shopData.Setting.BarberID);
    //                 //             // this.currentBizIndex = this.shopS.businessArray.indexOf(foundBus);
    //                 //         }

    //                 //         // getBizSetting.unsubscribe();
    //                 //         // getShopWorkDays.unsubscribe()
    //                 //     });// end f workdays Sub
    //             });//end of subscribe
    //         });//end of forEach 







    //         // this.us.user.bizArray.forEach(element => {
    //         //     const getBizSetting = self.shopS.getBizSetting(element).valueChanges().subscribe(bizData => {
    //         //         let Workdaysvar: any;
    //         //         const getShopWorkDays = self.shopS.getShopWorkDaysForUser(element).valueChanges().subscribe(
    //         //             (shopWorkDays: any) => {
    //         //                 // console.log('shopWorkDays', shopWorkDays)
    //         //                 Workdaysvar = shopWorkDays;
    //         //                 // let index = 0;
    //         //                 // let confirm = (this.us.user.needConfirmBuis && this.us.user.needConfirmBuis.includes(element)) ? false : true;
    //         //                 let data: any = {
    //         //                     BarberId: element,
    //         //                     Setting: bizData,
    //         //                     WorkDays: Workdaysvar
    //         //                     // confirmed: confirm
    //         //                 }
    //         //                 // console.log(data);

    //         //                 // index = arr.findIndex(x => x.Setting.BarberID == data.Setting['BarberID']);
    //         //                 // if (index == -1) {
    //         //                 arr.push(data);
    //         //                 // self.shopS.businessArray = self.shopS.businessArray.sort(this.compare);
    //         //                 // console.log(arr);

    //         //                 counter++;
    //         //                 // }
    //         //                 if (counter == this.us.user.bizArray.length) {
    //         //                     self.shopS.businessArray = arr.sort(this.compare);
    //         //                     // self.shopS.businessArray.find(busi => busi.BarberId == this.shopS.shopID);
    //         //                     // let foundBus = this.shopS.businessArray.find(busi => busi.Setting.BarberID == this.shopS.shopData.Setting.BarberID);
    //         //                     // this.currentBizIndex = this.shopS.businessArray.indexOf(foundBus);
    //         //                 }

    //         //                 getBizSetting.unsubscribe();
    //         //                 getShopWorkDays.unsubscribe()
    //         //             });// end f workdays Sub
    //         //     });//end of subscribe
    //         // });//end of forEach 



    //         // console.log(promises);

    //         // Promise.all([promises]).then(values => {
    //         //     console.log(values.values());

    //         //     values.forEach((val: any) => {
    //         //         console.log(val);
    //         //         // let bizData = val.val();

    //         //         // console.log(bizData);

    //         //     });

    //         // })
    //     } else {
    //         console.log('no biz array');
    //         this.shopS.buttonHide = true;
    //     }//end of if  
    // }

    // checkUserStatus() {
    //     var checkUser: any = this.us.user;
    //     console.log("user data", this.us.user, checkUser.fname, checkUser.lname, checkUser.phone)
    //     if (((!checkUser.fname) || (!checkUser.lname) || (!checkUser.phone)) && (checkUser.email)) {
    //         console.log("need to updateeeeeee");
    //         this.us.emptyUser = true;
    //         this.openChangeUserModal();


    //     } else {
    //         console.log("ok ok ok ok ok ok ok ok ok")
    //     }
    // }

    // compare(a, b) {
    //     if (a.Setting && b.Setting) {
    //         const bandA = a.Setting.name.toLowerCase();
    //         const bandB = b.Setting.name.toLowerCase();

    //         let comparison = 0;
    //         if (bandA > bandB) {
    //             comparison = 1;
    //         } else if (bandA < bandB) {
    //             comparison = -1;
    //         }
    //         return comparison;
    //     }
    // }

    ionViewCanEnter() {
        console.log("ionViewCanEnter");

    }

    // ionViewDidLeave() {
    // console.log("ionViewDidLeave")
    // if (this.meetsData) {
    // this.meetsData.unsubscribe();
    // }
    // }

    // clearMeets() {
    //     this.arrayOfmeets2 = [];
    //     this.arrayOfmeets3 = [];
    //     this.arrayOfmeets4 = [];
    // }

    // is_admin_wihtout_his_biz(){
    //     if(!this.us.user.bizArray && this.us.user.shopOwnerKey  && !this.us.user.bizArray.contain(this.us.user.shopOwnerKey) ){
    //         // this.us.user.bizArray = [this.us.user.shopOwnerKey.id]

    //         // this.shopS.businessArray = [this.us.user.shopOwnerKey.id]
    //         alert('admin without his biz')

    //     }


    // }

    StartWork() {
        console.log("STARTWORK");
        // this.is_admin_wihtout_his_biz();
        this.shopS.AdminFLAG = false;

        this.shopS.changeButtonState(0);
        if (this.us.user.key) {
            console.log('openUserMeetingsSub');
            this.shopS.openUserMeetingsSub();
        }



        // this.checkUserStatus();
        // this.sekeletonFLAG = true;
        this.getUserData();
        // this.us.checkUserExciting();
        // this.meetService.meetToChange = "";
        // this.barberSetting = this.shopS.shopData.Setting

        // this.getAllBarbers();

        // var self = this;


        // this.us.getUserMeets().then((res: any) => {
        //     let data = res.val();
        //     this.arrayOfmeets = [];
        //     console.log('getUserMeets', data);
        //     var meets = this.combineMeet(data);
        //     meets.sort(this.compare);
        //     this.arrayOfmeets = meets;
        //     this.clearMeets();
        //     this.deletoldmeet();
        // });
    }

    // randerBuis() {
    //     var removesub = this.us.getUserMeets().subscribe((data: any) => {


    //         this.arrayOfmeets = [];


    //         console.log('data', data);

    //         var meets = this.combineMeet(data);
    //         meets.sort(this.compare);
    //         this.arrayOfmeets = meets;
    //         // this.arrayOfmeets2=meets;
    //         console.log('this.arrayOfmeets', this.arrayOfmeets);

    //         // this.arrayOfmeets.sort(this.compare);
    //         // this.arrayOfmeets2.sort(this.compare);

    //         // console.log(this.arrayOfmeets, " this.arrayOfmeets----2");
    //         this.clearMeets();
    //         this.deletoldmeet();

    //         removesub.unsubscribe();

    //         // meetsub.unsubscribe();
    //         // this.meetsArray(meets);

    //     })
    // }

    // combineMeet(meets) {
    //     if (meets && meets.length > 0) {
    //         this.shopS.removeSheet(meets);
    //     }
    //     var meetCombine = this.ShopService.json2array(meets);
    //     var allMeets = [];
    //     for (var i = 0; i < meetCombine.length; i++) {
    //         var arrayforuse = this.ShopService.json2array(meetCombine[i]);
    //         arrayforuse.forEach(element => {
    //             allMeets.push(element);
    //         });
    //     }
    //     console.log("allMeets", allMeets);
    //     return allMeets;
    // }

    // compare(a, b) {
    //     // Use toUpperCase() to ignore character casing
    //     const bandA = a.meetDate;
    //     const bandB = b.meetDate;

    //     let comparison = 0;
    //     if (bandA > bandB) {
    //         comparison = 1;
    //     } else if (bandA < bandB) {
    //         comparison = -1;
    //     }
    //     return comparison;
    // }

    // deletoldmeet() {
    //     var inde_count = 0;
    //     this.arrayOfmeets.forEach((x, index) => {
    //         var d1 = new Date();
    //         var d2 = new Date(x.endTime);
    //         // d1.setDate(d1.getDate());
    //         if (d1.getTime() > d2.getTime()) {
    //             x.meetStatus = 5;
    //             this.arrayOfmeets2.push(x);
    //         } else {
    //             if (x.BarberID) {
    //                 x['dateforsort'] = new Date(x.startDate);
    //                 if (this.number_of_recent_to_show > inde_count || this.hide_load_button) {
    //                     this.arrayOfmeets4.push(x);
    //                     inde_count++;
    //                 }
    //                 this.arrayOfmeets3.push(x);
    //             }
    //         }

    //         // var d11 = d1.getDate() + "/" + (d1.getMonth() + 1) + "/" + d1.getFullYear();
    //         // var d22 = d2.getDate() + "/" + (d2.getMonth() + 1) + "/" + d2.getFullYear();

    //         // var d1 = new Date();
    //         // var d2 = new Date();
    //         // if (d11 > d22) {
    //         //     //   this.meetService.removeTafus(x);
    //         // }
    //     })
    //     console.log(this.arrayOfmeets4);

    //     // this.moveToHistory();
    // }

    // hide_load_button = false;
    // load_all_events() {
    //     this.hide_load_button = true;
    //     this.arrayOfmeets4 = this.arrayOfmeets3;

    // }

    // moveToHistory() {
    //     this.arrayOfmeets2.forEach(x => {
    //         this.shopS.moveToHistory(x);
    //     })
    // }


    coronaVirus() {

        this.CoronaVirusService.dataToSave = {
            data: this.modalData,
        }
        this.CoronaVirusService.dataToSave.data.meetTime = this.CoronaVirusService.dataToSave.data.startTimeView;

        this.CoronaVirusService.alertModal()

    }


    changeBizFromID(id) {
        let searchResult = this.shopS.businessArray.filter(x => x.Setting.BarberID == id);
        console.log(searchResult)
        console.log(this.shopS.businessArray)
        console.log(this.busiListCont);

        if (searchResult[0].Setting.BarberID != this.shopS.shopData.Setting.BarberID) {
            this.changeBiz(searchResult[0], this.busiListCont.nativeElement);
        }

    }


    changeMeet(meet) {
        console.log("meet", meet);
        console.log(this.shopS.businessArray)

        //    let searchResult = this.shopS.businessArray.filter(x => x.Setting.BarberID == meet.BarberID);
        //    console.log(searchResult)
        //     if(searchResult[0].Setting.BarberID != this.shopS.shopData.Setting.BarberID){
        //         this.changeBiz(searchResult[0])
        //     }
        this.changeBizFromID(meet.BarberID)

        this.meetService.meetToChange = meet;
        this.meetService.meetToChange.meetStatus = 5;
        this.modalFLAG = false;
        this.shopS.changeButtonState(0);

        this.shopS.userOrder.stage = 0;

        this.orderButtonComponent.goToAbout()
        // this.router.navigate(['tabs/about']);

    }

    // meetsArray(meets) {
    //     this.arrayOfmeets = [];
    //     meets.forEach(x => {
    //         this.arrayOfmeets.push(x);
    //     })
    // }

    // changeToBuis(id) {
    //     console.log(this.shopS.businessArray);

    //     this.shopS.businessArray.forEach(element => {
    //         if (element.Setting.BarberID == id) {
    //             this.changeBiz(element, this.busiListCont);
    //             return;
    //         }

    //     });
    // }


    toggleModal(event, data) {
        console.log('data', data);
        if (data.BarberID) {
            this.changeBizFromID(data.BarberID)
        }
        if (this.modalFLAG) {
            this.shopS.changeButtonState(0);
        } else {
            this.shopS.changeButtonState(-1);
        }

        if (data == '') {
            this.modalFLAG = !this.modalFLAG;
            // this.showCancel = true;
            return;
        }

        this.shopS.meetForBuis.importMeet(data);
        this.shopS.meetForBuis.showClass();


        event.stopPropagation();




        this.barberName = data.barberName;
        let index = this.shopS.businessArray.findIndex(x => x.Setting.BarberID == data.BarberID);

        if (data.meetType.payment_way == 'down_payment' && data.payment_status != 'pending') {
            let paymentRespone = this.shopS.json2array(data.paymentRespone);
            let totalPayed = paymentRespone.filter(res => res.sale_status == 'completed').map(res => res.price);
            let sum = totalPayed.reduce((partialSum, a) => partialSum + a, 0);
            sum = sum / 100;
            if (sum < parseFloat(data.meetType.price)) {
                data.payment_status = 'rest_payment';
            }
        }
        data.reminder = data.reminder.toString();
        this.modalData = data;
        let cancelTime = parseInt(data.meetType.cancelation);
        if (cancelTime) {
            // let today = new Date();
            // let date_to_reply = new Date(data.startDate);
            // // console.log((date_to_reply.getTime() - today.getTime())/7);
            // var timeinmilisec = date_to_reply.getTime() - today.getTime();
            // var daysUntil = Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24));
            // if (Number(cancelTime) > daysUntil) {
            //     this.showCancel = false;
            // }


            let now = new Date().getTime();
            let cancelationTime = (1000 * 60 * 60 * 24 * cancelTime);
            let meetTime = new Date(data.startDate).getTime();
            if ((meetTime - now) < cancelationTime) {
                this.showCancel = false;
            }
        } else {
            this.showCancel = true;
        }



        if (index != -1) {
            this.modalData.biz = this.shopS.businessArray[index];
            if (this.modalData.reminder) {
                this.modalData.reminder = this.modalData.reminder.toString();
            }
            console.log('this.modalData', this.modalData)



            this.modalFLAG = !this.modalFLAG;
        } else {
            this.modalData.biz = {
                Setting: {
                    name: '',
                    phone: '',
                    logo: '',
                }
            }
            this.shopS.changeButtonState(0);

            this.shopS.getBuisSettingByID(data.BarberID).then(res => {
                let buis = res.val();
                if (buis) {
                    this.noBizModalMeet(buis);
                }
            });

        }

    }

    toggleModalevent(event, data) {
        console.log('data', data);

        this.shopS.eventForBuis.importEventData(data);
        this.shopS.eventForBuis.showClass();


        event.stopPropagation();

        // this.showCancel = true;
        if (this.modalFLAG) {
            this.shopS.changeButtonState(0);
        } else {
            this.shopS.changeButtonState(-1);
        }

        if (data == '') {
            this.modalFLAG = !this.modalFLAG;
            return false;
        }


        this.barberName = data.barberName;
        let index = this.shopS.businessArray.findIndex(x => x.Setting.BarberID == data.BarberID);

        if (data.payment_way == 'down_payment') {
            let paymentRespone = this.shopS.json2array(data.paymentRespone);
            let totalPayed = paymentRespone.filter(res => res.sale_status == 'completed').map(res => res.price);
            let sum = totalPayed.reduce((partialSum, a) => partialSum + a, 0);
            sum = sum / 100;
            if (sum < parseFloat(data.price)) {
                data.payment_status = 'rest_payment';
            }
        }

        this.modalData = data;




        // if(data.meetType.cancelation){
        //     let today=new Date();
        //     let date_to_reply = new Date(data.startDate);
        //     // console.log((date_to_reply.getTime() - today.getTime())/7);
        //     var timeinmilisec = date_to_reply.getTime() - today.getTime();
        //     var daysUntil=Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24)) ;
        //     if(Number(data.meetType.cancelation) > daysUntil){
        //     this.showCancel=false;
        //     }
        // }



        if (index != -1) {
            this.modalData.biz = this.shopS.businessArray[index];
            if (this.modalData.reminder) {
                this.modalData.reminder = this.modalData.reminder.toString();
            }
            console.log('this.modalData', this.modalData)


            this.modalFLAG = !this.modalFLAG;
        } else {
            this.modalData.biz = {
                Setting: {
                    name: '',
                    phone: '',
                    logo: '',
                }
            }
            this.shopS.changeButtonState(0);
            this.shopS.getBuisSettingByID(data.BarberID).then(res => {
                let buis = res.val();
                if (buis) {
                    this.noBizModalMeet(buis);
                }
            });
        }
        // this.showCancel = true;


    }

    ionViewCanLeave() {
        return false;
    }

    public ngOnDestroy(): void {
        // if (this.meetsData) {
        //     this.meetsData.unsubscribe();
        // }
        console.log("SETTINGS DESTROY");

        this.shopS.closeUserMeetingsSub();
        if (this.isconnectSubscribe && !this.isconnectSubscribe.closed) {
            this.isconnectSubscribe.unsubscribe();
        }
        if (this.userSub) {
            this.userSub.unsubscribe();
        }
        if (this.requestsSub) {
            this.requestsSub.unsubscribe();
        }
        // this.requestsSub.unsubscribe();
    }



}
