import { CaltimePipPipe } from './../caltime-pip.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeysPipe } from "../keys.pipe";
import { DateAgoPipe } from "../pipes/date-ago.pipe";
import { ClientKeyPipe } from "../client-key.pipe";
import { MeetnotifiPipe } from '../meetnotifi.pipe';
import { SortlistModule } from '../sortlist/sortlist.module';
import { NumberCommaPipe } from '../number-comma.pipe';
import { DecodeURIPipe } from '../decode-uri.pipe';


@NgModule({
  declarations: [KeysPipe,DateAgoPipe,ClientKeyPipe,CaltimePipPipe,MeetnotifiPipe, NumberCommaPipe, DecodeURIPipe],
  imports: [
    CommonModule,
    SortlistModule
  ],
  exports: [KeysPipe,DateAgoPipe,ClientKeyPipe,CaltimePipPipe,MeetnotifiPipe,SortlistModule, NumberCommaPipe, DecodeURIPipe],
})
export class PipeModule { }
