import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/notification.service';
import { ShopService } from 'src/ShopService/shop.service';
import { PaymentService } from "../payment-service/payment.service";

@Component({
  selector: 'app-paynow',
  templateUrl: './paynow.component.html',
  styleUrls: ['./paynow.component.scss'],
})
export class PaynowComponent implements OnInit {

  constructor(
    public PaymentService: PaymentService,
    public shopS: ShopService,
    private modalController: ModalController,
    private notiS: NotificationService
  ) { }

  closeModal() {
    this.modalController.dismiss();
  }

  next_payment;
  gift = false;

  ngOnInit() {
    console.log('ngOnInit')
    this.PaymentService.getUserCards();
    // this.clearSeletion();
    console.log(this.checkMeetAvailable);
    this.next_payment = new Date();

    if (this.PaymentService.currentProduct.key == '-MGXUUY-621pbguAGGHm' && ((this.shopS.shopData.Setting.gift_options && !this.shopS.shopData.Setting.gift_options.used) || !this.shopS.shopData.Setting.gift_options)) {
      this.gift = true;
      if (this.shopS.shopData.Setting.gift_options && this.shopS.shopData.Setting.gift_options.days) {
        this.next_payment.setDate(this.next_payment.getDate() + this.shopS.shopData.Setting.gift_options.days);
      } else {
        this.next_payment.setDate(this.next_payment.getDate() + this.shopS.configDATA.gift_options.days);
      }

      if (this.shopS.shopData.Setting.gift_options && this.shopS.shopData.Setting.gift_options.price) {
        this.PaymentService.currentProduct.price = this.shopS.shopData.Setting.gift_options.price;
      }
    }
  }



  ngOnDestroy() {
    console.log('ngOnDestroy')
    this.PaymentService.UserCardsSubscribe.unsubscribe();
    if (this.paymentSub) {
      this.paymentSub.unsubscribe();
    }
  }


  PushCard(x) {
    this.clearSeletion();
    x.active = true;
    this.PaymentService.selectedCard = x;
  }

  @Input() checkMeetAvailable;
  paymentSub: Subscription;

  async payNow() {
    if (this.checkMeetAvailable) {
      if (this.PaymentService.currentProduct.type == 'meet') {
        let meet = this.shopS.meetForBuis.exportMeetUser();
        this.shopS.checkMeetAvailable(meet.startDate, meet.meetType.meetTime, meet.key, null, meetIsAvailable => {
          if (meetIsAvailable) {
            this.shopS.itemSelectedTime(this.continuePay.bind(this));
            this.paymentSub = this.PaymentService.paymentStatus.subscribe(status => {
              console.log(status);

              if (status) {
                if (status == 'success') {
                  this.shopS.meetFinish();
                  this.shopS.changeButtonState(0);
                } else if (status == 'fail') {
                  console.log("fail");
                  this.shopS.deletMeetforeverUser(this.shopS.meetForBuis.exportMeetUser());
                  this.shopS.deletMeetforeverUserAdmin(this.shopS.meetForBuis.exportMeetUser());
                  // this.shopS.meetForBuis.resetCLass();
                }

                this.paymentSub.unsubscribe();
                this.PaymentService.paymentStatus.next(null);
              }
            });
          } else {
            this.modalController.dismiss();
            this.shopS.getFreeHours(this.shopS.calendar.currentDate);
            this.shopS.presentAlertMeet('היי התור בידיוק נתפס! נסו תור אחר');
          }
        });
      } else if (this.PaymentService.currentProduct.type == 'event') {
        this.shopS.checkEventAvailable(eventIsAvailable => {
          if (eventIsAvailable) {
            this.continuePay();
            this.paymentSub = this.PaymentService.paymentStatus.subscribe(status => {
              console.log(status);

              if (status) {
                if (status == 'success') {
                  this.shopS.signInToEvent(this.shopS.eventForBuis.exportEventUser());
                  // var datauser = {
                  //   key: this.shopS.shopData.Setting.userManager,
                  // };
                  // this.notiS.arrange_JSON_Notification_event(this.shopS.eventForBuis.exportEventAdmin(), datauser, 'user_add_event');
                  this.shopS.changeButtonState(0);
                } else if (status == 'fail') {
                  console.log("fail");
                  // this.shopS.meetForBuis.resetCLass();
                }

                this.paymentSub.unsubscribe();
                this.PaymentService.paymentStatus.next(null);
              }
            });
          } else {
            this.modalController.dismiss();
            this.shopS.selectEvent(this.shopS.eventForBuis.exportEventAdmin());
            // this.shopS.changeViewHouers(this.shopS.calendar.currentDate);
            // this.shopS.presentAlertMeet('היי התור בידיוק נתפס! נסו תור אחר');
          }
        });
      }

      // this.shopS.itemSelectedTime();
    } else {
      this.continuePay();
    }
    //  this.PaymentService.sendData(ccard);
  }

  continuePay(newProduct?) {
    console.log(newProduct);
    
    if (newProduct) {
      this.PaymentService.currentProduct = newProduct;
    }
    console.log(this.PaymentService.currentProduct);
    
    console.log(this.PaymentService.selectedCard)
    let ccard = {
      cardType: this.PaymentService.selectedCard.cardType,
      cardNo: this.PaymentService.selectedCard.cardId,
      cardDisplay: this.PaymentService.selectedCard.cardDisplay,
      fullName: this.PaymentService.selectedCard.fullName,
      cardExpiration: this.PaymentService.selectedCard.cardExpiration,
      tz: this.PaymentService.selectedCard.tz,
      amount: this.PaymentService.currentProduct.price,
      type: 'token',
      renew_sub: this.PaymentService.currentProduct.renew_subscription,
      key: this.PaymentService.selectedCard.key,
      payType: this.PaymentService.currentProduct.payType
    }

    if (this.PaymentService.currentProduct.renew_subscription) {
      ccard.type = 'renew';
    }

    this.PaymentService.AuthFingerprintAIO(ccard);
  }


  clearSeletion() {
    for (var key in this.PaymentService.userCards) {
      this.PaymentService.userCards[key]['active'] = false;
    }




  }












  // async addNewCard(): Promise<HTMLIonModalElement> {
  //   console.log('asdasdas')
  //      const modal = await this.modalController.create({
  //        component: AddCreditCardComponent,
  //        swipeToClose: true,
  //        cssClass: 'modalLookLikeNativ',
  //      //  presentingElement: this.routerOutlet.nativeEl,
  //        mode:"ios",
  //       // Get the top-most ion-modal
  //      });
  //       await modal.present();
  //      const { data } = await modal.onWillDismiss();
  //      if(data){
  //      if(data.dismissed == "finish"){
  //      }
  //  }
  //      return modal;
  //    }



  //    userCards = [];

  //    UserCardsSubscribe:any;
  //    getUserCards(){
  //      this.UserCardsSubscribe = this.PaymentService.getUserCard(this.userService.key).subscribe((res:any)=>{
  //      ///  console.log(res);

  //    //  get the keys
  //    let keys = this.PaymentService.getkeys(res);
  //   ///// console.log('keys',keys)

  //    //
  //    res = this.PaymentService.json2array(res);

  //    this.userCards = [];

  //    res.forEach((element,index) => {
  //    let dat = JSON.parse(this.CryptoService.Decrypt(element));
  //    dat['key'] = keys[index];
  //    //console.log('element',this.CryptoService.Decrypt(element));
  //    this.userCards.push(dat);

  //    });// end of foreach

  //   //// console.log('this.userCards',this.userCards);
  // //   this.userCards[this.currentCardIndex]['active'] = true;
  //    this.cardSelected(this.currentCardIndex)
  //    // I WANT THE SUBSCRIBE TO END WHEN MODAL IS DISMISS
  //    //UserCardsSubscribe.unsubscribe();
  //    });// end of subscribe
  //    }





}
