import { PremiumService } from './../premium/premium-service/premium.service';
import { ShopService } from './../../ShopService/shop.service';
import { PopoverController, NavParams } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { ExclusivePopoverComponent } from '../exclusive-popover/exclusive-popover.component';

@Component({
  selector: 'app-premium-popover',
  templateUrl: './premium-popover.component.html',
  styleUrls: ['./premium-popover.component.scss'],
})
export class PremiumPopoverComponent implements OnInit {

  settingUpdate = {};
  premium = true;
  typePop = "";
  @Input() typePop2;
  eventPeoplePremium = [];

  constructor(private popoverController: PopoverController,
    private navParams: NavParams
    , private shopS: ShopService,
    private premiumService: PremiumService) { }

  ngOnInit() {
    //Get data from popover page
    this.typePop = this.navParams.get('type');

    if (this.typePop == "eventPeople") {
      this.eventPeoplePremium = [];
      console.log("CartridgeMeet", this.shopS.shopData.Setting.Premium.CartridgeMeet);
      for (var i = 1; i < this.shopS.shopData.Setting.Premium.CartridgeMeet; i++) {
        this.eventPeoplePremium.push({ num: i, type: "b" });
      }
      for (i = this.shopS.shopData.Setting.Premium.CartridgeMeet; i <= 50; i++) {
        this.eventPeoplePremium.push({ num: i, type: "p" });
      }
    }




    console.log("typePop", this.typePop)
  }

  showExc() {
    this.shopS.openModal(ExclusivePopoverComponent, 'modalLookfullWidth', false, null);
  }


  settingChange(event, kind) { 

    // console.log('event',event);
    // console.log('kind',kind);
    // console.log('this.shopS.shopData=before',this.shopS.shopData);
    // this.shopS.shopData[kind] = event.detail.value;
    // console.log('this.shopS.shopData=after',this.shopS.shopData);

    this.settingUpdate[kind] = event;
    this.shopS.updateStr('/Setting/', this.settingUpdate);
    this.popoverController.dismiss();

  }

  showPremium() {
    // this.popoverController.dismiss();
    this.premiumService.PremiumLock("go");
  }

  cancelReturn(daysCancel) {

    this.popoverController.dismiss(daysCancel);

  }
  eventPeople(people) {
    console.log("people", people);
    this.popoverController.dismiss(people);

  }
}
