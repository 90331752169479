import { Injectable } from '@angular/core';
import { ShopService } from '../ShopService/shop.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { AlertController } from '@ionic/angular';
import { userService } from '../ShopService/user.service';
import { ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class CoronaVirusService {

  constructor(
    public shopS: ShopService,
    public us: userService,
    private afDb: AngularFireDatabase,
    private toastController: ToastController,
    private alertController:AlertController
  ) { }

dataToSave:any= {
  meetTypeName: '',
  barberName: '',
  meetTime: '',
  meetType: '',
  keyLine: '',
  startDate : '',
  uId: '',
  userFullname : ''
}
  
toastss:any;
async showToast() {
  this.toastss = await this.toastController.create({
    message: `נחתם בהצלחה`,
    position: 'top',
    color:'success',
    cssClass: 'toastCustomWebc',
    duration: 2000,
    mode: 'ios'
  });
  return await this.toastss.present();
  // toast.present();
}


  savetoDB(){
    var newID = this.shopS.newGuid();
    console.log('dataToSave: ',this.dataToSave);
    console.log('this.us.user: ',this.us.user);
    let dat:any = {
      userFullname: this.us.user.fname + ' ' + this.us.user.lname,
      ProfilePic: this.us.user.ProfilePic ? this.us.user.ProfilePic : '',
      barberName: this.dataToSave.data.barberName,
      meetTime: this.dataToSave.data.meetTime,
      currentSignDate: new Date(),
      uId : this.us.user.key,
    } 


    dat.currentSignDate = dat.currentSignDate.toString();
    console.log('dat: ',dat);

    this.afDb.object('/coronavirus/' + this.shopS.shopID + "/"+newID).set(dat);
    this.showToast();
  }



  async alertModal(){

    var textT=`<img class='imgalert2' src="../../assets/imgs/virus.png">`;
    textT+=this.shopS.configDATA.coronavirus.text;
    const alert = await this.alertController.create({
      header: this.shopS.configDATA.coronavirus.alertTitle,
      message:textT,
      buttons: [
        {
          text: this.shopS.configDATA.coronavirus.alertNotConfirmBtn,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cancel Cancel: Cancel');
            return false;
          }
        }, {
          text: this.shopS.configDATA.coronavirus.alertConfirmBtn,
          handler: () => {

            console.log('Okay: Okay');
            //confirm order 
            this.savetoDB();

          }
        }
      ]
    });
    await alert.present();

  }



}
