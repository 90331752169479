import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { ConfigService } from "../../app/config.service";

@Component({
  selector: 'smsCode',
  templateUrl: 'smsCode.page.html',
  styleUrls: ['./smsCode.page.scss']
})

export class SmsCodePage implements OnInit {

  smsCodeForm: FormGroup;
  constructor(private fb: FormBuilder,
    public configS: ConfigService,
              private router: Router) {}

  ngOnInit() {
    this.generateSmsCodePage();
  }

  generateSmsCodePage() {
    this.smsCodeForm = this.fb.group({
      firstNumber: ['', [Validators.compose([
        Validators.required, Validators.maxLength(1)
      ])]],
      secondNumber: ['', [Validators.compose([
        Validators.required, Validators.maxLength(1)
      ])]],
      thirdNumber: ['', [Validators.compose([
        Validators.required, Validators.maxLength(1)
      ])]],
      fourthNumber: ['', [Validators.compose([
        Validators.required, Validators.maxLength(1)
      ])]],
    });
  }

  goToRegisterPage() {
    this.router.navigate(['signUp']);
  }


  goToSetPassPage() {
    console.log(this.smsCodeForm.value);
    this.router.navigate(['setPassword']);
  }


}
