import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/services/auth-guard.service';
import { GoogleCalendarPage } from './google-calendar.page';

const routes: Routes = [
  {
    path: '',  redirectTo: 'tabs', pathMatch: 'full',
  },
  {
    path: 'google-calendar', component: GoogleCalendarPage, canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GoogleCalendarPageRoutingModule {}
