import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ShopService } from 'src/ShopService/shop.service';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss'],
})
export class PrivacypolicyComponent implements OnInit {

  constructor(public modalController: ModalController, public shopS: ShopService) { }

  ngOnInit() {}
  closeModalnodismiss(){
    this.modalController.dismiss();

  }
}
