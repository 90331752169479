import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
// import { File } from '@ionic-native/file/ngx';

@Component({
  selector: 'app-user-share-to-biz',
  templateUrl: './user-share-to-biz.component.html',
  styleUrls: ['./user-share-to-biz.component.scss'],
})
export class UserShareToBizComponent implements OnInit {

  constructor(
    public shopS: ShopService,
    private socialSharing: SocialSharing,
    // private file: File
  ) { }

  ngOnInit() { }

  toggleModal(event) {
    event.stopPropagation();
    this.shopS.UserShareToBizFLAG = !this.shopS.UserShareToBizFLAG;
    console.log('toggleModal=======this.shopS.SharemodalFLAG', this.shopS.UserShareToBizFLAG);
  }

  text = '';
  url = 'https://point-app.net/';

  async shareInstagram() {
    // Either URL or Image
    this.text = `שלום לכם לקוחות יקרים!
    התחלתי לעבוד עם שירות לקביעת תורים.
    מעכשיו תוכלו להזמין אצלי תור בפשטות וקלות, הרשמו והזינו את קוד העסק `+ this.shopS.shopData.Setting.code + ' אני מזמין אתכם להכנס ללינק מטה ולהוריד את האפליקציה:' + this.url;

    this.socialSharing.shareViaInstagram(this.text, null).then(() => {
      // Success
    }).catch((e) => {
      // Error!
    });
  }



  async share() {
    let line_break = this.shopS.environment.platform == 'web' ? '%0a' : '\n';

    // this.text = this.shopS.configDATA.UserShareToBiz + this.url;
    this.text = 'היי, הייתי רוצה להזמין אצלך תורים דרך אפליקציית Point, המאפשרת ללקוחות לזמן תורים בעצמם.' + line_break + 'אשמח אם תשתמש בשירות על מנת להקל עליי כלקוח, השירות ניתן חינם!' + line_break + this.url;
    this.shopS.share(this.text);
    // this.socialSharing.share
    // (this.text, null,null,this.url).then(() => {
    //   // Success
    // }).catch((e) => {
    //   // Error!
    //   console.log('err',e)
    // });



  }

  async shareSMS() {
    // Either URL or Image
    this.text = this.shopS.configDATA.UserShareToBiz + this.url;
    console.log('this.text', this.text)
    console.log('this.url', this.url)
    console.log('this.shopS.shopData.Setting.code', this.shopS.shopData.Setting.code)
    this.socialSharing.shareViaSMS(this.text, null).then(() => {
      // Success
    }).catch((e) => {
      // Error!
    });
  }


  async shareWhatsApp() {
    this.text = this.shopS.configDATA.UserShareToBiz;
    if (this.shopS.environment.platform == 'web') {
      window.open("https://api.whatsapp.com/send?text=" + this.text + " " + this.url);
    } else {
      this.socialSharing.shareViaWhatsApp(this.text, null, this.url).then(() => {
      }).catch((e) => {
        console.log('whatsapp error - ', e);
      });
    }
  }

  // async shareWhatsAppWeb() {
  //   this.text = this.shopS.configDATA.UserShareToBiz;

  //   window.open("https://api.whatsapp.com/send?text=" + this.text + " " + this.url);
  // }

  async resolveLocalFile() {
    // return this.file.copyFile(`${this.file.applicationDirectory}www/assets/imgs/`, 'academy.jpg', this.file.cacheDirectory, `${new Date().getTime()}.jpg`);
  }

  removeTempFile(name) {
    // this.file.removeFile(this.file.cacheDirectory, name);
  }

  async shareEmail() {
    let file = await this.resolveLocalFile();
    this.text += this.shopS.shopData.Setting.code + ' אני מזמין אתכם להכנס ללינק מטה ולהוריד את האפליקציה:' + this.url;

    this.socialSharing.shareViaEmail(this.text, 'שלום', [], null, null, null).then(() => {

    }).catch((e) => {
      // Error!
    });
  }

  async shareFacebook() {
    // let file = await this.resolveLocalFile();

    // // Image or URL works
    // this.socialSharing.shareViaFacebook(null, null, null).then(() => {
    //   this.removeTempFile(file.name);
    // }).catch((e) => {
    //   // Error!
    // });
  }













}
