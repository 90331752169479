import { Component, OnInit } from '@angular/core';
import { PaymentService } from "../../payments/payment-service/payment.service";
import { ModalController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-premium-lock',
  templateUrl: './premium-lock.component.html',
  styleUrls: ['./premium-lock.component.scss'],
})
export class PremiumLockComponent implements OnInit {
  premium_active = false;


  celebrating_gif_array = [
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-1.gif?alt=media&token=dbef9049-e64d-4ae5-8873-942003b5631e',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-10.gif?alt=media&token=4df7ee33-dcb8-4c47-bdec-b72810e57ef0',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-11.gif?alt=media&token=3dbd40a8-c1fe-49a1-83aa-fae4c42c9d2e',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-12.gif?alt=media&token=8e6adacf-3b30-496f-8424-9e295c9e9f30',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-13.gif?alt=media&token=ecd41bd6-1e9b-4548-a71b-e42c85209188',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-2.gif?alt=media&token=5ee2f58f-d949-427b-9514-a73ca17503c1',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-4.gif?alt=media&token=06439c24-25bb-4fb4-8ac2-e37de69cc982',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-5.gif?alt=media&token=f296dfd7-0354-4f35-8b63-2c1893f0d6ef',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-6.gif?alt=media&token=121ed847-5a28-4baf-b5e9-d645f1bef90b',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-7.gif?alt=media&token=dd85727b-ba58-4365-a17f-07c0032d0a2a',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-8.gif?alt=media&token=ba141dd8-526b-400c-a9d5-b700f59707db',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-9.gif?alt=media&token=dccb27b6-f32a-4b52-9959-100371655d2c',
    // '../assets/gif/giphy-1.gif',
    // '../assets/gif/giphy-2.gif',
    // // '../assets/gif/giphy-3.gif',
    // '../assets/gif/giphy-4.gif',
    // '../assets/gif/giphy-5.gif',
    // '../assets/gif/giphy-6.gif',
    // '../assets/gif/giphy-7.gif',
    // '../assets/gif/giphy-8.gif',
    // '../assets/gif/giphy-9.gif',
    // '../assets/gif/giphy-10.gif',
    // '../assets/gif/giphy-11.gif',
    // '../assets/gif/giphy-12.gif',
    // '../assets/gif/giphy-13.gif',
  ]
  selectedGIF = '';
  constructor(public PaymentService:PaymentService,
    public ModalController:ModalController,public shopS:ShopService,public alertController: AlertController) {
    // here get if premium active
    this.premium_active = false;
   }
   slideOpts = {
    initialSlide: 0,
    speed: 400,
    loop:true
  };

  today_date=  new Date();
  ngOnInit() {

    this.shopS.log('','premium_modal_click');

    this.selectedGIF = this.celebrating_gif_array[Math.floor(Math.random() * this.celebrating_gif_array.length)];


    console.log('Setting.gift_options',this.shopS.shopData.Setting.gift_options)
    console.log('configDATA.gift_options',this.shopS.configDATA.gift_options)
    // this.shopS.shopData.Setting.gift_options
    
    
    // if(this.shopS.shopData.Setting.gift_options){
    //   this.text = this.shopS.shopData.Setting.gift_options.text;
    //   this.used = this.shopS.shopData.Setting.gift_options.used;
    // }else{
    //   this.text = this.shopS.configDATA.gift_options.text;
    // }

console.log(this.shopS.shopData.Setting.Premium.Statistics);
if(this.shopS.shopData.Setting.Premium.status == 'wait' || this.shopS.shopData.Setting.Premium.status == 'fail') {
this.PaymentService.calc_days_grace();
}
  }

  dismissModal(){
    this.ModalController.dismiss()
  }



  animate_scroll_img = false;
  animate_scroll_gesture = false;
  ngAfterViewInit(){
    setTimeout(() => {
      this.animate_scroll_img = true;
      setTimeout(() => {
        this.animate_scroll_gesture = true;
        setTimeout(() => {
         this.animate_scroll_gesture = false;
        }, 6000);

      }, 13500);

    }, 500);

  }



  async checkBasic() {
    const alert = await this.alertController.create({
      header: 'חזרה למסלול חינמי!',
      message: 'בלחיצה על כן תוותרו על כל האפשרויות המתקדמות של מנוי Premuim.',
      buttons: [
        {
          text: 'ביטול',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'כן עבור למסלול חינמי!',
          
          handler: () => {
            this.shopS.makeBasic();
            this.shopS.log('','premium_make_basic');

            this.ModalController.dismiss()
          }
        }
      ]
    });

    await alert.present();
  }








}
