import { CoronavirusComponent } from './../coronavirus/coronavirus.component';
import { PremiumPopoverComponent } from './../premium-popover/premium-popover.component';
import { CalendarSettingsModalComponent } from './../../components/calendar-settings-modal/calendar-settings-modal.component';
import { SettingsModalComponent } from './../components/settings-modal/settings-modal.component';
import { ViewUiComponent } from './../view-ui/view-ui.component';
import { PremiumService } from './../premium/premium-service/premium.service';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { userService } from './../../ShopService/user.service';
import { AuthService } from './../../providers/auth-service';
import { LoadingService } from './../../providers/loading.service';
import { Router } from '@angular/router';
import { ShopService } from 'src/ShopService/shop.service';
import { Component, OnInit } from '@angular/core';
import { WalletServiceService } from '../wallet/wallet-service/wallet-service.service';
// import { WalletTransferMoneyComponent } from '../wallet/wallet-transfer-money/wallet-transfer-money.component';
// import { AlertExample } from 'src/providers/alert.service';

@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.scss'],
})
export class ProfileUserComponent implements OnInit {

  constructor(
    public shopS: ShopService,
    private router: Router,
    private loadingService: LoadingService,
    private authService: AuthService,
    private us: userService,
    public alertController: AlertController,
    public modalController: ModalController,
    private popoverController: PopoverController,
    private premiumService: PremiumService,
    public walletS: WalletServiceService
    // private alertS:AlertExample  
  ) { }

  showtutorial = false;
  aShow = false;
  UserD: any;
  address: string;
  open = false;
  mySetting: any;
  jso: any = {};

  waze() {
    let  waze_link =  this.shopS.shopData.Setting.addres;
    this.shopS.openAppUrl('waze', waze_link) 

    // window.open("https://www.waze.com/live-map/directions?navigate=yes&to=" + this.shopS.shopData.Setting.latitude + ',' + this.shopS.shopData.Setting.longitude);
    

  }

  facebook() {
    this.shopS.openAppUrl('facebook', this.shopS.shopData.Setting.facebook_link, this.shopS.shopData.Setting.facebook_link) 

    // window.open(this.shopS.shopData.Setting.facebook_link);
  }

  instagram() {
    this.shopS.openAppUrl('instagram', this.shopS.shopData.Setting.instagram_link) 

    // window.open(this.shopS.shopData.Setting.instagram_link);
  }

  async open_ui_modal() {
    const modal = await this.modalController.create({
      component: ViewUiComponent,
      swipeToClose: true,
      cssClass: 'popupModal-wide',
      backdropDismiss: true,
      mode: "ios",

      // Get the top-most ion-modal
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();

  }

  // async payyy() {
  //   const modal = await this.modalController.create({
  //     component: WalletTransferMoneyComponent,
  //     componentProps: { modal_type: 'transfer' },
  //     swipeToClose: true,
  //     cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'mmodalForPayments modalGreyBG modal-half-hight',
  //     mode: "ios",
  //   });
  //   return await modal.present();

  // }



  async open_setting_modal() {
    const modal = await this.modalController.create({
      component: CalendarSettingsModalComponent,
      swipeToClose: false,
      cssClass: this.shopS.platform == 'desktop' ? '' : 'modalForPayments modalGreyBG modal-half-hight',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
      // Get the top-most ion-modal
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();

  }


  async open_setting_buiss_modal() {
    const modal = await this.modalController.create({
      component: SettingsModalComponent,
      swipeToClose: true,
      cssClass: 'modalForPayments modalGreyBG modal-half-hight',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
      // Get the top-most ion-modal
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();

  }


  async alertTorefresh(event) {
    console.log(event.detail.value);

    let result = false;
    const alert = await this.alertController.create({
      header: 'עדכון השינוי',
      message: 'בשלב זה צריך לכבות את האפליקציה ולהדליק על מנת שהשינוי יכנס לתוקף',
      buttons: [
        {
          text: 'כן הבנתי',
          handler: () => {
            this.shopS.calendar.currentDate = new Date();
            console.log("PROFILE USER ALERT");
            if (event.detail.value) {
              localStorage.setItem("calendarTimeInterval", event.detail.value);
              this.shopS.calendar.timeInterval = event.detail.value;
            }
          }
        }
      ]
    });

    await alert.present();
    return result;
  }




  async openHistoryModal(ev: any) {
    const popover = await this.popoverController.create({
      component: PremiumPopoverComponent,
      cssClass: 'my-custom-class popmodal',
      event: ev,
      translucent: true,
      componentProps: { "type": "history" }


    });
    return await popover.present();
  }


  async removeBizFromArray() {
    let result = false;
    const alert = await this.alertController.create({
      header: 'בטוחים שתרצו להתנתק?',
      message: 'תמיד יהיה ניתן להוסיף את העסק מחדש',
      cssClass: 'ttt',
      buttons: [
        {
          text: 'ביטול',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cancel Cancel: Cancel');
            result = false;
          }
        }, {
          text: 'כן התנתק',
          handler: () => {
            result = true;
            console.log('Okay: Okay');
            let index = this.us.user.bizArray.indexOf(this.shopS.shopData.Setting.BarberID)
            console.log(index);
            this.us.user.bizArray.splice(index, 1);
            console.log('this.us.user.bizArray', this.us.user.bizArray)
            this.us.addBizArray(this.us.user.bizArray);


            const indexx = this.shopS.businessArray.findIndex(x => x.Setting.BarberID === this.shopS.shopData.Setting.BarberID);
            const f = this.shopS.businessArray.splice(indexx, 1);
            console.log('f', f);

            if (this.us.user.bizArray.length == 0) {
              this.shopS.resetShopData();
              this.shopS.buttonHide = true;
              this.us.updateShopId('');
            } else {
              this.shopS.shopData = this.shopS.businessArray[0];
              this.us.updateShopId(this.shopS.businessArray[0].Setting.BarberID);
            }

            this.router.navigate(['tabs/home'], { replaceUrl: true });
          }
        }
      ]
    });

    await alert.present();
    return result;
  }



  // removeBizFromArray(){
  //   console.log('remove biz from array', this.shopS.shopData.Setting.BarberID);
  //   console.log(' biz  array', this.us.user.bizArray);

  // }



  inputchange(event, kind) {
    // console.log('kind',kind);
    if (kind == 'color') {
      this.jso[kind] = event;
    } else {
      // console.log('event.target.value',event.target.value);
      this.jso[kind] = event.target.value;
    }
    //  console.log('jso[kind]',this.jso[kind]);
    //  console.log('jso',this.jso);
    this.shopS.updateStr('/Setting/', this.jso);
  }
  openWaze(addres) {
    window.open('https://waze.com/ul?q="' + addres + '"')
  }
  SETdeletePost(post, bol) {
    post.delete = bol;
    // console.log(this.shopS.listPosts);
  }

  //   async presentModal() {
  //     const modal = await this.modalController.create({
  //       component: CoronavirusComponent,

  //     });

  //  await modal.present();
  //     const { data } = await modal.onWillDismiss();


  //   }

  show(a, b) {
    console.log(a, b)
  }


  changeAdminEditMode() {
    console.log('.AdminEditMode--before', this.shopS.AdminEditMode);
    this.shopS.AdminEditMode = !this.shopS.AdminEditMode;
    // console.log('.AdminEditMode',this.AdminEditMode);
    // .equalTo('30-04-2020') //.child('18-05-2020')//.orderByChild('meetDate')
    //this.shopS.checkReminder()

  }



  GPREMIUM() {
    //GO TO SERVER

    // this.shopS.shopData.Setting.Premium=this.shopS.Premium.exportPremium();
    this.shopS.makePremium();

  }
  // GBasic() {
  //   //GO TO SERVER

  //   // this.shopS.shopData.Setting.Premium=this.shopS.Premium.exportPremium();
  //   this.shopS.makeBasic();

  // }
  deletePost(post) {

    // console.log(this.shopS.listPosts);
    // console.log('post: ',post);
    this.shopS.deletePost(post.key);
    //  delete this.shopS.listPosts[post.key]; 
    this.shopS.deleteJsonFromArray(this.shopS.listPosts, post.key, 'key')
  }
  setColor(type: string, color: string) {
    console.log('type', type);
    console.log('color', color);
  }
  //listPosts = [];
  ngOnInit() {

    this.UserD = JSON.parse(localStorage.getItem('userdata'));
    if (this.UserD.email == "team@webc.co.il") {
      this.aShow = true;
    }
    this.shopS.Webc = this.UserD.key;
    // document.addEventListener('backbutton', () => {
    //   console.log('bussnies profile - - - - backbutton action!');

    //   if(this.shopS.AdminFLAG == true){
    //     this.goBackAdmin();
    //   }else{
    //     this.goBack();
    //   }


    //     }, false);

    this.shopS.AdminEditMode = true;

    //console.log(this.shopS.shopData);
    this.mySetting = JSON.parse(JSON.stringify(this.shopS.shopData.Setting));
    this.shopS.listPosts = this.shopS.json2array(this.shopS.shopData.Posts);
    ////////////////////////////// SET TIMEOUT REFRESH
    ////////////////////////////// SET TIMEOUT REFRESH
    ////////////////////////////// SET TIMEOUT REFRESH
    ////////////////////////////// SET TIMEOUT REFRESH
    ////////////////////////////// SET TIMEOUT REFRESH
    ////////////////////////////// SET TIMEOUT REFRESH
    ////////////////////////////// SET TIMEOUT REFRESH
    setTimeout(() => {
      this.mySetting = JSON.parse(JSON.stringify(this.shopS.shopData.Setting));
      console.log(this.mySetting, "this.mySetting")
      if (this.mySetting.buisStatus == "new") {



      }
      console.log(this.mySetting, "mySetting")
    }, 8000);
    ////////////////////////////// SET TIMEOUT REFRESH
    ////////////////////////////// SET TIMEOUT REFRESH
    ////////////////////////////// SET TIMEOUT REFRESH
    ////////////////////////////// SET TIMEOUT REFRESH
    ////////////////////////////// SET TIMEOUT REFRESH
    ////////////////////////////// SET TIMEOUT REFRESH
    ////////////////////////////// SET TIMEOUT REFRESH


    const today = new Date();
    let datee = this.shopS.shopData.WorkDays[today.getDay()];
    // console.log('datee',datee);
    let hour = this.hours_with_leading_zeros(today) + ':' + this.minutes_with_leading_zeros(today);

    console.log('hour', hour);

    let result = false;
    if (datee.timeStart < hour && datee.timeEnd > hour) {

      result = true;
    }


    // console.log('result',result);
    this.open = result;
  }



  goTogoodModeP() {
    this.router.navigate(['products']);

  }

  goTogoodModeF() {
    this.router.navigate(['function']);

  }

  currentImg: any = {
    cover: '',
    logo: '',
    post: ''
  };
  currentImg2: any;
  imageChanged(e) {
    this.currentImg = e;
    console.log('e', e);
  }

  goBack() {
    console.log('Go');
    this.router.navigate(['tabs/home']);
  }
  goBackAdmin() {
    console.log('Go');
    this.router.navigate(['tabsAdmin/calendar']);
  }


  marginWorkHour(workday) {
    // console.log('workdat',workday);
    if (workday.dayName == "ו׳") {
      return 45;
    } else {
      return 40;
    }
  }

  uploadThatImg(type) {
    console.log('uploadThatImg', this.currentImg, this.typeSetting);
    this.loadingService.presentforever();
    let result = this.shopS.uploadImg(this.currentImg[type], '/Setting/', type).then(resullt => {
      this.loadingService.dismiss();
    }).catch((e) => {
      this.loadingService.dismiss();
    });
    console.log('result:', result);
  }

  dissmisThatImage(type) {
    if (type == 'logo') {
      this.mySetting.logo = this.shopS.shopData.Setting.logo;
      this.shopS.UploadFlags.logo = false;
      (document.getElementById('logoUpload') as HTMLInputElement).value = '';

    } else if (type == 'cover') {
      this.mySetting.cover = this.shopS.shopData.Setting.cover;
      this.shopS.UploadFlags.cover = false;
      (document.getElementById('coverUpload') as HTMLInputElement).value = '';


    } else if (type == 'CustomerBaner') {
      this.mySetting.CustomerBaner = this.shopS.shopData.Setting.CustomerBaner;
      this.shopS.UploadFlags.CustomerBaner = false;
      (document.getElementById('CustomerBaner') as HTMLInputElement).value = '';


    }
  }


  CustomerBaner_show(banner) {
    console.log(banner);
    this.open_ui_modal()

  }

  uploadThatPost() {
    console.log('.currentImg:', this.currentImg);
    console.log('.typeSetting:', this.typeSetting);
    this.loadingService.presentforever();

    let result = this.shopS.uploadImg(this.currentImg[this.typeSetting], '/Setting/', this.typeSetting).then(data => {
      console.log('11111111-----data:', data);

    });
    console.log('result:', result);

  }


  logout() {
    this.authService.logout();
  }

  public imagePath;
  imgURL: any;
  public message: string;
  typeSetting: any;

  preview(event, where) {
    this.typeSetting = where;
    this.currentImg[where] = event;
    console.log('this.currentImg:', this.currentImg);
    var filess = event.srcElement.files;
    // console.log('filess:',filess);

    if (filess.length === 0)
      return;

    var mimeType = filess[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(filess[0]);
    reader.onload = (_event) => {
      console.log('reader.result', reader.result);
      console.log('this.mySetting', this.mySetting);
      this.mySetting[where] = reader.result;
      this.shopS.UploadFlags[where] = true;
    }
  }
  checkCloseDay(wd) {
    if (wd.timeStart == '00:00' && wd.timeEnd == '00:00') {
      return true;
    } else {
      return false;
    }
  }

  // preview(event: { srcElement: { files: any; }; },where: string | number) {
  //   this.typeSetting = where;
  //   this.currentImg = event;
  //   console.log('event:',event);
  //   var filess = event.srcElement.files ;
  //   console.log('filess:',filess);

  //   if (filess.length === 0)
  //     return;

  //   var mimeType = filess[0].type;
  //   if (mimeType.match(/image\/*/) == null) {
  //     this.message = "Only images are supported.";
  //     return;
  //   }

  //   var reader = new FileReader();
  //  // Ensure it's an image
  //  var file = filess[0];
  // let self = this;
  //     if(file.type.match(/image.*/)) {
  //       console.log('An image has been loaded',file);

  //       // Load the image
  //       var reader = new FileReader();
  //       console.log('reader',reader);

  //       reader.onload = function (readerEvent:any) {
  //           var image:any = new Image();
  //           console.log('image',image);

  //           image.onload = function () {
  //           //  console.log('imageEvent',imageEvent);

  //               // Resize the image
  //               var canvas = document.createElement('canvas'),
  //                   max_size = 600,// TODO : pull max size from a site config
  //                   width = image.width,
  //                   height = image.height;
  //               if (width > height) {
  //                   if (width > max_size) {
  //                       height *= max_size / width;
  //                       width = max_size;
  //                   }
  //               } else {
  //                   if (height > max_size) {
  //                       width *= max_size / height;
  //                       height = max_size;
  //                   }
  //               }
  //               canvas.width = width;
  //               canvas.height = height;
  //               canvas.getContext('2d').drawImage(image, 0, 0, width, height);
  //               var dataUrl = canvas.toDataURL('image/jpeg');
  //               var resizedImage = self.dataURLToBlob(dataUrl);
  //               console.log('resizedImage',resizedImage);
  //               console.log('dataUrl',dataUrl);
  //               self.mySetting[where] = dataUrl; 
  //               self.shopS.UploadFlags[where] = true;
  //               console.log('before --- self.currentImg.target.files[0]',self.currentImg.target.files[0])
  //               let newImg  =  resizedImage;
  //               let oldImg = self.currentImg.target.files[0];
  //               self.currentImg2 =  new File([resizedImage], self.currentImg.target.files[0].name);
  //              console.log('oldImg --- newImg',oldImg,newImg)

  //               console.log('after --- self.currentImg2',self.currentImg2)

  //               // $.event.trigger({
  //               //     type: "imageResized",
  //               //     blob: resizedImage,
  //               //     url: dataUrl
  //               // });
  //           }
  //           image.src = readerEvent.target.result;
  //       }
  //       reader.readAsDataURL(file);
  //       console.log('reader.result',reader.result);
  //       console.log('this.mySetting',this.mySetting);
  //    //   this.mySetting[where] = reader.result; 
  // //      this.shopS.UploadFlags[where] = true;

  //   }


  // reader.readAsDataURL(filess[0]); 
  // reader.onload = (_event) => { 
  //   console.log('reader.result',reader.result);
  //   console.log('this.mySetting',this.mySetting);
  //   this.mySetting[where] = reader.result; 
  //   this.shopS.UploadFlags[where] = true;
  // }
  //}
  settingUpdate = {};


  settingChange(event, kind) {
    // console.log('event',event);
    // console.log('kind',kind);

    // console.log('this.shopS.shopData=before',this.shopS.shopData);
    // this.shopS.shopData[kind] = event.detail.value;
    // console.log('this.shopS.shopData=after',this.shopS.shopData);

    this.settingUpdate[kind] = event.detail.value;
    this.shopS.updateStr('/Setting/', this.settingUpdate);


  }
  // TimecalChange(event){
  //   this.alertTorefresh(event);


  // }

  // calChange(event, kind) {
  //   if (event.detail.value == "week") {
  //     this.shopS.calendar.currentDate = new Date();


  //   }
  //   localStorage.setItem("calendarMode", event.detail.value);
  //   this.shopS.calendar.mode = event.detail.value;
  // }

  BizDetail = false;
  toggleBizDetail($event) {
    event.stopPropagation();
    if (this.shopS.AdminFLAG) {
      this.BizDetail = !this.BizDetail;
    }
  }

  asd() {
    let arr = document.getElementsByClassName('scroll-y');
    console.log(arr);

  }

  toggleModal(event) {
    event.stopPropagation();
    if (this.shopS.AdminFLAG) {
      this.shopS.openingHoursmodalFLAG = !this.shopS.openingHoursmodalFLAG;
      console.log('toggleModal=======openingHoursmodalFLAG', this.shopS.openingHoursmodalFLAG);
    }

  }

  dataURLToBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      var raw = parts[1];

      return new Blob([raw], { type: contentType });
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw: any = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }


  minutes_with_leading_zeros(dt) {
    return (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
  }
  hours_with_leading_zeros(dt) {
    // console.log('hours_with_leading_zeros------dt',dt);
    return (dt.getHours() < 10 ? '0' : '') + dt.getHours();
  }
}
