import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { Http, Response } from '@angular/http';
import { ShopService } from 'src/ShopService/shop.service';
import { meetService } from 'src/ShopService/meetS.service';
import { AuthService } from 'src/providers/auth-service';
import { userService } from 'src/ShopService/user.service';
import { AlertController, ModalController } from '@ionic/angular';
import { ToastService } from 'src/providers/toast-service';
import { filter } from 'rxjs/operators';
import { LogService } from '../log.service';
import * as moment from 'moment-timezone';
import { AlertExample } from 'src/providers/alert.service';
import { PhoneLoginComponent } from '../phone-login/phone-login.component';
import { Subscription } from 'rxjs';
import { WalletServiceService } from '../wallet/wallet-service/wallet-service.service';
// import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';


@Component({
  selector: 'app-buis-profile',
  templateUrl: './buis-profile.component.html',
  styleUrls: ['./buis-profile.component.scss'],
})
export class BuisProfileComponent implements OnInit, OnDestroy {
  shopData;
  businessExist = false;
  open = false;
  show_meetingType = false;
  connectSub;
  hideBtn = true;
  authSub: Subscription;


  constructor(
    private meetS: meetService,
    private router: Router,
    public authService: AuthService,
    private route: ActivatedRoute,
    private _http: Http,
    private shopS: ShopService,
    private us: userService,
    private modalController: ModalController,
    public alertController: AlertController,
    private toast: ToastService,
    private ms: meetService,
    public walletS: WalletServiceService,
    // private socialSharing: SocialSharing
  ) {

    this.route.paramMap.subscribe((params: any) => {
      console.log('params!!!!!!', params.params.id);
      console.log('current_biz!!!!!', this.shopS.current_biz)
      if (this.shopS.current_biz) {
        this.shopData = {
          BarberId: this.shopS.current_biz.BarberID,
          Setting: this.shopS.current_biz
        }
      }
      this._http.post(this.shopS.serverDev + '/public_buis', params.params.id).subscribe(val => {
        console.log(val);
        if (val['_body'] == '') {
          this.goBack();
          return;
        }
        let data = JSON.parse(val['_body']);
        console.log(data);
        console.log(data.Setting);
        if (data.Setting) {
          // console.log("val['_body']",val['_body']);

          this.shopData = data;
          this.shopData.BarberId = this.shopData.Setting.BarberID;
          this.shopS.loaderoff = false;

          this.meetingType_show();

          // if (this.shopData.MeetingType.length > 0) {
          //   this.meetingType_show(this.shopData.MeetingType);
          // } else {
          //   let MeetingTypearr = this.shopS.json2array(this.shopData.MeetingType);
          //   this.meetingType_show(MeetingTypearr);
          // }

          // this.checkConfirm();
          console.log('shopData', this.shopData);
          // console.log('shopData', this.shopData);
          // console.log(this.shopS.businessArray);
          // console.log(this.us.user.bizArray);
          console.log("this.us.user",this.us.user,this.shopData.BarberId);
          // setTimeout(() => {
          // console.log(this.us.user.bizArray);

          // }, 4000);

          this.initBtn();

          // if (this.us.user.needConfirmBuis && this.us.user.needConfirmBuis.includes(this.shopData.BarberId)) {
          // if (this.us.user.needConfirmBuis && this.us.user.needConfirmBuis.hasOwnProperty(this.shopData.BarberId)) {
          //   this.shopData.need_confirm = true;
          // }

          this.checkOpen();

        } else {
          console.log("NO BUIS FOUND");
          // this.router.navigate(['login'], { replaceUrl: true });
          this.goBack();
        }
      }, err => {
        console.log("NO BUIS FOUNDDDDDD");
        // this.router.navigate(['login'], { replaceUrl: true });
        this.goBack();

        console.log(err);

      });


    });

  }
  // confirmMsg = '';
  // pendingConfirm = false;
  // checkConfirm() {
  //   console.log(this.shopData);

  //   if (this.shopData.confirm_users) {
  //     this.confirmMsg = 'דורש אישור של בעל העסק';
  //   }
  //   if (this.us.user.needConfirmBuis && this.us.user.needConfirmBuis.includes(this.shopData.BarberId)) {
  //     this.pendingConfirm = true;
  //     this.confirmMsg = 'בקשה נשלחה, ממתין לאישור..';
  //   }
  // }

  checkBusinessExist() {
    if (this.us.user.bizArray && this.us.user.bizArray.find(busi => busi == this.shopData.BarberId)) {
      this.businessExist = true;
    } else {
      this.businessExist = false;
    }
    console.log('this.businessExist', this.businessExist);
  }

  checkNeedConfirm() {
    if (this.us.user.needConfirmBuis && this.us.user.needConfirmBuis.hasOwnProperty(this.shopData.BarberId)) {
      this.shopData.need_confirm = true;
    } else {
      this.shopData.need_confirm = false;
    }
    console.log('this.shopData.need_confirm', this.shopData.need_confirm);
  }


  initBtn() {
    this.authSub = this.authService.auth_state.subscribe(state => {
      if (state) {
        console.log(state);
        console.log(this.hideBtn);

        if (state == 'auth') {
          this.connectSub = this.us.userLoaded.subscribe(loaded => {
            console.log(loaded);
            if (loaded) {
              console.log(this.us.user);
              this.checkBusinessExist();

              this.checkNeedConfirm();
              this.hideBtn = false;
              if (this.connectSub) {
                this.connectSub.unsubscribe();
              }
            }
          });
        } else {
          this.hideBtn = false;
        }
        if (this.authSub) {

          this.authSub.unsubscribe();
        }
      }
    });






    ////////

    // if (this.authService.auth_state.getValue() == 'auth') {
    //   this.connectSub = this.us.userLoaded.subscribe(loaded => {
    //     console.log(loaded);
    //     if (loaded) {
    //       console.log(this.us.user);
    //       if (this.us.user.bizArray && this.us.user.bizArray.find(busi => busi == this.shopData.BarberId)) {
    //         this.businessExist = true;
    //         console.log(this.businessExist);
    //       }

    //       if (this.us.user.needConfirmBuis && this.us.user.needConfirmBuis.hasOwnProperty(this.shopData.BarberId)) {
    //         console.log("TYE");
    //         this.shopData.need_confirm = true;
    //       }
    //       this.hideBtn = false;
    //       if (this.connectSub) {
    //         this.connectSub.unsubscribe();
    //       }
    //     }
    //   });
    // } else {
    //   this.hideBtn = false;
    // }
  }


  waze() {
    console.log('waze');

    // var windowReference:any = window.open();

    // windowReference.location = "https://www.waze.com/live-map/directions?navigate=yes&to=" + this.shopS.shopData.Setting.latitude + ',' + this.shopS.shopData.Setting.longitude+'';
    // this.openTab("https://www.waze.com/live-map/directions?navigate=yes&to=" + this.shopS.shopData.Setting.latitude + ',' + this.shopS.shopData.Setting.longitude+'');
    // let waze_link = this.shopS.shopData.Setting.latitude + '%2C' + this.shopS.shopData.Setting.longitude;
    let waze_link = this.shopData.Setting.addres;
    this.shopS.openAppUrl('waze', waze_link);

    // 45.6906304,-120.810983&
    // this.inAppBrowser.create(waze_link,'_system');

    // https://www.waze.com/ul?ll=32.0143684%2C34.7502401&navigate=yes&zoom=12
    //  window.open( waze_link, "_system");


    // var waze = window.document.getElementById("wazeid");
    // waze.setAttribute('href',"https://www.waze.com/live-map/directions?navigate=yes&to=" + this.shopS.shopData.Setting.latitude + ',' + this.shopS.shopData.Setting.longitude+'');
    // waze.click();
    // window.open("https://www.waze.com/live-map/directions?navigate=yes&to=" + this.shopS.shopData.Setting.latitude + ',' + this.shopS.shopData.Setting.longitude);
  }

  facebook() {
    // this.inAppBrowser.create(this.shopS.shopData.Setting.facebook_link,'_system');
    // this.shopS.openAppUrl('facebook', this.shopS.shopData.Setting.facebook_link, this.shopS.shopData.Setting.facebook_link);
    this.shopS.openAppUrl('facebook', this.shopData.Setting.facebook_link, this.shopData.Setting.facebook_link)
    // window.open(this.shopS.shopData.Setting.facebook_link, "_blank",'location=yes');
  }

  instagram() {
    // this.shopS.openAppUrl('instagram', this.shopS.shopData.Setting.instagram_link)
    this.shopS.openAppUrl('instagram', this.shopData.Setting.instagram_link)

    // this.inAppBrowser.create(this.shopS.shopData.Setting.facebook_link,'_system');

    // window.open(this.shopS.shopData.Setting.instagram_link, "_blank");
  }




  meetingType_show() {
    for (var key in this.shopData.MeetingType) {
      let element = this.shopData.MeetingType[key];
      element.active = false;
      if (!element.hidden) {
        this.show_meetingType = true;
      }
    }
    // MeetingTypearr.forEach(meetType => {
    //   if (!meetType.hidden) {
    //     this.show_meetingType = true;
    //   }
    // })
    console.log('show_meetingType', this.show_meetingType);
  }


  checkOpen() {
    var busi_timezone = moment.tz("Asia/Jerusalem");
    let busi_utcOffset = moment.parseZone(busi_timezone).utcOffset();
    let my_offset = new Date().getTimezoneOffset();

    let now = new Date();
    let fixed_now = new Date(now.getTime() + (my_offset * 60000) + (busi_utcOffset * 60000));
    let hour = fixed_now.getHours();
    let min = fixed_now.getMinutes();

    let timeStart = this.shopData.WorkDays[fixed_now.getDay()].timeStart.split(':');
    let timeEnd = this.shopData.WorkDays[fixed_now.getDay()].timeEnd.split(':');

    let timeStart_hour = parseInt(timeStart[0]);
    let timeStart_min = parseInt(timeStart[1]);

    let timeEnd_hour = parseInt(timeEnd[0]);
    let timeEnd_min = parseInt(timeEnd[1]);

    if (hour > timeStart_hour && hour < timeEnd_hour) {
      this.open = true;
    } else if (hour == timeStart_hour) {
      if (min >= timeStart_min) {
        this.open = true;
      } else {
        this.open = false;
      }
    } else if (hour == timeEnd_hour) {
      if (min <= timeEnd_min) {
        this.open = true;
      } else {
        this.open = false;
      }
    } else {
      this.open = false;
    }
  }

  async removeBizFromArray(ev) {
    let result = false;
    const alert = await this.alertController.create({
      header: 'בטוחים שתרצו להתנתק?',
      message: 'תמיד יהיה ניתן להוסיף את העסק מחדש',
      cssClass: 'ttt',
      buttons: [
        {
          text: 'ביטול',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            ev.stopPropagation();
            console.log('Cancel Cancel: Cancel');
            result = false;
          }
        }, {
          text: 'כן התנתק',
          handler: () => {
            ev.stopPropagation();
            result = true;
            console.log('Okay: Okay');
            let index = this.us.user.bizArray.indexOf(this.shopData.BarberId)
            console.log(index);
            this.us.user.bizArray.splice(index, 1);
            console.log('this.us.user.bizArray', this.us.user.bizArray)
            console.log(this.shopS.businessArray);
            console.log(this.us.user);

            this.us.addBizArray(this.us.user.bizArray);

            if (this.shopData.need_confirm) {
              if (this.us.user.needConfirmBuis[this.shopData.BarberId]) {
                delete this.us.user.needConfirmBuis[this.shopData.BarberId];
                this.us.addBizConfirm(this.us.user.needConfirmBuis);
              }
            }

            // const indexx = this.shopS.businessArray.findIndex(x => x.Setting.BarberID === this.shopData.BarberId);
            // const f = this.shopS.businessArray.splice(indexx, 1);
            // console.log('f', f);

            // if (this.us.user.bizArray.length == 0) {
            //   this.shopS.resetShopData();
            //   this.shopS.buttonHide = true;
            //   this.us.updateShopId('');
            // } else {
            //   // this.shopData = this.shopS.businessArray[0];
            //   // this.us.updateShopId(this.shopS.businessArray[0].Setting.BarberID);
            //   console.log("1");

            //   this.shopS.changeBiz(this.us.user.bizArray[0]);
            // }

            // this.router.navigate(['tabs/home'], { replaceUrl: true });
            this.goBack();
          }
        }
      ]
    });

    await alert.present();
    return result;
  }

  async openLoginModal(): Promise<HTMLIonModalElement> {
    this.shopS.external_login = true;
    this.shopS.current_biz = this.shopData;
    // localStorage.setItem('welcome_screen', 'true');
    // this.shopS.welcome_screen.next('true');

    const modal = await this.modalController.create({
      component: PhoneLoginComponent,
      swipeToClose: true,
      cssClass: 'modalLookLikeNativ',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
    });

    await modal.present();

    const data = await modal.onDidDismiss();
    // modal.onDidDismiss().then(res => {
    //   console.log(res)
    //   // if (res.data) {

    //   // }
    // })
    console.log(data);
    // return;
    console.log(data, this.authService.auth_state.getValue());

    if (data && this.authService.auth_state.getValue() == 'auth') {
      console.log("ASDASDASD");
      this.checkBusinessExist();
      this.checkNeedConfirm();
      this.buttonClick();
      // this.submitBusinessCode();
    }

    return modal;

  }

  buttonClick() {
    if (this.authService.auth_state.getValue() == 'auth') {
      if (this.us.user.fname) {
        if (!this.shopData.need_confirm) {
          if (!this.businessExist) {
            this.submitBusinessCode();
          } else {
            this.orderMeet();
          }
        }
      } else {
        this.shopS.noUserModal = true;
        this.openLoginModal();
      }
    } else {
      // this.alertService.flexAlert('הרשמה', '', 'על מנת שנוכל להתחבר לעסק עלייך ליצור תחילה משתמש', 'ביטול', 'פתיחת משתמש', res => {
      this.openLoginModal();
      // if (res) {
      // this.router.navigate(['login'], { replaceUrl: true });
      // }
      // });
    }
  }
  selectedTypes = [];

  chooseMeetType(meetType) {
    console.log(meetType);
    meetType.active = !meetType.active;

    this.selectedTypes = [];
    let meetTime = 0;
    let meetName = '';
    let meetPrice = 0;
    var meetFor = JSON.parse(JSON.stringify(meetType));

    for (var key in this.shopData.MeetingType) {
      let element = this.shopData.MeetingType[key];
      if (element.active == true) {
        this.selectedTypes.push(element);
        meetTime = meetTime + parseInt(element.meetTime);
        meetName = meetName + " " + element.name;
        meetPrice = meetPrice + parseInt(element.price);
      }
    }

    if (this.selectedTypes.length) {
      var newmeet = this.createNewMeet(meetFor, meetTime.toString(), meetName, meetPrice);
      this.shopS.meetForBuis.importMeetType(newmeet);
      this.ms.setMeetType(newmeet);
      this.shopS.currentMeet.meetType = newmeet;
      // this.shopS.changeButtonState(2);
    }

  }

  createNewMeet(meet, time, name, price) {
    var meet2 = meet;
    meet2.meetTime = time;
    // this.Mtime = time;
    meet2.name = name;
    meet2.price = price;
    meet2.approve = true;
    meet2.flow = false;

    return meet2;
  }

  submitBusinessCode() {

    var fullname = this.us.user.fname + " " + this.us.user.lname;
    // console.log("------ >>>>> ",this.businessCodeForm.value,this.us.user);
    // console.log("*****>",this.freeShop)

    // if (this.shopS.shopData) {

    this.updateUser();

    if (this.shopData.Setting.confirm_users) {
      // if (this.us.user.needConfirmBuis) {
      //   this.us.user.needConfirmBuis.push(this.shopData.BarberId);
      // } else {
      //   this.us.user.needConfirmBuis = [this.shopData.BarberId];
      // }
      if (!this.us.user.needConfirmBuis) {
        this.us.user.needConfirmBuis = {};
      }
      this.us.user.needConfirmBuis[this.shopData.BarberId] = this.shopData.BarberId;
      // this.checkConfirm();
      this.shopData.need_confirm = true;
      this.us.addBizConfirm(this.us.user.needConfirmBuis).then(() => {
      });
    }
    // else {


    let found = false;
    if (this.us.user.bizArray) {
      this.us.user.bizArray.forEach(element => {
        if (element == this.shopData.BarberId) {
          found = true;
        }
      })

      if (found == false) {
        this.us.user.bizArray.push(this.shopData.BarberId);
        this.us.addBizArray(this.us.user.bizArray).then(res => {
        })
      }

    } else {
      this.us.user.bizArray = [this.shopData.BarberId];
      this.us.addBizArray(this.us.user.bizArray).then(res => {
      })
    }

    // this.us.addBizArray(this.us.user.bizArray).then(() => {
    // })

    if (this.shopS.businessArray) {
      let foundBusi = this.shopS.businessArray.find(bus => bus.BarberID == this.shopData.BarberId);
      if (!foundBusi) {
        this.shopS.businessArray.push(this.shopData);
        this.shopS.businessArray = this.shopS.businessArray.sort(this.compare);
      }
    } else {
      this.shopS.businessArray = [this.shopData];
    }

    this.businessExist = true;



    if (this.shopS.external_login) {
      if (this.shopData.Setting.confirm_users) {
        console.log('2');

        this.shopS.changeBiz(this.shopData.BarberId);
        // this.router.navigate(['tabs/home'], { replaceUrl: true });
      } else {
        this.orderMeet();
      }
    } else {
      if (!this.shopS.shopData.BarberId) {
        console.log("3");

        this.shopS.changeBiz(this.shopData.BarberId);
      }
    }

    if (this.shopData.Setting.confirm_users) {
      this.shopS.addNotificationBell('new_client_need_confirm', 'business', {},this.shopData.BarberId);
    } else {
      this.shopS.addNotificationBell('new_client', 'business', {},this.shopData.BarberId);
    }

    this.meetS.alertNotiToBarber({ BarberID: this.shopData.BarberId, userFullName: fullname, BizName: this.shopData.Setting.name }, 'newClientToBiz', false);
    // }

    this.modalController.dismiss(
      {
        'dismissed': "finish"
      }
    );
    // }
    // this.shopS.updateUserToBarber(this.us.user,this.us.user.key)
  }

  updateUser() {
    const currUser = this.us.getUserObj();
    console.log('this.shopData updateUser', this.shopData);
    console.log('currUser', currUser);
    if (this.shopData.BarberId) {
      console.log('currUser', currUser);

      var BarberID = this.shopData.BarberId;
    } else {
      return;
    }
    this.shopS.configDATA.default.userProfile
    https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/male_avatar_323b.svg



    let businessCodes = [];
    console.log('currUser.BarberID-before', currUser.BarberID);

    businessCodes.push(BarberID);
    let user = this.us.user;
    if (this.shopData.Setting.confirm_users) {
      user.confirm_status = 'pending';
    } else {
      this.us.updateShopId(BarberID).then((result) => {
        console.log(result);
        this.toast.showToast('העסק נוסף בהצלחה!');
        this.shopS.turnOnConfetti(3000);
        // this.businessExist = true;
        // this.shopS.changeButtonState(7);

        console.log("login - logout")
        var getUserDetalisByUid = this.us.getUserDetalisByUid(this.us.user.key).snapshotChanges().subscribe((res) => {
          this.us.updateBarberID(businessCodes);
          // this.authService.logOutForLogin();
          getUserDetalisByUid.unsubscribe();
        });
      });
    }
    console.log("!!!!!!!!!!", BarberID, user, this.us.user.key);

    this.shopS.updateUserNewToBarber(BarberID, user, this.us.user.key);


    this.shopS.log(this.shopS.us.key, 'business_add_finish');


  }

  compare(a, b) {
    if (a.Setting && b.Setting) {
      const bandA = a.Setting.name.toLowerCase();
      const bandB = b.Setting.name.toLowerCase();

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    }
  }

  // changeBiz(id?) {
  //   // this.shopS.CloseLiveSub(this.shopS.shopData.Setting.BarberID);
  //   // this.shopS.openLiveSub(this.shopData.BarberId, 'user');
  //   let buis_id = id ? id : this.shopData.BarberId;
  //   this.shopS.closeSettingSub();
  //   this.shopS.openSettingSub(buis_id);
  //   // this.shopS.shopData = this.shopData;
  //   this.shopS.getBusinessDataForUser(buis_id);
  //   this.us.user.BarberID = buis_id;
  //   this.shopS.updateStrFull('/Users/' + this.us.user.key + '/', { BarberID: buis_id });
  //   this.shopS.shopID = buis_id;
  // }

  async orderMeet() {
    this.shopS.changeButtonState(0);
    if (this.shopS.shopData.Setting.BarberID != this.shopData.BarberId) {
      console.log("4");

      this.shopS.changeBiz(this.shopData.BarberId);
    }
    if (this.selectedTypes.length) {
      this.shopS.userOrder.leftIcon = 'fa-question';
      this.shopS.userOrder.opacity = 0.5;
      this.shopS.userOrder.txt = 'מתי נוח לך';
      this.shopS.userOrder.prece = '70%';
      this.shopS.userOrder.stage = 2;
      this.router.navigate(['tabs/AppointmentPage'], { replaceUrl: true });
    } else {
      this.shopS.userOrder.leftIcon = 'fa-question';
      this.shopS.userOrder.opacity = 0.5;
      this.shopS.userOrder.txt = 'מה בא לך';
      this.shopS.userOrder.prece = '35%';
      this.shopS.userOrder.stage = 1;
      this.router.navigate(['tabs/aboutClient'], { replaceUrl: true });
    }
  }

  checkHidden(hidden) {
    if (hidden && !this.shopS.AdminFLAG) {
      return true;
    } else {
      return false;
    }
  }

  checkCloseDay(wd) {
    if (wd.timeStart == '00:00' && wd.timeEnd == '00:00') {
      return true;
    } else {
      return false;
    }
  }

  goBack() {
    if (this.authService.auth_state.getValue() == 'auth') {
      this.router.navigate([this.shopS.previousUrl ? this.shopS.previousUrl : '/tabs/home'], { replaceUrl: true })
    } else {
      this.router.navigate(['login'], { replaceUrl: true });
    }
  }


  marginWorkHour(workday) {
    // console.log('workdat',workday);
    if (workday.dayName == "ו׳") {
      return 45;
    } else {
      return 40;
    }
  }


  ngOnInit() {
    this.shopS.buttonHide = false;
    console.log("INIT B PROF", this.shopS.userOrder.stage);
    console.log(this.authService.auth_state.getValue());
    let type = localStorage.getItem('type');
    if (type == 'admin') {
      this.authService.changeToUser();
    }
  }

  ngOnDestroy(): void {
    if (this.connectSub) {
      this.connectSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }
}
