import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';

@Injectable()


export class ToastService {

  toast: any;

  constructor(
    private toastController: ToastController,
    private shopS:ShopService
  ) {}

  async showToast(message) {
    // this.shopS.confettiFLAG = false;
    this.shopS.turnOnConfetti(2500);

    // setTimeout(() => {
    // // this.shopS.confettiFLAG = true;
    // this.shopS.turnOnConfetti();
    // }, 2500);
    this.toast = await this.toastController.create({
      message: `${message}`,
      position: 'top',
      color:'success',
      cssClass: 'toastCustomWebc',
      duration: 2000,
      mode: 'ios'
    });
    return await this.toast.present();
    // toast.present();
  }

  async showToast2(message) {
    // this.shopS.confettiFLAG = false;
    // setTimeout(() => {
    // // this.shopS.confettiFLAG = true;
    // this.shopS.turnOnConfetti();
    // }, 2500);
    this.toast = await this.toastController.create({
      message: `${message}`,
      position: 'top',
      color:'success',
      cssClass: 'toastCustomWebc',
      duration: 2000,
      mode: 'ios'
    });
    return await this.toast.present();
    // toast.present();
  }

  async showTostDanger(message) {
    console.log('danger',message);
    const toast = await this.toastController.create({
      message: `${message}`,
      position: 'top',
      cssClass: 'toastCustomWebc',
      duration: 2000,
      mode: 'ios',
      color:'danger'
    });
    toast.present();
  }

  async torOk() {
    const toast = await this.toastController.create({
      message: 'התור זומן בהצלחה',
      position: 'top',
      duration: 2000,
      mode: 'ios',
      color:'success'
    });
    toast.present();
  
  }

  async DeletTorok() {
    const toast = await this.toastController.create({
      message: 'התור נמחק בהצלחה',
      duration: 2000,
      position: 'top',
      color:'success'
    });
    toast.present();
  
  }
  async DeletNotOK() {
    const toast = await this.toastController.create({
      message: 'היתה בעיה במחיקת התור',
      duration: 2000,
      position: 'top',
      color:'danger'
    });
    toast.present();
  
  }

  async presentDarkToastByText(x) {


    const toast = await this.toastController.create({
        message: x,
        color: 'dark',
        duration: 2000
    });
    toast.present();
    this.shopS.shopMessage.ok = false;
    this.shopS.shopMessage.message = "";


}

async removeMeet() {
  const toast = await this.toastController.create({
      header: 'התור בוטל בהצלחה',
      message: 'Click to Close',
      position: 'top',
      buttons: [
          {
              side: 'start',
              icon: 'star',
              text: 'Favorite',
              handler: () => {
                  console.log('Favorite clicked');
              }
          }, {
              text: 'Done',
              role: 'cancel',
              handler: () => {
                  console.log('Cancel clicked');
              }
          }
      ]
  });
  toast.present();
}




}


