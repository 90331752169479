import { Component, Injectable, OnInit } from '@angular/core';
// import { AboutPage } from '../about/about';
import { ShopService } from '../../ShopService/shop.service';
import { Router } from '@angular/router';
import { NavController, MenuController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { CoronaVirusService } from '../../app/corona-virus.service';
import { NotificationService } from 'src/app/notification.service';
import { Subscription } from 'rxjs';
import { PaymentService } from 'src/app/payments/payment-service/payment.service';
import { log } from 'console';
import { userService } from 'src/ShopService/user.service';

@Component({
  selector: 'app-order-button',
  templateUrl: './order-button.component.html',
  styleUrls: ['./order-button.component.scss'],
})
@Injectable({ providedIn: 'root' })
export class OrderButtonComponent implements OnInit {

  paymentSub: Subscription;

  constructor(public router: Router,
    public shopS: ShopService,
    public navCtrl: NavController,
    private notiS: NotificationService,
    public alertController: AlertController,
    public CoronaVirusService: CoronaVirusService,
    public PaymentService: PaymentService,
    private us: userService
  ) {
  }
  ngOnInit() {
    // console.log('HERE WE GO=======');
  }

  shine(btn) {
    btn.id = 'shineAnim';
    setTimeout(() => {
      btn.id = '';
    }, 2000);
  }

  async goToAbout() {
    console.log("this.shopS.userOrder", this.shopS.userOrder)

    if (this.shopS.userOrder.stage == 0) {


      this.shopS.userOrder.leftIcon = 'fa-question';
      this.shopS.userOrder.opacity = 0.5;
      this.shopS.userOrder.txt = 'מה בא לך';
      this.shopS.userOrder.prece = '35%';
      this.shopS.userOrder.stage = 1;
      this.router.navigate(['tabs/aboutClient'], { replaceUrl: true });

    }

    if (this.shopS.userOrder.stage == 2) {

      this.shopS.userOrder.leftIcon = 'fa-question';
      this.shopS.userOrder.opacity = 0.5;
      this.shopS.userOrder.txt = 'מתי נוח לך';
      this.shopS.userOrder.prece = '70%';
      this.shopS.userOrder.stage = 2;
      this.navCtrl.navigateForward(['tabs/AppointmentPage'], { replaceUrl: true });


    }

    if (this.shopS.userOrder.stage == 4) {
      // this.checkIFAlert();
      if (this.shopS.meetForBuis.meetType.payment_way == 'pre_payment' || this.shopS.meetForBuis.meetType.payment_way == 'down_payment') {
        let price = this.shopS.meetForBuis.meetType.price;
        if (this.shopS.meetForBuis.meetType.payment_way == 'down_payment') {
          price = ((parseFloat(this.shopS.meetForBuis.meetType.down_payment_amount) * parseFloat(this.shopS.meetForBuis.meetType.price)) / 100).toString();
        }

        let obj = {
          name: this.shopS.meetForBuis.meetType.name,
          price: price,
          BarberID: this.shopS.shopData.Setting.BarberID,
          key: '',
          type: 'meet'
        }

        this.PaymentService.payNowModal(obj, true);
      } else {
        this.orderSelectedAppoitment();
      }
    }

    if (this.shopS.userOrder.stage == 5) {

      // this.checkIFAlertEvent();
      if (this.shopS.eventForBuis.payment_way == 'pre_payment' || this.shopS.eventForBuis.payment_way == 'down_payment') {
        let price = this.shopS.eventForBuis.price;
        if (this.shopS.eventForBuis.payment_way == 'down_payment') {
          price = ((parseFloat(this.shopS.eventForBuis.down_payment_amount) * parseFloat(this.shopS.eventForBuis.price)) / 100).toString();
        }

        let obj = {
          name: this.shopS.eventForBuis.eventName,
          price: price,
          BarberID: this.shopS.eventForBuis.BarberID,
          userid: this.us.user.key,
          key: 'EVENT-' + this.shopS.eventForBuis.key,
          type: 'event',
          meetID: this.shopS.eventForBuis.key,
          meet_date: this.shopS.meetForBuis.convertDateToYYYY(this.shopS.eventForBuis.meetDate)
        }

        this.PaymentService.payNowModal(obj, true);
      } else {
        this.orderSelectedEvent();
      }
    }

    // this.shopS.userOrder.leftIcon = 'fa-question';
    // this.shopS.userOrder.opacity = 0.5;
    // this.shopS.userOrder.txt = 'מתי נוח לך';
    // this.shopS.userOrder.prece = '70%';
    // this.shopS.userOrder.stage = 1;
    // this.navCtrl.navigateForward(['AppointmentPage']);





  }


  goToAboutParam() {
    console.log("--->")


    this.shopS.userOrder.leftIcon = 'fa-question';
    this.shopS.userOrder.opacity = 0.5;
    this.shopS.userOrder.txt = 'מה בא לך';
    this.shopS.userOrder.prece = '35%';
    this.shopS.userOrder.stage = 1;


  }
  

  // payMeet(obj) {
  //   this.PaymentService.pay_now_service(obj);
  // }

  async orderSelectedAppoitment() {
    this.shopS.LoadingService.present_for(5000);
    this.shopS.checkMeetAvailable(this.shopS.meetForBuis.Date, this.shopS.meetForBuis.meetType.meetTime, this.shopS.meetService.meetToChange, null, meetIsAvailable => {
      this.shopS.LoadingService.dismiss();
      if (meetIsAvailable) {
        this.shopS.changeButtonState(0);
        this.shopS.itemSelectedTime();
      } else {
        this.shopS.getFreeHours(this.shopS.calendar.currentDate);
        this.presentAlertMeet();
        // return;
      }
    });
  }

  orderSelectedEvent() {
    this.shopS.LoadingService.present_for(5000);
    this.shopS.checkEventAvailable(eventIsAvailable => { 
      this.shopS.LoadingService.dismiss();
      if (eventIsAvailable) {
        this.shopS.signInToEvent(this.shopS.eventForBuis.exportEventUser());
        this.shopS.changeButtonState(0);
      } else {
        this.shopS.selectEvent(this.shopS.eventForBuis.exportEventAdmin());
      }
    });
  }


  async checkIFAlertEvent() {


    // for now only corona virus

    console.log('this.configDATA.coronavirus', this.shopS.configDATA.coronavirus);
    if (this.shopS.configDATA.coronavirus.showStatus && this.shopS.shopData.Setting.coronaHealthPopup == 'true') {

      var textT = `<img class='imgalert2' src="../../assets/imgs/virus.png">`;
      textT += this.shopS.configDATA.coronavirus.text;
      const alert = await this.alertController.create({
        header: this.shopS.configDATA.coronavirus.alertTitle,
        message: textT,
        buttons: [
          {
            text: this.shopS.configDATA.coronavirus.alertNotConfirmBtn,
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Cancel Cancel: Cancel');
              return false;
            }
          }, {
            text: this.shopS.configDATA.coronavirus.alertConfirmBtn,
            handler: () => {

              console.log('Okay: Okay');
              //confirm order 
              console.log('shopData: ', this.shopS.shopData)
              console.log('correctAppoin: ', this.shopS.correctAppoin)
              this.CoronaVirusService.dataToSave = {
                data: {
                  barberName: this.shopS.shopData.Setting.name,
                  meetTime: this.shopS.correctAppoin,
                }
              };
              this.CoronaVirusService.savetoDB();

              // this.shopS.addCounter();
              //add Event
              this.orderSelectedEvent();

            }
          }
        ]
      });
      await alert.present();
    } else {
      //confirm order 
      this.orderSelectedEvent()
    }

  }

  async checkIFAlert() {

    // this.testMeet();
    // for now only corona virus

    console.log('this.configDATA.coronavirus', this.shopS.configDATA.coronavirus);
    if (this.shopS.configDATA.coronavirus.showStatus && this.shopS.shopData.Setting.coronaHealthPopup == 'true') {
      var textT = `<img class='imgalert2' src="../../assets/imgs/virus.png">`;
      textT += this.shopS.configDATA.coronavirus.text;
      const alert = await this.alertController.create({
        header: this.shopS.configDATA.coronavirus.alertTitle,
        message: textT,
        buttons: [
          {
            text: this.shopS.configDATA.coronavirus.alertNotConfirmBtn,
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Cancel Cancel: Cancel');
              return false;
            }
          }, {
            text: this.shopS.configDATA.coronavirus.alertConfirmBtn,
            handler: () => {

              console.log('Okay: Okay');
              //confirm order 
              console.log('shopData: ', this.shopS.shopData)
              console.log('correctAppoin: ', this.shopS.correctAppoin)
              this.CoronaVirusService.dataToSave = {
                data: {
                  barberName: this.shopS.shopData.Setting.name,
                  meetTime: this.shopS.correctAppoin,
                }
              };



              var appDate = new Date(this.shopS.calendar.currentDate);
              // check Again the meet time
              var stringDate = this.shopS.formatDate(appDate);
              var gtafusmeet = this.shopS.getDateTafus(stringDate).valueChanges()
                .subscribe(data2 => {

                  var gtafusevent = this.shopS.getEventTafus(stringDate).valueChanges()
                    .subscribe(data3 => {

                      // console.log(this.shopS.meetService.meetType.meetTime);

                      let end: any = new Date(this.shopS.meetForBuis.Date);
                      end.setTime(end.getTime() + (Number(this.shopS.meetService.meetType.meetTime) * 60 * 1000));
                      var endTimeView = this.shopS.meetForBuis.addzero(end.getHours()) + ':' + this.shopS.meetForBuis.addzero(end.getMinutes())




                      var allmeetstest: any = data2.concat(data3);

                      var errorsmeet = 0;

                      // console.log(this.shopS.meetForBuis..meetForBuis);
                      for (var i = 0, l = allmeetstest.length; i < l; i++) {


                        if (allmeetstest[i].endTimeView <= this.shopS.meetForBuis.startTimeView) {
                          console.log(allmeetstest[i].startTimeView, "-", allmeetstest[i].endTimeView, "ok 1")

                        } else {
                          if (allmeetstest[i].startTimeView >= endTimeView) {

                            console.log(allmeetstest[i].startTimeView, "-", allmeetstest[i].endTimeView, "ok 2")

                          } else {
                            if ((allmeetstest[i].meetStatus == 5) || (allmeetstest[i].flow == true) || (this.shopS.meetService.meetToChange) && (this.shopS.meetService.meetToChange.startTimeView == allmeetstest[i].startTimeView)) {

                            } else {
                              errorsmeet++;
                              console.log("----------------fuckkkkkkkkk", allmeetstest[i])

                            }

                          }
                        }

                      }



                      if (errorsmeet == 0) {
                        console.log("go next");
                        this.CoronaVirusService.savetoDB();
                        this.orderSelectedAppoitment();
                      } else {
                        // this.shopS.changeViewHouers(this.shopS.calendar.currentDate);
                        this.presentAlertMeet()
                        console.log("problemmmmmm", allmeetstest[i]);

                      }
                      gtafusevent.unsubscribe();
                    })

                  gtafusmeet.unsubscribe();
                })
            }
          }
        ]
      });
      await alert.present();
    }
    console.log(this.shopS.currentMeet.meetType);

  }

  async presentAlertMeet() {
    var textT = "";
    const alert = await this.alertController.create({
      header: 'היי התור בידיוק נתפס! נסו תור אחר',
      message: textT,
      buttons: [
        {
          text: 'סגור',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        },

      ]
    });

    await alert.present();
  }

  testMeet() {

    console.log("this.shopS.correctAppoin", this.shopS.correctAppoin);
    // this.shopS.changeViewHouers(this.shopS.correctAppoin);

    // this.shopS.getDateTafus()


  }
  iconClass() {
    return this.shopS.userOrder.leftIcon;
  }

}