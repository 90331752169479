import { LoadingService } from 'src/providers/loading.service';
import { Component, OnInit } from '@angular/core';
import { Contacts, ContactFieldType, IContactFindOptions, ContactFindOptions } from '@ionic-native/contacts/ngx';
import { ShopService } from '../../ShopService/shop.service';
import { AlertController, NavController, Platform } from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-addcontact',
  templateUrl: './addcontact.page.html',
  styleUrls: ['./addcontact.page.scss'],
})
export class AddcontactPage implements OnInit {
ourtype: ContactFieldType[] = ['displayName'];
modalFLAG = false;


  constructor(private contacts: Contacts,
    public shopS:ShopService,
    private loadingService:LoadingService,
    private router:Router,
    public alertCtrl:AlertController,
    private platform:Platform
    ) { 
      this.search('');
    }


    selectedContacts:any = [];

    ngOnInit(){

      document.addEventListener('backbutton', () => {
        this.goBack();
          }, false);



    }

  

    contactsList3:any = [];

    contactsList:any = [
    //   {
    //   displayName: 'דני בוי',
    //   phoneNumbers: [{ value: '00000000606' }]
    // },
    // {
    //   displayName: 'אדי מרפי',
    //   phoneNumbers: [{ value: '11111111606' }]
    // },
    // {
    //   displayName: 'מיקי מאוס',
    //   phoneNumbers: [{ value: '22222220606' }]
    // },
    // {
    //   displayName: 'פיטר פן',
    //   phoneNumbers: [{ value: '333333098098' }]
    // },
    // {
    //   displayName: 'התינוק של במבה',
    //   phoneNumbers: [{ value: '444444408989' }]
    // },
  ];
   
  showNextContactFLAG=false;
  side;
  
  segmentChanged(e){
    console.log(e);
    this.side = e;
  }
  goBack(){
    
  }
  
  addToList(c){
    console.log('c',c);
    if(c.hasOwnProperty('numberOfInvites')){
  
    }else{
      c.numberOfInvites = '1';
    }
  
    
  
    if(c.hasOwnProperty('active')){
      console.log('hasOwnProperty = true');
      c.active = !c.active;
      if(c.active == false){
        console.log('c.active == false');
  
       // remove from selected array
        this.selectedContacts.splice(this.selectedContacts.findIndex(v => v.displayName === c.displayName), 1);
       // check if empty remove FLAG
        if(this.selectedContacts.length == 0){
          console.log('this.selectedContacts.length == 0');
  
          this.showNextContactFLAG = false;
        }
  
      }else{
   
        this.selectedContacts.push(c);
          this.showNextContactFLAG = true;
  
  
      }
  
  
  
    }else{
      console.log('hasOwnProperty = false');
  
      c.active = true;
      this.selectedContacts.push(c);
    
      if(this.selectedContacts.length == 1){
        this.showNextContactFLAG = true;
      }
  
    }
  
  
  
    console.log(this.contactsList);
  console.log('this.selectedContacts',this.selectedContacts);
  }
  
  
  
  
  
  
  showLog;
  gotoGetSide(){
    if(!this.showNextContactFLAG){
      console.log('selectedContacts',this.selectedContacts);
      console.log('modalFLAG',this.modalFLAG);
  
      this.modalFLAG = false;
    }else{
  
      if(this.modalFLAG){
  
      }
      
      console.log(this.selectedContacts);
      this.modalFLAG = !this.modalFLAG;
    }
  }
  
  
  
  
  
  
  
  
  async addToDB(side){
    console.log('contacts',this.selectedContacts);
    console.log('side',side);
    let myData:any=[];
   
    
    this.selectedContacts.forEach(item => {
      var ppp:any;
console.log('ppp',ppp);
if(item.phoneNumbers!=null){
  console.log('item.phoneNumbers[0]',item.phoneNumbers[0]);

   ppp =  item.phoneNumbers[0].value;
   console.log('ppp',ppp);

}else{
   ppp =  '';
}


console.log('end - ppp',ppp);

var DataD:any = {
  ProfilePic: this.shopS.configDATA.default.userProfile,
  fname: this.getName(item),
  lname: "",
  local: true,
  phone: ppp,
};

      //myData.push(DataD);
      this.shopS.addUserToBarber(DataD);
    });
  
  
  
  
  console.log('myData',myData);
  
  
  //this._DataService.insertRSVP(myData);
  //alert( 'this._DataService.insertRSVP(myData ');



  this.modalFLAG = false;
  const alert = await this.alertCtrl.create({
    header: 'כל הכבוד!',
    message: 'נוספו ' +  this.selectedContacts.length +  ' בהצלחה ' ,
    buttons: [ {
      text: 'חזרה',
      role: 'cancel',
      handler: data => {
        console.log('Cancel clicked');
        this.router.navigate(['tabsAdmin/clients']);

      }}]


  });

  await alert.present();
  this.selectedContacts = [];
  this.showNextContactFLAG = false;
  console.log('this.modalFLAG',this.modalFLAG);
  
  this.contactsList.forEach(element => {
    element.active = false;
  });
  
  
  }
  
 
  
  clearSelection(){
    this.selectedContacts = [];
  this.showNextContactFLAG = false;
  
    this.contactsList.forEach(element => {
      element.active = false;
    });
  
  }
  
  

  ionViewDidEnter(){

    this.modalFLAG = false;

    if(this.shopS.mainPlatform == 'ios'){
      this.loadingService.presentLoadingContactIOS();

    }else{
      this.loadingService.presentLoadingContact();

    }
  setTimeout(() => {
  
  
  this.getAllContact('');
    // this.search('');
  
  }, 500);
   
  }

  toggleModal(event){
    event.stopPropagation();
    this.modalFLAG = !this.modalFLAG;
    console.log('toggleModal=======modalFLAG',this.modalFLAG);
  }
  
  
  
  getName(c) {
    var name = c.displayName;
    if(!name || name === "") {
        if(c.name.formatted) return c.name.formatted;
        if(c.name.givenName && c.name.familyName) return c.name.givenName +" "+c.name.familyName;
        return "Nameless";
    }
    return name;
}
  
  
  
  
    search(q){
      const option: IContactFindOptions = {
        filter: ''
      }
  
  
  
  
  // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
  // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
  // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
  // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
  // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
  // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
  // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
  // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
  // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
  // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
  // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
  // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
  
    }

    getAllContact(q){

      const option: IContactFindOptions = {
        filter: ''
      }

      // here get all contacts ---------- ------------- ------------- -------------
    // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
    // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
    // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
    // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
    // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
    // ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ------------- ---
    if(this.platform.is('ios')){
  
    
      this.contacts.find(['name', 'phoneNumbers']).then(data => {
    
        console.log("datadatadatadata",data);
        this.loadingService.dismiss();
        this.contactsList=data;
            //   var contactsList2 = data;
            //   // console.log(this.contactsList);
            // contactsList2.forEach(element => {
            //   var newName=getName(element);
            //   element.displayName=newName;
            //   this.contactsList.push(element);
            // });
          });
       }else{
    
        console.log('isLoading:',this.loadingService.isLoading);

      
          this.contacts.find(['displayName', 'name', 'phoneNumbers'])
          .then(data => {
            console.log('isLoading:',this.loadingService.isLoading);

            this.loadingService.dismiss();

            this.contactsList = data
    
            var contactsList2 = data;

             console.log(this.contactsList);
          contactsList2.forEach(element => {
            var newName=getName(element);
            element.displayName=newName;
            this.contactsList.push(element);
          });
    
    
            
          });
    
        }
      
      
      
      
      
      
          this.contacts.find(this.ourtype,option).then(cont => {
            this.contactsList = cont;
          });
      
    }
  
    getContacts(){
     // let contact: Contact = this.contacts.create();
    }
  
    searchAfter(ser){
      if(ser == ''){
        this.contactsList3=[];
        return;
      }
      this.contactsList3=[];
      var newList = this.contactsList;
      this.contactsList.forEach(element => {
        console.log("-----",JSON.stringify(element),"------");
        var checkName=element.name.givenName+element.name.formatted;
        if(checkName.includes(ser)){
          this.contactsList3.push(element);
        }
        
        
      });
    
      // const found = newList.find(element => element > 10);
    }
    onKeyUp(ev){
      // this.search(ev.target.value)
      this.searchAfter(ev.target.value);

    }
  
  
  
  
    findAndReplace(object, value, replacevalue) {
      for (var x in object) {
        if (object.hasOwnProperty(x)) {
          if (typeof object[x] == 'object') {
            this.findAndReplace(object[x], value, replacevalue);
          }
          if (object[x] == value) { 
            object["name"] = replacevalue;
            // break; // uncomment to stop after first replacement
          }
        }
      }
    }
    
  
  
  
  
  
  
  }
  

  function getName(c) {
    var name = c.displayName;
    if(!name || name === "") {
        if(c.name.formatted) return c.name.formatted;
        if(c.name.givenName && c.name.familyName) return c.name.givenName +" "+c.name.familyName;
        return "Nameless";
    }
    return name;
}