import { Component, OnInit } from '@angular/core';
import { CalendarMode, Step } from 'ionic2-calendar/calendar';
import { HttpClient } from '@angular/common/http';

import { GoogleApiService, GoogleAuthService } from 'ng-gapi';
import { ShopService } from 'src/ShopService/shop.service';
// import { SheetResource } from './SheetResource';

import { GoogleCalendarService } from './google-calendar-service';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-home',
    templateUrl: 'google-calendar.page.html',
    styleUrls: ['google-calendar.page.scss'],
})

export class GoogleCalendarPage implements OnInit {
    eventSource: any;
    viewTitle: any;
    sheetId: string;
    sheet: any;
    foundSheet: any;

    isToday: boolean;

    // calendar = {
    //     mode: 'month' as CalendarMode,
    //     step: 30 as Step,
    //     currentDate: new Date(),
    //     dateFormatter: {
    //         formatMonthViewDay: function (date: Date) {
    //             return date.getDate().toString();
    //         },
    //         formatMonthViewDayHeader: function (date: Date) {
    //             return 'MonMH';
    //         },
    //         formatMonthViewTitle: function (date: Date) {
    //             return 'testMT';
    //         },
    //         formatWeekViewDayHeader: function (date: Date) {
    //             return 'MonWH';
    //         },
    //         formatWeekViewTitle: function (date: Date) {
    //             return 'testWT';
    //         },
    //         formatWeekViewHourColumn: function (date: Date) {
    //             return 'testWH';
    //         },
    //         formatDayViewHourColumn: function (date: Date) {
    //             return 'testDH';
    //         },
    //         formatDayViewTitle: function (date: Date) {
    //             return 'testDT';
    //         }
    //     }
    // };


    constructor(authService: GoogleAuthService,
        gapiService: GoogleApiService,
        // public sheetResource: SheetResource,
        public firestore: AngularFirestore,
        public shopS: ShopService,
        private http: HttpClient,
        public googleCal: GoogleCalendarService) {

        gapiService.onLoad().subscribe();
    }



    ngOnInit() {

        this.googleCal.user_type = "";
        // this.googleCal.user_type = "exclusive";
        if (this.shopS.shopData.Setting.Premium.exclusive) {
            this.googleCal.user_type = "exclusive"
        };
        this.googleCal.calendar_auth = false;
        this.googleCal.google_credentials = false;
        this.googleCal.import_events = 0;

        // this.googleCal.user_type = "";
        // // this.googleCal.user_type = "exclusive";
        // if (this.shopS.shopData.Setting.Premium.exclusive) {
        //     this.googleCal.user_type = "exclusive"
        // };
        // this.googleCal.calendar_auth = false;
        // this.googleCal.GoogleCalSynced = false;
        // this.googleCal.import_events = 0;

        if (this.shopS.shopData.Setting.GoogleCalendar) {
            this.googleCal.calendar_auth = this.shopS.shopData.Setting.GoogleCalendar.GoogleCalendar;
            this.googleCal.import_events = this.shopS.shopData.Setting.GoogleCalendar.googleCalImport || 0;
            // this.googleCal.user_mail = this.shopS.shopData.Setting.GoogleCalendar.email;
            // this.googleCal.user_name = this.shopS.shopData.Setting.GoogleCalendar.name;
            // this.googleCal.user_pic = this.shopS.shopData.Setting.GoogleCalendar.picture;
            if (this.shopS.shopData.Setting.Premium.exclusive) {
                this.googleCal.GoogleCalSynced = this.shopS.shopData.Setting.GoogleCalendar.GoogleCalSynced || false;
            }
            // console.log('user_type:', this.googleCal.user_type, ', calendar_auth:', this.googleCal.calendar_auth, ', GoogleCalSynced:', this.googleCal.GoogleCalSynced, ', import_events:', this.googleCal.import_events, 'this.googleCal.user_name:', this.googleCal.user_name, this.googleCal.user_pic);


            if (this.shopS.shopData.Setting.GoogleCalendar.refresh_token) {
                this.googleCal.google_credentials = true;
                this.googleCal.log_in_stage = "";

            } else {
                this.CheckAuthCredentials();
            }

        } else {
            this.CheckAuthCredentials();
        }
        console.log('this.shopS.shopData.Setting.GoogleCalendar', this.shopS.shopData.Setting.GoogleCalendar);

        console.log('user_type:', this.googleCal.user_type, ', calendar_auth:', this.googleCal.calendar_auth, 'this.googleCal.google_credentials', this.googleCal.google_credentials, ', GoogleCalSynced:', this.googleCal.GoogleCalSynced, ', import_events:', this.googleCal.import_events);
    }


    // loadEvents() {
    //     this.eventSource = this.createRandomEvents();
    // }

    // onViewTitleChanged(title) {
    //     this.viewTitle = title;
    // }

    // onEventSelected(event) {
    //     console.log('Event selected:' + event.startTime + '-' + event.endTime + ',' + event.title);
    // }

    // changeMode(mode) {
    //     this.calendar.mode = mode;
    // }

    // today() {
    //     this.calendar.currentDate = new Date();
    // }

    // onTimeSelected(ev) {
    //     console.log('Selected time: ' + ev.selectedTime + ', hasEvents: ' +
    //         (ev.events !== undefined && ev.events.length !== 0) + ', disabled: ' + ev.disabled);
    // }

    // onCurrentDateChanged(event: Date) {
    //     var today = new Date();
    //     today.setHours(0, 0, 0, 0);
    //     event.setHours(0, 0, 0, 0);
    //     this.isToday = today.getTime() === event.getTime();
    // }

    // createRandomEvents() {
    //     var events = [];
    //     for (var i = 0; i < 2; i += 1) {
    //         var date = new Date();
    //         var eventType = Math.floor(Math.random() * 2);
    //         var startDay = Math.floor(Math.random() * 90) - 45;
    //         var endDay = Math.floor(Math.random() * 2) + startDay;
    //         var startTime;
    //         var endTime;
    //         if (eventType === 0) {
    //             startTime = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + startDay));
    //             if (endDay === startDay) {
    //                 endDay += 1;
    //             }
    //             endTime = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + endDay));
    //             events.push({
    //                 title: 'All Day - ' + i,
    //                 startTime: startTime,
    //                 endTime: endTime,
    //                 allDay: true
    //             });
    //         } else {
    //             var startMinute = Math.floor(Math.random() * 24 * 60);
    //             var endMinute = Math.floor(Math.random() * 180) + startMinute;
    //             startTime = new Date(date.getFullYear(), date.getMonth(), date.getDate() + startDay, 0, date.getMinutes() + startMinute);
    //             endTime = new Date(date.getFullYear(), date.getMonth(), date.getDate() + endDay, 0, date.getMinutes() + endMinute);
    //             events.push({
    //                 title: 'Event - ' + i,
    //                 startTime: startTime,
    //                 endTime: endTime,
    //                 allDay: false
    //             });
    //         }
    //     }
    //     console.log(events);
    //     return events;
    // }

    // point_meet = {
    //     "BarberID": "f366aa26-25c6-4f5e-9e70-7f1f4d4ff6b1",
    //     "Date": "Thu Jun 09 2022 10:00:00 GMT+0300 (Israel Daylight Time)",
    //     "barberName": "jgg",
    //     "endTime": "2022-06-09T08:15:00.000Z",
    //     "endTimeView": "11:15",
    //     "flow": false,
    //     "key": "003a7d5b-8121-4d5c-9502-57c03139c116",
    //     "mType": {
    //         "duplicated": "",
    //         "repeat": "",
    //         "type": "Admin"
    //     },
    //     "meetDate": "09-06-2022",
    //     "meetDateFormat": "09-06-2022",
    //     "meetStatus": 1,
    //     "meetTime": "75",
    //     "meetType": {
    //         "active": false,
    //         "approve": false,
    //         "cancelation": "0",
    //         "color": "#4366FB",
    //         "flow": false,
    //         "gender": "female",
    //         "googleId": "3rea1jgeh4iv2rqku6t6kk234g",
    //         "info": "987654",
    //         "key": "-MznyuK-apgL7uMprELA",
    //         "meetTime": "75",
    //         "name": "משהו",
    //         "pic": "לחeמ",
    //         "price": "30",
    //         "reminder": 0
    //     },
    //     "moreInfo": "",
    //     "price": "30",
    //     "reminder": 0,
    //     "sendSms": true,
    //     "startDate": "Thu Jun 09 2022 10:00:00 GMT+0300 (Israel Daylight Time)",
    //     "startTimeView": "10:00",
    //     "typem": "meet",
    //     "uId": "eRxG1mJsL2ZqlrIxJZbbuEWw8dp2",
    //     "zoomlink": "",
    //     "title": "test",
    //     "allDay": false,
    //     "startTime": "2022-06-09T07:00:00.000Z",
    //     "meetTypeData": {
    //         "active": false,
    //         "approve": false,
    //         "cancelation": "0",
    //         "color": "#4366FB",
    //         "flow": false,
    //         "gender": "female",
    //         "googleId": "alnah6fv5jk7lhm0ng8ndad2d0",
    //         "info": "987654",
    //         "key": "-MznyuK-apgL7uMprELA",
    //         "meetTime": "75",
    //         "name": "משהו",
    //         "pic": "לחeמ",
    //         "price": "30",
    //         "reminder": 0
    //     },
    //     "uIdData": {
    //         "Devices": [
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/97.0.4692.71 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/97.0.4692.99 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.82 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.102 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.51 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.74 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.82 Safari/537.36",
    //             "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.82 Mobile Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.84 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.60 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.75 Safari/537.36",
    //             "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.75 Mobile Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.88 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Safari/537.36",
    //             "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Mobile Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.4951.54 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.4951.64 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.4951.67 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.0.0 Safari/537.36",
    //             "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36"
    //         ],
    //         "ProfilePic": "https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/male_avatar_323b.svg",
    //         "email": "fdsfds@fdsfsfds.ff",
    //         "fname": "הדס",
    //         "isValid": "Verify",
    //         "key": "eRxG1mJsL2ZqlrIxJZbbuEWw8dp2",
    //         "lastSeen": "2022-06-06T07:06:47.963Z",
    //         "lname": "ישראלי",
    //         "phone": "0526559716"
    //     }
    // }

    // updated_point_meet = {
    //     "key": "003a7d5b-8121-4d5c-9502-57c03139c116",
    //     "uId": "eRxG1mJsL2ZqlrIxJZbbuEWw8dp2",
    //     "BarberID": "f366aa26-25c6-4f5e-9e70-7f1f4d4ff6b1",
    //     "barberName": "jgg",
    //     "Date": "Thu Jun 09 2022 08:00:00 GMT+0300 (Israel Daylight Time)",
    //     "meetDateFormat": "09-06-2022",
    //     "startDate": "Thu Jun 09 2022 08:00:00 GMT+0300 (Israel Daylight Time)",
    //     "startTimeView": "08:00",
    //     "meetDate": "09-06-2022",
    //     "endTime": "Thu Jun 09 2022 09:15:00 GMT+0300 (Israel Daylight Time)",
    //     "endTimeView": "09:15",
    //     "meetTime": "75",
    //     "price": "30",
    //     "flow": false,
    //     "meetType": {
    //         "active": false,
    //         "approve": false,
    //         "cancelation": "0",
    //         "color": "#4366FB",
    //         "flow": false,
    //         "gender": "female",
    //         "googleId": "3rea1jgeh4iv2rqku6t6kk234g",
    //         "info": "987654",
    //         "key": "-MznyuK-apgL7uMprELA",
    //         "meetTime": "75",
    //         "name": "משהו",
    //         "pic": "לחeמ",
    //         "price": "30",
    //         "reminder": 0
    //     },
    //     "meetStatus": 1,
    //     "moreInfo": "",
    //     "reminder": 0,
    //     "mType": {
    //         "type": "user",
    //         "duplicated": "",
    //         "repeat": ""
    //     },
    //     "sendSms": true,
    //     "zoomlink": "",
    //     "typem": "meet"
    // }

    CheckAuthCredentials() {
        console.log('Check_Auth_Credentials');

        let body = {
            BarberId: this.shopS.shopData.BarberId,
            type: 'Check_Auth_Credentials'
        }
        // console.log('end_time', new Date(end_date), 'start_time', new Date(start_date), body);
        body = this.shopS.cryptoService.Encrypt(body);

        this.http.post(this.shopS.serverDev + '/GooglePointCalendarEvent', body).subscribe(val => {
            console.log('Google_Calendar_Auth_Credentials:', val['status']);

            // if (val.data()['user_info']) {
            //     let value = val.data()['user_info'];
            //     this.googleCal.user_mail = value['email'];
            //     this.googleCal.user_name = value['name'];
            //     this.googleCal.user_pic = value['picture'];
            // }
            if (this.shopS.environment.platform == 'web') {

                if (val['status'] == "no Credentials") {//user has no credentials -> need to approve scopes to connect
                    var textT = '<span class="aaaa" style="">';
                    var text = 'יש לוודא שאישרת את כל מה שנידרש על מנת שנוכל להתחבר ליומן שלך';

                    console.log('textT', textT, text);
                    textT += text + '</span>';
                    this.googleCal.alertModal2(textT, 'סיום תהליך התחברות ליומן גוגל', "no Credentials", 'הבנתי');
                }
                if (val['status'] == "no refresh_token") {//no refresh token or refresh token invalid -> user need to reconnect

                    var textT = '<span class="aaaa" style="">';
                    var text = 'הייתה בעיה עם החיבור, צריך להתחבר מחדש.\n לחץ על הקישור על מנת להסיר את ההרשאה של Point עבור "גישה לGoogle Calendar"   ';

                    console.log('textT', textT, text);
                    textT += text + `<img class='imgalert2 contactimg' src="../../assets/googlecalaccess.png" ></span>`;
                    this.googleCal.alertModal2(textT, 'בעיית התחברות ליומן גוגל', "no refresh_token", 'הבנתי');
                    this.shopS.remove_GoogleCalendar();
                }
                if (val['status'] == "ok") {
                    this.googleCal.calendar_auth = true;
                    this.googleCal.google_credentials = true;
                    this.googleCal.log_in_stage = "";

                    console.log('user_type:', this.googleCal.user_type, ', calendar_auth:', this.googleCal.calendar_auth, 'this.googleCal.google_credentials', this.googleCal.google_credentials, ', GoogleCalSynced:', this.googleCal.GoogleCalSynced, ', import_events:', this.googleCal.import_events);

                }
            }
        });
    }



    onRangeChanged(ev) {
        console.log('range changed: startTime: ' + ev.startTime + ', endTime: ' + ev.endTime);
    }

    markDisabled = (date: Date) => {
        var current = new Date();
        current.setHours(0, 0, 0);
        return date < current;
    };

    handleClientLoad() {
        gapi.load('client:auth2', this.ngOnInit);
    }



    // isLoggedIn(): boolean {
    //     return this.googleCal.isUserSignedIn();
    // }

    // handleAddEventClick() {
    //     console.log('AddEvent');
    //     let eventt = {
    //         eventStatuse: "Add Event To Calendar",
    //         eventName: "Add calendar Event",
    //         description: "This is a sample ionic-google calendar Event",
    //         startTime: "2022-05-24T10:00:00",
    //         endTime: "2022-05-24T12:00:00"
    //     }
    //     this.googleCal.calendarEvents(eventt);
    // }

    // handleGetEventClick() {
    //     console.log('GetEvent');

    //     let eventt = {
    //         eventStatuse: 'Get Event From Calendar'
    //     }
    //     this.googleCal.calendarEvents(eventt);
    // }

    // handleEditEventClick() {
    //     console.log('EditEvent');
    //     let eventt = {
    //         eventStatuse: 'Edit Event Of Calendar',
    //         eventName: "Edit Event test",
    //         description: "calendar Event",
    //         startTime: "2022-01-27T12:00:00",
    //         endTime: "2022-01-27T17:00:00",
    //         id: "5dsfj93gf0c40elnmlnc4qge34"
    //     }
    //     this.googleCal.calendarEvents(eventt);
    // }

    // handleDeleteEventClick() {
    //     console.log('DeleteEvent');
    //     let eventt = {
    //         eventStatuse: 'Delete Event From Calendar',
    //         id: "5dsfj93gf0c40elnmlnc4qge34"
    //     }
    //     this.googleCal.calendarEvents(eventt);
    // }
}