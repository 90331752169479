import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { AngularFireDatabase } from '@angular/fire/database';
import { ShopService } from '../ShopService/shop.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DecimalPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private afDb: AngularFireDatabase,
    private _http: Http,
    public shopS: ShopService,
    private sanitizer: DomSanitizer,
  ) { }
  currentVersion:any = 4.8;

  config:any = {
    bgImg: 'https://images.unsplash.com/photo-1564490292125-2e3c78a0ef44?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=975&q=80',
  }



  getTemplate(){
  const afDbb=  this.afDb.object('/template/').valueChanges().subscribe(val => {
    val['currentVersion'] = this.currentVersion;
      this.shopS.configDATA = val;
      // this.shopS.configDATA.wallet_module = true;
      console.log('template!!! ' ,this.shopS.configDATA)
      this.shopS.contactPhoneNumber.phone = this.shopS.configDATA.contactPhone;
      // console.log('this.shopS.configDATA',this.shopS.configDATA);

      let change = `
      <style>
      `+ this.shopS.configDATA.cssInject +`
      </style>
      `;
      this.shopS.themeStyleTag = this.sanitizer.bypassSecurityTrustHtml(change);
    
      afDbb.unsubscribe();
    });
  }



}
