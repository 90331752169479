import { ShopService } from './../../ShopService/shop.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-coronavirus',
  templateUrl: './coronavirus.component.html',
  styleUrls: ['./coronavirus.component.scss'],
})
export class CoronavirusComponent implements OnInit {
  searchText="";
  coronoaVirus2:any=[];
  coronoaVirus:any=[];
  constructor( 
    private modalController:ModalController,
    public shopS:ShopService) { }

  
  ngOnInit() {


  }

  ionViewDidEnter() {
    var subcon = this.shopS.getcornalist().subscribe(res=>{
      var corona=this.shopS.json2array(res);
    
      corona.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
    
        var dateA:any = new Date(a.currentSignDate), dateB:any = new Date(b.currentSignDate);
        return dateA - dateB;

        var bb =b.currentSignDate;
        var aa =a.currentSignDate;
        return bb - aa;
      });
    
      for(var i=0;i<corona.length;i++){
        var cr=new Date(corona[i].currentSignDate);
        var newArr:any=corona[i];
        newArr.currentSignDate=cr.getDate()+"-"+(cr.getMonth()+1)+"-"+cr.getFullYear();
        newArr.timeT=cr.getHours()+":"+cr.getMinutes();
        this.coronoaVirus.push(newArr);
        this.coronoaVirus2.push(newArr);
      }
      // this.coronoaVirus.reverse();
      subcon.unsubscribe()
    })

  }

  dismissModal() {
    if (this.modalController) {
      this.modalController.dismiss({
        'dismissed': false
      }).then(() => { this.modalController = null; });
    }
  }
  dismissModalSelected(x) {
    if (this.modalController) {
      this.modalController.dismiss({
        'dismissed':x
      }).then(() => { this.modalController = null; });
    }
  }
  searc(){
    this.coronoaVirus2=[];
   
    if(this.searchText.length > 2){
    for(var i =0 ;i<this.coronoaVirus.length;i++){
  
      if(this.coronoaVirus[i].currentSignDate){

    
        if(this.coronoaVirus[i].currentSignDate.includes(this.searchText)){
          this.coronoaVirus2.push(this.coronoaVirus[i]);
          
        
      }}
    }
  }else{
    this.coronoaVirus2=this.coronoaVirus;
  }

  }

}

