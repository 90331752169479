import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    transform(value: any, args?: any): any {
      const currentLang = 'he';
      const lang = {
        'he' : {
          'multiply':{
            year: 'שנים',
            month: 'חודשים',
            week: 'שבועות',
            day: 'ימים',
            hour: 'שעות',
            minute: 'דקות',
            second: 'שניות'
          },
          'single':{
            year: 'שנה',
            month: 'חודש',
            week: 'שבוע',
            day: 'יום',
            hour: 'שעה',
            minute: 'דקה',
            second: 'כמה שניות'
          },        }
        
      }

        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return lang[currentLang].single.second;
               // return 'Just now';
            const intervals = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
             //   console.log('counter',counter);
             //   console.log('i',i);
                if (counter > 0)
                    if (counter === 1) {
                      return  lang[currentLang].single[i];
                    //    return counter + ' ' + i + ' ago'; // singular (1 day ago)
                    } else {
                      return counter + ' ' + lang[currentLang].multiply[i];
                     //   return counter + ' ' + i + 's ago'; // plural (2 days ago)
                    }
            }
        }
        return value;
    }

}
