import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';

@Component({
  selector: 'app-fullscreen-alert',
  templateUrl: './fullscreen-alert.component.html',
  styleUrls: ['./fullscreen-alert.component.scss'],
})
export class FullscreenAlertComponent implements OnInit {

  constructor(public shopS: ShopService, private modalController: ModalController) { }

  ngOnInit() { }

  toggleModal() {
    this.modalController.dismiss();
  }

}
