import { PremiumPopoverComponent } from './../premium-popover/premium-popover.component';
import { AlertExample } from 'src/providers/alert.service';
import { EventClass } from './../event-class';
import { BuisMeet } from './../buis-meet';
import { UserSearchModalComponent } from './../user-search-modal/user-search-modal.component';
import { LoadingService } from './../../providers/loading.service';
import { meetService } from './../../ShopService/meetS.service';
import { ShopService } from './../../ShopService/shop.service';
import { Component, OnInit, Input } from '@angular/core';
import { NavParams, PopoverController, Platform, ModalController } from '@ionic/angular';
import { NotificationService } from '../../app/notification.service';
// import { IonicSelectableComponent } from 'ionic-selectable';
import { eventNames } from 'process';
import { ImageCompressService } from "../image-compress.service";
import { PremiumService } from '../premium/premium-service/premium.service';


class Port {
  public id: string;
  public name: string;
  public userData: object;

}


@Component({
  selector: 'app-modal-newmeet',
  templateUrl: './modal-newmeet.component.html',
  styleUrls: ['./modal-newmeet.component.scss'],
})
export class ModalNewmeetComponent implements OnInit {


  eventMeet = 'meet';
  confirmEvent = false;
  zoomlink = "";
  ports: Port[];
  port: Port;
  // Data passed in by componentProps
  @Input() ev: string;


  newEventObject = new EventClass();


  showPremium() {
    // this.popoverController.dismiss();
    this.premiumService.PremiumLock("go");
  }


  dismissModal() {
    console.log('dismiss');
    // this.eventMeet = 'meet';
    this.modalController.dismiss();
  }

  zoomshow = false;
  buttonDisabled = false;
  sendSms = true;
  meetTypeObj = {
    approve: true,
    color: "#fb434a",
    flow: false,
    gender: "female",
    info: "987654",
    meetTime: "30",
    name: "כללי",
    pic: "",
    price: "",
    reminder: 30,
    key: "",
  };
  eventName;
  selectedMeet1;
  moreInfo = "";
  meetTypeObj2 = {
    approve: true,
    color: "#fb434a",
    flow: false,
    gender: "female",
    info: "987654",
    meetTime: "30",
    name: "כללי",
    pic: "",
    price: "",
    reminder: 30,
    key: "",
  };
  newMeetObject = {
    date: "",
    time: "",

  }
  // shopS.currentMeet.user = this.shopS.Auser;
  meetTypeKey = "-KiadQ7abrQ9H4NGNsXF";
  timeSelect = "02:30";
  userArray = [];
  meets = [];
  timeStep = ["5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95", "100", "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160", "165", "170", "175", "180", "185", "190", "195", "200", "205", "210", "215", "220", "225", "230", "235", "240", "245", "250", "255", "260", "265", "270", "275", "280", "285", "290", "295"];
  eventPeopleBasic = [2, 3];

  eventPeoplePremium = [];
  eventPeople;
  eventCloseBefore;

  eventPrice;
  eventPaymentWay = 'none';
  eventDownPaymentAmount = '0';



  getTime;
  valueTime = "20";
  dateTime;
  yearValues = [];


  constructor(
    private popoverController: PopoverController,
    public shopS: ShopService,
    private alertExample: AlertExample,
    private modalController: ModalController,
    navParams: NavParams,
    private notiS: NotificationService,
    public LoadingService: LoadingService,
    public _imgCompress: ImageCompressService,
    private premiumService: PremiumService,
    // private loadingService: LoadingService
  ) {
    this.ports = [
      { id: "1", name: 'Tokai', userData: {} },

    ];

    this.getTime = navParams.get('time');
    this.dateTime = new Date(this.getTime.selectedTime);

    let today = new Date();
    for (let i = 0; i < 10; i++) {
      this.yearValues.push((today.getFullYear() + i));
    }


    // console.log(this.dateTime.getHours(),this.dateTime.getMinutes())
    this.timeSelect = this.addzero(this.dateTime.getHours()) + ":" + this.addzero(this.dateTime.getMinutes());
    console.log(this.timeSelect);
    console.log(this.dateTime);

    this.moreInfo = "";
  }

  ionViewWillEnter() {
    this.buttonDisabled = false;
    this.eventMeet = this.getTime.onlyEvent ? 'event' : 'meet';
    console.log(this.eventMeet);
    setTimeout(() => {
      console.log('2', this.eventMeet);

    }, 2000);
  }
  // portChange(event: {
  //   component: IonicSelectableComponent,
  //   value: any,

  // }) {
  //   console.log('port:', event.value);
  // }

  toggelZoom() {
    this.zoomshow = !this.zoomshow;
  }

  changeEventDate(ev) {
    console.log(ev);
    let date = new Date(ev);
    this.dateTime = date;
    console.log(this.dateTime);

  }

  ngOnInit() {
    this.eventPeoplePremium = [];
    for (var i = 0; i < this.shopS.shopData.Setting.Premium.CartridgeMeet; i++) {
      this.eventPeoplePremium.push({ num: i, type: "b" });
    }
    // for(var m=this.shopS.shopData.Setting.Premium.CartridgeMeet;m<50;m++){
    //   this.eventPeoplePremium.push({num:m,type:"p"});
    // }
    console.log("eventPeoplePremium", this.eventPeoplePremium)

    // this.userArray = this.shopS.json2array(this.shopS.shopData.Users);
    // this.ports = [];


    // // this.shopS.Auser.key= this.shopS.shopData.Setting.userManager;

    // this.port = {
    //   id:   this.shopS.Auser.key,
    //   name: this.shopS.Setting.Name,
    //   userData: this.shopS.Auser
    // }

    // this.ports.push({
    //   id: this.shopS.Auser.key,
    //   name: this.shopS.Setting.Name,
    //   userData: this.shopS.Auser
    // })

    // this.userArray.forEach(element => {
    //   var n = element.fname + ' ' + element.lname;
    //   if (this.shopS.Auser.key != element.key) {
    //     this.ports.push({
    //       id: element.key,
    //       name: n,
    //       userData: element
    //     })
    //   }

    // });

    // this.shopS.currentMeet.user={
    //   id: this.shopS.Auser.key,
    //   name: this.shopS.Setting.Name,
    //   userData: this.shopS.Auser

    // }
    // eventData
    this.eventPeople = 2;
    this.eventCloseBefore = '0';
    console.log('this.dateTime', this.dateTime);
    console.log(this.shopS.shopData.MeetingType);

    this.shopS.currentMeet.user = this.shopS.Auser;
    console.log("shopS.currentMeet.user", this.shopS.currentMeet.user)
    this.meets = this.shopS.json2array(this.shopS.shopData.MeetingType);
    this.meetNewReorder(this.shopS.json2array(this.shopS.shopData.MeetingType))

  }

  meetNewReorder(orderMeet) {
    // let haveKlali = false;
    let commonMeet = orderMeet.find(meet => meet.name == 'כללי');
    if (!commonMeet) {
      // this.meets = [this.meetTypeObj2].concat(orderMeet);
      this.meets.push(this.meetTypeObj2);
      this.meetTypeObj = this.meetTypeObj2;
    } else {
      this.meetTypeObj = commonMeet;
    }
    this.selectedMeet(this.meetTypeObj);
    // orderMeet.forEach(element => {
    //   if (element.name == "כללי") {
    //     // this.selectedMeet1 = element;
    //     haveKlali = true;
    //     this.meetTypeObj = element;
    //     return;
    //     // console.log("selevt", this.selectedMeet1)
    //   }
    // });
    // if (!haveKlali) {
    //   this.meetTypeObj = this.meetTypeObj2;
    // }
  }

  async presentPopover(typePop) {
    const popover = await this.popoverController.create({
      component: PremiumPopoverComponent,
      cssClass: 'my-custom-class popmodal',

      translucent: true,
      componentProps: { "type": typePop }



    });
    await popover.present();
    popover.onDidDismiss().then(res => {
      console.log(res)
      if (res.data) {
        this.eventPeople = res.data;

      }
    })
  }
  ChangeTime(dataTime) {
    console.log(dataTime);

    // if (dataTime.length > 7) {
    //   var nDATA = new Date(dataTime);
    //   this.dateTime.setHours(nDATA.getHours());
    //   this.dateTime.setMinutes(nDATA.getMinutes());

    //   newTime = this.addzero(nDATA.getHours()) + ":" + this.addzero(nDATA.getMinutes());
    // } else {

    var T = dataTime.split(":");
    this.dateTime.setHours(T[0]);
    this.dateTime.setMinutes(T[1]);
    this.timeSelect = this.addzero(T[0]) + ":" + this.addzero(T[1]);

    // }



    console.log(this.timeSelect);
  }


  changeEvent(data) {
    this.eventMeet = data;
    // if(data == 'meet'){
    //   this.eventMeet='meet';
    // }else{
    //   this.eventMeet='meet';

    // }
    this.localData.eventCover = '';
  }
  selectedMeet(data) {
    console.log(data);

    this.meetTypeKey = data.key;
    this.valueTime = data.meetTime.toString();

    // if (data == "כללי") {
    // this.meetTypeObj = this.meetTypeObj2
    // } else {
    // this.meetTypeObj = data;
    // }


  }
  async openUsersModal() {

    var modal = await this.modalController.create({
      component: UserSearchModalComponent,
      swipeToClose: false,
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativMoredown',
      backdropDismiss: true,
      mode: "ios",
      componentProps: {
        'type': 'meet'
      }

      //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal


      // Get the top-most ion-modal

    });



    await modal.present();
    modal.onDidDismiss().then(res => {
      console.log(res);
      if (res.data) {


        this.shopS.currentMeet.user = res.data;
      }
    })
    return modal;
  }
  selectedTime(data) {
    console.log(data);

  }
  addzero(x) {
    x = x.toString();
    if (x.length == 1) {
      x = "0" + x;
    }
    return (x)
  }
  selectUser(data) {

    if (data == "כללי") {
      this.shopS.currentMeet.user = this.shopS.Auser;
    } else {
      this.shopS.currentMeet.user = data;
    }


  }


  checkMinPrice() {
    if (this.eventPaymentWay == 'pre_payment' && this.eventPrice < 5) {
      return 'min5';
    } else if (this.eventPaymentWay == 'down_payment') {
      let minPrecentage = Math.min(parseFloat(this.eventDownPaymentAmount), 100 - parseFloat(this.eventDownPaymentAmount));
      if (!this.eventDownPaymentAmount || (minPrecentage * parseFloat(this.eventPrice)) / 100 < 5) {
        return 'down_payment_min5';
      }
    }
  }



  newEventBtn() {
    console.log("confirmEvent", this.confirmEvent)
    if (this.confirmEvent && (this.eventPaymentWay == 'pre_payment' || this.eventPaymentWay == 'down_payment')) {
      this.alertExample.aleryByText('לא ניתן לדרוש תשלום ואישור על מפגש בו זמנית');
      this.LoadingService.dismiss();
      return;
    }
    let checkPrice = this.checkMinPrice();
    if (checkPrice == 'min5') {
      this.alertExample.aleryByText('יש להזין תשלום מינימלי של 5 שקלים');
      this.LoadingService.dismiss();
      return;
    } else if (checkPrice == 'down_payment_min5') {
      this.alertExample.aleryByText('יש להזין תשלום מינימלי של 5 שקלים');
      this.LoadingService.dismiss();
      return;
    }

    let objectEvent = {
      dateTime: this.dateTime,
      moreInfo: this.moreInfo,
      valueTime: this.valueTime,
      eventPrice: this.eventPrice,
      eventPeople: this.eventPeople,
      eventCloseBefore: this.eventCloseBefore,
      eventName: this.eventName,
      BarberID: this.shopS.shopData.Setting.BarberID,
      barberName: this.shopS.shopData.Setting.name,
      sendSms: this.sendSms,
      needConfirm: this.confirmEvent ? 3 : 1,
      payment_way: this.eventPaymentWay,
      down_payment_amount: this.eventDownPaymentAmount,
      zoomlink: this.zoomlink,
      meetType: 'event',
    }
    console.log(this.localData);
    console.log(this.dateTime, this.valueTime, this.eventPrice, this.eventPeople, this.eventCloseBefore, this.eventName)
    if (this.dateTime && this.valueTime && this.eventPrice >= 0 && this.eventPeople && this.eventName) {
      if (this.localData['eventCover']) {
        // this.LoadingService.presentforever();

        this.upload_temp(callback => {
          objectEvent['eventCover'] = callback;
          this.createEvent(objectEvent);
        });
      } else {
        objectEvent['eventCover'] = this.localData['eventCover'];
        this.createEvent(objectEvent);
      }
    } else {
      this.LoadingService.dismiss();
      let miss = '';
      miss += !this.dateTime ? 'תאריך, ' : '';
      miss += !this.valueTime ? 'משך מפגש, ' : '';
      miss += this.eventPrice >= 0 ? '' : 'מחיר, ';
      miss += !this.eventPeople ? 'כמות משתתפים, ' : '';
      miss += !this.eventName ? 'שם המפגש, ' : '';
      miss = miss.substring(0, miss.length - 2);

      this.alertExample.eventDetailsAlart(miss);
      this.buttonDisabled = false;
    }
  }

  zoomLinkCheck() {
    if (this.zoomlink == '' || this.zoomlink.includes("https://zoom.us/")) {
      return true;
    } else {
      this.zoomlink = '';
      this.alertExample.aleryByText("הקישור לזום אינו תקין")
    }
  }

  createEvent(objectEvent) {
    this.shopS.eventForBuis.importEvent(objectEvent);
    this.shopS.eventForBuis.newKey();
    this.shopS.meet_api('create_event_business', { meet: this.shopS.eventForBuis.exportEventAdmin(), ShopSetting: this.shopS.shopData.Setting, function: 'createEvent' }, () => {
      this.shopS.eventForBuis.resetCLass();
      this.buttonDisabled = true;
      this.LoadingService.dismiss();
      this.dismissModal();
    });
    console.log("CreateNewEvent", objectEvent);
  }


  newMeetBtn() {
    this.buttonDisabled = true;
    // this.loadingService.present();
    this.shopS.meetForBuis.newKey();
    this.shopS.meetForBuis.importMeetType(this.meetTypeObj);
    this.shopS.meetForBuis.importZoomlink(this.zoomlink)
    this.shopS.meetForBuis.importSendSms(this.sendSms);
    this.shopS.meetForBuis.importStartDateAndHoureview(this.dateTime);
    this.shopS.meetForBuis.importBarber(this.shopS.shopData.Setting.BarberID, this.shopS.shopData.Setting.name);
    this.shopS.meetForBuis.importUserId(this.shopS.currentMeet.user.key);
    this.shopS.meetForBuis.importMoreInfo(this.moreInfo);
    this.shopS.meetForBuis.defineMeetTime(this.valueTime);
    this.shopS.meetForBuis.CreateNewMeet();
    this.shopS.meetForBuis.importPrice();
    this.shopS.meetForBuis.defineFlow(this.meetTypeObj.flow);
    this.shopS.meetForBuis.defineStatus(1);
    this.shopS.meetForBuis.showClass();

    this.shopS.setMeet();

    // this.shopS.setSmallModalNew(this.meetTypeObj, this.dateTime, this.shopS.currentMeet.user, this.moreInfo, this.valueTime, this.sendSms, this.zoomlink)
    // this.notiS.arrangeJSONforNotificationFROMmeet(this.shopS.meetForBuis.exportMeetAdmin(), 'ZimonToClient');
    // this.shopS.meetForBuis.resetCLass();
    // this.dismissModal();
    // this.loadingService.dismiss();
  }




  async upload_temp(callback) {

    let blo = this.shopS.dataURLToBlob(this.localData['eventCover']);
    console.log('blo', blo)

    let file_type = blo.type.split('/')[1]
    console.log('file_type', file_type)
    let path = this.shopS.shopData.Setting.BarberID + '/imgs/';
    let file_name = this.shopS.newGuid() + '.' + file_type;

    let fullpath = path + file_name;

    this.shopS.upload_img(blo, fullpath, cb => {
      console.log('cb', cb)
      callback(cb);
    });


  }

  localData: any = {
    eventCover: ''
  };


  preview(event, where) {
    // this.typeSetting = where;
    var self = this;
    this.localData[where] = event;
    console.log('this.localData:', this.localData);
    var filess = event.srcElement.files;
    // console.log('filess:',filess);

    if (filess.length === 0)
      return;

    var mimeType = filess[0].type;
    if (mimeType.match(/image\/*/) == null) {
      //this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(filess[0]);
    reader.onload = (_event) => {

      self._imgCompress.compressFile2(reader.result, -1).then(res => {
        //return res;
        //console.log('dsadsadsad',res);
        this.localData[where] = res;
      });



      //console.log('reader.result',reader.result);
      //console.log('this.localData',this.localData);
      //this.localData[where] = reader.result; 
      //this.shopS.UploadFlags[where] = true;
    }
  }



  toggleconfirmEvent(event) {

    this.confirmEvent = !this.confirmEvent;

  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
  }


  checkMeet(e) {
    e.preventDefault();
    if (this.shopS.checkTimeZoneDiff()) {
      this.alertExample.timezoneAlert();
    } else {
      this.LoadingService.present();
      let duration;
      this.buttonDisabled = true;
      if (this.eventMeet == 'meet') {
        duration = this.meetTypeObj.meetTime;
      } else if (this.eventMeet == 'event') {
        duration = this.valueTime;
      }

      this.shopS.checkMeetAvailable(this.dateTime, duration, null, null, available => {
        if (available) {
          if (this.eventMeet == 'meet') this.newMeetBtn();
          if (this.eventMeet == 'event') this.newEventBtn();
        } else {
          this.LoadingService.dismiss();
          this.alertExample.alertConfirm('שימו לב', '', 'קיים כבר תור בזמן זה, האם ברצונכם להמשיך?', 'המשך בכל זאת', 'ביטול', res => {
            console.log(res);
            if (res) {
              this.LoadingService.present_for(5000);
              if (this.eventMeet == 'meet') this.newMeetBtn();
              if (this.eventMeet == 'event') this.newEventBtn();
            } else {
              this.buttonDisabled = false;
            }
          });
        }
      });
    }
  }
}

