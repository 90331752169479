import { Injectable } from '@angular/core';
import { CryptoService } from 'src/app/crypto.service';
import { Http, Response } from '@angular/http';
import { env } from 'src/app/app.module';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(private CryptoService: CryptoService, private _http: Http,
  ) { }
  send_new_contacts(element, shop) {
    console.log('element---- SMSMSSMSMSMS', element);
    console.log('shop', shop);

    let dat = [

      {
        "sms_message_name": {
          biz_name: shop.name,
          code: shop.code,
          client_fname: element.name,
        },
        "sms_message": 'send_bulk_contacts',
        "phone": element.phone,
        "from_phone": "0587040933",
        "key": "aldaslkdj"


      }

    ];


    console.log('send SMSSSS', dat)
    this.sendSMS(dat);

  }



  send_bulk_contacts(list, shop) {
    console.log('list', list);
    console.log('shop', shop);

    let dat = [];
    list.forEach(element => {

      dat.push({
        "sms_message_name": {
          biz_name: shop.name,
          code: shop.code,
          client_fname: element.name,
        },
        "sms_message": 'send_bulk_contacts',
        "phone": element.phone,
        "from_phone": "0549438404",
        "key": "aldaslkdj"
      })

    });


    console.log('send SMSSSS', dat)
    this.sendSMS(dat);

  }


  sendSMS_local_client(data, user, type) {
    let meet_name;

    if (data.typem == 'meet') {
      meet_name = data.meetType.name;
    } else if (data.typem == 'event') {
      meet_name = data.eventName;

    }
    console.log('data to SMS', data)
    console.log('user to SMS', user)

    let meet_date = data.meetDateFormat;
    let meet_start_time = data.startTimeView;
    // let meet_end_time = data.endTimeView;

    let biz_name = data.barberName;

    let client_fname = user.fname;
    //    let client_lname = user.lname;
    let client_phone = user.phone;


    let dat = [{
      "sms_message_name": {
        meet_name: meet_name,
        meet_date: meet_date,
        meet_start_time: meet_start_time,
        biz_name: biz_name,
        client_fname: client_fname,
      },
      "sms_message": type,
      "phone": client_phone,
      "from_phone": "0587040933",
      "key": "aldaslkdj"
    }
    ]

    console.log('SMS  =====  dat', dat)

    this.sendSMS(dat);

  }



  async sendSMS(data) {

    let encrypted = this.CryptoService.Encrypt(data)
    console.log('encrypted', encrypted)


    await this._http.post(env.cloudfunctions + '/SendSms', encrypted).subscribe((respone: any) => {
      console.log('respone: ', respone);

    }, (error: any) => {


      console.log('error', error)

    });



  }


}
