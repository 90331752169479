import { Pipe, PipeTransform } from '@angular/core';
import { ShopService } from '../ShopService/shop.service';

@Pipe({
  name: 'meetnotifi'
})
export class MeetnotifiPipe implements PipeTransform {

  constructor(
    public shopS: ShopService,
  ) { }
  SelectedUser: any = [];
  listt;
  newlist;
  today = new Date();
  transform(value: any[], type?: any): any {
    console.log(value);
    
    // if (type == 'meet' && value) {
      if (!type) {
      this.SelectedUser = [];
      const isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
          someDate.getMonth() == today.getMonth() &&
          someDate.getFullYear() == today.getFullYear()
      }

      this.listt = this.shopS.json2array(this.shopS.shopData.Meetings);
      for (var i = 0; i < this.listt.length; i++) {
        let dd = this.shopS.json2array(this.listt[i]);
        for (var x = 0; x < dd.length; x++) {
          let startTTime = new Date(dd[x].startDate)
          if ((dd[x].meetStatus == 3) && ((startTTime >= this.today) || (isToday(startTTime)))) {
            dd[x].meetTypeData = this.shopS.findInJsonKey(this.shopS.shopData.MeetingType, dd[x].meetType);
            dd[x].uIdData = this.shopS.findInJsonKey(this.shopS.shopData.Users, dd[x].uId);
            this.SelectedUser.push(dd[x]);
          } else if (dd.length - 1 == x) {
          }
        }
      }
      // let meetings = value.filter(meet => meet.typem == 'meet');
      // meetings.forEach(meet => {
      //   let startTTime = new Date(meet.startDate)
      //     if ((meet.meetStatus == 3) && ((startTTime >= this.today) || (isToday(startTTime)))) {
      //       meet.meetTypeData = this.shopS.findInJsonKey(this.shopS.shopData.MeetingType, meet.meetType);
      //       meet.uIdData = this.shopS.findInJsonKey(this.shopS.shopData.Users, meet.uId);
      //       this.SelectedUser.push(meet);
      //     }
      // });
      console.log(this.SelectedUser);
      this.shopS.NotiCount.meetings = this.SelectedUser.length;
      return this.SelectedUser;

    } else if (type == 'event' && value) {
      console.log(value);
      
      // let events = value.filter(eve => eve.meetType == 'event');
      // console.log(events);

      // let newUsers = [];
      // events.forEach(event => {
      //   // if (new Date(event.endTime).getTime() > this.today.getTime()) {
      //   //   filteredEvents.push(event);
      //   // }
      //   if (Array.isArray(event.uId)) {
      //     event.uId.forEach(user => {
      //       if (user.status == 'wait') {
      //         console.log(user);
      //         user.event = JSON.parse(JSON.stringify(event));
      //         newUsers.push(user);
      //       }
      //     });
      //   }
      // });
      // console.log(newUsers);
      // this.shopS.NotiCount.events = newUsers.length;
      // return newUsers;
    }
  }

}
