import { ToastController, ModalController } from '@ionic/angular';
import { userService } from './../../ShopService/user.service';
import { Component, OnInit, Input, ɵConsole } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';
import { AlertExample } from './../../providers/alert.service';



@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent implements OnInit {

  constructor(
    public shopS: ShopService,
    private modalController: ModalController,
    private us: userService,
    private AlertExample: AlertExample,
    private toastController: ToastController
  ) { }

  cuponData = "";
  sendertype;
  cupon = false;
  problemreport = "";
  jso: any = {};
  report = false;
  ngOnInit() {
    this.shopS.log(this.shopS.us.key, 'contact_us_modal_open');

  }
  //contactPhoneNumber={"phone" : '05444444',}
  text = "";


  reportfunc(type) {
    this.sendertype = type;
    this.report = !this.report;
    if (this.cupon && this.report) this.cupon = false;
  }

  sendWhatsapp() {
    if (this.shopS.environment.platform == 'web') {
      window.open("https://api.whatsapp.com/send?phone=" + this.shopS.contactPhoneNumber.phone + "&text=שלום");
    } else {
      this.shopS.sendWhatsapp(this.shopS.contactPhoneNumber)
    }
  }

  // async shareWhatsApp() {
  //   this.text ='פניה מפוינט';

  //   // Text + Image or URL works
  //   this.socialSharing.shareViaWhatsApp(this.text, null, this.contactPhoneNumber.phone).then(() => {
  //     // Success
  //   }).catch((e) => {
  //     // Error!
  //   });
  // }

  async shareEmail() {

    this.text = this.problemreport;

    console.log(this.us.user);

    this.shopS.sendData(this.us.user.fname + " " + this.us.user.lname, this.us.user.phone, this.us.user.email, this.text, "contact", this.us.user.key).subscribe(respone => {
      console.log('respone: ', respone);
      var respone2: any = respone
      if (respone2.ok == true) {
        this.report = false;
        this.problemreport = "";
        this.torOk();
      }
    });

    // this.socialSharing.shareViaEmail(this.text, 'צור קשר Point', ['team@webc.co.il'], null, null, null).then(() => {
    // console.log("email sent !!!")
    // }).catch((e) => {
    //   // Error!
    //   console.log("i haver error")
    //   console.log(e);
    // });
  }

  async torOk() {
    this.shopS.log(this.shopS.us.key, 'contact_us_modal_send');

    const toast = await this.toastController.create({
      message: 'הודעה נשלחה בהצלחה',
      duration: 3000,
      color: 'success',
      position: 'top'
    });

    toast.present();

  }
  async DeletTorok() {
    const toast = await this.toastController.create({
      message: 'יש בעיה בשליחה המייל ',
      duration: 3000,
      color: 'success',
      position: 'top'
    });
    toast.present();

  }

  shareswich() {

    if (this.sendertype)
      switch (this.sendertype) {
        case "contact":
          this.shareEmail()
          break;
        case "problem":
          this.shareEmailReport()
          break;
        default:
          break;
      }
  }

  showCupon() {
    this.cupon = !this.cupon;
    if (this.cupon && this.report) this.report = false;
  }


  cuponSend() {

    if (this.cuponData) {

      this.shopS.sendCupon(this.cuponData);
      this.cupon = false;

      setTimeout(() => {
        this.cuponData = '';

      }, 1000)


    }
  }

  async shareEmailReport() {

    this.text = this.problemreport;

    console.log(this.us.user);

    this.shopS.sendData(this.us.user.fname + " " + this.us.user.lname, this.us.user.phone, this.us.user.email, this.text, "problem", this.us.user.key).subscribe(respone => {
      console.log('respone: ', respone);
      var respone2: any = respone
      if (respone2.ok == true) {
        this.report = false;
        this.problemreport = "";
        this.torOk();
      } else {

      }
    });



    // Check if sharing via email is supported
    // this.socialSharing.canShareViaEmail().then((x) => {
    //   // Sharing via email is possible
    //   console.log("share",x)
    // }).catch((x) => {
    //   console.log("problem share",x)
    //   // Sharing via email is not possible
    // });

    //     this.socialSharing.shareViaEmail(this.text, 'תקלה חדשה מ point', ['team@webc.co.il'],null,null,null).then(() => {

    //     console.log("email sent !!!")
    //     }).catch((e) => {
    //       // Error!
    //       console.log("i haver error")
    //       console.log(e);

    //     });
  }


  sendProblem() {
    console.log(this.problemreport)
    this.shareEmailReport();
  }
  toggleModal(event) {
    event.stopPropagation();
    this.shopS.contactUS = !this.shopS.contactUS;
    console.log('toggleModal=======this.shopS.MeetmodalFLAG', this.shopS.contactUS);
    this.modalController.dismiss();
  }




  facebook() {
    // this.inAppBrowser.create(this.shopS.shopData.Setting.facebook_link,'_system');
    // this.shopS.openAppUrl('facebook', this.shopS.shopData.Setting.facebook_link, this.shopS.shopData.Setting.facebook_link);
    this.shopS.openAppUrl('facebook', 'PointAppointment', 'PointAppointment')
    // window.open(this.shopS.shopData.Setting.facebook_link, "_blank",'location=yes');
  }

  instagram() {
    // this.shopS.openAppUrl('instagram', this.shopS.shopData.Setting.instagram_link)
    this.shopS.openAppUrl('instagram', 'point_appointment')

    // this.inAppBrowser.create(this.shopS.shopData.Setting.facebook_link,'_system');

    // window.open(this.shopS.shopData.Setting.instagram_link, "_blank");
  }

}
