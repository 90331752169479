import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';
import { Router } from '@angular/router';
// import { TasksService } from 'src/app/tasks/tasks-service/tasks.service';
import { LoadingController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AlertExample } from 'src/providers/alert.service';
import { LoadingService } from 'src/providers/loading.service';

@Component({
  selector: 'notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
})
export class NotificationBellComponent implements OnInit, OnDestroy {

  @Input() pointlogo: string;
  allNotifModal = false;
  totalNotif = [];
  loader = false;
  displayData = [];

  constructor(
    public shopS: ShopService,
    private router: Router,
    // private taskS: TasksService,
    private loadingController: LoadingController,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private alertExample: AlertExample
  ) {



  }
  modalFLAG = false;
  SelectedUser: any = [];

  listt: any;

  trackByFn(index, item) {
    //console.log('trackByFn',index,item)
    // console.log(item);

    return item.key;
  }

  ngAfterViewChecked() {
    //your code to update the model
    // console.log('notificationsArraynotificationsArraynotificationsArraynotificationsArray',this.shopS.notificationsArray);  
    this.cdr.detectChanges();
  }

  allNotif() {
    this.allNotifModal = !this.allNotifModal;
    // let allNotSub = this.shopS.getallNotificationBell(this.shopS.shopID).valueChanges().subscribe(res => {
    //   console.log(res);

    //   allNotSub.unsubscribe();
    //   this.totalNotif = res;
    //   console.log(this.totalNotif);

    //   // this.totalNotif
    // });
    this.loader = true;
    let allNotSub = this.shopS.getallNotificationBell(this.shopS.shopID).get().subscribe(res => {
      this.loader = false;

      allNotSub.unsubscribe();
      this.totalNotif = [];

      res.docs.forEach(doc => {
        this.totalNotif.push(doc.data());

      });
      this.totalNotif = this.totalNotif.sort((a, b) => b.timestamp - a.timestamp)

    });
  }


  // clickDOMupdate() {
  //   console.log('clickit')
  // }
  delete_counter = 0;
  delete_length = 0;
  isDelete = false;

  markAllRead() {
    let notifFilter = this.shopS.currentNotifFilter.getValue();
    let length = notifFilter.arr.length;

    if (length) {
      this.isDelete = true;
      this.delete_counter = 0;
      this.delete_length = length;
      let arr = notifFilter.arr;
      notifFilter.arr = [];
      this.shopS.currentNotifFilter.next(notifFilter);
      if (this.shopS.notificationsSub) {
        this.shopS.notificationsSub.unsubscribe();
      }
      arr.forEach(notif => {
        this.markRead(notif);
      });
    }
    // this.modalFLAG = false;
  }

  markRead(notif) {
    notif.vanish = true;
    setTimeout(() => {
      this.shopS.seenNotification(notif.key).then(() => {
        this.delete_counter++;
        if (this.delete_counter == this.delete_length && this.isDelete) {
          this.isDelete = false;
          this.shopS.openNotificationSub(this.shopS.shopID);
        }
      });
    }, 300);
  }

  disapproveMeet(notif) {
    this.shopS.presentAlertConfirmMeet(notif.data.meet, (res) => {
      if (res) {
        this.markRead(notif);
      }
    });
  }

  displayNotif = [];

  filterModal = false;
  openFilter() {
    this.filterModal = true;
  }

  approveMeet(notif) {
    this.shopS.approveMeet();
    this.markRead(notif);
  }

  approveEvent(notif) {
    notif.data.user.uId = notif.userID;
    this.shopS.approveUserEvent(notif.data.user);
    this.markRead(notif);
  }

  closeModal() {
    this.filterModal = false;
  }

  disapproveEvent(notif) {
    this.shopS.removeUserFromEvent(this.shopS.eventForBuis.exportEventAdmin(), notif.userID);
    this.markRead(notif);
  }

  checkStatus(notif, action) {
    this.loadingService.present_for(5000);
    if (notif.type == 'new_client_need_confirm') {
      // if (action == 'approve') {
      //   this.shopS.confirmUser(notif.data.user);
      // } else {
      //   this.shopS.notConfirmUser(notif.data.user);
      // }
    } else {
      if (notif.data.meet.typem == 'meet') {
        this.checkMeetStatus(notif, action);
      } else if (notif.data.meet.typem == 'event') {
        this.checkEventStatus(notif, action);
      }
    }
  }

  checkEventStatus(notif, action) {
    this.shopS.getEvent(notif.data.meet).then(async res => {
      let meet = res.val();
      if (meet) {
        this.shopS.getMeet(notif.data.meet).then(res2 => {
          this.loadingService.dismiss();
          let user_event = res2.val();
          if (user_event) {
            if (user_event.eventStatus == 3) {
              this.shopS.eventForBuis.importEventData(meet);
              notif.data.meet = user_event;
              if (action == 'approve') {
                this.approveEvent(notif);
              } else if (action == 'disapprove') {
                this.disapproveEvent(notif);
              }
            } else if (user_event.eventStatus == 5) {
              this.alertExample.aleryByTextPayAttention('התור כבר בוטל');
              this.markRead(notif);
            } else if (user_event.eventStatus == 1) {
              this.alertExample.aleryByTextPayAttention('התור כבר אושר');
              this.markRead(notif);
            }
          } else {
            this.alertExample.aleryByTextPayAttention('הלקוח ביטל את השתתפותו במפגש');
            this.markRead(notif);
          }
        });
      } else {
        this.loadingService.dismiss();
        this.alertExample.aleryByTextPayAttention('מפגש בוטל');
        this.markRead(notif);
      }
    });
  }

  checkMeetStatus(notif, action) {
    console.log(notif);

    this.shopS.getMeet(notif.data.meet).then(async res => {
      let meet = res.val();
      this.loadingService.dismiss();
      if (meet) {
        if (meet.meetStatus == 3) {
          this.shopS.meetForBuis.loadFullMeet(meet);
          if (action == 'approve') {
            this.approveMeet(notif);
          } else if (action == 'disapprove') {
            this.disapproveMeet(notif);
          }
        } else if (meet.meetStatus == 5) {
          this.alertExample.aleryByTextPayAttention('התור כבר בוטל');
          this.markRead(notif);
        } else if (meet.meetStatus == 1) {
          this.alertExample.aleryByTextPayAttention('התור כבר אושר');
          this.markRead(notif);
        }
      } else {
        this.alertExample.aleryByTextPayAttention('התור בוטל על ידי הלקוח');
        this.markRead(notif);
      }
    });
  }

  counter_retry = 0;
  startGo(line) {
    console.log('start Go', line);
    let el;
    this.shopS.scrolling_now = true;
    setTimeout(() => {
      el = document.getElementById(line.key);
      console.log('el-', el);
      if (el) {
        console.log('scroll!!!!');
        this.loadingController.dismiss();
        el.scrollIntoView();
        setTimeout(() => {
          this.shopS.scrolling_now = false;
        }, 1000);
        this.modalFLAG = false;
      } else {
        console.log('somwthing waiting');
        setTimeout(() => {
          if (this.counter_retry > 0) {
            this.counter_retry++
            this.startGo(line);
          } else {
            this.shopS.scrolling_now = false;
            this.loadingController.dismiss();
            this.modalFLAG = false;
          }
        }, 1000);
      }
    }, 500);

    if (this.shopS.dateForView) {
      this.shopS.dateForView.forEach(x => {
        x.active = false;
        if (line.meetDateFormat == this.shopS.formatDate(x.cal)) {
          x.active = true;
        }
      });
    }

  }

  goTo(e) {
    this.router.navigate(['tabsAdmin/calendar']);
  }

  notifClick(notif, ev) {
    if (ev.target.className.includes('notif_seen')) {
      this.markRead(notif);
    } else if (ev.target.className.includes('notif_approve_click')) {
      return;
    } else {
      if (notif.type == 'new_meet' || notif.type == 'meet_cancel' || notif.type == 'meet_change' || notif.type == 'user_sign_to_event') {
        this.clickOnLine(notif.data.meet);
      } else if (notif.type == 'new_client' || notif.type == 'new_client_need_confirm') {
        if (this.router.url != 'tabsAdmin/clients') {
          this.router.navigate(['tabsAdmin/clients']);
          this.modalFLAG = false;
        }
      }
      this.markRead(notif);

      // this.shopS.seenNotification(notif.key);
    }
  }

  async clickOnLine(line) {
    // this.LoadingService.presentforever();
    await this.loadingController.create({
      duration: 5000000,
      spinner: "dots",
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
      });
    });

    console.log('line', line);
    console.log('this.shopS.calendar.currentDate', this.shopS.calendar.currentDate)
    this.shopS.calendar.currentDate = new Date(line.startDate);
    this.counter_retry = 0;
    console.log('this.router.url', this.router.url);

    if (this.router.url != '/tabsAdmin/calendar') {
      this.router.navigate(['tabsAdmin/calendar']);
    } else {
      var superDate = new Date(this.shopS.calendar.currentDate);
      var a = {
        "active": true,
        "cal": superDate,
        "date": superDate.getDate(),
        "name": this.shopS.days["he"][superDate.getDay()]
      }
      this.shopS.switchDay(a);
    }
    this.startGo(line);

  }

  reloadSub: Subscription;
  loading_more = false;

  scroll(e) {
    // console.log((e.target.scrollHeight - e.target.offsetHeight) - e.target.scrollTop);

    if ((e.target.scrollHeight - e.target.offsetHeight) - e.target.scrollTop <= 500 && !this.loading_more && this.displayData.length < this.shopS.currentNotifFilter.getValue().arr.length) {
      console.log("ASDASD");
      this.loading_more = true;
      this.displayData = this.displayData.concat(this.shopS.currentNotifFilter.getValue().arr.slice(this.displayData.length, this.displayData.length + 50));
      console.log(this.displayData);
      setTimeout(() => {
        this.loading_more = false;
      }, 200);
    }
  }

  notSub: Subscription;

  ngOnInit() {
    console.log(this.shopS.notificationsArray);

    // setTimeout(() => {
    //   this.taskS.get_tasks();
    // }, 5000);

    this.notSub = this.shopS.currentNotifFilter.subscribe(val => {
      console.log(val);
      if (val) {
        this.displayData = val.arr.slice(0, 50);
      }
      console.log(this.displayData);
    });
    //    this.listt = this.shopS.json2array(this.shopS.shopData.Meetings);
    //    console.log('this.listt',this.listt);
    //    //this.shopS.findInJsonKey(this.shopS.shopData.MeetingType,Id);
    // for (var i = 0; i < this.listt.length; i++){
    //     // look for the entry with a matching `code` value
    //   // console.log(this.listt[i]);
    //     let dd =this.shopS.json2array(this.listt[i]);
    //     for (var x = 0; x < dd.length; x++){
    //       console.log(dd[x]);
    //       if(dd[x].meetStatus == 3){
    //         dd[x].meetTypeData = this.getMeetDataFromId(dd[x].meetType),
    //         dd[x].uIdData = this.getuIdDataFromId(dd[x].uId)
    //         this.SelectedUser.push(dd[x]);
    //         console.log('push',this.SelectedUser);
    //       }
    //     }//end of for 2

    // }//end of for 1
    //console.log('ngOnInit - bell')

    // this.shopS.reLoad.subscribe((data) => {
    //console.log('data - bell',data)
    // console.log('Subscriber B:', data);
    // let element: HTMLElement = document.getElementById('clickEventForDOM') as HTMLElement;
    //console.log(element);
    // if (element) {
    //   setTimeout(() => {
    //     element.click();
    //   }, 200);

    // }

    // })
    // this.reloadSub = this.shopS.reLoad.subscribe(() => {
    //   // if (this.shopS.eventArray && this.shopS.eventArray.length > 0) {
    //   // this.arr.meetings = this.shopS.eventArray;
    //   // console.log("RELOAD EVENT");
    //   // console.log(this.shopS.eventArray);


    //   // this.arr = JSON.parse(JSON.stringify(this.shopS.eventArray));
    //   this.arr = [];
    //   // setTimeout(() => {

    //   let arr = JSON.parse(JSON.stringify(this.shopS.eventArray));
    //   // console.log(arr);

    //   let events = arr.filter(eve => eve.meetType == 'event');
    //   // console.log(events);

    //   let newUsers = [];
    //   events.forEach(event => {
    //     if (Array.isArray(event.uId)) {
    //       event.uId.forEach(user => {
    //         if (user.status == 'wait') {
    //           // console.log(user);
    //           user.event = JSON.parse(JSON.stringify(event));
    //           newUsers.push(user);
    //         }
    //       });
    //     }
    //   });
    //   // console.log(newUsers);
    //   this.shopS.NotiCount.events = newUsers.length;
    //   setTimeout(() => {

    //     this.arr = newUsers;
    //   }, 100);
    //   // }, 100);
    //   // }
    //   // if (this.shopS.shopData.Meetings && this.shopS.shopData.Meetings.length > 0) {
    //   //   this.arr.meetings = this.shopS.shopData.Meetings;
    //   // }
    // })


  }// end of onInint

  arr = [];

  meetCounter = 0;
  eventCounter = 0;

  // setNotiCount(num, type) {
  //   if (type == 'meet') {
  //     this.meetCounter = num;
  //   } else if (type == 'event') {
  //     this.eventCounter = num;
  //   }
  //   this.shopS.NotiCount = this.meetCounter + this.eventCounter;
  // }


  // getuIdDataFromId(Id){
  //   let data =  this.shopS.findInJsonKey(this.shopS.shopData.Users,Id);
  //    console.log('data: ',data);
  //    return data
  //  }
  // getMeetDataFromId(Id){
  //  let data =  this.shopS.findInJsonKey(this.shopS.shopData.MeetingType,Id);
  //   console.log('data: ',data);
  //   return data
  // }
  now = new Date().getTime();
  begining_of_today = new Date().setHours(0, 0, 0, 0);

  toggleModal() {
    // this.taskS.get_tasks()
    // console.log(this.taskS.tasks);

    // this.shopS.reminders_check()



    this.modalFLAG = !this.modalFLAG;
    this.allNotifModal = false;

    if (this.modalFLAG) {
      this.now = new Date().getTime();
      this.begining_of_today = new Date().setHours(0, 0, 0, 0);
    }
  }






  convertDate(inputFormat) {
    // console.log('inputFormat',inputFormat);
    var today: any = new Date(inputFormat);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '.' + mm + '.' + yyyy;


  }

  ngOnDestroy(): void {
    if (this.reloadSub) {
      this.reloadSub.unsubscribe();
    }
    if (this.notSub) {
      this.notSub.unsubscribe();
    }
  }
}
