import { ContactModalComponent } from './../../components/contact-modal/contact-modal.component';
import { AuthService } from 'src/providers/auth-service';
import { AlertController, IonRouterOutlet, ModalController, Platform } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';
import { ToastService } from './../../providers/toast-service';
import { LoadingService } from 'src/providers/loading.service';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { auth } from "firebase";

// import {  } from '@ionic-nxative/firebase-authentication/ngx';

// import { firebase } from '@firebase/app';
import '@firebase/auth';
import { AlertExample } from 'src/providers/alert.service';
import { userService } from 'src/ShopService/user.service';
import { PrivacypolicyComponent } from '../privacypolicy/privacypolicy.component';
// import these as needed..
// import '@firebase/auth'; 
// import '@firebase/database'; 
// import '@firebase/firestore';


@Component({
  selector: 'app-login-phone',
  templateUrl: './login-phone.component.html',
  styleUrls: ['./login-phone.component.scss'],
})
export class LoginPhoneComponent implements OnInit {
  TimeTimer = 90;
  timeInterval;
  phoneNumber: string;
  recaptchaVerifier;
  resendRecaptchaVerifier;
  confirmationResult;
  isPhoneNumberValid = false;
  isInFocus = false;
  moveToVerification = false;
  keyboardHeight = 0;
  currentDigit;
  terms = false;
  marketing = false;




  @ViewChild('firstCodeInputElement', { static: false }) firstCodeInputElement;
  @ViewChild('secondCodeInputElement', { static: false }) secondCodeInputElement;
  @ViewChild('thirdCodeInputElement', { static: false }) thirdCodeInputElement;
  @ViewChild('fourthCodeInputElement', { static: false }) fourthCodeInputElement;
  @ViewChild('fifthCodeInputElement', { static: false }) fifthCodeInputElement;
  @ViewChild('sixthCodeInputElement', { static: false }) sixthCodeInputElement;

  windowRef: any;
  prefix: any;
  line: any;
  userdup;

  userData = {
    userdup: "",
    providerId: "",
    photo: "",
    userName: "",
  }
  verifCode: any;
  providerId = "";
  tryve = 0;
  confiermRecaptcha: firebase.auth.ConfirmationResult;

  constructor(private afDb: AngularFireDatabase,
    private router: Router,
    public shopS: ShopService,
    private authS: AuthService,
    private us: userService,
    private angularFireAuth: AngularFireAuth,
    private loadingService: LoadingService,
    // private routerOutlet: IonRouterOutlet,
    private toastService: ToastService,
    private platform: Platform,
    private modalController: ModalController,
    private ref: ChangeDetectorRef,
    private alertController: AlertController) {
  }

  ngOnInit() {

    // this.recaptchaVerifier =new firebase.auth.RecaptchaVerifier('recaptcha-container',{'size':'invisible'})
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(document.getElementById('recaptcha-button'), {
      size: 'invisible',
      callback: (response) => {
      }
    });


    window.addEventListener('native.keyboardshow', (e) => {
      // @ts-ignore
      this.keyboardHeight = e.keyboardHeight;
    });
    window.addEventListener('native.keyboardhide', (e) => {
      // @ts-ignore
      this.keyboardHeight = 0;
    });




    this.shopS.log(this.shopS.us.key, 'user_phonevalidation_start');

  }



  goBack() {
    this.modalController.dismiss();
  }

  goBackin() {
    this.moveToVerification = !this.moveToVerification;
  }

  logOut() {
    console.log("logout");
    this.authS.logout();
    this.modalController.dismiss();
  }

  handlePhoneNumberValue(event) {
    const phoneNumber = event.target.value;
    this.phoneValidation(phoneNumber)




  }

  isVerificationCodeReadyToVerify() {
    let isVerificationCodeReadyToVerify = false;
    const isFirstInputEmpty = this.firstCodeInputElement.value === '';
    const isSecondInputEmpty = this.secondCodeInputElement.value === '';
    const isThirdInputEmpty = this.thirdCodeInputElement.value === '';
    const isFourthInputEmpty = this.fourthCodeInputElement.value === '';
    const isFifthInputEmpty = this.fifthCodeInputElement.value === '';
    const isSixthInputEmpty = this.sixthCodeInputElement.value === '';

    if (!isFirstInputEmpty && !isSecondInputEmpty && !isThirdInputEmpty &&
      !isFourthInputEmpty && !isFifthInputEmpty && !isSixthInputEmpty && this.confirmationResult) {
      isVerificationCodeReadyToVerify = true;
    }
    return isVerificationCodeReadyToVerify;
  }

  moveToPrevInput(prevInput) {
    prevInput.setFocus();
  }

  setCurrentDigit(digit) {
    this.currentDigit = digit;

  }

  // ww() {
  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: '',
      message: '?האם אתה בטוח שברצונך להתחבר',
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'כן',
          handler: () => {
            this.connectuser();
          }
        }
      ]
    });

    await alert.present();
  }
  // }

  verifyCode(code: string) {

    if (this.tryve > 5) {
      this.toastService.showTostDanger("המערכת חסומה לכמה דקות");
      return;
    }
    this.loadingService.present();
    this.shopS.sendVerfyCode(code, this.phoneNumber, 1).subscribe(res => {


      console.log('PhoneVerfication=======>', res['_body']);
      this.loadingService.dismiss();
      if (res['_body'].includes('providerId')) {
        var userData = JSON.parse(res['_body']);
        this.userData.userdup = userData.providerId.email;

        this.providerId = userData.providerId.providerData[0].providerId;


        if (userData.providerId.photoURL) {

          this.userData.photo = userData.providerId.photoURL;
        }
        if (userData.providerId.displayName) {

          this.userData.userName = userData.providerId.displayName;
        }
        switch (userData.providerId.providerData[0].providerId) {
          case "password":
            this.userData.providerId = "מייל"
            break;
          case "google.com":
            this.userData.providerId = "Google"
            break;

          default:
            this.userData.providerId = userData.providerId.providerData[0].providerId
            break;
        }
        // this.toastService.showTostDanger("משתמש נוסף מחובר למספר הזה");
        return;
      }

      if (res['_body'] == "Verify") {
        this.shopS.log(this.shopS.us.key, 'user_phonevalidation_success');
        console.log(this.phoneNumber);

        this.toastService.showToast("הטלפון אומת בהצלחה");
        this.us.user.phone = this.phoneNumber;


        this.shopS.reloadUserNew(this.phoneNumber);


        this.shopS.loadMeetings(this.phoneNumber);


        this.shopS.loadRealUser(this.phoneNumber);
        this.modalController.dismiss();
      } else {


        this.cleanCodeVerification();
        this.toastService.showTostDanger("קוד האימות שהקשתם לא נכון");
        this.tryve++;
        if (this.tryve > 5) {
          this.toastService.showTostDanger("המערכת חסומה לכמה דקות");

        }
      }
    }, (error) => {
      console.log("Error while converting", error);
    })

    //haim code here


    //   console.log(firebase.auth().currentUser);
    //   console.log(response);
    // }).catch((error) => {

    //   console.log("error-log ,",error.code)
    //   switch (error.code) {
    //     case 'auth/invalid-verification-code':
    //       this.toastService.showTostDanger('קוד האימות שגוי.');
    //       break;
    //     case 'auth/code-expired':
    //       this.toastService.showTostDanger('יותר מידי ניסיונות, שלח קוד אימות חדש.');
    //       break;
    //     default:
    //       this.toastService.showTostDanger('נסה שוב מאוחר יותר.');
    //     }
    //   });
  }

  resetRecaptchaVerifier() {
    this.recaptchaVerifier.clear();
    this.recaptchaVerifier.render().then((widgetId) => {
      this.recaptchaVerifier.reset(widgetId);
    });
  }

  resendCodeVerification() {
    this.cleanCodeVerification();


    this.phoneValid();
    this.moveToVerification = true;
    this.confirmationResult = true;
    this.toastService.showToast("קוד חדש נשלח בהצלחה")

    // this.signInWithPhoneNumber();
  }

  closeT() {
    this.userdup = "";
  }
  contactus() {
    console.log(" this.shopS.contactUS", this.shopS.contactUS)
    this.shopS.contactUS = !this.shopS.contactUS;
    this.openContactModal();

    console.log(" this.shopS.contactUS", this.shopS.contactUS);
    this.userdup = "";


  }


  connectuser() {


    const verificationCode = this.firstCodeInputElement.value + this.secondCodeInputElement.value + this.thirdCodeInputElement.value +
      this.fourthCodeInputElement.value + this.fifthCodeInputElement.value + this.sixthCodeInputElement.value;

    this.shopS.sendVerfyCode(verificationCode, this.phoneNumber, 2).subscribe(res => {


      console.log('PhoneVerfication=======>', res['_body']);
      this.loadingService.dismiss();
      if (res['_body'].includes('*')) {
        this.userdup = res['_body'];
        // this.toastService.showTostDanger("משתמש נוסף מחובר למספר הזה");
        return;
      }

      if (res['_body'] == "Verify") {
        this.shopS.log(this.shopS.us.key, 'user_phonevalidation_success');
        // this.toastService.showToast("הטלפון אומת בהצלחה");
        this.us.user.phone = this.phoneNumber;
        this.shopS.reloadUserNew(this.phoneNumber);
        this.shopS.loadMeetings(this.phoneNumber);
        this.shopS.loadRealUser(this.phoneNumber);
        this.modalController.dismiss();
      } else {


        this.cleanCodeVerification();
        this.toastService.showTostDanger("קוד האימות שהקשתם לא נכון");
        this.tryve++;
        if (this.tryve > 5) {
          this.toastService.showTostDanger("המערכת חסומה לכמה דקות");

        }
      }
    }, (error) => {
      console.log("Error while converting", error);
    })

  }

  async openContactModal() {

    const modal = await this.modalController.create({
      component: ContactModalComponent,
      swipeToClose: false,
      cssClass: 'modalForPhoneContact modalGreyBG ',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
      // Get the top-most ion-modal
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();


  }
  timeLeft: number = 90;
  interval;

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {

        this.pauseTimer();
      }
    }, 1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  ngOnDestroy() {
    this.pauseTimer();
  }


  phoneValFLAG = true;

  phoneValid() {
    // this.timeLeft=90;
    if (this.phoneValFLAG) {

      this.phoneValFLAG = false;
      this.startTimer();
      this.shopS.sendPhoneValidation(this.phoneNumber).subscribe(res => {

        console.log('PhoneValidation=======> ', res);
        if (res["_body"] == "exist") {

          this.userdup = "משתמש קיים";

          // this.shopS.loadUserDataByPhone(this.phoneNumber).then(res=>{

          //  var uData= this.shopS.json2array(res.val());
          //   console.log("res",uData[0]);

          //   this.userdup=uData[0].fname+ ' '+uData[0].lname; 

          // })

          this.toastService.showTostDanger("מספר הטלפון כבר קיים במערכת");
          this.phoneValFLAG = true;
        } else {
          this.toastService.showToast("הקוד נשלח בהצלחה, הנה זה כבר מגיע!");
          this.moveToVerification = true;
          this.confirmationResult = true;
          this.phoneValFLAG = true;
        }
        this.loadingService.dismiss();
      }, (error) => {
        this.loadingService.dismiss();
        this.shopS.logWithData(this.shopS.us.key, 'error', error);
        console.log("Error while converting", error);
      })


    }

  }


  cleanCodeVerification() {
    this.firstCodeInputElement.value = '';
    this.secondCodeInputElement.value = '';
    this.thirdCodeInputElement.value = '';
    this.fourthCodeInputElement.value = '';
    this.fifthCodeInputElement.value = '';
    this.sixthCodeInputElement.value = '';
    this.firstCodeInputElement.setFocus();
  }

  phoneValidation(phoneNumber: string) {
    const regex = RegExp('^[0][5][0|1|2|3|4|5|7|8|9]{1}[-]{0,1}[0-9]{7}$');
    if (regex.test(phoneNumber)) {
      this.isPhoneNumberValid = true;
      // this.phoneNumber = '+972' + phoneNumber;
      this.phoneNumber = phoneNumber;

      return true;
    } else {
      this.isPhoneNumberValid = false;
      return false;
    }
  }

  onFocusIn() {
    this.isInFocus = true;
  }

  onFocusOut() {
    setTimeout(() => this.isInFocus = false, 0);
  }

  onSubmit = (event) => {
    this.loadingService.presentforever();
    this.phoneValid();
    if (this.marketing) {
      this.shopS.updateMarketing(this.us.user.key);
    }
    // this.signInWithPhoneNumber();
  }



  moveToNextInput(nextInput) {
    nextInput.setFocus();
  }

  isDigitOrBackspace(event, currentInput) {
    console.log('event', event)
    console.log('event.key', event.key)
    console.log('currentInput', currentInput)
    let isDigitOrBackspace = false;

    let digitPressed = event.key
    //   let isDigit =;
    //    console.log('isDigit',isDigit)




    if (digitPressed == 'Backspace') {
      currentInput.value = '';
      isDigitOrBackspace = true;

    } else {

      if (!isNaN(parseInt(digitPressed, 0))) {
        currentInput.value = digitPressed;
        isDigitOrBackspace = true;
      } else {
        currentInput.value = event.target.value;
        isDigitOrBackspace = true;

      }



    }


    // if(!event.key || event.key == 'Unidentified'){
    //     event['key'] = event.data;
    // }
    // if (!isNaN(parseInt(digitPressed, 0))) {
    //   currentInput.value = digitPressed;
    //   isDigitOrBackspace = true;
    // } else

    // if (digitPressed === 'Backspace') {
    //   currentInput.value = '';
    //   isDigitOrBackspace = true;
    // }
    event.preventDefault();
    return isDigitOrBackspace;
  }

  hard_validation() {
    const verificationCode = this.firstCodeInputElement.value + this.secondCodeInputElement.value + this.thirdCodeInputElement.value +
      this.fourthCodeInputElement.value + this.fifthCodeInputElement.value + this.sixthCodeInputElement.value;
    this.verifyCode(verificationCode);
  }


  onKeyDown(event, prevInput, currentInput, nextInput) {
    console.log('ev')
    if (!this.isDigitOrBackspace(event, currentInput)) {
      return false;
    }

    console.log("key down", this.isVerificationCodeReadyToVerify())
    if (this.isVerificationCodeReadyToVerify()) {




      const verificationCode = this.firstCodeInputElement.value + this.secondCodeInputElement.value + this.thirdCodeInputElement.value +
        this.fourthCodeInputElement.value + this.fifthCodeInputElement.value + this.sixthCodeInputElement.value;
      this.verifyCode(verificationCode);
      return false;
    }
    if (nextInput && currentInput.value !== '') {
      this.moveToNextInput(nextInput);
      return false;
    }
    if (prevInput && event.key === 'Backspace' && this.currentDigit === '') {
      this.moveToPrevInput(prevInput);
    }
    this.setCurrentDigit(currentInput.value);
  }


  signInWithPhoneNumber() {

    console.log("this.phoneNumber", this.phoneNumber);
    //     AngularFireAuth
    //    this.angularFireAuth.auth.signInWithPhoneNumber(this.phoneNumber,).then(function(verificationId) {
    //     // pass verificationId to signInWithVerificationId
    //     console.log(verificationId)
    // });
  //  this.menual_OTP()



    firebase.auth().signInWithPhoneNumber(this.phoneNumber, this.recaptchaVerifier)
      .then((confirmationResult) => {
        this.confirmationResult = confirmationResult;
        if (!this.moveToVerification) {
          this.moveToVerification = true;
          this.ref.detectChanges();
        }
      })
      .catch((error) => {

        console.log("error.code", error)
        switch (error.code) {
          case 'auth/too-many-requests':
            this.toastService.showTostDanger('יותר מידי ניסיונות, נסה שוב מאוחר יותר.');
            break;
          default:
            this.toastService.showTostDanger('נסה שוב מאוחר יותר.');
        }
        this.resetRecaptchaVerifier();
      });
  }

  signinToUser(response) {
    const uid = response.user.uid;
    const userData = {
      fname: response.additionalUserInfo.profile.first_name,
      lname: response.additionalUserInfo.profile.last_name,
      email: response.additionalUserInfo.profile.email,
      ProfilePic: response.user.photoURL,
    };

    this.afDb.object('/Users/' + uid).update(
      userData
    );
  }

  menual_OTP(){

    this.shopS.http.post(this.shopS.serverDev + '/phonecodevalidate', { phone: this.phoneNumber  }).subscribe((res: any) => {
      console.log('res!!!', res);
      if (res.status) {
      }
    });
    
  }



  async presentPrivacypolicy(): Promise<HTMLIonModalElement> {
    if (this.platform.is("ios")) {
      var modal = await this.modalController.create({
        component: PrivacypolicyComponent,
        swipeToClose: false,
        cssClass: '',
        // presentingElement: this.routerOutlet.nativeEl,
        mode: "ios",
        //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
        // Get the top-most ion-modal
      });
    } else {
      var modal = await this.modalController.create({
        component: PrivacypolicyComponent,
        swipeToClose: false,
        mode: 'ios',
        cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',
        //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
        // Get the top-most ion-modal
      });
    }
    // });
    await modal.present();
    return modal;
  }
}


function myTimer(self) {
  console.log("myTimer", self.TimeTimer)
  self.TimeTimer--;
}