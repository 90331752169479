import { Component, OnInit } from '@angular/core';
import { SortlistService } from './sortlist-service/sortlist.service';

@Component({
  selector: 'app-sortlist',
  templateUrl: './sortlist.component.html',
  styleUrls: ['./sortlist.component.scss'],
})
export class SortlistComponent implements OnInit {

  constructor(
    public sortListS: SortlistService,

  ) { }

  ngOnInit() { }


  selectedType(t) {
    console.log(t)

    if (this.sortListS.types[this.sortListS.current_type].selected == t.content) {
      if (this.sortListS.types[this.sortListS.current_type].order == 'asc') {
        this.sortListS.types[this.sortListS.current_type].order = 'desc'
      } else {
        this.sortListS.types[this.sortListS.current_type].order = 'asc'
      }
    } else {
      this.sortListS.types[this.sortListS.current_type].selected = t.content;
    }

    console.log(this.sortListS.types[this.sortListS.current_type].selected);

    let default_index = this.sortListS.default_local.find(o => o.type === this.sortListS.current_type);
    console.log('default_index', default_index)
    default_index.data = t.content;
    default_index.order = this.sortListS.types[this.sortListS.current_type].order;
    localStorage.setItem('selectedTypes', JSON.stringify(this.sortListS.default_local));
    console.log(this.sortListS.default_local);




  }


}
