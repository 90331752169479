import { ShopService } from 'src/ShopService/shop.service';
import { Router } from '@angular/router';
import { routes } from './../../pages/tabs/tabs.module';
import { userService } from './../../ShopService/user.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-s-admin-reg',
  templateUrl: './s-admin-reg.component.html',
  styleUrls: ['./s-admin-reg.component.scss'],
})
export class SAdminRegComponent implements OnInit {

  constructor(    public modalController: ModalController
    ,public shopS:ShopService,
    public us:userService,
    private router:Router
    ) { }

  ngOnInit() {
    console.log(this.us.user);
    
    console.log("presentModal-in")
  }
  modaldismis(){
    this.modalController.dismiss();
  }
  goBack(){
    console.log('go back');
   this.us.superNew=false;
   this.modalController.dismiss();
    this.router.navigate(['addBusiness']);
  }

}

