import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { PaymentService } from 'src/app/payments/payment-service/payment.service';
import { UserSearchModalComponent } from 'src/app/user-search-modal/user-search-modal.component';
import { LoadingService } from 'src/providers/loading.service';
import { ToastService } from 'src/providers/toast-service';
import { ShopService } from 'src/ShopService/shop.service';
import { userService } from 'src/ShopService/user.service';
import { AlertExample } from '../../../providers/alert.service';
import { WalletServiceService } from '../wallet-service/wallet-service.service';
import { WalletShowNewSalePage } from '../wallet-show-new-sale/wallet-show-new-sale.page';


@Component({
  selector: 'app-wallet-transfer-money',
  templateUrl: './wallet-transfer-money.component.html',
  styleUrls: ['./wallet-transfer-money.component.scss'],
})
export class WalletTransferMoneyComponent implements OnInit {

  // transfer_amount2 = null;
  method = 'credit-card';
  payObj;
  mode;
  meetTypes = [];
  getLink = true;

  @Input() currentPage = 0;
  @Input() finishPage = false;
  @Input() transfer_amount = '';
  @Input() cause = '';
  @Input() modal_type;
  @Input() user;

  pages = [
    {
      btn_text: 'המשך',
      valid: false
    },
    {
      btn_text: 'המשך',
      valid: false
    },
    {
      btn_text: 'אישור',
      valid: true
    }
    // ,
    // {
    //   btn_text: '4המשך',
    //   valid: true
    // }
  ]

  constructor(
    public shopS: ShopService,
    private PaymentService: PaymentService,
    private toast: ToastService,
    private modalController: ModalController,
    private us: userService,
    private loadingService: LoadingService,
    private AlertExample: AlertExample,
    private walletS: WalletServiceService
  ) { }

  ngOnInit() {
    console.log(this.modal_type);
    console.log(this.user);
    this.meetTypes = this.shopS.json2array(JSON.parse(JSON.stringify(this.shopS.shopData.MeetingType)));
    if (!this.user) {
      this.openUsersModal();
    }
    if (this.modal_type == 'request' || this.modal_type == 'request_finishPage') {
      this.mode = 'request';
    } else if (this.modal_type == 'transfer' || this.modal_type == 'transfer_credit' || this.modal_type == 'transfer_bit') {
      this.mode = 'transfer';
    }
  }

  async openUsersModal() {

    var modal = await this.modalController.create({
      component: UserSearchModalComponent,
      swipeToClose: false,
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativMoredown',
      backdropDismiss: true,
      mode: "ios",
      componentProps: {
        'type': 'meet'
      }
    });

    await modal.present();
    modal.onDidDismiss().then(res => {
      console.log(res);
      if (res.data) {
        this.user = res.data;
      } else {
        this.shopS.dismissModalController();
      }
    })
    return modal;
  }

  chooseMeetType(mType) {
    console.log(mType);
    mType.choosen = !mType.choosen;
    let choosenTypes = this.meetTypes.filter(type => type.choosen);
    let prices = choosenTypes.map(type => parseFloat(type.price));
    let causes = choosenTypes.map(type => type.name);
    console.log(causes);

    let totalPrices: any = prices.reduce((partialSum, a) => partialSum + a, 0);
    this.transfer_amount = totalPrices ? totalPrices : '';
    this.pages[0].valid = totalPrices >= 5 ? true : false;
    let cause: any = '';
    for (let i = 0; i < causes.length; i++) {
      if (i == causes.length - 1) {
        cause += causes[i];
      } else {
        cause += (causes[i] + ', ');
      }
      console.log(cause);

    }
    this.cause = cause;
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  otherChoose() {
    this.currentPage++;
    this.cause = '';
    this.transfer_amount = '';
    this.meetTypes.forEach(type => type.choosen = false);
  }

  changeAmount(num) {
    if (this.transfer_amount == '') {
      if (num == '.' || num == '0') {
        this.AlertExample.aleryByText('יש להזין סכום מינימלי של 5 שקלים');
      } else {
        this.transfer_amount += num;
      }
    } else {
      if (this.transfer_amount.includes('.')) {
        if (num != '.') {
          let split = this.transfer_amount.split('.');
          let decimels = split[1].length;
          if (decimels < 2) {
            this.transfer_amount += num;
          }
        }
      } else {
        this.transfer_amount += num;
      }
    }
    this.pages[this.currentPage].valid = this.transfer_amount == '' ? false : true;
  }

  removeNum() {
    if (this.transfer_amount != '') {
      if (this.transfer_amount.length == 1) {
        this.transfer_amount = '';
      } else {
        this.transfer_amount = this.transfer_amount.substring(0, this.transfer_amount.length - 1);
      }
    }
    this.pages[this.currentPage].valid = this.transfer_amount == '' ? false : true;
  }

  // causeChange(cause) {
  //   this.pages[this.currentPage].valid = cause ? true : false;
  // }

  // changeAmount(num) {
  //   if (this.transfer_amount == null) {
  //     if (num != '.') {
  //       this.transfer_amount = num;
  //     }
  //   } else {
  //     if (this.transfer_amount.includes('.')) {
  //       if (num != '.') {
  //         let split = this.transfer_amount.split('.');
  //         let decimels = split[1].length;
  //         if (decimels < 2) {
  //           this.transfer_amount += num;
  //         }
  //       }
  //     } else {
  //       this.transfer_amount += num;
  //     }
  //   }
  //   this.pages[this.currentPage].valid = this.transfer_amount == null ? false : true;
  // }

  // removeNum() {
  //   if (this.transfer_amount != null) {
  //     if (this.transfer_amount.length == 1) {
  //       this.transfer_amount = null;
  //     } else {
  //       this.transfer_amount = this.transfer_amount.substring(0, this.transfer_amount.length - 1);
  //     }
  //   }
  //   this.pages[this.currentPage].valid = this.transfer_amount == null ? false : true;
  // }

  prev_page() {
    if (this.finishPage) this.finishPage = false;
    else {
      this.currentPage--;
      if (this.currentPage == 0) this.transfer_amount = '';
      if (this.currentPage == 1) this.cause = '';
    }
  }

  payment_link = '';
  date = new Date();
  paymentSub: Subscription;

  copyLink() {
    navigator.clipboard.writeText(this.payment_link);
    this.toast.showToast2('קישור לדף תשלום הועתק בהצלחה!');
  }

  finish() {
    console.log(this.shopS.shopData);
    if (this.modal_type == 'transfer') {
      this.modalController.dismiss();

      let obj = {
        name: this.cause,
        price: this.transfer_amount,
        BarberID: this.shopS.shopData.Setting.BarberID,
        key: 'TRANSFER-' + this.shopS.shopData.Setting.BarberID
      }

      this.PaymentService.payNowModal(obj);
    } else if (this.modal_type == 'request' || this.modal_type == 'request_finishPage') {
      this.walletS.generate_sale.seller_payme_id = this.shopS.shopData.Wallet.wallet_id;
      this.walletS.generate_sale.sale_email = this.user.email ? this.user.email : null;
      this.walletS.generate_sale.sale_mobile = this.user.phone ? this.user.phone : null;
      this.walletS.generate_sale.sale_name = this.user.fname + ' ' + this.user.lname;
      this.walletS.generate_sale.sale_price = parseInt(this.transfer_amount) * 100;

      let request = {
        price: this.transfer_amount,
        date: this.date.getTime(),
        cause: this.cause,
        BarberID: this.shopS.shopData.Setting.BarberID,
        barber_name: this.shopS.shopData.Setting.name,
        userID: this.user.key,
        user_name: this.user.fname + ' ' + this.user.lname,
        user_email: this.user.email ? this.user.email : null,
        user_phone: this.user.phone ? this.user.phone : null,
        payment_status: 'pending',
        // seen: false,
        type: this.modal_type,
        sale_data: this.walletS.generate_sale
      }

      console.log(request);
      this.shopS.addPaymentRequest(request).then(val => {
        console.log(val);
        console.log(val.key);

        if (val) {
          this.toast.showToast('בקשת התשלום נשלחה בהצלחה');
          this.shopS.log(this.us.user.key, 'paymentRequest_sent');
          if (this.getLink) {
            this.payment_link = 'https://app.point-app.net/pay/' + val.key;
          } else {
            this.modalController.dismiss();
          }
        } else {
          this.modalController.dismiss();
          this.toast.showTostDanger('אירעה שגיאה בבקשת התשלום');
        }
      });
    } else if (this.modal_type == 'transfer_credit' || this.modal_type == 'transfer_bit') {
      this.walletS.generate_sale.seller_payme_id = this.shopS.shopData.Wallet.wallet_id;
      this.walletS.generate_sale.sale_email = this.user.email ? this.user.email : null;
      this.walletS.generate_sale.sale_mobile = this.user.phone;
      this.walletS.generate_sale.sale_name = this.user.fname + ' ' + this.user.lname;
      this.walletS.generate_sale.cause = this.cause;
      this.walletS.generate_sale.sale_price = parseInt(this.transfer_amount) * 100;
      if (this.modal_type == 'transfer_credit') {
        this.walletS.generate('credit-card');
      } else {
        this.walletS.generate('bit');
      }
    }
  }

  asd() {
    console.log(this.currentPage);
    console.log(this.finishPage);

  }

  async share() {
    let line_break = this.shopS.environment.platform == 'web' ? '%0a' : '\n';
    let text = this.shopS.shopData.Setting.name + ' מבקש ממך תשלום בסך' + this.transfer_amount + 'שקלים, עבור ' + this.cause + line_break + 'למעבר לדף תשלום נא להיכנס לקישור למטה' + line_break + this.payment_link;
    this.shopS.share(text);
  }

  next_page() {
    if (this.finishPage) {
      this.finish();
      return;
    }
    if (this.currentPage == 0 || this.currentPage == 2) {
      // this.finish();
      // this.currentPage = 3;
      this.date = new Date();
      this.finishPage = true;
    } else if (this.currentPage == 1) {
      if (parseFloat(this.transfer_amount) >= 5) {
        this.currentPage++;
        let el = document.getElementById('walletTr_cause_input');
        let input: any = el.childNodes[0];
        setTimeout(() => {
          input.focus();
        }, 600);
      } else {
        this.AlertExample.aleryByText('יש להזין סכום מינימלי של 5 שקלים');
      }
    }
    // else if (this.currentPage == 2) {
    //   this.date = new Date();
    //   // this.currentPage++;
    //   this.finishPage = true;
    // } 
    // else if (this.currentPage == 3) {
    //   this.finish();
    // }
  }

  // nextPage() {
  //   if (this.currentPage == 0) {
  //     if (parseFloat(this.transfer_amount) >= 5) {
  //       this.currentPage++;
  //     } else {
  //       this.AlertExample.aleryByText('יש להזין סכום מינימלי של 5 שקלים');
  //     }
  //   } else if (this.currentPage == 1 && this.modal_type == 'transfer') {
  //     this.modalController.dismiss();

  //     let obj = {
  //       name: this.cause,
  //       price: this.transfer_amount,
  //       BarberID: this.shopS.shopData.Setting.BarberID,
  //       key: 'TRANSFER-' + this.shopS.shopData.Setting.BarberID
  //     }

  //     console.log(this.us.user);

  //     this.PaymentService.payNowModal(obj);
  //   } else if (this.currentPage == 1 && this.modal_type == 'request') {
  //     this.modalController.dismiss();

  //     let date = new Date().getTime();

  //     let request = {
  //       price: this.transfer_amount,
  //       date: date,
  //       cause: this.cause,
  //       BarberID: this.shopS.shopData.Setting.BarberID,
  //       barber_name: this.shopS.shopData.Setting.name,
  //       userID: this.user.key,
  //       user_name: this.user.fname + ' ' + this.user.lname,
  //       payment_status: 'pending',
  //       seen: false
  //     }


  //     this.shopS.addPaymentRequest(request).then(val => {
  //       console.log(val);

  //       if (val) {
  //         this.toast.showToast('בקשת התשלום נשלחה בהצלחה');
  //         this.shopS.log(this.us.user.key, 'paymentRequest_sent');
  //         // this.modalController.dismiss();
  //       } else {
  //         this.toast.showTostDanger('אירעה שגיאה בבקשת התשלום');
  //       }
  //     });

  //   }
  // }

}
