import { ProfileUserComponent } from './../../app/profile-user/profile-user.component';
import { AboutClinetComponent } from './../../app/about-clinet/about-clinet.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule, IonRouterOutlet } from '@ionic/angular';


import { TabsPage } from './tabs.page';
import { AuthGuard } from '../../services/auth-guard.service';

import { HomePage } from '../home/home';
// import { AboutPage } from '../about/about';
import { ContactPage } from '../contact/contact';
//import { OrderButtonComponent } from '../../components/order-button/order-button.component';

import { SettingsPage } from '../Setting/Setting.component';
import { KeysPipe } from '../../pipes/keys.pipe';
import { UserShareToBizComponent } from '../../components/user-share-to-biz/user-share-to-biz.component';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { DmenuComponent } from 'src/app/dmenu/dmenu.component';
import { MainModule } from 'src/app/main/main.module';
import { OrderButtonComponent } from 'src/components/order-button/order-button.component';
import { AppointmentPage } from '../appointment/appointment';
import { WalletTransferMoneyComponent } from 'src/app/wallet/wallet-transfer-money/wallet-transfer-money.component';
import { BuisIndexComponent } from 'src/app/buis-index/buis-index.component';
// import { GooglemapsComponent } from 'src/app/googlemaps/googlemaps.component';
import { AgmCoreModule } from '@agm/core';

export const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      { path: 'home', component: SettingsPage, canActivate: [AuthGuard] },
      { path: 'about', component: AboutClinetComponent, canActivate: [AuthGuard] },
      { path: 'contact', component: ContactPage, canActivate: [AuthGuard] },
      { path: 'business-profile', component: ProfileUserComponent, canActivate: [AuthGuard] },
      { path: 'AppointmentPage', component: AppointmentPage, canActivate: [AuthGuard] },
      { path: 'aboutClient', component: AboutClinetComponent, canActivate: [AuthGuard] },
      { path: 'discover', component: BuisIndexComponent, canActivate: [AuthGuard] }
    ]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [
    TabsPage,
    HomePage,
    AboutClinetComponent,
    BuisIndexComponent,
    // GooglemapsComponent,
    SettingsPage,
    ProfileUserComponent,
    AppointmentPage,
    ContactPage,
    OrderButtonComponent,
    UserShareToBizComponent,
    KeysPipe,
    WalletTransferMoneyComponent
  ],

  entryComponents: [
    WalletTransferMoneyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipeModule,
    MainModule,
    IonicModule,
    AgmCoreModule.forRoot({
      // apiKey:"AIzaSyC51cc96KCUJWHCjal_9ZC740MFL2c4e2A"
      apiKey: "AIzaSyDJYGsppHU_r_BjvfYFw-lwaQsbPqVV2zw",
      // libraries: ['places']
  }),
    // TabsPageRoutingModule,
    RouterModule.forChild(routes)
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  // exports: [GooglemapsComponent]
})
export class TabsPageModule { }
