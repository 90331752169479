import { PremiumService } from 'src/app/premium/premium-service/premium.service';
import { UserSearchModalComponent } from 'src/app/user-search-modal/user-search-modal.component';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { Event } from '@angular/router';
import { ToastService } from './../../providers/toast-service';
import { AlertExample } from './../../providers/alert.service';
import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';
import { NotificationService } from '../../app/notification.service';
import { meetService } from '../../ShopService/meetS.service';
import { empty } from 'rxjs';
import { PremiumPopoverComponent } from 'src/app/premium-popover/premium-popover.component';
import { WalletServiceService } from 'src/app/wallet/wallet-service/wallet-service.service';
import { InvoiceServiceService } from 'src/app/invoice/invoice-service/invoice-service.service';
import { InvoiceMigrateService } from 'src/app/invoice/invoice-migrate/invoice-migrate.service';
import { userService } from 'src/ShopService/user.service';
import { LoadingService } from 'src/providers/loading.service';
import { WalletPopoverComponent } from 'src/app/wallet-popover/wallet-popover.component';




@Component({
  selector: 'meet-modal',
  templateUrl: './meet-modal.component.html',
  styleUrls: ['./meet-modal.component.scss'],
})
export class MeetModalComponent implements OnInit {

  newarray = ["5", "10", "15", "20"];
  // repshowm = false;
  expendAdvance = false;
  untilDate = new Date();
  period = "week";
  maxYear;

  constructor(
    public shopS: ShopService,
    private notiS: NotificationService,
    private popoverController: PopoverController,
    private loadingService: LoadingService,
    private premiumService: PremiumService,
    private modalController: ModalController,
    private AlertExample: AlertExample,
    private ToastService: ToastService,
    public walletS: WalletServiceService,
    private toast: ToastService,
    public invoiceS: InvoiceServiceService,
    public invoiceM: InvoiceMigrateService,
    private us: userService
  ) { }




  view_invoice_from_meet(meet) {
    console.log('meet', meet);
    if (meet.invoice_data) {

      let arr = this.shopS.json2array(meet.invoice_data);
      if (arr.length == 1) {
        if (arr[0].doc_url) {
          window.open(arr[0].doc_url, '_blank');
        }
        if (arr[0].url) {
          window.open(arr[0].url.origin, '_blank');
        }
        //  this.invoiceM.doc_list(arr,this.shopS.shopData.Setting.invoice_supplier,cb=>{
        //    this.invoiceS.view_document(cb[0])
        //  })
      } else {
        //// show alert with options
      }

    } else {
      this.shopS.showfailToast('משהו השתבש נסו שנית')
    }
  }



  new_invoice_from_meet(meet) {
    if (this.shopS.shopData.Setting.invoice_supplier) {
      this.invoiceS.invoice_supplier_img = this.invoiceS.invoice_supplier_pic();
      this.invoiceS.document_count = this.invoiceM.types_array(this.shopS.shopData.Setting.invoice_supplier);

    }
    this.invoiceS.new_document_from_meet(meet);
    //  this.shopS.setActionToMeet_check('invoice','stat_invoice_okkkkkkkk',this.shopS.SelectedMeet.event)
  }





  ngOnInit() {
    console.log(this.shopS.SelectedMeet.event);

    // var d = new Date()
    // this.timeSelect2 = ""+d.getDate()+"-"+d.getFullYear()+"";
  }

  // timeSelect = "02:30";
  // timeSelect2 = "12-12";

  ionViewWillEnter() {


  }

  ionViewDidEnter() {

  }


  preview_img(img) {
    this.shopS.modalS.open_Preview_img(img)
  }



  requestPayment() {
    if (this.walletS.check_user_wallet()) {
      if (this.shopS.SelectedMeet.event.payment_status == 'fail' || this.shopS.SelectedMeet.event.payment_status == 'refused' || this.shopS.SelectedMeet.event.payment_status == 'rest_payment' || !this.shopS.SelectedMeet.event.payment_status) {
        let paymentRespone = this.shopS.json2array(this.shopS.SelectedMeet.event.paymentRespone);
        console.log(paymentRespone);
        let totalPayed = paymentRespone.filter(res => res.sale_status == 'completed').map(res => res.price);
        console.log(totalPayed);
        let sum = totalPayed.reduce((partialSum, a) => partialSum + a, 0);
        sum = sum / 100;
        let fixedPrice = (parseFloat(this.shopS.SelectedMeet.event.price) - sum).toString();

        this.walletS.openModal('request_finishPage', { user: this.shopS.SelectedMeet.event.uIdData, transfer_amount: fixedPrice, cause: this.shopS.SelectedMeet.event.meetTypeData.name, finishPage: true, currentPage: 2 });
      }
    } else {
      this.shopS.openModal(WalletPopoverComponent, 'modalLookfullWidth', false, null);
    }
  }





  checkStus = false;
  repEveryTime = "day";

  cheackArray() {
    console.log('cheackArray');

    var isWait = false;
    if (Array.isArray(this.shopS.eventForBuis.uId)) {
      this.shopS.eventForBuis.uId.forEach(res => {
        if (res.status == "wait") {
          isWait = true
        }

      })
    }
    return isWait;
  }
  changezoom(data) {
    if (data == 'event') {
      console.log(data, this.shopS.eventForBuis.zoomlink);

      if (this.zoomLinkCheck(this.shopS.eventForBuis.zoomlink)) {
        this.shopS.globalEventUpdate(this.shopS.eventForBuis.exportEventAdmin());
      }
    }

    if (data == 'meet') {
      console.log(data, this.shopS.meetForBuis.zoomlink);
      if (this.zoomLinkCheck(this.shopS.meetForBuis.zoomlink)) {

        this.shopS.meetForBuis.updateBarberMeetAnduser();
      }
    }
  }

  zoomLinkCheck(zoomlink) {

    if (zoomlink == '' || zoomlink.includes("https://zoom.us/")) {
      return true;
    } else {
      zoomlink = '';
      this.AlertExample.aleryByText("הקישור לזום אינו תקין")
    }
  }
  togglerepet(event) {
    if (this.shopS.checkTimeZoneDiff()) {
      this.AlertExample.timezoneAlert();
    } else {
      console.log(event);
      // this.repshowm = !this.repshowm;
      this.shopS.repShow = !this.shopS.repShow;
    }
  }

  changeAdvance() {
    if (this.shopS.checkTimeZoneDiff()) {
      this.AlertExample.timezoneAlert();
    } else {
      this.expendAdvance = !this.expendAdvance;
    }
  }
  toggelZoom() {
    this.shopS.zoomshow = !this.shopS.zoomshow;
  }

  morethrnmotn(time) {
    let d = new Date(time);
    let todayDay = new Date();
    let dif = this.datediff(todayDay, d);
    if (dif < 31 && dif > 0) {
      return true;
    } else {
      return false;
    }
  }
  datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  // repeatKey;
  repeat_meet = {};

  checkIfwork(day) {
    console.log("day.timeStart", day.timeStart);

    let s = day.timeStart.split(":", 2).map(Number);
    console.log("day.timeStart", s);

    if (s[0] == 0) {
      return false;
    } else {
      return true;
    }
  }


  repeatMeet() {
    console.log(this.repEveryTime, this.untilDate);
    console.log(this.shopS.meetForBuis.exportMeetAdmin());
    var dDate = new Date(this.shopS.meetForBuis.Date);

    if (this.repEveryTime == 'day') {
      dDate.setDate(dDate.getDate() + 1);
    } else {
      dDate.setDate(dDate.getDate() + 1 * 7);
    }

    if (dDate.getTime() > new Date(this.untilDate).setHours(23, 59, 59, 999)) {
      console.log(this.repeat_meet);
      this.finishRepeat();
      return;
    }

    this.shopS.meetForBuis.defineStartTimeAndEndTime(dDate, this.shopS.meetForBuis.meetTime);
    this.shopS.meetForBuis.importStartDate(dDate);

    if (this.checkIfwork(this.shopS.shopData.WorkDays[dDate.getDay()])) {
      this.shopS.meetForBuis.mType.repeat = { key: this.shopS.SelectedMeet.event.key, meetDate: this.shopS.SelectedMeet.event.meetDate };
      this.shopS.meetForBuis.newKey();
      let meet_key = this.shopS.meetForBuis.key;
      let meet = {
        meetDate: this.shopS.formatDate(dDate),
        key: meet_key
      }

      console.log(this.shopS.meetForBuis.exportMeetAdmin());

      if (this.shopS.meetForBuis.flow) {
        this.shopS.setRepeatMeet();
        this.repeat_meet[meet_key] = meet;
        this.repeatMeet();
      } else {
        this.shopS.checkMeetAvailable(dDate, this.shopS.meetForBuis.meetTime, this.shopS.SelectedMeet.event.key, null, available => {
          console.log(available);

          if (available) {
            this.shopS.setRepeatMeet();
            this.repeat_meet[meet_key] = meet;
            this.repeatMeet();
          } else {
            this.AlertExample.alertConfirm('שימו לב', '', 'קיים כבר תור ב' + this.shopS.meetForBuis.convertDate(dDate) + ', האם ברצונכם לקבוע בכל זאת?', 'קבע תור', 'דלג', res => {
              if (res) {
                this.shopS.setRepeatMeet();
                this.repeat_meet[meet_key] = meet;
              }
              this.repeatMeet();
            });
          }
        });
      }
    } else {
      this.repeatMeet();
    }
  }

  initRepeatMeet() {
    this.repeat_meet = {};
    this.repeatMeet();
  }

  finishRepeat() {
    var textmeet = "" + "נקבעו לך תורים לתאריכים :  " + "<ul>";
    for (const key in this.repeat_meet) {
      const element = this.repeat_meet[key];
      textmeet += "<li style='text-align:right'>" + element.meetDate + "</li>";
    }
    textmeet += "</ul>";

    this.shopS.meetmodalFLAG = false;
    this.AlertExample.aleryByTextgood(textmeet);
    this.shopS.repShow = !this.shopS.repShow;

    this.shopS.updateRepeatMeet(this.shopS.SelectedMeet.event, this.repeat_meet);

    this.shopS.meetForBuis.resetCLass();
  }






  // removeRepetsMeet(repet) {
  //   this.shopS.cancelLinerepted(repet, cb => {
  //     alert(cb);

  //     let text = "נמחקו ";
  //     text += cb;
  //     text += " תורים ";
  //     this.AlertExample.aleryByTextgood(text);

  //     this.shopS.meetmodalFLAG = false;
  //     this.shopS.meetForBuis.resetCLass();
  //     /// here NOTIFICATION TO USER
  //     this.ToastService.DeletTorok();

  //   });
  // }


  showPremium() {
    // this.popoverController.dismiss();
    this.premiumService.PremiumLock("go");
  }



  async presentAlertConfirmEvent(meet) {
    const alert = await this.AlertExample.alertController.create({
      header: 'מחיקת מפגש',
      message: 'האם אתה בטוח שברצונך למחוק',
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'כן',
          handler: () => {
            // this.shopS.removeEventFromAdmin(this.shopS.eventForBuis.exportEventAdmin());
            this.shopS.meet_api('delete_event_business', { meet: this.shopS.eventForBuis.exportEventAdmin(), ShopSetting: this.shopS.shopData.Setting, function: 'presentAlertConfirmEvent' }, () => {
              this.finishDeleteMeet(meet);
              this.shopS.showToast('המפגש נמחק בהצלחה');
            });
            meet.uId.forEach(user => {
              if (user.uId) {
                let new_event: any = this.shopS.eventForBuis.exportEventUser(meet);
                new_event.uId = user.uId;
                new_event.eventStatus = 5;
                // this.shopS.meet_api('update_event_user_business', { meet: new_event, userid: user.uId });
                this.shopS.meet_api('update_event_user_business', { meet: new_event, userid: user.uId, ShopSetting: this.shopS.shopData.Setting, userData: this.shopS.shopData.Users[user.uId], notif_type: 'remove_user_to_event_aborted', function: 'presentAlertConfirmEvent' });

              }
            });

            // this.shopS.meet_api('remove_all_users_event_business', meet);
            // if (this.shopS.eventForBuis.eventStatus != 5) {
            //   this.updateToCancelUserevent(meet);
            // }
          }
        }
      ]
    });
    await alert.present();
  }


  // async presentAlertConfirmMeet(meet) {
  //   const alert = await this.AlertExample.alertController.create({
  //     header: 'מחיקת תור',
  //     message: 'האם אתה בטוח שברצונך למחוק',
  //     buttons: [
  //       {
  //         text: 'לא',
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         handler: (blah) => {

  //           // this.ToastService.DeletNotOK();
  //         }
  //       }, {
  //         text: 'כן',

  //         handler: () => {
  //           // if (this.shopS.modalMeetStatus == 'meet') {
  //             this.shopS.meetForBuis.removeFromAdmin();
  //             if (this.shopS.meetForBuis.meetStatus != 5) {
  //               this.shopS.checkIfRealUser(this.shopS.meetForBuis.uId).then(res => {
  //                 let user = res.val();
  //                 let meet_notif = {
  //                   name: meet.meetType.name,
  //                   startTimeView: meet.startTimeView,
  //                   meetDate: meet.meetDate
  //                 }
  //                 if (user) {
  //                   this.shopS.meetForBuis.updateToCancelUser().then(() => {
  //                     this.finishDeleteMeet(meet);
  //                   });
  //                   // NOTIFICATION
  //                   let notif_data = {
  //                     type: '',
  //                     users: [user],
  //                     meet: meet_notif
  //                   }
  //                   if (this.shopS.meetForBuis.meetStatus == 3) {
  //                     notif_data.type = 'admin_disapprove_meet';
  //                   } else {
  //                     notif_data.type = 'admin_cancel_meet';
  //                     // DELETE REMINDER
  //                     this.shopS.removeReminder(meet.key);
  //                   }
  //                   this.shopS.send_notif(notif_data);
  //                 } else {
  //                   //SEND SMS
  //                   if (meet.uIdData.phone) {
  //                     let sms_data = {
  //                       type: 'admin_cancel_meet',
  //                       to_phone: meet.uIdData.phone,
  //                       meet: meet_notif
  //                     }
  //                     this.shopS.send_sms(sms_data);
  //                   }
  //                   this.finishDeleteMeet(meet);
  //                 }
  //               });
  //             } else {
  //               this.finishDeleteMeet(meet);
  //             }
  //             // this.shopS.saveToTrush();
  //             // let index = this.shopS.eventArray.findIndex(x => x.key === this.shopS.meetForBuis.key);
  //             // this.shopS.eventArray.splice(index, 1);
  //             // this.shopS.reLoad.next(Math.random());
  //             // this.meetS.removeTafus(meet, true);
  //             // this.shopS.meetmodalFLAG = false;

  //             // if (this.shopS.AdminFLAG && this.shopS.meetForBuis.meetStatus != 5) {
  //             //   this.notiS.sendNotification(meet, 'cancel');
  //             // }
  //             // this.shopS.meetForBuis.resetCLass();
  //             /// here NOTIFICATION TO USER
  //             // if (this.shopS.meetForBuis.meetStatus == 5) {
  //             // this.shopS.meetForBuis.resetCLass();
  //             // }
  //             // this.ToastService.DeletTorok();

  //           // } else {
  //           //   this.shopS.removeEventFromAdmin(this.shopS.eventForBuis.exportEventAdmin());
  //           //   if (this.shopS.eventForBuis.eventStatus != 5) {
  //           //     this.updateToCancelUserevent(meet);
  //           //   }

  //           //   // this.shopS.saveEventToTrush();
  //           //   // let index = this.shopS.eventArray.findIndex(x => x.key === this.shopS.eventForBuis.key);
  //           //   // this.shopS.eventArray.splice(index, 1);
  //           //   // this.shopS.reLoad.next(Math.random());
  //           //   // this.meetS.removeTafus(meet, true);
  //           //   // this.shopS.meetmodalFLAG = false;
  //           //   // this.shopS.eventForBuis.resetCLass();
  //           //   // /// here NOTIFICATION TO USER
  //           //   // this.ToastService.DeletTorok();
  //           // }
  //         }
  //       }
  //     ]
  //   });
  //   await alert.present();
  // }


  finishDeleteMeet(meet) {
    let type = this.shopS.modalMeetStatus == 'meet' ? 'meetForBuis' : 'eventForBuis';

    this.shopS.saveToTrushMeet(meet);
    // let index = this.shopS.eventArray.findIndex(x => x.key === this.shopS[type].key);
    // this.shopS.eventArray.splice(index, 1);
    // this.shopS.reLoad.next(Math.random());
    // this.meetS.removeTafus(meet, true);
    this.shopS.meetmodalFLAG = false;
    this.shopS[type].resetCLass();
    this.ToastService.DeletTorok();
  }



  // updateToCancelUserevent(meet) {
  //   console.log(meet);
  //   if ((typeof this.shopS.eventForBuis.uId != "string") && (this.shopS.eventForBuis.uId && this.shopS.eventForBuis.uId.length > 0)) {
  //     let counter = 0;
  //     this.shopS.eventForBuis.uId.forEach((element, i) => {
  //       this.shopS.checkIfRealUser(element.uId).then(res => {
  //         let meet_notif = {
  //           name: meet.meetType.name,
  //           startTimeView: meet.startTimeView,
  //           meetDate: meet.meetDate
  //         }
  //         if (res.val()) {
  //           // NOTIFICATION
  //           this.shopS.updateUserEventStatusToCancel(this.shopS.eventForBuis.exportEventUser(), element.uId);
  //           let notif_data = {
  //             type: 'remove_user_to_event_aborted',
  //             users: [res.val()],
  //             meet: meet_notif
  //           }
  //           this.shopS.send_notif(notif_data);
  //           // DELETE REMINDER
  //           this.shopS.removeReminder(this.shopS.eventForBuis.key + '-' + element.uId);
  //         } else {
  //           // SEND SMS
  //           if (element.phone) {
  //             let sms_data = {
  //               type: 'deleteUserEventAll',
  //               to_phone: element.phone,
  //               meet: meet_notif
  //             }
  //             this.shopS.send_sms(sms_data);
  //           }
  //         }
  //         counter++;
  //         if (counter == this.shopS.eventForBuis.uId.length) {
  //           this.finishDeleteMeet(meet);
  //         }
  //       });
  //     });
  //   } else {
  //     this.finishDeleteMeet(meet);
  //   }
  // }








  async presentAlertConfirmRemove(repeat) {
    const alert = await this.AlertExample.alertController.create({
      header: 'מחיקת תורים חוזרים',
      message: 'האם תרצו למחוק את כל התורים החוזרים ?',
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {

            // this.ToastService.DeletNotOK();
          }
        }, {
          text: 'כן',

          handler: () => {
            this.shopS.deleteRepeatMeet(repeat);
            // this.shopS.deleteRepeatMeet(repet, cb => {


            //   let text = "נמחקו ";
            //   text += cb;
            //   text += " תורים ";
            //   this.AlertExample.aleryByTextgood(text);

            //   this.shopS.meetmodalFLAG = false;
            //   this.shopS.meetForBuis.resetCLass();
            //   /// here NOTIFICATION TO USER
            //   this.ToastService.DeletTorok();

            // });
          }
        }
      ]
    });
    await alert.present();
  }

  // repetChane() {
  //   console.log(this.repEveryTime);
  //   // var d = new Date(this.shopS.SelectedMeet.event.startDate);
  //   if (this.repEveryTime == 'week') {


  //     // this.maxYear =d.getFullYear()+1;

  //     this.maxYear = "2021-12-22";
  //   }
  //   if (this.repEveryTime == 'day') {

  //     // var newDate = new Date(d.setMonth(d.getMonth() + 8));
  //     // console.log("newDate", newDate)
  //     // this.maxYear =newDate.getFullYear()+"-0"+newDate.getMonth()+"-"+newDate.getDate();
  //     this.maxYear = "2021-01-22";
  //   }
  // }
  async presentPopover(typePop, ev) {
    const popover = await this.popoverController.create({
      component: PremiumPopoverComponent,
      cssClass: 'my-custom-class popmodal',

      translucent: true,
      componentProps: { "type": typePop }



    });
    await popover.present();
    popover.onDidDismiss().then(res => {
      console.log(res)
      // if (res.data) {
      if (this.shopS.SelectedMeet.event.totalPeople != res.data && res.data >= this.shopS.eventForBuis.CatchPeople) {
        this.shopS.SelectedMeet.event.totalPeople = res.data;
        // this.changeMade(ev);
        this.saveEventChanges(null, null, null);
      }

      // }
    })
  }


  async openUsersModal() {
    var modal = await this.modalController.create({
      component: UserSearchModalComponent,
      swipeToClose: false,
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativMoredown',
      backdropDismiss: true,
      mode: "ios",
      componentProps: {
        'uId': this.shopS.eventForBuis.uId,
        'type': 'event'
      }
    });

    await modal.present();

    modal.onDidDismiss().then(res => {
      console.log('CHECK THIS', res);
      // if (res.data) {
      //   var userData = res.data;
      //   if (res.data.status == "removeUser") {
      //     var uidRmove;
      //     if (userData.uId) {
      //       uidRmove = userData.uId;
      //     } else {
      //       uidRmove = userData.key;
      //     }
      //     console.log("   userData", this.shopS.eventForBuis.uId, res.data, uidRmove);
      //     this.shopS.removeUserFromEvent(this.shopS.eventForBuis.exportEventAdmin(), uidRmove);
      //     console.log(this.shopS.eventForBuis.uId);
      //     return;
      //   }

      //   this.shopS.addUserToEvent(this.shopS.eventForBuis.exportEventAdmin(), userData, 'admin');
      //   console.log("selected meet", this.shopS.SelectedMeet.event);
      //   return;
      // }
    })
    return modal;
  }

  // cacncelPick(event) {
  //     console.log(this.untilDate);
  // }

  untillDateChange(ev) {
    this.untilDate = ev;
    console.log(this.untilDate);
  }

  getYear() {

    var d = new Date(this.shopS.SelectedMeet.event.startDate);

    this.maxYear = d.getFullYear();
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();

    // console.log(month);
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    // console.log(d.getFullYear().toString() + '-' + month + '-' + day);

    return d.getFullYear().toString() + '-' + month + '-' + day;
  }
  nextYear() {
    var d = new Date(this.shopS.SelectedMeet.event.startDate);
    let year = d.getFullYear() + 1;
    return year;
  }

  nextMonth() {
    var d = new Date(this.shopS.SelectedMeet.event.startDate);
    d.setMonth(+8);
    let newdate = d.getFullYear + "-0" + d.getMonth() + "-" + d.getDate();
    return newdate;
  }
  changeSmsData() {
    this.shopS.eventForBuis.showClass();
    // this.shopS.globalEventUpdateFiled(this.shopS.eventForBuis.exportEventAdmin(),this.shopS.eventForBuis.sendSms,"sendSms")
    this.shopS.globalEventUpdate(this.shopS.eventForBuis.exportEventAdmin()).then(res => {
      console.log("updated")
    })
  }

  ChangeTime(event) {
    console.log(event);
    // let nDate = new Date(event);
    // this.untilDate = nDate;

    // this.timeSelect2=event;
    // console.log(nDate);

  }

  // ChangeTimeStart(change) {
  //   console.log("ChangeTimeStart", this.shopS.SelectedMeet.event.startTimeView);
  //   console.log(this.shopS.SelectedMeet.event.uId);

  //   if ((this.shopS.SelectedMeet.event.startTimeView != change) && change) {
  //     // if (this.shopS.modalMeetStatus == 'meet') {
  //     this.shopS.meetForBuis.showClass();
  //     this.shopS.meetForBuis.changeStartTime(change);
  //     this.shopS.checkIfRealUser(this.shopS.SelectedMeet.event.uId).then(res => {
  //       let user = res.val();
  //       console.log(user);

  //       let meet_notif = {
  //         name: this.shopS.SelectedMeet.event.meetType.name,
  //         startTimeView: this.shopS.SelectedMeet.event.startTimeView,
  //         meetDate: this.shopS.SelectedMeet.event.meetDate
  //       }
  //       if (user && this.shopS.SelectedMeet.event.uId != 'localUserAdmin') {
  //         // this.shopS.meetForBuis.updateBarberMeetAnduser();
  //         this.shopS.meet_api('update_meet_both_business', this.shopS.meetForBuis.exportMeetAdmin());
  //         // NOTIFICATION
  //         let notif_data = {
  //           type: 'admin_change_meet',
  //           users: [user],
  //           meet: meet_notif
  //         }
  //         this.shopS.send_notif(notif_data);
  //         // UPDATE REMONIDER
  //         this.shopS.updateReminder(this.shopS.SelectedMeet.event);
  //       } else {
  //         // this.shopS.meetForBuis.updateBarberMeet();
  //         this.shopS.meet_api('update_meet_business', this.shopS.meetForBuis.exportMeetAdmin());
  //         // SEND SMS
  //         if (this.shopS.SelectedMeet.event.uIdData.phone) {
  //           let sms_data = {
  //             type: 'admin_change_meet',
  //             to_phone: this.shopS.SelectedMeet.event.uIdData.phone,
  //             meet: meet_notif
  //           }
  //           this.shopS.send_sms(sms_data);
  //         }
  //       }
  //     });
  //     // }
  //   }

  // }

  // wasChange = false;

  saveEventChanges(timeStart, eventDuration, func) {
    // if (timeStart && (this.shopS.SelectedMeet.event.startTimeView != timeStart) && (timeStart != '')) {
    this.shopS.eventForBuis.changeStartTime(timeStart);
    // }
    // if (eventDuration && (this.shopS.SelectedMeet.event.eventTime != eventDuration) && (eventDuration != '')) {
    this.shopS.eventForBuis.changeMeetTime(eventDuration);
    // }
    // if (!!this.shopS.SelectedMeet.event.flow != !!this.shopS.eventForBuis.flow) {
    this.shopS.eventForBuis.flow = this.shopS.SelectedMeet.event.flow;
    // }
    // if (this.shopS.eventForBuis.totalPeople != this.shopS.SelectedMeet.event.totalPeople) {
    this.shopS.eventForBuis.totalPeople = this.shopS.SelectedMeet.event.totalPeople;
    // }
    // if (this.shopS.eventForBuis.eventName != this.shopS.SelectedMeet.event.eventName) {
    // this.shopS.eventForBuis.eventName = this.shopS.SelectedMeet.event.eventName;
    // }



    // if (this.shopS.eventForBuis.Date != new Date(this.shopS.SelectedMeet.event.Date)) {
    //   this.shopS.eventForBuis.defineStartTimeAndEndTime(new Date(this.shopS.SelectedMeet.event.Date), this.shopS.SelectedMeet.event.eventTime);
    //   this.shopS.eventForBuis.meetDateFormat = this.shopS.eventForBuis.convertDate(new Date(this.shopS.SelectedMeet.event.Date));
    //   this.shopS.eventForBuis.meetDate = this.shopS.eventForBuis.convertDate(new Date(this.shopS.SelectedMeet.event.Date));
    // }
    // console.log(this.shopS.eventForBuis);
    let admin_event = this.shopS.eventForBuis.exportEventAdmin();
    let eventData = this.shopS.SelectedMeet.event;
    this.shopS.meet_api('update_event_business', { meet: admin_event, ShopSetting: this.shopS.shopData.Setting, function: func });
    console.log(eventData);
    if (eventData.uId != 'newevent' && eventData.uId && eventData.uId.length) {
      eventData.uId.forEach(user => {
        if (user.uId) {
          let user_event: any = this.shopS.eventForBuis.exportEventUser(admin_event);
          user_event.uId = user.uId;
          console.log(user_event);
          // this.shopS.meet_api('update_event_user_business', { meet: user_event, userid: user.uId });
          this.shopS.meet_api('update_event_user_business', { meet: user_event, userid: user.uId, ShopSetting: this.shopS.shopData.Setting, userData: this.shopS.shopData.Users[user.uId], notif_type: 'event_change', function: func });
        }
      });
    }
    // this.shopS.meet_api('update_all_users_event_business', this.shopS.eventForBuis.exportEventAdmin());

    // this.shopS.updateAllEeventUsers(); 
    // this.shopS.globalEventUpdate(this.shopS.eventForBuis.exportEventAdmin());

    // setTimeout(() => {
    //   this.toggleModal(ev);
    // }, 100);
  }

  // eventNameChange(ev) {
  //   if (!this.wasChange) {
  //     this.wasChange = true;
  //   }
  //   this.shopS.SelectedMeet.event.eventName = ev.target.innerText;
  // }

  Selectperiod() {

  }

  showdata() {
    console.log('this.shopS.SelectedMeet', this.shopS.SelectedMeet);

  }

  toggleModal(event) {
    event.stopPropagation();
    // if (this.shopS.modalMeetStatus == 'event') {
    //   this.shopS.SelectedMeet.event.flow = this.shopS.eventForBuis.flow;
    //   this.shopS.SelectedMeet.event.totalPeople = this.shopS.eventForBuis.totalPeople;
    // }
    this.shopS.meetForBuis.resetCLass();
    this.shopS.eventForBuis.resetCLass();
    this.modalController.dismiss();
    this.shopS.meetmodalFLAG = !this.shopS.meetmodalFLAG;
    this.repEveryTime = 'day';
    this.shopS.repShow = false;
    this.untilDate = new Date();
    // this.wasChange = false;
    console.log('toggleModal=======this.shopS.meetmodalFLAG', this.shopS.meetmodalFLAG);
  }

  // ApproveLine(event) {

  //   this.shopS.meetForBuis.importMeetStatus(1);
  //   this.shopS.meetForBuis.updateBarberMeet();
  //   this.shopS.meetForBuis.updateUserMeet();

  //   // this.shopS.updateMeeting(this.shopS.SelectedMeet, 'meetStatus', '1');
  //   // setTimeout(() => {
  //   //   // if notification counter is 1 do 0
  //   //   console.log('this.shopS.NotiCount', this.shopS.NotiCount);
  //   //   if (this.shopS.NotiCount == 1) {
  //   //     this.shopS.NotiCount = 0;
  //   //     console.log('this.shopS.NotiCount000', this.shopS.NotiCount);

  //   //   }

  //   // }, 1000);

  //   /// here NOTIFICATION TO USER
  //   this.toggleModal(event);
  //   this.notiS.sendNotification(this.shopS.SelectedMeet.event, 'Approve');

  // }

  splitMeet(event, meet) {
    if (this.shopS.checkTimeZoneDiff()) {
      this.AlertExample.timezoneAlert();
    } else {
      console.log(meet.Break, meet.nextmeet);
      console.log("moveForwardTime");


      if (meet.Break && meet.nextmeet) {

        this.shopS.newSplitMeet(meet);




      } else {
        this.AlertExample.aleryByText("חסרה שעת הפסקה או תור המשך")
      }
    }
  }

  // changeMade(ev) {
  //   // ev.stopPropagation();
  //   if (!this.wasChange) {
  //     this.wasChange = true;
  //   }
  // }

  // changeMeetTimeBefore(change) {
  //   if (this.shopS.modalMeetStatus == 'meet') {
  //     this.shopS.changeMeetTime(change);
  //   }
  // }
  splitmeetCheck(x) {
    this.shopS.spleetmeet();
    console.log("split", x)
  }

  toggleFlow($event) {
    if (this.shopS.checkTimeZoneDiff()) {
      this.AlertExample.timezoneAlert();
    } else {

      this.shopS.SelectedMeet.event.flow = !this.shopS.SelectedMeet.event.flow;

      if (this.shopS.modalMeetStatus == 'meet') {
        this.checkMeet('flow', this.shopS.SelectedMeet.event.flow, 'toggleFlow');
        // this.shopS.meet_api('update_meet_business', { meet: this.shopS.meetForBuis.exportMeetAdmin(), ShopSetting: this.shopS.shopData.Setting, function: 'toggleFlow' });
      } else {
        this.checkEvent(null, null, 'toggleFlow');
        // this.shopS.meet_api('update_event_business', { meet: this.shopS.eventForBuis.exportEventAdmin(), ShopSetting: this.shopS.shopData.Setting, function: 'toggleFlow' });
      }

      // if (this.shopS.modalMeetStatus == 'meet') {
      //   this.shopS.meetForBuis.flow = !this.shopS.meetForBuis.flow;
      //   this.shopS.meetForBuis.updateBarberMeet();
      // } else {
      //   this.wasChange = true;
      // }
    }
  }

  approveMeet() {
    this.loadingService.present_for(5000);
    this.shopS.approveMeet();
  }

  cancelLine($event) {
    var newdate = this.shopS.changeToLineFormat(this.shopS.SelectedMeet.event)
    this.shopS.SelectedMeet.event['meetDate'] = newdate;
    this.shopS.SelectedMeet.event['BarberID'] = this.shopS.shopData.Setting.BarberID;

    // console.log('cancelLine',this.shopS.SelectedMeet.event);
    console.log(this.shopS.SelectedMeet.event);

    this.shopS.presentAlertConfirmMeet(this.shopS.SelectedMeet.event);
  }

  cancelLineEvent($event) {
    console.log("cancelLineEvent", this.shopS.SelectedMeet);
    var newdate = this.shopS.changeToLineFormat(this.shopS.SelectedMeet.event);
    this.shopS.SelectedMeet.event['meetDate'] = newdate;
    this.shopS.SelectedMeet.event['BarberID'] = this.shopS.shopData.Setting.BarberID;
    this.presentAlertConfirmEvent(this.shopS.SelectedMeet.event)
  }

  checkApprove() {
    let result = false;
    if (this.shopS.SelectedMeet.event.meetStatus == 3) {
      result = true;
    }
    return result;
  }

  checkEvent(timeStart, eventDuration, func) {
    console.log('timeStart', timeStart);
    console.log('eventDuration', eventDuration);
    timeStart = timeStart ? timeStart : this.shopS.SelectedMeet.event.startTimeView;
    eventDuration = eventDuration ? eventDuration : this.shopS.SelectedMeet.event.eventTime;

    if (this.shopS.checkTimeZoneDiff()) {
      this.AlertExample.timezoneAlert();
    } else {
      console.log(this.shopS.SelectedMeet.event.flow);
      console.log(this.shopS.SelectedMeet.event);
      if (!this.shopS.SelectedMeet.event.flow) {
        let hours = timeStart.split(':')[0];
        let minutes = timeStart.split(':')[1];
        let startDate = new Date(this.shopS.eventForBuis.Date).setHours(hours, minutes);
        let event = this.shopS.eventForBuis.exportEventAdmin();
        // event.eventTime = eventDuration;
        // event.eventTime = eventDuration;
        console.log(event);

        this.shopS.checkMeetAvailable(startDate, eventDuration, this.shopS.eventForBuis.key, this.shopS.eventForBuis.exportEventAdmin(), available => {
          console.log("AVAILABLE", available);

          if (available) {
            this.saveEventChanges(timeStart, eventDuration, func);
          } else {
            this.AlertExample.alertConfirm('שימו לב', '', 'קיים כבר תור בזמן זה, האם ברצונכם להמשיך?', 'המשך בכל זאת', 'ביטול', res => {
              if (res) {
                this.saveEventChanges(timeStart, eventDuration, func);
              } else {
                this.resetEvent();
              }
            });
          }
        });
      } else {
        this.saveEventChanges(timeStart, eventDuration, func);
      }
    }
  }

  checkMeet(check_type, change, func) {
    if (this.shopS.checkTimeZoneDiff()) {
      this.AlertExample.timezoneAlert();
    } else {
      if (!this.shopS.SelectedMeet.event.flow) {
        let startDate: any = new Date(this.shopS.SelectedMeet.event.Date);
        let duration = this.shopS.SelectedMeet.event.meetTime;
        if (check_type == 'startTimeView') {
          let hours = change.split(':')[0];
          let minutes = change.split(':')[1];
          startDate = new Date(this.shopS.SelectedMeet.event.Date).setHours(hours, minutes);
        } else if (check_type == 'meetTime') {
          let newDuration = parseInt(change);
          let oldDuration = parseInt(duration);
          if (newDuration > oldDuration) {
            duration = newDuration - oldDuration;
            startDate = new Date(this.shopS.SelectedMeet.event.endTime);
          } else {
            this.shopS.meetChange(check_type, change, func);
            return;
          }
        }
        console.log(startDate, duration, this.shopS.SelectedMeet.event.key);
        console.log(this.shopS.meetForBuis.exportMeetAdmin());

        this.shopS.checkMeetAvailable(startDate, duration, this.shopS.SelectedMeet.event.key, this.shopS.meetForBuis.exportMeetAdmin(), available => {
          console.log(available);

          if (available) {
            this.shopS.meetChange(check_type, change, func);
          } else {
            this.AlertExample.alertConfirm('שימו לב', '', 'קיים כבר תור בזמן זה, האם ברצונכם להמשיך?', 'המשך בכל זאת', 'ביטול', res => {
              if (res) {
                this.shopS.meetChange(check_type, change, func);
              } else {
                this.resetMeet();
              }
            });
          }
        });
      } else {
        this.shopS.meetChange(check_type, change, func);
      }
    }
  }

  resetEvent() {
    this.shopS.SelectedMeet.event.startTimeView = this.shopS.eventForBuis.startTimeView;
    this.shopS.SelectedMeet.event.eventTime = this.shopS.eventForBuis.eventTime;
    this.shopS.SelectedMeet.event.flow = this.shopS.eventForBuis.flow;
  }

  resetMeet() {
    this.shopS.SelectedMeet.event.startTimeView = this.shopS.meetForBuis.startTimeView;
    this.shopS.SelectedMeet.event.meetTime = this.shopS.meetForBuis.meetTime;
    this.shopS.SelectedMeet.event.flow = this.shopS.meetForBuis.flow;
  }

}

