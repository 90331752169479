import { LoginPhoneComponent } from './login-phone/login-phone.component';
import { KnoknowledgeModalComponent } from '../app/components/knoknowledge-modal/knoknowledge-modal.component';
import { ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { PremiumLockComponent } from './premium/premium-lock/premium-lock.component';
import { ImgPreviewComponent } from './img-preview/img-preview.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalController: ModalController) { }


  async PremiumLock(data) {

    let classType = 'modalLookLikeNativ'
    let classType2 = 'modalLookfullWidth'
    console.log('modallll_PremiumLock')

    var modal = await this.modalController.create({
      component: PremiumLockComponent,
      swipeToClose: false,
      cssClass: classType2,
      backdropDismiss: false,
      mode: "ios"

      //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal


      // Get the top-most ion-modal

    });

    await modal.present();
    modal.onDidDismiss().then(res => {
      console.log(res);
      if (res.data) {

      }
    })
    return modal;
  }



  dismissModal() {
    this.modalController.dismiss()
  }

  async presentModalKnowoge(swichClass) {
    let classType = 'modalLookLikeNativ'
    let classType2 = 'modalLookfullWidth'


    var modal = await this.modalController.create({
      component: KnoknowledgeModalComponent,
      swipeToClose: false,
      cssClass: classType2,
      backdropDismiss: false,
      mode: "ios",
      componentProps: { "titleData": swichClass }

      //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
      // Get the top-most ion-modal

    });

    await modal.present();
    const { data } = await modal.onWillDismiss();


  }
  openValidNumberModal;

  async openValidNumber(from) {
    let classType2 = 'modalLookfullWidth'

    const isModalOpened = await this.modalController.getTop();
    console.log('isModalOpened', isModalOpened)
    console.log('this.openValidNumberModal', this.openValidNumberModal)
    if (!isModalOpened || (from == 'userUpdate')) {

      this.openValidNumberModal = await this.modalController.create({
        component: LoginPhoneComponent,
        swipeToClose: false,
        cssClass: classType2,
        backdropDismiss: false,
        mode: "ios",
        // componentProps:

        //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
        // Get the top-most ion-modal

      });

      await this.openValidNumberModal.present();
      const { data } = await this.openValidNumberModal.onWillDismiss();
    }



  }






  async open_Preview_img(img_path) {


    let classType = 'modalLookLikeNativ';
    let classType2 = 'modalLookfullWidth';


    var modal = await this.modalController.create({
      component: ImgPreviewComponent,
      swipeToClose: true,
      cssClass: classType2,
      backdropDismiss: true,
      mode: "ios",
      componentProps: { img: img_path }

      //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
      // Get the top-most ion-modal

    });

    await modal.present();
    const { data } = await modal.onWillDismiss();




  }






}
