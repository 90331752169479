import { AuthService } from './../../providers/auth-service';
import { Router } from '@angular/router';
import { routes } from './../../pages/tabs/tabs.module';
import { OpeninghoursModalComponent } from './../../components/openinghours-modal/openinghours-modal.component';
import { ShopService } from './../../ShopService/shop.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonSlides, MenuController, ModalController } from '@ionic/angular';
// import { ConsoleReporter } from 'jasmine';
import { LoadingService } from '../../providers/loading.service';
import { TasksService } from '../tasks/tasks-service/tasks.service';
import { ContactListService } from '../contact-list/contact-list-service/contact-list.service';
import { ContactListComponent } from '../contact-list/contact-list.component';
import { userService } from 'src/ShopService/user.service';
import { PremiumService } from 'src/app/premium/premium-service/premium.service';
import { ChoosePackageComponent } from '../choose-package/choose-package.component';


@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent implements OnInit {
  slideWithNav;
  service;
  arrayTime = [];
  mySetting: any;
  skipStatus = "start";
  selectAll = true;
  choosePathModal = false;
  path = '';

  @ViewChild('slides', {
    read: "",
    static: false
  }) ionSlides: IonSlides;

  disablePrevBtn = true;
  disableNextBtn = false;
  slideOpts = {
    initialSlide: 1,
    speed: 400,


  };

  constructor(public shopS: ShopService,
    private router: Router,
    private contactsS: ContactListService,
    private LoadingService: LoadingService,
    private menuController: MenuController,
    private taskS: TasksService,
    private us: userService,
    private alertController: AlertController,
    private modalController: ModalController,
    private premiumService: PremiumService,
    private auth: AuthService) {
    this.menuController.enable(false);

    // this.shopS.nextSlide.subscribe(x=>{
    //   console.log(x);
    //   switch (x) {
    //     case 1:
    //       this.ionSlides.lockSwipes(false)
    //       this.ionSlides.slideNext(400);
    //       this.ionSlides.lockSwipes(true)

    //       break;

    //     default:
    //       break;
    //   }
    // })
  }





  async goTOaddContacts() {
    console.log(this.shopS.shopData);
    this.shopS.shopData['Users'] = {};
    let point_contact_list_premission = localStorage.getItem('point_contact_list_premission');
    if (point_contact_list_premission == 'true') {
      console.log('point_contact_list_premission', point_contact_list_premission)
      this.LoadingService.presentforever_with_text('טוען אנשי קשר');
      this.open_contacts();
    } else {
      console.log('point_contact_list_premission', point_contact_list_premission)



      var textT = `<img class='imgalert2 contactimg' src="../../assets/contactlist.png" >`;
      textT += 'לאחר הודעה זו תופיע בקשת גישה לאנשי הקשר במכשיר שלך. חשוב לאשר את הבקשה';
      const alert = await this.alertController.create({
        header: 'ייבוא מאנשי קשר',
        message: textT,
        buttons: [
          {
            text: 'מאוחר יותר',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Cancel Cancel: Cancel');
              return false;
            }
          }, {
            text: 'מתן הרשאות',
            handler: () => {

              console.log('Okay: Okay');
              //confirm order 
              this.LoadingService.presentforever_with_text('טוען אנשי קשר');
              this.open_contacts();

            }
          }
        ]
      });
      await alert.present();
    }







    //  this.LoadingService.presentLoadingContact()


  }
  open_contacts() {
    this.shopS.log(this.shopS.us.key, 'open_contacts_from_tutorial');

    this.contactsS.loadContacts(cb => {
      console.log(cb);
      this.LoadingService.dismiss();

      if (cb) {
        localStorage.setItem('point_contact_list_premission', 'true')
        this.newModal();
      }
    })
  }


  async newModal() {

    const modal = await this.modalController.create({
      component: ContactListComponent,
      cssClass: 'modalLookLikeNativ',
      mode: 'ios',
    });
    return await modal.present();
  }









  doCheck() {
    let prom1 = this.ionSlides.isBeginning();
    let prom2 = this.ionSlides.isEnd();

    Promise.all([prom1, prom2]).then((data) => {
      data[0] ? this.disablePrevBtn = true : this.disablePrevBtn = false;
      data[1] ? this.disableNextBtn = true : this.disableNextBtn = false;
    });
  }
  nextSlide(types) {
    var newarrayservice = [{
      "approve": false,
      "color": "#434a54",
      "flow": false,
      "hidden": true,
      "info": "987654",
      "ischecked": true,
      "key": "19",
      "meetTime": "30",
      "name": "כללי",
      "price": 0,
      "reminder": 30,
    }];
    // this.shopS.openingHoursmodalFLAG = !this.shopS.openingHoursmodalFLAG;
    if (types == 2) {
      console.log(this.service);
      this.service.forEach(element => {
        if (element.ischecked == true) {
          newarrayservice.push(element)

        }
      });



      // console.log(newarrayservice);
      if (newarrayservice.length > 0) {
        this.shopS.updateBarberService(newarrayservice).then(res => {
          // console.log(res)
        })

      }
    }
    // this.ionSlides.lockSwipes(false)
    this.ionSlides.slideNext(400);
    // this.ionSlides.lockSwipes(true)
  }
  changeTo;

  changeSelection() {

    this.selectAll = !this.selectAll;
    //console.log(this.selectAll);
    if (this.selectAll == false) {
      this.changeTo = false;
    } else {
      this.changeTo = true;
    }
    if (this.service) {
      for (var i = 0; i < this.service.length; i++) {
        this.service[i].ischecked = this.changeTo;
      }
    }
  }

  nextSlideSimple() {
    this.shopS.openingHoursmodalFLAG = false;
    if (this.skipStatus == "end") {
      this.finsihTutorial();
    } else {
      // this.ionSlides.lockSwipes(false)
      this.ionSlides.slideNext(400);
      // this.ionSlides.lockSwipes(true)
    }

  }

  choosePath(path) {
    // this.premiumService.PremiumLock("go");
    this.path = path;
  }

  // goto_contact_list() {

  //   this.nextSlideSimple();
  //   // this.goTOaddContacts();
  // }


  endSlide() {
    console.log("end slide")
    this.skipStatus = "end";
  }



  task_flag = true;

  give_new_task() {

    if (this.task_flag) {

      this.shopS.shopData.Setting['BarberID'] = this.shopS.shopID;
      console.log('this.shopS.shopID', this.shopS.shopID)
      console.log('shopS.shopData before add task!', this.shopS.shopData);
      this.taskS.new_task(this.taskS.default_task);

      this.task_flag = false;

    }


  }

  // sendLogFinishTut(){
  //   let data = {
  //     user: this.shopS.us.user,
  //     buisness: this.shopS.shopData
  //   }
  //   console.log('biz_register_finish_tuturial log data', data);
  //   this.shopS.logWithDataJSON(this.shopS.us.key, 'biz_register_finish_tuturial',{}, JSON.stringify(data));

  // }

  finsihTutorial() {
    // this.shopS.log(this.shopS.us.key, 'biz_register_finish_tuturial');
    this.LoadingService.present();
    
    let data = {
      user: this.shopS.us.user,
      buisness: this.shopS.shopData
    }
    console.log('biz_register_finish_tuturial log data', data);
    this.shopS.logWithDataJSON(this.shopS.us.key, 'biz_register_finish_tuturial', {}, JSON.stringify(data));

    if (this.shopS.configDATA.new_open_premium != this.shopS.configDATA.currentVersion) {

      this.give_new_task();
    }
    localStorage.setItem('type', 'admin');

    this.shopS.updateFinisht().then(res => {
      // this.shopS.confettiFLAG = false;
      this.shopS.turnOnConfetti(6300);
      // this.shopS.choosePackageModal = true;
      if(!this.shopS.shopData.Setting.collaborator){

        this.shopS.openModal(ChoosePackageComponent, 'modalLookfullWidth', false, null);
      }
      // setTimeout(() => {
      //   // this.shopS.confettiFLAG = true;
      //   this.shopS.turnOnConfetti();
      // }, 6300);
      // setTimeout(() => {
      if (this.shopS.platform != 'desktop') {
        this.shopS.explainerFLAF = true;
      }
      // }, 5300);
      // this.auth.logOutForLogin();
      this.auth.checkType(this.us.user);
      // this.router.navigate(['/tabsAdmin/clients'],{replaceUrl:true});
    })
  }




  logout() {
    this.shopS.openingHoursmodalFLAG = false;
    this.auth.logout();
  }

  ionViewDidEnter() {
    this.shopS.tutrialMode = true;
    //haim change
    this.shopS.openingHoursmodalFLAG = false;
    if (this.shopS.buisType == "") {
      this.shopS.buisType = "barber";
    }
    var LoadService = this.shopS.LoadService(this.shopS.buisType).subscribe(res => {
      this.service = res;
      this.mySetting = this.shopS.shopData.Setting;
      this.mySetting.cover = this.shopS.configDATA.default.cover[this.shopS.buisType];
      this.mySetting.logo = this.shopS.configDATA.default.profile;


      LoadService.unsubscribe();
    })

    for (var i = 0; i < 2000; i = i + 5) {
      this.arrayTime.push(i)
    }
    // this.ionSlides.lockSwipes(true)

  }
  ngOnInit() {
    // document.addEventListener('backbutton', () => {
    //   console.log('tutorial - - - - backbutton action!');
    //   //  alert('khkjh');
    //   }, false);

    setTimeout(() => {
      // console.log('this.shopS.shopData.WorkDays',this.shopS.shopData.WorkDays);
      this.myDate = JSON.parse(JSON.stringify(this.shopS.shopData.WorkDays));
      this.myDate.forEach((element: any, index) => {
        if (element.timeStart == '00:00' && element.timeEnd == '00:00') {
          this.closeDay[index] = true;
        }
      });

    }, 4000);


  }
  checked(workday) {
    // console.log('showdata==this.myDate',this.myDate,workday)
  }

  showdata() {
    // console.log('showdata==this.myDate',this.myDate);
  }

  changeCkeck(chek) {
    chek = !chek;
  }
  goBack() {
    console.log("backs")
    this.router.navigate(['tabsAdmin/calendar']);
  }
  slideChanged() {

  }
  PushMeetType(meet) {
    meet.ischecked = !meet.ischecked;
    if (!meet.ischecked) {
      this.selectAll = false;
    }
  }

  // nextslide(x) {
  //   console.log("sdsd",this.shopS.openingHoursmodalFLAG)

  //   this.shopS.openingHoursmodalFLAG = !this.shopS.openingHoursmodalFLAG;
  //   // this.slideWithNav.slideTo(x, 500);
  // }


  //Move to Next slide
  slideNext(object, slideView) {

    slideView.slideNext(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  //Move to previous slide
  slidePrev(object, slideView) {
    slideView.slidePrev(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });;
  }

  //Method called when slide is changed by drag or navigation
  SlideDidChange(object, slideView) {
    this.checkIfNavDisabled(object, slideView);
  }

  //Call methods to check if slide is first or last to enable disbale navigation  
  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }
  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }





  closeDay: any = [false, false, false, false, false, false, false];



  myDate: any = [{
    dayName: "א׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },
  {
    dayName: "ב׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },
  {
    dayName: "ג׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },
  {
    dayName: "ד׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },
  {
    dayName: "ה׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },
  {
    dayName: "ו׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },
  {
    dayName: "ש׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },];



  saveHours(event) {
    // console.log('saveHours_____this.myDate',this.myDate);
    this.myDate.forEach((element: any, index) => {
      // console.log('index',index);
      // console.log('this.closeDay[index]',this.closeDay[index]);
      if (this.closeDay[index]) {
        element.timeStart = '00:00';
        element.timeEnd = '00:00';
      }
      //   console.log('element',element);
      let dani = this.shopS.updateWorkDays(index, element.timeStart, element.timeEnd);
      // console.log('---- dani ---',dani);
    });

    this.nextSlide(1);

    //this.shopS.updateWorkDays(key,timeStart,to_value);
  }




  showButtonsFlag = false;






  public imagePath;
  imgURL: any;
  public message: string;
  typeSetting: any;
  currentImg: any;
  currentImg2: any;


  inputchange(event, kind) {
    console.log('-event', event);

    //  console.log('jso[kind]',this.jso[kind]);
    //  console.log('jso',this.jso);
    this.shopS.updateStr('/Setting/', { 'color': event });
  }







  cancelUpload(type) {
    console.log('type: ', type);

    if (type == 'cover') {
      this.showButtonsFlag = false;
      this.shopS.UploadFlags[type] = false;
      (document.getElementById('coverUpload') as HTMLInputElement).value = '';
      //   this.mySetting.cover = "https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/jing.jpg?alt=media&token=3c7eaa0d-9039-42f5-b41e-860f35b90108";
      // 

      this.mySetting.cover = this.shopS.configDATA.default.cover[this.shopS.buisType];
    }
    if (type == 'logo') {
      this.showButtonsFlag = false;
      this.shopS.UploadFlags[type] = false;
      (document.getElementById('logoUpload') as HTMLInputElement).value = '';

      // this.mySetting.logo = "https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/icon.png?alt=media&token=9663ccd6-2c4e-415a-ae30-975e3fa2eb8f";
      this.mySetting.logo = this.shopS.configDATA.default.profile;

    }


  }

  uploadThatImg() {
    console.log('uploadThatImg', this.currentImg, this.typeSetting);
    this.LoadingService.present();

    let result = this.shopS.uploadImg(this.currentImg, '/Setting/', this.typeSetting).then(value => {
      console.log('value:', value);
      this.showButtonsFlag = false;
      this.shopS.UploadFlags['logo'] = false;
      this.shopS.UploadFlags['cover'] = false;
      setTimeout(() => {
        this.LoadingService.dismiss();
      }, 500);

    });
    console.log('result:', result);
  }

  preview(event, where) {
    this.typeSetting = where;
    this.currentImg = event;
    // console.log('event:',event);
    var filess = event.srcElement.files;
    // console.log('filess:',filess);

    if (filess.length === 0)
      return;

    var mimeType = filess[0].type;
    if (mimeType.match(/image\/*/) == null) {
      // this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(filess[0]);
    reader.onload = (_event) => {
      console.log('reader.result', reader.result);
      console.log('this.mySetting', this.mySetting);
      this.mySetting[where] = reader.result;
      this.shopS.UploadFlags[where] = true;
      this.showButtonsFlag = true;
    }
  }















}
