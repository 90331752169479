import { ModalController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';
import { Component, OnInit } from '@angular/core';
class Port {
  public id: string;
  public name: string;
  public data: object;
  public price: number;
}

@Component({
  selector: 'app-service-search-modal',
  templateUrl: './service-search-modal.component.html',
  styleUrls: ['./service-search-modal.component.scss'],
})
 


export class ServiceSearchModalComponent implements OnInit {


  data_Array;
  searchText:any = '';
  ports: Port[];
  ports2: Port[];
  selected_item:any;

  constructor(private shopS:ShopService,
              private modalController:ModalController) { }

  

  ngOnInit(){
    this.data_Array = this.shopS.json2array(this.shopS.shopData.MeetingType);
   console.log(this.data_Array)
    this.ports = [];


    // this.shopS.Auser.key= this.shopS.shopData.Setting.userManager;


    this.data_Array.forEach(element => {

        this.ports.push({
          id: element.key,
          name: element.name,
          price: element.price,
          data: element
        })
    });
  
    this.ports2=this.ports;
    console.log(this.ports)
  }

  filterItems() {
    if(this.searchText.length > 0){

    
    this.ports2 = this.ports.filter(item => {
      return item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
    });
  }else{
    this.ports2=this.ports;
  }
  }

  selectUser(data) {

this.modalController.dismiss(data)


  }


}

