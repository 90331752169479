import { AdminHistoryComponent } from './../../app/admin-history/admin-history.component';
import { EventModalComponent } from './../../app/event-modal/event-modal.component';
import { SettingsModalComponent } from './../../app/components/settings-modal/settings-modal.component';
import { PipeModule } from './../../app/pipe/pipe.module';
import { PremiumPopoverComponent } from './../../app/premium-popover/premium-popover.component';
import { UserSearchModalComponent } from './../../app/user-search-modal/user-search-modal.component';
import { PopoverComponent } from './../../app/popover/popover.component';
// import { TutorialComponent } from './../../app/tutorial/tutorial.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { TabsAdminPage } from './tabsAdmin.page';
import { NgCalendarModule } from 'ionic2-calendar';

import { ClientsPage } from '../clients/clients.page';
import { AddLineAdminPage } from '../add-line-admin/add-line-admin.page';
//import { NotificationBellComponent } from '../../components/notification-bell/notification-bell.component';
import { ClientModalComponent } from '../../components/client-modal/client-modal.component';
import { addClientModalComponent } from '../../components/add-client-modal/add-client-modal.component';

import { ExplainerCoverComponent } from '../../components/explainer-cover/explainer-cover.component';

import { MeetModalComponent } from '../../components/meet-modal/meet-modal.component';
import { OpeninghoursModalComponent } from '../../components/openinghours-modal/openinghours-modal.component';
import { CalendarSettingsModalComponent } from '../../components/calendar-settings-modal/calendar-settings-modal.component';
import { MeetTypeModalComponent } from '../../components/meet-type-modal/meet-type-modal.component';

//import {  } from '../';
import { BusinessProfileAdminPage } from '../business-profile-admin/business-profile-admin.page';
import { CalendarPage } from '../calendar/calendar.page';

//import { MeetnotifiPipe } from '../../app/meetnotifi.pipe';
import { MeetcalendarPipe } from '../../app/meetcalendar.pipe';
import { AboutPage } from 'src/pages/about/about';
import { KeysPipe } from '../../pipes/keys2.pipe';

import { AddcontactPage } from '../addcontact/addcontact.page';

// import { CardModule } from 'ngx-card/ngx-card';
import { NotificationBellModule } from 'src/components/notification-bell/notification-bell.module';

import { NotificationToAllComponent } from "../../app/notification-to-all/notification-to-all.component";
import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
import { ViewUiComponent } from 'src/app/view-ui/view-ui.component';
// import { DmenuComponent } from 'src/app/dmenu/dmenu.component';
import { MainModule } from 'src/app/main/main.module';

import { ServiceSearchModalComponent } from "../../app/service-search-modal/service-search-modal.component";
import { AuthGuard } from 'src/services/auth-guard.service';
import { PremiumModule } from 'src/app/premium/premium.module';
import { SetupInvoiceSupplierComponent } from 'src/app/invoice/setup-invoice-supplier/setup-invoice-supplier.component';
import { WalletPageModule } from 'src/app/wallet/wallet.module';
import { WalletSetupComponent } from 'src/app/wallet/wallet-setup/wallet-setup.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ContactListModule } from 'src/app/contact-list/contact-list.module';
import { ColorPickerModule } from 'src/app/color-picker/color-picker.module';
import { GoogleCalendarPageModule } from 'src/app/google-calendar/google-calendar.module';
import { HolidayListComponent } from 'src/app/holiday-list/holiday-list.component';
import { InvoiceNewDocumentComponent } from 'src/app/invoice/invoice-new-document/invoice-new-document.component';
import { InvoicePopupComponent } from 'src/app/invoice/invoice-popup/invoice-popup.component';
import { LeadModalComponent } from 'src/app/lead-modal/lead-modal.component';
import { ChoosePackageComponent } from 'src/app/choose-package/choose-package.component';
import { PremiumGiftComponent } from 'src/components/premium-gift/premium-gift.component';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ExclusivePopoverComponent } from 'src/app/exclusive-popover/exclusive-popover.component';
import { LottieModule } from 'ngx-lottie';
import { WalletPopoverComponent } from 'src/app/wallet-popover/wallet-popover.component';
// import { LottieModule } from 'ngx-lottie';
// import player from 'lottie-web';
// export function playerFactory() {
//   return player;
// }
export const routes: Routes = [
  {
    path: 'tabsAdmin',
    component: TabsAdminPage,
    children: [
      { path: 'clients', component: ClientsPage, canActivate: [AuthGuard] },
      { path: 'add', component: AddLineAdminPage },
      { path: 'calendar', component: CalendarPage },
      { path: 'business', component: BusinessProfileAdminPage },
      { path: 'about', component: AboutPage },
      { path: 'contacts', component: AddcontactPage },

    ]
  },
  {
    path: '',
    redirectTo: 'tabsAdmin/calendar',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    TabsAdminPage,
    UserSearchModalComponent,
    //  NotificationBellComponent,
    AddcontactPage,
    ClientModalComponent,
    PremiumPopoverComponent,
    PopoverComponent,
    EventModalComponent,
    ViewUiComponent,
    ExplainerCoverComponent,
    addClientModalComponent,
    MeetModalComponent,
    NotificationToAllComponent,
    AdminHistoryComponent,
    HolidayListComponent,
    OpeninghoursModalComponent,
    CalendarSettingsModalComponent,
    MeetTypeModalComponent,
    SettingsModalComponent,
    //  MeetnotifiPipe,
    PremiumGiftComponent,
    AboutPage,
    MeetcalendarPipe,
    KeysPipe,
    CalendarPage,
    ServiceSearchModalComponent,
    BusinessProfileAdminPage,
    AddLineAdminPage,
    // CalendarPage,
    // BusinessProfileAdminPage
    ClientsPage,
    LeadModalComponent,
    ExclusivePopoverComponent,
    WalletPopoverComponent,
    ChoosePackageComponent,
    WalletSetupComponent,
    SetupInvoiceSupplierComponent,
    InvoiceNewDocumentComponent,
    InvoicePopupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NotificationBellModule,
    // InfiniteScrollModule,
    // DmenuComponent,
    MainModule,
    LottieModule,
    PremiumModule,
    WalletPageModule,
    Ng2SearchPipeModule,
    ContactListModule,
    ColorPickerModule,
    // DmenuComponent,
    // CardModule,
    GoogleCalendarPageModule,
    NgCalendarModule,
    PipeModule,
    Ionic4DatepickerModule,
    // LottieModule.forRoot({ player: playerFactory }),
    // TabsAdminPageRoutingModule,
    RouterModule.forChild(routes)
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  entryComponents: [
    PopoverComponent,
    PremiumPopoverComponent,
    NotificationToAllComponent,
    EventModalComponent,
    // DmenuComponent,
    AdminHistoryComponent,
    HolidayListComponent,
    MeetModalComponent,
    PremiumGiftComponent,
    LeadModalComponent,
    WalletPopoverComponent,
    ExclusivePopoverComponent,
    ChoosePackageComponent,
    SettingsModalComponent,
    UserSearchModalComponent,
    MeetTypeModalComponent,
    ServiceSearchModalComponent,
    WalletSetupComponent,
    SetupInvoiceSupplierComponent,

    CalendarSettingsModalComponent,
    ViewUiComponent,
    InvoiceNewDocumentComponent,
    InvoicePopupComponent
  ],
  providers: [OpeninghoursModalComponent,    NgxImageCompressService,
  ],
  exports: [
    WalletPageModule,
  ]



})
export class TabsAdminPageModule { }
