import { Injectable } from '@angular/core';
import { ShopService } from '../.././../ShopService/shop.service';
import { FingerprintAIO, FingerprintOptions } from "@ionic-native/fingerprint-aio/ngx";
import { userService } from '../../../ShopService/user.service';
import { Platform } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { CryptoService } from "../../../app/crypto.service";
import { ToastService } from "../../../providers/toast-service";
import { LoadingService } from "../../../providers/loading.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { WalletSetupComponent } from '../wallet-setup/wallet-setup.component';
import * as firebase from 'firebase/app';
import { env } from 'src/app/app.module';
import { WalletModalInfoComponent } from '../wallet-modal-info/wallet-modal-info.component';
import { WalletMeetSettingsComponent } from '../wallet-meet-settings/wallet-meet-settings.component';
import { WalletTransferMoneyComponent } from '../wallet-transfer-money/wallet-transfer-money.component';
import { WalletShowNewSalePage } from '../wallet-show-new-sale/wallet-show-new-sale.page';

@Injectable({
  providedIn: 'root'
})
export class WalletServiceService {

  constructor(
    public afDb: AngularFireDatabase,
    public modalController: ModalController,
    public Firestore: AngularFirestore,
    public http: HttpClient,
    public CryptoService: CryptoService,
    public ToastService: ToastService,
    public LoadingService: LoadingService,
    public userService: userService,
    public shopS: ShopService,
    public faio: FingerprintAIO,
    public plt: Platform,
    public alertController: AlertController
  ) {

    firebase.auth().onAuthStateChanged(a => {
      console.log('firebase.auth().onAuthStateChanged', a);
      if (a == null) {
        this.resetWallet();
      }
    })
  }

  sales_array: any = [];
  current_document: any;
  current_document_popover = false;


  toast_message = {

    toast_warning_data: {
      text: 'רק לאחר העלאת מסמכים ניתן יהיה להפקיד את הכסף לחשבון הבנק שלך',
      type: 'warning',//warning,ongoing
      position: 'bottom',
      backdrop: true
    },

    toast_warning_data_inline: {
      text: 'רק לאחר העלאת מסמכים ניתן יהיה להפקיד את הכסף לחשבון הבנק שלך',
      type: 'warning',//warning,ongoing
      position: 'inline',
      backdrop: false
    },

    toast_ongoing_data_inline: {
      text: 'רק לאחר בדיקת המסמכים ניתן יהיה להפקיד את הכסף לחשבון הבנק שלך',
      type: 'ongoing',//warning,ongoing
      position: 'inline',
      backdrop: false
    },
    toast_ongoing_data: {
      text: 'רק לאחר בדיקת מסמכים ניתן יהיה להפקיד את הכסף לחשבון הבנק שלך',
      type: 'ongoing',//warning,ongoing
      position: 'bottom',
      backdrop: true
    },

  }








  // 
  load_seller = false;

  current_seller: any = {}
  show_new_sale: any = {}
  info_modal = 'contact-info';

  current_toast_message = {
    toast_data: {},
    toast_data_inline: {},
  }
  modal_view_doc: any;
  view_doc: any;
  // 
  loaded_before = false;

  generate_sale: any = {
    sale_price: "1000",
    seller_payme_id: "",
    buyer_key: "",
    product_name: "Product",
    currency: "ILS",
    sub_callback_url: env.cloudfunctions + "/paymentResposePayme",
    // sale_return_url:"http://127.0.0.1:3000/api/collect",
    sale_send_notification: false,
    sale_email: "matan@webc.co.il",
    sale_name: "bus name",
    sale_mobile: '',
    installments: "1",
    sale_payment_method: "bit",
    language: "he",
    transaction_id: '',
    // sale_type: ""
  }

  generate_subscription: any = {
    sub_price: "1000",
    seller_payme_id: "",
    buyer_key: "",
    sub_description: "Product",
    sub_currency: "ILS",
    sub_callback_url: env.cloudfunctions + "/paymentResposePayme",
    // sale_return_url:"http://127.0.0.1:3000/api/collect",
    sub_iteration_type: "3",
    sub_iterations: null,
    sub_start_date: null,
    sale_send_notification: false,
    transaction_id: '',
    // sale_email: "matan@webc.co.il",
    // sale_name: "bus name",
    // sale_mobile: '',
    // installments: "1",
    // sale_payment_method: "bit",
    language: "he",
    sale_type: "",
    sub_type: "template",
    subscription_id: ''
  }

  banks = [
    {
      name: 'בנק יהב',
      src: '../../../assets/imgs/banks/yahav.png',
      value: 4
    },
    {
      name: 'בנק הדואר',
      src: '../../../assets/imgs/banks/doar.gif',
      value: 9
    },
    {
      name: 'בנק לאומי',
      src: '../../../assets/imgs/banks/leumi.svg',
      value: 10
    },
    {
      name: 'בנק דיסקונט',
      src: '../../../assets/imgs/banks/discount.svg',
      value: 11
    },
    {
      name: 'בנק הפועלים',
      src: '../../../assets/imgs/banks/hapoalim.svg',
      value: 12
    },
    {
      name: 'בנק אגוד לישראל',
      src: '../../../assets/imgs/banks/igud.svg',
      value: 13
    },
    {
      name: 'בנק אוצר החייל',
      src: '../../../assets/imgs/banks/otzar_hahyal.jpg',
      value: 14
    },
    {
      name: 'מרכנתיל דיסקונט',
      src: '../../../assets/imgs/banks/marcantile.png',
      value: 17
    },

    {
      name: 'בנק מזרחי טפחות',
      src: '../../../assets/imgs/banks/mizrahi.svg',
      value: 20
    },
    {
      name: 'סיטיבנק',
      src: '../../../assets/imgs/banks/citi.svg',
      value: 22
    },
    {
      name: 'HSBC',
      src: '../../../assets/imgs/banks/hsbc.svg',
      value: 23
    },
    {
      name: 'UBank',
      src: '../../../assets/imgs/banks/ubank.png',
      value: 26
    },
    {
      name: 'הבינלאומי',
      src: '../../../assets/imgs/banks/benleumi.jpg',
      value: 31
    },
    {
      name: 'בנק ערבי ישראל',
      src: '../../../assets/imgs/banks/ai.png',
      value: 34
    },
    {
      name: 'Bank of India',
      src: '../../../assets/imgs/banks/sbi.jpg',
      value: 39
    },
    {
      name: 'בנק מסד',
      src: '../../../assets/imgs/banks/massad.png',
      value: 46
    },
    {
      name: 'בנק פאגי',
      src: '../../../assets/imgs/banks/pagi.svg',
      value: 52
    },
    {
      name: 'בנק ירושלים',
      src: '../../../assets/imgs/banks/jerusalem.png',
      value: 54
    },
    {
      name: 'דקסיה ישראל',
      src: '../../../assets/imgs/banks/municipal.jpg',
      value: 68
    },
    {
      name: 'לאומי למשכנתאות',
      src: '../../../assets/imgs/banks/leumi_mashkanta.svg',
      value: 77
    },
    {
      name: 'דיסקונט למשכנתאות',
      src: '../../../assets/imgs/banks/discount_mashkanta.png',
      value: 90
    },
    {
      name: 'משכן למשכנתאות',
      src: '../../../assets/imgs/banks/mishkan.jpg',
      value: 91
    },
    {
      name: 'הבינלאומי למשכנתאות',
      src: '../../../assets/imgs/banks/benleumi_mashkanta.jpg',
      value: 92
    }
  ]

  businesses_types = [
    {
      name: 'אביזרי אופנה',
      value: 1608
    },
    {
      name: 'שירותי ייעוץ',
      value: 1816
    },
    {
      name: 'אהילים ונברשות',
      value: 1313
    },
    {
      name: 'אולם שמחות',
      value: 1504
    },
    {
      name: 'אופנועים',
      value: 119
    },
    {
      name: 'אופניים',
      value: 114
    },
    {
      name: 'אופטיקה',
      value: 1408
    },
    {
      name: 'שמרטפים',
      value: 1118
    },
    {
      name: 'אכסניות וכפרי נופש',
      value: 1507
    },
    {
      name: 'אסטרולוגיה גרפולוגיה',
      value: 1511
    },
    {
      name: 'ארנק אלקטרוני',
      value: 1814
    },
    {
      name: 'ארנקים תיקים ומזודות',
      value: 1212
    },
    {
      name: 'אתרי בידור',
      value: 1510
    },
    {
      name: 'בגדי גברים',
      value: 1201
    },
    {
      name: 'בגדי ילדים',
      value: 1203
    },
    {
      name: 'בגדי נשים',
      value: 1202
    },
    {
      name: 'בדי רפוד',
      value: 1207
    },
    {
      name: 'בדים וטכסטיל',
      value: 1213
    },
    {
      name: 'בזאר',
      value: 1106
    },
    {
      name: 'בזק',
      value: 1705
    },
    {
      name: 'בטוח',
      value: 1606
    },
    {
      name: 'ביטוח לאומי',
      value: 1709
    },
    {
      name: 'בית לוויות',
      value: 1822
    },
    {
      name: 'בית ספר לנהיגה',
      value: 1615
    },
    {
      name: 'בניית אתרי אינטרנט',
      value: 1831
    },
    {
      name: 'בשר דגים ועופות',
      value: 1105
    },
    {
      name: 'בתי דפוס',
      value: 1512
    },
    {
      name: 'בתי הבראה ומרגוע',
      value: 1506
    },
    {
      name: 'בתי כל בו',
      value: 1001
    },
    {
      name: 'בתי כל בו ארציים',
      value: 1004
    },
    {
      name: 'בתי מלון ופנסיון',
      value: 1505
    },
    {
      name: 'בתי מרקחת',
      value: 1406
    },
    {
      name: 'בתי מרקחת חו"ל',
      value: 1818
    },
    {
      name: 'בתי ספר',
      value: 1114
    },
    {
      name: 'בתי קפה ומילק בר',
      value: 1503
    },
    {
      name: 'גלידריות',
      value: 1627
    },
    {
      name: 'גלריות',
      value: 1314
    },
    {
      name: 'גמח הלוואות',
      value: 1836
    },
    {
      name: 'גני ילדים',
      value: 1220
    },
    {
      name: 'דיסקוטק/פאבים',
      value: 1508
    },
    {
      name: 'דלק לחימום ביתי',
      value: 1321
    },
    {
      name: 'דלק שרות עצמי',
      value: 116
    },
    {
      name: 'דרגאסטורים',
      value: 1003
    },
    {
      name: 'דשנים וכימיקלים',
      value: 1125
    },
    {
      name: 'הובלות והסעות',
      value: 1120
    },
    {
      name: 'הוצאות לאור',
      value: 1513
    },
    {
      name: 'היכרויות ושדוכים',
      value: 1607
    },
    {
      name: 'היפר שוק תנובה',
      value: 1800
    },
    {
      name: 'עצוב אפנה',
      value: 1200
    },
    {
      name: 'הלוואות פקדונות חסכו',
      value: 1803
    },
    {
      name: 'העברת כספים לכרטיס',
      value: 1804
    },
    {
      name: 'תקליטן DJ',
      value: 1830
    },
    {
      name: 'הרבלייף',
      value: 1620
    },
    {
      name: 'השכרת רכב',
      value: 1311
    },
    {
      name: 'וידאו אוטומט',
      value: 117
    },
    {
      name: 'וילונות ודקורטיבים',
      value: 1215
    },
    {
      name: 'ועדי בתים',
      value: 1621
    },
    {
      name: 'חב. ציי רכב (כ.דלק)',
      value: 1419
    },
    {
      name: 'חברות בניה',
      value: 1625
    },
    {
      name: 'חברות עירוניות',
      value: 1704
    },
    {
      name: 'חברות תעופה',
      value: 118
    },
    {
      name: 'חברת חשמל',
      value: 1707
    },
    {
      name: 'חומרי ניקוי',
      value: 1415
    },
    {
      name: 'חיות',
      value: 1413
    },
    {
      name: 'חלפני כספים',
      value: 1630
    },
    {
      name: 'חלקי חלוף לרכב',
      value: 1310
    },
    {
      name: 'חנויות ללא מכס',
      value: 1315
    },
    {
      name: 'חניוני נופש',
      value: 1509
    },
    {
      name: 'חניונים',
      value: 1808
    },
    {
      name: 'שירותי בטחון ושמירה',
      value: 1113
    },
    {
      name: 'טבק וצרכי עישון',
      value: 1412
    },
    {
      name: 'טלפונים סלולריים',
      value: 1626
    },
    {
      name: 'טפטים',
      value: 1214
    },
    {
      name: 'כובעים',
      value: 1210
    },
    {
      name: 'כלי בית ומטבח',
      value: 1300
    },
    {
      name: 'כלי מיטה',
      value: 1217
    },
    {
      name: 'כלי נגינה ותקליטים',
      value: 1414
    },
    {
      name: 'כלי עבודה ובנין צבע',
      value: 1401
    },
    {
      name: 'כלי רכב',
      value: 1309
    },
    {
      name: 'לבני נשים וגברים',
      value: 1205
    },
    {
      name: 'לוטו טוטו',
      value: 1825
    },
    {
      name: 'ליסינג',
      value: 1622
    },
    {
      name: 'מבוגרים',
      value: 1806
    },
    {
      name: 'מגרשי מכוניות משומשות',
      value: 1122
    },
    {
      name: 'מדליות מטבעות ובולים',
      value: 1112
    },
    {
      name: 'מוניות',
      value: 1123
    },
    {
      name: 'מוסכים',
      value: 1312
    },
    {
      name: 'מועדוני ספורט',
      value: 111
    },
    {
      name: 'מועצות ועיריות',
      value: 1418
    },
    {
      name: 'מופעי בידור ותרבות',
      value: 1500
    },
    {
      name: 'מוצרי גומי ופלסטיק',
      value: 1402
    },
    {
      name: 'מוצרי גז',
      value: 1302
    },
    {
      name: 'מוצרי חשמל גדולים',
      value: 1301
    },
    {
      name: 'מוצרי חשמל קטנים',
      value: 1219
    },
    {
      name: 'מוצרי פרסום',
      value: 1614
    },
    {
      name: 'מזון טבעי',
      value: 1110
    },
    {
      name: 'מזון מהיר',
      value: 1817
    },
    {
      name: 'מזכרות מתנות חפצי חן',
      value: 1305
    },
    {
      name: 'מחנאות וספורט',
      value: 1411
    },
    {
      name: 'מחשבים',
      value: 1116
    },
    {
      name: 'נגריות/רפדיות',
      value: 1128
    },
    {
      name: 'מים ומטהרי מים',
      value: 1802
    },
    {
      name: 'מינימרקטים',
      value: 1101
    },
    {
      name: 'מכבסות/מתפרות',
      value: 1619
    },
    {
      name: 'מכון רפואי',
      value: 1628
    },
    {
      name: 'מכונות כתיבה/תפירה',
      value: 1124
    },
    {
      name: 'מכונות לתעשיה חקלאות',
      value: 1308
    },
    {
      name: 'מכוני לימוד והדרכה',
      value: 1319
    },
    {
      name: 'מכוני קוסמטיקה',
      value: 1603
    },
    {
      name: 'מכירות פומביות',
      value: 1623
    },
    {
      name: 'מכירת דירות /נדל"ן',
      value: 1711
    },
    {
      name: 'מכירת יחידות נופש',
      value: 1702
    },
    {
      name: 'מכירת כלי רכב',
      value: 1121
    },
    {
      name: 'מכירת מכשירי גז',
      value: 1320
    },
    {
      name: 'מכשירי בריאות',
      value: 112
    },
    {
      name: 'מלכ"ר (מוסד ללא רווח)',
      value: 1218
    },
    {
      name: 'מנויי לוטו טוטו',
      value: 1824
    },
    {
      name: 'מסגרות לתמונות',
      value: 1126
    },
    {
      name: 'מסגריות',
      value: 1127
    },
    {
      name: 'מסחר במט"ח',
      value: 1827
    },
    {
      name: 'מסעדות',
      value: 1501
    },
    {
      name: 'מספרות ופאות',
      value: 1602
    },
    {
      name: 'מעבדות',
      value: 1601
    },
    {
      name: 'מעדניה/קונדיטוריה',
      value: 1103
    },
    {
      name: 'מפעל הפיס/טוטו',
      value: 1009
    },
    {
      name: 'צלמים',
      value: 1409
    },
    {
      name: 'משחקים און ליין',
      value: 1811
    },
    {
      name: 'משקאות חריפים וקלים',
      value: 1108
    },
    {
      name: 'משרדי ממשלה',
      value: 1708
    },
    {
      name: 'משרדי פרסום',
      value: 1317
    },
    {
      name: 'מתן אשרות שהיה',
      value: 1838
    },
    {
      name: 'מתנ"ס',
      value: 1616
    },
    {
      name: 'מתנ"סים',
      value: 113
    },
    {
      name: 'נעליים',
      value: 1208
    },
    {
      name: 'נשק ותחמושת',
      value: 1629
    },
    {
      name: 'סדקית ומוצרי תפירה',
      value: 1211
    },
    {
      name: 'רהיטים',
      value: 1303
    },
    {
      name: 'סוכנויות ביטוח',
      value: 1703
    },
    {
      name: 'סוכני דלק',
      value: 1631
    },
    {
      name: 'סופרמרקטים',
      value: 1100
    },
    {
      name: 'סטקיות ומזנונים',
      value: 1502
    },
    {
      name: 'סיטונאים',
      value: 1624
    },
    {
      name: 'סינמטק/בתי קולנוע',
      value: 1223
    },
    {
      name: 'סלון לכלות',
      value: 1216
    },
    {
      name: 'סליקה פנים קיבוצית',
      value: 1812
    },
    {
      name: 'סניטציה',
      value: 1117
    },
    {
      name: 'סניפים',
      value: 500
    },
    {
      name: 'ספא',
      value: 1810
    },
    {
      name: 'ספק אינטרנט',
      value: 1801
    },
    {
      name: 'ספק תוכן אינטרנט',
      value: 1799
    },
    {
      name: 'ספריות וידיאו',
      value: 1115
    },
    {
      name: 'ספרים',
      value: 1405
    },
    {
      name: 'עו"ד',
      value: 1400
    },
    {
      name: 'עמותות ואגודות',
      value: 1828
    },
    {
      name: 'עמותות חרדיות - תרומות',
      value: 1835
    },
    {
      name: 'עמותות חרדיות-חינוך',
      value: 1834
    },
    {
      name: 'עמותות כלליות',
      value: 1837
    },
    {
      name: 'עמותות מוסלמיות',
      value: 1832
    },
    {
      name: 'עמותות נוצריות',
      value: 1833
    },
    {
      name: 'עתיקות ותשמישי קדושה',
      value: 1306
    },
    {
      name: 'פיצריות',
      value: 1813
    },
    {
      name: 'פירות וירקות',
      value: 1104
    },
    {
      name: 'פרוות ובגדי עור',
      value: 1204
    },
    {
      name: 'פרחים ומשתלות',
      value: 1410
    },
    {
      name: 'ציוד לתינוק',
      value: 1304
    },
    {
      name: 'ציוד משרדי',
      value: 1404
    },
    {
      name: 'ציוד משרדי',
      value: 1613
    },
    {
      name: 'ציוד רפואי',
      value: 1807
    },
    {
      name: 'צמר וסריגה',
      value: 1209
    },
    {
      name: 'צעצועים',
      value: 1416
    },
    {
      name: 'קו-אופ',
      value: 1005
    },
    {
      name: 'קואופ ירושלים',
      value: 1006
    },
    {
      name: 'קונדיטוריות וממתקים',
      value: 1107
    },
    {
      name: 'קופות חולים',
      value: 1007
    },
    {
      name: 'קזינו',
      value: 1710
    },
    {
      name: 'קיוסק',
      value: 1109
    },
    {
      name: 'קייטנה',
      value: 1221
    },
    {
      name: 'קייטרינג',
      value: 1823
    },
    {
      name: 'קפה ותבלינים',
      value: 1821
    },
    {
      name: 'רואה חשבון/מהנדסים',
      value: 1612
    },
    {
      name: 'רכישת ניירות ערך',
      value: 1712
    },
    {
      name: 'רפואת שיניים',
      value: 1600
    },
    {
      name: 'רשות השידור',
      value: 1706
    },
    {
      name: 'רשתות מזון ארציות',
      value: 1002
    },
    {
      name: 'שטיחים',
      value: 1206
    },
    {
      name: 'שיווק ישיר',
      value: 1805
    },
    {
      name: 'שירותי רכב',
      value: 1119
    },
    {
      name: 'שירותים ממשלתיים',
      value: 1316
    },
    {
      name: 'שירותים משרדיים',
      value: 1819
    },
    {
      name: 'שלטים',
      value: 1129
    },
    {
      name: 'שמאים',
      value: 1131
    },
    {
      name: 'שק"ם',
      value: 2000
    },
    {
      name: 'שרותי רפואה/בתי חולי',
      value: 1605
    },
    {
      name: 'תוכנות מחשבים',
      value: 1609
    },
    {
      name: 'תחנות דלק',
      value: 1403
    },
    {
      name: 'תיווך',
      value: 1318
    },
    {
      name: 'תיירות למרפא',
      value: 1829
    },
    {
      name: 'תכשיטים ושעונים',
      value: 1307
    },
    {
      name: 'תמרוקיות',
      value: 1407
    },
    {
      name: 'תנועות נוער',
      value: 1611
    },
    {
      name: 'תעופה תיירות ונסיעות',
      value: 1604
    },
    {
      name: 'תערוכות וכנסים',
      value: 1809
    },
    {
      name: 'תקליטים',
      value: 1417
    },
    {
      name: 'תקשורת וטלפונים',
      value: 1610
    },
    {
      name: 'תקשורת טלויזיה בכבלי',
      value: 115
    },
    {
      name: 'אינטרנט-שונות',
      value: 205
    },
    {
      name: 'תרומה',
      value: 1815
    },
    {
      name: 'תריסים',
      value: 1130
    }
  ]

  businesses_incs = [
    {
      name: 'פרטי',
      value: 0
    },
    {
      name: 'עוסק מורשה',
      value: 1
    },
    {
      name: 'שותפות רשומה',
      value: 3
    },
    {
      name: 'עוסק פטור',
      value: 4
    },
    {
      name: 'עמותה',
      value: 5
    },
    {
      name: 'חברה בע"מ',
      value: 6
    }
  ]

  generate(method) {
    this.LoadingService.present_for(5000);
    if (this.generate_sale.sale_type == "") {
      this.generate_sale.sale_type = undefined;
    }
    this.generate_sale.sale_payment_method = method;
    console.log(this.generate_sale);

    this.send_to_api('generate-sale', this.generate_sale, val => {
      this.shopS.dismissModalController();
      this.LoadingService.dismiss();
      val = val.res;
      console.log('dsds', val)
      this.show_new_sale = val;
      this.shopS.openModal(WalletShowNewSalePage, null, false, { iframe: this.show_new_sale.sale_url });
    });
  }

  async openModal(type, props?) {
    let modal_type;
    this.info_modal = type;
    // let props;
    let css;
    if (props) {
      props.modal_type = type;
    }
    console.log(props);

    switch (type) {
      case 'business-info':
      case 'contact-info':
      case 'bank-info':
      case 'plan-info':
      case 'document-info':
      case 'contact-us':
      case 'agreement-info':
        modal_type = WalletModalInfoComponent;
        css = this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'transparentModal';
        // css = this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalForPayments modal-half-hight';
        break;

      case 'meet-settings':
        modal_type = WalletMeetSettingsComponent;
        css = this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalForPayments modalGreyBG modal-half-hight';
        break;

      case 'wallet-setup':
        modal_type = WalletSetupComponent;
        css = this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalForPayments modalGreyBG modal-half-hight';
        break;

      // case 'payment_link':
      case 'transfer':
      case 'request':
      case 'transfer_credit':
      case 'transfer_bit':
      case 'request_finishPage':
        modal_type = WalletTransferMoneyComponent;
        // props = { modal_type: type, user: user ? user : null };
        props = props;
        css = 'fullscreenModalBlurBG';
        break;

      // case 'wallet-transfer':
      //   modal_type = WalletTransferMoneyComponent;
      //   props = { modal_type: 'transfer' };
      //   css = 'fullscreenModalBlurBG';
      //   break;

      default:
        break;
    }
    console.log(this.modalController);


    const modal = await this.modalController.create({
      component: modal_type,
      componentProps: props,
      swipeToClose: true,
      // cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : css,
      cssClass: css,
      mode: "ios",
    });
    return await modal.present();

  }

  // dismissModal() {
  //   if (this.modalController) {
  //     this.modalController.dismiss({
  //       'dismissed': false
  //     }).then(() => { this.modalController = null; });
  //   }
  // }

  resetWallet() {
    this.load_seller = false;
    this.shopS.wallet_loaded_before = false;

    this.current_seller = {}
    this.show_new_sale = {}
    this.info_modal = 'contact-info';

    this.current_toast_message = {
      toast_data: {},
      toast_data_inline: {},
    }
  }


  // ymR3IiChzsTqVfTL5KhZFQXcurr1
  check_user_wallet() {
    console.log(this.shopS.shopData.Wallet);
    if (this.shopS.shopData.Wallet) {
      if (this.shopS.shopData.Wallet.wallet_id) {
        return true;
      } else {
        // this.setup_wallet();
        return false;
      }
    } else {
      // this.setup_wallet();
      return false;
    }
  }



  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////





  async setup_wallet() {

    var modal = await this.modalController.create({
      component: WalletSetupComponent,
      swipeToClose: false,
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',
      backdropDismiss: true,
      mode: "ios"
    });

    await modal.present();
    modal.onDidDismiss().then(res => {
      console.log(res);
      if (res.data) {

      }
    })
    return modal;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////



  send_to_api(type, data, cb?) {
    if (firebase.auth().currentUser) {


      firebase.auth().currentUser.getIdToken().then(token => {
        this.userService.userToken = token;

        // this.userService.userToken
        // let expired_token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjJjZGFiZDIwNzVjODQxNDI0NDY3MTNlM2U0NGU5ZDcxOGU3YzJkYjQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vd2ViY3V0LWRldiIsImF1ZCI6IndlYmN1dC1kZXYiLCJhdXRoX3RpbWUiOjE2MjcyNDE5NjAsInVzZXJfaWQiOiJFSTcwZ0lqOTluYldZV25hMGtheWxjcG50TW0yIiwic3ViIjoiRUk3MGdJajk5bmJXWVduYTBrYXlsY3BudE1tMiIsImlhdCI6MTYzMDkyOTk5MCwiZXhwIjoxNjMwOTMzNTkwLCJlbWFpbCI6Im1hbWFAbWFtYS5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibWFtYUBtYW1hLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.Omv88w6aLFy-EFYw4J1xJ4tZhywwynSvtaSQSygcne7ZW8TeGzQ5EqVu542yt4ErqG5aipWsLZZGMYd7-of7jv_oCBHkonIQ5Xj1XDXcWs3Fc5FWKUCxDpx5mtJfBTAI57S76423DyhHkuAdy7_ft0cwN08LNCpZMKJwVv2s8wCFloX1eSZc7tHhsFdKuuZ7pNBbv2zoiQNYYNwZ3i_5Dj3UpyL9X3bYBWSBLqMzxVb1_ysEi-8-j0ZtadwsPUmuY0x8FPAabVTk7js6wfQDWwhc2axi3vWZav40fySiI3cfvldWMmPaixJyyGnzBvckHMR_kilWT7wbpcDghW2q0A';
        var reqHeader = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.userService.userToken
          // 'Authorization': 'Bearer ' + expired_token
        });

        let encrypted = this.CryptoService.Encrypt({
          type: type,
          data: data,
          BarberID: this.shopS.shopData.Setting.BarberID
        });
        this.http.post(this.shopS.serverDev + '/walletAPI', encrypted, { headers: reqHeader }).subscribe((response: any) => {
          console.log('response', response);
          if (response.status) {
            cb(response);
          } else {
            cb(false);
            // alert('problem')
            // if(response.res.code == 'auth/id-token-expired'){
            //   // alert('expired');
            //   firebase.auth().currentUser.getIdToken().then(token => {
            //     console.log('TOKEN',token)
            //     this.userService.userToken = token;
            //     this.send_to_api(type,data,cb);
            //   });


            // }
          }
        })// end of http post
      });// end of token

    } else {
      cb({ status: 'no auth' })
    }

  }







  disableSubscription(sub) {
    // this.afDb.object('/Subscription/' + key + '/').update({
    //   active:false,
    //   ccard: 'nocard'
    // });
    console.log('disableSubscription', sub)
    this.send_to_api('cancel-subscription', sub, cb => {
      console.log('cbcbcbcb', cb)
      if (cb.status) {
        this.ToastService.showToast('המנוי בוטל בהצלחה');
        this.shopS.log(this.shopS.us.user.username, 'subscription_canceled');
        // let index = this.mySubscriptionArray.findIndex(sub => sub.sub_payme_id == cb.res.sub_payme_id);
        // this.mySubscriptionArray[index].sub_status = "5"
      } else {
        this.shopS.log(this.shopS.us.user.username, 'subscription_canceled_err', cb);

      }

    })
  }



  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  async presentAlert(title, show) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '',
      subHeader: 'הפרטים הבאים חסרים / לא תקינים',
      message: show,
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async presentAlert2(title, message) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '',
      subHeader: title,
      message: message,
      buttons: ['אישור']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }










  get_total_revenue_from_key(val) {
    // console.log('val', val);
    var totalSum = 0;

    val.forEach(element => {
      if (element.sale_status == "completed") {
        let price = element.sale_price / 100
        totalSum += price;
      }
    });


    return '₪' + totalSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  }
  monthsList = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];

  get_month_from_key(key) {
    //console.log(key)
    let s = key.split('.');
    return this.monthsList[parseInt(s[0])];
  }

  get_year_from_key(key) {
    // console.log(key)
    let s = key.split('.');
    return s[1];
  }

  get_month_from_meetDate(key) {
    let s = key.split('.');
    return this.monthsList[parseInt(s[0]) - 1];
  }
}



