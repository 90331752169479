
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {Platform } from '@ionic/angular';

// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';

// import { File } from '@ionic-native/file/ngx';
// import { FileOpener } from '@ionic-native/file-opener/ngx';
//import { Ionic4DatepickerModalComponent } from 'ionic4-datepicker';
import { ModalController } from '@ionic/angular';
import { UserSearchModalComponent } from 'src/app/user-search-modal/user-search-modal.component';
// import { ServiceSearchModalComponent } from "src/app/service-search-modal/service-search-modal.component";

import { InvoiceServiceService } from "../invoice-service/invoice-service.service";

import { InvoiceTemplateService } from "../invoice-template-service/invoice-template.service";


// import { AlertExample } from 'src/providers/alert.service';

import { ShopService } from "src/ShopService/shop.service";
// import { InvoiceNewDocumentComponent } from "../invoice-new-document/invoice-new-document.component";
// import * as jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';



@Component({
  selector: 'app-invoice-view-document',
  templateUrl: './invoice-view-document.component.html',
  styleUrls: ['./invoice-view-document.component.scss'],
})
export class InvoiceViewDocumentComponent implements OnInit {


  @ViewChild('invoice',{static:false}) invoice: ElementRef;
  constructor(public invoiceS:InvoiceServiceService, private plt: Platform,
    //  private file: File, private fileOpener: FileOpener,  
       public modalCtrl: ModalController,
    public templateS:InvoiceTemplateService,
    // private AlertExample:AlertExample,
    public shopS:ShopService
    
    ) { }

    

    dismissModal() {
      if (this.modalCtrl) {
        this.modalCtrl.dismiss({
          'dismissed': false
        }).then(() => { this.modalCtrl = null; });
      }
    }



    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [day, month, year].join('.');
  }
  


    VAT_amount = 0.17;
    // datePickerObj = {
    //   dateFormat: 'YYYY-MM-DD'
    // };
    selectedDate;

















































  letterObj = {
    to: '',
    from: '',
    text: ''
  }



  async  createFile(fileUrl){


    fetch(fileUrl)
    .then(res => res.blob()) // Gets the response and returns it as a blob
    .then(blob => {
      // Here's where you get access to the blob
      // And you can use it for whatever you want
      // Like calling ref().put(blob)
  
      // Here, I use it to make an image appear on the page
      let objectURL = URL.createObjectURL(blob);
      let myImage = new Image();
      myImage.src = objectURL;
      return myImage;
  });
  

    // let response = await fetch(fileUrl);
    // let data = await response.blob();
    // let metadata = {
    //   type: 'image/jpeg'
    // };
    // return new File([data], "test.jpg", metadata);
    // // ... do something with the file or return it
  }
  
  async getFile(url){
    let blob = await fetch(url).then(r => r.blob());
    return blob;
  }

  ngOnInit() {

    this.set_totals();

  }


  shareSMS(){

  }

shareWhatsApp(){
  
}



////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
totalVAT:any = 0;
totalPrice:any = 0;


totalAmount;

set_totals(){
  this.totalAmount =  this.invoiceS.invoiceObj.services.map(item => (item.price*item.quantity)).reduce((prev, next) => prev + parseInt(next) );


this.totalPrice = this.totalAmount - (this.totalAmount * this.VAT_amount);
this.totalVAT = this.totalAmount - this.totalPrice;

}







////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////






// UserSelected:any = {
//   fname:'',
//   lname: '',
//   name:''
// };



  async openUsersModal(){
    
    var modal = await this.modalCtrl.create({
      component: UserSearchModalComponent,
      swipeToClose: true,
      cssClass: 'modalLookLikeNativMoredown',
      backdropDismiss:true,
      mode:"ios"

    //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal

      
     // Get the top-most ion-modal

    });
  
    

    await modal.present();
    modal.onDidDismiss().then(res=>{
      console.log(res);
      if(res.data){
        res.data['meetings'] = [];
        this.invoiceS.invoiceObj.client = res.data;
     // this.UserSelected=res.data;
    }
    })
    return modal;
}








////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////





}
