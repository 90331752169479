import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationBellComponent } from './notification-bell.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { TasksModule } from "../../app/tasks/tasks.module";

 
@NgModule({
  declarations: [NotificationBellComponent],
  imports: [
    CommonModule,
    PipeModule,
    TasksModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  exports: [NotificationBellComponent]
})
export class NotificationBellModule { }
