import * as moment from 'moment-timezone';
// import { AdminHistoryComponent } from './../../app/admin-history/admin-history.component';
import { ModalNewmeetComponent } from './../../app/modal-newmeet/modal-newmeet.component';
import { AuthService } from './../../providers/auth-service';
import { Component, OnInit, ViewChild, Inject, LOCALE_ID, Self, OnDestroy } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';
import { CalendarComponent } from 'ionic2-calendar/calendar';
import { AlertController, MenuController, ModalController } from '@ionic/angular';
// import { formatDate } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeHe from '@angular/common/locales/he';
registerLocaleData(localeHe);
import { Router } from '@angular/router';

import { PopoverController } from '@ionic/angular';
// import { ReturnStatement, ThrowStmt } from '@angular/compiler';
import { CalendarSettingsModalComponent } from "src/components/calendar-settings-modal/calendar-settings-modal.component";
import { Ionic4DatepickerModalComponent } from '@logisticinfotech/ionic4-datepicker';
import { ToastService } from 'src/providers/toast-service';
import { Subscription } from 'rxjs';
import { AlertExample } from 'src/providers/alert.service';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.page.html',
  styleUrls: ['./calendar.page.scss'],
})
export class CalendarPage implements OnInit, OnDestroy {


  // currentDay = new Date().getDay();

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  chageDatePicker(event) {

    console.log(event)
    console.log(this.currentD)
  }

  currentD: any = '2020-10-08';



  reset_date_time(date) {

  }


  scroolH_fromDate(date) {
    //console.log(date.setHours(0, 0, 0));
    this.shopS.dateForView.forEach((element, index) => {
      //  console.log(element.cal.setHours(0, 0, 0));
      if (element.cal.setHours(0, 0, 0) == date.setHours(0, 0, 0)) {
        //    console.log('froud!!!!',element);
        this.scrollToscrollHY(index)
      }
    });
  }




  scrollToscrollHY(id) {
    var myElement = document.getElementById('clickUpdate' + id);
    console.log(myElement);
    if (myElement) {
      var topPos = myElement.offsetTop;
      myElement.scrollIntoView();
      console.log(topPos);

    }

    //  document.getElementById('scrollHY').scrollTo() = topPos;

  }



  async open_setting_modal() {
    console.log("OPEN SETTING MODAL");

    const modal = await this.modalController.create({
      component: CalendarSettingsModalComponent,
      swipeToClose: false,
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalForPayments modalGreyBG modal-half-hight',

      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
      // Get the top-most ion-modal
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();

  }


  selectedDate;


  async modal_calendar_select() {
    const datePickerModal = await this.modalController.create({
      component: Ionic4DatepickerModalComponent,
      cssClass: 'li-ionic4-datePicker',
      mode: 'ios',
      componentProps: {
        'objConfig': this.datePickerObj,
        'selectedDate': this.shopS.calendar.currentDate
      }
    });
    await datePickerModal.present();

    datePickerModal.onDidDismiss()
      .then((data) => {
        console.log(data);





        if (data.data.date != "Invalid date") {

          // localStorage.setItem("calendarMode", "day");
          // this.shopS.calendar.mode = 'day';



          this.selectedDate = data.data.date;
          //  this.shopS.calendar.currentDate.setDate(this.selectedDate);

          this.daysFromRowPicker(this.selectedDate);
          this.scroolH_fromDate(new Date(data.data.date));

        }
        // this.shopS.calendar.currentDate = new Date(this.selectedDate)


      });
  }







  //   scrollWeek() {
  //     // let ele = 'startHourr' + this.shopS.calendar.currentDate.getDate() + this.shopS.calendar.currentDate.getMonth();
  //     // console.log('ele-',ele);
  //      let el ;

  //     setTimeout(() => {
  //        el = document.getElementsByClassName('weekview-container');
  //        console.log('el-',el);
  //        if(el){
  //          console.log('scroll!!!!');
  //          el.scrollIntoView();
  //        }else{
  //         console.log('somwthing waiting');

  //        }
  //     }, 200);


  //    // el.scrollIntoView();
  // }
  scrollBy(element, value, duration, easingFunc) {
    var startTime;
    var startPos = element.scrollTop;
    var clientHeight = element.clientHeight;
    var maxScroll = element.scrollHeight - clientHeight;
    var scrollIntendedDestination = startPos + value;
    // low and high bounds for possible scroll destinations
    var scrollEndValue = Math.min(Math.max(scrollIntendedDestination, 0), maxScroll)
    // create recursive function to call every frame
    var scroll = function (timestamp) {
      startTime = startTime || timestamp;
      var elapsed = timestamp - startTime;
      element.scrollTop = startPos + (scrollEndValue - startPos) * easingFunc(elapsed / duration);
      elapsed <= duration && window.requestAnimationFrame(scroll);
    };
    // call recursive function
    if (startPos != scrollEndValue) window.requestAnimationFrame(scroll);
  }


  easeInOutCubic = function (t) {
    return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  }

  scroll_trys = 0;
  scroll() {
    if (!this.shopS.scrolling_now) {
      let ele = 'startHourr' + this.shopS.calendar.currentDate.getDate() + this.shopS.calendar.currentDate.getMonth();
      console.log('ele-', ele);
      let el;
      this.scroll_trys++;



      setTimeout(() => {
        el = document.getElementById(ele);
        console.log('el-', el);
        if (el) {
          this.scroll_trys = 0;
          let dddd: any = document.getElementById("calendarid").getElementsByClassName("scroll-content")[3];
          var topPos = el.offsetTop;
          dddd.style.transitionDuration = '0.3s';
          this.scrollBy(dddd, topPos - 15, 300, this.easeInOutCubic)
          el.scrollTo()
        } else {
          console.log('somwthing waiting');
          if (this.scroll_trys < 2) {
            this.scroll();
          }
        }
      }, 80);
    }////////////////////////////////////////////////////////

    // el.scrollIntoView();
  }



  testArray2 = [];

  // event = {
  //   title: '',
  //   desc: '',
  //   startTime: '',
  //   endTime: '',
  //   allDay: false
  // };

  minDate = new Date().toISOString();
  array;
  eventsData;
  eventSource = [];
  viewTitle;

  dateForView = [];
  days = {
    "en": ["ראשון", "שניה", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
    "he": ["א׳", "ב׳", "ג׳", "ד׳", "ה׳", "ו׳", "ש׳"]
  }





  @ViewChild(CalendarComponent, { static: false }) myCal: CalendarComponent;


  constructor(
    public popoverController: PopoverController,
    public shopS: ShopService,
    private alertCtrl: AlertController,
    private router: Router,
    private modalController: ModalController,
    private MenuController: MenuController,
    private authService: AuthService,
    private ToastService: ToastService,
    private alertExample: AlertExample,


    @Inject(LOCALE_ID) private locale: string
  ) {
    this.authService.isconnect.subscribe(value => {
      if (value == true) {
        this.stratwork();
      }
    })
  }

  datePickerObj;
  ngOnInit() {
    // if (this.shopS.calendar.mode == 'week') {
    //   this.shopS.getMinStartTime();
    // }
    this.shopS.setCurrentDay(this.shopS.calendar.currentDate);

    console.log(this.shopS.calendar);
    console.log(this.shopS.shopData.WorkDays);
    // this.shopS.calendar.currentDate = new Date();
    // this.shopS.dateForView.forEach(x => {
    //   x.active = false;
    // });
    // this.shopS.dateForView[0].active = true;
    var untilWork = new Date();
    console.log("untilWork -->before", untilWork);
    console.log(this.shopS.shopData.Setting.limitMeetAdmin);
    
    untilWork.setDate(untilWork.getDate() + Number(this.shopS.shopData.Setting.limitMeetAdmin));
    console.log("untilWork", untilWork, this.shopS.shopData.Setting.limitMeet);
    
    this.datePickerObj = {
      inputDate: new Date(this.shopS.calendar.currentDate), // defauthilt new Date()
      // fromDate: new Date(), // default null
      toDate: untilWork, // default null
      showTodayButton: true, // default true
      closeOnSelect: true, // default false
      //   disableWeekDays: [4], // default []
      mondayFirst: false, // default false
      setLabel: 'בחירה',  // default 'Set'
      todayLabel: 'היום', // default 'Today'
      closeLabel: 'סגור', // default 'Close'
      disabledDates: [], // default []
      titleLabel: 'בחירת תאריך', // default null
      monthsList: ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"],
      weeksList: ["א׳", "ב׳", "ג׳", "ד׳", "ה׳", "ו׳", "ש׳"],
      dateFormat: 'YYYY-MM-DD', // default DD MMM YYYY
      clearButton: false, // default true
      momentLocale: 'iw-IL', // Default 'en-US'
      yearInAscending: true, // Default false
      btnCloseSetInReverse: true, // Default false
      btnProperties: {
        expand: 'block', // Default 'block'
        fill: '', // Default 'solid'
        size: '', // Default 'default'
        disabled: '', // Default false
        strong: '', // Default false
        color: '' // Default ''
      },
      arrowNextPrev: {
        nextArrowSrc: 'assets/images/arrow_right.svg',
        prevArrowSrc: 'assets/images/arrow_left.svg'
      }, // This object supports only SVG files.
      highlightedDates: [
      ],
      // Default [],
      // isSundayHighlighted : {
      //  fontColor: '#ee88bf' // Default null
      // } // Default {}
    };
  }


  reloadSub: Subscription;


  lEvent() {
    console.log("loadEvents", this.shopS.eventArray)

    // console.log("loadEvents ---->");
    if (this.myCal) {
      this.myCal.loadEvents();
    } else {
      setTimeout(() => {
        this.lEvent()
      }, 500);
    }

  }

  stratwork() {
    var self = this;
    // this.reloadSub = this.shopS.reLoad.subscribe((data) => {
    //   this.lEvent();
    // });

    // this.resetEvent();
    //this.eventSource = this.createRandomEvents();
    var self = this;
    var today = new Date()
    var dayToday = today.getDay();
    if (this.shopS.currentMeet.date.name == undefined) {
      this.shopS.currentMeet.date =
      {
        "name": dayToday,
        "date": today.getDate(),
        'cal': new Date(this.shopS.calendar.currentDate)
      };
    }

    setTimeout(() => {
      // if (this.shopS.dateForView.length == 0) {
      //   self.shopS.daysForViewFunc();
      // }
      this.scroll();
    }, 200);

    //    this.formatDateToCalandar(this.shopS.shopData.Meetings);

  }

  addDaysToDate(x) {

  }
  async presentPopover_old(ev: any) {
    const popover = await this.popoverController.create({
      component: ModalNewmeetComponent,
      event: ev,
      translucent: true,
      componentProps: { time: ev },
      backdropDismiss: true,
      cssClass: "toppop",


    });
    // return await popover.present();
    await popover.present();

    await popover.onDidDismiss().then(x => {
      this.modalController.dismiss();
    });


  }




  async presentPopover(ev: any) {
    const popover = await this.modalController.create({
      component: ModalNewmeetComponent,
      // event: ev,
      //  translucent: true,
      componentProps: { time: ev },
      backdropDismiss: true,
      cssClass: "toppop",
      mode: 'ios',
      swipeToClose: true,


    });
    // return await popover.present();
    await popover.present();

    await popover.onDidDismiss().then(x => {
      this.modalController.dismiss();
    });


  }



  weekViewFunName(x, i) {

    if (i == 0) {
      var datee = new Date(x.cal);
      var day1 = datee.getDay();


      var cheackDay = datee.getDay();
      datee.setDate(datee.getDate() + (6 - cheackDay));
      var day2 = datee.getDay();

      datee.setDate(datee.getDate() - 6);
      var day1 = datee.getDay();
    } else {
      var datee = new Date(x.cal);
      var day1 = datee.getDay();
      datee.setDate(datee.getDate() + 6);
      var day2 = datee.getDay();
    }


    return this.days.he[day1] + "-" + this.days.he[day2];
  }

  weekViewFun(x, i) {
    // console.log("i>>>>>>>>>",x,i)
    if (i == 0) {
      var datee = new Date(x.cal);
      var day1 = datee.getDate();


      var cheackDay = datee.getDay();
      datee.setDate(datee.getDate() + (6 - cheackDay));
      var day2 = datee.getDate();

      datee.setDate(datee.getDate() - 6);
      var day1 = datee.getDate();
    } else {
      var datee = new Date(x.cal);
      var day1 = datee.getDate();
      datee.setDate(datee.getDate() + 6);
      var day2 = datee.getDate();
    }
    return day2 + "-" + day1;
  }
  // daysForViewFunc() {
  //   this.shopS.dateForView = [];
  //   // console.log("dateForView: ", i,this.shopS.dateForView)
  //   var today = new Date()
  //   var dayToday = today.getDay();
  //   var limitMeet = parseInt(this.shopS.shopData.Setting.limitMeetAdmin);
  //   if (!limitMeet) {
  //     limitMeet = 31;
  //   }

  //   // console.log(" limitMeet" ,limitMeet)
  //   for (var i = 0; i < limitMeet; i++) {


  //     this.days.he[dayToday];
  //     var calDate = today.toString();

  //     if (i == 0) {
  //       this.shopS.dateForView.push(
  //         {
  //           "name": this.days.he[dayToday],
  //           "date": today.getDate(),
  //           'cal': new Date(calDate),
  //           'active': true
  //         });
  //     } else {
  //       this.shopS.dateForView.push(
  //         {
  //           "name": this.days.he[dayToday],
  //           "date": today.getDate(),
  //           'cal': new Date(calDate),
  //           'active': false
  //         });
  //     }


  //     if (dayToday != 6) {
  //       dayToday++;
  //     } else {
  //       dayToday = 0;
  //     }

  //     today.setDate(today.getDate() + 1);
  //   }

  // }


  calendarMode: any;
  calendarTimeInterval: any;


  // removeHistory() {
  // this.loadHistoryByAndBarber();
  // this.shopS.removeHistory();

  // }


  // loadHistoryByAndBarber() {

  // this.shopS.removeHistory();
  // this.shopS.loadHistoryByAndBarber().then(res=>{

  //  this.shopS.loadHistoryByAndBarberEvent().then(eve=>{
  //    console.log("all my history ",res.val());
  //    console.log('snap.val()', res.val());
  //    var beforeSortEvent=this.shopS.json2array(eve.val());
  //    var beforeSort=this.shopS.json2array(res.val());
  //    var bar=[];
  //    beforeSort.forEach(element => {
  //      Object.keys(element).forEach(key=>{

  //        if(typeof element[key] != "string"){


  //          console.log("element[key],",element[key].uId);

  //          element[key]["userData"] =this.shopS.shopData.Users[element[key].uId];

  //          bar.push(element[key]);
  //        }

  //      });

  //    })


  //    beforeSortEvent.forEach(element => {
  //      Object.keys(element).forEach(key=>{

  //        if(typeof element[key] != "string"){


  //          bar.push(element[key]);
  //        }

  //      });

  //    })

  //    bar.forEach(res=>{

  //     this.shopS.removeAdminMeet(res);
  //   })

  //    })    })

  // }

  // checkTimeZone() {
  //   var zone_name = moment.tz.guess();
  //   var current_timezone = moment.tz(zone_name);
  //   let my_utcOffset = moment.parseZone(current_timezone).utcOffset();
  //   var busi_timezone = moment.tz("Asia/Jerusalem");
  //   let def_utcOffset = moment.parseZone(busi_timezone).utcOffset();
  //   let busi_utcOffset = this.shopS.shopData.Setting.timezone ? moment.parseZone(this.shopS.shopData.Setting.timezone).utcOffset() : def_utcOffset;

  //   console.log(zone_name);
  //   console.log(def_utcOffset);

  //   console.log(busi_utcOffset, my_utcOffset);

  //   if (my_utcOffset != busi_utcOffset) {
  //     this.shopS.openFullscreenAlert();
  //   }
  // }

  ionViewDidEnter() {
    if (this.shopS.shopData.Setting.BarberID) {
      // this.checkTimeZone();
      if (this.shopS.checkTimeZoneDiff()) {
        this.shopS.openFullscreenAlert();
      }
      setTimeout(() => {
        this.shopS.openBusinessMeetingsSub();
      }, 10);
    }

    console.log("this.shopS.shopData.Meetings ---- > ", this.shopS.shopData)
    // if (this.shopS.shopData.Meetings) {
    //   //server job
    //   console.log(this.shopS.shopData.Setting.Premium.History)
    //   if (this.shopS.shopData.Setting.history === false) {
    //     this.shopS.removeHistory();
    //   }
    // } else {
    this.shopS.shopData['Meetings'] = {};
    // }

    this.calendarMode = localStorage.getItem("calendarMode");
    this.calendarTimeInterval = localStorage.getItem("calendarTimeInterval");
    this.shopS.calendarIsDisplay = false;

    if (this.calendarMode == null) {
      this.shopS.calendar.mode = 'day';
    } else {
      this.shopS.calendar.mode = this.calendarMode;
    }

    if (this.calendarTimeInterval == null) {
      this.shopS.calendar.timeInterval = '15';
    } else {
      this.shopS.calendar.timeInterval = this.calendarTimeInterval;
    }
    setTimeout(() => {
      this.shopS.calendarIsDisplay = true;
    }, 10);
  }


  // resetEvent() {
  //   this.event = {
  //     title: '',
  //     desc: '',
  //     startTime: new Date().toISOString(),
  //     endTime: new Date().toISOString(),
  //     allDay: false
  //   };
  // }

  // Create the right event format and reload source
  // addEvent() {
  //   let eventCopy = {
  //     title: this.event.title,
  //     startTime: new Date(this.event.startTime),
  //     endTime: new Date(this.event.endTime),
  //     allDay: this.event.allDay,
  //     desc: this.event.desc
  //   }

  //   if (eventCopy.allDay) {
  //     let start = eventCopy.startTime;
  //     let end = eventCopy.endTime;

  //     eventCopy.startTime = new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate()));
  //     eventCopy.endTime = new Date(Date.UTC(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate() + 1));
  //   }

  //   this.eventSource.push(eventCopy);
  //   this.myCal.loadEvents();
  //   this.resetEvent();
  // }


  // Change current month/week/day
  next() {
    var swiper = document.querySelector('.swiper-container')['swiper'];
    swiper.slideNext();
  }

  back() {
    var swiper = document.querySelector('.swiper-container')['swiper'];
    swiper.slidePrev();
  }

  // Change between month/week/day
  changeMode(mode) {
    localStorage.setItem("calendarMode", mode);
    // console.log('calendarMode',mode);
    this.shopS.calendar.mode = mode;
  }

  // daysFromRow(a) {
  //   this.shopS.closeBusinessMeetingsSub();
  //   console.log("a", a)
  //   this.currentDay = new Date(a.cal).getDay();
  //   this.shopS.dateForView.forEach(x => {
  //     x.active = false;
  //   });
  //   a.active = true;
  //   this.shopS.calendar.currentDate = a.cal;
  //   this.shopS.currentMeet.date = a;
  //   setTimeout(() => {
  //     this.shopS.openBusinessMeetingsSub();
  //   }, 10);
  // }


  daysFromRowPicker(thisD) {
    var superDate = new Date(thisD);
    console.log("a", superDate, thisD);
    var a = {
      "active": true,
      "cal": superDate,
      "date": superDate.getDate(),
      "name": this.shopS.days["he"][superDate.getDay()]
    }
    this.shopS.switchDay(a);

    // console.log("a", a);
    // this.shopS.dateForView.forEach(x => {
    //   x.active = false;
    //   var date1 = new Date(x.cal);
    //   var date2 = new Date(a.cal);

    //   if ((date1.getMonth() == date2.getMonth()) && (date1.getDate() == date2.getDate())) {
    //     console.log(date1, date2)
    //     x.active = true;
    //   }
    // })

    // this.shopS.calendar.currentDate = a.cal;
    // this.shopS.currentMeet.date = a;
  }

  // Focus today
  // today(a) {

  //   //this.shopS.fix_old_meetings()



  //   //this.scrollToscrollHY(0);
  //   this.shopS.dateForView.forEach(x => {
  //     x.active = false;
  //   })
  //   a.active = true;
  //   this.shopS.calendar.currentDate = a.cal;
  //   this.shopS.currentMeet.date = a;
  // }





  // Selected date reange and hence title changed
  onViewTitleChanged(title) {
    // console.log('onViewTitleChanged-title',title);
    this.shopS.viewTitle = title;

  }





  toggleModalSetting(event) {

    event.stopPropagation();
    this.router.navigate(['tabsAdmin/business'], { replaceUrl: true });
    // this.shopS.calendarSettingFLAG = !this.shopS.calendarSettingFLAG;
    // console.log('toggleModal=======this.shopS.calendarSettingFLAG',this.shopS.calendarSettingFLAG);
    setTimeout(() => {
      this.shopS.AdminEditMode = false;

    }, 500);

  }

  toggleModal(ev) {
    ev.stopPropagation();
    this.shopS.meetmodalFLAG = !this.shopS.meetmodalFLAG;
    // console.log('toggleModal=======this.shopS.meetmodalFLAG',this.shopS.meetmodalFLAG);
  }

  // Calendar event was clicked
  async onEventSelected(ev, data) {
    console.log("data.event", data.event);

    if (data.event.meetType.payment_way == 'down_payment' && data.event.payment_status != 'pending') {
      let paymentRespone = this.shopS.json2array(data.event.paymentRespone);
      let totalPayed = paymentRespone.filter(res => res.sale_status == 'completed').map(res => res.price);
      let sum = totalPayed.reduce((partialSum, a) => partialSum + a, 0);
      sum = sum / 100;
      if (sum && sum < parseFloat(data.event.meetType.price)) {
        data.event.payment_status = 'rest_payment';
      }
    }

    ev.stopPropagation();
    if (!data.event.mType) {
      data.event['mType'] = {
        duplicated: "",
        repeat: "",
        type: "Admin"
      };
    }

    this.shopS.SelectedMeet = JSON.parse(JSON.stringify(data));

    if (data.event.meetType == 'event') {
      this.shopS.eventForBuis.importEventData(data.event);
      this.shopS.modalMeetStatus = 'event';
      if (this.shopS.eventForBuis.zoomlink) {
        this.shopS.zoomshow = true;
      }
    } else {
      this.shopS.meetForBuis.loadFullMeet(data.event);
      this.shopS.modalMeetStatus = 'meet';
      if (this.shopS.meetForBuis.zoomlink) {
        this.shopS.zoomshow = true;
      }
    }

    this.toggleModal(ev);
  }


  minutes_with_leading_zeros(dt) {
    return (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
  }
  hours_with_leading_zeros(dt) {
    // console.log('hours_with_leading_zeros------dt',dt);
    return (dt.getHours() < 10 ? '0' : '') + dt.getHours();
  }

  checkOpenHour(data) {

    let result = false;

    let hour = this.hours_with_leading_zeros(data) + ':' + this.minutes_with_leading_zeros(data);
    //console.log('hour',hour);
    let datee = this.shopS.shopData.WorkDays[data.getDay()];

    if (datee.timeStart == hour) {
      //console.log('match!',hour);
      result = true;
    }

    return result;
  }


  // dataTocalNEW() {

  //   this.getArrayForCalandar(this.shopS.json2array(this.shopS.shopData.Meetings));

  // }

  // dataTocal(val) {
  //   /// console.log('dataTocal',val);
  //   //return val;
  //   this.getArrayForCalandar(this.shopS.json2array(this.shopS.shopData.Meetings));

  //   console.log("--- > ", val)

  //   return val;
  // }

  checkBussniesHours(data) {
    // console.log('type',type);
    // console.log('data',data);
    let result = false;

    let hour = this.hours_with_leading_zeros(data) + ':' + this.minutes_with_leading_zeros(data);
    // console.log('hour',hour);

    // console.log('data',data);
    let datee = this.shopS.shopData.WorkDays[data.getDay()];


    if (datee.timeStart > hour) {
      //console.log('match!',hour);
      result = true;
    }

    if (datee.timeEnd < hour) {
      //  console.log('match!',hour);
      result = true;
    }

    //this.workDay
    // if(type == 'open' ){
    //   result = true;
    // }

    // if(type == 'close'){
    //   result = true;

    // }
    return result;
  }

  // 
  counter = 4;
  srat = 0;
  currentDate;
  workDay;

  trackByFn(index, item) {
    return index;
  }


  onCurrentDateChanged(ev) {
    console.log('onCurrentDateChanged-ev *****************', ev);
    this.currentDate = new Date(ev);

    this.scroll();
  }


  // Time slot was clicked
  onTimeSelected(ev, mod) {
    if (this.shopS.checkTimeZoneDiff()) {
      this.alertExample.timezoneAlert();
    } else {
      this.presentPopover(ev);
    }
    // if (mod == "week") {
    //   return;
    // } else {
    //   this.presentPopover(ev);
    //   return;
    // }
  }


  // async presentAlertConfirm(headerD, messageD, CancelBTN, OkayBTN) {
  //   let result = false;
  //   const alert = await this.alertCtrl.create({
  //     header: headerD,
  //     message: messageD,
  //     buttons: [
  //       {
  //         text: CancelBTN,
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         handler: (blah) => {
  //           console.log('Cancel Cancel: Cancel');
  //           result = false;
  //         }
  //       }, {
  //         text: OkayBTN,
  //         handler: () => {
  //           result = true;
  //           // console.log('Okay: Okay');
  //           // console.log('BEFORE GO TO ADD - this.shopS.currentMeet.date',this.shopS.currentMeet);
  //           //this.router.navigate(['tabsAdmin/add']);
  //           // this.shopS.addLineFLAG = true;
  //           this.openModalAddLine();
  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  //   return result;
  // }


  // openModalAddLine() {
  //   event.stopPropagation();
  //   // console.log(this.shopS.currentMeet);
  //   this.shopS.daysForViewFunc();
  //   this.shopS.addLineFLAG = !this.shopS.addLineFLAG;
  //   // const peopleArray = Object.keys(this.shopS.shopData.MeetingType).map(i => this.shopS.shopData.MeetingType[i])
  //   const peopleArray: any = Object.entries(this.shopS.shopData.MeetingType).map(([key, value]) => ({ key, value }));
  //   peopleArray[0].value.key = peopleArray[0].key;
  //   if (this.shopS.currentMeet.meetType.name == "דוגמא") {
  //     this.shopS.currentMeet.meetType = peopleArray[0].value;
  //     // this.shopS.currentMeet.user=this.shopS.Auser;

  //   }
  //   this.shopS.selectType = Object.entries(this.shopS.shopData.MeetingType).map(([key, value]) => ({ key, value }));
  //   // console.log("this.shopS.selectType",this.shopS.selectType)
  //   // this.shopS.selectType=Object.values(this.shopS.shopData.MeetingType);
  //   // console.log('toggleModal=======this.shopS.addLineFLAG',peopleArray[0],this.shopS.currentMeet,this.shopS.addLineFLAG);

  // }



  convertDate(inputFormat) {
    //console.log('inputFormat',inputFormat);
    var today: any = new Date(inputFormat);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '-' + mm + '-' + yyyy;


  }

  testArray;
  eventsss;
  formatDateToCalandar(dateArray) {
    //  console.log('-----------------------dateArray--------------',dateArray);
    // let dateD =  dateArray.key;
    // let datearray = dateD.split("-");
    // let newdate = datearray[1] + '-' + datearray[0] + '-' + datearray[2];

    this.shopS.json2array(dateArray).forEach(element => {
      // this.dateArray.forEach(element => {
      //  console.log('-----ELEMENT------',element);

      element.allDay = false;
      element.endTime = new Date(element.endTime);
      // element.startTime = new Date(element.startDate);
      // element.meetTypeData=  this.getMeetDataFromId(element.meetType);
      var meetTypes: any;
      // console.log("---- ----- ----- ---- >",typeof element.meetType,element.meetType)
      switch (typeof element.meetType) {
        case "string":


          if (element.meetType == "event") {
            meetTypes = {
              approve: true,
              color: "#fb434a",
              flow: false,
              gender: "female",
              info: "987654",
              key: "",
              meetTime: "20",
              name: "כללי",
              pic: "",
              price: "",
              reminder: "0"
            }
          } else {
            meetTypes = this.getMeetDataFromId(element.meetType);

          }

          break;
        case "object":
          meetTypes = element.meetType;

          break;

        default:
          meetTypes = this.getMeetDataFromId(element.meetType);

          break;
      }


      console.log("meetTypes,", meetTypes)
      meetTypes.meetTime = element.meetTime;

      element.meetTypeData = meetTypes;
      // console.log("this.getMeetDataFromId(element.meetType)",element,this.getMeetDataFromId(element.meetType))

      if (element.uId == "localUserAdmin") {
        element.uIdData = this.shopS.Auser;

      } else {
        if (element.meetType != "event") {
          element.uIdData = this.getuIdDataFromId(element.uId);
        }

      }

      this.testArray.push(element);
    });

    // console.log('-----this.testArray',this.testArray);

    // console.log('-------------------------------------------------------------------');
  }

  getuIdDataFromId(Id) {
    // let data2 =  this.shopS.getUserFromUsers(Id);
    // console.log('data2: ---   ',data2);
    let data = this.shopS.findInJsonKey(this.shopS.shopData.Users, Id);
    // console.log('data: ',data);
    return data
  }
  getMeetDataFromId(Id) {
    let data = this.shopS.findInJsonKey(this.shopS.shopData.MeetingType, Id);
    // console.log('data: ',data);
    return data
  }

  getArrayForCalandar(arr) {
    this.testArray = [];
    arr.forEach(element => {
      console.log('element', element);
      this.formatDateToCalandar(element);
    });

    this.eventsData = this.shopS.json2array(this.shopS.shopData.Events);
    if (this.eventsData) {
      this.eventsData.forEach(element => {
        console.log('element event ->', element);
        this.formatDateToCalandar(element);
      });
    }



    console.log('this.testArray', this.testArray)
    this.eventsss = this.testArray;



  }

  ngOnDestroy(): void {
    this.shopS.closeBusinessMeetingsSub();
  }

}
