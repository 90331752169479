import { Injectable } from '@angular/core';
import { InvoiceServiceService } from '../invoice-service/invoice-service.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceMigrateService {

  constructor(public invoiceS:InvoiceServiceService) { }


  doc_list(data,supplier,cb) {

    switch (supplier) {
      case 'Greeninvoice':
        this.greeninvoice_doc_migrate(data,cbb=>{
          cb(cbb);
        })
      break;
      case 'Icount':
        this.icount_doc_migrate(data,cbb=>{
          cb(cbb);
        })
      break;
      default:
        break;
    }

  }



  new_doc(supplier,data,cb){

    switch (supplier) {
      case 'Greeninvoice':
        this.greeninvoice_doc_migrate_to(data,cbb=>{
          cb(cbb);
        })
      break;
      case 'Icount':
        this.icount_doc_migrate_to(data,cbb=>{
          cb(cbb);
        })
      break;
      default:
        break;
    }

  }








icount_doc_migrate_to(data,cb){
    let data_return = {};
    
    data_return['doctype']=data['document_counter']['id'];
    // data_return['lang']=data['lang'];
    data_return['cash']={};
    data_return['cash']['sum']=data['total_amount'];

    // data_return['date']=this.invoiceS.to_valid_date(data['date']['date_of_issue']);
    // data_return['dueDate']=this.invoiceS.to_valid_date(data['date']['date_to_pay']);
    
    
    data_return['items'] = [];
    data.services.forEach(service=>{
      data_return['items'].push({
          description: service['name'],
          quantity: service['quantity'],
          unitprice_incvat: service['price'],
        });
      })



    // data_return['lang']=data['lang'];
    // data_return['currency']=data['currency'];
    data_return['send_email']=true;
    data_return['email_to_client']=true;
    data_return['vatType']=0;

    data_return['footer_note']=data['footer'];
    data_return['remarks']=data['document_counter']['name'];

    // data_return['client']={};
    data_return['client_name']=data['client']['fname'] + ' '+ data['client']['lname'];
    data_return['email_to']=[ data['client']['email']  ];
    // data_return['client']['phone']=data['client']['phone'];
    // data_return['client']['add']=false;
    
    // data_return['paymentRequestData']={};
    // data_return['paymentRequestData']['maxPayments']=1;
    // data_return['paymentRequestData']['plugins']=[];

    
    
    if(data['currency'] == '₪'){
      data_return['currency_code']='ILS';
    }
      if(data['currency'] == '$'){
        data_return['currency_code']='USD';
      }

    data_return['discription']=data['discription'];
    if(data['meet_inv']){
      data_return['meet_inv'] = data['meet_inv']
    }

    cb(data_return);
    
  }


  icount_doc_migrate(data,cb){
    let converted_array = [];

    // if(data.length > 0){
    //   data['results_list'] = data;
    // }

    data.results_list.forEach(item=>{
      var converted_obj:any = {};

      converted_obj['original_obj']= JSON.parse( JSON.stringify(item) );

      
      converted_obj['lang']='he';
      converted_obj['discription']=item['comment'];
      converted_obj['client'] = {};
      converted_obj['client']['name']=item['client_name'];

      converted_obj['total_amount']=item['total'];

      converted_obj['date'] = {};
      converted_obj['date']['date_of_issue']=item['timeissued'];
      converted_obj['date_of_issue']=item['timeissued'];


      converted_obj['document_counter'] = {};
      converted_obj['document_counter']['name']=  this.get_doc_type_icount(item['doctype']).name  ;
      converted_obj['document_counter']['counter']=  item['docnum']  ;

      
      converted_obj['file']=item['doc_url'];

      converted_obj['services'] = [];
      item.items.forEach(income=>{
        converted_obj['services'].push({
          name: income['description'],
          quantity: income['quantity'],
          price: income['unitprice'],
        });
      })
      


      if(item['currency_code'] == 'ILS'){
        converted_obj['currency']='₪';
      }
      if(item['currency'] == 'USD'){
        converted_obj['currency']='$';
      }





converted_array.push(converted_obj);
    })
    cb(converted_array);
  }




















  greeninvoice_doc_migrate(data,cb){
    let converted_array = [];

    // if(data.length > 0){
    //   data['items'] = data;
    // }

    data.items.forEach(item=>{
      var converted_obj:any = {};

      converted_obj['original_obj']= JSON.parse( JSON.stringify(item) );

      
      converted_obj['lang']=item['lang'];
      converted_obj['discription']=item['description'];
      converted_obj['client'] = {};
      converted_obj['client']['name']=item['client']['name'];

      converted_obj['total_amount']=item['amount'];

      converted_obj['date'] = {};
      converted_obj['date']['date_of_issue']=item['documentDate'];
      converted_obj['date_of_issue']=item['documentDate'];


      converted_obj['document_counter'] = {};
      converted_obj['document_counter']['name']=  this.get_doc_type_greeninvoice(item['type']).name  ;
      converted_obj['document_counter']['counter']=  item['number']  ;

      
      converted_obj['file']=item['url']['origin'];

      converted_obj['services'] = [];
      item.income.forEach(income=>{
        converted_obj['services'].push({
          name: income['description'],
          quantity: income['quantity'],
          price: income['price'],
        });
      })
      


      if(item['currency'] == 'ILS'){
        converted_obj['currency']='₪';
      }
      if(item['currency'] == 'USD'){
        converted_obj['currency']='$';
      }





converted_array.push(converted_obj);
    })
    cb(converted_array);
  }





  greeninvoice_doc_migrate_to(data,cb){
    let data_return = {};



    
    data_return['type']=data['document_counter']['id'];
    data_return['lang']=data['lang'];
    data_return['amount']=data['total_amount'];

    data_return['date']=this.invoiceS.to_valid_date(data['date']['date_of_issue']);
    data_return['dueDate']=this.invoiceS.to_valid_date(data['date']['date_to_pay']);
    
    
    data_return['income'] = [];
    data.services.forEach(service=>{
      data_return['income'].push({
          name: service['name'],
          quantity: service['quantity'],
          price: service['price'],
          description: service['name'],
        });
      })



    data_return['lang']=data['lang'];
    data_return['currency']=data['currency'];
    data_return['signed']=true;
    data_return['rounding']=false;
    data_return['vatType']=0;

    data_return['footer_note']=data['footer'];
    data_return['remarks']=data['document_counter']['name'];

    data_return['client']={};
    data_return['client']['name']=data['client']['fname'] + ' '+ data['client']['lname'];
    data_return['client']['emails']=[ data['client']['email']  ];
    data_return['client']['phone']=data['client']['phone'];
    data_return['client']['add']=false;
    
    data_return['paymentRequestData']={};
    data_return['paymentRequestData']['maxPayments']=1;
    data_return['paymentRequestData']['plugins']=[];

    
    
    if(data['currency'] == '₪'){
      data_return['currency']='ILS';
    }
      if(data['currency'] == '$'){
        data_return['currency']='USD';
      }

    data_return['discription']=data['discription'];


    if(data['meet_inv']){
      data_return['meet_inv'] = data['meet_inv']
    }



    cb(data_return);
    
  }


  types_array(key){

    switch (key) {
      case 'Greeninvoice':
        return this.greeninvoice_types_array;
        break;
        case 'Icount':
          return this.icount_types_array;
          break;
      default:
        break;
    }
  
  
  }

icount_types_array= [
    {
        "id": 'invoice',
        "name": "חשבונית מס"
    },
    {
        "id": "receipt",
        "name": "קבלה"
    },
    {
        "id": "invrec",
        "name": "חשבונית מס קבלה"
    },
    {
        "id": "deal",
        "name": "חשבון עסקה"
    },
    {
        "id": "offer",
        "name": "הצעת מחיר"
    },
    {
      "id": "order",
      "name": "הזמנה"
  },
  {
    "id": "delcert",
    "name": "תעודת משלוח"
},
{
  "id": "refund",
  "name": "חשבונית זיכוי"
},

  ];







 greeninvoice_types_array= [
    {
        "id": 10,
        "name": "הצעת מחיר"
    },
    {
        "id": 20,
        "name": "חשבון / אישור תשלום"
    },
    {
        "id": 100,
        "name": "הזמנה"
    },
    {
        "id": 200,
        "name": "תעודת משלוח"
    },
    {
        "id": 210,
        "name": "תעודת החזרה"
    },
    {
        "id": 300,
        "name": "חשבון עסקה"
    },
    {
        "id": 305,
        "name": "חשבונית מס"
    },
    {
        "id": 320,
        "name": "חשבונית מס / קבלה"
    },
    {
        "id": 330,
        "name": "חשבונית זיכוי"
    },
    {
        "id": 400,
        "name": "קבלה"
    },
    {
        "id": 405,
        "name": "קבלה על תרומה"
    },
    {
        "id": 500,
        "name": "הזמנת רכש"
    },
    {
        "id": 600,
        "name": "קבלת פיקדון"
    },
    {
        "id": 610,
        "name": "משיכת פיקדון"
    }
]

  get_doc_type_greeninvoice(type_to_find){
    return this.greeninvoice_types_array.find(type => type.id == type_to_find)
  }

  get_doc_type_icount(type_to_find){
    return this.icount_types_array.find(type => type.id == type_to_find)
  }




}
