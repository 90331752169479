import { PremiumPopoverComponent } from './../../app/premium-popover/premium-popover.component';
import { ModalController, PopoverController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';
import { ColorPickerDirective } from 'ngx-color-picker';
import { AlertExample } from './../../providers/alert.service';
import { ToastService } from '../../providers/toast-service';
import { PaymentService } from 'src/app/payments/payment-service/payment.service';
import { WalletServiceService } from 'src/app/wallet/wallet-service/wallet-service.service';
import { PremiumService } from 'src/app/premium/premium-service/premium.service';

@Component({
  selector: 'meet-type-modal',
  templateUrl: './meet-type-modal.component.html',
  styleUrls: ['./meet-type-modal.component.scss'],
})
export class MeetTypeModalComponent implements OnInit {

  constructor(
    public PaymentService: PaymentService,
    public shopS: ShopService,
    private toast: ToastService,
    private AlertExample: AlertExample,
    private popoverController: PopoverController,
    private premiumService: PremiumService,
    public walletS: WalletServiceService,
  ) { }
  jso: any = {};

  wallet_settings_is_open = false;
  details = false;
  cancelBefore = 0;
  // gg() {
  //   console.log(this.shopS.meetTypeData.reminder);
  //   setTimeout(() => {
  //     this.gg();
  //   }, 1000);
  // }
  ngOnInit() {
    // this.gg();
  }

  // active: false
  // approve: true
  // color: "#51b332"
  // flow: false
  // gender: "female"
  // info: "987654"
  // key: "-LnRsaGw6XLRbhv5CZdo"
  // meetTime: "30"
  // name: "פסים"
  // pic: "לחeמ"
  // price: "300"
  // reminder: "20"


  async presentAlertConfirm() {
    const alert = await this.AlertExample.alertController.create({
      header: 'מחיקת שירות',
      message: 'האם אתה בטוח שברצונך למחוק',
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'כן',

          handler: () => {

            console.log('this.shopS.shopData', this.shopS.shopData);
            console.log('this.shopS.shopData.Setting.BarberId', this.shopS.shopData.Setting);
            this.shopS.deleteMeetType(this.shopS.shopData.Setting.BarberID, this.shopS.meetTypeData.key);
            this.toast.showTostDanger('השירות נמחק');

            this.shopS.MeetEditmodalFLAG = false;
            this.shopS.MeetEditmodalFLAGADD = false;




          }
        }
      ]
    });
    await alert.present();
  }



  async presentEmptyAndSpaceOnly() {
    const alert = await this.AlertExample.alertController.create({
      header: 'שם שירות אינו תקין',
      message: 'לא ניתן להכניס שם ריק',
      buttons: [
        {
          text: 'הבנתי',

          handler: () => {




          }
        }
      ]
    });
    await alert.present();
  }






  checkMinPrice() {
    if (this.shopS.meetTypeData.payment_way == 'pre_payment' && this.shopS.meetTypeData.price < 5) {
      return 'min5';
    } else if (this.shopS.meetTypeData.payment_way == 'down_payment') {
      let minPrecentage = Math.min(parseFloat(this.shopS.meetTypeData.down_payment_amount), 100 - parseFloat(this.shopS.meetTypeData.down_payment_amount));
      if (!this.shopS.meetTypeData.down_payment_amount || (minPrecentage * parseFloat(this.shopS.meetTypeData.price)) / 100 < 5) {
        return 'down_payment_min5';
      }
    }
  }

  @Input()
  colorPicker: string;

  checkConditions() {
    let res = true;
    if (this.shopS.meetTypeData.approve && this.shopS.meetTypeData.payment_way && (this.shopS.meetTypeData.payment_way == 'pre_payment' || this.shopS.meetTypeData.payment_way == 'down_payment')) {
      this.AlertExample.aleryByText('לא ניתן לדרוש תשלום ואישור על תור בו זמנית');
      res = false;
    }
    let checkPrice = this.checkMinPrice();
    if (checkPrice == 'min5') {
      this.AlertExample.aleryByText('יש להזין תשלום מינימלי של 5 שקלים');
      res = false;
    } else if (checkPrice == 'down_payment_min5') {
      this.AlertExample.aleryByText('יש להזין תשלום מינימלי של 5 שקלים');
      res = false;
    }
    return res;
  }

  async saveMeetType() {

    if (this.shopS.checkEmptyAndSpaceOnly(this.shopS.meetTypeData.name)) {

      if (this.checkConditions()) {
        this.shopS.shopData.MeetingType[this.shopS.meetTypeData.key] = this.shopS.meetTypeData;
        this.shopS.shopData.MeetingType = JSON.parse(JSON.stringify(this.shopS.shopData.MeetingType));

        console.log('this.shopS.shopData.MeetingType', this.shopS.shopData.MeetingType)
        console.log('this.shopS.shopData.MeetingType[this.shopS.meetTypeData.key]', this.shopS.shopData.MeetingType[this.shopS.meetTypeData.key])
        this.shopS.meetTypeData['active'] = false;
        this.shopS.updateStr('/MeetingType/' + this.shopS.meetTypeData.key + '/', this.shopS.meetTypeData);
        this.toast.showToast('השירות נשמר בהצלחה');
        this.shopS.MeetEditmodalFLAG = false;
        this.shopS.MeetEditmodalFLAGADD = false;
        this.details = false;
      }

    } else {
      this.presentEmptyAndSpaceOnly()
    }

  }

  addMeetType() {

    if (this.shopS.meetTypeData.price == "") {
      this.shopS.meetTypeData.price = 0;
    }

    if (this.shopS.checkEmptyAndSpaceOnly(this.shopS.meetTypeData.name)) {
      if (this.checkConditions()) {
        this.shopS.meetTypeData.reminder = parseInt(this.shopS.meetTypeData.reminder);

        this.shopS.addStr('/MeetingType/', this.shopS.meetTypeData);
        this.toast.showToast('השירות נוסף בהצלחה');
        this.shopS.log(this.shopS.us.key, 'new_meettype');

        this.shopS.MeetEditmodalFLAG = false;
        this.shopS.MeetEditmodalFLAGADD = false;
        this.details = false;
      }


    } else {
      this.presentEmptyAndSpaceOnly()

    }

    this.details = false;
  }


  toggleModal(event) {
    event.stopPropagation();
    this.shopS.MeetEditmodalFLAG = !this.shopS.MeetEditmodalFLAG;
    this.shopS.MeetEditmodalFLAGADD = false;
    this.details = false;
    console.log('toggleModal=======this.shopS.MeetmodalFLAG', this.shopS.MeetEditmodalFLAG);
  }
  settingUpdate = {}

  switchData: any = {};
  toggleSwitch($event, kind) {
    // if (kind == 'approve' && !this.shopS.meetTypeData[kind] && (this.shopS.meetTypeData.payment_way && (this.shopS.meetTypeData.payment_way == 'pre_payment' || this.shopS.meetTypeData.payment_way == 'down_payment'))) {
    //   this.AlertExample.aleryByText('לא ניתן לדרוש תשלום מראש ואישור על תור בו זמנית');
    //   return;
    // }
    this.switchData = {};

    this.shopS.meetTypeData[kind] = !this.shopS.meetTypeData[kind];
    // this.shopS.updateMeeting(this.shopS.SelectedMeet,'flow',this.shopS.SelectedMeet.event.flow);
    this.switchData[kind] = this.shopS.meetTypeData[kind];
    //this.settingUpdate[kind] = this.shopS.meetTypeData[kind];
    console.log('this.shopS.meetTypeData[kind]', this.shopS.meetTypeData[kind]);

    console.log('this.switchData', this.switchData);

    // if not add new meet
    //if(!this.shopS.MeetEditmodalFLAGADD){
    //  this.shopS.updateStr('/MeetingType/'+ this.shopS.meetTypeData.key +'/',this.switchData);
    //}

  }
  async presentPopover2(typePop) {
    const popover = await this.popoverController.create({
      component: PremiumPopoverComponent,
      cssClass: 'my-custom-class popmodal',

      translucent: true,
      componentProps: { "type": typePop }



    });
    await popover.present();
    popover.onDidDismiss().then(res => {
      console.log(res)
      if (res.data) {

        this.shopS.meetTypeData["float_num"] = res.data;
        this.settingUpdate["float_num"] = res.data;


      }
    })
  }

  deleteMeet() {

    // console.log('this.shopS.shopData',this.shopS.shopData.BarberId);
    // console.log('this.shopS.meetTypeData',this.shopS.meetTypeData);

    this.presentAlertConfirm()




  }

  cancelDefault = 0;


  async presentPopover(typePop) {
    const popover = await this.popoverController.create({
      component: PremiumPopoverComponent,
      cssClass: 'my-custom-class popmodal',

      translucent: true,
      componentProps: { "type": typePop }



    });
    await popover.present();
    popover.onDidDismiss().then(res => {
      console.log(res);

      this.shopS.meetTypeData["cancelation"] = res.data;
      this.settingUpdate["cancelation"] = res.data;
    })

  }



  inputchange(event, kind) {
    this.jso = {};

    console.log('kind', kind);

    if (kind == 'color') {
      this.jso[kind] = event;
    }
    if (kind == 'price') {
      console.log('price', event.target.value)
      // event.target.value = this.shopS.numberOnlyValidation(event.target.value);
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
      console.log('price', event.target.value);
      this.jso[kind] = event.target.value;

    } else {
      console.log('event.target.value', event.target.value);
      this.jso[kind] = event.target.value;
    }
    //  console.log('jso[kind]',this.jso[kind]);
    console.log('jso', this.jso);
    this.settingUpdate[kind] = event.target.value;
    // update modal data on service 
    this.shopS.meetTypeData[kind] = this.jso[kind];


    // if not add new meet
    if (!this.shopS.MeetEditmodalFLAGADD) {
      // this.shopS.updateStr('/MeetingType/'+ this.shopS.meetTypeData.key +'/',this.jso);
    }
  }



  async changeTimeMeet(event, kind) {

    if (kind == "meetTime") {
      var x = Number(event) % this.shopS.shopData.Setting.timeStep
      if (x != 0 && false) { // REMOVE FALSE TO ACTIVATE
        const alert = await this.AlertExample.alertController.create({
          header: 'התראה',
          message: 'הזמן שלך לא מתחלק טוב בשעות',
          buttons: [
            {
              text: 'הבנתי',
              handler: () => {
                this.settingChange(event, kind)
              }
            }
          ]
        });
        await alert.present();
      }
    }
  }


  settingChange(event, kind) {
    // if (event == 'down_payment' && !this.shopS.meetTypeData.down_payment_amount) {
    //   this.shopS.meetTypeData.down_payment_amount = '10';
    // }
    // if ((event == 'pre_payment' || event == 'down_payment') && this.shopS.meetTypeData.approve) {
    //   console.log(this.shopS.meetTypeData[kind]);
    //   console.log(this.shopS.meetTypeData);

    //   this.AlertExample.aleryByText('לא ניתן לדרוש תשלום מראש ואישור על תור בו זמנית');
    //   setTimeout(() => {
    //   console.log(this.shopS.meetTypeData);

    //   }, 1000);
    //   return;
    // }

    this.shopS.meetTypeData[kind] = event;
    this.settingUpdate[kind] = event;
    console.log('this.settingUpdate', this.shopS.meetTypeData);
    console.log('this.settingUpdate', this.settingUpdate);
    //this.shopS.updateStr('/Setting/',this.settingUpdate);
    //if(this.shopS.meetTypeData.key){
    //  this.shopS.updateStr('/MeetingType/'+ this.shopS.meetTypeData.key +'/',this.settingUpdate);
    //}
  }

  deleteData() {
    console.log('DELETE ALL DATA!!!!!!!!');
  }

  newLine(event, u) {
    event.stopPropagation();
    console.log('newLine-u', u);
    this.shopS.MeetEditmodalFLAG = false;

  }




  showDetails() {
    if (this.shopS.shopData.Setting.Premium.status == 'premium') {
      this.details = !this.details;
    } else {
      this.premiumService.PremiumLock('go');
    }
  }

}
