import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {
  transform(value, args:string[]) : any {
    let keys = [];
   // console.log('keys before',keys)
   // console.log('value before',value)
    for (let key in value) {
    //  console.log('key',key)
      value[key].key = key;
      keys.push(value[key]);
    }
 //   console.log('keys after',keys)
 //   console.log('value after',value)

    return keys;
  }
}
