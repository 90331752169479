import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InvoiceServiceService } from "../invoice-service/invoice-service.service";

@Component({
  selector: 'app-invoice-settings-modal',
  templateUrl: './invoice-settings-modal.component.html',
  styleUrls: ['./invoice-settings-modal.component.scss'],
})
export class InvoiceSettingsModalComponent implements OnInit {

  constructor(    
    public modalController: ModalController,
    public invoiceS:InvoiceServiceService
    ) { }

    localData:any;
    isValidate:any;
  ngOnInit() {}

  changed(e,t){

  }
  saveData(){
    
  }
  dismissModal() {
    if (this.modalController) {
      this.modalController.dismiss({
        'dismissed': false
      }).then(() => { this.modalController = null; });
    }
  }

}
