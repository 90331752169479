import { MeetModalComponent } from './../../components/meet-modal/meet-modal.component';
import { ModalController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';
import { Component, OnInit } from '@angular/core';
import { ModalNewmeetComponent } from '../modal-newmeet/modal-newmeet.component';

@Component({
  selector: 'app-event-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.scss'],
})
export class EventModalComponent implements OnInit {

  allevents = [];
  eventsDATA;
  monthsList = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];

  constructor(private shopS: ShopService, private modalController: ModalController) { }

  ngOnInit() { }

  dismissModal() {
    this.modalController.dismiss();
  }

  ionViewDidEnter() {
    var self = this
    this.shopS.loadEvents().on('value', function (res) {
      self.allevents = [];

      var eventsDatas = res.val();
      console.log("events", eventsDatas);

      self.eventsDATA = self.shopS.json2array(eventsDatas)
      console.log("this.eventsDATA", self.eventsDATA);

      var beforeSort = self.shopS.json2array(res.val());

      beforeSort.sort(self.compare);

      //   this.historyMeet=beforeSort.reverse();
      var afterall = self.createMonth(beforeSort);

      //  console.log("afterall",afterall)
      //  this.allevents=afterall;

      self.markEvent();
    });
  }

  markEvent() {

  }

  async presentPopover() {
    let now = new Date();
    const popover = await this.modalController.create({
      component: ModalNewmeetComponent,
      // event: ev,
      //  translucent: true,
      componentProps: { time: { selectedTime: now, onlyEvent: true } },
      backdropDismiss: true,
      cssClass: "toppop",
      swipeToClose: true,
      mode:'ios',


    });
    // return await popover.present();
    await popover.present();

    await popover.onDidDismiss().then(x => {
      // this.modalController.dismiss();
    });


  }

  async openEventModal() {

    const modal = await this.modalController.create({
      component: MeetModalComponent,
      swipeToClose: true,
      cssClass: '  fullScreen',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
      // Get the top-most ion-modal
    });

    await modal.present();
    await modal.onWillDismiss().then(res => {

    })


  }

  // toggleModal(event) {
  //   event.stopPropagation();

  //   // this.shopS.meetmodalFLAG = !this.shopS.meetmodalFLAG;
  //   this.openEventModal();
  //   // console.log('toggleModal=======this.shopS.meetmodalFLAG',this.shopS.meetmodalFLAG);
  // }

  // Calendar event was clicked
  async onEventSelected(event, data) {
    console.log("data.event", this.shopS.SelectedMeet);

    event.stopPropagation();

    this.shopS.SelectedMeet.event = data;

    this.shopS.SelectedMeet.event.uIdData = {}


    this.shopS.eventForBuis.importEventData(data);
    this.shopS.modalMeetStatus = 'event';




    this.openEventModal();
  }


  createMonth(dataMeets) {
    var dataMeet = "";
    var arrayOfMeet = [];
    var objec = {
      "dates": "",
      "meets": []
    }

    console.log("------->dataMeets", dataMeets);
    var r = 1;
    var relemntscount = dataMeets.length;

    dataMeets.forEach(element2 => {

      var i = 1;
      var elmLenth = Object.keys(element2).length - 1;
      Object.keys(element2).forEach(key => {



        if (typeof element2[key] != "string") {


          var xs = element2[key];





          console.log("elemnts length ", i, elmLenth)


          var nameDates = this.get_month_from_key(element2[key].meetDate) + " ," + this.get_year_from_key(element2[key].meetDate)


          if (objec.dates == nameDates) {

            objec.meets.push(xs);


            console.log("samessss");
            if (i == elmLenth && r == relemntscount) {
              this.allevents.push(objec);
            }

          } else {
            if (objec.dates) {
              this.allevents.push(objec);
            }

            objec = {
              "dates": nameDates,
              "meets": []
            }


            objec.meets.push(xs);

            if (i == elmLenth && r == relemntscount) {
              this.allevents.push(objec);
            }
          }

        }

        i++;
      });


      // var nameOF=this.get_month_from_key(element.meetDate)+" ,"+ this.get_year_from_key(element.meetDate);
      // this.historyMeet[nameOF].push(element);
      r++;
    });

    console.log("historyMeet", this.allevents);

    return objec;

  }

  get_year_from_key(key) {
    // console.log(key)
    let s = key.split('-');
    return s[0];
  }

  get_month_from_key(key) {
    //console.log(key)
    let s = key.split('-');
    return this.monthsList[parseInt(s[1]) - 1];
  }



  compare(a, b) {
    // Use toUpperCase() to ignore character casing

    // var d1=this.convertDate(a.startDate);
    // var d2=this.convertDate(b.startDate);

    const bandA = new Date(a.startDate);
    const bandB = new Date(b.startDate);

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  convertDate(inputFormat) {

    var today: any = new Date(inputFormat);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '-' + mm + '-' + yyyy;

  }
}
