import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ShopService } from 'src/ShopService/shop.service';
import { env } from '../app.module';
import 'firebase/firestore';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { userService } from 'src/ShopService/user.service';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LoadingService } from 'src/providers/loading.service';

@Component({
  selector: 'app-buis-index',
  templateUrl: './buis-index.component.html',
  styleUrls: ['./buis-index.component.scss'],
})
export class BuisIndexComponent implements OnInit {
  backgroundIMG;
  randomBackground() {
    let background_array = [
      '../../assets/indexBG/9.png',
      '../../assets/indexBG/8.png',
      '../../assets/indexBG/7.png',
      '../../assets/indexBG/6.png',
      '../../assets/indexBG/5.png',
      '../../assets/indexBG/2.png',
      '../../assets/indexBG/1.png',

    ];
    this.backgroundIMG = background_array[Math.floor(Math.random() * background_array.length)];
  }

  all_emoji;
  random_emoji() {
    let array = [
      '💃',
      '🥳',
      '👯‍♀️',
      '👑',
      '🤟',
      '💪',
      '🎲',
      '⛱️',
      '📢',
      '🎉',
      '💡',
    ];
    this.all_emoji = array[Math.floor(Math.random() * array.length)];
  }

  catSlidesOpts = {
    freeMode: true,
    slidesPerView: 2.75,
    // slidesOffsetBefore: 11,
    spaceBetween: 1
  };

  highlightSlidesOpts = {
    slidesPerView: 3.2,
    freeMode: true,
    spaceBetween: 2,
    loop: true,
    direction: 'vertical'
    // centeredSlides: true,
  };

  shops_lat: number;
  shops_lng: number;
  loader = false;

  zoom: number;
  // address: string;
  private geoCoder;

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;

  // locationCordinates: any;
  // timestamp: any;

  constructor(
    public shopS: ShopService,
    private http: HttpClient,
    private mapsAPILoader: MapsAPILoader,
    private alertController: AlertController,
    private locationAccuracy: LocationAccuracy,
    private androidPermissions: AndroidPermissions,
    public us: userService,
    private geolocation: Geolocation,
    private platform: Platform,
    private loadingService: LoadingService
  ) { }


  // checkPermission() {
  //   this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
  //     result => {
  //       if (result.hasPermission) {
  //         this.enableGPS();
  //       } else {
  //         this.locationAccPermission();
  //       }
  //     },
  //     error => {
  //       alert('error1 - ' + error);
  //     }
  //   );
  // }

  // locationAccPermission() {
  //   this.locationAccuracy.canRequest().then((canRequest: boolean) => {
  //     if (canRequest) {
  //     } else {
  //       this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION)
  //         .then(
  //           () => {
  //             this.enableGPS();
  //           },
  //           error => {
  //             alert('error2 - ' + error)
  //           }
  //         );
  //     }
  //   });
  // }

  enableGPS() {
    console.log('this.shopS.platform!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', this.platform.is('ios'), this.shopS.environment.platform)
    if (this.shopS.environment.platform == 'web' || this.platform.is('ios')) {
      this.setCurrentLocation();
    } else {

      this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
        () => {
          this.deviceLocation = true;
          this.setCurrentLocation();
        },
        error => {
          // alert('error3 - ' + JSON.stringify(error));
          this.deviceLocation = false;
        }
      );
    }
  }

  deviceLocation = true;
  // currentLocPosition() {
  //   this.geolocation.getCurrentPosition().then((response) => {
  //     // this.locationCordinates.latitude = response.coords.latitude;
  //     // this.locationCordinates.longitude = response.coords.longitude;
  //     // this.locationCordinates.accuracy = response.coords.accuracy;
  //     // this.locationCordinates.timestamp = response.timestamp;
  //   }).catch((error) => {
  //     alert('Error4: ' + error);
  //   });
  // }

  my_address = "המיקום שלי";
  // access_location = null;
  // my_location = null;
  // my_location_choosen = false;


  point_geo_location_premission = localStorage.getItem('point_geo_location_premission');

  async geoLocationPremission() {
    console.log('this.point_geo_location_premission', this.point_geo_location_premission);

    if (this.point_geo_location_premission) {
      // if (this.shopS.environment.platform == 'web') {
      //   this.setCurrentLocation();
      // } else {
      this.enableGPS();
      // }
    } else {
      var textT = `<img class='imgalert2 contactimg' src="../../assets/mylocation.png" >`;
      textT += 'על מנת שנוכל למצוא עסקים בסביבתך אנו צריכים גישה למיקום המכשיר שלך. חשוב לאשר את הבקשה';
      const alert = await this.alertController.create({
        header: 'הרשאות גישה למיקומך',
        message: textT,
        buttons: [
          {
            text: 'הבנתי',
            handler: () => {
              console.log('Okay: Okay');
              // if (this.shopS.environment.platform == 'web') {
              //   this.setCurrentLocation();
              // } else {
              this.enableGPS();
              // }
            }
          }
        ]
      });
      await alert.present();
    }
  }

  get_score() {
    let ShopScore = this.shopS.cryptoService.Encrypt("ShopScore");
    console.log('shopScore');

    this.http.post(this.serverDev + '/ShopScore', ShopScore)
      .subscribe(val => {
        console.log('updated score barber ', val);
      });
  }

  choosen_address;


  ngOnInit() {
    this.shopS.buttonHide = true;
    this.randomBackground();
    this.random_emoji();
    let choosen_address = localStorage.getItem('index_address') ? localStorage.getItem('index_address') : 'my_location';
    this.choosen_address = choosen_address == 'my_location' ? 'my_location' : JSON.parse(choosen_address);
    // this.point_geo_location_premission = localStorage.getItem('point_geo_location_premission') ? localStorage.getItem('point_geo_location_premission') : 'false';

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.setLocation();
    });


    // this.shopS.getShopBarber().subscribe(val => {
    //   console.log('val ', val);
    //   this.barber_scoring_array = this.shopS.json2array(val);
    // });

    // this.get_score();
  }

  locationPremission = true;

  setCurrentLocation() {// set latitude and longitude by current location and send them to search near by buis
    console.log('setCurrentLocation');
    this.my_address = "המיקום שלי";
    this.loadingService.presentforever_with_text('מתחבר לשירותי המיקום...');
    // navigator.geolocation.getCurrentPosition((position) => {

    if (this.platform.is('ios')) {
      console.log("1");

      navigator.geolocation.getCurrentPosition((position) => {

        console.log('POSITION!!!!!!IOS !!!!!!', JSON.stringify(position));
        this.loadingService.dismiss();
        console.log('position', position);
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 8;
        console.log(this.lat, this.lng, this.zoom);
        this.locationPremission = true;
        localStorage.setItem('point_geo_location_premission', 'true');
        this.point_geo_location_premission = 'true';
        this.getAddress(this.lat, this.lng);
        this.send_to_search(this.lat, this.lng);

      }, err => {
        console.log('POSITION!!!!!!IOS !!!!!!ERR', JSON.stringify(err))

        this.loadingService.dismiss();
        this.shopS.resetLocation();
        this.locationPremission = false;
        localStorage.setItem('point_geo_location_premission', 'false');
        this.point_geo_location_premission = 'false';


      })
    } else {
      console.log("2");

      ////////////////////////////////////////////////////////////////////////////////////////////////

      this.geolocation.getCurrentPosition().then((position) => {
        this.loadingService.dismiss();
        console.log('position', position);
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 8;
        console.log(this.lat, this.lng, this.zoom);
        this.locationPremission = true;
        localStorage.setItem('point_geo_location_premission', 'true');
        this.point_geo_location_premission = 'true';
        this.getAddress(this.lat, this.lng);
        this.send_to_search(this.lat, this.lng);

      }).catch((err) => {
        console.log('this.geolocation err', err);
        this.loadingService.dismiss();
        this.shopS.resetLocation();
        this.locationPremission = false;
        localStorage.setItem('point_geo_location_premission', 'false');
        this.point_geo_location_premission = 'false';
      });

    }////////////////////////////////////////////////////////////////

  }



  x = document.getElementById("demo");
  showPosition(position) {
    console.log('position ', position);
    // this.x.innerHTML = "Latitude: " + position.coords.latitude + "<br>Longitude: " + position.coords.longitude;
    // console.log('this.x.innerHTML', this.x.innerHTML);
  }

  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          console.log(results[0].formatted_address);

          // this.my_address = results[0].formatted_address.split(',')[0];
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }



  serverDev = env.cloudfunctions;
  //החרושת 14 בת ים lat lng
  // lat = 32.01453212972839;
  // lng = 34.75019718666279;
  lat;
  lng;

  radius = 5 * 1000;
  // buis_category = this.shopS.configDATA.default.bizTypeArray;

  search_marker = {
    // lat: this.lat,
    // long: this.lng,
    icon: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
    // icon=  "https://point-app.net/wp-content/uploads/2021/02/clock-solid.png"
  }

  Barber_array = [];
  Barbers_allready_set = [];
  Barber_broken = [];
  totaly_broken = [];
  buis_index_array = [];
  retry = 5;

  buisType: any = 'all';
  buis_category_array = [];
  buis_category_array_filter = [];
  buis_category_array_display = [];

  drag_search_marker(event: { coords: { lat: any; lng: any; }; }) {//get lat lng from map marker
    // console.log('drag_search_marker', event);
    let lat_marker = event.coords.lat;
    let lng_marker = event.coords.lng;
    this.radius = 5000;
    this.lat = lat_marker;
    this.lng = lng_marker;

    this.getAddress(lat_marker, lng_marker);
    this.send_to_search(lat_marker, lng_marker);
  }
  searching = false;

  send_to_search(lat_marker: number, lng_marker: number) {// send to search buis near by
    // this.buis_category_array = [];

    // this.buis_category_array_filter = [];

    this.searching = true;
    // this.loading_more = true;
    var bounds = {
      center: [lat_marker, lng_marker],
      radiusInM: this.radius
    }
    console.log('bounds', bounds);

    this.geo_hash(bounds);
  }

  geo_hash(bounds: any) {// get near by buis by geohash (calculated from lat lng coords)
    bounds = this.shopS.cryptoService.Encrypt(bounds);

    this.http.post(this.serverDev + '/GeoHash', bounds).subscribe((val: any) => {
      console.log('geohash val', val, val.length);
      console.log(this.buis_index_array.length);
      let buis_index_id_list = this.buis_index_array.map(busi => busi._fieldsProto.BarberID[busi._fieldsProto.BarberID.valueType]);

      if (this.buis_index_array.length) {
        if (val.length) {

          val.forEach(busi => {
            if (!buis_index_id_list.includes(busi._fieldsProto.BarberID[busi._fieldsProto.BarberID.valueType])) {
              this.buis_index_array.push(busi);
            }
          });


          // this.buis_index_array = this.buis_index_array.concat(val);
        }
      } else {
        this.buis_index_array = val;
      }
      console.log(this.buis_index_array);

      // this.buis_index_array = val;
      if (this.buis_index_array.length < 5) {//if found less than 5 buis expand radius and search 
        this.enlarge_radius();
      } else {//found at least 5 buis -> sort by distance or popularity 
        this.sort_index();
      }

    });

  }

  sort_index() {//sort business array by distance or popularity 
    console.log('sort');

    let self = this;
    this.buis_index_array.sort(function (a, b) {
      return parseFloat(a[self.current_view_tag]) < parseFloat(b[self.current_view_tag]) ? -1 : 1;
    });

    // this.buis_index_array.sort((a, b) => Number(a[self.current_view_tag]) - Number(b[self.current_view_tag]));
    // console.log('after sorting buis_index_array ', this.buis_index_array);
    // if (self.current_view_tag == 'Score') {
    //   this.buis_index_array.sort((a: { Score: number; }, b: { Score: number; }) =>
    //     b.Score - a.Score);
    // }
    // console.log('self.current_view_tag', self.current_view_tag);
    this.buis_index();
  }



  // categoryLoader = true;
  buis_index() {// arranges the search results array
    let category_id_list = this.buis_category_array.map(busi => busi.BarberID);
    let promises = [];
    let shopIndexInfo = {};
    var score = 0;
    console.log(this.buis_index_array);

    // let new_busi = false;
    this.buis_index_array.forEach((bizID: any, index) => {
      console.log(!category_id_list.includes(bizID._fieldsProto.BarberID.stringValue));
      if (!category_id_list.includes(bizID._fieldsProto.BarberID.stringValue)) {
        // promises.push(this.shopS.getShopBizBarber(bizID._fieldsProto.BarberID.stringValue));////////////////////
        let biz = {
          GeoHash: bizID._fieldsProto.GeoHash.stringValue,
          latitude: bizID._fieldsProto.Latitude.doubleValue,
          longitude: bizID._fieldsProto.Longitude.doubleValue,
          BarberID: bizID._fieldsProto.BarberID.stringValue,
          buisType: bizID._fieldsProto.BuisType.stringValue,
          searchByText: bizID._fieldsProto.searchByText.booleanValue,
          name: bizID._fieldsProto.BuisName.stringValue,
          code: bizID._fieldsProto.BuisCode.stringValue,
          userManager: bizID._fieldsProto.userManager.stringValue,
          logo: 'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/icon.png?alt=media&token=9663ccd6-2c4e-415a-ae30-975e3fa2eb8f',//bizID._fieldsProto.logo.stringValue || ,
          cover: 'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/65fbc076-7594-4e2b-8935-ed220e8a1c86?alt=media&token=0bf9133c-07d9-4bf2-81eb-1e20bec0ba3a'//bizID._fieldsProto.cover.stringValue || 
        }

        if (bizID._fieldsProto.logo) {
          biz['logo'] = bizID._fieldsProto.logo.stringValue
        }
        if (bizID._fieldsProto.cover) {
          biz['cover'] = bizID._fieldsProto.cover.stringValue
        }

        promises.push(biz);
        if (bizID._fieldsProto.Total_Score.integerValue) {
          score = (bizID._fieldsProto.Total_Score.integerValue).toString();
        }
        if (bizID._fieldsProto.Total_Score.doubleValue) {
          score = (bizID._fieldsProto.Total_Score.doubleValue).toString();
        }

        shopIndexInfo[bizID._fieldsProto.BarberID.stringValue] = {
          distanceInKm: (Math.round(bizID.distanceInKm * 100) / 100).toFixed(2),
          Score: score
        };
      }

    });


    if (promises.length) {
      Promise.all(promises).then((values) => {
        values.forEach(val => {
          // let business = val.val();
          let business = val;
          business['distanceInKm'] = shopIndexInfo[business.BarberID].distanceInKm;
          business['Score'] = shopIndexInfo[business.BarberID].Score;
          this.shopS.configDATA.default.bizTypeArray.forEach(bizType => {
            if (bizType.type == business['buisType']) {
              business['category'] = bizType.name;
            }
          });
          this.buis_category_array.push(business);

          if (business.buisType == this.buisType || this.buisType == 'all') {
            this.buis_category_array_filter.push(business);
          }
        });

        if (this.loading_more) {
          this.load_more();
        } else {
          this.buis_category_array_display = this.buis_category_array_filter.slice(0, 5);
        }


        if (this.buis_category_array_filter.length < 5) {
          this.enlarge_radius();//if found less than 5 buis expand radius and search 
        } else {
          this.searching = false;
        }

        console.log(this.buis_category_array);
        console.log(this.buis_category_array_filter);
        console.log(this.buis_category_array_display);

      });
    } else {//didn't found any businesses -> expand radius and search 
      this.enlarge_radius();
    }
    // console.log(subs);

  }

  // search_by_categoty(category: any) {
  //   this.buisType = category.type;

  //   if (category.type == 'all') {
  //     this.buis_category_array_filter = JSON.parse(JSON.stringify(this.buis_category_array));

  //   } else {
  //     // this.buis_category_array_filter = [];

  //     this.buis_category_array_filter = JSON.parse(JSON.stringify(this.buis_category_array.filter(x => x.buisType == this.buisType)));
  //     console.log('buis_category_array_filter.length', this.buis_category_array_filter.length);

  //     if (this.buis_category_array_filter.length < 5) {
  //       this.enlarge_radius();
  //     }
  //   }
  //   console.log('search_by_category', this.buisType, category);
  //   // console.log('2___buis_category_array_filter', this.buis_category_array_filter);
  // }

  filter_category(category) {// filters search results array by buis type
    this.buis_category_array_filter = [];

    if (this.buisType != category) {
      this.buisType = category;

      if (category == 'all') {
        this.buis_category_array_filter = this.buis_category_array;
      } else {
        this.buis_category_array_filter = this.buis_category_array.filter(busi => busi.buisType == category);
      }
      this.buis_category_array_display = this.buis_category_array_filter.slice(0, 5);
      console.log(this.buis_category_array_filter, this.current_view_tag);
      if (this.buis_category_array_filter.length < 5) {
        // if (this.buis_category_array_filter.length == 0) {
        // this.categoryLoader = true;
        // }
        this.enlarge_radius();//expand radius and search 
      }
    }
  }

  enlarge_radius() {//expand radius and search for businesses
    // if (this.buis_category_array_filter.length < 5 || this.buis_index_array.length < 5) {
    if (this.retry) {
      this.radius = this.radius + (5 * 1000);
      console.log('this.radius', this.radius);
      this.send_to_search(this.lat, this.lng);
      this.retry--;
    } else {
      console.log("NO RETRY LEFT");
      this.searching = false;
      // this.categoryLoader = false;
    }
    // console.log('buisType ', this.buisType);
    // console.log('length1___buis_category_array_filter ', this.buis_category_array_filter.length, this.buis_category_array_filter);

    // alert('empty');
    // }


    // else {
    console.log('buis_category_array_filter ', this.buis_category_array_filter.length, this.buis_category_array_filter);
    // this.start_scoring();
    // }

  }


  // clear_search() {
  //   console.log('clear search');
  //   this.radius = 5 * 1000;
  //   // this.lat = 32.01453212972839;
  //   // this.lng = 34.75019718666279;
  //   this.setCurrentLocation();
  //   console.log("Current location", this.lat, this.lng);
  //   this.buisType = 'all';

  //   // this.send_to_search(this.lat, this.lng);
  // }





  getGeoLocation() {
    // var BarberID, BuisType, lat, lng;

    this.shopS.getShopBarber().then(res => {
      let val = res.val();
      console.log('val ', val);
      this.Barber_array = this.shopS.json2array(val);

      console.log("Barber_array length", this.Barber_array.length);
      this.Barbers_allready_set = this.Barber_array.filter(x =>
        x.Setting &&
        x.Setting.GeoHash &&
        x.Setting.latitude
      );
      this.Barber_broken = this.Barber_array.filter(x =>
        x.Setting &&
        !x.Setting.latitude
      );


      this.totaly_broken = this.Barber_array.filter(x =>
        !x.Setting
      );
      console.log('Barber_broken', this.Barber_broken);
      console.log('totaly_broken', this.totaly_broken);
      console.log('Barbers_allready_set', this.Barbers_allready_set);

    });
  }

  setGeoLocation() {
    console.log('setGeoLocation');

    this.Barber_array.forEach((Barber, index) => {
      if (Barber.Setting) {
        if (Barber.Setting.latitude && Barber.Setting.longitude) {
          // if (Barber.Setting.GeoHash) {

          var Barber_obj = {
            BarberID: Barber.Setting.BarberID,
            BuisType: Barber.Setting.buisType,
            BuisName: Barber.Setting.name,
            lat: Barber.Setting.latitude,
            lng: Barber.Setting.longitude,
            searchByText: Barber.Setting.searchByText,
            BuisCode: Barber.Setting.code,
            logo: Barber.Setting.logo,
            cover: Barber.Setting.cover,
            actionType: 'setGeoLocation'
          }

          console.log("Barber_obj ", Barber.Setting.BarberID, Barber_obj);

          Barber_obj = this.shopS.cryptoService.Encrypt(Barber_obj);

          this.http.post(this.serverDev + '/Geo', Barber_obj).subscribe(val => {
            console.log('val ', val);
          });
          // }
        }
      }
    });
  }

  search_results_array = [];
  search_val = "name";
  searchText = "";



  search() {
    if (this.search_val == 'name') {
      this.search_by_name();
    }
    if (this.search_val == 'code') {
      this.search_by_code();
    }
    if (this.search_val == 'type') {
      this.search_by_type();
    }
    if (this.search_val == 'address') {
      this.search_by_address();
      // console.log('address: ', this.searchText);
    }
  }

  inputIsFocus = false;

  focus_input() {
    this.address_open = false;
    this.inputIsFocus = true;
    this.search_started = false;
  }

  focusout_input() {
    setTimeout(() => {
      this.inputIsFocus = false;
      this.searchText = "";
      this.search_results_array = [];
    }, 100);
  }

  search_started = false;

  search_by_name() {//searching businesses by search input 'name' 
    if (this.searchText.length) {
      this.search_started = true;
      this.address_open = false;
      this.loader = true;
      console.log("BuisName ", this.searchText);
      var search_name = {
        search_type: 'name',
        center: [this.lat, this.lng],
        radiusInM: this.radius,
        BuisName: this.searchText
      }
      let encrypt = this.shopS.cryptoService.Encrypt(search_name);
      // let result_array = [];
      // this.search_open = false;
      this.http.post(this.serverDev + '/SearchBuis', encrypt).subscribe(val => {
        console.log('val ', val);
        // result_array = this.shopS.json2array(val);
        if (this.searchText == search_name.BuisName) {
          let arr = this.shopS.json2array(val);
          this.get_shop_settings(arr);
          if (!arr.length) {
            this.loader = false;
          }
        }
        // this.searchText = "";
      });
    }
  }

  mouseTimer = 0;
  mouseInt;

  startTimer() {
    // this.search_results_array = [];
    if (this.mouseInt) this.stopTimer();
    this.mouseInt = setInterval(() => {
      this.mouseTimer += 100;
      // console.log(this.mouseTimer);
      if (this.mouseTimer >= 500) {
        this.stopTimer();
        console.log('searchText Input', this.searchText);
        if (this.searchText) {
          this.search_by_name();
        } else {
          this.search_results_array = [];
          this.loader = false;
        }
      }
    }, 100);
  }

  stopTimer() {
    this.mouseTimer = 0;
    clearInterval(this.mouseInt);
  }

  codeText = ' ';

  input_code(num) {
    if (this.codeText == ' ') {
      this.codeText = num.toString();
    } else {
      if (num == 'delete')
        this.codeText = this.codeText.slice(0, -1);
      else {
        this.codeText = this.codeText.concat(num.toString());
      }
    }
    // if (num == 'delete') {
    //   this.codeText = this.codeText.slice(0, -1);
    // }


    if (this.codeText.length == 5) {
      this.loader = true;
      console.log('buis code input', this.codeText);
      this.searchText = this.codeText;
      this.search_by_code();
      // this.code_toggle();
    }
  }

  search_by_code() {//searching businesses by search input 'code' 
    console.log("BuisCode ", this.searchText);
    var search_code = {
      search_type: 'code',
      center: [this.lat, this.lng],
      radiusInM: this.radius,
      BuisCode: this.searchText
    }
    search_code = this.shopS.cryptoService.Encrypt(search_code);

    this.http.post(this.serverDev + '/SearchBuis', search_code).subscribe(val => {
      console.log('val ', val);
      let arr: any = val;

      if (arr.length) {
        this.get_shop_settings(this.shopS.json2array(val));
      } else {
        this.loader = false;
      }
    });

  }

  search_by_type() {//searching businesses by search input 'type' 
    console.log(" buis search by: ", this.searchText);

    this.shopS.configDATA.default.bizTypeArray.forEach(category => {
      var search_type = {
        search_type: 'type',
        center: [this.lat, this.lng],
        radiusInM: this.radius,
        type: category.type
      }

      if (category.name.includes(this.searchText) || category.type.includes(this.searchText)) {
        search_type = this.shopS.cryptoService.Encrypt(search_type);
        this.http.post(this.serverDev + '/SearchBuis', search_type).subscribe(val => {
          console.log('val ', val);
          this.get_shop_settings(this.shopS.json2array(val));
        });
      }
    });

    // JSON.parse(JSON.stringify(this.buis_category_array.filter(x => x.buisType == this.buisType)));
  }


  search_by_address() {//searching businesses by search input 'address' 
    this.shops_lat = this.shopS.shop_latitude;
    this.shops_lng = this.shopS.shop_longitude;
    console.log("shopS lat lng", this.shops_lat, this.shops_lng);

    // console.log("BuisAddress ", this.searchText);
    var search_address = {
      search_type: 'address',
      center: [this.lat, this.lng],
      radiusInM: this.radius,
      buis_lat: this.shops_lat,
      buis_lng: this.shops_lng
      // BuisName: this.searchText
    }

    search_address = this.shopS.cryptoService.Encrypt(search_address);

    this.http.post(this.serverDev + '/SearchBuis', search_address).subscribe(val => {
      console.log('val ', val);
      this.get_shop_settings(this.shopS.json2array(val));
    });
  }

  get_shop_settings(buis_array) {//arranges the search results array 
    this.search_open = true;
    this.search_results_array = [];
    buis_array.forEach((bizID: any, index) => {
      // this.shopS.getShopBizBarber(bizID.BarberID).then((res: any) => {///////////////////////////////////
      //   let val = res.val();
      //   if (!val) {
      //     console.log('errrrrrrrrrrrorrrrr no settings', bizID.BarberID);
      //   }
      //   val['distanceInKm'] = (Math.round(bizID.distanceInKm * 100) / 100).toFixed(2);
      //   val['Score'] = bizID.Total_Score;
      //   this.shopS.configDATA.default.bizTypeArray.forEach(bizType => {
      //     if (bizType.type == val['buisType']) {
      //       val['category'] = bizType.name;
      //     }
      //   });
      //   // console.log('val ', val);
      //   this.search_results_array.push(val);
      //   this.loader = false;
      //   if (index == buis_array.length - 1) {
      //     console.log('search_results_array', this.search_results_array);
      //     // this.buis_category_array_filter = this.search_results_array;
      //   }
      // });


      bizID['name'] = bizID['BuisName'];
      bizID['code'] = bizID['BuisCode'];
      bizID['distanceInKm'] = (Math.round(bizID.distanceInKm * 100) / 100).toFixed(2);
      bizID['Score'] = bizID.Total_Score;
      this.shopS.configDATA.default.bizTypeArray.forEach(bizType => {
        if (bizType.type == bizID['BuisType']) {
          bizID['category'] = bizType.name;
        }
      });
      // console.log('val ', val);
      this.search_results_array.push(bizID);
      this.loader = false;
      if (index == buis_array.length - 1) {
        console.log('search_results_array', this.search_results_array);
        // this.buis_category_array_filter = this.search_results_array;
      }

    });
  }

  current_view_tag = 'distanceInKm';
  resetCode() {
    this.codeText = ' ';
    this.search_results_array = [];
  }

  view_by(view: string) { //sort reasult array by distance or popularity (score)
    this.current_view_tag = view;
    let order;
    if (view == 'Score') {
      order = 1;
    } else {
      order = -1;
    }

    this.buis_category_array = this.buis_category_array.sort((a, b) => {
      return parseFloat(a[this.current_view_tag]) < parseFloat(b[this.current_view_tag]) ? order : (-1 * order);
    });

    this.buis_category_array_filter = this.buis_category_array_filter.sort((a, b) => {
      return parseFloat(a[this.current_view_tag]) < parseFloat(b[this.current_view_tag]) ? order : (-1 * order);
    });
    this.buis_category_array_display = this.buis_category_array_filter.slice(0, 5);
  }



  map_open = false;
  map_toggle() {
    this.map_open = !this.map_open;
  }

  search_location_window = false;

  toggle_location_window() {
    console.log('toggle_location_window');

    this.search_location_window = !this.search_location_window;
    this.shopS.resetLocation();

    if (this.search_location_window) {
      let input = document.getElementById('txtHome');
      setTimeout(() => {
        if (this.search_location_window) {
          input.focus();
        }
      }, 500);
    }
  }

  search_open = false;

  code_open = false;
  code_toggle() {
    this.code_open = !this.code_open;
    this.codeText = ' ';
    this.search_open = false;
    this.searchText = "";
  }

  address_open = false;

  // address_input() {
  //   // this.my_address = this.shopS.user_address_input.slice(0, -7);
  //   this.radius = 5000;
  //   this.lat = this.shopS.shop_latitude;
  //   this.lng = this.shopS.shop_longitude;
  //   this.send_to_search(this.lat, this.lng);
  //   this.address_toggle();
  //   this.search_open = false;
  //   console.log("this.my_address", this.my_address);
  //   console.log("lat lng", this.shopS.shop_latitude, this.shopS.shop_longitude);
  // }

  address_toggle() {
    this.shopS.resetLocation();
    this.address_open = !this.address_open;
    this.search_open = false;
    this.search_location_window = false;
    // this.my_address = "המיקום שלי";
  }

  remove_address(address) {//remove address from user address list
    delete this.us.user.index_address_list[address.key];
    this.us.user.index_address_list = JSON.parse(JSON.stringify(this.us.user.index_address_list));
    this.shopS.remove_address(address);
  }

  showadrress = true;
  add_address() {//add address to user address list
    console.log(this.shopS.locationSet);
    console.log(this.us.user.index_address_list);
    console.log(this.choosen_address);
    if (this.shopS.locationSet.address) {
      let index = this.shopS.locationSet.address.indexOf(this.shopS.locationSet.country) ? this.shopS.locationSet.address.indexOf(this.shopS.locationSet.country) - 2 : -8;
      let address = {
        name: this.shopS.locationSet.address.slice(0, index),
        address: this.shopS.locationSet.address,
        lat: this.shopS.locationSet.latitude,
        lng: this.shopS.locationSet.longitude
      }
      console.log(address);

      this.shopS.add_address(address).then(res => {
        if (!this.us.user.index_address_list) {
          this.us.user.index_address_list = {};
        }
        this.us.user.index_address_list[res.key] = address;
        setTimeout(() => {
          this.us.user.index_address_list = JSON.parse(JSON.stringify(this.us.user.index_address_list));
        }, 500);
        this.shopS.resetLocation();
        this.showadrress = true;
        this.choose_address(address);
      });
    }

  }

  setChoosenLocation() {//setting latitude longitude from choosen address and search buis 
    this.my_address = this.choosen_address.name.split(',')[0];
    this.lat = this.choosen_address.lat;
    this.lng = this.choosen_address.lng;
    this.send_to_search(this.lat, this.lng);
  }

  setLocation() {
    this.buis_index_array = [];
    this.buis_category_array = [];
    this.buis_category_array_filter = [];
    this.buis_category_array_display = [];

    if (this.choosen_address == 'my_location') {
      this.geoLocationPremission();
    } else {
      this.locationPremission = true;
      this.setChoosenLocation();
    }
  }

  choose_address(address) {//setting latitude longitude from choosen address 
    // this.my_address = "המיקום שלי";
    console.log(address);
    this.radius = 5000;
    this.retry = 5;
    this.choosen_address = address;
    localStorage.setItem('index_address', JSON.stringify(address));
    this.setLocation();
    this.shopS.resetLocation();
    this.searchText = "";
    this.search_open = false;
    this.address_open = false;
    console.log('user address:', this.us.user.index_address_list);
  }


  // location_err = false;
  // toggle_location_err() {
  //   this.locationPremission = !this.locationPremission;
  // }



  loading_more = false;
  // ss = true;
  scroll(e) {
    // console.log((e.target.scrollHeight - e.target.offsetHeight) + ' / ' + e.target.scrollTop);
    if ((e.target.scrollHeight - e.target.offsetHeight) - e.target.scrollTop <= 250 && !this.loading_more && this.buis_category_array_display.length < this.buis_category_array_filter.length) {
      this.load_more();
    }
  }

  add_retry() {
    this.retry++;
    this.loading_more = true;
    this.enlarge_radius();//expand radius and search 
  }

  load_more() {//loading more results to array
    this.loading_more = true;
    setTimeout(() => {
      let to_push = this.buis_category_array_filter.slice(this.buis_category_array_display.length, this.buis_category_array_display.length + 5);
      console.log(to_push);
      this.buis_category_array_display = this.buis_category_array_display.concat(to_push);
      this.loading_more = false;
    }, 200);
  }

  move_to_search(input) {
    this.codeText = ' ';
    this.searchText = '';
    this.search_results_array = [];
    this.code_open = false;
    setTimeout(() => {
      input.focus();
    }, 100);
  }

}
