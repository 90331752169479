import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/providers/toast-service';
import { ShopService } from 'src/ShopService/shop.service';
import { userService } from 'src/ShopService/user.service';
import { ExclusivePopoverComponent } from '../exclusive-popover/exclusive-popover.component';
import { LeadModalComponent } from '../lead-modal/lead-modal.component';
import { PaymentService } from '../payments/payment-service/payment.service';
import { PremiumService } from '../premium/premium-service/premium.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-choose-package',
  templateUrl: './choose-package.component.html',
  styleUrls: ['./choose-package.component.scss'],
})
export class ChoosePackageComponent implements OnInit, OnDestroy {

  sub: Subscription;
  // exclusive = false;
  options2: AnimationOptions = {
    path: '../assets/lottie/solarspace.json',
  };
  constructor(
    private premiumService: PremiumService,
    private paymentService: PaymentService,
    private modalController: ModalController,
    public shopS: ShopService,
    private us: userService,
  ) { }

  ngOnInit() {
    this.sub = this.paymentService.paymentStatus.subscribe(res => {
      if (res == 'success') {
        // this.shopS.choosePackageModal = false;
        this.shopS.dismissModalController();
        this.sub.unsubscribe();
      }
    });
  }

  modalClick(ev) {
    ev.stopPropagation();
  }


  choosePackage(pack) {
    if (pack == 'premium') {
      this.premiumService.PremiumLock('go');
    } else if (pack == 'free') {
      // this.shopS.choosePackageModal = false;
      this.shopS.dismissModalController();
      this.shopS.openModal(LeadModalComponent, null, false, null);
    } else if (pack == 'exclusive') {
      this.shopS.openModal(ExclusivePopoverComponent, 'modalLookfullWidth', false, null);
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
