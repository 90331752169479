import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';
import { ToastService } from '../../providers/toast-service';

@Component({
  selector: 'openinghours-modal',
  templateUrl: './openinghours-modal.component.html',
  styleUrls: ['./openinghours-modal.component.scss'],
})
export class OpeninghoursModalComponent implements OnInit {

  constructor(
    public shopS: ShopService,
    private toast: ToastService
  ) { }


  ngOnInit() {
    setTimeout(() => {
      // console.log('this.shopS.shopData.WorkDays',this.shopS.shopData.WorkDays);
      this.myDate = JSON.parse(JSON.stringify(this.shopS.shopData.WorkDays));
      this.myDate.forEach((element: any, index) => {
        if (element.timeStart == '00:00' && element.timeEnd == '00:00') {
          this.closeDay[index] = true;
        }
      });

    }, 4000);
  }
  checked(workday) {
    // console.log('showdata==this.myDate',this.myDate,workday)
  }
  saveHours(event) {
    // console.log('saveHours_____this.myDate',this.myDate);
    console.log(this.myDate);
    console.log(this.closeDay);
    this.myDate.forEach((element: any, index) => {
      // console.log('index',index);
      // console.log('this.closeDay[index]',this.closeDay[index]);
      if (this.closeDay[index]) {
        element.timeStart = '00:00';
        element.timeEnd = '00:00';
      }
      //   console.log('element',element);
      this.shopS.updateWorkDays(index, element.timeStart, element.timeEnd);
      // console.log('---- dani ---',dani);
      this.toast.showToast('השעות עודכנו בהצלחה');

    });
    if (this.shopS.tutrialMode) {
      this.shopS.nextSlide.next(1);
    }
    this.toggleModal(event);

  }
  nextSlide() {
    this.shopS.nextSlide.next(1);
    this.toggleModal(event);
  }
  showdata() {
    // console.log('showdata==this.myDate',this.myDate);
  }

  closeDay: any = [false, false, false, false, false, false, false];


  myDate: any = [{
    dayName: "א׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },
  {
    dayName: "ב׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },
  {
    dayName: "ג׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },
  {
    dayName: "ד׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },
  {
    dayName: "ה׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },
  {
    dayName: "ו׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },
  {
    dayName: "ש׳",
    timeEnd: "18:00",
    timeStart: "08:00"
  },];


  toggleModal(event) {
    event.stopPropagation();
    this.shopS.openingHoursmodalFLAG = !this.shopS.openingHoursmodalFLAG;
    console.log('toggleModal=======openingHoursmodalFLAG', this.shopS.openingHoursmodalFLAG);
  }

}
