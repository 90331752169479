import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberComma'
})
export class NumberCommaPipe implements PipeTransform {

  transform(value: any): any {
    value = value.toString();
    if (value.includes('.')) {
      value = value.split('.')[0];
    }
    if (value.length > 3) { 
      let length = value.length;
      let commas = Math.floor(length / 3);
      let sherit = length % 3;
      sherit = sherit == 0 ? 3 : sherit;
      commas = sherit == 3 ? commas - 1 : commas;
      let fixed_num = value.slice(0, sherit);

      for (let i = 0; i < commas; i++) {
        fixed_num += ',' + value.slice(sherit + (i * 3), sherit + (i * 3) + 3);
      }

      return fixed_num;
    } else return value;
  }
}