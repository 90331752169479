import { Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import { Http, Response } from '@angular/http';
import { PaymentService } from "../payment-service/payment.service";
import { ToastService } from "../../../providers/toast-service";
declare let PayMe: any; //declare moment

@Component({
  selector: 'app-add-credit-card',
  templateUrl: './add-credit-card.component.html',
  styleUrls: ['./add-credit-card.component.scss'],
})
export class AddCreditCardComponent implements OnInit {

  cardNo = '';
  fullName = '';
  cardDate = '';
  cvv = '';
  cardDateToSend = ''
  isValidate = false;
  tz = '';


  constructor(
    private _http: Http,
    public ToastService:ToastService,
    public PaymentService:PaymentService,
  ) { }

 allFieldsReady = [];


ngOnInit() {




  let apiKey = 'ac76cbc1-9a83-47a4-82bd-1c82c4979fdd';
  const DEFAULT_SETTINGS = {
    styles: {
      base: {
        'color': '#565B7D',
        'font-size': '16px',
        '::placeholder': { 'color': '#8FA7C8' },
      },
      invalid: {
        'color': '#fff',
      },
      valid: {
        'color': '#565B7D',
      },
    },
  };

 console.log('PayMe:',PayMe)
 PayMe.create(apiKey, { testMode: true }).then((instance) => {

  const fields = instance.hostedFields();

  // Protected fields ------------------------------------------------------

  // Card Number
  const cardNumberSettings = Object.assign({}, DEFAULT_SETTINGS, {
    placeholder: '1234 1234 1234 1234',
    messages: {
      invalid: 'Bad credit card number',
      required: 'Field "Card Number" is mandatory',
    },
  });
  const cardNumber = fields.create(PayMe.fields.NUMBER, cardNumberSettings);
  this.allFieldsReady.push(
    cardNumber.mount('#card-number-container2'),
  );
  cardNumber.on('keyup', function(event){
    console.log(' cardNumber keyup-event',event);
    
  });
  cardNumber.on('blur', function(event){
    console.log('cardNumber blur-event',event);
    
  });
  cardNumber.on('focus',function(event){
    console.log('cardNumber focus-event',event);
  });

  // Expiry Date
  const expirationField = Object.assign({}, DEFAULT_SETTINGS, {
    messages: {
      invalid: 'Invalid Expiration',
      required: 'Field "Expiration" is mandatory',
    },
  });
  const expiration = fields.create(PayMe.fields.EXPIRATION, expirationField);
  this.allFieldsReady.push(
    expiration.mount('#card-expiration-container2'),
  );
  expiration.on('keyup', function(event){
    console.log('expiration keyup-event',event);
    
  });
  expiration.on('validity-changed', function(event){
    console.log('expiration validity-changed-event',event);
    
  });
  expiration.on('blur', function(event){
    console.log('expiration blur-event',event);
    
  });
  expiration.on('focus',function(event){
    console.log('expiration focus-event',event);
    
  });

  // CVC/CVV
  const cvcField = Object.assign({}, DEFAULT_SETTINGS, {
    placeholder: 'CVC',
    messages: {
      invalid: 'Invalid CVC',
      required: 'Field "CVC" is mandatory',
    },
  });
  const cvc = fields.create(PayMe.fields.CVC, cvcField);
  this.allFieldsReady.push(
    cvc.mount('#card-cvv-container2'),
  );
  cvc.on('keyup', function(event){
    console.log('CVC keyup-event',event);
    
  });
  cvc.on('validity-changed', function(event){
    console.log('CVC validity-changed',event);
    
  });
  cvc.on('blur', function(event){
    console.log('CVC blur-event',event);
    
  });
  cvc.on('focus',function(event){
    console.log('CVC focus-event',event);
    
  });


});
}




/////////////////////////////////////////////////
////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////








validte(){
  // console.log('this.cardNo',this.cardNo);
  // console.log('this.fullName',this.fullName);
  // console.log('this.cvv',this.cvv);
  if((this.cardNo.length > 7 )&&(this.fullName.length > 3 )&&(this.cvv.length > 2)&&(this.cardDate.length > 3)&&(this.tz.length > 8)
  ){
    this.isValidate=true;
  }else{
    this.isValidate=false;
  }
}




IdentifyCard(){
  let elem = document.querySelector('.jp-card-identified');
  if(elem){
    console.log('elem',elem);
  let fields = elem.classList[1].split('-');
  console.log('fields',fields);

  if  (fields[2] == 'safari'){
    fields = elem.classList[2].split('-');
  }

  return fields[2];
  }else{
 //  console.log('not recognize');  
   this.ToastService.showTostDanger('not recognize');
  return 'none'; 
  }
}


expirationCheck(){

 let Expration = this.cardDate.replace( /\s/g, '').split('/');
if(Expration[1].length ==4){
 // console.log('full year');
 // let fullYear = Expration[1];
  Expration[1] = Expration[1].slice(-2);
 // console.log(Expration)
}

let cardDate = new Date( '20'+Expration[1]+'-'+ Expration[0]);
//console.log('cardDate',cardDate);

if(cardDate <= new Date()){
 // console.log('card expired');
  this.ToastService.showTostDanger('card expired');
  return false;
}

///console.log('Expration',Expration);

this.cardDateToSend = Expration[0] + Expration[1];
return true;
}


defaultCheck(){
  if(this.PaymentService.userCards.length == 0){
    return true;
  }else{
    return false;
  }
}


removeSpaces(string){
 return string.replace( /\s/g, '');
}

/////////////////////////////////////////////////
////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////
async sendService(){

  if((!this.expirationCheck()) || (this.IdentifyCard()=='none')){
    return false;
  }




  let ccard = {
    cardType : this.IdentifyCard(),
    cardNo: this.removeSpaces(this.cardNo),
    fullName: this.fullName,
    cardExpiration: this.cardDateToSend, 
    cvv: this.cvv,
    tz: this.tz,
    amount: 7900,
    type: 'verify',
    defaultCard: this.defaultCheck(),
   
  }

this.PaymentService.sendDataTo(ccard);


//this.PaymentService.sendData(ccard);


}



// send(){


//   if((!this.expirationCheck()) || (this.IdentifyCard()=='none')){
//     return false;
//   }

  


//   let ccard = {
//     cardType : this.IdentifyCard(),
//     cardNo: this.removeSpaces(this.cardNo),
//     fullName: this.fullName,
//     cardExpiration: this.cardDateToSend, 
//     cvv: this.cvv,
//     tz: this.tz,
//     amount: 7900,
//     type: 'firstPayment',

//   }






// // 37 - american express
// // 45 visa
// // 5*1-5 
// // 3038 - dienrs

// console.log('ccard',ccard)

//   //decodeURIComponent(
//  const data = {
//   type: 'firstPayment',
//   cardNo: 4580458045804580,
//   cardExpiration: 1222,
//   cvv: 123,
//   amount: 7900,
//   username: 'name card holder',
//   tz: '000000000'
  
//   }


// let encrypted =  this.CryptoService.Encrypt(ccard);

// this.LoadingService.presentforever();

//   this._http.post('https://webc.co.il/pointnot/payment/',encrypted).subscribe((respone:any) => {
//     console.log('respone: ', respone);
//     respone = respone['_body'];
//     let decrypted = this.CryptoService.Decrypt(respone)
//  //   console.log(JSON.parse(JSON.parse(decrypted)))
//     let res = JSON.parse(JSON.parse(decrypted));
//    // console.log(res);
//     this.handleResult(res,ccard)

//   });
// }


// handleResult(res,ccard){
// console.log(res,ccard);

 
// this.LoadingService.dismiss();

// let card = {
//   cardId: res.cardId,
//   cardExpiration: ccard.cardExpiration,
//   cardType: ccard.cardType,
//   fullName: ccard.fullName,
//   tz: ccard.tz,
//   cardDisplay: this.createDotsDisplay(res.cardId)
// }
// console.log('card-before',card);

// card = this.CryptoService.Encrypt(card);

// console.log('card-after',card);
// console.log('this.userService.key',this.userService.key);
// this.PaymentService.addNewCard(this.userService.key,card);

// }



}
