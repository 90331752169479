import { EventModalComponent } from './../../app/event-modal/event-modal.component';
import { MeetTypeModalComponent } from './../../components/meet-type-modal/meet-type-modal.component';
import { OrderButtonComponent } from './../../components/order-button/order-button.component';
import { AppointmentPage } from './../appointment/appointment';
import { meetService } from './../../ShopService/meetS.service';
import { AppModule } from './../../app/app.module';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { loginPage } from '../../pages/login/login';
// import { chooseDate } from '../chooseDate/chooseDate';
import { userService } from '../../ShopService/user.service';
import { ShopService } from '../../ShopService/shop.service';
import { Router } from '@angular/router';

import { AuthService } from '../../providers/auth-service';


import { NavController, MenuController, Platform, ModalController } from '@ionic/angular';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription } from 'rxjs';
import { PremiumService } from 'src/app/premium/premium-service/premium.service';

@Component({
  selector: 'page-about',
  templateUrl: 'about.html',
  styleUrls: ['about.scss']
})
export class AboutPage implements OnInit, OnDestroy {
  meetsType;
  BarberID;
  Mtime = 0;
  selectedArray = [];
  pages: Array<{ title: string, component: any }>;
  meetingTypes = [];
  canDrag = false;

  constructor(
    public navCtrl: NavController,
    private menu: MenuController,
    private us: userService,
    private orderButtonComponent: OrderButtonComponent,
    private afDb: AngularFireDatabase,
    public shopS: ShopService,
    private modalController: ModalController,
    private router: Router,
    private authService: AuthService,
    private ms: meetService,
    private premiumService: PremiumService,
    private Platform: Platform) {


  }

  serviceOrder() {
    if (this.shopS.shopData.Setting.Premium.status == 'premium') {
      this.canDrag = !this.canDrag;
    } else{
      this.premiumService.PremiumLock('go');
    }
  }

  handleReorder(ev) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    this.meetingTypes = ev.detail.complete(this.meetingTypes);
    // ev.detail.complete();

    for (var key in this.shopS.shopData.MeetingType) {
      let element = this.shopS.shopData.MeetingType[key];
      let foundType = this.meetingTypes.find(type => type.key == key);
      let foundIndex = this.meetingTypes.indexOf(foundType);
      element.order = foundIndex;
    }
    console.log(this.meetingTypes);

    console.log(this.shopS.shopData.MeetingType);
    this.shopS.updateMettingType();
  }

  asd(ev) {
    console.log(ev.target);

  }

  goTogoodModeP() {
    this.router.navigate(['products']);
  }

  goBack() {
    console.log('Go', this.shopS.userOrder.stage);
    //  this.shopS.userOrder.stage = 0;
    this.shopS.changeButtonState(0);
    this.clearSeletion();
    this.router.navigate(['tabs/home']);
  }

  ionViewCanLeave() {
    return false;
  }


  logout() {
    this.authService.logout();
  }

  ionViewDidEnter() {
    this.selectedArray = [];
    this.Mtime = 0;
    if (this.shopS.shopData.MeetingType) {
      // has meetings
      this.meetsType = this.shopS.json2array(this.shopS.shopData.MeetingType);
      for (var i = 0; i < this.meetsType.length; i++) {
        this.meetsType[i].active = false;
      }
    } else {
      // 
      console.log('none meet')
      const getShopMeetingTypeForUser = this.shopS.getShopMeetingTypeForUser(this.shopS.shopData.Setting.BarberID).valueChanges().subscribe(bizData => {

        console.log('bizData', bizData);
        this.meetsType = bizData;
        this.shopS.shopData.MeetingType = bizData;
        for (var i = 0; i < this.meetsType.length; i++) {
          this.meetsType[i].active = false;
        }

        // console.log('meetsType',this.meetsType);
        // console.log('this.meetsType.length',this.meetsType.length);
        getShopMeetingTypeForUser.unsubscribe()
      });
    }// end of else
    //  console.log(this.meetsType );
    //  console.log(this.shopS.shopData );
    //  console.log(this.shopS.shopData.MeetingType );

  }

  meetingTypeSub: Subscription;

  ngOnInit() {
    console.log("ONINITMEET");
    this.meetingTypeSub = this.shopS.meetingTypeChange.subscribe(meetingType => {
      if (meetingType) {
        this.meetingTypes = this.shopS.json2array(this.shopS.shopData.MeetingType).sort((a, b) => a.order - b.order);
      }
    })
    this.BarberID = this.us.user.BarberID;

    // this.meetingTypes = this.shopS.json2array(this.shopS.shopData.MeetingType).sort((a, b) => a.order - b.order);
    console.log(this.shopS.shopData.MeetingType);

    console.log(this.meetingTypes);

    // document.addEventListener('backbutton', () => {

    //   console.log('tutorial - - - - backbutton action!');

    //   if(this.shopS.AdminFLAG == true){
    //     this.router.navigate(['/tabsAdmin/calendar'])

    //   }else{
    //     this.goBack();
    //   }



    //     }, false);
  }


  addMeetType() {
    this.shopS.MeetEditmodalFLAG = true;
    this.shopS.MeetEditmodalFLAGADD = true;

    this.shopS.meetTypeData = {
      approve: true,
      color: "#4366FB",
      flow: false,
      gender: "female",
      info: "987654",
      meetTime: "30",
      name: "",
      pic: "לחeמ",
      price: "30",
      reminder: '0',
      cancelation: "0"

    };
    // this.openServiceModal();


  }

  async openEventModal() {

    const modal = await this.modalController.create({
      component: EventModalComponent,
      swipeToClose: true,
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalForPayments modalGreyBG ',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
      // Get the top-most ion-modal
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();


  }

  async openServiceModal() {

    const modal = await this.modalController.create({
      component: MeetTypeModalComponent,
      swipeToClose: true,
      cssClass: 'modalForPayments modalGreyBG modal-half-hight',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
      // Get the top-most ion-modal
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();


  }
  editMeet(event, x) {
    event.stopPropagation();
    console.log(x);
    
    //  console.log("x213123x : ", x);
    this.shopS.meetTypeData = JSON.parse(JSON.stringify(x));
    this.shopS.MeetEditmodalFLAG = true;
  }

  PushMeetType(x) {
    console.log("PUSSHMEET");

    if (this.shopS.AdminFLAG) {
      this.clearSeletion();
    }


    x.active = !x.active;
    //   console.log("xxxxx : ", x);

    if (this.shopS.AdminFLAG) {

      this.shopS.currentMeet.meetType = x;
      //  this.navCtrl.navigateBack(['tabsAdmin/add']);
      //   this.shopS.addLineFLAG = true;
      this.shopS.plusAnimation();

    } else {
      this.pages = [];
      var meetTime = 0;
      var meetName = "";
      var meetFor = JSON.parse(JSON.stringify(x));
      console.log(meetFor)
    }
    if (this.shopS.AdminFLAG) {

      this.ms.setMeetType(x);
    }
    else {
      this.selectedArray = [];
      this.shopS.shopData.MeetingType.forEach(element => {
        if (element.active == true) {
          this.selectedArray.push(element);
          meetTime = meetTime + parseInt(element.meetTime);
          meetName = meetName + " " + element.name;

        }
      });
      // console.log(this.selectedArray.length, "this.selectedArray.length")
      if (this.selectedArray.length > 1) {

        var newmeet = this.createNewMeet(meetFor, meetTime.toString(), meetName);

        this.ms.setMeetType(newmeet);
        this.shopS.changeButtonState(2);
      } else {
        if (this.selectedArray.length == 0) {

          this.orderButtonComponent.goToAboutParam();
          this.Mtime = 0;
          this.ms.setMeetType(newmeet);
        } else {

          var newmeet = this.selectedArray[0];
          this.Mtime = parseInt(newmeet.meetTime);
          this.shopS.changeButtonState(2);
          this.ms.setMeetType(newmeet);
        }

      }

      // this.ms.setMeetType(newmeet);

    }



  }
  createNewMeet(meet, time, name) {
    var meet2 = meet;
    meet2.meetTime = time;
    this.Mtime = time;
    meet2.name = name;
    meet2.approve = true;
    meet2.flow = false;

    return meet2;
  }


  clearSeletion() {
    //  console.log(this.shopS.shopData.MeetingType);


    for (var key in this.shopS.shopData.MeetingType) {
      if (this.shopS.shopData.MeetingType.hasOwnProperty(key)) {
        // console.log(key + " -> " + this.shopS.shopData.MeetingType[key]);
        this.shopS.shopData.MeetingType[key].active = false;
      }
    }


    for (var val of this.shopS.shopData.MeetingType) {
      // console.log(val);
      if (val) {
        val.active = false;
      }
      // console.log(val); // prints values: 10, 20, 30, 40
    }
  }

  doClick() {
    console.log('do Click');
  }
  ngOnDestroy() {
    if (this.meetingTypeSub) {
      this.meetingTypeSub.unsubscribe();
    }
    // this.meetsType.unsubscribe();
  }


  checkHidden(hidden) {
    if (hidden && !this.shopS.AdminFLAG) {
      return true;
    } else {
      return false;
    }
  }

}

