import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Component, OnInit, ViewChild } from '@angular/core';

import { meetService } from './../../ShopService/meetS.service';
import { BuissnesPageComponent } from './../../app/buissnes-page/buissnes-page.component';
//  import { Keyboard } from '@ionic-native/keyboard/ngx';
import { SourceImage } from 'ng2-image-compress';
import { AlertController, Platform } from '@ionic/angular';
import { AlertExample } from './../../../src/providers/alert.service';
// import { Keyboard } from '@ionic-native/keyboard';


import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { TabsPage } from '../tabs/tabs';
import { AngularFireDatabase } from '@angular/fire/database';
import { userService } from '../../ShopService/user.service';
import { ShopService } from '../../ShopService/shop.service';
import { ModalController } from '@ionic/angular';
import { ModalPage } from '../modal/modal.page';

import { AuthService } from './../../providers/auth-service';
import { ToastService } from 'src/providers/toast-service';




@Component({
  selector: 'app-switch-business',
  templateUrl: './switch-business.component.html',
  styleUrls: ['./switch-business.component.scss'],
})
export class SwitchBusinessComponent implements OnInit {
  newBuis = false;
  buisness: any;
  buisness2: any;
  serachInput = '';
  searchText = "";
  @ViewChild('searchBar', { static: false }) myInput;

  public businessCodeForm: FormGroup;
  public freeShop: any = {};

  public showBusinessNotFound: boolean = false;

  pageStatus;
  constructor(
    private angularFD: AngularFireDatabase,
    private toast: ToastService,
    public us: userService,
    public modalController: ModalController,
    private alertController: AlertController,
    private meetS: meetService,
    private alert: AlertExample,
    private authService: AuthService,
    public shopS: ShopService,
    private router: Router,
    private Platform: Platform,
    private fb: FormBuilder,
  ) { }

aa(b) {
  console.log(b);
  
}

  ngOnInit() {

    // console.log("buis on init")
    this.generateBusinessForm();
    // this.shopS.saveAllShop();

    this.shopS.log(this.shopS.us.key, 'business_add_start');

    // console.log("this.us.superNew ",this.us.superNew )

    //   document.addEventListener('backbutton', () => {
    //     console.log('addBussnies - - - - backbutton action!');


    // if(this.us.superNew == false){
    //   this.goBack("tabs");
    // }else{

    //   this.goBack("login");
    // }

    //  }, false);



    // console.log("this.shopS.shopData.Setting,",this.pageStatus);


  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: BuissnesPageComponent,
      mode:'ios',
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    // console.log("sss",data);
    if (data.dismissed != false) {
      this.getBarModal(data.dismissed)
    }

  }
  getBarModal(data) {

    console.log(data);
    this.freeShop.Setting = data;
    this.presentAlertFound(data.name)

  }
  getBar(data) {

    console.log(data);
    this.freeShop = data;
    this.presentAlertFound(data.Setting.name)

  }
  ionViewDidLeave() {
    console.log('ionViewDidLeave');
    this.shopS.changeButtonState(0);
    if (this.newBuis) {

    }
    this.shopS.disableBtn = false;

  }
  ionViewDidEnter() {

    console.log('ionViewWillEnter', this.us.user);

    this.clearFormValue();

    this.shopS.disableBtn = true;

  }

  generateBusinessForm() {
    this.businessCodeForm = this.fb.group({
      businessCode: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(5)])]
    });
  }

  getOneShop() {
    this.shopS.getShopDetils('Arie-israeli').valueChanges().subscribe((result) => {
      // console.log(result);
    }, (error) => {
      // console.log(error);
    });
  }
  addBusinessPageAdmin() {
    this.router.navigate(['admin-register'], { replaceUrl: true })
  }
  // call this func when user type 5 symbols code. Check each time when he type something.
  onInputValid() {
    if (this.businessCodeForm.valid) {

      console.log(this.businessCodeForm.value);
      var getFreeShopNew = this.shopS.getFreeShopNew(this.businessCodeForm.value.businessCode).subscribe(res => {
        if (res) {
          this.showBusinessNotFound = false;
          this.freeShop.Setting = res;
          this.presentAlertFound("")
        } else {
          this.showBusinessNotFound = true;
        }
        getFreeShopNew.unsubscribe();

        console.log("res", res)
      })
      return;

      this.freeShop = this.shopS.getFreeShop(this.businessCodeForm.value);
      if (!(Object.keys(this.freeShop).length === 0)) {
        //  this.keyboard.hide();
        console.log("found found");
        this.presentAlertFound("")
        this.showBusinessNotFound = false;
      }
    } else {
      this.freeShop = {};
      if (this.businessCodeForm.value.businessCode !== '') {
        this.showBusinessNotFound = true;
      } else {
        this.showBusinessNotFound = false;
      }
    }
  }

  goBack(toNav) {
    this.newUserClientFLAG = false;
    if (toNav == "login") {
      if (this.us.superNew == false) {

        this.presentLogOut();


      } else {
        this.us.superNew = false;
      }
    } else {
      if (toNav == "tabs") {
        this.router.navigate(['tabs/home']);

      } else {
        this.router.navigate(['/' + toNav + '']);

      }

    }

  }

  newUserClientFLAG = false;

  modalBack() {
    console.log('back to im client or biz');
    this.us.superNew = false;
    this.newUserClientFLAG = false;

  }

  modalStart(x) {
    this.us.superNew = true;
    if (x == "user") {
      this.newUserClientFLAG = true;
      console.log('this.newUserClientFLAG', this.newUserClientFLAG);

    } else {
      this.addBusinessPageAdmin();
    }

  }
  updateUser() {
    const currUser = this.us.getUserObj();
    console.log('currUser', currUser);
    if (this.freeShop.Setting.BarberID) {
      console.log('currUser', currUser);

      var BarberID = this.freeShop.Setting.BarberID;
    } else {
      return;
    }
    this.shopS.configDATA.default.userProfile
    // https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/male_avatar_323b.svg



    let businessCodes = [];
    console.log('currUser.BarberID-before', currUser.BarberID);



    // if (currUser.BarberID) {
    //   console.log('currUser.BarberID-inside',currUser.BarberID);
    //   businessCodes = currUser.BarberID;
    // }
    // console.log('currUser.BarberID-after',currUser.BarberID);
    // console.log('businessCodes-after',businessCodes);

    console.log(this.us.user);
    

    businessCodes.push(BarberID);
    // this.us.addUserOnShop(BarberID,currUser);
    // console.log(BarberID,this.us.user,this.us.user.key,"------xxxxxx");
    // this.shopS.updateUserNewToBarber(BarberID, this.us.user, this.us.user.key);
    this.shopS.addUserToBarber(this.us.user);
    // console.log(BarberID,this.us.user,this.us.user.key)
    this.us.updateShopId(BarberID).then((result) => {
      console.log(result);
      this.toast.showToast('העסק נוסף בהצלחה!');


      console.log("login - logout")
      // this.us.checkUserExciting();
      var getUserDetalisByUid = this.us.getUserDetalisByUid(this.us.user.key).snapshotChanges().subscribe((res) => {
        console.log("********CHECK THIS LOG********", businessCodes);
        
        this.us.updateBarberID(businessCodes);
        // this.authService.logOutForLogin();
        getUserDetalisByUid.unsubscribe();

        // this.us.saveUserData(newUser.payload.val(), this.us.userUid);
        //  this.router.navigate(['tabs']);
      });

    });

    this.shopS.log(this.shopS.us.key, 'business_add_finish');


  }

  saveBuisCode(code) {
    this.goBack("tabs");
    return;
    this.shopS.updateUserNewToBarber(code, this.us.user, this.us.user.key);
    this.us.updateShopId(code);
    // this.router.navigate(['tabs/home']);
  }
  submitBusinessCode() {

    var fullname = this.us.user.fname + " " + this.us.user.lname;
    // console.log("------ >>>>> ",this.businessCodeForm.value,this.us.user);
    console.log("*****>",this.freeShop)
    if (this.freeShop.Setting) {
      this.shopS.shopID = this.freeShop.Setting.BarberID;

      this.updateUser();
      let found = false;
      // this.us.user.bizArray;

      if (this.us.user.bizArray) {

        this.us.user.bizArray.forEach(element => {
          if (element == this.freeShop.Setting.BarberID) {
            found = true;
          }
        })

        if (found == false) {
          this.us.user.bizArray.push(this.freeShop.Setting.BarberID);
          this.us.addBizArray(this.us.user.bizArray).then(res => {
          })
        }

      } else {
        this.us.user.bizArray = [this.freeShop.Setting.BarberID];
        this.us.addBizArray(this.us.user.bizArray).then(res => {
        })
      }
      this.shopS.addNotificationBell('new_client', 'business', {});
      this.meetS.alertNotiToBarber({ BarberID: this.freeShop.Setting.BarberID, userFullName: fullname, BizName: this.freeShop.Setting.name }, 'newClientToBiz', false);

      this.modalController.dismiss(
        {
          'dismissed': "finish"
        }
      );
    }
    // this.shopS.updateUserToBarber(this.us.user,this.us.user.key)
  }

  closeModal() {
    this.modalController.dismiss({
      'dismissed': "finish"
    });

  }
  closeModalnodismiss() {
    this.modalController.dismiss();

  }

  clearFormValue() {
    if (this.businessCodeForm) {


      this.businessCodeForm.reset();
      this.showBusinessNotFound = false;
    }
  }

  ngOnDestroy() {
    this.clearFormValue();
  }


  async presentAlertFound(buisName) {
    var thisbuizArray = this.us.user.bizArray;
    var found = false;
    if (thisbuizArray) {

      thisbuizArray.forEach(element => {
        if (element == this.freeShop.Setting.BarberID) {
          found = true;
        }
      })
    }

    if (found == false) {


      var textT = "<img class='imgalert' src=" + this.freeShop.Setting.logo + ">"
      textT += "האם תרצה להתחבר לעסק ?";
      var headerText = "כן התחבר!";
    } else {
      var textT = "<img class='imgalert' src=" + this.freeShop.Setting.logo + ">"
      textT += "העסק כבר נמצא ברשימת העסקים";
      var headerText = "חבר אותי ";

    }


    const alert = await this.alertController.create({
      header: 'עסק נמצא!',
      subHeader: this.freeShop.Setting.name,

      animated: true,
      keyboardClose: true,
      message: textT,
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: headerText,

          handler: () => {
            this.shopS.buttonHide = false;

            this.submitBusinessCode();
          }
        }
      ]
    });

    await alert.present();
  }



  async presentLogOut() {



    const alert = await this.alertController.create({
      header: 'התנתקות',
      subHeader: "",

      animated: true,
      keyboardClose: true,
      message: "האם אתה בטוח שתרצה להתנתק ?",
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'כן',

          handler: () => {
            this.authService.logout();
          }
        }
      ]
    });

    await alert.present();
  }


  searc() {
    this.buisness2 = [];


    var self = this;
    if (this.searchText.length > 2) {
      let getAllShopsStartAT = this.shopS.getAllShopsStartAT().orderByChild("name").startAt(this.searchText).endAt(this.searchText + "\uf8ff")
        .once('value').then(res => {


          var buisness3 = self.shopS.json2array(res.val());
          buisness3.forEach(x => {
            if (x.searchByText == true) {
              self.buisness2.push(x);
            }
          })

          if (self.buisness2.length == 0) {
            this.showBusinessNotFound = true;

          } else {
            this.showBusinessNotFound = false;

          }

        })
    } else {
      this.showBusinessNotFound = false;
    }
    if ((this.searchText.length == 5) && (this.shopS.isNumber(this.searchText))) {


      var getFreeShopNew = this.shopS.getFreeShopNew(this.searchText).subscribe(res => {
        if (res) {
          this.showBusinessNotFound = false;
          this.freeShop.Setting = res;
          this.presentAlertFound("")
        } else {
          this.showBusinessNotFound = true;
        }
        getFreeShopNew.unsubscribe();
        // console.log("res",res)
      })
    }
    //  console.log(this.buisness2);
    return
    if (this.searchText.length > 2) {
      for (var i = 0; i < this.buisness.length; i++) {

        if (this.buisness[i].Setting) {

          if (this.buisness[i].Setting.name) {
            if (this.buisness[i].Setting.name.includes(this.searchText)) {
              this.buisness2.push(this.buisness[i]);

            }
          }
        }
      }
    }



  }
  onInputValid2() {
    this.buisness2 = []

    var self = this;

    for (var i = 0; i < this.buisness.length; i++) {

      if (this.buisness[i].Setting.name) {


        if (this.buisness[i].Setting.name) {
          if (this.buisness[i].Setting.name.includes(self.serachInput)) {
            self.buisness2.push(this.buisness[i])
          }
        }
      }
    }

    // this.buisness2=this.buisness.find(this.isName(self))
  }

  toggleModalUserShareToBiz(event) {
    this.closeModal();
    this.shopS.UserShareToBizFLAG = !this.shopS.UserShareToBizFLAG;

  }


  isName(x, self) {
    console.log(x, self.serachInput)
    return x.Setting.name.includes(this.serachInput);
  }
}
