// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
 
export const environment = {
  production: false,
  platform: 'web', 
  key: 'd3!@!30a,ft4d^^5ssdq',
  currentEnv : 'dev',
  firebase_prod: {
    apiKey: "AIzaSyDSZi8iTEWv51vLBcpKN3lEJrO72lCl79M",
    authDomain: "webcut-2001a.firebaseapp.com",
    databaseURL: "https://webcut-2001a.firebaseio.com",
    projectId: "webcut-2001a",
    storageBucket: "webcut-2001a.appspot.com",
    messagingSenderId: "474725772867",
    appId: "1:474725772867:web:af6b6cc4b633205d",
    name: 'prod',
    cloudfunctions: "https://us-central1-webcut-2001a.cloudfunctions.net",
    measurementId: "G-M7RTDDR9L7"
  },
  firebase_staging: {
    apiKey: "AIzaSyDSZi8iTEWv51vLBcpKN3lEJrO72lCl79M",
    authDomain: "webcut-2001a.firebaseapp.com",
    databaseURL: "https://webcut-2001a-staging.firebaseio.com",
    projectId: "webcut-2001a",
    storageBucket: "webcut-2001a.appspot.com",
    messagingSenderId: "474725772867",
    appId: "1:474725772867:web:af6b6cc4b633205d",
    name: 'staging',
    cloudfunctions: "https://us-central1-webcut-2001a.cloudfunctions.net",
    measurementId: "G-M7RTDDR9L7"
  },
  firebase_dev: {
    apiKey: "AIzaSyAmJQBO-FJBt2nmcTHRsd66PaGnsrGsLfQ",
    authDomain: "webcut-dev.firebaseapp.com",
    databaseURL: "https://webcut-dev.firebaseio.com",
    projectId: "webcut-dev",
    storageBucket: "webcut-dev.appspot.com",
    messagingSenderId: "353723050664",
    appId: "1:353723050664:web:fa85c5c720f1acfa95309d",
    name: 'dev',
    cloudfunctions: "https://us-central1-webcut-dev.cloudfunctions.net",
    measurementId: "G-M7RTDDR9L7"


    },
    firebase_dev_test: {
      apiKey: "AIzaSyAmJQBO-FJBt2nmcTHRsd66PaGnsrGsLfQ",
      authDomain: "webcut-dev.firebaseapp.com",
      databaseURL: "https://webcut-dev-tes.firebaseio.com",
      projectId: "webcut-dev",
      storageBucket: "webcut-dev.appspot.com",
      messagingSenderId: "353723050664",
      appId: "1:353723050664:web:fa85c5c720f1acfa95309d",
      name: 'dev',
      cloudfunctions: "https://us-central1-webcut-dev.cloudfunctions.net",
      measurementId: "G-M7RTDDR9L7"
  
  
      }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
