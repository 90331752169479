import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ShopService } from '../../ShopService/shop.service';
import { Router } from '@angular/router';
import { AuthService } from '../../providers/auth-service';
import { userService } from "../../ShopService/user.service";
@Component({
  selector: 'app-b-link',
  templateUrl: './b-link.page.html',
  styleUrls: ['./b-link.page.scss'],
})
export class BLinkPage implements OnInit {
  b: any;
  constructor(
    private route: ActivatedRoute,
    public shopS: ShopService,
    private userS: userService,
    private router: Router,
    private authS: AuthService,
  ) { }

  ngOnInit() {
    this.b = this.route.snapshot.paramMap.get("name");
    this.shopS.getShopDetils(this.b).valueChanges().subscribe(data => {
      // console.log(data);
      if (data) {
        console.log("YYYY");

        var state = this.authS.auth_state.getValue();
        // console.log('login',login);
        // if user logged in
        if (state == 'not-auth') {
          console.log('no user connected = saveBiz and login');
          localStorage.setItem('bizToConnect', this.b);


          this.router.navigate(['login'], { replaceUrl: true });
        } else {
          console.log(' user connected = saveBiz and Profile');
          let D = JSON.parse(localStorage.getItem('userdata'));
          let newUserData = {
            BarberID: this.b
          }


          var obj: any = {};
          obj[D.key] = D;
          console.log(obj);


          this.userS.updateUserData(newUserData);
          this.shopS.updateStr('/Users/', obj)
          this.router.navigate(['tabs']);
        }


      } else {
        this.router.navigate(['login'], { replaceUrl: true });
      }



    })

  }

}
