import { ErrorHandler, Injectable } from '@angular/core';
// import { Router } from '@angular/router';
import { CryptoService } from 'src/app/crypto.service';
import { LogService } from 'src/app/log.service';
// import { ShopService } from 'src/ShopService/shop.service';
import { userService } from 'src/ShopService/user.service';
import { environment } from '../environments/environment';

// {
//   providedIn: 'root'
// }
@Injectable(
  // {
  //   providedIn: 'root'
  // }
)
export class ErrorHandlerService extends ErrorHandler {

  environment = environment;

  constructor(
    // private shopS: ShopService
    private us: userService,
    private logS: LogService,
    public cryptoService: CryptoService,
    // private router: Router

    ) {
    super();
  }

  handleError(error: any): void {
    console.log('!!!!- ERRRORRR',error)
    let err = error.toString();
    if (!err.includes('overlay') && !err.includes('cordova_not_available')) {
      let user = this.us.user.key ? this.us.user.key : 'no-user';
      this.error_log(user, 'handleError', err);
      super.handleError(error);
    }
  }

  error_log(user, type, data) {
    console.log("error_log");
    
    if (user && this.environment.currentEnv == 'prod') {
      console.log('-----------LOGG=========', user, type);

      let sendData = {
        "user": user,
        // "barberid": this.shopData.Setting.BarberID,
        // "url": this.router.url,
        "type": type,
        "data": data
      }
      let newSend = this.cryptoService.Encrypt(sendData);

      this.logS.sendLog('https://webc.co.il/pointnot/log/', newSend)


    }
  }
}
