import { Component, OnInit } from '@angular/core';
import { TasksService } from "../tasks-service/tasks.service";
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss'],
})
export class TaskViewComponent implements OnInit {

  constructor(
    public taskS:TasksService,
    private modalctrl:ModalController
  ) { }

  ngOnInit() {}

  dismiss()
  {
    this.modalctrl.dismiss()
  }
  


}
