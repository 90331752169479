import { Platform } from '@ionic/angular';
import { AuthService } from './../../providers/auth-service';
import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
// import { AboutPage } from '../about/about';
import { ShopService } from '../../ShopService/shop.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})
export class TabsPage implements OnInit {

  constructor(public router:Router,
    public shopS: ShopService,
    private autho:AuthService,
    private Platform:Platform
    ) { 
  }

  ngOnInit() {
    console.log('tabs PAGE');
   



    // document.addEventListener('backbutton', () => {
    //   this.router.navigate(['Business']);
    //     }, false);

    // this.autho.isconnect.next(true)
    //this.shopS.userOrder.txt
  }

  hideBtn() {
    this.shopS.buttonHide = true;
  }

  showBtn() {
    this.shopS.buttonHide = false;
  }

  // goToAbout(){
  //   console.log("go to abput")
    
  //   this.router.navigate(['about']);
  // }



  // iconClass()
  // {
  //     return this.shopS.userOrder.leftIcon;
  // }


  
}
