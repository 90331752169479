import { AlertExample } from './../../providers/alert.service';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NotificationService } from '../notification.service';
import { userService } from 'src/ShopService/user.service';
import { ToastService } from 'src/providers/toast-service';
import { LoadingService } from 'src/providers/loading.service';
import { ContactListService } from '../contact-list/contact-list-service/contact-list.service';
import { ContactListComponent } from '../contact-list/contact-list.component';
import { Subscription } from 'rxjs';
import { PremiumPopoverComponent } from '../premium-popover/premium-popover.component';


class Port {
  public id: string;
  public name: string;
  public userData: object;

}




@Component({
  selector: 'app-user-search-modal',
  templateUrl: './user-search-modal.component.html',
  styleUrls: ['./user-search-modal.component.scss'],
})
export class UserSearchModalComponent implements OnInit, OnDestroy {

  @Input() uId: any;
  @Input() type: any;


  userEvent = [];
  userArray;
  searchText: any = '';
  ports: Port[];
  ports2: Port[];
  port: Port;
  UserSelected: any;
  newUser = {
    open: false,
    fname: "",
    lname: "",
    phone: "",
    ProfilePic: null,
    local: true
  }
  usersSub: Subscription;

  constructor(public shopS: ShopService,
    private modalController: ModalController,
    private notiS: NotificationService,
    public popoverController: PopoverController,
    private alertExample: AlertExample,
    private us: userService,
    private toast: ToastService,
    private AlertExample: AlertExample,
    public LoadingService: LoadingService,
    private alertController: AlertController,
    public contactsS: ContactListService
  ) { }



  toggleNewUser(form?, close?) {
    this.newUser = {
      open: close ? false : !this.newUser.open,
      fname: "",
      lname: "",
      phone: "",
      ProfilePic: null,
      local: true
    };
    if (form) {
      form.reset();
    }
    this.newUserContState = 0;
  }

  ngOnInit() {
    this.port = {
      id: this.shopS.Auser.key,
      name: this.shopS.Setting.Name,
      userData: this.shopS.Auser
    }
    this.usersSub = this.shopS.usersDataChange.subscribe(users => {
      console.log("USER CHANGE!");
      
      this.getClients();
    });
    // this.getClients();
  }

  getClients() {
    this.userArray = this.shopS.json2array(this.shopS.shopData.Users);
    // console.log(this.userArray);
    this.userEvent = [];
    this.ports = [];
    this.ports2 = [];

    this.ports.push(this.port)

    if (this.uId && this.uId.length) {
      this.uId.forEach(element => {
        var n = element.fname + ' ' + element.lname;
        if (element.fname && !element.lname.includes("undefined")) {
          if (this.shopS.Auser.key != element.key) {
            if (this.shopS.eventForBuis.needConfirm == 3) {
              this.userEvent.push({
                id: element.uId,
                name: n,
                userData: element,
                status: element.isConfirm
              })
            } else {
              this.userEvent.push({
                id: element.uId,
                name: n,
                userData: element,

              })
            }

          }
        }
      });
    }
    // this.shopS.Auser.key= this.shopS.shopData.Setting.userManager;

    this.userArray.forEach(element => {
      var n = element.fname + ' ' + element.lname;
      if (element.fname && !element.fname.includes("undefined")) {
        if (this.shopS.Auser.key != element.key) {
          if (this.findeInEventArray(element.key)) {
            this.ports.push({
              id: element.key,
              name: n,
              userData: element,
            })
          }
        }
      }
    });

    // setTimeout(() => {
    this.ports2 = this.ports.slice(0,50);
    console.log(this.ports2);

    // }, 100);
  }

  loading_more = false;

  scroll(e) {

    if ((e.target.scrollHeight - e.target.offsetHeight) - e.target.scrollTop <= 500 && !this.loading_more && !this.searchText && this.ports2.length < this.ports.length) {
      // this.lazyLoad();
      console.log("ASDASD");
      this.loading_more = true;
      this.ports2 = this.ports2.concat(this.ports.slice(this.ports2.length, this.ports2.length + 50));
      console.log(this.ports2);
      setTimeout(() => {
        this.loading_more = false;
      }, 200);
    }
  }

  createNewUser(f) {
    // let fname = f.form.controls['fname'].value;
    // let lname = f.form.controls['lname'].value;
    let phone = f.form.controls['phone'].value ? f.form.controls['phone'].value : '';
    // if (phone) {
    phone = phone.replace(/\s/g, '');
    phone = phone.replace(/-/g, '');
    console.log(phone);

    var useDate = Object.values(this.shopS.shopData.Users).filter(function (el: any) {
      return el.phone == phone &&
        el.phone != ""
    });
    console.log("useDateuseDate", useDate);
    console.log(f);
    console.log(phone);
    console.log(this.newUser);
    this.newUser.phone = phone;

    if (useDate.length == 0) {
      this.newUser.ProfilePic = this.shopS.profilePicLocalUser;
      this.toast.showToast('הלקוח נוסף בהצלחה');
      delete this.newUser.open;
      this.shopS.addUserToBarber(this.newUser);
      this.shopS.userUpdatedFLAG = false;
      this.toggleNewUser(f, 'close');
      // setTimeout(() => {
      //   this.getClients();
      // }, 4000);
    } else {
      this.AlertExample.aleryByText("מספר הטלפון כבר קיים במערכת");
    }
    // } else {
    // this.newUser.ProfilePic = this.shopS.profilePicLocalUser;
    // this.toast.showToast('הלקוח נוסף בהצלחה');
    // this.shopS.addUserToBarber(this.newUser);
    // this.shopS.userUpdatedFLAG = false;
    // this.toggleNewUser(f, 'close');
    // setTimeout(() => {
    //   this.getClients();
    // }, 5000);
    // }

  }

  newUserContState = 0;

  async goTOaddContacts() {
    this.newUserContState = 0;

    let point_contact_list_premission = localStorage.getItem('point_contact_list_premission');
    if (point_contact_list_premission == 'true') {
      console.log('point_contact_list_premission', point_contact_list_premission)
      this.LoadingService.presentforever_with_text('טוען אנשי קשר');
      this.open_contacts();
    } else {
      console.log('point_contact_list_premission', point_contact_list_premission)


      var textT = `<img class='imgalert2 contactimg' src="../../assets/contactlist.png" >`;
      textT += 'לאחר הודעה זו תופיע בקשת גישה לאנשי הקשר במכשיר שלך. חשוב לאשר את הבקשה';
      const alert = await this.alertController.create({
        header: 'ייבוא מאנשי קשר',
        message: textT,
        buttons: [
          {
            text: 'מאוחר יותר',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Cancel Cancel: Cancel');
              return false;
            }
          }, {
            text: 'מתן הרשאות',
            handler: () => {

              console.log('Okay: Okay');
              //confirm order 
              this.LoadingService.presentforever_with_text('טוען אנשי קשר');
              this.open_contacts();

            }
          }
        ]
      });
      await alert.present();
    }
    //  this.LoadingService.presentLoadingContact()
  }

  open_contacts() {
    this.shopS.log(this.shopS.us.key, 'open_contacts');

    this.contactsS.loadContacts(cb => {
      console.log(cb);
      this.LoadingService.dismiss();

      if (cb) {
        localStorage.setItem('point_contact_list_premission', 'true')
        this.newModal();
      }
    })
  }

  async newModal() {
    const modal = await this.modalController.create({
      component: ContactListComponent,
      cssClass: 'modalLookLikeNativ',
      mode: 'ios',
    });
    return await modal.present();
  }

  filterItems() {
    if (this.searchText.length > 0) {
      this.ports2 = this.ports.filter(item => {
        return item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
      });
    } else {
      this.ports2 = this.ports.slice(0, 50);
    }
  }


  changeSmsData(event) {
    this.shopS.eventForBuis.sendSms = !this.shopS.eventForBuis.sendSms;

    this.shopS.globalEventUpdate(this.shopS.eventForBuis.exportEventAdmin()).then(res => {
      console.log("updated")
    })
  }


  closeModal() {
    this.modalController.dismiss();
  }


  approveUser(data) {
    console.log(data);
    this.shopS.approveUserEvent(data);
    // this.shopS.updateUserStatusToApprove(data).then(() => {
    //   // NOTIFICATION
    //   let notif_data = {
    //     type: 'approve_user_to_event',
    //     users: [data],
    //     meet: {
    //       name: this.shopS.eventForBuis.exportEventAdmin().eventName,
    //       startTimeView: this.shopS.eventForBuis.exportEventAdmin().startTimeView,
    //       meetDate: this.shopS.eventForBuis.exportEventAdmin().meetDate
    //     }
    //   }
    //   this.shopS.send_notif(notif_data);
    //   // REMINDER
    //   this.shopS.prepare_event_reminder(this.shopS.eventForBuis.exportEventAdmin(), data);
    // });

    if (data == "כללי") {
      this.UserSelected = this.shopS.Auser;
    } else {
      this.UserSelected = data;
    }
    // let index = this.userEvent.findIndex(x => x.id === this.UserSelected.uId);
    // if (index == -1) {

    // } else {
    // data.status = "ok";
    // this.shopS.eventForBuis.uId[index].status = "ok";
    // this.shopS.globalEventUpdate(this.shopS.eventForBuis.exportEventAdmin());
    // }
  }

  selectUser(data) {
    console.log(data);

    if (data == "כללי") {
      this.UserSelected = this.shopS.Auser;
    } else {
      this.UserSelected = data;
    }

    let index = this.userEvent.findIndex(x => x.id === data.userData.key);
    if (index == -1) {
      if (this.type == 'event') {
        if (this.shopS.eventForBuis.CatchPeople > (this.shopS.shopData.Setting.Premium.CartridgeMeet - 1)) {
          this.alertExample.aleryByText("הגעת למגבלת המשתתפים לחבילה החינמית של Point");
          return;
        };
        this.userEvent.push(this.UserSelected);
        let index2 = this.ports2.findIndex(x => x.id === data.userData.key);
        this.ports2.splice(index2, 1);
        this.shopS.addUserToEvent(this.shopS.SelectedMeet.event, this.UserSelected.userData);
      } else {
        this.modalController.dismiss(this.UserSelected.userData)
      }
    } else {
      this.alertExample.aleryByText("הלקוח כבר נמצא במפגש")
    }
  }

  removeUser(data) {
    console.log(data);

    if (data == "כללי") {
      this.UserSelected = this.shopS.Auser;
    } else {
      this.UserSelected = data;
    }

    this.UserSelected.userData.status = "removeUser";
    if (this.UserSelected.userData.uId) {
      this.UserSelected.userData.key = this.UserSelected.userData.uId;
    }
    let index = this.userEvent.findIndex(x => x.id === this.UserSelected.userData.key);
    if (index != -1) {
      this.userEvent.splice(index, 1);
      this.ports2.push(this.UserSelected);
    }

    var uidRmove;
    if (this.UserSelected.userData.uId) {
      uidRmove = this.UserSelected.userData.uId;
    } else {
      uidRmove = this.UserSelected.userData.key;
    }

    this.shopS.removeUserFromEvent(this.shopS.SelectedMeet.event, uidRmove);
    // this.notiS.arrange_JSON_Notification_event(this.shopS.eventForBuis.exportEventAdmin(), this.UserSelected.userData, 'remove_user_to_event');
  }

  closeT() {
    this.modalController.dismiss();
  }

  // toggleModaladdClientmodalFLAG(event) {
  //   event.stopPropagation();
  //   this.resetUsers()
  //   // this.popoverController.dismiss();
  //   this.shopS.addClientmodalFLAG = !this.shopS.addClientmodalFLAG;
  //   console.log('toggleModal=======this.shopS.addClientmodalFLAG', this.shopS.addClientmodalFLAG);

  // }

  // resetUsers() {

  //   this.shopS.userUpdated = {
  //     fname: '',
  //     lname: '',
  //     ProfilePic: "",
  //     phone: '',
  //     key: "",
  //     local: "1",

  //   }
  // }

  addNewUser() {
    if (this.shopS.environment.platform == 'web') {
      this.toggleNewUser();
    } else {
      this.newUserContState = 1;
    }
  }

  findeInEventArray(id) {
    let index = this.userEvent.findIndex(x => x.id === id);

    //console.log(index,id)
    if (index == -1) {
      return true;
    } else {
      return false;
    }

  }

  ngOnDestroy() {
    this.usersSub.unsubscribe();
  }

}
