import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/providers/toast-service';
import { ShopService } from 'src/ShopService/shop.service';
import { userService } from 'src/ShopService/user.service';

@Component({
  selector: 'app-wallet-popover',
  templateUrl: './wallet-popover.component.html',
  styleUrls: ['./wallet-popover.component.scss'],
})
export class WalletPopoverComponent implements OnInit {

  constructor(public shopS: ShopService, public us: userService, private toast: ToastService) { }

  ngOnInit() { }

  askExclusive() {
    let text = 'אני רוצה ליד\nתחום: ארנק';

    this.shopS.sendData(this.us.user.fname + " " + this.us.user.lname, this.us.user.phone, this.us.user.email, text, "lead", this.us.user.key).subscribe(respone => {
      console.log('respone: ', respone);
      if (respone) {
        this.toast.showToast2('הבקשה נשלחה בהצלחה!');
        this.shopS.dismissModalController();
      }
    });
  }

}
