import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/providers/toast-service';
import { ShopService } from 'src/ShopService/shop.service';
import { userService } from 'src/ShopService/user.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-exclusive-popover',
  templateUrl: './exclusive-popover.component.html',
  styleUrls: ['./exclusive-popover.component.scss'],
})
export class ExclusivePopoverComponent implements OnInit {

  constructor(
    public ModalController: ModalController,
    public shopS: ShopService, private toast: ToastService, public us: userService
  ) { }

  ngOnInit() { }

  dismissModal() {
    this.ModalController.dismiss();
  }

  askExclusive() {
    let text = 'אני רוצה ליד\nתחום: חבילת אקסקלוסיב';

    this.shopS.sendData(this.us.user.fname + " " + this.us.user.lname, this.us.user.phone, this.us.user.email, text, "lead", this.us.user.key).subscribe(respone => {
      console.log('respone: ', respone);
      if (respone) {
        this.toast.showToast2('הבקשה נשלחה בהצלחה!');
        // this.shopS.choosePackageModal = false;
        this.dismissModal();
      }
    });
  }

}
