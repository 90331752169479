import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/providers/auth-service';
import { LoadingService } from 'src/providers/loading.service';



@Component({
  selector: 'app-somethingwentwrong',
  templateUrl: './somethingwentwrong.component.html',
  styleUrls: ['./somethingwentwrong.component.scss'],
})
export class SomethingwentwrongComponent implements OnInit {

  constructor(public authS: AuthService,public ModalController:ModalController,public LoadingService:LoadingService) { }
imageGIF = 'https://media.giphy.com/media/elVw7yozfiDJMIRvxq/giphy.gif';
  ngOnInit() {


    let arrayFail = [ 'https://media.giphy.com/media/elVw7yozfiDJMIRvxq/giphy.gif', 'https://media.giphy.com/media/IONcI446NLLTq/giphy.gif', 'https://media.giphy.com/media/gw3xkBBCnpvr77Dq/giphy.gif', 'https://media.giphy.com/media/QsbnDuOAAl59C/giphy.gif'];
    this.imageGIF = arrayFail[Math.floor(Math.random() * arrayFail.length)];



  }


  reload_again(){
    // this.authS.logOutForLogin();
    location.reload();
    this.LoadingService.presentforever();
    setTimeout(() => {
      this.LoadingService.dismiss();

        this.ModalController.dismiss();
    }, 4000);
  }


}
