import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caltimePip'
})
export class CaltimePipPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    var x;
    switch (value) {
      case 0:
        x = "סגור";
        break;
      case 7:
        x = "שבוע";
        break;
      case 14:
        x = "שבועיים";
        break;
      case 31:
        x = "חודש";
        break;
      case 62:
        x = "חודשיים";
        break;
      case 93:
        x = "שלושה חודשים ";
        break;

      case 183:
        x = "חצי שנה ";
        break;
      case 361:
        x = "שנה";
        break;

      default:
        break;
    }



    console.log("valueeeee", value)

    return x;
  }

}
