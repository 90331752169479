import { Http } from '@angular/http';
import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-knoknowledge-modal',
  templateUrl: './knoknowledge-modal.component.html',
  styleUrls: ['./knoknowledge-modal.component.scss'],
})
export class KnoknowledgeModalComponent implements OnInit {



  @Input() titleData: string;
  @Input() textData: string;
  middleInitial;

  constructor(public ModalController:ModalController,
    private _http: Http,
    ) { }

  ngOnInit() {}


  jsonKnowlge=[
    {
    "id":"1",
    "title":"תצוגת יומן",
    "subTxt":"בחר כיצד היומן של יוצג",
    "textData":"בחירה בתצוגה יומית תציג לך את היום הנוכחי ואילו תצוגה שבועית תציג לך את כל השבוע מיום ראשון עד שבת, הגדרה זו מיועדת לעסק בלבד"
  }];


  ionViewDidEnter(){


    this.getDataKnowledge("know")
  }

  dismissModal(){
    this.ModalController.dismiss()
  }



  getDataKnowledge(sendPar){
    this._http.post('https://webc.co.il/pointnot/Knowledge.php',sendPar).subscribe(respone=>{
    let data:any=respone; 
 
    this.jsonKnowlge=JSON.parse(data["_body"]);
    console.log('knoww: ',this.jsonKnowlge);
    let newDefault = this.jsonKnowlge.find(o => o.title === this.titleData);

    console.log(newDefault);
    this.textData=newDefault.textData;
    this.middleInitial=newDefault.subTxt;
    
    }); 
  }

  // jsonKnowlge=[
  //   {
  //   "id":"1",
  //   "title":"תצוגת יומן",
  //   "subTxt":"בחר כיצד היומן של יוצג",
  //   "textData":"בחירה בתצוגה יומית תציג לך את היום הנוכחי ואילו תצוגה שבועית תציג לך את כל השבוע מיום ראשון עד שבת, הגדרה זו מיועדת לעסק בלבד"
  // },
  // {
  //   "id":"2",
  //   "title":"מרווח בין תורים",
  //   "subTxt":"בחר את תדירות התורים ביומן שלך",
  //   "textData":"הגדרה זו מאפשרת לך לשלוט בחשיפת השעות הפנויות ללקוח, למשל קביעת תור לפי שעה עגולה בלבד."
  // }, {
  //   "id":"3",
  //   "title":"היומן פתוח ל",
  //   "subTxt":"המועד האחרון לקבלת תור מרגע זה.",
  //   "textData":"כאן תוכלו להגדיר את המועד האחרון לקבלת תור מרגע זה. למשל יומן פתוח לשבועיים יאפשר ללקוחות שלכם להזמין תור עד ל14 יום מעכשיו."
  // }, {
  //   "id":"4",
  //   "title":"לקוחות יוכלו להזמין",
  //   "subTxt":"המועד הקרוב ביותר להזמנה",
  //   "textData":"הגדרה זו מגבילה את היכולת של הלקוח להזמין תור בתקופה שהוגדרה, למשל במידה והגדרתם ממחר, לקוחות לא יוכלו להזמין מהיום להיום"
  // }, {
  //   "id":"5",
  //   "title":"שמירת היסטוריה",
  //   "subTxt":"האפשרות לשמור את פגישות היומן",
  //   "textData":"במידה ואין צורך בתיעוד הפגישות תוכלו לבטל שמירת היסטוריה, שימו לב במידה ושמרתם היסטוריה ולחצתם על הכפתור, היסטוריית הפגישות תמחק."
  // }, {
  //   "id":"6",
  //   "title":"יומן מחולק לפי",
  //   "subTxt":"תצוגת היומן לפי דקות",
  //   "textData":"הגדרה זו משפיעה על תצוגת היומן שלכם, החליטו כיצד לצפות במועדי התורים, הגדרה זו אינה חלה על הלקוח."
  // }, {
  //   "id":"7",
  //   "title":"קורונה הצהרת בריאות",
  //   "subTxt":"שליטה בצורך בהצהרת בריאות",
  //   "textData":"הפעילו את הגדרה זו בכדי לאפשר ללקוחות להצהיר על בריאותם בהתאם לתקנות משרד הבריאות, לאחר ההפעלה תוכלו לצפות בתיעוד ההצהרות בהגדרות העסק."
  // }, {
  //   "id":"8",
  //   "title":"תזכורות",
  //   "subTxt":"הפעלת תזכורות עבור תורים",
  //   "textData":"באמצעות הגדרה זו תאפשרו למערכת לשלוח תזכורות עבור תורים שנקבעו ללקוחות."
  // }, {
  //   "id":"9",
  //   "title":"מלא יומן",
  //   "subTxt":"שינוי שיטת זימון התורים",
  //   "textData":"הגדרה זו חושפת ללקוח רק את השעה הפנויה הבאה ביומן, כך שהיומן תמיד יישאר מלא. למשל יומן שפנוי משעה 13:00 עד סוף היום יאפשר קביעת תור לשעה 13:00 בלבד"
  // }, {
  //   "id":"10",
  //   "title":"הצג קוד ללקוחות",
  //   "subTxt":"הצגת קוד העסק בפרופיל הלקוח",
  //   "textData":"באמצעות הגדרה זאת תחשפו את קוד העסק שלכם ללקוחות שלכם, כך שיוכלו לשתף לחבריהם."
  // }, {
  //   "id":"11",
  //   "title":"אפשר חיפוש שם עסק",
  //   "subTxt":"היכולת לשלוט במאגר העסקים",
  //   "textData":"הגדרה זו מאפשרת לכם להחליט האם להיות חשופים לחיפוש עסקים, לדוגמא אם ביטלתם את האפשרות, יוכלו להגיע לעסק רק על ידי קוד העסק"
  // }
  // ]






}

