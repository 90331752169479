import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigService } from "../../app/config.service";

@Component({
  selector: 'changePass',
  templateUrl: 'setPassword.page.html',
  styleUrls: ['./setPassword.page.scss']
})

export class SetPasswordPage implements OnInit {

  setPasswordForm: FormGroup;

  constructor(private fb: FormBuilder,
    public configS: ConfigService,
              private router: Router) {}

  ngOnInit() {
    this.createResPassForm();
  }

  createResPassForm() {
    this.setPasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      repeatPassword: ['']
    }, {validator: this.checkPasswords });
  }

  setPassword() {
    console.log(this.setPasswordForm.value);

  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.controls.password.value;
    const confirmPass = group.controls.repeatPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }




}
