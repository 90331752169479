import { Component, OnInit } from '@angular/core';
import { ShopService } from 'src/ShopService/shop.service';

@Component({
  selector: 'app-swichmodal',
  templateUrl: './swichmodal.component.html',
  styleUrls: ['./swichmodal.component.scss'],
})
export class SwichmodalComponent implements OnInit {

  constructor(public shopS: ShopService) { }

  ngOnInit() { }

}
