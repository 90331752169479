import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';


@Component({
  selector: 'app-holiday-list',
  templateUrl: './holiday-list.component.html',
  styleUrls: ['./holiday-list.component.scss'],
})
export class HolidayListComponent implements OnInit {
  holiday_loading = true;

  constructor(
    public shopS: ShopService,
    private router: Router,
    private modalController: ModalController,
    private alertController: AlertController) { }

  ngOnInit() {

    console.log('getting holidays...');
    this.shopS.Holidays();

    setTimeout(() => {
      this.holiday_loading = false;
      console.log('holidays set? ', this.shopS.holidays);
    }, 1500);

  }

  showAlert() {
    var textT = '<ul class="aaaa" style="">';
    var text = '';
    this.shopS.holidays.forEach((item, index) => {
      if (item.change && !item.SavedChange && item.notWorking) {
        if (item.closeallday == 'allday') {
          text += '<li>' + item.summary + " - העסק סגור" + '</li>';
        } else {
          text += '<li>' + item.summary + " - העסק פתוח עד השעה " + item.startWorkDay + '</li>';
        }
      }
      if (index == this.shopS.holidays.length - 1) {
        if (text == '') {
          textT += 'אין שינויים!' + '</ul>';
        } else {
          console.log('textT', textT, text);
          textT += text + '</ul>';
          this.alertModal(textT);
        }
      }
    })
  }

  async alertModal(textT) {

    const alert = await this.alertController.create({
      header: 'אישור שינויים',
      message: textT,
      buttons: [
        {
          text: 'ביטול וחזרה לעריכה',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cancel Cancel: Cancel');
            return false;
          }
        }, {
          text: 'אישור ושמירה',
          handler: () => {

            console.log('Okay: Okay');
            //confirm order 
            this.setHoliday();

          }
        }
      ]
    });
    await alert.present();

  }


  closeModal(type?) {
    this.modalController.dismiss(type)
  }

  switchDay(date) {
    let day = {
      cal: new Date(date)
    }
    this.shopS.switchDay(day);
    this.closeModal('switchDay')
    this.router.navigate(['tabsAdmin/calendar'], { replaceUrl: true });

  }


  no_working() {
  let index = this.shopS.holidays.findIndex(day => day.notWorking == true &&  !day.SavedChange);  
  if(index == -1){
   return true
  }else{
    return false
  }
  }



  changeEvent(index, data, type) {
    console.log('index', index);
    console.log('data', data);
    console.log('type', type);

  }



  updateWorkDay(index) {
    this.shopS.holidays[index]['change'] = true;
    if (!this.shopS.holidays[index]['closeallday']) {
      this.shopS.holidays[index]['closeallday'] = 'allday';
    }
    console.log(index, this.shopS.holidays[index]);
  }

  working(index, value) {
    this.shopS.holidays[index]['change'] = true;
    this.shopS.holidays[index]['startWorkDay'] = value;

    console.log(index, this.shopS.holidays[index]);
  }

  zoomlink = "";
  sendSms = true;
  moreInfo = "";

  setHoliday() {
    this.shopS.holidays.forEach((item, index) => {
      if (item.change && !item.SavedChange && item.notWorking) {
        item.SavedChange = true;
        this.shopS.setHolidays(index, item);

        let meetTypeObj = {
          "approve": false,
          "color": "#000272",
          "flow": false,
          "info": "987654",
          "ischecked": true,
          "key": "1",
          "meetTime": "",
          "name": "",
          "price": 0,
          "reminder": 30,
          "active": true
        }
        console.log(index, item, 'meetTypeObj: ', meetTypeObj);
        // console.log('item.start.date:', item.start.date, 'item.startWorkDay: ', item.startWorkDay);
        // console.log('item.start.date:', item.start.date, 'item.endWorkDay: ', item.endWorkDay);
        let dateString = (item.start.date).concat("T").concat(item.startWorkDay);
        let start_time = new Date(dateString);

        let end_time = new Date((item.start.date).concat("T").concat(item.endWorkDay));
        console.log('event start time:', start_time, 'end time: ', end_time);


        meetTypeObj.meetTime = (Math.floor(((end_time.valueOf() - start_time.valueOf()) / 1000) / 60)).toString();
        console.log('meetTypeObj.meetTime: ', (Math.floor(((end_time.valueOf() - start_time.valueOf()) / 1000) / 60)).toString());

        if (meetTypeObj.meetTime != 'NaN') {
          meetTypeObj.name = item.summary;

          console.log('meetTypeObj.name: ', item.summary);
          console.log('meetTypeObj:', index, meetTypeObj);

          this.shopS.meetForBuis.importMeetType(meetTypeObj);
          this.shopS.meetForBuis.importZoomlink(this.zoomlink)
          this.shopS.meetForBuis.importSendSms(this.sendSms);
          this.shopS.meetForBuis.importStartDateAndHoureview(start_time);

          this.shopS.meetForBuis.importBarber(this.shopS.shopData.Setting.BarberID, this.shopS.shopData.Setting.name);
          this.shopS.meetForBuis.importUserId('localUserAdmin');//this.shopS.currentMeet.user.key
          this.shopS.meetForBuis.importMoreInfo(this.moreInfo);
          this.shopS.meetForBuis.defineMeetTime(meetTypeObj.meetTime);//this.valueTime 
          this.shopS.meetForBuis.CreateNewMeet();
          this.shopS.meetForBuis.importPrice();
          this.shopS.meetForBuis.defineFlow(meetTypeObj.flow);
          this.shopS.meetForBuis.defineStatus(1);
          this.shopS.meetForBuis.showClass();

          this.shopS.setMeet(true);
        }
      }
    });

  }
}
