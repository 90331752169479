import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/providers/loading.service';
import { ModalController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';
import { InvoiceServiceService } from '../invoice-service/invoice-service.service';

@Component({
  selector: 'app-setup-invoice-supplier',
  templateUrl: './setup-invoice-supplier.component.html',
  styleUrls: ['./setup-invoice-supplier.component.scss'],
})
export class SetupInvoiceSupplierComponent implements OnInit {

  constructor(public invoiceS:InvoiceServiceService,public shopS:ShopService,private LoadingService:LoadingService,public ModalController:ModalController) { }

  setup_steps = [
    {
      active: true,
      prece: '10%',
      opacity: 1,
      leftIcon: 'fa-chevron-left',
      stage: 0,
      txt: 'בואו נתחיל'
    },
    {
      active: false,
      prece: '35%',
      opacity: 0.5,
      leftIcon: 'fa-question',
      stage: 1,
      txt: 'מה הספק חשבוניות היום?'
    },
    {
      active: false,
      prece: '50%',
      opacity: 1,
      leftIcon: 'fa-chevron-left',
      stage: 2,
      txt: 'לקישור הספק'
    },
    {
      active: false,
      prece: '75%',
      opacity: 0.5,
      leftIcon: 'fa-question',
      stage: 3,
      txt: 'פרטי התחברות'
    },
    {
      active: false,
      prece: '100%',
      opacity: 1,
      leftIcon: 'fa-chevron-left',
      stage: 4,
      txt: 'חיבור לחשבון שלי'
    },
    {
      active: false,
      prece: '100%',
      opacity: 1,
      leftIcon: 'fa-chevron-left',
      stage: -1,
      txt: ''
    },
  ]

  currentStep = 0;
  next_step(){
    if( this.currentStep == 4){
      this.check_auth();
    }
    if(
      this.currentStep == 0
      ||
      this.currentStep == 2
      ||
      this.currentStep == 4
      ){
      this.currentStep++;
      this.setup_steps[this.currentStep].active = true;
    }

  }

  previous_step(){
    this.setup_steps[this.currentStep].active = false;
    this.currentStep--;

  }
  gif_status = '';


  current_invoice_supplier:any = {};

  select_invoice_supplier(type){
   this.current_invoice_supplier = type;
   this.currentStep = 2;
  }




  auth = {
    id: '010554cd-21cd-49cd-9f6b-56965c82b84b',
    secret: 'zOI-UEdjI5DZVAIpm3ShaQ',
    username:'220790',
    password:'Webc@2018',
    cid:'testnow'
  }
  auth_changed(event,type){
    this.auth[type] = event.target.value;
    this.currentStep = 4;

  } 

  check_status = {
    status:'try',
    txt: 'מנסה להתחבר'  
  };
  message = '';
  check_auth(){
    this.check_status = {
      status:'try',
      txt: 'מנסה להתחבר'  
    };
    this.gif_status='';
    this.invoiceS.try_auth(this.current_invoice_supplier,this.auth,cb =>{
      console.log(cb);
      if(cb.status){
        this.gif_status = this.shopS.celebrating_gif_array[Math.floor(Math.random() * this.shopS.celebrating_gif_array.length)]
        this.check_status = {
          status : 'succses',
          txt: 'התחברנו! '
        }
   
        this.invoiceS.set_invoice_supplier(this.current_invoice_supplier.name);
    
      }else{ 
        this.gif_status = this.shopS.celebrating_gif_array[Math.floor(Math.random() * this.shopS.celebrating_gif_array.length)]

        this.check_status = {
          status : 'failed',
          txt: cb.message
        }
      }      


    });
  }

  ngOnInit() {}

  dismissModal() {
    this.ModalController.dismiss();
  }


}
