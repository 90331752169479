import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-img-preview',
  templateUrl: './img-preview.component.html',
  styleUrls: ['./img-preview.component.scss'],
})
export class ImgPreviewComponent implements OnInit {
  @Input() img: string;

  constructor(private ModalController:ModalController) { }

  ngOnInit() {}

  dismissModal(){
    this.ModalController.dismiss()
  }



}
