import { AlertExample } from './../../providers/alert.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TabsPage } from '../tabs/tabs';
import { Http } from '@angular/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { registerU } from '../register/registerUser';
// import {choseShop} from '../chooseShop/chooseShop';
import { userD } from '../../app/UserDClass';
import { userService } from '../../ShopService/user.service';
import { AuthService } from '../../providers/auth-service';
import { ShopService } from '../../ShopService/shop.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import * as firebase from 'firebase/app';
import { ConfigService } from "../../app/config.service";
import { LoadingController, MenuController, Platform } from '@ionic/angular';
import { AlertController } from '@ionic/angular';



import { LocalStorageService } from '../../providers/local-storage.service';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';

// import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Router } from '@angular/router';

import { KeyValueDiffers, KeyValueDiffer } from '@angular/core';
import { LoadingService } from 'src/providers/loading.service';

@Component({
  selector: 'login',
  templateUrl: 'login.html',
  styleUrls: ['./login.scss']

})
export class loginPage implements OnInit, OnDestroy {
  userData: userD;
  useData: any;
  myEmail;
  userSub;
  shopSub;
  loginForm: FormGroup;

  UserShop;
  email = '';
  password = '';
  // chP = choseShop;
  uid: any;
  testCred: any;

  regPage = registerU;
  differ: KeyValueDiffer<string, any>;

  constructor(private authService: AuthService,
    public shopS: ShopService,
    public facebook: Facebook,
    private loading: LoadingService,
    private alertExample: AlertExample,
    private alertController: AlertController,
    private afAuth: AngularFireAuth,
    private fb: FormBuilder,
    private afDb: AngularFireDatabase,
    public us: userService,
    private MenuController: MenuController,
    private router: Router,
    private googlePlus: GooglePlus,
    private differs: KeyValueDiffers,
    private Platform: Platform,
    public configS: ConfigService,
    public loadingController: LoadingController,
    private localStorageService: LocalStorageService,

    private alertCtrl: AlertController) {
    this.differ = this.differs.find({}).create();
  }
  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'בטעינה',
      duration: 1000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();

    console.log('Loading dismissed!');
  }

  async presentLoadingWithOptions() {
    const loading = await this.loadingController.create({
      spinner: "dots",
      duration: 150,
      message: '',
      showBackdrop: false,
      translucent: true,

      cssClass: 'custom-class custom-loading'
    });
    return await loading.present();
  }

  ionViewWillEnter() {
    this.MenuController.enable(false);

  }
  adminLogin() {
    this.router.navigate(['loginadmin']);
  }



  ngOnInit() {
    this.generateLoginForm();

    // document.addEventListener('backbutton', () => {

    //   this.goBack();


    //     }, false);

  }

  generateLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.maxLength(60)])],
      password: ['', Validators.compose([Validators.required, Validators.maxLength(25), Validators.minLength(6)])]
    });
  }





  goToRegisterPage() {
    this.router.navigate(['signUp'], { replaceUrl: true });
  }

  // facebookLogin() {
  // // this.navCtrl.push(TabsPage);
  // this.afAuth.auth.signInWithPopup({
  //   provider: new firebase.auth.FacebookAuthProvider(),
  //   method: AuthMethods.Popup,
  //   scope: ['public_profile', 'email', 'user_friends']
  // }).then((authState: any) => {
  //   this.afDb.object('/Users/' + authState.uid).update({
  //     accessToken: authState.facebook.accessToken
  //   })
  // })
  // }
  goBack() {

    this.router.navigate(['socialLogin'], { replaceUrl: true });
  }

  ToLogin() {
    // let loading=this.presentLoadingWithOptions();
    this.loading.present();
    console.log("go")

    this.email = this.loginForm.value.email;
    this.password = this.loginForm.value.password;
    if ((this.email) && (this.password)) {

      this.authService.login(this.email, this.password)
        .then(authState => {
          console.log("yes login", authState.user.uid)


          this.shopS.log(authState.user.uid, 'login_email');

          this.UserShop = this.afDb.object('/Users/' + authState.user.uid).snapshotChanges()
            .subscribe((data: any) => {


              this.uid = authState.user.uid;



              console.log('login power', data.payload.val());
              let m = data.payload.val();
              console.log(m);
              // this.us.saveUserData(m, data.key);
              // this.collectAndSave(m);
              // this.checkBarberId(m);
              // this.shopS.getOneSignalID(this.uid);

              // this.RoutingPag(m);
              // this.MenuController.enable(true);
              this.UserShop.unsubscribe();
              console.log(this.shopS.shopData)

            });

        }).catch(async (error) => {
          this.loading.dismiss();
          console.log(error, "error")
          this.shopS.log('', 'login_failed');

          if (error.code.includes('user-not-found') || error.code.includes('wrong-password') || error.code.includes('invalid-email')) {

            const alert = await this.alertCtrl.create({
              header: 'התראה',
              message: 'האימייל או הסיסמה לא נכונים',
              buttons: ['סגור']
            });

            await alert.present();
          } else if (error.code.includes('too-many-requests')) {
            const alert = await this.alertCtrl.create({
              header: 'התראה',
              message: 'יותר מידי נסיונות התחברות <br> נסי/ה שוב מאוחר יותר',
              buttons: ['סגור']
            });

            await alert.present();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'התראה',
              message: 'הראה שגיאה בהתחברות צור קשר עם עם point',
              buttons: ['סגור']
            });

            await alert.present();
          }
        });
    }
  }


  forgetPassword() {

    var auth = firebase.auth();
    var emailAddress = this.loginForm.value.email;
    var alertexample = this.alertExample;
    firebase.auth().languageCode = 'he';


    if (!emailAddress) {

      alertexample.aleryByText("לא הכנסת אימייל");
      return;
    }


    auth.sendPasswordResetEmail(emailAddress).then(function () {
      // Email sent.
      // alertexample.aleryByText("איפוס סיסמה נשלח אלייך במייל")
      alertexample.aleryByTextgood("איפוס סיסמה נשלח אלייך במייל")
      console.log("password sent")
    }).catch(function (error) {
      // An error happened.
      alertexample.aleryByText("הכתובת אינה נכונה/ לא קיימת")

      console.log("password erroe", error)

    });
  }

  // loginFacebook(): Promise<any> {
  //   return this.facebook.login(['email']).then( (result) => {
  //   //  console.log(result);
  //     const facebookCredential = firebase.auth.FacebookAuthProvider.credential(result.authResponse.accessToken);
  //     this.testCred = facebookCredential;
  //  //   console.log(facebookCredential);
  //     const accessToken = result.authResponse.accessToken;
  //     firebase.auth().signInWithCredential(facebookCredential).then((success) => {
  //  //     console.log(success.user.uid);
  //       this.uid = success.user.uid;
  //       const userData = this.configUserData(success, accessToken);
  //       this.afDb.object('/Users/' +  this.uid).update({
  //         ...userData
  //       });
  //       this.afDb.object('/Users/' +  this.uid).snapshotChanges().subscribe((res) => {
  //  //       console.log(res.payload.val());
  //         const data = res.payload.val();
  //         this.collectAndSave(data);
  //         this.us.saveUserData(data, res.key);
  //         this.checkBarberId(data);
  //         this.RoutingPag(data);
  //         this.shopS.getOneSignalID(this.uid);

  //       });

  //     }, (error) => {
  //   //    console.log(error);
  //       firebase.auth().fetchSignInMethodsForEmail(error.email).then((data) => {
  //  //       console.log(data);
  //       }, (err) => {
  //         console.log(err);
  //       });
  //       this.redirectToGoogleAuth();

  //     });
  //   }, (error) => {
  //     alert('Connection error');
  //     console.log(error);
  //   });
  // }

  async showAlert(email) {
    const passAlert = await this.alertController.create({
      header: 'Password',
      message: 'Insert your password: ',
      inputs: [
        {
          name: 'password',
          type: 'password',
          placeholder: 'Write your password again'
        }
      ],
      buttons: [{
        text: 'cancel',
        role: 'cancel',
        handler: (res) => {
          console.log(res);
        }
      },
      {
        text: 'confirm',
        role: 'confirm',
        handler: (res) => {
          this.loginAfterMerge(email, res.password);
        }
      }]
    });
    await passAlert.present();
  }

  loginAfterMerge(email, password) {
    // console.log(password);
    this.email = email;
    this.password = password;

  }


  // redirectToGoogleAuth() {
  //   alert('Already signed via Google. Redirecting...');
  //   this.googleLogin();

  // }

  // redirectToFacebookAuth() {
  //   alert('Already signed via Facebook. Redirecting...');
  //   this.loginFacebook();

  // }

  // googleLogin(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.googlePlus.login({
  //       webClientId: '474725772867-hrh47a1hpvsgai4u7sa8ddpav5gdkk1f.apps.googleusercontent.com',
  //       offline: true
  //     }).then( (result) => {
  //    //   console.log(result);
  //       const googleCredential = firebase.auth.GoogleAuthProvider.credential(result.idToken);
  //       const accessToken = result.accessToken;
  //       firebase.auth().signInWithCredential(googleCredential).then((success) => {
  //    //     console.log(success);
  //         this.uid = success.user.uid;
  //         const userData = this.configUserData(success, accessToken);
  //         this.afDb.object('/Users/' +  this.uid).update({
  //           ...userData
  //         });
  //         this.afDb.object('/Users/' +  this.uid).snapshotChanges().subscribe((res) => {
  //     //      console.log(res.payload.val());
  //           const data = res.payload.val();
  //           this.collectAndSave(data);
  //           this.us.saveUserData(data, res.key);
  //           this.checkBarberId(data);
  //           this.RoutingPag(data);
  //           this.shopS.getOneSignalID(this.uid);
  //         });
  //       }, (error) => {
  //         this.redirectToFacebookAuth();
  //       });
  //     }, (error) => {
  //       alert('Connection error');
  //       console.log(error);
  //     } );
  //   });
  // }


  configUserData(credential, aToken) {
    //  console.log(credential);
    const user = {
      accessToken: aToken,
      name: credential.user.displayName,
      email: credential.user.email,
      phone: credential.user.phoneNumber,
      image: credential.user.photoURL
    };
    return user;
  }

  // collectAndSave(data) {
  //   let finalData = {
  //     BarberID: '',
  //     uid: ''
  //   };
  //   for (let key in data) {
  //     if (key === 'BarberID') {
  //       finalData.BarberID = data[key];
  //     }
  //   }
  //   // console.log('data',data);
  //   finalData.uid = this.uid;
  //   console.log("finalData finalData finalData finalData finalData finalData finalData", finalData);
  //   this.localStorageService.saveToLocalStorage('user', finalData);
  // }

  subscribeToUser: any;
  loginFlag = 0;
  checkBarberId(data: any) {
    // console.log(data);
    if (data.BarberID) {



      //   console.log(data.BarberID);
      this.subscribeToUser = this.shopS.getShopDetils(data.BarberID).valueChanges().subscribe(
        (shopD: any) => {


          //console.log(shopD.$key);
          //        console.log(shopD, 'shopD');
          console.log("5555");

          this.shopS.shopData = shopD;
          this.shopS.shopData['BarberId'] = data.BarberID;
          this.shopS.saveShopDetils(shopD);
          this.loginFlag = 1;
          // localStorage.setItem('BarberID', data.BarberID);
          // localStorage.setItem('type', 'user');
          this.shopS.changeButtonState(0);
          this.subscribeToUser.unsubscribe();
        }, (error) => {
          console.log(error);
        });
    } else {
      this.loginFlag = 2;

      //  this.router.navigate(['chooseShop'], {replaceUrl: true});
    }
  }


  ngDoCheck() {
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        // console.log('item changed', item);

        if (item.key == 'loginFlag' && item.currentValue == 1 && item.previousValue == 0) {
          // console.log('if 1', item);

          this.router.navigate(['tabs/home'], { replaceUrl: true });
        }

        if (item.key == 'loginFlag' && item.currentValue == 2) {
          // console.log('if 2', item);

          this.router.navigate(['addBusiness'], { replaceUrl: true });
        }

      });
    }
  }

  RoutingPag(ad) {


    // console.log(ad);
    if (ad.BarberID) {
      //   console.log('if ad.BarberID',ad.BarberID);

    } else {
      //  console.log('else ad.BarberID',ad.BarberID);

      this.router.navigate(['addBusiness'], { replaceUrl: true });
    }
  }







  goToSignUpPage() {
    this.router.navigate(['signUp']);
  }

  goToForgotPass() {

    this.forgetPassword();

    this.router.navigate(['askSms']);
  }

  unsubscribeAll() {
    // if (this.UserShop) {
    //   this.UserShop.unsubscribe();
    // }
    // if (this.shopSub) {
    //   this.shopSub.unsubscribe();
    // }
  }


  ngOnDestroy() {
    this.unsubscribeAll();
    // this.UserShop.unsubscribe();
    // // this.userSub.unsubscribe();
    // this.shopSub.unsubscribe();


  }

}









