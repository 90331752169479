import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/providers/toast-service';
import { ShopService } from 'src/ShopService/shop.service';
import { userService } from 'src/ShopService/user.service';
// import { ItemReorderEventDetail } from '@ionic/angular';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { ModalController } from '@ionic/angular';



@Component({
  selector: 'app-lead-modal',
  templateUrl: './lead-modal.component.html',
  styleUrls: ['./lead-modal.component.scss'],
})
export class LeadModalComponent implements OnInit {

  leads = [];

  options2: AnimationOptions = {
    path: '../assets/lottie/4.json',
  };

  constructor(
    public shopS: ShopService, 
    private us: userService,
    public toast: ToastService,
    public modalController: ModalController,
    ) { }
  closeModalnodismiss() {
    this.modalController.dismiss();

  }

  ngOnInit() {
    console.log(this.shopS.configDATA);

    this.leads = this.shopS.json2array(this.shopS.configDATA.vendor_requests);
    console.log(this.leads);
    this.leads.forEach(lead => {
      lead.clicked = false;
    });
  }

  async getLead(lead) {
    if (!lead.clicked) {
      let text = 'אני רוצה ליד\nתחום: ' + lead.name;

      this.shopS.sendData(this.us.user.fname + " " + this.us.user.lname, this.us.user.phone, this.us.user.email, text, "lead", this.us.user.key).subscribe(respone => {
        console.log('respone: ', respone);
        if (respone.status == 200) {
          this.toast.showToast2("הבקשה נשלחה בהצלחה!");
          lead.clicked = true;
          this.shopS.setVendorRequest(lead.id).then(() => {
          });
        } else {
          this.toast.showTostDanger('אירעה שגיאה');
        }
      });
    }
  }

  handleReorder(ev) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    ev.detail.complete();
  }

}
