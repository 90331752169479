import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InvoiceTemplateService {

  constructor() { }




getTemplate(data){

  switch (data.settings.template) {
    case '0':
      console.log('set_template_0',this.set_template_0(data))
      return  this.set_template_0(data); 
    break;
    
    case '1':
      console.log('set_template_1',this.set_template_1(data))
    return  this.set_template_1(data);
    break;
  
    default:
    break;
  }


 // return this.invoice_template[data.settings.template];
}







toRtl(str){
 //return str.replace(" " , " "); //replace space with nbspace char
 let st = str.split(" ").reverse().join("  "); 
 console.log('st',st)
 return st
}







////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////





////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////





////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////







set_template_1(data){
  console.log('data',data);
  var self = this;
  let template:any =  {
  footer: function(currentPage, pageCount) {
    return {
        margin:10,
        columns: [
          { text: self.toRtl(data.document_counter.name +' '+ data.document_counter.counter + ' עמוד ' + currentPage.toString() + ' מתוך ' + pageCount), style: 'documentFooterLeft' },
          { text: '', style: 'documentFooterCenter' },
          { text: self.toRtl('הופק באמצעות invoice point'), style: 'documentFooterRight' }
        ]
    };

},
   content: [
       // Header
       {
           columns: [
               {
                     image: data.settings.logo,
                      width: 150
               },
                   
               [
                   {
                       text: this.toRtl(data.document_counter.name), 
                       style: 'invoiceTitle',
                       width: '*'
                   },
                   {
                     stack: [
                          {
                              columns: [
                                   {
                                    text:data.document_counter.counter,
                                       style:'invoiceSubTitle',
                                       width: '*'
                                       
                                   }, 
                                   {
                                    text: this.toRtl(data.document_counter.name), 
                                       style:'invoiceSubValue',
                                       width: 100
                                       
                                   }
                                   ]
                          },
                          {
                              columns: [
                                  {
                                    text:data.date.date_of_issue,
                                      style:'invoiceSubTitle',
                                      width: '*'
                                  }, 
                                  {
                                    text:'תאריך',
                                      style:'invoiceSubValue',
                                      width: 100
                                  }
                                  ]
                          },
                          {
                              columns: [
                                  {
                                    text:data.date.date_to_pay,

                                      style:'invoiceSubTitle',
                                      width: '*'
                                  }, 
                                  {
                                    text: this.toRtl('לתשלום עד'),

                                      style:'invoiceSubValue',
                                      width: 100
                                  }
                                  ]
                          },
                      ]
                   }
               ],
           ],
       },
       // Billing Headers
       {
           columns: [
               {
                   text: '',
                   style:'invoiceBillingDetails',
                   
               },
               {
                   text: 'לכבוד:',
                   style:'invoiceBillingDetails',
                   
               },
           ]
       },
       // Billing Details
       {
           columns: [
               {
                   text: this.toRtl(data.settings.business_name),
                   style: 'invoiceBillingTitle'
               },
               {
                   text: this.toRtl(data.client.fname + ' ' + data.client.lname),
                   style: 'invoiceBillingTitle'
               },
           ]
       },
       // Billing Address Title
       {
           columns: [
               {
                   text: data.settings.business_number,
                   style: 'invoiceBillingAddress'
               },
               {
                   text: data.client.phone,
                   style: 'invoiceBillingAddress'
               },
           ]
       },
       // Billing Address
       {
        columns: [
            {
                text: this.toRtl(data.settings.business_address),
                style: 'invoiceBillingAddress'
            },
            {
                text: data.client.address,
                style: 'invoiceBillingAddress'
            },
        ]
    },
           // Billing email
           {
            columns: [
                {
                    text: data.settings.business_email,
                    style: 'invoiceBillingAddress'
                },
                {
                    text: data.client.email,
                    style: 'invoiceBillingAddress'
                },
            ]
        },
                   // Billing email

        {
          columns: [
              {
                  text: data.settings.business_phone,
                  style: 'invoiceBillingAddress'
              },
              {
                  text: '',
                  style: 'invoiceBillingAddress'
              },
          ]
      },
         // Line breaks
       '\n\n',
       // Items
         {
           table: {
             // headers are automatically repeated if the table spans over multiple pages
             // you can declare how many rows should be treated as headers
             headerRows: 1,
             widths: [ 80, 40, 'auto', '*' ],
     
             body: [
               // Table Header
               [ 
                {
                  text: 'סה״כ',
                  style: [ 'itemsHeader', 'center']
              },
                 
                  
                   {
                       text: 'כמות',
                       style: [ 'itemsHeader', 'center']
                   }, 
                   {
                    text: 'מחיר',
                    style: [ 'itemsHeader', 'center']
                }, 
                   {
                    text: 'פירוט',
                    style: 'itemsHeader'
                },
                  
               ],
               // Items
               // Item 1
             
               
               // END Items
             ]
           }, // table
         //  layout: 'lightHorizontalLines'
         },
      // TOTAL
         {
           table: {
             // headers are automatically repeated if the table spans over multiple pages
             // you can declare how many rows should be treated as headers
             headerRows: 0,
             widths: [ 85,'*'],
     
             body: [
               // Total
               [ 
                { 
                  text:data.currency +' '+data.total_price,
                  style:'itemsFooterSubValue'
              },
                   {
                       text:'סה״כ',
                       style:'itemsFooterSubTitle'
                   }
                  
               ],
               [ 
                   
                   {
                       text: data.currency +' '+data.total_vat,
                       style:'itemsFooterSubValue'
                   },
                   {
                    text:'מע״מ',
                    style:'itemsFooterSubTitle'
                }
               ],
               [ 
                   
                   {
                       text: data.currency +' '+data.total_amount,
                       style:'itemsFooterTotalValue'
                   },
                   {
                    text:this.toRtl('סה״כ לתשלום'),
                    style:'itemsFooterTotalTitle'
                }
               ],
             ]
           }, // table
           layout: 'lightHorizontalLines'
         },
       // Signature
       {
           columns: [
               {
                image: data.settings.signature,
                width: 150

               },
              //  {
              //      stack: [
              //          { 
              //              text: '_________________________________',
              //              style:'signaturePlaceholder'
              //          },
              //          { 
              //              text: 'Your Name',
              //              style:'signatureName'
                           
              //          },
              //          { 
              //              text: 'Your job title',
              //              style:'signatureJobTitle'
                           
              //          }
              //          ],
              //     width: 180
              //  },
           ]
       },
         { 
             text: 'הערות',
             style:'notesTitle'
         },
         { 
             text: this.toRtl(data.notes),
             style:'notesText'
         }
   ],
   styles: {
       // Document Header
       documentHeaderLeft: {
           fontSize: 10,
           margin: [5,5,5,5],
           alignment:'left'
       },
       documentHeaderCenter: {
           fontSize: 10,
           margin: [5,5,5,5],
           alignment:'center'
       },
       documentHeaderRight: {
           fontSize: 10,
           margin: [5,5,5,5],
           alignment:'right'
       },
       // Document Footer
       documentFooterLeft: {
           fontSize: 10,
           margin: [5,5,5,5],
           alignment:'left'
       },
       documentFooterCenter: {
           fontSize: 10,
           margin: [5,5,5,5],
           alignment:'center'
       },
       documentFooterRight: {
           fontSize: 10,
           margin: [5,5,5,5],
           alignment:'right'
       },
       // Invoice Title
     invoiceTitle: {
       fontSize: 22,
       bold: true,
       alignment:'right',
       margin:[0,0,0,15]
     },
     // Invoice Details
     invoiceSubTitle: {
       fontSize: 12,
       alignment:'right'
     },
     invoiceSubValue: {
       fontSize: 12,
       alignment:'right'
     },
     // Billing Headers
     invoiceBillingTitle: {
       fontSize: 14,
       bold: true,
       alignment:'right',
       margin:[0,20,0,5],
     },
     // Billing Details
     invoiceBillingDetails: {
       alignment:'right'
  
     },
     invoiceBillingAddressTitle: {
         margin: [0,7,0,3],
         bold: true
     },
     invoiceBillingAddress: {
         
     },
     // Items Header
     itemsHeader: {
      margin: [0,5,0,5],
      bold: true
  },
  itemsRight: {
    margin: [0,5,0,5],
    alignment: 'right',
  },
     // Item Title
     itemTitle: {
         bold: true,
     },
     itemSubTitle: {
             italics: true,
             fontSize: 11
     },
     itemNumber: {
         margin: [0,5,0,5],
         alignment: 'center',
     },
     itemTotal: {
         margin: [0,5,0,5],
         bold: true,
         alignment: 'center',
     },
  
     // Items Footer (Subtotal, Total, Tax, etc)
     itemsFooterSubTitle: {
         margin: [0,5,0,5],
         bold: false,
         alignment:'left',
     },
     itemsFooterSubValue: {
         margin: [3,5,0,5],
         bold: false,
         alignment:'center',
     },
     itemsFooterTotalTitle: {
         margin: [0,5,0,5],
         bold: true,
         alignment:'left',
     },
     itemsFooterTotalValue: {
         margin: [3,5,0,5],
         bold: true,
         alignment:'center',
     },
     signaturePlaceholder: {
         margin: [0,70,0,0],   
     },
     signatureName: {
         bold: true,
         alignment:'center',
     },
     signatureJobTitle: {
         italics: true,
         fontSize: 10,
         alignment:'center',
     },
     notesTitle: {
       fontSize: 10,
       bold: true,  
       margin: [0,50,0,3],
     },
     notesText: {
       fontSize: 10
     },
     center: {
         alignment:'center',
     },
   },
   defaultStyle: {
     columnGap: 20,
   }
  };

  data.services.forEach((element,index) => {
    console.log(element,index);
    template.content[8].table.body.push(
      [
      {
      style: "itemNumber",
      text: data.currency +' '+ element.quantity * element.price
     },
     {
      style: "itemNumber",
      text: element.quantity
     },
     {
      style: "itemNumber",
      text: data.currency +' '+ element.price
     },
     {
      style: "itemsRight",
      text: this.toRtl(element.name)
     }
    ]
    );
  });

  return template;

}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////




////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////




set_template_0(data){
  console.log('data',data);
  var self = this;


// data.client.fname + ' ' + data.client.lname
// data.client.address

// data.settings.business_name
// data.settings.business_address

// data.date.date_of_issue
// data.date.date_to_pay

// data.document_counter.name
// data.document_counter.counter

// data.notes



let template:any =   {
  footer: function(currentPage, pageCount) {
    return {
        margin:10,
        columns: [
          { text: self.toRtl(data.document_counter.name +' '+ data.document_counter.counter + ' עמוד ' + currentPage.toString() + ' מתוך ' + pageCount), style: 'documentFooterLeft' },
          { text: '', style: 'documentFooterCenter' },
          { text: self.toRtl('הופק באמצעות invoice point'), style: 'documentFooterRight' }
        ]
    };

},
    content: [
      {
        columns: [
          {
            image:
              data.settings.logo,
            width: 150,
          },
          [
            {
              text: this.toRtl(data.document_counter.name),
              color: '#333333',
              width: '*',
              fontSize: 28,
              bold: true,
              alignment: 'right',
              margin: [0, 0, 0, 15],
            },
            {
              stack: [
                {
                  columns: [
                    {

                      text: data.document_counter.counter,
                      bold: true,
                      color: '#333333',
                      width: '*',
                      fontSize: 12,
                      alignment: 'right',
                    },
                    {
                      text: this.toRtl(data.document_counter.name) ,
                      bold: true,
                      color: '#aaaaab',
                      fontSize: 12,
                      alignment: 'right',
                      width: 100,
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: data.date.date_of_issue,

                      bold: true,
                      width: '*',
                      fontSize: 12,
                      alignment: 'right',
                    },
                    {
                      text: 'תאריך',
                      color: '#aaaaab',
                      bold: true,
                      fontSize: 12,
                      alignment: 'right',
                      width: 100,
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: data.date.date_to_pay,

                      bold: true,
                      width: '*',
                      fontSize: 12,
                      alignment: 'right',
                    },
                    {
                      text: this.toRtl('לתשלום עד'),
                      color: '#aaaaab',
                      bold: true,
                      fontSize: 12,
                      alignment: 'right',
                      width: 100,
                    },
                  ],
                },
                // {
                //   columns: [
                //     {
                //       text: 'Status',
                //       color: '#aaaaab',
                //       bold: true,
                //       fontSize: 12,
                //       alignment: 'right',
                //       width: '*',
                //     },
                //     {
                //       text: 'PAID',
                //       bold: true,
                //       fontSize: 14,
                //       alignment: 'right',
                //       color: 'green',
                //       width: 100,
                //     },
                //   ],
                // },
              ],
            },
          ],
        ],
      },
      {
        columns: [
          {
            text: 'מאת',
            color: '#aaaaab',
            bold: true,
            fontSize: 14,
            alignment: 'right',
            margin: [0, 20, 0, 5],
          },
          {
            text: 'לכבוד',
            color: '#aaaaab',
            bold: true,
            fontSize: 14,
            alignment: 'right',
            margin: [0, 20, 0, 5],
          },
        ],
      },
      {
        columns: [
          {
            text: this.toRtl(data.settings.business_name),
            bold: true,
            color: '#333333',
            alignment: 'right',
          },
          {
            text: this.toRtl(data.client.fname + ' ' + data.client.lname),
            bold: true,
            color: '#333333',
            alignment: 'right',
          },
        ],
      },
      {
        columns: [
          {
            text: data.settings.business_number,
            // color: '#aaaaab',
            // bold: true,
            // margin: [0, 7, 0, 3],
          },
          {
            text: data.client.phone,
            // color: '#aaaaab',
            // bold: true,
            // margin: [0, 7, 0, 3],
          },
        ],
      },
      {
        columns: [
          {
            text: data.settings.business_address,
            style: 'invoiceBillingAddress',
          },
          {
            text: data.client.address,
            style: 'invoiceBillingAddress',
          },
        ],
      },
      {
        columns: [
          {
                  text: data.settings.business_email,
            style: 'invoiceBillingAddress',
          },
          {
            text: data.client.email,
            style: 'invoiceBillingAddress',
          },
        ],
      },
      {
        columns: [
          {
            text: data.settings.business_phone,
            style: 'invoiceBillingAddress',
          },
          {
            text: '',
            style: 'invoiceBillingAddress',
          },
        ],
      },
      '\n\n',
      {
        width: '100%',
        alignment: 'center',
        text: this.toRtl( data.document_counter.name + ' ' + data.document_counter.counter),
        bold: true,
        margin: [0, 10, 0, 10],
        fontSize: 15,
      },
      {
        layout: {
          defaultBorder: false,
          hLineWidth: function(i, node) {
            return 1;
          },
          vLineWidth: function(i, node) {
            return 1;
          },
          hLineColor: function(i, node) {
            if (i === 1 || i === 0) {
              return '#bfdde8';
            }
            return '#eaeaea';
          },
          vLineColor: function(i, node) {
            return '#eaeaea';
          },
          hLineStyle: function(i, node) {
            // if (i === 0 || i === node.table.body.length) {
            return null;
            //}
          },
          // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
          paddingLeft: function(i, node) {
            return 10;
          },
          paddingRight: function(i, node) {
            return 10;
          },
          paddingTop: function(i, node) {
            return 2;
          },
          paddingBottom: function(i, node) {
            return 2;
          },
          fillColor: function(rowIndex, node, columnIndex) {
            return '#fff';
          },
        },
        table: {
          headerRows: 1,
          widths: [80,40,'auto','*'],
          body: [
            [
              {
                text: 'סה״כ',
                fillColor: '#eaf2f5',
                alignment: 'center',
                border: [false, true, false, true],
                margin: [0, 5, 0, 5],
                textTransform: 'uppercase',
              },
              {
                text: 'כמות',
                border: [false, true, false, true],
                alignment: 'center',
                fillColor: '#eaf2f5',
                margin: [0, 5, 0, 5],
                textTransform: 'uppercase',
              },
              {
                text: 'מחיר',
                border: [false, true, false, true],
                alignment: 'center',
                fillColor: '#eaf2f5',
                margin: [0, 5, 0, 5],
                textTransform: 'uppercase',
              },
              {
                text: 'פירוט',
                border: [false, true, false, true],
                alignment: 'right',
                fillColor: '#eaf2f5',
                margin: [0, 5, 0, 5],
                textTransform: 'uppercase',
              },
            ],
          
          ],
        },
      },
      '\n\n',
      {
        layout: {
          defaultBorder: false,
          hLineWidth: function(i, node) {
            return 1;
          },
          vLineWidth: function(i, node) {
            return 1;
          },
          hLineColor: function(i, node) {
            return '#eaeaea';
          },
          vLineColor: function(i, node) {
            return '#eaeaea';
          },
          hLineStyle: function(i, node) {
            // if (i === 0 || i === node.table.body.length) {
            return null;
            //}
          },
          // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
          paddingLeft: function(i, node) {
            return 10;
          },
          paddingRight: function(i, node) {
            return 10;
          },
          paddingTop: function(i, node) {
            return 3;
          },
          paddingBottom: function(i, node) {
            return 3;
          },
          fillColor: function(rowIndex, node, columnIndex) {
            return '#fff';
          },
        },
        table: {
          headerRows: 1,
          widths: [80,'*'],
          body: [
            [
              {
                text: data.currency +' '+data.total_price,
                border: [false, true, false, true],
                alignment: 'center',
                fillColor: '#f5f5f5',

                margin: [0, 5, 0, 5],
              },
              {
                border: [false, true, false, true],
                text: 'סה״כ',
                alignment: 'left',
                margin: [0, 5, 0, 5],
              },
            ],
            [
              {
                text: data.currency +' '+data.total_vat,
                border: [false, false, false, true],
                alignment: 'center',
                fillColor: '#f5f5f5',
                margin: [0, 5, 0, 5],
              },
              {
                text: 'מע״מ',
                alignment: 'left',
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
              },
            ],
            [
              {
                text: data.currency +' '+data.total_amount,
                bold: true,
             //   fontSize: 20,
                alignment: 'center',
                fillColor: '#f5f5f5',
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
              },
              {
                text: this.toRtl('סה״כ לתשלום'),
                bold: true,
            //    fontSize: 20,
                alignment: 'left',
                border: [false, false, false, true],
                margin: [0, 5, 0, 5],
              },
            ],
          ],
        },
      },
      '\n\n',
      {
        image: data.settings.signature,
        width: 150,
        alignment:'left'

      },
      {
        text: 'הערות',
        style: 'notesTitle',
      },
      {
        text: this.toRtl(data.notes),
        style: 'notesText',
      },
    ],
    styles: {
      notesTitle: {
        fontSize: 10,
        bold: true,
        margin: [0, 50, 0, 3],
      },
      notesText: {
        fontSize: 10,
      },
             // Document Footer
             documentFooterLeft: {
              fontSize: 10,
              margin: [5,5,5,5],
              alignment:'left'
          },
          documentFooterCenter: {
              fontSize: 10,
              margin: [5,5,5,5],
              alignment:'center'
          },
          documentFooterRight: {
              fontSize: 10,
              margin: [5,5,5,5],
              alignment:'right'
          },
    },
    defaultStyle: {
      columnGap: 20,
      //font: 'Quicksand',
    },
  };

  data.services.forEach((element,index) => {
    console.log(element,index);
    template.content[9].table.body.push(
      [
      {
      border: [false, false, false, true],
      alignment: 'center',
      margin: [0, 5, 0, 5],
      fillColor: '#f5f5f5',
      text: data.currency +' '+ element.quantity * element.price
     },
     {
      border: [false, false, false, true],
      alignment: 'center',
      margin: [0, 5, 0, 5],
      text: element.quantity
     },
     {
      border: [false, false, false, true],
      alignment: 'center',
      margin: [0, 5, 0, 5],
      text: data.currency +' '+ element.price
     },
     {
      border: [false, false, false, true],
      alignment: 'right',
      margin: [0, 5, 0, 5],
      text: this.toRtl(element.name)
     }
    ]
    );
  });

  return template;


  }



















  


















  










  













////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////













  
}
