import { PrivacypolicyComponent } from './../../app/privacypolicy/privacypolicy.component';
import { LoadingService } from './../../providers/loading.service';
import { AuthService } from './../../providers/auth-service';
import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';

import {
  SignInWithApple,
  AppleSignInResponse,
  AppleSignInErrorResponse,
  ASAuthorizationAppleIDRequest
} from '@ionic-native/sign-in-with-apple';

import { IonRouterOutlet, Platform } from '@ionic/angular';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { registerU } from '../register/registerUser';
// import {choseShop} from '../chooseShop/chooseShop';
import { userD } from '../../app/UserDClass';
import { userService } from '../../ShopService/user.service';
import { ShopService } from '../../ShopService/shop.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LocalStorageService } from '../../providers/local-storage.service';
import * as firebase from 'firebase/app';

import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { ConfigService } from "../../app/config.service";
import { AlertController, MenuController, ModalController } from '@ionic/angular';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AlertExample } from 'src/providers/alert.service';
import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';


@Component({
  selector: 'social-login-page',
  templateUrl: 'socialLogin.page.html',
  styleUrls: ['./socialLogin.page.scss']
})

export class SocialLoginPage implements OnInit {

  uid: any;
  testCred: any;
  backDropUsed = false;


  @ViewChild('termsModal', { static: false }) modal: ElementRef;
  userData: any;
  constructor(
    private localStorageService: LocalStorageService,
    public shopS: ShopService,
    private authService: AuthService,
    private router: Router,
    public alertController: AlertController,
    public configS: ConfigService,
    private renderer: Renderer2,
    public facebook: Facebook,
    private platform: Platform,
    private afAuth: AngularFireAuth,
    private menuControl: MenuController,
    private afDb: AngularFireDatabase,
    public us: userService,
    private routerOutlet: IonRouterOutlet,
    private modalController: ModalController,
    private loadingService: LoadingService,
    private googlePlus: GooglePlus,
    private alertS: AlertExample) {







    /* Saving user data in localstorage when 
logged in and setting up null when logged out */

    //13.5.2020
    // this.afAuth.authState.subscribe(user => {
    //   if (user) {
    //     // console.log("socialLogin TRUE: ",user)
    //     //   console.log('user',user);
    //     this.userData = user;
    //     localStorage.setItem('user', JSON.stringify(this.userData));
    //     JSON.parse(localStorage.getItem('user'));


    //   } else {
    //     //   console.log('user',user);
    //     localStorage.setItem('user', null);
    //     console.log("socialLogin FALSE: ",user)

    //     JSON.parse(localStorage.getItem('user'));
    //   }
    // })


  }


  clickNewOrder() {
    // this.shopS.loadFireFunction();

    // this.shopS.loadAllBuis().subscribe((result:any) => {
    //   var newArray:any=[];
    //   var numm=0;
    //   var result2=this.shopS.json2array(result);
    //   console.log(result2)
    //   result2.forEach(element => {
    //     numm++;
    //     console.log(numm,newArray,element.Setting)
    //     newArray.push(element.Setting);

    //     this.shopS.pushNeWbarber(element.Setting)

    //   });
    //      console.log("================ >>>>>>>>>>>>>>>>> |",newArray);


    //   //   console.log("================ >>>>>>>>>>>>>>>>> |",this.allBusiness);
    // });
  }
  ionViewDidEnter() {
    this.shopS.loaderoff = false;

    this.shopS.changeButtonState(-1);
    setTimeout(() => {
      this.menuControl.close();
      this.menuControl.enable(false);
    }, 900);


    // this.haimfunction();


  }

  showprintLogin = false;
  consoleNUM = 7;
  consoleLOGshow() {
    this.consoleNUM--;
    if (this.consoleNUM == 0) {
      alert('debug mode on');
      this.consoleNUM = 7;
      this.showprintLogin = !this.showprintLogin;
    }
  }

  goToEmailLogin() {
    this.router.navigate(['login'], { replaceUrl: true });
  }
  goToPhomeLogin() {
    this.router.navigate(['login-phone'], { replaceUrl: true });
  }

  ngOnInit() {


  }



  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'התחברות נכשלה',
      subHeader: '',
      message: 'זיהינו כי קיים כבר משתמש עם כתובת המייל שהוזנה אנא נסו שנית בדרך אחרת.',
      buttons: ['סגור']
    });

    await alert.present();
  }


  async loginAlertFacebook() {
    const alert = await this.alertController.create({
      header: 'שימו לב',
      subHeader: '',
      message: 'עקב המעבר של פייסבוק לMeta ייתכנו בעיות זמניות בחיבור דרך פייסבוק',
      buttons: [
        {
          text: 'יציאה',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return false;
          }
        }, {
          text: 'המשך',
          handler: () => {
            if (this.shopS.environment.platform == 'app') {
              this.loginFacebook();
            } else {
              this.FBLogin_web();
            }
            return true;
          }
        }
      ]
    });

    await alert.present();
  }

  loginFacebook(): Promise<any> {
    // this.alertS.aleryByText2('ahasd');

    console.log("loginFacebook")
    let self = this;
    //this.loadingService.present1sec();
    this.loadingService.presentforever();

    return this.facebook.login(['email']).then((result) => {
      let self2 = self;

      //  console.log(result);
      // this.textMessage += '----------------------------------resultFACEBOOK--------------------' + JSON.stringify(result);

      const facebookCredential = firebase.auth.FacebookAuthProvider.credential(result.authResponse.accessToken);
      this.testCred = facebookCredential;
      // console.log(facebookCredential);
      // console.log("step 1 ")
      const accessToken = result.authResponse.accessToken;
      firebase.auth().signInWithCredential(facebookCredential).then((success: any) => {
        // console.log(success.user.uid);
        // console.log("step 2 ")
        let self3 = self2;

        ///USER AGENT

        this.uid = success.user.uid;
        /// this.textMessage += '----------------------------------resultFACEBOOK--------------------'+ JSON.stringify(success);
        this.textMessage += '----------------------------------resultFACEBOOK-----------accessToken------this.uid---' + this.uid + '////' + accessToken;
        var credential: any = success;

        const userData = {
          accessToken: accessToken,
          fname: credential.additionalUserInfo.profile.first_name,
          lname: credential.additionalUserInfo.profile.last_name,
          email: credential.additionalUserInfo.profile.email ? credential.additionalUserInfo.profile.email : this.uid + "@point-app.net",
          // phone: credential.user.phoneNumber,
          ProfilePic: credential.user.photoURL,
          //credential: credential,
        };
        this.textMessage += '-----------resultFACEBOOK------userData!!@$%%$#-----userData::::' + JSON.stringify(userData);

        this.afDb.object('/Users/' + this.uid).update(
          userData
        );

        this.textMessage += '-----------resultFACEBOOK------success!!@$%%$#-----this.uid::::';
        this.shopS.log(this.uid, 'login_facebook');

        var firbaseM = this.afDb.object('/Users/' + this.uid).snapshotChanges().subscribe((res) => {
          //   console.log(res.payload.val());
          console.log("step 3 ")
          this.textMessage += '-----------resultFACEBOOK------success!!@$%%$#-----step 3step 3step 3step 3step 3step 3step 3::::';

          const data = res.payload.val();


          this.collectAndSave(data);
          this.us.saveUserData(data, res.key);
          this.checkBarberId(data);
          firbaseM.unsubscribe();
          this.loadingService.dismiss();
        });

      }, (error) => {
        this.loadingService.dismiss();
        this.authService.logout();
        console.log(error);
        //   this.presentAlert();
        //   firebase.auth().fetchSignInMethodsForEmail(error.email).then((data) => {
        //  //   console.log(data);
        //   }, (err) => {
        //     console.log(err);

        //   });
        //this.redirectToGoogleAuth();
        this.presentAlert();
      });
    }, (error) => {
      this.loadingService.dismiss();
      this.shopS.log(this.uid, 'login_facebook_fail');

      //   alert('Connection error');
      console.log("facebook error ", error);
      // this.presentAlert();

      this.textMessage += '-----------------------------------error123FACEBOOK--------------------' + error;

    });
  }

  async nativeAppleAuth(): Promise<void> {

    console.log("nativeAppleAuth")
    this.loadingService.present1sec();


    try {
      const appleCredential: AppleSignInResponse = await SignInWithApple.signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
        ]
      });
      const credential = new firebase.auth.OAuthProvider('apple.com').credential(
        appleCredential.identityToken
      );
      this.afAuth.auth.signInWithCredential(credential).then(response => {

        console.log('Login successful', JSON.stringify(response));

        var success = response;
        this.uid = success.user.uid;
        var credential: any = success;


        console.log("credential.additionalUserInfo.profile.email ---- > ", credential.additionalUserInfo.profile.email)
        ///USER AGENT
        const userData = {
          //  accessToken: accessToken,
          //  fname: credential.additionalUserInfo.profile.first_name,
          //  lname: credential.additionalUserInfo.profile.last_name,
          email: credential.additionalUserInfo.profile.email ? credential.additionalUserInfo.profile.email : this.uid + "@point-app.net",
          //  phone: credential.user.phoneNumber,
          ProfilePic: "https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/male_avatar_323b.svg",
          //credential: credential,
        };
        console.log('-----------apple login' + JSON.stringify(userData));
        this.shopS.log(this.uid, 'login_apple');

        this.afDb.object('/Users/' + this.uid).update(
          userData
        );
      })






    } catch (error) {
      this.loadingService.dismiss();
      //haim change
      this.authService.logout()
      console.log(error);
    }
  }

  async loginWithGoogle() {


    //  const credential = firebase.auth.GoogleAuthProvider.credential(null, '474725772867-enl0gnc3s3cletqalkobh7hp3egio06o.apps.googleusercontent.com')
    // const  uid  = await firebase.auth().signInWithCredential(credential)
    //       console.log('uid',uid);   474725772867-hrh47a1hpvsgai4u7sa8ddpav5gdkk1f.apps.googleusercontent.com

    const credential = new auth.GoogleAuthProvider();
    //credential.setCustomParameters.
    await this.afAuth.auth.signInWithPopup(credential).then(data => {
      //console.log('data',data);
    }).catch(err => {
      this.loadingService.dismiss();
      console.log('err', err);
    })
    console.log('this.afAuth.auth.signInWithPopup', this.afAuth.auth.signInWithPopup);
    //   this.router.navigate(['admin/list']);
  }


  textMessage: any = 'testmamammaL:     \n\n\n\n';
  googleLogin() {
    console.log('googleLogin');
    // this.loadingService.present1sec();
    this.loadingService.presentforever();

    this.textMessage += '-----------------------------------google login--------------------';
    this.textMessage += '-----------------------------------google webClientId--------------------' + this.webClientId;

    console.log("step ---2 ")
    this.googlePlus.login({
      'scopes': '',
      'webClientId': '474725772867-enl0gnc3s3cletqalkobh7hp3egio06o.apps.googleusercontent.com',
      'offline': true
    }).then((result) => {
      //   console.log(result);
      console.log("step --- 3 ", result)
      this.textMessage = result;

      const googleCredential = firebase.auth.GoogleAuthProvider.credential(result.idToken);
      const accessToken = result.accessToken;
      firebase.auth().signInWithCredential(googleCredential).then((success) => {

        console.log("step --- 4 ", JSON.stringify(success));
        this.uid = success.user.uid;
        /// this.textMessage += '----------------------------------resultFACEBOOK--------------------'+ JSON.stringify(success);
        this.textMessage += '----------------------------------resultFACEBOOK-----------accessToken------this.uid---' + this.uid + '////' + accessToken;
        var credential: any = success;

        const userData = {
          //  accessToken: accessToken,
          fname: credential.additionalUserInfo.profile.given_name,
          lname: credential.additionalUserInfo.profile.family_name,
          email: credential.additionalUserInfo.profile.email,
          //  phone: credential.user.phoneNumber,
          ProfilePic: credential.user.photoURL
          //credential: credential,
        };
        this.textMessage += '-----------resultFACEBOOK------userData!!@$%%$#-----userData::::' + JSON.stringify(userData);
        console.log("step --- 4.5 ", JSON.stringify(userData));
        this.afDb.object('/Users/' + this.uid).update(
          userData
        );


        var firbaseM = this.afDb.object('/Users/' + this.uid).snapshotChanges().subscribe((res) => {
          console.log("step --- 5 ");
          //   console.log(res.payload.val());
          const data = res.payload.val();
          this.collectAndSave(data);
          this.loadingService.dismiss();

          this.us.saveUserData(data, res.key);

          this.checkBarberId(data);

          firbaseM.unsubscribe();
        });
      }, (error) => {
        //  this.redirectToFacebookAuth();
        this.loadingService.dismiss();
        this.presentAlert();
        this.textMessage += '-----------------------------------error--------------------' + error;

        console.log("step ---2 ")
        this.googlePlus.login({
          'scopes': '',
          'webClientId': '474725772867-enl0gnc3s3cletqalkobh7hp3egio06o.apps.googleusercontent.com',
          'offline': true
        }).then((result) => {
          //   console.log(result);
          console.log("step --- 3 ")
          this.textMessage = result;

          const googleCredential = firebase.auth.GoogleAuthProvider.credential(result.idToken);
          const accessToken = result.accessToken;
          firebase.auth().signInWithCredential(googleCredential).then((success) => {

            console.log("step --- 4 ")
            //   console.log(success);
            this.uid = success.user.uid;
            const userData = this.configUserData(success, accessToken);
            console.log("step --- 4 ", success, accessToken)
            this.afDb.object('/Users/' + this.uid).update({
              ...userData
            });


            this.shopS.log(this.uid, 'login_google');


            var firbaseM = this.afDb.object('/Users/' + this.uid).snapshotChanges().subscribe((res) => {
              console.log("step --- 5 ");
              //   console.log(res.payload.val());
              const data = res.payload.val();
              this.collectAndSave(data);
              this.us.saveUserData(data, res.key);
              this.loadingService.dismiss();
              this.checkBarberId(data);
              firbaseM.unsubscribe();
            });
          }, (error) => {
            //  this.redirectToFacebookAuth();
            this.loadingService.dismiss();
            this.shopS.log(this.uid, 'login_google_fail');

            this.presentAlert();
            this.textMessage += '-----------------------------------error--------------------' + error;

          });
        }, (error) => {
          //  alert('Connection error');
          this.shopS.log(this.uid, 'login_google_fail');

          this.loadingService.dismiss();
          console.log(error);
          this.textMessage += '-----------------------------------error123--------------------' + error;

        });


      });
    }, (error) => {
      this.loadingService.dismiss();
      this.shopS.log(this.uid, 'login_google_fail');

      this.textMessage += '-----------------------------------error333123--------------------' + error;

    }
    );
  }

  redirectToGoogleAuth() {
    // alert('Already signed via Google. Redirecting...');
    this.googleLogin();

  }

  redirectToFacebookAuth() {
    alert('Already signed via Facebook. Redirecting...');
    this.loginFacebook();

  }






  configUserDataF(credential, aToken) {
    //    console.log(credential);
    //   console.log(credential.additionalUserInfo);
    if (credential.user.phoneNumber) {

    } else {
      credential.user.phoneNumber = '';
    }
    const user = {
      accessToken: aToken,
      fname: credential.additionalUserInfo.profile.first_name,
      lname: credential.additionalUserInfo.profile.last_name,
      email: credential.additionalUserInfo.profile.email,
      phone: credential.user.phoneNumber,
      ProfilePic: credential.user.photoURL,
      //     credential: credential
      //      rawData : credential.toSring(),
    };
    return user;
  }

  configUserData(credential, aToken) {
    // console.log(credential);
    //   console.log(credential.additionalUserInfo);
    if (credential.user.phoneNumber) {

    } else {
      credential.user.phoneNumber = '';
    }
    const user = {
      accessToken: aToken,
      fname: credential.additionalUserInfo.profile.given_name,
      lname: credential.additionalUserInfo.profile.family_name,
      email: credential.additionalUserInfo.profile.email,
      phone: credential.user.phoneNumber,
      ProfilePic: credential.user.photoURL,
      //  credential: credential
    };
    return user;
  }

  collectAndSave(data) {
    let finalData = {
      BarberID: '',
      uid: ''
    };
    for (let key in data) {
      if (key === 'BarberID') {
        finalData.BarberID = data[key];
      }
    }
    finalData.uid = this.uid;
    // console.log(finalData);
    this.localStorageService.saveToLocalStorage('user', finalData);
  }

  // checkBarberId(data: any) {
  //   console.log(data);
  //   if (data.BarberID) {
  //     console.log(data.BarberID);
  //     this.shopS.getShopDetils(data.BarberID).valueChanges().subscribe(
  //       (shopD) => {
  //         console.log(shopD, 'shopD');
  //         if (shopD) {
  //           this.shopS.saveShopDetils(shopD);
  //           this.router.navigate(['tabs'], {replaceUrl: true});
  //         } else {
  //           this.router.navigate(['chooseShop'], {replaceUrl: true});
  //         }
  //       }, (error) => {
  //         console.log(error);
  //       });
  //   } else {
  //     this.router.navigate(['chooseShop'], {replaceUrl: true});
  //   }
  // }



  subscribeToUser: any;

  loginFlag = 0;
  checkBarberId(data: any) {

    console.log(" 11 - 111 1111 --1 111 111 111  ------>");


    //  console.log(data);
    if (data.BarberID) {
      //    console.log(data.BarberID);
      this.subscribeToUser = this.shopS.getShopDetils(data.BarberID).valueChanges().subscribe(
        (shopD: any) => {
          console.log(" 222 --- 222 ---- 222 ---- 222  ------>");
          //console.log(shopD.$key);
          //          console.log(shopD, 'shopD');

          this.loginFlag = 1;
          if (shopD) {
            console.log(" 333 --- 333 ---- 3333 ---- 333  ------>");
            this.shopS.saveShopDetils(shopD);
            this.shopS.shopData = {};
            this.shopS.shopData = shopD;
            this.shopS.shopData['BarberId'] = data.BarberID;
            // this.shopS.saveShopDetils(shopD);
            console.log(" run run run run run ------- > >");
            this.authService.isconnect.next(true);
            this.shopS.socialLogin = true;
            // this.router.navigate(['tabs/home']);
            // this.router.navigate(['setting'], {replaceUrl: true});
            this.subscribeToUser.unsubscribe();


          } else {
            this.router.navigate(['chooseShop'], { replaceUrl: true });
            this.subscribeToUser.unsubscribe();

          }


        }, (error) => {
          console.log(error);
        });
    } else {
      this.authService.isconnect.next(true);
      this.loginFlag = 2;

    }
  }




  openModal() {
    // console.log(this.modal);
    this.backDropUsed = true;
    this.renderer.addClass(this.modal.nativeElement, 'openModal');
  }
  closeModal() {
    this.backDropUsed = false;
    this.renderer.removeClass(this.modal.nativeElement, 'openModal');
  }




























  // webClientId = '474725772867-enl0gnc3s3cletqalkobh7hp3egio06o.apps.googleusercontent.com';
  webClientId = '474725772867-kj5km11ughodloqlbccg4ug513u7aam5.apps.googleusercontent.com';

  async doGoogleLogin() {
    this.googlePlus.login({
      'scopes': '', // optional - space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
      'webClientId': this.webClientId, // optional - clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
      'offline': false, // Optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
    })
      .then(user => {
        //save user data on the native storage
        this.textMessage += '-----------------------------------user:!!--------------------' + user;

      }, err => {
        console.log(err);
        this.textMessage += '-----------------------------------error    ' + err + '-----------------------------------webClientId    ' + this.webClientId;


      })

  }

  // async presentAlert() {
  //   const alert = await this.alertController.create({
  //      message: 'Cordova is not available on desktop. Please try this in a real device or in an emulator.',
  //      buttons: ['OK']
  //    });

  //   await alert.present();
  // }


  // async presentLoading(loading) {
  //   return await loading.present();
  // }




  googleLogin_web() {
    // const provider = new firebase.auth.GoogleAuthProvider();
    // console.log(provider);

    console.log('googleLogin_web');



    // return this.oAuthLogin(provider);

    this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).catch(function (error) {
      // An error happened.
      console.log(error)

    });

  }

  FBLogin_web() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        console.log(credential.user);
        this.updateUserData(credential.user)
      }, (err) => {
        console.log(err);
        // this.presentAlert();
      })
  }

  private updateUserData(user) {
    //sets user data to firebase om login

    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
    };
    console.log('data:', data)
    return data;
  }









  async presentPrivacypolicy(): Promise<HTMLIonModalElement> {

    if (this.platform.is("ios")) {
      var modal = await this.modalController.create({
        component: PrivacypolicyComponent,
        swipeToClose: false,
        cssClass: '',
        presentingElement: this.routerOutlet.nativeEl,
        mode: "ios",
        //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal


        // Get the top-most ion-modal

      });
    } else {
      var modal = await this.modalController.create({
        component: PrivacypolicyComponent,
        swipeToClose: false,
        mode: 'ios',
        cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',


        //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal


        // Get the top-most ion-modal

      });
    }
    // });
    await modal.present();
    return modal;
  }
  // else{
  //   var modal = await this.modalController.create({
  //     component: PrivacypolicyComponent,
  //     swipeToClose: false,
  //     cssClass: this.shopS.platform == 'desktop' ? 'modalDesktop' : 'modalLookLikeNativ',


  //   //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal


  //    // Get the top-most ion-modal




  // }










}
