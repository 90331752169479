import { Component, OnInit, ViewChild } from '@angular/core';
import { ContactListService } from './contact-list-service/contact-list.service';
import { IonInfiniteScroll, IonVirtualScroll } from '@ionic/angular';


@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})


export class ContactListComponent implements OnInit {


  @ViewChild(IonInfiniteScroll,{static:false}) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonVirtualScroll,{static:false}) virtualScroll: IonVirtualScroll;

  constructor(public contactsS:ContactListService) { }


  contactsList3 = [{name:'',phone:'',active:false}]
  ngOnInit() {
  //  console.log('init contactsS.contactsList3',this.contactsS.contactsList3);
    this.contactsList3 = JSON.parse(JSON.stringify(this.contactsS.contactsList3));
  }
  show_finish = false;

  finish(){


    if(this.contactsS.showNextContactFLAG){
      this.show_finish = true;
    }

   

  }
  




  searchAfter(ser){
		if(ser == ''){
			this.contactsS.contactsList4=[];
		//   this.contactsList3 = JSON.parse(JSON.stringify(this.data));;
		  return;
		}else if(ser.length > 1){

    
		this.contactsS.contactsList4=[];
		var newList = this.contactsS.data;
		this.contactsS.contactsList3.forEach(element => {
		  //console.log("-----",JSON.stringify(element),"------");
		  var checkName=element.name+element.name;
		  if(checkName.includes(ser)){
			this.contactsS.contactsList4.push(element);
		  }
		  
    });
    // this.virtualScroll.checkEnd();


    console.log("searchAfter",this.contactsS.contactsList4);
  }
    //this.virtualScroll.invalidateAllCachedMeasurements();}

		// const found = newList.find(element => element > 10);
	  }
    onKeyUp(ev){
		// this.search(ev.target.value)
		this.searchAfter(ev.target.value);
  
    }
    
  
  dismissFinish(){
    this.show_finish = false;

  }



}
