import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WalletServiceService } from '../wallet-service/wallet-service.service';
import { WalletShowNewSalePage } from '../wallet-show-new-sale/wallet-show-new-sale.page';

@Component({
  selector: 'app-wallet-generate-subscription',
  templateUrl: './wallet-generate-subscription.component.html',
  styleUrls: ['./wallet-generate-subscription.component.scss'],
})
export class WalletGenerateSubscriptionComponent implements OnInit {

  constructor(public walletS: WalletServiceService,public modalController: ModalController) { }

  ngOnInit() {
    var today:any = new Date();
    var dd:any = today.getDate();
    var mm:any = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    today = dd + '/' + mm + '/' + yyyy;
    this.walletS.generate_subscription.sub_start_date = today;

  }


  generate(){
    if(this.walletS.generate_subscription.sale_type == ""){
       this.walletS.generate_subscription.sale_type = undefined;
    }
    if(this.walletS.generate_subscription.sub_type == ""){
      this.walletS.generate_subscription.sub_type = undefined;
   }
    console.log('this.walletS.generate_subscription',this.walletS.generate_subscription)
    this.walletS.send_to_api('generate-subscription',this.walletS.generate_subscription,val=>{
      val = val.res;

      console.log('dsds',val)
      this.walletS.show_new_sale = val;
      // this.show_new_sale_modal();
    })
  }



  async show_new_sale_modal(){
    const modal = await this.modalController.create({
      component: WalletShowNewSalePage,
      mode:'ios',
      cssClass: 'my-custom-class',
      componentProps: { iframe: this.walletS.show_new_sale.sale_url }
    });
    return await modal.present();

  }




  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }


}
