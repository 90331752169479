import { tap } from 'rxjs/operators';
import { repeat } from 'rxjs-compat/operator/repeat';
import * as moment from 'moment-timezone';

export class BuisMeet {
    public googleId: any = "";
    public createdAt: any = "";
    public calSynced: any = false;

    public afDb: any;
    private workTimeArray;
    public sendSms: any = true;
    public BarberID: string;
    public barberName: string;
    public endTime: string;
    public reminder: any;
    // public startTime: any;
    public meetTime: string;
    public price: string = "0";
    public startTimeView: string;
    public endTimeView: string;
    public meetType: any;
    public meetDate: any;
    public timezone: any;
    public zoomlink = "";
    public timestamp: any;
    // public paymentRespone: any = [];
    // public payment_status: string;

    public meetTypeName: string;
    public meetDay: string;
    public meetStatus: number;
    public moreInfo: string = "";
    public Date: any;
    public flow: any;
    public uIdData: object;
    public startDate: any;
    public key: string = this.newGuid();
    public uId: string;
    public meetDateFormat: any;
    private repeatKey;
    private arrayRepet = [];
    public mType = {
        type: "",
        duplicated: "",
        repeat: null
    }
    public daysName = ["יום ראשון", "יום שני", "יום שלישי", "יום רביעי", "יום חמישי", "יום שישי", "יום שבת"];

    public StartTimeObj = {
        Hour: "00",
        Minuts: "00"
    }
    public EndTimeObj = {
        Hour: "00",
        Minuts: "00"
    }



    // createMeet(BarberID, barberName, endTime, meetTime, price, startTimeView, endTimeView, meetType, meetDate, meetTypeName, meetDay, meetStatus, moreInfo, Date, key, uId) {

    //     this.BarberID = BarberID;
    //     this.barberName = barberName;
    //     this.endTime = endTime;
    //     this.meetTime = meetTime;
    //     this.price = price;
    //     this.startTimeView = startTimeView;
    //     this.endTimeView = endTimeView;
    //     this.meetType = meetType;
    //     this.meetDate = meetDate;
    //     this.meetTypeName = meetTypeName;
    //     this.meetDay = meetDay;
    //     this.meetStatus = meetStatus;
    //     this.moreInfo = moreInfo;
    //     this.Date = Date;
    //     this.key = key;
    //     this.uId = uId;


    // }
    resetCLass() {

        this.BarberID = "";
        this.barberName = "";
        this.endTime = "";
        this.meetTime = "";
        this.price = "";
        this.timestamp = "";
        this.startTimeView = "";
        // this.payment_status = "";
        // this.paymentRespone = [];
        this.endTimeView = "";
        this.meetType = "";
        this.meetDate = "";
        this.meetTypeName = "";
        this.meetDay = "";
        this.meetStatus = 0;
        this.moreInfo = "";
        this.Date = "";
        this.key = this.newGuid();
        this.uId = "";
        this.zoomlink = "";
        this.timezone = "";
        this.googleId = "";
        this.calSynced = false;
        this.createdAt = "";
        this.mType = {
            type: "",
            duplicated: "",
            repeat: null
        }
    }



    fixDate() {
        var my_zone_name = moment.tz.guess();
        var busi_timezone = moment.tz("Asia/Jerusalem");
        let busi_utcOffset = moment.parseZone(busi_timezone).utcOffset();

        let my_offset = new Date().getTimezoneOffset();

        let fixed_date_start = new Date(this.Date + (my_offset * 60000) + (busi_utcOffset * 60000));
        let fixed_date_end = new Date(this.Date + (my_offset * 60000) + (busi_utcOffset * 60000) + (parseInt(this.meetTime) * 60000));
        this.startDate = new Date(this.Date).toString();
        this.endTime = new Date(this.Date + (parseInt(this.meetTime) * 60000)).toString();
        this.startTimeView = this.addzero(fixed_date_start.getHours()) + ':' + this.addzero(fixed_date_start.getMinutes());
        this.endTimeView = this.addzero(fixed_date_end.getHours()) + ':' + this.addzero(fixed_date_end.getMinutes());
        this.meetDate = this.addzero(fixed_date_start.getDate()) + '-' + (this.addzero(fixed_date_start.getMonth() + 1)) + '-' + this.addzero(fixed_date_start.getFullYear());
        this.meetDateFormat = this.meetDate;
        this.timezone = my_zone_name;
    }

    exportMeetUser() {
        this.mType.type = "user";
        console.log(this.mType);
        this.fixDate();

        return {
            key: this.key,
            uId: this.uId,
            BarberID: this.BarberID,
            barberName: this.barberName,
            Date: this.Date,
            timestamp: this.timestamp ? this.timestamp : new Date().getTime(),
            meetDateFormat: this.meetDateFormat,
            // paymentRespone: this.paymentRespone,
            // payment_status: this.payment_status,
            startDate: new Date(this.Date).toString(),
            startTimeView: this.startTimeView,
            meetDate: this.meetDate,
            endTime: this.endTime,
            endTimeView: this.endTimeView,
            meetTime: this.meetTime,
            price: this.price,
            flow: false,
            meetType: this.meetType,
            meetTypeName: this.meetTypeName,
            meetDay: this.meetDay,
            meetStatus: this.meetStatus,
            moreInfo: this.moreInfo,
            mType: this.mType,
            sendSms: this.sendSMScheck(this.sendSms),
            reminder: this.meetType.reminder,
            zoomlink: this.zoomlink,
            typem: "meet",
            timezone: this.timezone,
            googleId: this.googleId || null,
            createdAt: this.createdAt || null,
            calSynced: this.calSynced || null,
        }
    }


    sendSMScheck(sms) {
        console.log('sms', sms)
        if (sms == undefined) {
            console.log('false', sms)

            return "false";
        } else {
            console.log('true', sms)

            return sms;
        }
    }

    importMeet(iMeet) {
        console.log('iMeet', iMeet)
        this.BarberID = iMeet.BarberID;
        this.barberName = iMeet.barberName;
        this.timestamp = iMeet.timestamp ? iMeet.timestamp : null;
        this.endTime = iMeet.endTime;
        this.meetTime = iMeet.meetTime;
        this.price = iMeet.price;
        this.startTimeView = iMeet.startTimeView;
        this.endTimeView = iMeet.endTimeView;
        this.meetType = iMeet.meetType;
        this.meetDate = iMeet.meetDate;
        this.meetTypeName = iMeet.meetTypeName;
        this.meetDay = iMeet.meetDay;
        this.meetStatus = iMeet.meetStatus;
        this.moreInfo = iMeet.moreInfo;
        this.Date = iMeet.Date;
        this.key = iMeet.key;
        this.zoomlink = iMeet.zoomlink;
        this.uId = iMeet.uId;
        this.startDate = iMeet.startDate;
        this.meetDateFormat = iMeet.meetDateFormat;
        this.sendSms = iMeet.sendSms;

        if (iMeet.googleId) {
            this.googleId = iMeet.googleId;
            this.calSynced = iMeet.calSynced;
            this.createdAt = iMeet.createdAt;
        }
    }

    exportMeetAdmin() {
        this.mType.type = "Admin";
        console.log(this.BarberID);
        console.log(this.barberName);
        this.fixDate();


        return {
            key: this.key,
            uId: this.uId,
            BarberID: this.BarberID,
            barberName: this.barberName,
            Date: this.Date,
            timestamp: this.timestamp ? this.timestamp : new Date().getTime(),
            // paymentRespone: this.paymentRespone,
            // payment_status: this.payment_status,
            meetDateFormat: this.meetDateFormat,
            startDate: new Date(this.Date).toString(),
            startTimeView: this.startTimeView,
            meetDate: this.meetDate,
            endTime: this.endTime,
            endTimeView: this.endTimeView,
            meetTime: this.meetTime,
            price: this.price,
            flow: this.flow ? this.flow : false,
            meetType: this.meetType,
            meetStatus: this.meetStatus,
            moreInfo: this.moreInfo,
            reminder: this.meetType.reminder,
            mType: this.mType,
            sendSms: this.sendSMScheck(this.sendSms),
            zoomlink: this.zoomlink,
            typem: "meet",
            timezone: this.timezone,
            googleId: this.googleId || null,
            createdAt: this.createdAt || null,
            calSynced: this.calSynced || null,
        }


    }



    add_googleID_meet(googleId, createdAt, calSynced) {
        this.googleId = googleId;
        this.createdAt = createdAt;
        this.calSynced = calSynced;
    }

    importMeetStatus(status) {
        this.meetStatus = status;
    }
    showClass() {
        console.log("showClass : " +
            "\n", "BarberID ", this.BarberID,
            "\n barberName ", this.barberName,
            "\n endTime ", this.endTime,
            "\n timestamp ", this.timestamp,
            // "\n paymentRespone ", this.paymentRespone,
            // "\n payment_status ", this.payment_status,
            "\n meetTime ", this.meetTime,
            "\n price ", this.price,
            "\n startTimeView ", this.startTimeView,
            "\n endTimeView ", this.endTimeView,
            "\n meetType ", this.meetType,
            "\n meetDate ", this.meetDate,
            "\n meetTypeName ", this.meetTypeName,
            "\n meetDay ", this.meetDay,
            "\n meetStatus ", this.meetStatus,
            "\n moreInfo ", this.moreInfo,
            "\n Date ", this.Date,
            "\n key ", this.key,
            "\n uId ", this.uId,
            "\n meetDateFormat ", this.meetDateFormat,
            "\n startDate ", this.startDate,
            "\n flow ", this.flow,
            "\n meetTypeName ", this.meetTypeName,
            "\n mType ", this.mType,
            "\n smsSend ", this.sendSms,
            "\n zoomlink ", this.zoomlink,
            "\n timezone ", this.timezone

        )
        if (this.googleId) {
            console.log("showClass : " +
                "\n", "googleId ", this.googleId,
                "\n calSynced ", this.calSynced,
                "\n createdAt ", this.createdAt)
        }
    }

    //convert datar to dd-mm-yyyy
    convertDate(inputFormat) {

        var today: any = new Date(inputFormat);
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        return dd + '-' + mm + '-' + yyyy;

    }

    convertDateToYYYY(inputFormat) {
        // console.log('inputFormat',inputFormat)



        if (inputFormat) {
            let t = inputFormat.split("-");
            return t[2].toString() + "-" + t[1].toString() + "-" + t[0].toString();
        }

    }

    importSendSms(data) {
        this.sendSms = data;
    }
    newKey() {
        this.key = this.newGuid();
    }

    importPrice() {
        this.price = this.meetType.price;
    }
    importMoreInfo(info) {
        this.moreInfo = info;
    }
    CreateNewMeet() {
        console.log("ifmeet");
        // if (this.ifmeet()) {
        console.log("ifmeet", this.Date, this.meetType.meetTime);
        this.defineStartTimeAndEndTime(this.Date, this.meetType.meetTime);
        this.price = this.meetType.price;
        this.meetTime = this.meetType.meetTime;
        this.meetTypeName = this.meetType.name;
        // this.meetStatusDefine();
        this.meetStatus = this.meetType.approve ? 3 : 1;
        // }
    }

    defineStatus(s) {
        this.meetStatus = 1;
    }
    ifmeet() {
        console.log(this.BarberID, this.meetDate, this.meetType, this.startTimeView)
        if (this.BarberID && this.meetDate && this.meetType && this.startTimeView) {
            return true;
        } else {
            return false;
        }
    }

    importZoomlink(zoomlink) {
        console.log("zoomlink", zoomlink)
        if (zoomlink) {
            this.zoomlink = zoomlink;
        }
    }

    meetDateFormatDefine(meetdate) {

        this.meetDateFormat = this.convertDate(meetdate);
        return this.meetDateFormat;

    }




    // add zero and save HOUR AND MINUTS
    addZeroToTime(oldtime) {
        let t = oldtime.split(":", 2).map(Number);
        let x = t[0].toString();
        let y = t[1].toString();

        return this.addzero(x) + ":" + this.addzero(y);
    }

    setDate(timestamp, hour_view) {
        this.Date = timestamp;
        this.startTimeView = hour_view;
    }

    // importViewStart(oldtime) {
    //     let t = oldtime.split(":", 2).map(Number);
    //     this.StartTimeObj.Hour = t[0].toString();
    //     this.StartTimeObj.Minuts = t[1].toString();
    //     this.startTimeView = oldtime
    //     var StartTime = new Date(this.Date);
    //     StartTime.setHours(Number(this.StartTimeObj.Hour), Number(this.StartTimeObj.Minuts)).toString();
    //     // this.startTime = StartTime;
    //     this.Date = StartTime.getTime();
    // }


    changeStartTime(oldtime) {
        // let self = this;
        // return new Promise(async resolve => {
        let t = oldtime.split(":", 2).map(Number);
        this.StartTimeObj.Hour = t[0].toString();
        this.StartTimeObj.Minuts = t[1].toString();
        this.startTimeView = oldtime
        var StartTime = new Date(this.Date);
        StartTime.setHours(Number(this.StartTimeObj.Hour), Number(this.StartTimeObj.Minuts)).toString();
        // this.startTime = StartTime;
        this.Date = new Date(StartTime).getTime();
        this.startDate = new Date(StartTime);
        this.defineEndTime();
        // resolve(true);
        // })
    }
    importBarber(id, bName) {
        console.log(id, bName);

        this.BarberID = id;
        this.barberName = bName;
        console.log(this.BarberID);
        console.log(this.barberName);

        return;
    }

    importUserId(id) {
        this.uId = id;
    }

    creatMeet() {

    }

    importuIdData(uIdData) {
        this.uIdData = uIdData;
    }

    loadFullMeet(fMeet: any) {
        // console.log(fMeet);

        this.BarberID = fMeet.BarberID;
        this.Date = fMeet.Date;
        this.timestamp = fMeet.timestamp ? fMeet.timestamp : null;
        this.barberName = fMeet.barberName;
        this.endTime = fMeet.endTime;
        this.endTimeView = fMeet.endTimeView;
        this.flow = fMeet.flow;
        this.key = fMeet.key;
        this.meetDate = fMeet.meetDate;
        this.meetDateFormat = fMeet.meetDateFormat;
        this.meetStatus = fMeet.meetStatus;
        this.meetTime = fMeet.meetTime;
        this.startTimeView = fMeet.startTimeView;
        this.meetType = fMeet.meetType;
        this.moreInfo = fMeet.moreInfo;
        this.price = fMeet.price;
        this.meetTypeName = fMeet.meetTypeName;
        this.startDate = fMeet.startDate;
        // this.startTime = fMeet.startTime;
        this.uId = fMeet.uId;
        this.uIdData = fMeet.uIdData;
        this.zoomlink = fMeet.zoomlink;
        this.importViewEnd(fMeet.endTimeView);
        this.imporViewtStart(fMeet.startTimeView);

        if (fMeet.googleId) {
            this.googleId = fMeet.googleId;
            this.createdAt = fMeet.createdAt;
            this.calSynced = fMeet.calSynced;
        }

        if (typeof fMeet.meetType == "object") {
            this.meetTypeName = fMeet.meetType.name;
        } else {
            this.meetTypeName = "";
        }
    }

    changeMeetTime(newTime) {
        let self = this;
        // return new Promise(async (resolve) => {
        this.meetTime = newTime;
        console.log(this.meetTime);

        this.defineStartTimeAndEndTime(this.Date, newTime)
        // resolve(true);
        // });
    }

    importViewEnd(oldtime) {

        let t = oldtime.split(":", 2).map(Number);
        this.EndTimeObj.Hour = t[0].toString();
        this.EndTimeObj.Minuts = t[1].toString();
        this.endTimeView = oldtime
    }

    imporViewtStart(oldtime) {

        let t = oldtime.split(":", 2).map(Number);
        this.StartTimeObj.Hour = t[0].toString();
        this.StartTimeObj.Minuts = t[1].toString();
        this.startTimeView = oldtime
    }

    importStartDate(sDate) {

        // this.startTime = sDate;
        this.Date = new Date(sDate).getTime();
        this.startDate = new Date(sDate);
        this.meetDate = this.meetDateFormatDefine(sDate);

    }
    importStartDateAndHoureview(sDate) {
        console.log(sDate);

        // this.startTime = sDate;
        this.Date = new Date(sDate).getTime();
        this.meetDate = this.meetDateFormatDefine(sDate);
        this.startTimeView = this.addzero(new Date(this.Date).getHours()) + ":" + this.addzero(new Date(this.Date).getMinutes());
        this.startDate = new Date(this.Date);

        let newObj: any = {
            Hour: this.addzero(new Date(this.Date).getHours()),
            Minuts: this.addzero(new Date(this.Date).getMinutes())
        }
        this.StartTimeObj = newObj;
        // this.StartTimeObj.Hour = this.addzero(this.Date.getHours());
        // this.StartTimeObj.Minuts = this.addzero(this.Date.getMinutes());



    }
    defineFlow(flow) {
        // if(f){


        // this.flow = f;
        // }else{
        //     this.flow =false;
        // }
        this.flow = !!flow;
    }

    defineFlowAuto() {
        // if(this.meetType.flow){
        //     this.flow =true;
        // }else{
        //     this.flow =false;

        // }
        this.flow = !!this.meetType.flow;
    }


    importMeetType(mType) {
        this.meetType = mType;
    }

    defineMeetTime(t) {
        this.meetType.meetTime = t;
    }

    moveForwardTime(breakTime) {
        let nDate = new Date(this.endTime);
        nDate.setTime(nDate.getTime() + (breakTime * 60 * 1000));
        this.importStartDateAndHoureview(nDate);
        this.defineStartTimeAndEndTime(nDate, this.meetTime)


    }

    // meetStatusDefine() {
    //     if (this.meetStatus == 3) {
    //         return true;
    //     }
    //     this.meetStatus = 3;
    //     if (this.meetType.approve == false) {
    //         this.meetStatus = 1;
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    checkIfwork(day, time) {

        console.log("day.timeStart", day.timeStart);

        let s = day.timeStart.split(":", 2).map(Number);
        console.log("day.timeStart", s);

        if (s[0] == 0) {
            return false;
        } else {
            return true;
        }




    }


    // repeatDay(untilTime) {
    //     var dDate = new Date(this.Date);
    //     dDate.setDate(dDate.getDate() + 1);
    //     if (dDate.getTime() > untilTime.getTime()) return this.arrayRepet;


    //     if (this.checkIfwork(this.workTimeArray[dDate.getDay()], dDate)) {
    //         console.log("push meet in ", dDate);
    //         this.defineStartTimeAndEndTime(dDate, this.meetTime);
    //         this.importStartDate(dDate);

    //         this.mType.repeat = this.repeatKey;
    //         this.newKey();
    //         // this.showClass();

    //         this.setAdminMeet();
    //         if (this.uId != "localUserAdmin") {
    //             this.setUserMeet();
    //         }
    //         this.arrayRepet.push(dDate)
    //         return this.repeatDay(untilTime);

    //     } else {
    //         this.Date = dDate.getTime();
    //         return this.repeatDay(untilTime);
    //     }

    // }

    // repeatWeek(untilTime) {
    //     var dDate = new Date(this.Date);
    //     dDate.setDate(dDate.getDate() + 1 * 7);
    //     if (dDate.getTime() > untilTime.getTime()) return this.arrayRepet;


    //     if (this.checkIfwork(this.workTimeArray[dDate.getDay()], dDate)) {
    //         // console.log("push meet in ", dDate);
    //         this.defineStartTimeAndEndTime(dDate, this.meetTime);
    //         this.importStartDate(dDate);
    //         this.mType.repeat = this.repeatKey;
    //         this.newKey();
    //         this.setAdminMeet();
    //         if (this.uId != "localUserAdmin") {
    //             this.setUserMeet();
    //         }

    //         this.arrayRepet.push(dDate)
    //         return this.repeatWeek(untilTime);

    //     } else {
    //         this.Date = dDate.getTime();
    //         return this.repeatWeek(untilTime);

    //     }

    // }
    // async createReaptMeet(pType, untilTime, workTimeArray) {
    //     this.arrayRepet = [];
    //     this.repeatKey = this.key;
    //     this.workTimeArray = workTimeArray;
    //     switch (pType) {
    //         case "week":
    //             return await this.repeatWeek(untilTime)
    //             break;
    //         case "day":
    //             return await this.repeatDay(untilTime);

    //             break;

    //         default:
    //             break;
    //     }

    //     console.log(pType, untilTime, workTimeArray)
    // }

    defineStartTimeAndEndTime(currentDate, meetTime) {
        let self = this;
        // return new Promise(async (resolve) => {
        let startT = new Date(currentDate);
        startT.setHours(Number(this.StartTimeObj.Hour), Number(this.StartTimeObj.Minuts)).toString();
        // this.Date = new Date(startT).getTime();
        let end: any = new Date(currentDate);
        end.setTime(startT.getTime() + (meetTime * 60 * 1000));
        this.endTimeView = this.addzero(end.getHours()) + ':' + this.addzero(end.getMinutes())
        this.meetDay = this.daysName[end.getDay()]
        this.endTime = end.toString();
        // resolve(true);
        // });
    }

    addzero(x) {
        // return new Promise(resolve => {
        x = x.toString();
        if (x.length == 1) {
            x = "0" + x;
        }
        return (x)
        // resolve(x);
        // });
    }


    defineEndTime() {
        let self = this;
        // return new Promise(async resolve => {
        let end: any = new Date(this.Date);
        end.setTime(end.getTime() + (Number(this.meetTime) * 60 * 1000));
        this.endTimeView = this.addzero(end.getHours()) + ':' + this.addzero(end.getMinutes())
        this.meetDay = this.daysName[end.getDay()]
        this.endTime = end.toString();
        // resolve(true);
        // })



    }

    // saveBuisHistory() {
    // var BarberHistory = {
    //     "barber": this.exportMeetAdmin(),
    //     'Mdate': this.meetDate,
    //     'userId': this.uId,
    //     'BarberID': this.BarberID,
    // }
    // this.afDb.object('/BarberShop/' + this.BarberID + "/History/" + this.key).set(BarberHistory);

    // }

    setUserMeet() {

        console.log('/Users/' + this.uId + "/meetings/" + this.BarberID + "/" + this.key, this.exportMeetUser());

        // if(!this.sendSms){
        //     this.sendSms == "false";
        // }
        let userRef = this.afDb.object('/Users/' + this.uId + "/meetings/" + this.BarberID + "/" + this.key);
        userRef.set(this.exportMeetUser()).then();

    }

    // saveForIndex(phone) {
    //     let userRef = this.afDb.object('/indexPhone/' + phone + "/meetings/" + this.BarberID + "/" + this.key);
    //     userRef.set(this.exportMeetUser()).then();
    // }


    removeFromAdmin() {
        this.afDb.object('/BarberShop/' + this.BarberID + "/Meetings/" + this.convertDateToYYYY(this.meetDate) + "/" + this.key).remove().catch()
    }

    // removeFromUser() {
    //     this.moveToHistory();
    //     this.afDb.object('/Users/' + this.uId + "/meetings/" + this.BarberID + "/" + this.key).remove().catch();
    // }

    // moveToHistory() {
    //     console.log('/Users/' + this.uId + '/History/' + this.key);
    //     this.afDb.object('/Users/' + this.uId + '/History/' + this.key)
    //         .update(this.exportMeetUser());
    // }
    updateToCancelAdmin() {
        console.log("updateToCancelAdmin")
        console.log('/BarberShop/' + this.BarberID + "/Meetings/" + this.convertDateToYYYY(this.meetDate) + "/" + this.key);
        return this.afDb.object('/BarberShop/' + this.BarberID + "/Meetings/" + this.convertDateToYYYY(this.meetDate) + "/" + this.key).update({
            meetStatus: 5,

        }).catch()
    }




    // updateToCancelUser() {

    //     this.removeReminder(this.uId);

    //     return this.afDb.object('/Users/' + this.uId + "/meetings/" + this.BarberID + "/" + this.key).update({
    //         meetStatus: 5
    //     }).catch();
    // }


    setAdminMeet() {
        let baraber = this.afDb.object('/BarberShop/' + this.BarberID + "/Meetings/" + this.convertDateToYYYY(this.meetDate) + "/" + this.key);
        baraber.set(this.exportMeetAdmin());
    }

    updateBarberMeet() {
        let nMeet = this.exportMeetAdmin();
        this.afDb.object('/BarberShop/' + this.BarberID + "/Meetings/" + this.convertDateToYYYY(this.meetDate) + "/" + this.key).update(nMeet);
    }
    updateUserMeet() {
        let nMeet = this.exportMeetUser();
        console.log('nMeet', nMeet)
        if (!nMeet.meetDay) {
            nMeet['meetDay'] = ''
        }
        this.afDb.object('/Users/' + this.uId + "/meetings/" + this.BarberID + "/" + this.key).update(nMeet).catch();
    }

    updateBarberMeetAnduser() {
        this.updateBarberMeet();
        if (this.uId != "localUserAdmin") {
            this.updateUserMeet()
        }

    }

    // newGuid() {
    //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    //         var r = Math.random() * 16 | 0,
    //             v = c == 'x' ? r : (r & 0x3 | 0x8);
    //         return v.toString(16);
    //     });
    // }
    newGuid() {
        let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });

        let time = new Date().getTime();
        return guid + time;
    }

    // new_guid() {
    //     let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-'.replace(/[xy]/g, function (c) {
    //         var r = Math.random() * 16 | 0,
    //             v = c == 'x' ? r : (r & 0x3 | 0x8);
    //         return v.toString(16);
    //     });

    //     let time = new Date().getTime();
    //     return guid + time;
    // }


    // removeReminder(newID) {
    //     this.afDb.object('/Reminders/' + newID).remove();
    // }









}