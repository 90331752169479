import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { ShopService } from 'src/ShopService/shop.service';
import { WalletServiceService } from '../wallet-service/wallet-service.service';
import { env } from 'src/app/app.module';
import { PaymentService } from 'src/app/payments/payment-service/payment.service';
import { WalletShowNewSalePage } from '../wallet-show-new-sale/wallet-show-new-sale.page';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireDatabase } from '@angular/fire/database';
import { ToastService } from 'src/providers/toast-service';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss'],
})
export class PaymentLinkComponent implements OnInit {

  @ViewChild('form', { static: false }) form;

  request_data;
  request_id;
  buis_logo;
  lottie_options: AnimationOptions = {
    path: '../assets/lottie/payed.json',
    loop: false
  };

  constructor(public shopS: ShopService,
    private afDb: AngularFireDatabase,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    public walletS: WalletServiceService,
    public ToastService: ToastService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      this.request_id = params.params.id;
      console.log('params!!!!!!', this.request_id);
      this.shopS.getPaymentRequestByID(this.request_id).then(res => {
        let val = res.val();
        console.log(val);
        if (val) {
          this.request_data = val;
          this.shopS.getBuisLogo(this.request_data.BarberID).then(res => {
            this.buis_logo = res.val();
          });
        }
      });

    });
  }

  generate_credit(method) {
    this.walletS.generate_sale.seller_payme_id = this.shopS.shopData.Wallet.wallet_id;
    this.walletS.generate_sale.userID = this.request_data.userID;
    this.walletS.generate_sale.sale_email = this.request_data.user_email;
    this.walletS.generate_sale.sale_mobile = this.request_data.user_phone;
    this.walletS.generate_sale.sale_name = this.request_data.user_name;
    this.walletS.generate_sale.cause = this.request_data.cause;
    this.walletS.generate_sale.sale_payment_method = method;
    this.walletS.generate_sale.sub_callback_url = env.cloudfunctions + "/payResposePayme";
    this.walletS.generate_sale.sale_price = parseInt(this.request_data.price) * 100;

    this.paymentService.new_iframe('generate-iframe-sale', this.walletS.generate_sale, respone => {
      console.log(respone);
      this.paymentService.transaction_id = respone.res.payme_sale_id;
      this.paymentService.transaction_data = respone.res;

      this.shopS.openModal(WalletShowNewSalePage, null, false, { iframe: respone.res.sale_url });

      this.paymentService.set_new_transaction_iframe(this.request_data.userID);
      this.listen_new_transaction_iframe();

    });
  }

  listen_new_transaction_subscribe;

  listen_new_transaction_iframe() {


    // console.log('transaction_id',this.transaction_id)
    // admin.database().ref('/BarberShop/'+data.BarberId+'/Payments/history/').child(BarberHistoyKey).set(data);
    this.listen_new_transaction_subscribe = this.afDb.object('/Transaction/' + this.paymentService.transaction_id).valueChanges().subscribe(val => {
      let newChange: any = val;
      console.log('newChange-child_changed', newChange);

      switch (newChange.status) {
        case 'open':

          break;

        case 'success':
          this.request_data.payment_status = 'completed';
          this.shopS.updatePaymentRequest(this.request_id, { payment_status: 'completed' });
          break;


        default:
          // סירוב
          // this.shopS.log('', 'payment_newCard_fail');

          // this.card_iframe_modal.dismiss();
          this.ToastService.showTostDanger('אירעה שגיאה');
          this.shopS.dismissModalController();
          // this.sendToIframe();
          break;
      }




    });


  }



  // generate(sale_payment_method) {
  //   this.loadingService.present_for(5000);
  //   if (this.request_data.sale_data.sale_type == "") {
  //     this.request_data.sale_data.sale_type = undefined;
  //   }
  //   this.request_data.sale_data.sale_payment_method = sale_payment_method;
  //   console.log(this.request_data);

  //   this.walletS.send_to_api('generate-sale', this.request_data.sale_data, val => {
  //     val = val.res;
  //     this.loadingService.dismiss();
  //     console.log('dsds', val)
  //     this.walletS.show_new_sale = val;
  //     this.shopS.openModal(WalletShowNewSalePage, null, false);
  //   })
  // }
}
