import { userService } from './../../ShopService/user.service';
import { AlertController, MenuController, ModalController } from '@ionic/angular';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';
import { Router } from '@angular/router';
import { AuthService } from '../../providers/auth-service';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { PopoverController } from '@ionic/angular';
import { PopoverComponent } from '../../app/popover/popover.component';
import { PaymentService } from "../../app/payments/payment-service/payment.service";
import { PremiumService } from "../../app/premium/premium-service/premium.service";

import { InvoiceServiceService } from "../../app/invoice/invoice-service/invoice-service.service";
import { SocialSharingPage } from 'src/components/social-sharing/social-sharing.page';
import { WalletServiceService } from 'src/app/wallet/wallet-service/wallet-service.service';
import { subscriptionLogsToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { Subscription } from 'rxjs';
import { SortlistService } from 'src/app/sortlist/sortlist-service/sortlist.service';
import { WalletPopoverComponent } from 'src/app/wallet-popover/wallet-popover.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.page.html',
  styleUrls: ['./clients.page.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: '0' })),
      state('*', style({ opacity: '1' })),
      transition('void <=> *', animate('150ms ease-in'))
    ])
  ]
})
export class ClientsPage implements OnInit, OnDestroy {
  trackByFn(index, item) {
    return index;
  }


  // product1 = {
  //   name: 'פרמיום לפוינט',
  //   price: 79,
  //   payType: 'subscription',
  //   BarberId: "64b0970a-ee94-4b96-91fc-94188e82ca56",
  //   BarberName: 'פוינט',
  //   BarberProfile: 'https://images.unsplash.com/photo-1593366299134-3ef7cd2abbb8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80',
  //   SubscriptionImg: 'https://i.ibb.co/hXrcCyT/Screen-Shot-2020-07-09-at-19-54-323231.png'
  // }



  transfers = [];
  showNot = false;

  product2 = {
    name: 'חד פעמי לפוינט',
    price: 1,
    payType: 'onetime',
    BarberProfile: 'https://images.unsplash.com/photo-1591436907060-6e1e1501cfad?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80',
    BarberId: "64b0970a-ee94-4b96-91fc-94188e82ca56",
    BarberName: 'פוינט'
  }

  constructor(
    public popoverController: PopoverController,
    public shopS: ShopService,
    public sortListS: SortlistService,
    private router: Router,
    private authService: AuthService,
    private MenuController: MenuController,
    public PaymentService: PaymentService,
    public PremiumService: PremiumService,
    private modalController: ModalController,
    public _InvoiceServiceService: InvoiceServiceService,
    public walletS: WalletServiceService,
    private us: userService
  ) {

  }

  // confirmUser(user) {
  //   user.confirm_status = 'confirm';
  //   this.checkNeedConfirm(this.shopS.shopData.Users);
  //   this.shopS.getNeedConfirmBuis(user).then(res => {
  //     let confirm_arr = res.val();
  //     if (confirm_arr) {
  //       confirm_arr = confirm_arr.filter(buis => buis != this.shopS.shopID);
  //       this.shopS.deleteBusiFromNeedConfirm(user, confirm_arr).then(() => {
  //         this.shopS.updateUser(user);
  //       })
  //     }
  //   });
  // }

  // notConfirmUser(user) {
  //   this.shopS.getNeedConfirmBuis(user).then(confirm_res => {
  //     console.log(confirm_res);
  //     let confirm_arr = confirm_res.val();
  //     if (confirm_arr) {
  //       confirm_arr = confirm_arr.filter(buis => buis != this.shopS.shopID);
  //       this.shopS.deleteBusiFromNeedConfirm(user, confirm_arr).then(() => {
  //         this.shopS.deleteUserFromBarber(user);
  //         this.shopS.getBizArray(user).then(biz_res => {
  //           let biz_arr = biz_res.val();
  //           if (biz_arr) {
  //             biz_arr = biz_arr.filter(buis => buis != this.shopS.shopID);
  //             this.shopS.deleteBusiFromBizArray(user, biz_arr);
  //           }
  //         });
  //       })
  //     }
  //   });
  // }

  sortModal = false;
  openSort() {
    this.sortModal = true;
  }

  closeSort() {
    this.sortModal = false;
  }

  ssss() {
    let meet_start = 1679975100000;
    let meet_end = 1679976900000;

    let windows = [ { end: 1679978700000, start: 1679976900000 } ];
    windows.forEach(window => {
      if (meet_start >= window.start && meet_end <= window.end || meet_end > window.start && meet_start <= window.start || meet_start < window.end && meet_end >= window.end ) {
        console.log("asdasdasd1");
      }

    });
  }

  asc = true;
  current_sort = 'fname';

  sort(sort_type, ev?) {
    console.log(sort_type)
    console.log(ev);
    ev ? ev.stopPropagation() : null;
    if (this.current_sort == sort_type) {
      this.asc = !this.asc;
    }
    this.current_sort = sort_type;
    let client_sort = {
      asc: this.asc,
      current_sort: sort_type
    }
    localStorage.setItem('client_sort', JSON.stringify(client_sort))
    let arr = this.usersData.filter(user => user[sort_type]);
    let arr2 = this.usersData.filter(user => !user[sort_type]);


    console.log(arr);

    if (sort_type == 'fname') {
      if (this.asc) {
        arr = arr.sort((a, b) => { return (a[sort_type].toLowerCase()) > (b[sort_type].toLowerCase()) ? 1 : -1 });
      } else {
        arr = arr.sort((a, b) => { return (a[sort_type].toLowerCase()) < (b[sort_type].toLowerCase()) ? 1 : -1 });
      }
    } else if (sort_type == 'registrationdate' || sort_type == 'lastSeen') {
      if (this.asc) {
        arr = arr.sort((a, b) => { return (new Date(a[sort_type]).getTime() ? new Date(a[sort_type]).getTime() : 0) - (new Date(b[sort_type]).getTime() ? new Date(b[sort_type]).getTime() : 0) });
      } else {
        arr = arr.sort((a, b) => { return (new Date(b[sort_type]).getTime() ? new Date(b[sort_type]).getTime() : 0) - (new Date(a[sort_type]).getTime() ? new Date(a[sort_type]).getTime() : 0) });
      }
    }
    this.usersData = arr.concat(arr2);
    this.search(this.searchText);
    console.log(this.usersData);
  }

  loading_more = false;
  scrollUsers(e) {
    // console.log((e.target.scrollHeight - e.target.offsetHeight) - e.target.scrollTop);

    if ((e.target.scrollHeight - e.target.offsetHeight) - e.target.scrollTop <= 500 && !this.loading_more && !this.searchText && this.usersDataDisplay.length < this.usersData.length) {
      // this.lazyLoad();
      console.log("ASDASD");
      this.loading_more = true;
      this.usersDataDisplay = this.usersDataDisplay.concat(this.usersData.slice(this.usersDataDisplay.length, this.usersDataDisplay.length + 50));
      console.log(this.usersDataDisplay);
      setTimeout(() => {
        this.loading_more = false;
      }, 200);
    }
  }

  // lazyLoad() {
  //   this.loading_more = true;
  //   let finish = false;
  //   let added = 0;
  //   this.historyMeet.forEach((month, ind) => {
  //     if (!finish) {
  //       if (!this.displayHistory.find(s => s.dates == month.dates)) {
  //         this.displayHistory.push({ dates: month.dates, meets: [] });
  //       }
  //       month.meets.forEach(meet => {
  //         if (added < 50 && !this.displayHistory[ind].meets.find(m => m.key == meet.key)) {
  //           this.displayHistory[ind].meets.push(meet);
  //           added++;
  //         }
  //         if (added >= 50) {
  //           finish = true;
  //           return;
  //         }
  //       });
  //     }
  //   });

  //   setTimeout(() => {
  //     this.loading_more = false;
  //   }, 200);

  //   console.log("displayHistory", this.displayHistory);
  // }

  getURL() {


    this.router.navigate(['/products'])


    //     let url= "https://firebasestorage.googleapis.com/v0/b/webcut-dev.appspot.com/o/a511c1d5-9249-41ff-83e9-c60d97ac7b3e?alt=media&token=19704bd3-3ee5-447d-bd9c-fc99f0bf1a78";
    //   //  let url= "https://firebasestorage.googleapis.com/v0/b/webcut-dev.appspot.com/o/a511c1d5-9249-41ff-83e9-c60d97ac7b3e";
    // console.log('url',url)
    //     this.shopS.get_file_from_url(url, res=>{
    //       console.log(res)
    //     })


    //   let d =  this.detectCardType('458003******5244')
    // console.log(d)
  }

  detectCardType(number) {

    // Visa: 49,44 or 47
    // Visa electron: 42, 45, 48, 49
    // MasterCard: 51
    // Amex:34
    console.log(number[0] + number[1])
    switch (number[0] + number[1]) {
      case '45':
        return 'visa'
        break;

      default:
        break;
    }

  }


  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  public trackItem(index: number, item) {
    console.log('index', index);
    console.log('item', item);
    return item.trackId;
  }

  doRefresh(event) {
    console.log('Begin async operation');
    console.log('this.shopS.shopData.Users', this.shopS.shopData.Users);

    //this.loadUserData();
    setTimeout(() => {
      console.log('Async operation has ended');

      event.target.complete();
    }, 1000);
  }

  logout() {
    this.authService.logout();
  }
  onScrollUp() {
    console.log('onScrollUpsxrollllled');

  }
  onScroll() {
    console.log('sxrollllled');

  }

  search(str) {
    if (str) {
      this.usersDataDisplay = this.usersData.filter(user => (user.fname + ' ' + user.lname).includes(str));
    } else {
      this.usersDataDisplay = this.usersData.slice(0, 50);
    }
  }


  ionViewWillEnter() {

    this.shopS.loaderoff = false;

    if (this.shopS.searchFocusFlag == true) {
      console.log('----Flag true!!!!');
    }

    // this.shopS.userStatus();


    //    console.log("his.shopS.shopData.Users",this.shopS.shopData.Users);
    //    console.log("his.shopS.shopData",this.shopS.shopData);
    //   var userS=this.shopS.json2array(this.shopS.shopData.Users);
    //    console.log("userS",userS);

    //     this.shopS.emptyClientsFLAG = userS.length;
    //     console.log("this.shopS.emptyClientsFLAG",this.shopS.emptyClientsFLAG);

  }

  aaa() {
    this.showNot = !this.showNot;
    console.log(this.us.user);

  }

  searchText: any = '';
  modalFLAG = false;
  getUserData;

  goTOaddContacts() {
    this.router.navigate(['tabsAdmin/contacts']);
  }


  // loadUserData() {
  //   var userS = this.shopS.json2array(this.shopS.shopData.Users);
  //   this.shopS.emptyClientsFLAG = userS.length;

  //   userS.forEach(element => {
  //     var el: any = element;
  //     if (!el.local) {
  //       this.getUserData = this.shopS.getUserData(el.key).valueChanges().subscribe(x => {
  //         console.log("xxxxxxxx-------xxxxxxxx0", x);

  //         this.shopS.updateUserOnBarberAdmin(x, el.key);

  //       })


  //       console.log("element", element)
  //     }

  //   });
  // }
  OnDestroy() {
    this.getUserData.unsubscribe();
  }
  usersData = [];
  usersDataDisplay = [];
  businessSub: Subscription;
  usersSub: Subscription;
  transSub: Subscription;
  totalTransfers = [];
  // needConfirm = false;
  // usersData = [];
  // displayUsersData = [];

  ngOnInit() {
    // this.businessSub = this.authService.businessLoaded.subscribe(status => {
    //   if (status) {
    //     this.totalTransfers = this.shopS.json2array(this.shopS.shopData.paymentTransfers);
    //     this.transfers = this.totalTransfers.filter(trans => !trans.seen);
    //   }
    // });
    let sort = JSON.parse(localStorage.getItem('client_sort'));
    console.log(sort);
    if (sort) {
      this.asc = sort.asc;
      this.current_sort = sort.current_sort;
    }

    this.usersSub = this.shopS.usersDataChange.subscribe((users: any) => {
      let arr: any = this.shopS.json2array(users);
      if (users) {
        arr.forEach(user => {
          user.lastSeen = user.lastSeen ? user.lastSeen : '';
          user.registrationdate = user.registrationdate ? user.registrationdate : '';
          user.fname = user.fname ? user.fname : '';
          // console.log(new Date(user.lastSeen).getTime());

        })
        this.usersData = arr;
        this.shopS.checkNeedConfirm(users);
        this.sort(this.current_sort);
        // this.search(this.searchText);
        console.log(this.usersData);
      }
    });


    //alin delete
    // this.businessSub = this.authService.businessLoaded.subscribe(status => {
    //   console.log(status);

    //   if (status) {
    //     this.shopS.getPaymentRequests('BarberID', this.shopS.shopData.Setting.BarberID).then(res => {
    //       let requests = res.val();
    //       console.log(res.val());
    //       this.totalTransfers = this.shopS.json2array(requests);
    //       this.transfers = this.totalTransfers.filter(trans => !trans.seen && (trans.payment_status == 'completed' || trans.payment_status == 'refused'));

    //       this.transSub = this.shopS.paymentRequestsChanges.subscribe(trans => {
    //         console.log(trans);

    //         if (trans) {
    //           this.transfers.push(trans);
    //           this.shopS.reRender();
    //         }
    //       });
    //     })
    //   }
    // });
  }

  // checkNeedConfirm(users) {
  //   let need_confirm = false;
  //   let usersData = this.shopS.json2array(users);
  //   if (usersData.length) {
  //     usersData.forEach(user => {
  //       if (user.confirm_status == 'pending') {
  //         need_confirm = true;
  //       }
  //     });
  //   }
  //   this.needConfirm = need_confirm;
  // }

  confirmTransfer() {
    // let transfer = this.transfers[this.currentTrans];
    // this.shopS.confirmTransform(this.shopS.shopData.Setting.BarberID, transfer.key).then(val => {
    //   console.log(val);
    //   this.transfers = this.transfers.filter(req => req.key != transfer.key);
    // });
    let transfer = this.transfers[this.currentTrans];
    this.shopS.updatePaymentRequest(transfer.key, { seen: true }).then(val => {
      this.transfers = this.transfers.filter(req => req.key != transfer.key);
      // if (transfer.meet) {

      // }

    });
  }

  requestPayment() {
    if (this.walletS.check_user_wallet()) {
      this.walletS.openModal('request', {user: this.shopS.currentMeet.user})
    } else {
      this.shopS.openModal(WalletPopoverComponent, 'modalLookfullWidth', false, null);
    }
  }

  currentTrans = 0;

  nextTrans() {
    if (this.currentTrans < this.transfers.length - 1) {
      this.currentTrans++;
    }
  }

  prevTrans() {
    if (this.currentTrans != 0) {
      this.currentTrans--;
    }
  }

  newLine(event, u) {
    event.stopPropagation();
    console.log('newLine-u', u);
    this.shopS.currentMeet.user = u;
    this.shopS.ClientmodalFLAG = false;
    //this.router.navigate(['tabsAdmin/add']);

    //this.shopS.addLineFLAG = true;

    this.shopS.plusAnimation();

  }

  openProfile(event, u) {
    event.stopPropagation();
    console.log('openProfile-u', u);
    this.shopS.SelectedUser = u;

    this.toggleModal(event);
  }

  toggleModal(event) {
    event.stopPropagation();
    this.shopS.ClientmodalFLAG = !this.shopS.ClientmodalFLAG;
    console.log('toggleModal=======this.shopS.ClientmodalFLAG', this.shopS.ClientmodalFLAG);
  }

  toggleModaladdClientmodalFLAG(event) {
    event.stopPropagation();
    this.resetUsers()
    this.shopS.addClientmodalFLAG = !this.shopS.addClientmodalFLAG;
    console.log('toggleModal=======this.shopS.addClientmodalFLAG', this.shopS.addClientmodalFLAG);
  }

  // removeOldMeer() {
  //   this.shopS.removeOldMeer();
  // }
  toggleModalShare(event) {
    event.stopPropagation();
    // this.shopS.SharemodalFLAG = !this.shopS.SharemodalFLAG;
    // console.log('toggleModal=======this.shopS.SharemodalFLAG',this.shopS.SharemodalFLAG);
    this.toggleModalShare_new(event);
  }


  async toggleModalShare_new(event) {

    const modal = await this.modalController.create({
      component: SocialSharingPage,
      swipeToClose: true,
      cssClass: 'popupModal',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",

      // Get the top-most ion-modal

    });


    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data) {
      if (data.dismissed == "finish") {

      }
    }
    return modal;

  }



  resetUsers() {

    this.shopS.userUpdated = {
      fname: '',
      lname: '',
      ProfilePic: "",
      phone: '',
      key: "",
      local: "1",

    }
  }

  ngOnDestroy(): void {
    if (this.businessSub) {
      this.businessSub.unsubscribe();
    }
    if (this.usersSub) {
      this.usersSub.unsubscribe();
    }
    if (this.transSub) {
      this.transSub.unsubscribe();
    }
    // this.transSub.unsubscribe();
  }

}
