import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

//import { PricePlansPageRoutingModule } from './price-plans-routing.module';

import { PricePlansPage } from './price-plans.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
   // PricePlansPageRoutingModule
  ],
  declarations: [PricePlansPage],
  entryComponents: [PricePlansPage]
})
export class PricePlansPageModule {}
