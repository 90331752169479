import {Platform } from '@ionic/angular';
import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';

import { ModalController } from '@ionic/angular';
import { CryptoService } from "../../../app/crypto.service";
import { ToastService } from "../../../providers/toast-service";
import { LoadingService } from "../../../providers/loading.service";
import { Http, Response } from '@angular/http';
// import { BehaviorSubject } from 'rxjs';
// import { promise } from 'protractor';
import { userService } from '../../../ShopService/user.service';
// import { formatDate } from "@angular/common";
import { ShopService } from '../.././../ShopService/shop.service';
// import { FingerprintAIO,FingerprintOptions} from "@ionic-native/fingerprint-aio/ngx";
import { InvoiceNewDocumentComponent } from "../invoice-new-document/invoice-new-document.component";

// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';


// import { File } from '@ionic-native/file/ngx';
// import { FileOpener } from '@ionic-native/file-opener/ngx';

import { InvoiceTemplateService } from "../invoice-template-service/invoice-template.service";
import { AlertController } from '@ionic/angular';
import { SetupInvoiceSupplierComponent } from '../setup-invoice-supplier/setup-invoice-supplier.component';
import { InvoiceViewDocumentComponent } from '../invoice-view-document/invoice-view-document.component';
import { Router } from '@angular/router';
import { InvoiceSettingsModalComponent } from '../invoice-settings-modal/invoice-settings-modal.component';

const pdfMake:any = {}

@Injectable({
  providedIn: 'root'
})
export class InvoiceServiceService {

  constructor(
    public afDb: AngularFireDatabase,
    private modalCtrl: ModalController,
    private Firestore:AngularFirestore,
    private _http: Http,
    public CryptoService:CryptoService,
    public ToastService:ToastService,
    public LoadingService:LoadingService,
    public userService:userService,
    public shopS:ShopService,
    // private faio: FingerprintAIO,
    public templateS:InvoiceTemplateService,
    private plt: Platform,
    //  private file: File,
      // private fileOpener: FileOpener,
      private router: Router,
      public alertController: AlertController
  ) { }
  invoice_supplier_img = '';
  invoice_supplier_pic(){
   let obj = this.invoice_supplier.find(val=> val.name == this.shopS.shopData.Setting.invoice_supplier)
    return obj['img'];
  }

  invoice_supplier = [
    {
      rigions: ['IL'],
      img: '../../assets/invoice/greeninvoice.svg',
      name: 'Greeninvoice',
      type: 'id_secret'
    },
    {
      rigions: ['IL'],
      img: '../../assets/invoice/icont.svg',
      name: 'Icount',
      type: 'user_pass'
    },
    // {
    //   rigions: ['IL'],
    //   img: '../../assets/invoice/ezcount-logo.png',
    //   name: 'EZcount',
    //   type: 'id_secret'
    // },
  ]


  default_auto_document:any;


  logout_from_invoice(){
    this.shopS.shopData.Setting['invoice_supplier'] = null;
      this.afDb.object('/BarberShop/' + this.shopS.shopData.Setting.BarberID +'/Setting' ).update({invoice_supplier:   null});
      this.router.navigate(['tabsAdmin/clients'], { replaceUrl: true });
  }


  set_invoice_supplier(invoice_supplier_name){
    this.afDb.object('/BarberShop/' + this.shopS.shopData.Setting.BarberID +'/Setting' ).update({invoice_supplier:   invoice_supplier_name});
  
  }
  
  async try_auth(invoice_supplier,auth,cb){

    const UserDoc = this.Firestore.collection('Businesses').doc(this.shopS.shopData.Setting.BarberID);
    
    UserDoc.get().subscribe(doc => {
      let data:any;
      if (doc.exists) {
        console.log('Document data:', doc.data());
        data = {
          invoice: {
            invoice_supplier: invoice_supplier.name,
            api: auth
          }
        }
        UserDoc.update(data);
      } else {
        console.log('No such document!');
        data = this.shopS.shopData.Setting;
        data['invoice'] = {
          invoice_supplier: invoice_supplier.name,
          api: auth
        }
        UserDoc.set(data);
      }
    
    
    });// end of get collection subscribe;

  console.log('auth',auth);
      
    var newSend = this.CryptoService.Encrypt({BarberID:this.shopS.shopData.Setting.BarberID,invoice_supplier:invoice_supplier,auth:auth});
    // 'http://localhost:5001/webcut-dev/us-central1'
    // this.shopS.serverDev
    
    this._http.post(this.shopS.serverDev + '/invoice_try_auth',newSend).subscribe(val=>{
      cb(JSON.parse(val['_body']))
    });
    
    }
    

// async try_auth_oldddd(invoice_supplier,auth){

// const UserDoc = this.Firestore.collection('Businesses').doc(this.shopS.shopData.Setting.BarberID);

// UserDoc.get().subscribe(doc => {
//   let data:any;
//   if (doc.exists) {
//     console.log('Document data:', doc.data());
//     data = {
//       invoice: {
//         invoice_supplier: invoice_supplier.name,
//         api: auth
//       }
//     }
//     UserDoc.update(data);
//   } else {
//     console.log('No such document!');
//     data = this.shopS.shopData.Setting;
//     data['invoice'] = {
//       invoice_supplier: invoice_supplier.name,
//       api: auth
//     }
//     UserDoc.set(data);
//   }


// });// end of get collection subscribe;
// var newSend = this.CryptoService.Encrypt({user:this.shopS.shopData.Setting.BarberID});

// this._http.post(this.shopS.serverDev + '/invoice_try_auth',newSend).subscribe(val=>{
//   console.log('val',val);
// });

// }



check_user_invoice(){

  if(this.shopS.shopData.Setting.invoice_supplier){
    return true;
  }else{
    // alert('no invoice_supplier');
    this.setup_invoice_supplier();
    return false;
  }


}





////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
settings_modal;
async open_settings_modal(): Promise<HTMLIonModalElement> {
  this.settings_modal = await this.modalCtrl.create({
    component: InvoiceSettingsModalComponent,
    swipeToClose: true,
    cssClass: 'modalForPaymentsChangeSubscriptionCard',
    //  presentingElement: this.routerOutlet.nativeEl,
    mode: "ios",
    // Get the top-most ion-modal
  });
  await this.settings_modal.present();
  const { data } = await this.settings_modal.onWillDismiss();
  if (data) {
    if (data.dismissed == "finish") {
    }
  }
  return this.settings_modal;
}




async setup_invoice_supplier(){
    
  var modal = await this.modalCtrl.create({
    component: SetupInvoiceSupplierComponent,
    swipeToClose: false,
    cssClass: 'modalLookLikeNativ',
    backdropDismiss:false,
    mode:"ios"
  });

  await modal.present();
  modal.onDidDismiss().then(res=>{
    console.log(res);
    if(res.data){

  }
  })
  return modal;
}


////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



//search_document
invoice_api(type,data,cb){
  if(this.shopS.shopData.Setting.BarberID){

  let data_to_send = {BarberID:this.shopS.shopData.Setting.BarberID,type:type,data:data};
  console.log('--------DATA TO SEND',data_to_send);
  

  var newSend = this.CryptoService.Encrypt(data_to_send);
  let server='http://localhost:5001/webcut-dev/us-central1';
  // this.shopS.serverDev
  this._http.post(this.shopS.serverDev + '/invoice_api',newSend).subscribe(val=>{
      console.log('val',val);
      cb(JSON.parse(val['_body']))
  });

} 
 }







////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////


async create_new_doc(data) {

  console.log('data',data);

      
  
        this.invoice_api('add_document',data,cb=>{
console.log('cbcbcbcbcb',cb)
  if(cb.status || cb.signed){
    this.loader_message = 'המסמך הופק בהצלחה';
            if(this.shopS.shopData.Setting.invoice_supplier == 'Icount'){
              this.invoiceObj['document_counter']={};
              this.invoiceObj['document_counter']['counter']=cb.docnum;
              this.invoiceObj['file'] = cb.doc_url
            }
            if(this.shopS.shopData.Setting.invoice_supplier == 'Greeninvoice'){
            // this.invoiceObj = cb;
            this.invoiceObj['file'] = cb.url.origin;
            }
            this.document_succsess_popup = true;
            this.invoice_loader = false;
            this.shopS.turnOnConfetti(10000);
            
            this.modal_new_doc.dismiss();
          }else{
            this.loader_message = 'אופס משהו השתבש';
          //  this.document_succsess_popup = false;
            this.invoice_loader = false;
            // this.shopS.turnOnConfetti(10000);
            this.shopS.showfailToast(this.loader_message)
            this.modal_new_doc.dismiss();
            this.modal_inoice_popup.dismiss();

          }
        


        })



}






































  async createPdf_from_obj(data) {

    let current_doc_template:any = this.templateS.getTemplate(data);

    current_doc_template.defaultStyle = {
      font: 'Rubik',
      alignment: 'right',
    }

    pdfMake.fonts = {
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      },
      Rubik: {
        normal: 'https://cdn.jsdelivr.net/npm/rubik-font@0.0.3/fonts/Rubik-Regular.ttf',
        bold: 'https://cdn.jsdelivr.net/npm/rubik-font@0.0.3/fonts/Rubik-Bold.ttf',
        italics: 'https://cdn.jsdelivr.net/npm/rubik-font@0.0.3/fonts/Rubik-Italic.ttf',
        bolditalics: 'https://cdn.jsdelivr.net/npm/rubik-font@0.0.3/fonts/Rubik-BoldItalic.ttf'

      },
      
    }
    var pdfObj = pdfMake.createPdf(current_doc_template);

    pdfObj.getBuffer((buffer) => {

      console.log('buffer!!!!1',buffer)
 this.shopS.check_send_pdf_sign(buffer,cb=>{
        console.log('cb',cb)
        let signed_Buffer = new Uint8Array(cb.data);
        console.log('signed_Buffer',signed_Buffer)

      })

      
    });



    // if (this.plt.is('cordova')) {
    //   pdfObj.getBuffer((buffer) => {


    //     console.log('buffer!!!!1',buffer)


    //     var blob = new Blob([buffer], { type: 'application/pdf' });

    //     // Save the PDF to the data Directory of our App
    //     this.file.writeFile(this.file.dataDirectory, 'myletter.pdf', blob, { replace: true }).then(fileEntry => {
    //       // Open the PDf with the correct OS tools
    //       this.fileOpener.open(this.file.dataDirectory + 'myletter.pdf', 'application/pdf');
    //     })
    //   });
    // } else {
    //   // On a browser simply use download!
    //   pdfObj.download();
    // }
  }









async view_document(c){
    console.log('c',c)


  this.invoiceObj = c;
  console.log('this.invoiceS.invoiceObj',this.invoiceObj)




  this.modal_view_doc = await this.modalCtrl.create({
    component: InvoiceViewDocumentComponent,
    swipeToClose: false,
    cssClass: '',
    backdropDismiss:false,
    mode:"ios"
  });

  

  await this.modal_view_doc.present();
  this.modal_view_doc.onDidDismiss().then(res=>{
    console.log(res);
  
  })
  return this.modal_view_doc;
}
















  new_document_from_exist(){
  //  console.log();
    this.new_document(this.document_count[this.invoiceObj.document_counter_type_key]);
    this.modal_view_doc.dismiss();
    
    
  }


  new_document_from_meet(meet){
    console.log('meet',meet);
    console.log('this.invoiceObj.document_counter_type_key',this.invoiceObj.document_counter_type_key);

    this.invoiceObj.document_counter = this.shopS.shopData.Setting.invoice_default_auto_document
  //   if(this.shopS.shopData.Setting.invoice_supplier == 'Icount'){
  //     if(true){ // buis PATUR 
  //     this.invoiceObj.document_counter = {
  //       id:"invoice",
  //       name: "חשבונית מס"
  //   }
  // }else{
       
  //   this.invoiceObj.document_counter = {
  //     id: "receipt",
  //     name: "קבלה"
  // }
  // }


    // }
    // if(this.shopS.shopData.Setting.invoice_supplier == 'Greeninvoice'){
    //   this.invoiceObj.document_counter = {
    //     id: 305,
    //     name: "חשבונית מס"
    //   }
    // }

    
    this.invoiceObj['meet_inv'] = {
      type: 'meet',
      status: 'invoice',
      meet_data:{
        BarberID: meet.BarberID,
        meet_id: meet.key,
        meet_date_path: this.shopS.meetForBuis.convertDateToYYYY(meet.meetDateFormat),
        userid: meet.uId,
      }
    }


    this.invoiceObj.services.push({
      name: meet.meetType.name,
      quantity:1,
      price: meet.meetType.price
    })
    if(meet.uIdData.fname){
    this.invoiceObj.client = {
      ProfilePic: meet.uIdData.ProfilePic,
      fname:meet.uIdData.fname,
      lname:meet.uIdData.lname,
      name: meet.uIdData.fullname,
    }
  }


  this.invoiceObj['total_amount'] =   this.invoiceObj.services.map(item => (item.price*item.quantity)).reduce((prev, next) => prev + parseInt(next) ); ;
  this.invoiceObj['total_price'] = this.invoiceObj['total_amount'] - (this.invoiceObj['total_amount'] * 0.17);;
  this.invoiceObj['total_vat'] = this.invoiceObj['total_amount'] -  this.invoiceObj['total_price'];

  this.invoiceObj['date_of_issue'] = new Date(  ).getTime();
  this.invoiceObj['date_to_pay'] = new Date(  ).getTime();
  this.invoiceObj['date_of_creation'] =  new Date().getTime();


    console.log('this.invoiceObj',this.invoiceObj);

    // document_counter:
// id: "refund"
// name: "חשבונית זיכוי"

// services: Array(1)
// 0:
// id: "cc9779b0-0ac6-4bd2-aaf9-3bc87be7dc28"
// name: "דשךגשדגש"
// price: 22
// quantity: 2
console.log('this.document_count',this.document_count);



this.new_document(this.invoiceObj.document_counter);
    // this.modal_view_doc.dismiss();
  }


  async new_document(c){
    
    this.invoiceObj.document_counter = c;
  
    this.modal_new_doc = await this.modalCtrl.create({
      component: InvoiceNewDocumentComponent,
      swipeToClose: false,
      cssClass: '',
      backdropDismiss:false,
      mode:"ios"
  
    //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
  
      
     // Get the top-most ion-modal
  
    });
  
    
  
    await this.modal_new_doc.present();
    this.modal_new_doc.onDidDismiss().then(res=>{
      console.log(res);
      this.invoiceObj.services = []
      if(res.data){
  
    }
    })
    return this.modal_new_doc;
  }








  invoiceObj:any = {
    document_counter:{},
    date: {
      date_of_issue : '',
      date_to_pay: '',
      date_of_creation: ''
    },
    lang: 'he',
    currency: '₪',
    client: {
        fname:'',
        lname: '',
         name:'',
         ProfilePic:'',
    },
    discription: '',
    services: [],
    notes: '',
    footer_note: '',  
  }


  userSettings:any = {
    template: 0,
    business_name: 'שם העסק',
    business_address: 'רחוב 14 בת עם',
    business_phone: 'רחוב 14 בת עם',
    business_email: 'email@gmail.com',
    business_type: 'עוסק מורשה',
    business_number: '58944324',
    color: '#34343434',    
  }

  document_counting_set_default(){
    const default_data:any = [
      {
        key: 0,
        name: 'הצעת מחיר',
        counter: 1,
        didhappen: false
      },  
      {
        key: 1,
        name: 'הזמנה',
        counter: 10001,
        didhappen: false
      },
      {
        key: 2,
        name: 'תעודת משלוח',
        counter: 20001,
        didhappen: false
      },
      {
        key: 3,
        name: 'תעודת החזרה',
        counter: 30001,
        didhappen: false
      },
      {
        key: 4,
        name: 'חשבון עסקה',
        counter: 40001,
        didhappen: false
      },
      {
        key: 5,
        name: 'חשבונית מס',
        counter: 50001,
        didhappen: false
      },
      {
        key: 6,
        name: 'חשבונית מס / קבלה',
        counter: 60001,
        didhappen: false
      },
      {
        key: 7,
        name: 'חשבונית זיכוי',
        counter: 70001,
        didhappen: false
      },
      {
        key: 8,
        name: 'קבלה',
        counter: 80001,
        didhappen: false
      },
      {
        key: 9,
        name: 'הזמנת רכש',
        counter: 90001,
        didhappen: false
      },
  ];
    this.afDb.object('/Invoice/' + this.shopS.shopData.Setting.BarberID + '/document_counting/').update(default_data)

  }// end of - document_counting_set_default



  document_add(data){
  delete data.pdfObj;
   data.client['meets'] = ''
    this.afDb.list('/Invoice/' + this.shopS.shopData.Setting.BarberID + '/documents/').push(data)
  }

  documents:any = [];
  drafts:any = [];

document_get(){
  this.afDb.list('/Invoice/' + this.shopS.shopData.Setting.BarberID + '/documents/').valueChanges().subscribe(val => {
    console.log('val',val)
    this.documents = val;
    this.set_document_array(val)
  });
}


draft_get(){
  this.afDb.list('/Invoice/' + this.shopS.shopData.Setting.BarberID + '/dtafts/').valueChanges().subscribe(val => {
    console.log('val',val)
    this.drafts = val;
  });
}


  document_add_draft(data){
    this.afDb.list('/Invoice/' + this.shopS.shopData.Setting.BarberID + '/drafts/').push(data)
  }


  document_counting_update(data){
    this.afDb.object('/Invoice/' + this.shopS.shopData.Setting.BarberID + '/document_counting/'+ data.key).update(data)
  }


  business_settings_update(data){
    console.log('data',data)
    this.afDb.object('/Invoice/' + this.shopS.shopData.Setting.BarberID + '/business_settings/').update(data)
  }

 


  business_settings_get_data_oldd(){
    this.afDb.object('/Invoice/' + this.shopS.shopData.Setting.BarberID + '/business_settings/').valueChanges().subscribe(val => {
      console.log('val',val)
      this.userSettings = val;
    });
  }

  document_count;

async  document_counting_get_data(){
    this.afDb.list('/Invoice/' + this.shopS.shopData.Setting.BarberID + '/document_counting/').valueChanges().subscribe(val => {
      console.log('val',val)
      this.document_count = val;
    });
  }



////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

document_array:any = [];
monthsList= ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];


get_total_revenue_from_key(val){
  //console.log('val');
var totalSum = 0;

  val.forEach(element => {

    totalSum += parseInt( element.total_amount);
    // switch (element.document_counter_type_key) {
    //   case 5:
    //   case 6:
    //   case 8:
       
    //   break;

    //   case 7:
    //     totalSum -= element.total_amount;
    //   break;

    //   default:
    //     break;
    // }
  });
  
  
  return totalSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '₪' ;

}


get_month_from_key(key){
//console.log(key)
let s = key.split('.');
return this.monthsList[parseInt(s[0])];
}

get_year_from_key(key){
 // console.log(key)
  let s = key.split('.');
  return s[1];
  }


  set_document_array(val){
    var document_obj = {};
    val.forEach(element => {



      // add document
      if(document_obj[new Date(element.date_of_issue).getMonth()+'.'+new Date(element.date_of_issue).getFullYear()]){
        document_obj[new Date(element.date_of_issue).getMonth()+'.'+new Date(element.date_of_issue).getFullYear()].push(element);
      }else{
        document_obj[new Date(element.date_of_issue).getMonth()+'.'+new Date(element.date_of_issue).getFullYear()] = [];
        document_obj[new Date(element.date_of_issue).getMonth()+'.'+new Date(element.date_of_issue).getFullYear()].push(element);
      }
      console.log(element.date_of_issue)
      console.log(new Date(element.date_of_issue).getMonth())
    });
    console.log(document_obj);

    this.document_array = this.shopS.json2array(document_obj);
    console.log('this.document_array',this.document_array)
  }


////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////















////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////



document_succsess_popup = false;
invoice_loader = true;
loader_message = 'מפיק מסמך';

modal_new_doc;
modal_view_doc
modal_inoice_popup;



















async createPdf(data) {

  console.log('data',data);
var logo;
  this.shopS.get_file_from_url(data.settings.logo, res=>{
    console.log('logo:   ',res)
    data.settings.logo = res;

    this.shopS.get_file_from_url(data.settings.signature, res=>{
      console.log('signature:   ',res)
      data.settings.signature = res;






      let current_doc_template:any = this.templateS.getTemplate(data);
      console.log('current_doc_template',current_doc_template)
        current_doc_template.defaultStyle = {
          font: 'Rubik',
          alignment: 'right',
        }
      
        pdfMake.fonts = {
          Roboto: {
            normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
            bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
            italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
            bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
          },
          Rubik: {
            normal: 'https://cdn.jsdelivr.net/npm/rubik-font@0.0.3/fonts/Rubik-Regular.ttf',
            bold: 'https://cdn.jsdelivr.net/npm/rubik-font@0.0.3/fonts/Rubik-Bold.ttf',
            italics: 'https://cdn.jsdelivr.net/npm/rubik-font@0.0.3/fonts/Rubik-Italic.ttf',
            bolditalics: 'https://cdn.jsdelivr.net/npm/rubik-font@0.0.3/fonts/Rubik-BoldItalic.ttf'
      
          },
          
        }
        let pdfObj = pdfMake.createPdf(current_doc_template);
      
      
      this.invoiceObj['pdfObj'] = pdfObj;
      console.log('this.invoiceObj',this.invoiceObj)
      

      pdfObj.getBuffer((buffer) => {
        this.loader_message = 'חותם מסמך';
        this.shopS.check_send_pdf_sign(buffer,cb=>{
          console.log('cb',cb)
            let signed_Buffer = new Uint8Array(cb.data);
            console.log('signed_Buffer',signed_Buffer)


          var blob = new Blob([signed_Buffer], { type: 'application/pdf' });
          let date_path= data.date.date_of_issue.split('.');
          let path = 'invoice/'+ this.shopS.shopData.Setting.BarberID + '/'+date_path[2]+'/'+date_path[1]+'/';
          let file_name = data.document_counter.counter + '.pdf';
          
          let fullpath = path+file_name;
  
  
  
          this.shopS.upload_img(blob,fullpath,cb=>{
            
            console.log('file',cb)
            this.invoiceObj['file']= cb;
            this.invoiceObj.settings = this.userSettings;
  
            this.document_add(this.invoiceObj);
  
            this.update_document_counting_num();
            this.document_succsess_popup = true;
            this.invoice_loader = false;
            this.shopS.turnOnConfetti(10000);
            
            this.modal_new_doc.dismiss();
            

      
          });// end of  upload_img






        })// end of  check_send_pdf_sign


     
     
     
     
     
      });  // end of  get_file_from_url



     // setTimeout(() => {
    //  }, 3500);
      
      












    })//get_file_from_url
  








  })

 

console.log('end')



}


update_document_counting_num(){
  this.document_count[this.invoiceObj.document_counter.key].counter = this.invoiceObj.document_counter.counter;
  console.log('this.document_count',this.document_count);

  if(!this.document_count[this.invoiceObj.document_counter.key].didhappen){
   
    this.document_count[this.invoiceObj.document_counter.key].didhappen = true;
  }

this.document_counting_update(this.document_count[this.invoiceObj.document_counter.key])
  

}

downloadFileJavascript() {
  // Create an invisible A element
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = this.invoiceObj.file

  // Use download attribute to set set desired file name
  a.setAttribute("download", this.invoiceObj.document_counter.counter +'.pdf');

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}
download_pdfObj(){
  let pdfObj = this.invoiceObj.pdfObj;

  if (this.plt.is('cordova')) {
    // pdfObj.getBuffer((buffer) => {
    //   var blob = new Blob([buffer], { type: 'application/pdf' });

    //   // Save the PDF to the data Directory of our App
    //   this.file.writeFile(this.file.dataDirectory, this.invoiceObj.document_counter.counter+'.pdf', blob, { replace: true }).then(fileEntry => {
    //     // Open the PDf with the correct OS tools
    //     this.fileOpener.open(this.file.dataDirectory + this.invoiceObj.document_counter.counter+'.pdf', 'application/pdf');
    //   })
    // });
  // this.fileOpener.open(this.invoiceObj.file, 'application/pdf');

  } else {
    // On a browser simply use download!
    window.open(this.invoiceObj.file, 'Download');
   // this.downloadFileJavascript()
  //  pdfObj.download(this.invoiceObj.document_counter.counter+'.pdf');
  }
}


to_valid_date(date){
  let s = date.split('.');
  return s[2]+'-'+s[1]+'-'+s[0];
}



















}
