
import { EventClass } from './../app/event-class';
import { CryptoService } from 'src/app/crypto.service';
import { ModalService } from './../app/modal.service';
import { PremiumClass } from './../app/premium/premium-class';
import * as moment from 'moment-timezone';

import { BuisMeet } from './../app/buis-meet';

import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { finalize, catchError, tap } from 'rxjs/operators';

import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';

import * as Rx from "rxjs";
// import 'rxjs/add/operator/map';
// import { map } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AlertController, MenuController, ModalController, Platform } from '@ionic/angular';


import { meetService } from './meetS.service';
import { userService } from './user.service';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { reduce } from 'rxjs-compat/operator/reduce';
// import undefined = require('firebase/empty-import');
// import undefined = require('firebase/empty-import');
import { LoadingService } from '../providers/loading.service';
import { ToastController } from '@ionic/angular';
import { SmsService } from 'src/providers/sms.service';
import { SortlistService } from 'src/app/sortlist/sortlist-service/sortlist.service';
import { env } from 'src/app/app.module';
import { SwichmodalComponent } from 'src/app/swichmodal/swichmodal.component';
import { SomethingwentwrongComponent } from 'src/app/somethingwentwrong/somethingwentwrong.component';
import { LogService } from 'src/app/log.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { log } from 'console';
// import { ToastService } from '../providers/toast-service';


import * as firebase from 'firebase/app';
import { isArray } from 'rxjs/internal-compatibility';

import { InAppBrowser, InAppBrowserObject } from '@ionic-native/in-app-browser/ngx';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { FullscreenAlertComponent } from 'src/app/fullscreen-alert/fullscreen-alert.component';
import { PremiumLockComponent } from 'src/app/premium/premium-lock/premium-lock.component';

declare var window: any;


@Injectable()

export class ShopService {

  download_url = 'https://point-app.net/download/';
  // choosePackageModal = false;
  noUserModal = false;
  googlemapInputValue = '';
  scrolling_now = false;
  timeZoneDiff = false;
  skeleton_lines = true;
  current_pick_day;
  freeHoursObj = {};
  shop_latitude: number;
  shop_longitude: number;
  user_address_input: string;
  indexCurrentBuisSetting;
  Premium = new PremiumClass();
  meetForBuis = new BuisMeet();
  eventForBuis = new EventClass();
  LoaderFlag = true;
  clientSide = false;
  validStatus = "";
  newadminfromuser = false;
  loaderoff = true;
  environment = environment;
  nextSlide = new Rx.BehaviorSubject(0);
  // reLoad = new Rx.BehaviorSubject(0);
  themeStyleTag: any = '';
  socialLogin = false;
  platform = '';
  mainPlatform = '';
  moreInfo = '';
  Webc = "";
  eventArray = [];
  eventArray2: Array<any>;
  desktop_menu = false;
  isIpad = false;
  zoomshow = false;
  params;
  welcome_screen = new Rx.BehaviorSubject('');
  welcome_screen_hideSlider = localStorage.getItem('welcome_screen');
  is_phone_code_admin = false;
  constructor(

    private storage: AngularFireStorage,
    public afDb: AngularFireDatabase,
    public _http: Http,
    public http: HttpClient,
    private callNumber: CallNumber,
    private socialSharing: SocialSharing,
    public LoadingService: LoadingService,
    public router: Router,
    public alertController: AlertController,
    public meetService: meetService,
    public us: userService,
    private oneSignal: OneSignal,
    private toastController: ToastController,
    public smsS: SmsService,
    public cryptoService: CryptoService,
    public firestore: AngularFirestore,
    public modalController: ModalController,
    public modalS: ModalService,
    public sortListS: SortlistService,
    public logS: LogService,
    private inAppBrowser: InAppBrowser,
    private appAvailability: AppAvailability,
    private platformm: Platform,

  ) {
    this.meetForBuis.afDb = this.afDb;
    // this.analize_cards()
    if (localStorage.getItem('app_env')) {
      this.environment.platform = localStorage.getItem('app_env');
    }
    // .stateChanges().do(actions => {
    //   actions.forEach(action => this.store.dispatch(action));
    // }).subscribe();
    // this.firestore.collection('Notifications').stateChanges().subscribe(actions => {
    //     console.log('stateChanges',actions);
    // })









    this.afDb.object('.info/connected').valueChanges().subscribe(data => {
      console.log('CONNECTED', data)
      // if(!data){
      //   // alert('disconnected')
      // }
    })
    // .ref('/BarberShop/' + this.shopData.Setting.BarberID + '/Users/')

    // firebase.onDisconnect()
    // this.afDb.database.ref('/BarberShop/' + 'ece3a99a-3be3-4442-8daf-cd74b3644774').onDisconnect().update({Rating: {Avg: 3, Total: 4, Vote: 1}});

  }
  async openModal(comp, css, swipe, props, cb?) {
    if (!css) {
      css = this.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ';
    }
    const modal = await this.modalController.create({
      component: comp,
      swipeToClose: swipe,
      cssClass: css,
      mode: "ios",
      componentProps: props

    });

    await modal.present();
    if (cb) {
      cb(modal);
    }
    return modal;
  }


  openAppUrl(app: string, name: string, id?: string) {
    switch (app) {
      case 'facebook':
        this.launchApp(
          'fb://', 'com.facebook.katana',
          'fb://profile/' + id,
          'fb://page/' + id,
          'https://www.facebook.com/' + name,
          app);
        break;
      case 'instagram':
        if (name[0] == '@') {
          name = name.substring(1);
        }
        this.launchApp(
          'instagram://',
          'com.instagram.android',
          'instagram://user?username=' + name,
          'instagram://user?username=' + name,
          'https://www.instagram.com/' + name,
          app);
        break;
      case 'waze':
        this.launchApp(
          'waze://', 'com.waze',
          'waze://ul?q=' + name + '&navigate=yes&zoom=12',
          'waze://ul?q=' + name + '&navigate=yes&zoom=12',
          'https://waze.com/ul?q=' + name + '&navigate=yes&zoom=12',
          app);
        break;
      default:
        break;
    }
  }

  private launchApp(iosApp: string, androidApp: string, appUrlIOS: string, appUrlAndroid: string, webUrl: string, app_opned) {
    let app: string;
    let appUrl: string;
    // check if the platform is ios or android, else open the web url
    if (app_opned == 'facebook' || app_opned == 'waze') {
      if (this.environment.platform == 'web' && app_opned == 'waze') webUrl = "https://www.waze.com/live-map/directions?navigate=yes&to=" + this.shopData.Setting.latitude + ',' + this.shopData.Setting.longitude;
      const browser: InAppBrowserObject = this.inAppBrowser.create(webUrl, '_system');
      return;
    }
    if (this.platformm.is('ios')) {
      app = iosApp;
      appUrl = appUrlIOS;
    } else if (this.platformm.is('android')) {
      app = androidApp;
      appUrl = appUrlAndroid;
    } else {
      const browser: InAppBrowserObject = this.inAppBrowser.create(webUrl, '_system');
      return;
    }
    this.appAvailability.check(app).then(
      () => {
        // success callback, the app exists and we can open it
        const browser: InAppBrowserObject = this.inAppBrowser.create(appUrl, '_system');
      },
      () => {
        // error callback, the app does not exist, open regular web url instead
        const browser: InAppBrowserObject = this.inAppBrowser.create(webUrl, '_system');
      }
    );
  }




  // analize_cards(){
  // let ddd =   this.cryptoService.Decrypt(Json);
  // console.log('ddd',ddd);

  // }



  // local  
  //serverDev = "http://localhost:5000/webcut-2001a/us-central1";
  // prod
  // serverDev="https://us-central1-webcut-2001a.cloudfunctions.net";
  // dev
  serverDev = env.cloudfunctions;

  celebrating_gif_array = [
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-1.gif?alt=media&token=dbef9049-e64d-4ae5-8873-942003b5631e',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-10.gif?alt=media&token=4df7ee33-dcb8-4c47-bdec-b72810e57ef0',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-11.gif?alt=media&token=3dbd40a8-c1fe-49a1-83aa-fae4c42c9d2e',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-12.gif?alt=media&token=8e6adacf-3b30-496f-8424-9e295c9e9f30',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-13.gif?alt=media&token=ecd41bd6-1e9b-4548-a71b-e42c85209188',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-2.gif?alt=media&token=5ee2f58f-d949-427b-9514-a73ca17503c1',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-4.gif?alt=media&token=06439c24-25bb-4fb4-8ac2-e37de69cc982',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-5.gif?alt=media&token=f296dfd7-0354-4f35-8b63-2c1893f0d6ef',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-6.gif?alt=media&token=121ed847-5a28-4baf-b5e9-d645f1bef90b',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-7.gif?alt=media&token=dd85727b-ba58-4365-a17f-07c0032d0a2a',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-8.gif?alt=media&token=ba141dd8-526b-400c-a9d5-b700f59707db',
    'https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/GIFS%2Fgiphy-9.gif?alt=media&token=dccb27b6-f32a-4b52-9959-100371655d2c',

    // '../assets/gif/giphy-1.gif',
    // '../assets/gif/giphy-2.gif',
    // // '../assets/gif/giphy-3.gif',
    // '../assets/gif/giphy-4.gif',
    // '../assets/gif/giphy-5.gif',
    // '../assets/gif/giphy-6.gif',
    // '../assets/gif/giphy-7.gif',
    // '../assets/gif/giphy-8.gif',
    // '../assets/gif/giphy-9.gif',
    // '../assets/gif/giphy-10.gif',
    // '../assets/gif/giphy-11.gif',
    // '../assets/gif/giphy-12.gif',
    // '../assets/gif/giphy-13.gif',
  ]

  usersDataChange = new Rx.BehaviorSubject({});
  previousUrl;
  currentUrl;
  modalMeetStatus = "";
  // userDataSelectedEvent;
  spleetmeetvar = false;
  profilePicLocalUser = "https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/male_avatar_323b.svg";
  dateForViewWeek = [];
  contactPhoneNumber: any = {
    phone: 'dsadasd'
  }
  configDATA: any = {
    logURL: '',
    premiumImg: '',
    'coronavirus': {
      showStatus: false
    },
    open_premium: false,
    premium_config: {
      noti_to_all: false,
      statistics: false,
      CustomerBaner: false,
      UserData: false,
      sortUsers: false
    },
    version: {
      min: -1,
      recommended: -1
    },
    default: {
      userProfile: '',
    }
  };
  external_login = false;



  openCypt() {
    let ddd = "{"
    let user_card = JSON.parse(this.cryptoService.Decrypt(ddd));
    console.log('user_card: ', user_card);


    let dd = [
    ]
    dd.forEach(val => {
      let dasdas = JSON.parse(this.cryptoService.Decrypt(val));
      console.log(dasdas.key, dasdas);

    })



  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setActionToMeet_check(type, status, dataa?) {
    let data;
    console.log('setActionToMeet_check', dataa);
    if (dataa) {
      data = {
        type: type,
        status: status,
        api_data: {
          api: 3424,
          do: 'that'
        },
        meet_data: {
          BarberID: dataa.BarberID,
          meet_id: dataa.key,
          meet_date_path: this.meetForBuis.convertDateToYYYY(dataa.meetDateFormat),
          userid: dataa.uId,
          // requestID: 'dkajhskdjha',
        }
      }


    } else {

      data = {
        type: type,
        status: 'inv_status',
        api_data: {
          api: 3424,
          do: 'that'
        },
        meet_data: {
          BarberID: "7a7675e3-f584-4b88-af86-a4cea8482afc",
          meet_id: "ad5cff31-e0b9-463f-8ed4-df7dcbc9ccdb",
          meet_date_path: '2022-03-01',
          userid: "BjamUv5xsCbCVOutWtAcogNIzDA2",
          // requestID: 'dkajhskdjha',
        }
      }
    }
    // this.setActionToMeet(data)


  }

  route_to(url) {
    if (!this.check_if_b_url()) {
      this.router.navigate([url], { replaceUrl: true });
    }
  }

  check_if_b_url() {
    // return true;
    console.log('this.router.url', this.router.url);
    let split = this.router.url.split('/');
    // && split[1] != 'login'
    if (split[1] != 'b' && split[1] != 'pay') {
      return false;
    } else {
      return true;
    }
  }

  setActionToMeet(data_to_send) {
    console.log(data_to_send);
    // var newSend = this.cryptoService.Encrypt(data);
    // console.log(newSend)

    return this._http.post('' + this.serverDev + '/setActionToMeet', data_to_send).subscribe(res => {
      console.log("res", res)
    })
  }


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  daysFromRow(data) {
    console.log('datatatatatatatatatat7876876876876876', data.cal)
    // this.dateForView.forEach(x => {
    //   x.active = x == data ? true : false;
    //   if (x.active) {
    this.current_pick_day = this.formatDate(data.cal);
    //     console.log(this.current_pick_day);
    //   }
    // });
    this.currentMeet.date = data;
    this.correctAppoin = "";
    // this.calendar.currentDate = data.cal;
    // this.appDate = this.formatDate(this.calendar.currentDate);
    this.getFreeHours(data.cal);
    console.log(this.meetForBuis.Date);

  }


  toggleMeetModal(event) {
    event.stopPropagation();
    // this.daysForViewFunc();
    const peopleArray: any = Object.entries(this.shopData.MeetingType).map(([key, value]) => ({ key, value }));
    peopleArray[0].value.key = peopleArray[0].key;
    if (this.currentMeet.meetType.name == "דוגמא") {
      this.currentMeet.meetType = peopleArray[0].value;
    }
    this.selectType = Object.entries(this.shopData.MeetingType).map(([key, value]) => ({ key, value }));
    this.meetService.meetType = this.selectType[0].value;
    // this.getFreeHours(new Date());
    this.addLineFLAG = !this.addLineFLAG;
    console.log(this.dateForView);
    // this.daysFromRow(this.dateForView[0]);
  }


  appState = 'adminWeb'; // adminApp, userApp, adminWeb

  menuTogglle_bol = false;
  menuTogglle(what) {
    console.log(what);

    if (what == 'close') {
      this.menuTogglle_bol = false;
    }

    if (what == 'open') {
      this.menuTogglle_bol = true;
    }

    if (what == 'toggle') {
      this.menuTogglle_bol = !this.menuTogglle_bol;
    }
  }

  // desktopMenuTogglle(what) {
  //   if (what == 'close') {
  //     this.desktopMenu_bol = false;
  //   }

  //   if (what == 'open') {
  //     this.desktopMenu_bol = true;
  //   }

  //   if (what == 'toggle') {
  //     this.desktopMenu_bol = !this.desktopMenu_bol;
  //   }
  // }

  // desktopMenu_bol = false;
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  buisType = "";
  emptyClientsFLAG: any = 0;
  userUpdatedFLAG = false;
  NotiCount = { meetings: 0, events: 0 };
  settingUpadate;
  barbersList = [];
  selectType;
  //shopData: Observable<any>;
  Auser: any = {
    fname: '',
    lname: '',
    phone: '',
    key: "",
    ProfilePic: "",
    local: "1",
  };
  isToday: boolean;
  completMeet;
  newMeetsComplete = [];
  // allMeets = [];
  userOrder = {
    prece: '10%',
    txt: 'הזמנת תור',
    opacity: 1,
    leftIcon: 'fa-chevron-left',
    stage: 0
  }
  viewTitle;
  userUpdated = {
    fname: '',
    lname: '',
    phone: '',
    key: "",
    ProfilePic: "",
    local: "1",

  }
  dateForView = [];


  calendarIsDisplay = true;




  log(user, type, cb?) {
    console.log('-----------LOGG=========', user, type)
    if (user && this.environment.currentEnv == 'prod') {



      if (user[0]) {
        if (user[0].key) {
          user = user[0].key
        }
        if (user[0].uID) {
          user = user[0].uID
        }
      }
      if (user.uId) {
        user = user.uId
      }
      if (user.key) {
        user = user.key
      }

      let sendData = {
        user: user,
        barberid: this.shopData.Setting.BarberID,
        type: type
      }
      let newSend = this.cryptoService.Encrypt(sendData);

      this.logS.sendLog(this.configDATA.logURL, newSend)


    }
  }


  logWithDataJSON(user, type, data, datajson) {
    console.log('-----------LOGG=========', user, type)
    if (user && this.environment.currentEnv == 'prod') {


      if (user[0]) {
        if (user[0].key) {
          user = user[0].key
        }
        if (user[0].uID) {
          user = user[0].uID
        }
      }
      if (user.uId) {
        user = user.uId
      }
      if (user.key) {
        user = user.key
      }

      let sendData = {
        "user": user,
        "barberid": this.shopData.Setting.BarberID,
        "type": type,
        "data": data,
        "datajson": datajson
      }
      let newSend = this.cryptoService.Encrypt(sendData);

      this.logS.sendLog(this.configDATA.logURL, newSend)


    }
  }

  logWithData(user, type, data) {
    console.log('-----------LOGG=========', user, type)
    if (user && this.environment.currentEnv == 'prod') {

      if (user[0]) {
        if (user[0].key) {
          user = user[0].key
        }
        if (user[0].uID) {
          user = user[0].uID
        }
      }
      if (user.uId) {
        user = user.uId
      }
      if (user.key) {
        user = user.key
      }

      let sendData = {
        "user": user,
        "barberid": this.shopData.Setting.BarberID,
        "type": type,
        "data": data
      }
      let newSend = this.cryptoService.Encrypt(sendData);

      this.logS.sendLog(this.configDATA.logURL, newSend)


    }
  }


  loadFireFunction() {

    // this.afDb.database
    // .ref('/BarberShop/' + this.shopData.Setting.BarberID + '/Users/')
    // .on('chil', function(snapshot) {
    //   console.log('snapshot.val()',snapshot.val());
    //   console.log('snapshot.key',snapshot.key);
    // });
    // this._http.post(this.serverDev+'/helloWorld',{data: 'dddd'}).subscribe(res => {
    // console.log('res',res);
    // });
  }


  phoneValidate( pNumber, status2) {

    var detailesPhone = {
      phone: pNumber,
      userId: this.us.user.key,
      status: status2
    }
    console.log(detailesPhone);

    var newSend = this.cryptoService.Encrypt(detailesPhone);

    console.log(newSend)
    return this._http.post(this.serverDev + '/phoneValidate',
      // return this._http.post(''+this.serverDev+'VerfyCode',
      newSend)

  }

  sendVerfyCode(code, pNumber, status2) {

    var detailesPhone = {
      code: code,
      phone: pNumber,
      userId: this.us.user.key,
      status: status2

    }
    console.log(detailesPhone);

    var newSend = this.cryptoService.Encrypt(detailesPhone);

    console.log(newSend)
    return this._http.post(this.serverDev + '/VerfyCode',
      // return this._http.post(''+this.serverDev+'VerfyCode',
      newSend)

  }

  sendPhoneValidation(phone1) {

    var detailesPhone = {
      phone: phone1,

    }
    var newSend = this.cryptoService.Encrypt(detailesPhone);

    console.log(newSend)
    return this._http.post(this.serverDev + '/PhoneValidation',
      // this._http.post(''+this.serverDev+'PhoneValidation',
      newSend)
  }


  testtest() {
    let obj = {
      API_KEY: '9f9b6af1549b4c73a611f6aa9823e9acfb',
      partner_id: 'apptor',
      type: 'send_message_to_user',
      business_ID: 'ece3a99a-3be3-4442-8daf-cd74b3644774',
      client_ID: 'P5yjlOsoLtW2JYHtkS2G71hYAR22' ,
      message_Content: 'asdasdasd',
    }
    
    this.http.post(this.serverDev + '/PointAPI', obj).subscribe((res: any) => {
      console.log('res!!!', res);
    });
  };



  changeButtonState(state) {
    console.log(state);

    if (state == -1) {
      this.userOrder.leftIcon = 'fa-chevron-left';
      this.userOrder.opacity = 0;
      this.userOrder.txt = '';
      this.userOrder.prece = '10%';
      this.userOrder.stage = -1;
    }


    if (state == 0) {
      this.userOrder.leftIcon = 'fa-chevron-left';
      this.userOrder.opacity = 1;
      this.userOrder.txt = 'הזמנת תור';
      this.userOrder.prece = '10%';
      this.userOrder.stage = 0;
    }

    if (state == 1) {
      this.userOrder.leftIcon = 'fa-chevron-left';
      this.userOrder.opacity = 0.5;
      this.userOrder.txt = 'מה בא לך?';
      this.userOrder.prece = '30%';
      this.userOrder.stage = 0;
    }


    if (state == 2) {
      this.userOrder.leftIcon = 'fa-chevron-left';
      this.userOrder.opacity = 1;
      this.userOrder.txt = 'מעולה, לבחירת הזמן';
      this.userOrder.prece = '50%';
      this.userOrder.stage = 2;
    }

    if (state == 3) {
      this.userOrder.leftIcon = 'fa-chevron-left';
      this.userOrder.opacity = 0.5;
      this.userOrder.txt = 'מתי נוח לך';
      this.userOrder.prece = '70%';
      this.userOrder.stage = 3;
    }


    if (state == 4) {
      this.userOrder.leftIcon = 'fa-hand-peace';
      this.userOrder.opacity = 1;
      this.userOrder.txt = 'יאללה קבענו';
      this.userOrder.prece = '110%';
      this.userOrder.stage = 4;
    }

    if (state == 5) {
      this.userOrder.leftIcon = 'fa-hand-peace';
      this.userOrder.opacity = 1;
      this.userOrder.txt = 'הירשם למפגש';
      this.userOrder.prece = '110%';
      this.userOrder.stage = 5;
    }

    // if (state == 6) {
    //   this.userOrder.leftIcon = 'fa-plus';
    //   this.userOrder.opacity = 1;
    //   this.userOrder.txt = 'הוספת עסק';
    //   this.userOrder.prece = '10%';
    //   this.userOrder.stage = 6;
    // }

    // if (state == 7) {
    //   this.userOrder.leftIcon = 'fa-calendar-plus';
    //   this.userOrder.opacity = 1;
    //   this.userOrder.txt = 'הזמנת תור';
    //   this.userOrder.prece = '10%';
    //   this.userOrder.stage = 7;
    // }


  }
  addLineFLAG: any = false;
  shopMessage = {
    "ok": false,
    "message": "הזמנת תור"
  };

  lang = 'he';

  allBusiness = [];
  connectedBusiness = [];
  monthNames = {
    'en': ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    'he': ["ינואר", "פבואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"],
  };

  shopData: any = {

    Setting: {
      color: '',
      name: '',
      codeVisible: false,
      searchByText: true,
      code: '',
      logo: '',
      invoice_default_auto_document: {
        name: '',
        id: 0
      },
      Premium: {
        CalendarTime: 30,
        History: false,
        HistoryOption: {},
        Reminders: false,
        UserData: "Basic",
        TeamManagement: "false",
        CustomerBaner: false,
        WebView: false,
        CartridgeMeet: 10,
        Statistics: "Basic",
        train: "basic",

      }


    },
    Users: {},
    WorkDays: [{
      dayName: "א׳",
      timeEnd: "18:00",
      timeStart: "08:00"
    },
    {
      dayName: "ב׳",
      timeEnd: "18:00",
      timeStart: "08:00"
    },
    {
      dayName: "ג׳",
      timeEnd: "18:00",
      timeStart: "08:00"
    },
    {
      dayName: "ד׳",
      timeEnd: "18:00",
      timeStart: "08:00"
    },
    {
      dayName: "ה׳",
      timeEnd: "18:00",
      timeStart: "08:00"
    },
    {
      dayName: "ו׳",
      timeEnd: "18:00",
      timeStart: "08:00"
    },
    {
      dayName: "ש׳",
      timeEnd: "18:00",
      timeStart: "08:00"
    }],
    Wallet: {}
  };


  shopID;
  uploadPercent;
  downloadURL;
  confettiFLAG = true;

  Rating = {
    Avg: "",
    Total: "",
    Vote: ""
  }

  Setting: any = {
    message: "",
    about: "",
    CalendarRestriction: 0,
    location: "",
    logo: "",
    Name: "",
    phone: "",
    photos: [],
    codeVisible: "",
    ProfilePic: ""


  }
  MeetingType = [{
    info: "",
    meetTime: "",
    name: "",
    pic: ""
  }]
  businessArray: any = [];

  buttonHide = false;
  shop = {
    ProfilePic: "",
    BarberID: "",
    email: "",
    fName: "",
    lName: "",
    Birthday: "",
    StarsCount: 0
  }

  days = {
    "en": ["ראשון", "שניה", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
    "he": ["א׳", "ב׳", "ג׳", "ד׳", "ה׳", "ו׳", "ש׳"]
  }

  logout() {
    // this.authS.logout();
  }
  resetShopData() {
    this.closeSettingSub();
    this.shopID = null;
    this.shopData = JSON.parse(JSON.stringify({

      Setting: {
        color: '',
        CustomerBaner: '',
        name: '',
        codeVisible: false,
        searchByText: true,
        code: '',
        logo: '',
        cutemptyspace: false,
        invoice_default_auto_document: {
          name: '',
          id: ''
        },
        Premium: {
          CalendarTime: 30,
          History: false,
          HistoryOption: {},
          Reminders: false,
          UserData: "Basic",
          TeamManagement: "false",
          CustomerBaner: false,
          WebView: false,
          CartridgeMeet: 10,
          Statistics: "Basic",
          train: "basic"
        }


      },
      WorkDays: [{
        dayName: "א׳",
        timeEnd: "18:00",
        timeStart: "08:00"
      },
      {
        dayName: "ב׳",
        timeEnd: "18:00",
        timeStart: "08:00"
      },
      {
        dayName: "ג׳",
        timeEnd: "18:00",
        timeStart: "08:00"
      },
      {
        dayName: "ד׳",
        timeEnd: "18:00",
        timeStart: "08:00"
      },
      {
        dayName: "ה׳",
        timeEnd: "18:00",
        timeStart: "08:00"
      },
      {
        dayName: "ו׳",
        timeEnd: "18:00",
        timeStart: "08:00"
      },
      {
        dayName: "ש׳",
        timeEnd: "18:00",
        timeStart: "08:00"
      }],
      Wallet: {}
    }
    ))
  }

  ImportPremium() {


    this.afDb.list('/BarberShop/' + this.shopData.Setting.BarberID + "/Setting/Premium").valueChanges().subscribe(res => {
      this.Premium.LoadPremium(res);


    });

  }

  makePremium() {

    //to server

    this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + "/Setting/Premium")
      .update(this.Premium.exportPremium())



  }


  // gg_basic(id) {
  //   var data = {
  //     BarberId: id,
  //   }

  //   var newSend = this.cryptoService.Encrypt(data);

  //   this._http.post('' + this.serverDev + '/makeBasic', newSend).subscribe(res => {
  //     console.log(res)
  //   });
  // }
  makeBasic() {
    var data = {
      BarberId: this.shopData.Setting.BarberID,
    }

    var newSend = this.cryptoService.Encrypt(data);

    this._http.post('' + this.serverDev + '/makeBasic', newSend).subscribe(res => {
      console.log(res)
    });

    if (this.shopData.Setting.limitMeetAdmin > 7) {
      this.shopData.Setting.limitMeetAdmin = 7;
      this.changeSettingDays();
    }

    // this.changeToBasicFeatures();

  }

  // changeToBasicFeatures() {
  //   let update_setting = false;
  //   let update_meetingType = false;

  //   // לקוחות יוכלו להזמין
  //   if (this.shopData.Setting.daysOrderBlock) {
  //     this.shopData.Setting.daysOrderBlock = '';
  //     update_setting = true;
  //   }

  //   // יומן פתוח לעסק
  //   if (this.shopData.Setting.limitMeetAdmin > 7) {
  //     this.shopData.Setting.limitMeetAdmin = 7;
  //     this.changeSettingDays();
  //     update_setting = true;
  //   }

  //   // יומן פתוח ללקוחות
  //   if (this.shopData.Setting.limitMeet > 7) {
  //     this.shopData.Setting.limitMeet = 7;
  //     update_setting = true;
  //   }

  //   // הצטרפות לקוחות חדשים באישור
  //   if (this.shopData.Setting.confirm_users) {
  //     this.shopData.Setting.confirm_users = false;
  //     update_setting = true;
  //   }

  //   // תורים עוקבים
  //   if (this.shopData.Setting.trainMeet) {
  //     this.shopData.Setting.trainMeet = false;
  //     update_setting = true;
  //   }


  //   for (const key in this.shopData.MeetingType) {
  //     let meet_type = this.shopData.MeetingType[key];

  //     // תזכורת
  //     if (meet_type.reminder > 30) {
  //       meet_type.reminder = 30;
  //       update_meetingType = true;
  //     }

  //     // ניתן לביטול
  //     if (meet_type.cancelation) {
  //       meet_type.cancelation = 0;
  //       update_meetingType = true;
  //     }
  //   }

  //   if (update_setting) {
  //     this.updateBarber({ Setting: this.shopData.Setting });
  //   }
  //   if (update_meetingType) {
  //     this.updateBarber({ MeetingType: this.shopData.MeetingType });
  //   }

  // }

  // updateBarber(obj) {
  //   this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID)
  //     .update(obj);
  // }

  // updateMettingType() {
  //   this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID)
  //     .update({
  //       Setting: this.shopData.Setting,
  //     });
  // }

  remove_CustomerBaner() {
    this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + "/Setting/CustomerBaner")
      .remove()
  }

  remove_GoogleCalendar() {
    this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + "/Setting/GoogleCalendar")
      .remove()
  }

  // removeAdminMeet(meet) {
  //   //move to server

  //   // this.saveHistoryShop(meet);
  //   this.deletMeetforeverUserAdmin(meet);
  //   this.saveToTrushMeet(meet);
  //   // return this._http.post(this.serverDev+'/RemoveMeetAdmin',meet)
  // }


  deletMeetforeverAdmin(meet) {

  }


  updateUserToPremium() {
    this.afDb.object('/Ba')
  }

  // changeViewHouers(event: Date) {
  //   console.log("changeViewHouers");
  //   var today = new Date();
  //   this.isToday = today.getTime() === event.getTime();
  //   var appDate = new Date(this.calendar.currentDate);
  //   var appDate2 = event.getDay();

  //   // this.day = event.getDay();
  //   if (!appDate2 && appDate2 != 0) {
  //     appDate2 = today.getDay();
  //   }

  //   if (appDate2 != 7) {
  //     // מחזיר את השעות עבודה של העסק

  //     var data = this.shopData.WorkDays;
  //     this.meetService.dayWork = data[appDate2].dayName;
  //     this.meetService.startWork = data[appDate2].timeStart;
  //     this.meetService.endWork = data[appDate2].timeEnd;


  //     this.meetService.setShopId(this.us.user.BarberID);
  //     //יוצר מערך שלש עות עבודה לי הלוז
  //     this.meetService.arrayOfHouers();
  //     var stringDate = this.formatDate(appDate);
  //     // מחזיר את כל התורים שיש באותו יום


  //     var gtafusmeet = this.getDateTafus(stringDate).valueChanges()
  //       .subscribe(data2 => {

  //         var gtafusevent = this.getEventTafus(stringDate).valueChanges()
  //           .subscribe(data3 => {
  //             this.completMeet = this.meetService.getCompleteMeets();


  //             var superTafus = data2.concat(data3);
  //             console.log("data + data3", superTafus)
  //             this.splitTheHouers(superTafus);
  //             this.meetService.cleanService();
  //             gtafusevent.unsubscribe();

  //           })
  //         gtafusmeet.unsubscribe();

  //       })

  //   }
  // }




  // reminders_check() {
  //   this.afDb.database
  //     .ref('/Reminders/').orderByChild('BarberID').equalTo('fa4f2662-b797-4caf-bceb-90a866f12f64').once('value').then(res => {
  //       console.log('reminders_check ---  res', this.json2array(res.val()));

  //     })


  //   // 
  // }

  getDateTafuss(newDate) {
    //להפוך
    console.log(this.shopData.Setting.BarberID);
    console.log(newDate);
    return this.http.get(this.environment.firebase_prod.databaseURL + '/BarberShop/' + this.shopData.Setting.BarberID + "/Meetings/" + newDate);

    // return this.afDb.database.ref('/BarberShop/' + this.shopData.Setting.BarberID + "/Meetings/" + newDate);
  }


  getDateTafus(newDate) {
    //להפוך
    return this.afDb.list('/BarberShop/' + this.shopData.Setting.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(newDate));
  }
  getEventTafus(newDate) {
    //להפוך
    return this.afDb.list('/BarberShop/' + this.shopData.Setting.BarberID + "/Events/" + this.meetForBuis.convertDateToYYYY(newDate));
  }

  getEvent(event) {
    //להפוך
    return this.afDb.database.ref('/BarberShop/' + event.BarberID + "/Events/" + this.meetForBuis.convertDateToYYYY(event.meetDate) + '/' + event.key).once('value');
  }

  removeMinutes(date, minutes) {
    return new Date(date.getTime() - minutes * 60000);
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
  }

  // splitTheHouers(tfusim) {
  //   console.log(this.completMeet);

  //   this.newMeetsComplete = [];
  //   var dupmeets = [];
  //   var origin = [];
  //   this.completMeet.forEach(element => {
  //     element.min.forEach(a => {
  //       var h = this.addzero(element.houer);
  //       var m = this.addzero(a);

  //       this.newMeetsComplete.push(h + ":" + m);
  //       dupmeets.push(h + ":" + m);
  //       origin.push(h + ":" + m);
  //     });
  //   });
  //   console.log(this.newMeetsComplete);
  //   this.allMeets = this.newMeetsComplete;

  //   // מוחק את התורים התפוסים
  //   this.meetService.meetType = this.currentMeet.meetType;
  //   var findem = false;


  //   // console.log(tfusim);
  //   if (tfusim.length != 0) {
  //     var reversTime = this.meetService.meetType.meetTime / 5;



  //     // console.log("splitTheHouers splitTheHouers ", tfusim)
  //     for (var t = 0; t < tfusim.length; t++) {
  //       findem = false;
  //       for (var i = 0; i < this.newMeetsComplete.length; i++) {
  //         if (tfusim[t].startTimeView == this.newMeetsComplete[i]) {
  //           findem = true;
  //           if ((tfusim[t].meetStatus == 5) || (tfusim[t].flow == true) || (this.meetService.meetToChange)) {



  //           } else {


  //             //     console.log(tfusim[t].startTimeView, this.newMeetsComplete[i], "match")
  //             var forword = tfusim[t].meetTime / 5;
  //             //forword
  //             // console.log(forword, "forword");
  //             for (var w = 0; w < forword; w++) {
  //               this.newMeetsComplete[i + w] = "x";
  //             }
  //             // console.log(reversTime, "backword");
  //             //backword
  //             for (var n = 0; n < reversTime; n++) {
  //               this.newMeetsComplete[i - n] = "x";
  //             }


  //           }
  //         }
  //       }

  //       // new changes x problem
  //       if (findem == false) {



  //         for (var i = 0; i < this.newMeetsComplete.length; i++) {

  //           if (tfusim[t].startTimeView == dupmeets[i]) {

  //             if ((tfusim[t].meetStatus == 5) || (tfusim[t].flow == true) || (this.meetService.meetToChange) && (this.meetService.meetToChange.startTimeView == tfusim[t].startTimeView)) {



  //             } else {
  //               // console.log(this.newMeetsComplete, dupmeets);


  //               // console.log(tfusim[t].startTimeView, dupmeets[i], "match")
  //               var forword = tfusim[t].meetTime / 5;
  //               //forword
  //               // console.log(forword, "forword");
  //               for (var w = 0; w < forword; w++) {
  //                 dupmeets[i + w] = "x";
  //               }
  //               // console.log(reversTime, "backword");
  //               //backword
  //               for (var n = 0; n < reversTime; n++) {
  //                 dupmeets[i - n] = "x";
  //               }
  //               this.newMeetsComplete = this.comperArray(this.newMeetsComplete, dupmeets);
  //               dupmeets = origin

  //             }



  //           }
  //         }
  //       }


  //     }





  //     for (var f = this.newMeetsComplete.length - 1; f >= 0; f--) {
  //       if (this.newMeetsComplete[f] === "x") {
  //         this.newMeetsComplete.splice(f, 1);
  //       }
  //     }


  //     this.allMeets = this.newMeetsComplete;
  //     this.beterView()

  //   } else {
  //     this.allMeets = this.newMeetsComplete;

  //     this.beterView();
  //     // console.log(  this.allMeets );
  //   }


  // }

  istoday(appDate) {
    var d = new Date();
    var otherDate = new Date(appDate);

    if ((d.getMonth() == otherDate.getMonth()) && (d.getDate() == otherDate.getDate())) {
      return true;
    } else {
      return false;
    }

  }

  loadUserHistory(key) {
    return this.afDb.object('/Users/' + key + '/History/').valueChanges();

  }

  loadHistoryByAndBarberEvent() {

    var d1 = new Date();

    d1.setDate(d1.getDate() - 1);

    var timeStart = "" + (d1.getFullYear() - 1) + "-" + ('0' + (d1.getMonth() + 1)).slice(-2) + "";
    var timeEnd = "" + d1.getFullYear() + "-" + ('0' + (d1.getMonth() + 1)).slice(-2) + "-" + ('0' + d1.getDate()).slice(-2);
    return this.afDb.database
      .ref('/BarberShop/' + this.shopData.Setting.BarberID + '/Events/').orderByKey().startAt(timeStart).endAt(timeEnd).once('value')
  }

  loadHistoryByAndBarber() {
    var d1 = new Date();
    d1.setDate(d1.getDate() - 1);

    var timeStart = "" + (d1.getFullYear() - 1) + "-" + ('0' + (d1.getMonth() + 1)).slice(-2) + "";
    var timeEnd = "" + d1.getFullYear() + "-" + ('0' + (d1.getMonth() + 1)).slice(-2) + "-" + ('0' + d1.getDate()).slice(-2);
    return this.afDb.database
      .ref('/BarberShop/' + this.shopData.Setting.BarberID + '/Meetings/').orderByKey().startAt(timeStart).endAt(timeEnd).once('value')
  }

  getBusinessHistory() {
    return this.loadHistoryByAndBarber().then(res => {
      return this.loadHistoryByAndBarberEvent().then(eve => {
        console.log("all my history ", res.val());
        var beforeSortEvent = this.json2array(eve.val());
        var beforeSort = this.json2array(res.val());
        var bar = [];
        beforeSort.forEach(element => {
          Object.keys(element).forEach(key => {
            if (typeof element[key] != "string") {
              //  console.log("element[key],",element[key].uId);
              element[key]["userData"] = this.shopData.Users[element[key].uId];
              bar.push(element[key]);
            }
          });
        });

        beforeSortEvent.forEach(element => {
          Object.keys(element).forEach(key => {
            if (typeof element[key] != "string") {
              bar.push(element[key]);
            }
          });
        });

        return bar;
      });
    });
  }

  // removeHistory() {
  //   this.getBusinessHistory().then(history => {
  //     history.forEach(meet => {
  //       this.removeAdminMeet(meet);
  //     });
  //   });
  //   // this.loadHistoryByAndBarber().then(res => {
  //   //   this.loadHistoryByAndBarberEvent().then(eve => {
  //   //     console.log("all my history ", res.val());
  //   //     var beforeSortEvent = this.json2array(eve.val());
  //   //     var beforeSort = this.json2array(res.val());
  //   //     var bar = [];
  //   //     beforeSort.forEach(element => {
  //   //       Object.keys(element).forEach(key => {
  //   //         if (typeof element[key] != "string") {
  //   //           //  console.log("element[key],",element[key].uId);
  //   //           element[key]["userData"] = this.shopData.Users[element[key].uId];
  //   //           bar.push(element[key]);
  //   //         }
  //   //       });
  //   //     })

  //   //     beforeSortEvent.forEach(element => {
  //   //       Object.keys(element).forEach(key => {
  //   //         if (typeof element[key] != "string") {
  //   //           bar.push(element[key]);
  //   //         }
  //   //       });
  //   //     });

  //   //     bar.forEach(res => {
  //   //       this.removeAdminMeet(res);
  //   //     });
  //   //   });
  //   // });
  // }

  getUserMeetings(userID) {
    console.log("key,barber", userID)
    return this.afDb.database
      // .ref('/Users/' + userID + '/meetings/').orderByChild('Date').startAt(0).endAt(new Date().getTime()).once('value');
      .ref('/Users/' + userID + '/meetings/').once('value');
  }

  loadUserHistoryByBarber(key, barber) {
    console.log("key,barber", key, barber)
    return this.afDb.database
      .ref('/Users/' + key + '/History/').orderByChild('BarberID').equalTo(barber).once('value')
  }

  loadLocalUserHistory(barber, user) {
    console.log("user,barber", user, barber)
    let now = new Date().getTime();
    return this.afDb.database
      .ref('/indexPhone/' + user.phone + '/meetings/' + barber).orderByChild('Date').endAt(now).once('value')
    // .ref('/indexPhone/' + user.phone + '/meetings/' + barber).once('value')
  }

  testDate(dataPast) {
    var d1 = new Date(dataPast);
    var d2 = new Date();
    if (d1 < d2) {
      return true
    } else {
      return false;
    }


  }
  testDateInDayes(daysBefore, dateOrder) {
    var d1 = new Date(dateOrder);
    var d2 = new Date();

    var dateOffset = (24 * 60 * 60 * 1000) * (Number(daysBefore) - 1); //5 days

    d1.setTime(d1.getTime() - dateOffset);
    if (d1 < d2) {
      return true
    } else {
      return false;
    }


  }
  moveToHistory(meet) {

    if (!meet.uId) {
      if (this.us.user.key) {
        meet.uId = this.us.user.key;
      }
    }

    console.log("moveToHistory", '/Users/' + meet.uId + '/History/' + meet.key)
    this.afDb.object('/Users/' + meet.uId + '/History/' + meet.key)
      .update(meet).then(x => {
        this.deletMeetforeverUser(meet).then(x => {
          console.log("meet deleted")
        });
      });
  }
  deletMeetforeverUser(meet) {
    console.log('deletMeetforeverUser', '/Users/' + meet.uId + '/meetings/' + meet.BarberID + "/" + meet.key)
    return this.afDb.object('/Users/' + meet.uId + '/meetings/' + meet.BarberID + "/" + meet.key).remove();
  }

  deletMeetforeverUserAdmin(meet) {
    if (meet.meetType == "event") {
      this.afDb.object('/BarberShop/' + meet.BarberID + "/Events/" + this.meetForBuis.convertDateToYYYY(meet.meetDate) + "/" + meet.key).remove().catch()

    } else {
      this.afDb.object('/BarberShop/' + meet.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(meet.meetDate) + "/" + meet.key).remove().catch()

    }
    // console.log('/BarberShop/' + meet.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(meet.meetDate)+ "/" + meet.key);
  }

  isVerify() {
    console.log("this.us.user.isValid", this.us.user.isValid)
    if (this.us.user.isValid == "no" || !this.us.user.isValid) {
      this.modalS.openValidNumber('');
    }
  }

  // isVerifyEdit() {
  //   console.log("openValidNumber")
  //   this.modalS.openValidNumber('userUpdate');
  // }

  reRender() {
    let renderBtn = document.getElementById('rerender');
    console.log(renderBtn);
    if (renderBtn) renderBtn.click();
  }

  cleanTodayTime(meetsToday) {
    var d = new Date();
    var Nhouer = d.getHours();
    Nhouer++;
    var Nminuts = d.getMinutes();
    var newMeetsToday = [];
    meetsToday.forEach(element => {
      var arrayTime = element.split(':');
      if (arrayTime[0] < Nhouer) {

      } else {


        newMeetsToday.push(element);
      }

    });
    return newMeetsToday;
  }

  // beterView() {
  //   // console.log("this.allMeets",this.allMeets)

  //   if (this.istoday(this.calendar.currentDate)) {

  //     this.allMeets = this.cleanTodayTime(this.allMeets);
  //   }

  //   var lastHour = "01";
  //   var newArrayMeet = [];


  //   var divideBy = this.shopData.Setting.timeStep / 5;
  //   var counter = 0;

  //   this.allMeets.forEach(x => {
  //     var newTime = x.split(":");
  //     var newHour = newTime[0];
  //     var newMinuts = newTime[1];
  //     if (this.shopData.Setting.cutemptyspace) {


  //       if (this.spaceBetweenTime(newArrayMeet[newArrayMeet.length - 1], x, this.shopData.Setting.timeStep)) {
  //         if (lastHour != newHour) {
  //           newArrayMeet.push(newHour);
  //           lastHour = newHour;
  //         }
  //         newArrayMeet.push(newHour + ":" + newTime[1]);
  //       }
  //     } else {



  //       //   if(counter == 0){

  //       //       counter++;
  //       //       if (lastHour != newHour) {
  //       //         newArrayMeet.push(newHour);
  //       //         lastHour = newHour;
  //       //       }
  //       //         // newArrayMeet.push(newHour + ":" + newTime[1]);
  //       //   }else{
  //       //     counter++;
  //       //     if(counter == divideBy){
  //       //     counter=0;
  //       //   }
  //       // }


  //       switch (this.shopData.Setting.timeStep) {
  //         case "60":
  //           if (newMinuts == "00") {
  //             if (lastHour != newHour) {
  //               newArrayMeet.push(newHour);
  //               lastHour = newHour;
  //             }
  //             newArrayMeet.push(newHour + ":" + newTime[1]);

  //           }
  //           break;
  //         case "30":
  //           if ((newMinuts == "00") || (newMinuts == "30")) {
  //             if (lastHour != newHour) {
  //               newArrayMeet.push(newHour);
  //               lastHour = newHour;
  //             }
  //             newArrayMeet.push(newHour + ":" + newTime[1]);
  //           }
  //           break;
  //         case "15":
  //           if ((newMinuts == "00") || (newMinuts == "30") || (newMinuts == "15") || (newMinuts == "45")) {
  //             if (lastHour != newHour) {
  //               newArrayMeet.push(newHour);
  //               lastHour = newHour;
  //             }
  //             newArrayMeet.push(newHour + ":" + newTime[1]);
  //           }
  //           break;


  //         case "20":
  //           if ((newMinuts == "20") || (newMinuts == "40") || (newMinuts == "00")) {
  //             if (lastHour != newHour) {
  //               newArrayMeet.push(newHour);
  //               lastHour = newHour;
  //             }
  //             newArrayMeet.push(newHour + ":" + newTime[1]);
  //           }
  //           break;

  //         default:
  //           if (lastHour != newHour) {
  //             newArrayMeet.push(newHour);
  //             lastHour = newHour;
  //           }
  //           newArrayMeet.push(newHour + ":" + newTime[1]);
  //           break;
  //       }

  //     }



  //   })

  //   if (this.shopData.Setting.trainMeet) {
  //     // this.allMeets = newArrayMeet;
  //     var spArray = [];
  //     for (var m = 0; m < 24; m++) {
  //       var timeShow: string = newArrayMeet[m];
  //       if (timeShow && timeShow.length == 5) {
  //         spArray.push(newArrayMeet[m]);
  //         m = 24;

  //       }
  //     }
  //     this.allMeets = spArray;

  //   } else {
  //     this.allMeets = newArrayMeet;

  //   }

  // }
  navAdminTab(page) {
    this.router.navigate(['tabsAdmin/' + page]);
  }


  spleetmeet() {
    this.spleetmeetvar = !this.spleetmeetvar;
    this.SelectedMeet.event.splitMeet = !this.SelectedMeet.event.splitMeet;
  }

  comperArray(a1, a2) {

    var a3 = [];
    for (var i = 0; i < a1.length; i++) {

      if (a1[i] == 'x' || a2[i] == 'x') {
        a3.push('x');
      } else {
        a3.push(a1[i]);
      }

    }
    return a3;

  }



  spaceBetweenTime(time1, time2, space) {


    // var now  = "04/09/2013 "+time1+":00";
    // var then = "04/09/2013 "+time2+":00";

    // var a =moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")

    if (time1 == undefined) {
      return true;
    }

    var newTime1 = time1.split(":");
    var newTime2 = time2.split(":");


    var date1: any = new Date(2000, 0, 1, newTime1[0], newTime1[1]); // 9:00 AM
    var date2: any = new Date(2000, 0, 1, newTime2[0], newTime2[1]); // 5:00 PM

    // the following is to handle cases where the times are on the opposite side of
    // midnight e.g. when you want to get the difference between 9:00 PM and 5:00 AM

    if (date2 < date1) {
      date2.setDate(date2.getDate() + 1);
    }

    var diff = date2 - date1;
    var mm = Math.floor(diff / 1000 / 60);


    if (mm >= space) {
      return true;
    } else {
      return false
    }
  }



  // cancelLinerepted(repetKey, cb) {
  //   console.log("thits.shopS.shopData.Meetings", this.eventArray);
  //   var n = 0
  //   var arrayOfKey = [];
  //   this.eventArray.forEach((element, index) => {
  //     if (element.mType) {
  //       if (element.mType.repeat == repetKey || element.key == repetKey) {
  //         console.log(element);
  //         n++;
  //         arrayOfKey.push(element.key)
  //         this.removeForAdminAndUser(element);
  //       }
  //     }
  //   });
  //   cb(n);

  //   arrayOfKey.forEach(res => {
  //     let index = this.eventArray.findIndex(x => x.key === res);
  //     this.eventArray.splice(index, 1);
  //   })

  // }

  deleteRepeatMeet(obj) {
    this.afDb.database.ref('/BarberShop/' + this.shopID + '/Meetings/' + this.meetForBuis.convertDateToYYYY(obj.meetDate) + '/' + obj.key + '/repeat_meet').once('value').then(res => {
      let val = res.val();
      console.log(val);
      for (const key in val) {
        const element = val[key];

        this.afDb.database.ref('/BarberShop/' + this.shopID + '/Meetings/' + this.meetForBuis.convertDateToYYYY(element.meetDate) + '/' + element.key).once('value').then(res2 => {
          let meet = res2.val();
          console.log(meet);
          if (meet) {
            this.deleteMeet(meet);
          }
        });
      }

      this.meetmodalFLAG = false;
      this.meetForBuis.resetCLass();
      this.showToast('התורים נמחקו בהצלחה');
    });
  }


  repeat_meet = {};

  checkIfwork(workDay, meet_start, duration) {
    console.log("checkIfwork", workDay, meet_start, duration);

    let timeStart_split = workDay.timeStart.split(":");
    let timeEnd_split = workDay.timeEnd.split(":");

    let start_work = new Date(meet_start).setHours(timeStart_split[0], timeStart_split[1], 0, 0);
    let end_work = new Date(meet_start).setHours(timeEnd_split[0], timeEnd_split[1], 0, 0);

    let meet_end = meet_start + (parseFloat(duration) * 60000);

    if (meet_start >= start_work && meet_end <= end_work) {
      return true;
    } else {
      return false;
    }
  }

  repEveryTime;
  untillDate;

  repeatMeet() {
    console.log(this.repEveryTime, this.untillDate);
    console.log(this.meetForBuis.exportMeetAdmin());
    var dDate = new Date(this.meetForBuis.Date);

    if (this.repEveryTime == 'day') {
      dDate.setDate(dDate.getDate() + 1);
    } else {
      dDate.setDate(dDate.getDate() + 1 * 7);
    }

    if (dDate.getTime() > new Date(this.untillDate).setHours(23, 59, 59, 999)) {
      console.log(this.repeat_meet);
      this.finishRepeat();
      return;
    }

    this.meetForBuis.defineStartTimeAndEndTime(dDate, this.meetForBuis.meetTime);
    this.meetForBuis.importStartDate(dDate);

    if (this.checkIfwork(this.shopData.WorkDays[dDate.getDay()], this.meetForBuis.Date, this.meetForBuis.meetTime)) {
      this.meetForBuis.mType.repeat = { key: this.SelectedMeet.event.key, meetDate: this.SelectedMeet.event.meetDate };
      this.meetForBuis.newKey();
      let meet_key = this.meetForBuis.key;
      let meet = {
        meetDate: this.formatDate(dDate),
        key: meet_key
      }

      console.log(this.meetForBuis.exportMeetAdmin());

      if (this.meetForBuis.flow) {
        this.setRepeatMeet();
        this.repeat_meet[meet_key] = meet;
        this.repeatMeet();
      } else {
        this.checkMeetAvailable(dDate, this.meetForBuis.meetTime, this.SelectedMeet.event.key, null, available => {
          console.log(available);

          if (available) {
            this.setRepeatMeet();
            this.repeat_meet[meet_key] = meet;
            this.repeatMeet();
          } else {
            let msg = 'בתאריך' + this.meetForBuis.convertDate(dDate) + 'קיים כבר תור, האם ברצונכם לקבוע בכל זאת?';
            this.presentAlertConfirm('שימו לב', msg, 'קבע תור', 'דלג').then(res => {
              if (res) {
                this.setRepeatMeet();
                this.repeat_meet[meet_key] = meet;
              }
              this.repeatMeet();
            });
          }
        });
      }
    } else {
      this.repeatMeet();
    }
  }

  initRepeatMeet(repEveryTime, untillDate) {
    if (this.checkTimeZoneDiff()) {
      this.timezoneAlert();
      return;
    }
    this.LoadingService.present_for(10000);
    this.repeat_meet = {};
    this.repEveryTime = repEveryTime;
    this.untillDate = untillDate;

    this.repeatMeet();
  }
  repShow = false;

  finishRepeat() {
    this.LoadingService.dismiss();
    var textmeet = "נקבעו לך תורים לתאריכים :";
    for (const key in this.repeat_meet) {
      const element = this.repeat_meet[key];
      textmeet += element.meetDate + ', ';
    }

    this.meetmodalFLAG = false;
    this.showAlert('תור חוזר', textmeet, null);
    this.repShow = !this.repShow;

    this.updateRepeatMeet(this.SelectedMeet.event, this.repeat_meet);

    this.meetForBuis.resetCLass();
  }

  async timezoneAlert() {
    const alert = await this.alertController.create({
      header: 'שימו לב',
      subHeader: '',
      message: 'נראה שאתם נמצאים בחול, לא ניתן לבצע שינויים ביומן',
      buttons: ['סגור']
    });

    await alert.present();
  }

  removeForAdminAndUser(meet) {
    if (meet.uId != "localUserAdmin") {
      this.checkIfRealUser(meet.uId).then(res => {
        console.log("if user realy ", res.val())
        if (res.val()) {
          this.removeMeetForUser(meet)
        }
        this.removeMeetForAdmin(meet);
      })
    } else {
      this.removeMeetForAdmin(meet);
    }
  }

  removeMeetForAdmin(meet) {
    console.log('/BarberShop/' + meet.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(meet.meetDate) + "/" + meet.key);
    this.afDb.object('/BarberShop/' + meet.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(meet.meetDate) + "/" + meet.key).remove().catch()
  }
  removeMeetForUser(meet) {
    console.log('/Users/' + meet.uId + "/meetings/" + meet.BarberID + "/" + meet.key)
    this.afDb.object('/Users/' + meet.uId + "/meetings/" + meet.BarberID + "/" + meet.key).update({
      meetStatus: 5
    })
  }

  changeSettingDays() {
    this.dateForView = [];
    this.calendarIsDisplay = false;
    // console.log(this.shopData.Setting.limitMeet, "this.shopData.Setting.limitMeet")
    var today = new Date()
    var dayToday = today.getDay();
    var limitMeet = parseInt(this.shopData.Setting.limitMeetAdmin);
    if (!limitMeet) {
      limitMeet = 7;
    }

    for (var i = 0; i < limitMeet; i++) {
      this.days.he[dayToday];
      var calDate = today.toString();
      if (i == 0) {
        this.dateForView.push(
          {
            "name": this.days.he[dayToday],
            "date": today.getDate(),
            'cal': new Date(calDate),
            'active': true
          });
        let f = this.monthNames[this.lang];
        this.viewTitle = f[today.getMonth()] + ', ' + today.getFullYear();
        // this.changeViewHouers(new Date(calDate));
        // this.getFreeHours(new Date(calDate));
      } else {
        this.dateForView.push(
          {
            "name": this.days.he[dayToday],
            "date": today.getDate(),
            'cal': new Date(calDate),
            'active': false
          });
      }


      if (dayToday != 6) {
        dayToday++;
      } else {
        dayToday = 0;
      }
      today.setDate(today.getDate() + 1);
    }

    setTimeout(() => {
      this.calendarIsDisplay = true;
    }, 10);
  }
  daysForViewFunc() {

    this.calendarIsDisplay = false;
    console.log(this.dateForView);

    if (this.dateForView.length == 0) {
      var today = new Date()
      var dayToday = today.getDay();
      console.log(this.shopData.Setting.limitMeet);

      var limitMeet = parseInt(this.shopData.Setting.limitMeetAdmin);
      if (!limitMeet) {
        limitMeet = 31;
      }

      for (var i = 0; i < limitMeet; i++) {


        this.days.he[dayToday];
        var calDate = today.toString();

        if (i == 0) {

          this.dateForView.push(
            {
              "name": this.days.he[dayToday],
              "date": today.getDate(),
              'cal': new Date(calDate),
              'active': true
            });
          let f = this.monthNames[this.lang];
          this.viewTitle = f[today.getMonth()] + ', ' + today.getFullYear();
          // this.changeViewHouers(new Date(calDate));

          // this.getFreeHours(new Date(calDate));
        } else {
          this.dateForView.push(
            {
              "name": this.days.he[dayToday],
              "date": today.getDate(),
              'cal': new Date(calDate),
              'active': false
            });
        }


        if (dayToday != 6) {
          dayToday++;
        } else {
          dayToday = 0;
        }

        today.setDate(today.getDate() + 1);
      }

    } else {
      // this.changeViewHouers(new Date(this.currentMeet.date.cal));
      this.getFreeHours(new Date(this.currentMeet.date.cal));
    }
    setTimeout(() => {
      this.calendarIsDisplay = true;
    }, 10);
  }
  saveShopDetils(shopD) {


    // this.Rating = {
    //   Avg: shopD.Rating.Avg,
    //   Total: shopD.Rating.Total,
    //   Vote: shopD.Rating.Vote
    // }

    console.log("shopD : ", shopD)

    this.Setting = {
      message: shopD.Setting.Message,
      about: shopD.Setting.about,
      CalendarRestriction: shopD.Setting.limitMeet,
      location: shopD.Setting.location,
      logo: shopD.Setting.logo,
      Name: shopD.Setting.name,
      phone: shopD.Setting.phone,
      photos: shopD.Setting.photos,
      ProfilePic: shopD.Setting.profilePic,
      codeVisible: shopD.Setting.codeVisible,

    }



    //     for (let i = 0; i < shopD.MeetingType.length; i++) {

    // //      console.log('shopD.MeetingType',shopD.MeetingType);
    //  //     console.log('shopD.MeetingType[i]',shopD.MeetingType[i]);
    //  //     console.log('[i]',i);
    //       if(shopD.MeetingType[i]){
    //       let meet = {
    //         info: shopD.MeetingType[i].info,
    //         meetTime: shopD.MeetingType[i].meetTime,
    //         name: shopD.MeetingType[i].name,
    //         pic: shopD.MeetingType[i].pic
    //       }

    //       this.MeetingType[i] = meet;
    //     }
    //     }

    this.auserDifine();
    // var userA=Object.values(this.shopData.Users);
    // this.Auser=userA.find(isA);
    // this.Auser.ProfilePic=shopD.Setting.logo;
    // this.Auser.fname=shopD.Setting.name;
    // this.currentMeet.user=  this.Auser;

    //console.log("this.Auser : ", this.Auser)
    //console.log("this.currentMeet : ", this.currentMeet)


    //console.log("save shop detels wtf!!! ,",this.Auser,userA)

    //  console.log("MeetingType : ", this.MeetingType)
  }



  auserDifine() {
    if (this.shopData.Users) {
      console.log("========== ", this.shopData.Users)
      var userA = Object.values(this.shopData.Users);
      this.Auser = userA.find(isA);
      this.Auser.key = "localUserAdmin";
      this.Auser.ProfilePic = this.shopData.Setting.logo;
      this.Auser.fname = this.shopData.Setting.name;
      this.currentMeet.user = this.Auser;

    }



    // console.log("auser0", this.Auser);

  }
  getShopWorkDaysForUser(shopID) {
    // this.shopID = shopID;
    console.log("getShopWorkDaysForUser")
    // return this.afDb.list('/BarberShop/' + shopID + '/WorkDays');
    return this.afDb.database.ref('/BarberShop/' + shopID + '/WorkDays').once('value');

    // return this.afDb.list('/BarberShop/' + shopID);
  }

  getShopMeetingTypeForUser(shopID) {
    // this.shopID = shopID;
    return this.afDb.list('/BarberShop/' + shopID + '/MeetingType');
    // return this.afDb.list('/BarberShop/' + shopID);
  }

  getShopDetilsForUser(shopID) {
    // this.shopID = shopID;
    return this.afDb.database.ref('/BarberShop/' + shopID + '/Setting');
    // return this.afDb.list('/BarberShop/' + shopID);
  }

  getShopPosts(shopID) {
    return this.afDb.object('/BarberShop/' + shopID + '/Posts');
  }

  getShopWallet(shopID) {
    return this.afDb.database.ref('/BarberShop/' + shopID + '/Wallet').once('value');
  }

  getTransaction(TransactionID) {
    return this.afDb.database.ref('/Transaction/' + TransactionID);
  }


  getFilteredShopDetils(shopID, cb) {
    let businessFilterdDetails = ['MeetingType', 'Posts', 'Rating', 'Setting', 'Users', 'Wallet', 'WorkDays', 'meetCounter', 'vendor_requests'];
    let shopData = {};
    let counter = 0;
    businessFilterdDetails.forEach(detail => {
      this.afDb.database.ref('/BarberShop/' + shopID + '/' + detail).once('value').then(res => {
        let val = res.val();
        console.log('/BarberShop/' + shopID + '/' + detail, val);

        if (val) shopData[detail] = val;
        counter++;
        if (counter == businessFilterdDetails.length) {
          console.log(shopData);
          cb(shopData);
        }
      });
    });
  }

  getShopDetils(shopID) {
    return this.afDb.object('/BarberShop/' + shopID);
  }

  getShopObjectDetils(shopID) {

    return this.afDb.list('/BarberShop/' + shopID).snapshotChanges();
    // return this.afDb.list('/BarberShop/' + shopID);
  }

  getAllShops() {
    return this.afDb.list('/BarberShop/').valueChanges();

  }

  loadEvents() {
    console.log('/BarberShop/' + this.shopData.Setting.BarberID + "/Events");

    var d = new Date();

    let month = (d.getMonth() + 1).toString();
    if (month.length == 1) {
      month = "0" + month;
    }
    let day = d.getDate().toString()
    if (day.length == 1) {
      day = "0" + day;
    }
    let year = d.getFullYear().toString();


    let dateString = '' + year + '-' + month + '-' + day + '';
    console.log("nDate ---- > ", dateString)
    return this.afDb.database.ref('/BarberShop/' + this.shopData.Setting.BarberID + "/Events/").orderByKey()
      .startAt(dateString).limitToLast(20)


    //  return this.afDb.object('/BarberShop/'+this.shopData.Setting.BarberID+"/Events").valueChanges()
  }


  BeBuis(data) {


    return this.afDb.object('/Users/' + this.Webc)
      .update({
        BarberID: data.Setting.BarberID,
        shopOwnerKey: {
          id: data.Setting.BarberID
        }
      });
  }
  getAllShop() {
    // return this.afDb.list('/BarberShop/').snapshotChanges();
  }
  deletMeet(Mtime, Mdate) {
    return this.afDb.object('/BarberShop/' + this.shopID + "/meetings/" + Mdate + "/" + Mtime).remove()
      .catch()

  }

  getFreeShopNew(id) {
    return this.afDb.object('/Codes/' + id).valueChanges();

  }


  getFreeHours(date) {
    console.log('getFreeHours----LOGGGG', date);

    this.skeleton_lines = true;
    // this.LoadingService.present();
    console.log(this.dateForView);
    date = new Date(date);
    // Arrange Data
    let workHours = this.shopData.WorkDays[new Date(date).getDay()];
    // let workHoursMS = {
    //   start: new Date(date).setHours(parseInt(workHours.timeStart.split(':')[0]), parseInt(workHours.timeStart.split(':')[1]), 0, 0),
    //   end: new Date(date).setHours(parseInt(workHours.timeEnd.split(':')[0]), parseInt(workHours.timeEnd.split(':')[1]), 0, 0)
    // }
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let data = {
      BarberID: this.shopData.Setting.BarberID,
      date: {
        // start_of_day: new Date(date).setHours(0, 0, 0, 0),
        // end_of_day: new Date(date).setHours(23, 59, 59, 999),
        current_date: new Date().getTime(),
        // meet_date: new Date(date).getTime(),
        meet_date_fix: this.current_pick_day
      },
      settings: {
        meetTime: this.meetService.meetType.meetTime,
        timeStep: this.shopData.Setting.timeStep,
        cutemptyspace: this.shopData.Setting.cutemptyspace,
        timezone: this.shopData.Setting.timezone,
        workHours: workHours,
      },
      userid: this.us.user.key,
      type: 'freeHours'
    };
    console.log(data);

    let encrypted = this.cryptoService.Encrypt(data);
    // this.allMeets = [];
    // Get free hours
    this.http.post(this.serverDev + '/meetAPI', encrypted, { headers: reqHeader }).subscribe(res => {
      // this.LoadingService.dismiss();
      this.skeleton_lines = false;

      let freeHours: any = res;
      console.log(freeHours);

      if (data.date.meet_date_fix) {
        this.freeHoursObj[this.current_pick_day] = freeHours;
      } else {
        let fixedHours = [];
        let last_hour_title;

        // Add title
        freeHours.forEach(hour => {
          let current_hour_title = this.addzero(new Date(hour.timestamp).getHours()) + ':00';
          if (last_hour_title != current_hour_title) {
            fixedHours.push({ title: current_hour_title });
            last_hour_title = current_hour_title;
          }
          hour.hour_view = this.addzero(new Date(hour.timestamp).getHours()) + ':' + this.addzero(new Date(hour.timestamp).getMinutes());
          fixedHours.push(hour);
        });

        console.log(fixedHours);
        this.freeHoursObj[this.current_pick_day] = fixedHours;
      }
      // this.allMeets = fixedHours;
    });


    // var gtafusmeet = this.getDateTafus(this.meetForBuis.convertDate(date)).valueChanges()
    //   .subscribe(data2 => {
    //     var gtafusevent = this.getEventTafus(this.meetForBuis.convertDateToYYYY(this.meetForBuis.convertDate(date))).valueChanges()
    //       .subscribe(async data3 => {
    //         let total: any = data2.concat(data3);
    //         total = total.filter(meet => meet.meetStatus != 5 && !meet.flow);
    //         let freeWindows: any = await this.setFreeWindows(total, date);
    //         this.setFreeHours(freeWindows, date);

    //         gtafusevent.unsubscribe();
    //       });
    //     gtafusmeet.unsubscribe();
    //   });
  }

  // setFreeHours(freeWindows, date) {
  //   let freeHours = [];
  //   let meetTime = parseInt(this.meetService.meetType.meetTime) * 60000;
  //   let space = parseInt(this.shopData.Setting.timeStep) * 60000;
  //   let workDay = this.shopData.WorkDays[new Date(date).getDay()];
  //   let workDayMS = {
  //     start: new Date(date).setHours(parseInt(workDay.timeStart.split(':')[0]), parseInt(workDay.timeStart.split(':')[1]), 0, 0),
  //     end: new Date(date).setHours(parseInt(workDay.timeEnd.split(':')[0]), parseInt(workDay.timeEnd.split(':')[1]), 0, 0)
  //   }

  //   freeWindows.forEach(window => {
  //     let window_duration = window.end - window.start;
  //     let loops = Math.floor(window_duration / space);
  //     let last_hour = window.start;
  //     if (!this.shopData.Setting.cutemptyspace) {
  //       let minutes = new Date(last_hour).getMinutes();
  //       if (minutes % 15) {
  //         if (minutes < 15) {
  //           last_hour = new Date(last_hour).setMinutes(15);
  //         } else {
  //           last_hour = new Date(last_hour).setMinutes(Math.ceil(minutes / 15) * 15);
  //         }
  //       }
  //     }

  //     for (let i = 0; i <= loops; i++) {
  //       let freeHourCheck = last_hour + meetTime;
  //       if (freeHourCheck <= window.end) {
  //         freeHours.push({ timestamp: last_hour, hour_view: this.addzero(new Date(last_hour).getHours()) + ':' + this.addzero(new Date(last_hour).getMinutes()) });
  //         last_hour = last_hour + space;
  //       } else {
  //         break;
  //       }
  //     }
  //   });
  //   freeHours = freeHours.filter(hours => hours.timestamp >= workDayMS.start && hours.timestamp <= (workDayMS.end - meetTime) && hours.timestamp > new Date().getTime());
  //   let fixedArray = [];
  //   let last_hour_title;
  //   freeHours.forEach(hour => {
  //     let current_hour_title = this.addzero(new Date(hour.timestamp).getHours()) + ':00';
  //     if (last_hour_title != current_hour_title) {
  //       fixedArray.push({ title: current_hour_title });
  //       last_hour_title = current_hour_title;
  //     }
  //     fixedArray.push(hour);
  //   });
  //   this.allMeets = fixedArray;
  // }

  // setFreeWindows(allMeets, date) {
  //   return new Promise(resolve => {
  //     let meets_arr = [];

  //     let takenWindows = [];
  //     let start_of_day = new Date(date).setHours(0, 0, 0, 0);
  //     let end_of_day = new Date(date).setHours(23, 59, 59, 999);
  //     allMeets.forEach(meet => {
  //       let obj = {
  //         start: new Date(meet.startDate).getTime(),
  //         end: new Date(meet.endTime).getTime()
  //       }
  //       meets_arr.push(obj);
  //     });

  //     meets_arr.sort((a, b) => a.start - b.start);
  //     let freeWindows = [];
  //     let last_taken_window: any = {
  //       start: start_of_day,
  //       end: start_of_day
  //     };
  //     let last_free_window: any = {
  //       start: start_of_day,
  //       end: start_of_day
  //     };
  //     meets_arr.forEach(meet => {
  //       if (last_taken_window.end < meet.start) {
  //         let window: any = {};
  //         window.start = meet.start;
  //         window.end = meet.end;
  //         for (let i = 0; i < meets_arr.length; i++) {
  //           if (meets_arr[i].start < window.end && meets_arr[i].end > window.end) {
  //             window.end = meets_arr[i].end;
  //           }
  //         }
  //         last_taken_window = window;
  //         takenWindows.push(window);
  //       }
  //     });

  //     takenWindows = takenWindows.sort((a, b) => a.start - b.start);
  //     if (takenWindows.length > 0) {
  //       for (let i = 0; i <= takenWindows.length; i++) {
  //         if (i == 0) {
  //           last_free_window = {
  //             start: start_of_day,
  //             end: takenWindows[i].start
  //           }
  //         } else if (i == takenWindows.length) {
  //           last_free_window = {
  //             start: takenWindows[i - 1].end,
  //             end: end_of_day
  //           }
  //         } else {
  //           last_free_window = {
  //             start: takenWindows[i - 1].end,
  //             end: takenWindows[i].start
  //           }
  //         }
  //         freeWindows.push(last_free_window);
  //       }
  //     } else {
  //       freeWindows.push({
  //         start: start_of_day,
  //         end: end_of_day
  //       });
  //     }
  //     resolve(freeWindows);
  //   });
  // }


  changeToLineFormat(meet) {
    var newdate;
    if (meet.meetDateFormat.includes(".")) {
      let datearray = meet.meetDateFormat.split(".");
      newdate = datearray[0] + '-' + datearray[1] + '-' + datearray[2];

    } else {
      newdate = meet.meetDateFormat;
    }
    return newdate;
  }

  changeEventTime(event, meet) {
    // console.log("------- ", event, meet)
    // event.detail.value;
    // meet.meetDateFormat;

    console.log("event.target.value", event.target.value)
    this.eventForBuis.showClass();
    this.eventForBuis.changeMeetTime(event.target.value);
    this.globalEventUpdate(this.eventForBuis.exportEventAdmin());
    this.updateAllEeventUsers()
    this.eventForBuis.showClass();

  }

  meetChange(field, change, func) {
    console.log("change", change, field)
    console.log(this.SelectedMeet.event.uId);
    this.meetForBuis.showClass();
    if (field != 'flow' && !change) {
      return;
    }

    if (field == 'meetTime' && change) this.meetForBuis.changeMeetTime(change);
    else if (field == 'startTimeView' && change) this.meetForBuis.changeStartTime(change);
    else if (field == 'flow') this.meetForBuis.flow = this.SelectedMeet.event.flow;
    console.log(this.meetForBuis.exportMeetAdmin());
    this.meet_api('update_meet_business', { meet: this.meetForBuis.exportMeetAdmin(), ShopSetting: this.shopData.Setting, function: func });

    if (this.SelectedMeet.event.uId != 'localUserAdmin') {
      let user_meet = this.meetForBuis.exportMeetUser();
      user_meet.uId = this.SelectedMeet.event.uId;
      this.meet_api('update_meet_user_business', { meet: user_meet, userid: this.SelectedMeet.event.uId, ShopSetting: this.shopData.Setting, userData: this.shopData.Users[this.SelectedMeet.event.uId], notif_type: 'admin_change_meet', function: func });
    }
  }

  // changeMeetTime(change) {
  //   console.log("change", change)
  //   console.log(this.SelectedMeet.event.uId);
  //   this.meetForBuis.showClass();

  //   if (this.meetForBuis.meetTime != change && change) {
  //     this.meetForBuis.changeMeetTime(change);
  //     this.checkIfRealUser(this.SelectedMeet.event.uId).then(res => {
  //       let user = res.val();
  //       console.log(user);

  //       let meet_notif = {
  //         name: this.SelectedMeet.event.meetType.name,
  //         startTimeView: this.SelectedMeet.event.startTimeView,
  //         meetDate: this.SelectedMeet.event.meetDate
  //       }
  //       if (user && this.SelectedMeet.event.uId != 'localUserAdmin') {
  //         // this.meetForBuis.updateBarberMeetAnduser();
  //         this.meet_api('change_meet_both_business', this.meetForBuis.exportMeetAdmin());
  //         // NOTIFICATION
  //         let notif_data = {
  //           type: 'admin_change_meet',
  //           users: [user],
  //           meet: meet_notif
  //         }
  //         this.send_notif(notif_data);
  //         // UPDATE REMONIDER
  //         this.updateReminder(this.SelectedMeet.event);
  //       } else {
  //         // this.meetForBuis.updateBarberMeet();
  //         this.meet_api('change_meet_business', this.meetForBuis.exportMeetAdmin());
  //         // SEND SMS
  //         if (this.SelectedMeet.event.uIdData.phone) {
  //           let sms_data = {
  //             type: 'admin_change_meet',
  //             to_phone: this.SelectedMeet.event.uIdData.phone,
  //             meet: meet_notif
  //           }
  //           this.send_sms(sms_data);
  //         }
  //       }
  //     });
  //   }
  //   // this.meetForBuis.updateBarberMeetAnduser();
  //   // this.meetForBuis.showClass();
  // }

  updateAllEeventUsers() {
    // if ((typeof this.eventForBuis.uId != "string") && (this.eventForBuis.uId.length > 0)) {
    if (Array.isArray(this.eventForBuis.uId)) {

      this.eventForBuis.uId.forEach(element => {
        console.log("people ---> ", element)
        this.updatePopleEvent(this.eventForBuis.exportExtraData(), element.uId)
      });
    }
  }



  // updateToCancelUserevent() {
  //   if ((typeof this.eventForBuis.uId != "string") && (this.eventForBuis.uId && this.eventForBuis.uId.length > 0)) {

  //     this.eventForBuis.uId.forEach(element => {




  //       // this.smsS.sendSMS_local_client(this.eventForBuis.exportEventUser(),element.uId,'deletUserEvent')


  //       // console.log("people ---> ", element);
  //       // this.eventForBuis.eventStatus = 5;
  //       // this.eventForBuis.needConfirm = 5;
  //       // this.updatePopleEvent(this.eventForBuis.exportEventUser(), element.uId);


  //       this.checkIfRealUser(element.uId).then(res => {

  //         if (res.val() != null) {


  //           // var Event_to_send: any = JSON.parse(JSON.stringify(this.eventForBuis.exportEventUser()));
  //           // Event_to_send['meetType'] = {
  //           //   name: Event_to_send.eventName
  //           // }


  //           this.smsS.sendSMS_local_client(this.eventForBuis.exportEventUser(), element.uId, 'deleteUserEvent')
  //           this.updateUserEventStatusToCancel(this.eventForBuis.exportEventUser(), element.uId);
  //           this.removeReminder(this.eventForBuis.key + '-' + element.uId)
  //         }
  //       })


  //     });
  //   }
  // }


  addPaus(start, paus, meet, nextTime) {
    var meetTypeData = meet.meetTypeData;
    var totalTime = parseInt(meetTypeData.meetTime);
    paus = parseInt(paus);
    start = parseInt(start);

    var nextMeetTime = totalTime - start - paus;

    var newdatef = this.changeToLineFormat(meet)


    var newDate = newdatef
    var forDate = newdatef;




    // var newStr = meet.meetDateFormat.split(".");
    // var newDate = newStr[0] + "-" + newStr[1] + "-" + newStr[2];
    // var forDate = newStr[1] + "-" + newStr[0] + "-" + newStr[2];

    var timeNew = meet.startTimeView.split(":");
    var StartTime = new Date(forDate);
    StartTime.setHours(Number(timeNew[0]), Number(timeNew[1])).toString();
    StartTime.setTime(StartTime.getTime() + ((paus + start) * 60 * 1000));



    let end: any = new Date(meet.meetDateFormat);
    end.setTime(StartTime.getTime() + ((nextMeetTime) * 60 * 1000));


    // console.log("StartTime.getTime()", meet)


    let newMeet = {
      flow: false,
      endTime: end.toString(),
      meetTime: meet.meetTime,
      startTimeView: StartTime.getHours() + ":" + StartTime.getMinutes(),
      endTimeView: this.addzero(end.getHours()) + ':' + this.addzero(end.getMinutes()),
      meetType: meet.meetType,
      meetStatus: "1",
      startDate: StartTime.toString(),
      meetDateFormat: meet.meetDateFormat,
      key: meet.newID,
      uId: this.us.key
    }

    //      console.log("newMeet", newMeet)
    // let baraber = this.afDb.object('/BarberShop/' + this.us.user.BarberID + "/Meetings/" + this.appDate + "/" + newID);
    // baraber.set(newMeet);


    // console.log("add paus",start,paus,totalTime-(paus+start));

  }



  getUserData(key) {
    return this.afDb.object('/Users/' + key);
  }


  updateWorkDays(key, timeStart, timeEnd) {
    // console.log('this.shopID:', this.shopID);

    // console.log('key:', key, '  timeStart:', timeStart, '  timeEnd:', timeEnd);
    let stringKey = key.toString();
    this.afDb.object('/BarberShop/' + this.shopID + '/WorkDays/' + stringKey)
      .update({
        timeStart: timeStart,
        timeEnd: timeEnd,
      })
  }


  newSplitMeet(meet) {

    console.log("moveForwardTime");

    this.meetForBuis.moveForwardTime(meet.Break);
    this.meetForBuis.changeMeetTime(meet.nextmeet);
    this.meetForBuis.newKey();
    this.meetForBuis.showClass();
    this.meetForBuis.setAdminMeet();
    this.meetForBuis.resetCLass();

    this.meetmodalFLAG = false;

  }

  // deletBuis(id) {
  //   return this.afDb.object('/BarberShop/' + id).remove();
  // }
  deleteUserFrombiz(user) {
    // console.log('user - remove',user);


    this.afDb.object('/BarberShop/' + this.shopID + '/Users/' + user.key).remove();


    if (user.local == true) {
      return;
    }

    let vrs = this.afDb.object('/Users/' + user.key).valueChanges().subscribe(x => {

      let data: any = x;
      if (data.bizArray) {
        var found = data.bizArray.filter(e => e !== this.shopID);
        console.log("found", found);
        var foundB;
        if (found[0]) {

          foundB = found[0];
        } else {
          foundB = "";
        }
        this.afDb.object('/Users/' + user.key)
          .update({
            BarberID: foundB,
            bizArray: found
          });

      } else {

        this.afDb.object('/Users/' + user.key)
          .update({
            BarberID: ''
          });
      }


      vrs.unsubscribe();
    })
    //  this.afDb.object('/Users/' + user.key + '/meetings/' + this.shopID + '/'+ key.event.key ).remove();

  }

  updateUser(user) {
    this.afDb.object('/BarberShop/' + this.shopID + '/Users/' + user.key)
      .update(user);
  }

  getNeedConfirmBuis(user) {
    return this.afDb.database.ref('/Users/' + user.key + '/needConfirmBuis/').once('value');
  }

  getBizArray(user) {
    return this.afDb.database.ref('/Users/' + user.key + '/bizArray/').once('value');
  }

  getBizObj(user) {
    return this.afDb.database.ref('/Users/' + user.key + '/bizObject/').once('value');
  }

  deleteUserFromBarber(user) {
    this.afDb.object('/BarberShop/' + this.shopID + '/Users/' + user.key).remove();
  }

  deleteBusiFromNeedConfirm(user, key) {
    return this.afDb.database.ref('/Users/' + user.key + '/needConfirmBuis/' + key).remove();
  }

  deleteBusiFromBizArray(user, arr) {
    return this.afDb.database.ref('/Users/' + user.key + '/bizArray/').set(arr);
  }

  addUserToBarber(obj) {
    this.log(obj.key, 'addUserToBarber');
    this.checkIfuserExist(obj);
    return;
  }


  updateUserToBarberNew(obj, key) {
    var data = {
      userData: obj,
      BarberId: this.shopID,
      key: key
    }
    console.log(data);
    var newSend = this.cryptoService.Encrypt(data);
    console.log(newSend)
    // this.checkIfuserExistforupdate(obj);
    this.updateUserToBarber(obj, obj.key);

    return;
    this.afDb.list('/BarberShop/' + this.shopID + '/Users/').push(obj);
  }

  checkIfuserExistforupdate(obj) {
    console.log('!!!!!1');

    this.afDb.database
      .ref('/Users/').orderByChild('phone').equalTo(obj.phone).once('value').then(res => {
        console.log('!!!!!2');

        if (res.val()) {
          console.log('!!!!!3');

          var data = this.json2array(res.val());
          if (data.length > 0) {
            var lookfor = true;
            data.forEach(x => {
              if ((x.isValid == "Verify") && (lookfor == true)) {
                this.deleteUserFrombiz(obj)
                this.pushUserToBarber(x);
                lookfor = false;
                return;
              }
            })

            if (lookfor == true) {
              /// add sms 
              console.log('this.sendSMS_FLAG', this.sendSMS_FLAG)
              if (this.sendSMS_FLAG) {
                this.smsS.send_new_contacts(obj, this.shopData.Setting);
              }
              this.updateUserToBarber(obj, obj.key);
            }
          }
        } else {
          console.log('!!!!!4');

          this.updateUserToBarber(obj, obj.key);
          /// add sms 
          console.log('this.sendSMS_FLAG', this.sendSMS_FLAG)
          if (this.sendSMS_FLAG) {
            this.smsS.send_new_contacts(obj, this.shopData.Setting);
          }

          this.afDb.database
            .ref('/indexPhone/' + obj.phone + '/Barbers/' + this.shopID).update({
              BarberID: this.shopID,
              local: "yes"
            })
          console.log("no user like thi");
        }
      })
  }


  loadUserDataByPhone(phone) {
    return this.afDb.database
      .ref('/Users/').orderByChild('phone').equalTo(phone).once('value')

  }


  sendSMS_FLAG = true;


  updateUserAdmin(key, uid) {
    var data = {
      key: key,
      uid: uid
    }

    console.log(data);
    var newSend = this.cryptoService.Encrypt(data);

    console.log(newSend);
    this._http.post('' + this.serverDev + '/updateUserAdmin/', newSend).subscribe(res => {
      console.log(res)
    });
  }


  async sendCupon(cuponData) {

    const self = this;
    var data = {
      cupon: cuponData,
      uid: this.us.user.key,
      BarberId: this.shopData.Setting.BarberID,
      username: this.us.user.fname + ' ' + this.us.user.lname
    }
    this.LoadingService.presentforever();
    console.log(data);
    var newSend = this.cryptoService.Encrypt(data);

    console.log(newSend);

    this._http.post('' + this.serverDev + '/getCupon/', newSend).subscribe(res => {

      console.log(res['_body']);

      let data = JSON.parse(res['_body']);
      this.LoadingService.dismiss();

      if (data.validity) {
        this.showToast(data.message);
      } else {
        this.showfailToast(data.message);
      }


    })
  }


  async showfailToast(message) {
    let tttt = await this.toastController.create({
      message: `${message}`,
      position: 'top',
      color: 'danger',
      cssClass: 'toastCustomWebc',
      duration: 2000,
      mode: 'ios'
    });
    return await tttt.present();
  }

  removeall(uid) {


    var data = {

      BarberId: uid,


    }

    console.log(data);
    var newSend = this.cryptoService.Encrypt(data);

    console.log(newSend);

    this._http.post('' + this.serverDev + '/golanfunction/', newSend).subscribe(res => {

      console.log(res)
    })
  }

  reloadUserNew(phone) {
    var data = {
      userId: this.us.user.key,
      phoneN: phone,
    }
    var newSend = this.cryptoService.Encrypt(data);
    console.log("send reloadd!")
    // this._http.post('' + this.serverDev + '/newUserload', newSend).subscribe(res => {
    //   if (res) {
    //     this.loadRealUser(phone);
    //   }

    //   console.log('reloadUserNew', res)
    // })
    this._http.post('' + this.serverDev + '/newUserLoginPhone', newSend).subscribe(res => {
      if (res) {
        this.loadRealUser(phone);
      }

      console.log('reloadUserNew', res)
    })
  }

  send_notif(data) {
    data.business = {
      logo: this.shopData.Setting.logo,
      name: this.shopData.Setting.name,
    }

    // data.users.forEach(user => {
    //   user = {
    //     ProfilePic: user.ProfilePic,
    //     fname: user.fname,
    //     lname: user.lname,
    //     notification: user.notification
    //   }
    // });

    this._http.post(this.serverDev + '/create_notif', data).subscribe(res => {
      console.log(res)
    });
  }

  // send_sms(data) {
  //   data.business = { name: this.shopData.Setting.name };
  //   data.users = [this.us.user];
  //   console.log(data);

  //   this._http.post(this.serverDev + '/create_sms', data).subscribe(res => {
  //     console.log(res)
  //   });
  // }

  loadMeetings(phone) {
    var data = {
      userId: this.us.user.key,
      phoneN: phone,
    }
    var newSend = this.cryptoService.Encrypt(data);
    this._http.post('' + this.serverDev + '/loadMeetings', newSend).subscribe(res => {
      console.log(res)
    })
  }

  loadRealUser(phone) {

    var data = {
      userId: this.us.user.key,
      phoneN: phone,
    }
    console.log(data);
    console.log(this.us.user);
    var newSend = this.cryptoService.Encrypt(data);
    this._http.post('' + this.serverDev + '/loadRealUser', newSend).subscribe(res => {
      console.log(res)
    })
  }

  checkIfuserExist(obj) {
    console.log("obj", obj);
    var data = {
      userData: obj,
      BarberId: this.shopID,
    }

    console.log(data);
    var newSend = this.cryptoService.Encrypt(data);
    console.log(newSend);
    this.LoadingService.present8();
    this._http.post('' + this.serverDev + '/addUserToBarber', newSend).subscribe(res => {
      this.LoadingService.dismiss();
      this.loadUserData();
      if (res["_body"] == "new-user" && this.sendSMS_FLAG) {
        console.log(res["_body"], " == new-user  ,  sendSMS_FLAG", this.sendSMS_FLAG);

        this.smsS.send_new_contacts(obj, this.shopData.Setting);
      }
      console.log(res)
    })
    return;
  }


  // checkIfuserExistContactList(obj) {
  //   console.log("obj", obj);

  //   var data = {
  //     userData: obj,
  //     BarberId: this.shopID,
  //   }

  //   var newSend = this.cryptoService.Encrypt(data);
  //   this._http.post('' + this.serverDev + '/addUserToBarber', newSend).subscribe(res => {

  //     this.loadUserData();

  //     if (res["_body"] == "new-user") {
  //       this.smsS.send_new_contacts(obj, this.shopData.Setting);

  //     }
  //     console.log(res)
  //   })


  //   return;

  // }



  loadUserData() {
    var userS = this.json2array(this.shopData.Users);
    this.emptyClientsFLAG = userS.length;

    userS.forEach(element => {
      var el: any = element;



    });
  }



  userStatus() {
    console.log("user data userStatus", this.us.user);

    var data = {
      userData: this.us.user,
      BarberId: this.shopID,
    }

    console.log(data);
    var newSend = this.cryptoService.Encrypt(data);

    // console.log(newSend)
    return this._http.post('' + this.serverDev + '/UserStatus', newSend).subscribe(res => {
      console.log("res userStatus", res["_body"]);
      if (res["_body"] == "problem") {
        this.presentModal_somethingwentwrong();
      }
      if (res["_body"] == "problemF") {
        this.presentModal_somethingwentwrong();
      }
    })
  }
  pushUserToBarber(user) {
    var data = {
      userData: user,
      BarberId: this.shopID,
    }

    console.log(data);
    var newSend = this.cryptoService.Encrypt(data);

    console.log(newSend)
    return this._http.post('' + this.serverDev + 'pushUserToBarber', newSend).subscribe(res => {
      console.log("res", res)
    })
  }

  update_User_currentVersion(currentVersion, key) {
    this.afDb.object('/Users/' + key + '/currentVersion').set(currentVersion);
  }


  updateUserToBarber(obj, key) {
    this.afDb.object('/BarberShop/' + this.shopID + '/Users/' + key).set(obj);
  }

  pushNeWbarber(obj) {
    if (obj.code) {
      this.afDb.object('/Codes/' + obj.code).set(obj);
    }

  }

  updateBarberService(ser) {
    console.log(this.shopID)
    return this.afDb.object('/BarberShop/' + this.shopID + '/MeetingType/').set(ser);
  }
  updateMettingType() {
    return this.afDb.object('/BarberShop/' + this.shopID + '/MeetingType/').update(this.shopData.MeetingType);
  }
  updateFinisht() {
    console.log(this.shopID)
    return this.afDb.object('/BarberShop/' + this.shopID + '/Setting').update({
      buisStatus: "aftert"
    });
  }
  updateUserNewToBarber(barber, obj, key) {
    let filter_user = {};
    this.userFilterDetails.forEach(detail => {
      if (obj[detail]) {
        filter_user[detail] = obj[detail];
      }
    });
    if (key) {
      this.afDb.object('/BarberShop/' + barber + '/Users/' + key).update(filter_user);
    }
  }
  LoadService(stype) {

    return this.afDb.object('/serviceTemplates/' + stype).valueChanges()

  }
  userFilterDetails = ['Devices', 'ProfilePic', 'email', 'fname', 'lname', 'isValid', 'key', 'lastSeen', 'notification', 'phone', 'confirm_status'];


  updateUserOnBarberAdmin(user: any, key) {
    var BarberID = this.shopID;
    var myDate
    if (user.myDate) {
      myDate = user.myDate;
    } else {
      myDate = "no date";
    }

    var newUserObj = {
      "ProfilePic": user.ProfilePic,
      "BarberID": user.BarberID,
      "email": user.email,
      "fname": user.fname,
      "lname": user.lname,
      "myDate": myDate,
      "phone": user.phone,
      "key": key
    }
    this.updateUserNewToBarber(BarberID, newUserObj, key);

  }


  updateUserOnBarber() {
    var BarberID = this.us.user.BarberID;
    var key = this.us.user.key;

    var myDate
    if (this.us.user.myDate) {
      myDate = this.us.user.myDate;
    } else {
      myDate = "no date";
    }


    var newUserObj = {
      "ProfilePic": this.us.user.ProfilePic,
      "BarberID": this.us.user.BarberID,
      "email": this.us.user.email,
      "fname": this.us.user.fname,
      "lname": this.us.user.lname,
      "myDate": myDate,
      "phone": this.us.user.phone,
      "key": this.us.user.key
    }
    // console.log("userData",this.us.user,this.us.user.BarberID);
    this.updateUserNewToBarber(BarberID, newUserObj, key);

  }

  getBusinessDataForUser(id) {
    this.getShopWorkDaysForUser(id).then(
      (shopWorkDays: any) => {
        console.log('shopWorkDays', shopWorkDays)
        this.shopData['WorkDays'] = shopWorkDays.val();
        // getShopWorkDays.unsubscribe()
      });

    const getPosts = this.getShopPosts(id).valueChanges().subscribe(
      (Posts: any) => {
        console.log('Posts', Posts)
        this.shopData['Posts'] = Posts;
        getPosts.unsubscribe();
      });

    this.getUserDetails(this.shopData.Setting.userManager, 'notification').then(res => {
      let notification = res.val();
      console.log(notification);
      if (notification) {
        this.shopData['admin'] = { notification };
      }
    });

    this.getShopWallet(id).then((res: any) => {
      let wallet = res.val();
      console.log(wallet);
      if (wallet) {
        if (wallet.wallet_setup_status) {

          this.shopData['Wallet'] = { wallet_setup_status: wallet.wallet_setup_status };
        }
      }
    })

    console.log(this.shopData);

    this.setNeedConfirm(id);
  }

  setNeedConfirm(id) {
    let need_confirm = (this.us.user.needConfirmBuis && this.us.user.needConfirmBuis.hasOwnProperty(id)) ? true : false;
    this.shopData['need_confirm'] = need_confirm;
  }


  // setReminderClient(newMeet) {

  //   // console.log('newMeetnewMeetnewMeet', newMeet);
  //   if (newMeet.reminder != '0') {
  //     let StartTime: any = new Date(newMeet.startDate)

  //     let end: any = new Date(newMeet.startDate);
  //     end.setTime(StartTime.getTime() - (newMeet.meetTypeData.reminder * 60 * 1000));
  //     end = end.toUTCString();
  //     newMeet['remindAt'] = end.toString();
  //     newMeet['BizName'] = this.shopData.Setting.name;
  //     this.afDb.object('/Reminders/' + newMeet.meetTypeData.key).set(newMeet);

  //   }

  // }


  // setReminder(newMeet, newID) {

  //   // console.log('newMeetnewMeetnewMeet', newMeet.uId, newMeet, newID);
  //   if (newMeet.meetTypeData && newMeet.meetTypeData.reminder != '0' && newMeet.uIdata && !newMeet.uIdata.local && newMeet.uId != "localUserAdmin") {
  //     let StartTime: any = new Date(newMeet.startDate)

  //     let end: any = new Date(newMeet.startDate);
  //     end.setTime(StartTime.getTime() - (newMeet.meetTypeData.reminder * 60 * 1000));
  //     end = end.toUTCString();
  //     newMeet['remindAt'] = end.toString();
  //     newMeet['BizName'] = this.shopData.Setting.name;
  //     this.afDb.object('/Reminders/' + newID).set(newMeet);

  //   }

  // }  


  // setMeeting(userId, appDate, newID, newMeet) {
  //   console.log('/BarberShop/' + userId + "/Meetings/" + appDate + "/" + newID);
  //   let baraber = this.afDb.object('/BarberShop/' + userId + "/Meetings/" + appDate + "/" + newID);
  //   baraber.set(newMeet);
  //   // REMINER HIDE
  //   this.setReminder(newMeet, newID)
  // }
  // setMeetingSMALLmODAL(userId, appDate, newID, newMeet) {

  //   // REMINER HIDE
  //   this.setReminder(newMeet, newID);


  //   return this.afDb.object('/BarberShop/' + userId + "/Meetings/" + appDate + "/" + newID).set(newMeet);

  // }


  // CreateNewEvent(objectEvent) {
  //   this.eventForBuis.importEvent(objectEvent);
  //   this.meet_api('create_event_business', this.eventForBuis.exportEventAdmin());
  //   // this.addEvent(this.eventForBuis.exportEventAdmin());
  // }

  // setSmallModalNew(meetTypeObj, dateTime, UserSelected, moreInfo, valueTime, sendSms, zoomlink) {
  //   this.meetForBuis.importMeetType(meetTypeObj);
  //   this.meetForBuis.importZoomlink(zoomlink)
  //   this.meetForBuis.importSendSms(sendSms);
  //   this.meetForBuis.importStartDateAndHoureview(dateTime);
  //   this.meetForBuis.importBarber(this.shopData.Setting.BarberID, this.shopData.Setting.name);
  //   this.meetForBuis.importUserId(UserSelected.key);
  //   this.meetForBuis.importMoreInfo(moreInfo);
  //   this.meetForBuis.defineMeetTime(valueTime);
  //   this.meetForBuis.CreateNewMeet();
  //   this.meetForBuis.importPrice();
  //   this.meetForBuis.defineFlow(meetTypeObj.flow);
  //   this.meetForBuis.defineStatus(1);
  //   this.meetForBuis.showClass();
  //   this.meetForBuis.setAdminMeet();
  //   this.addCounter();


  //   if ((UserSelected.local == true) || (this.meetForBuis.uId == "localUserAdmin")) {
  //     console.log("localUser");
  //   } else {
  //     this.meetForBuis.setUserMeet();
  //     console.log("noooo local")
  //   }

  //   console.log("UserSelected ----> ", UserSelected)
  //   let nmeet = this.meetForBuis.exportMeetAdmin();
  //   nmeet["uIdata"] = {
  //     fname: UserSelected.fname,
  //     lname: UserSelected.lname,
  //     phone: UserSelected.phone
  //   }
  //   let data_for_reminder = this.meetForBuis.exportMeetAdmin();
  //   data_for_reminder['uIdata'] = {
  //     fname: UserSelected.fname,
  //     lname: UserSelected.lname
  //   };
  //   this.log(UserSelected.key, 'meet_add');

  //   if (!this.check_if_date_in_past(nmeet.meetDate, nmeet.startTimeView)) {
  //     // check if in the past

  //     this.setReminder(data_for_reminder, this.meetForBuis.key)
  //   }

  //   console.log("nmeet ----> ", nmeet)

  //   //  this.setReminder(nmeet, this.meetForBuis.key);
  //   console.log("saveForIndex------", this.meetForBuis.uId, "------------------------", nmeet["uIdata"].phone);


  //   if ((UserSelected.local == true) && (nmeet["uIdata"].phone != "")) {
  //     // check if in the past
  //     if (!this.check_if_date_in_past(nmeet.meetDate, nmeet.startTimeView)) {
  //       this.smsS.sendSMS_local_client(this.meetForBuis.exportMeetAdmin(), UserSelected, 'new_local_client_meet');
  //       this.showToast('נשלח sms ללקוח');
  //     }

  //     this.meetForBuis.saveForIndex(nmeet["uIdata"].phone);
  //   }
  //   this.meetForBuis.saveBuisHistory();


  // }
  // addCounter() {
  //   if (this.shopData.Setting.meetCounter) {
  //     this.shopData.Setting.meetCounter++;

  //   } else {
  //     this.shopData.Setting.meetCounter = 1;
  //   }
  //   this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/')
  //     .update({
  //       meetCounter: this.shopData.Setting.meetCounter,

  //     })

  // }

  updateMeeting(key, filed, newD) {
    // console.log('this.shopID:', this.shopID);
    // console.log('filed:', filed);
    // console.log('newD:', newD);

    let dddd = key.event.meetDateFormat.replace(".", "-");
    dddd = dddd.replace(".", "-");
    //   console.log('key.event:',key.event);
    //  console.log('dddd:',dddd);
    let up: any = {
    }

    up[filed] = newD;
    //console.log('up:',up);
    up[filed]
    this.afDb.object('/BarberShop/' + this.shopID + '/Meetings/' + dddd + '/' + key.event.key)
      .update(
        up
      );

    this.afDb.object('/Users/' + key.event.uId + '/meetings/' + this.shopID + '/' + key.event.key)
      .update(
        up
      );


  }



  get_reminder_id(meet) {
    if (meet.meetTypeData.typem == 'event') {
      return meet.meetTypeData.key + '_' + meet.meetTypeData.uId;
    } else {
      return meet.meetTypeData.key
    }
  }


  setReminderClient(newMeet) {

    console.log('newMeetnewMeetnewMeet', newMeet);
    let meet_key = this.get_reminder_id(newMeet);
    if (newMeet.meetTypeData.reminder != '0') {

      let StartTime: any = new Date(newMeet.meetTypeData.startDate)

      let end: any = new Date(newMeet.meetTypeData.startDate);
      end.setTime(StartTime.getTime() - (newMeet.meetTypeData.reminder * 60 * 1000));
      end.setSeconds(0);
      end = end.toUTCString();
      newMeet.meetTypeData['remindAt'] = end.toString();
      newMeet.meetTypeData['BizName'] = newMeet.meetTypeData.barberName;

      newMeet.meetTypeData['uIdata'] = {
        fname: this.us.user.fname,
        lname: this.us.user.lname
      };

      console.log(meet_key);
      console.log(newMeet);


      this.afDb.object('/Reminders/' + meet_key).set(newMeet.meetTypeData);

    } else {
      this.afDb.object('/Reminders/' + meet_key).remove();
    }

    this.afDb.object('/Users/' + newMeet.meetTypeData.uId + "/meetings/" + newMeet.meetTypeData.BarberID + "/" + newMeet.meetTypeData.key).update({
      reminder: newMeet.meetTypeData.reminder
    });

  }


  // setReminder(newMeet, newID) {
  //   console.log('setReminder:', newMeet, newID);
  //   if (newMeet.meetType && newMeet.meetType.reminder != '0' && newMeet.uId && newMeet.uId != "localUserAdmin" && !this.check_if_date_in_past(newMeet.meetDate, newMeet.startTimeView)) {
  //     let StartTime: any = new Date(newMeet.startDate)
  //     let end: any = new Date(newMeet.startDate);
  //     end.setTime(StartTime.getTime() - (newMeet.meetType.reminder * 60 * 1000));
  //     end.setSeconds(0);
  //     end = end.toUTCString();
  //     newMeet['remindAt'] = end.toString();
  //     if (this.meetForBuis.barberName) {
  //       newMeet['BizName'] = this.meetForBuis.barberName;
  //     } else {
  //       newMeet['BizName'] = newMeet.barberName;
  //     }
  //     return newMeet;
  //     // let encrypted = this.cryptoService.Encrypt({ id: newID, meet: newMeet, type: 'set' });
  //     // this.http.post(this.serverDev + '/Reminder', encrypted).subscribe(res => {
  //     //   console.log('Reminder: ', res);
  //     // });
  //   }
  // }

  // updateReminder(meet) {
  //   // this.afDb.object('/Reminders/' + meet.key).update(meet);
  //   let data = { id: meet.key, meet: meet, type: 'update' }
  //   // let encrypted = this.cryptoService.Encrypt({ id: meet.key, meet: meet, type: 'update' });
  //   this.http.post(this.serverDev + '/Reminder', data).subscribe(res => {
  //     console.log('Reminder: ', res);
  //   });
  // }

  // removeReminder(newID) {
  //   // this.afDb.object('/Reminders/' + newID).remove();
  //   let data = { id: newID, meet: null, type: 'remove' };
  //   // let encrypted = this.cryptoService.Encrypt({ id: newID, meet: null, type: 'remove' });
  //   this.http.post(this.serverDev + '/Reminder', data).subscribe(res => {
  //     console.log('Reminder: ', res);
  //   });
  // }


  findInJsonKey(json, find) {
    var result = {};
    var keys = Object.keys(json);
    keys.forEach(function (key) {
      // console.log('key',key);
      if (key == find) {
        // return json[key];
        // json[key].key = key;
        result = json[key];
      }
    });

    // console.log(json["" + find + ""])
    return result;
  }

  json2arraySimple(obj) {
    return Object.keys(obj).map(function (key) {
      return [Number(key), obj[key]];
    });
  }
  json2array(json) {
    var result = [];
    // console.log(json);

    if (json) {

      var keys = Object.keys(json);

      keys.forEach(function (key) {

        if (key != 'key') {
          json[key].key = key;
          result.push(json[key]);
        }
      });
    }
    return result;
  }
  json2array2(json) {
    var result = [];
    if (json) {
      var keys = Object.keys(json);
      //console.log('keys',keys);
      keys.forEach(function (key: any) {

        if ((key != 'key') && (key != "undefined")) {


          if (typeof json[key] === 'object') {
            json[key].key2 = key;
            if (json[key].fname) {
              result.push(json[key]);
            }
          }

        }
      });
    }
    return result;
  }
  checkEmptyAndSpaceOnly(str) {
    let newStr = str.replace(/ /g, '');
    if (newStr == '') {
      return false;
    } else {
      return true;
    }
  }

  numberOnlyValidation(event: any) {
    console.log('event', event)
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();

    }
  }

  testest(BarberID) {
    this.afDb.database.ref('/BarberShop/' + BarberID + '/meetCounter').once('value').then(res => {
      let counter = res.val();
      let new_counter = counter ? counter + 1 : 1;
      this.afDb.database.ref('/BarberShop/' + BarberID).update({ meetCounter: new_counter });
    });
  }

  scrollToID(id) {
    let el;
    setTimeout(() => {
      el = document.getElementById(id);
      // console.log('el-', el);
      if (el) {
        // console.log('scroll!!!!');
        el.scrollIntoView();
      } else {
        console.log('somwthing waiting');
      }
    }, 200);


    // el.scrollIntoView();
  }

  getcornalist() {
    return this.afDb.object('/coronavirus/' + this.shopID).valueChanges()
  }

  // addEvent(eventData) {
  //   this.log(eventData.uId, 'event_add');
  //   return this.afDb.database.ref('/BarberShop/' + eventData.BarberID + '/Events/' + this.eventForBuis.convertDateToYYYY(this.eventForBuis.meetDate) + "/" + eventData.key).set(eventData)
  // }


  updateUserStatusToApprove(userData) {
    console.log('/Users/' + userData.uId + '/meetings/' + this.eventForBuis.BarberID + '/' + this.eventForBuis.key);
    return this.afDb.database.ref('/Users/' + userData.uId + '/meetings/' + this.eventForBuis.BarberID + '/' + this.eventForBuis.key).update({
      "eventStatus": 1
    })

  }

  approveUserEvent(data) {
    console.log(data);
    let foundUser = this.eventForBuis.uId.find(user => user.uId == data.uId);
    if (foundUser) {
      foundUser.status = 'ok';
    }
    console.log(this.eventForBuis.exportEventAdmin());

    this.meet_api('update_event_business', { meet: this.eventForBuis.exportEventAdmin(), ShopSetting: this.shopData.Setting, function: 'approveUserEvent' });

    // this.globalEventUpdate(this.eventForBuis.exportEventAdmin());
    let new_event: any = this.eventForBuis.exportEventUser();
    new_event.eventStatus = 1;
    new_event.uId = data.uId;
    // this.meet_api('approve_event_user_business', { meet: new_event, userid: data.uId });
    this.meet_api('update_event_user_business', { meet: new_event, userid: data.uId, ShopSetting: this.shopData.Setting, userData: this.shopData.Users[data.uId], notif_type: 'approve_user_to_event', function: 'approveUserEvent' });










    // this.updateUserStatusToApprove(data).then(() => {
    // NOTIFICATION
    // let notif_data = {
    //   type: 'approve_user_to_event',
    //   users: [data],
    //   meet: {
    //     name: new_event.eventName,
    //     startTimeView: new_event.startTimeView,
    //     meetDate: new_event.meetDate
    //   }
    // }
    // this.send_notif(notif_data);
    // // REMINDER
    // this.prepare_event_reminder(this.eventForBuis.exportEventAdmin(), data);
    // });
  }

  // updateUserAgent(userID, userAgent) {
  //   // return this.afDb.database.ref('/Users/' + userID + '/meetings/').push(userAgent);
  //   // return this.afDb.database.ref('/Users/' + userID + '/devices/').set({userAgent});
  //   return this.afDb.database.ref('/Users/' + userID).set({
  //     "Devices": userAgent
  //   });
  // }

  // getUserAgent() {
  //   const userAgent = window.navigator.userAgent;
  //   console.log(userAgent);
  //   this.updateUserAgent()
  //   // this.shopS.updateUserAgent()
  // }



  // prepare_event_reminder(eventData, userData) {
  //   if (userData.uId) {
  //     eventData.uId = userData.uId;
  //   } else {
  //     eventData.uId = userData.key;
  //   }

  //   let reminder_amount;
  //   if (eventData.reminder) {
  //     reminder_amount = eventData.reminder;
  //   } else {
  //     reminder_amount = 60;
  //   }

  //   let data_for_reminder = JSON.parse(JSON.stringify(eventData));
  //   data_for_reminder['uIdata'] = {
  //     fname: userData.fname,
  //     lname: userData.lname
  //   };
  //   data_for_reminder.key = data_for_reminder.key + '-' + eventData.uId;
  //   data_for_reminder.BizName = eventData.barberName
  //   data_for_reminder['meetType'] = {
  //     name: eventData.eventName,
  //     reminder: reminder_amount,
  //   }
  //   data_for_reminder['meetTypeData'] = {
  //     name: eventData.eventName,
  //     reminder: reminder_amount,
  //   }


  //   // if (!this.check_if_date_in_past(data_for_reminder.meetDate, data_for_reminder.startTimeView)) {
  //   this.setReminder(data_for_reminder, data_for_reminder.key);

  //   // }


  // }


  // if (!this.check_if_date_in_past(data_for_reminder.meetDate, data_for_reminder.startTimeView)) {
  // this.setReminder(data_for_reminder, data_for_reminder.key);













  checkZoom(str) {
    // var str = "https://zoom.us/skdaj;dlksaj;dklsjaldkjsalkdjaslkdjsalkdja";
    // var res = str.substring(0, 15);
    if (str.substring(0, 15) == 'https://zoom.us') {
      return true;
    } else {
      return false
    }


  }


  getUserDetails(userid, detail) {
    return this.afDb.database.ref('/Users/' + userid + '/' + detail).once('value');
  }




































  // setEventUser(eventData, userData) {
  //   eventData.uId = userData.key;
  //   this.meet_api('add_event_user', { meet: eventData, userid: userData.key });
  // }


  // setEventUserr(eventData, userData, cb?) {
  //   eventData.uId = userData.key;
  //   console.log('eventData', eventData);
  //   console.log('userData', userData);
  //   this.log(eventData.uId, 'setEventUser');
  //   if (this.AdminFLAG) {
  //     eventData.eventStatus = 1;
  //     this.meet_api('add_event_business', eventData, cb);
  //   } else {
  //     this.meet_api('add_event_user', eventData, cb);
  //   }

  //   // return this.afDb.database.ref('/Users/' + userData.key + '/meetings/' + eventData.BarberID + '/' + eventData.key).set(eventData);
  // }

  removeEventFromUser(eventData) {
    this.log(eventData.uId, 'removeEventFromUser');
    this.moveEventToUserHistory(eventData);
    this.afDb.object('/Users/' + eventData.uId + "/meetings/" + eventData.BarberID + "/" + eventData.key).remove().catch();
  }
  moveEventToUserHistory(eventData) {
    console.log('/Users/' + eventData.uId + '/History/' + eventData.key);
    this.afDb.object('/Users/' + eventData.uId + '/History/' + eventData.key)
      .update(eventData);
  }

  updatePopleEvent(eventData, uId) {
    console.log(eventData, uId)
    return this.afDb.database.ref('/Users/' + uId + '/meetings/' + eventData.BarberID + '/' + eventData.key).update(eventData)

  }

  saveBuisHistoryEvents(dataEvent) {
    var BarberHistory = {
      "barber": dataEvent,
      'Mdate': dataEvent.meetDate,
      'userId': dataEvent.uId,
      'BarberID': dataEvent.BarberID,
    }
    this.afDb.object('/BarberShop/' + dataEvent.BarberID + "/History/" + dataEvent.key).set(BarberHistory);

  }


  loadEventById(eventData) {
    return this.afDb.database.ref('/BarberShop/' + eventData.BarberID + '/Events/' + this.eventForBuis.convertDateToYYYY(eventData.meetDate) + "/" + eventData.key).once('value')

  }
  addUserAndUpdateEvent(eventData, userData, who) {
    var userForEvent = {
      uId: userData.key,
      ProfilePic: userData.ProfilePic ? userData.ProfilePic : "",
      fname: userData.fname ? userData.fname : "",
      lname: userData.lname ? userData.lname : "",
      phone: userData.phone ? userData.phone : "",
      status: (this.eventForBuis.needConfirm == 3 && !this.AdminFLAG) ? 'wait' : 'ok'
    }

    if (who == 'business') {
      if (eventData.uId == "newevent" || !eventData.uId || !eventData.uId.length || typeof (eventData.uId) == 'string') {
        eventData.uId = [];
      }
      eventData.uId.push(userForEvent);
      eventData.CatchPeople = eventData.uId.length;
    }
    console.log(eventData);
    console.log(userForEvent);

    this.meet_api('add_user_to_event_' + who, { meet: eventData, userid: userData.key, userData: userForEvent, ShopSetting: this.shopData.Setting, function: 'addUserAndUpdateEvent' });
  }

  deleteUserAndUpdateEvent(eventData, uId, who, cb?) {
    if (who == 'business') {
      eventData.uId = eventData.uId.filter(user => user.uId != uId);
      eventData.CatchPeople--;
    }
    // this.meet_api('update_event_business', eventData, cb);
    this.meet_api('remove_user_from_event_' + who, { meet: eventData, userid: uId, userData: { uId }, ShopSetting: this.shopData.Setting, function: 'deleteUserAndUpdateEvent' }, cb);
  }

  // removeUserFromEventOfAdmin(eventData, uId) {
  //   let index = eventData.uId.findIndex(x => x.uId === uId);
  //   eventData.uId.splice(index, 1);
  //   if (eventData.uId.length == 0) {
  //     eventData.uId = new Array();
  //   }
  //   eventData.CatchPeople--;
  //   this.globalEventUpdate(eventData);
  // }

  removeUserFromEvent(eventData, uId) {
    // this.log(uId, 'removeUserFromEvent');
    console.log(eventData);

    this.deleteUserAndUpdateEvent(eventData, uId, 'business');
    // this.globalEventUpdate(eventData);
    if (uId != 'localUserAdmin') {
      let new_event: any = this.eventForBuis.exportEventUser(eventData);
      new_event.uId = uId;
      new_event.eventStatus = 5;
      let notif_type = this.eventForBuis.eventStatus == 3 ? 'remove_user_from_event' : 'remove_user_to_event_aborted';
      console.log(new_event);
      this.meet_api('update_event_user_business', { meet: new_event, userid: uId, ShopSetting: this.shopData.Setting, userData: this.shopData.Users[uId], notif_type, function: 'removeUserFromEvent' });
    }













    // let meet_notif = {
    //   name: eventData.eventName,
    //   startTimeView: eventData.startTimeView,
    //   meetDate: eventData.meetDate
    // }
    // this.checkIfRealUser(uId).then(res => {
    //   let user = res.val();
    //   console.log("if user realy ", user, uId);
    //   if (user && uId != 'localUserAdmin') {
    //     eventData.uId = uId;
    //     eventData.eventStatus = 5;
    //     let new_event = this.eventForBuis.exportEventUser(eventData);
    //     // this.updateUserEventStatusToCancel(eventData, uId);
    //     this.meet_api('update_event_user_business', { meet: new_event, userid: uId });
    //     // this.meet_api('delete_user_from_event_business ', {event: eventData, uId: uId});
    //     // NOTIFICATION
    //     let notif_data = {
    //       type: 'remove_user_from_event',
    //       users: [user],
    //       meet: meet_notif
    //     }
    //     this.send_notif(notif_data);
    //     // DELETE REMINDER
    //     this.removeReminder(eventData.key + '-' + uId)
    //   } else {
    //     // SEND SMS
    //     if (user.phone) {
    //       let sms_data = {
    //         type: 'remove_user_from_event',
    //         to_phone: user.phone,
    //         meet: meet_notif
    //       }
    //       this.send_sms(sms_data);
    //     }
    //     // this.smsS.sendSMS_local_client(eventData, userToDelet, 'deleteUserEvent')
    //   }
    // });
  }


  updateToCancelAdminmeet(meet) {
    console.log("updateToCancelAdmin")
    return this.afDb.object('/BarberShop/' + meet.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(meet.meetDate) + "/" + meet.key).update({
      meetStatus: 5,

    }).catch()
  }

  updateRepeatMeet(meet, repeat_meet) {
    console.log('/BarberShop/' + meet.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(meet.meetDate) + "/" + meet.key + '/repeat_arr');

    return this.afDb.object('/BarberShop/' + meet.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(meet.meetDate) + "/" + meet.key).update({ repeat_meet: repeat_meet }).catch();
  }

  checkTimeZoneDiff() {
    // var zone_name = moment.tz.guess();
    // var current_timezone = moment.tz(zone_name);
    // let my_utcOffset = moment.parseZone(current_timezone).utcOffset();
    var def_timezone = moment.tz("Asia/Jerusalem");
    let def_utcOffset = moment.parseZone(def_timezone).utcOffset();
    let busi_utcOffset;
    if (this.shopData.Setting.timezone) {
      var busi_timezone = moment.tz(this.shopData.Setting.timezone);
      busi_utcOffset = moment.parseZone(busi_timezone).utcOffset();
    } else {
      busi_utcOffset = def_utcOffset;
    }
    // let busi_utcOffset = this.shopData.Setting.timezone ? moment.parseZone(this.shopData.Setting.timezone).utcOffset() : def_utcOffset;
    let my_utcOffset = new Date().getTimezoneOffset() * (-1);
    console.log(def_utcOffset);
    console.log(busi_utcOffset);
    console.log(my_utcOffset);

    if (my_utcOffset != busi_utcOffset) {
      return true;
    } else {
      return false;
    }
  }


  // addUserEventFromModal(userData) {
  //   this.addUserToEvent(this.eventForBuis.exportEventAdmin(), userData)
  // }
  signInToEvent(eventData) {
    console.log(eventData);
    let user = this.us.user;
    // this.setEventUser(eventData, user);
    eventData.uId = user.key;
    eventData.timestamp = new Date().getTime();
    this.meet_api('add_event_user', { meet: eventData, userid: user.key, ShopSetting: this.shopData.Setting, function: 'signInToEvent' });
    this.addUserAndUpdateEvent(eventData, user, 'user');

    // NOTIFICATION
    if (this.shopData.admin) {
      let notif_data = {
        type: '',
        send_to: [this.shopData.admin],
        user: user,
        meet: {
          name: this.eventForBuis.eventName,
          startTimeView: this.eventForBuis.startTimeView,
          meetDate: this.eventForBuis.meetDate
        }
      }
      this.send_notif(notif_data);
    }


    // REMINDER
    // if (eventData.eventStatus != 3) {
    //   this.prepare_event_reminder(eventData, user);
    //   notif_data.type = 'user_add_event';
    // } else {
    //   notif_data.type = 'user_add_event_needApprove';
    // }

    //BELL
    this.addNotificationBell('user_sign_to_event', 'business', { meet: eventData });

    this.correctAppoin = "";
    this.needApproveModalFlag = this.eventForBuis.needConfirm == 3 ? false : true;
    this.alertModal = true;
    this.confettiFLAG = false;
    this.eventForBuis.resetCLass();
    // this.saveBuisHistoryEvents(eventData);
    this.router.navigate(['tabs/home'], { replaceUrl: true });
  }

  addUserToEvent(eventData, userData) {
    console.log(eventData, userData);
    // eventData.eventStatus = 1;
    this.addUserAndUpdateEvent(eventData, userData, 'business');
    if (userData.key != 'localUserAdmin') {
      let user_event: any = this.eventForBuis.exportEventUser(eventData);
      user_event.uId = userData.key;
      user_event.eventStatus = 1;
      user_event.timestamp = new Date().getTime();
      console.log("CHECK sendSms", user_event);
      this.meet_api('add_event_business', { meet: user_event, userid: userData.key, ShopSetting: this.shopData.Setting, userData: this.shopData.Users[userData.key], notif_type: 'add_user_to_event', function: 'addUserToEvent' });
      // this.meet_api('update_event_user_business', { meet: new_event, userid: userData.key, logo: this.shopData.Setting.logo, userData: this.shopData[userData.key], notif_type: 'remove_user_to_event' });
    }












    // this.checkIfRealUser(userData.key).then(res => {
    //   let meet_notif = {
    //     name: this.eventForBuis.eventName,
    //     startTimeView: this.eventForBuis.startTimeView,
    //     meetDate: this.eventForBuis.meetDate
    //   }
    //   let user = res.val();
    //   if (user && user != 'localUserAdmin') {
    //     this.setEventUser(eventData, userData, 'business');

    //     // NOTIFICATION
    //     if (this.eventForBuis.sendSms) {
    //       let notif_data = {
    //         type: 'add_user_to_event',
    //         users: [user],
    //         meet: meet_notif
    //       }

    //       this.send_notif(notif_data);
    //     }
    //     // REMINDER
    //     this.prepare_event_reminder(eventData, userData);
    //   } else {
    //     if (user.phone) {
    //       // SMS
    //       let sms_data = {
    //         type: 'new_local_client_event',
    //         to_phone: user.phone,
    //         meet: meet_notif
    //       }
    //       this.send_sms(sms_data)
    //       this.saveForIndexEvent(userData);
    //       this.showToast('נשלח sms ללקוח');
    //     }
    //   }
    // });

    // console.log("addUserToEvent ---- > ", eventData, userData);
    // this.log(userData, 'addUserToEvent')

    // let meet_notif = {
    //   name: this.eventForBuis.eventName,
    //   startTimeView: this.eventForBuis.startTimeView,
    //   meetDate: this.eventForBuis.meetDate
    // }
    // if (userData.local == true || userData.key == "localUserAdmin") {
    //   if (userData.phone) {
    //     // SMS
    //     let sms_data = {
    //       type: 'new_local_client_event',
    //       to_phone: userData.phone,
    //       meet: meet_notif
    //     }
    //     this.send_sms(sms_data)
    //     this.saveForIndexEvent(userData);
    //     this.showToast('נשלח sms ללקוח');
    //   }

    //   return this.updateEvent(eventData, userData);
    //   // return this.meet_api('update_event_business', { event: eventData, user: userData });
    // } else {
    //   return this.setEventUser(eventData, userData, res => {
    //     console.log(res);
    //     return this.updateEvent(this.eventForBuis.exportEventAdmin(), userData, res2 => {
    //       console.log(res2);
    //       // REMINDER
    //       if (eventData.eventStatus != 3) {
    //         this.prepare_event_reminder(eventData, userData);
    //       }
    //       // NOTIFICATION
    //       let notif_data = {
    //         type: '',
    //         users: [userData],
    //         meet: meet_notif
    //       }

    //       if (who == 'user') {
    //         if (eventData.eventStatus == 3) {
    //           notif_data.type = 'user_add_event_needApprove';
    //         } else {
    //           notif_data.type = 'user_add_event';
    //         }
    //         this.send_notif(notif_data);

    //         //BELL
    //         this.addNotificationBell('user_sign_to_event', 'business', { meet: eventData });

    //         this.correctAppoin = "";
    //         this.needApproveModalFlag = this.eventForBuis.needConfirm == 3 ? false : true;
    //         this.alertModal = true;
    //         this.confettiFLAG = false;
    //         this.eventForBuis.resetCLass();
    //         this.saveBuisHistoryEvents(this.eventForBuis.exportEventAdmin());
    //         this.router.navigate(['tabs/home'], { replaceUrl: true });
    //       } else if (who == 'admin') {
    //         if (this.eventForBuis.sendSms) {
    //           notif_data.type = 'add_user_to_event';
    //           this.send_notif(notif_data);
    //         }
    //       }

    //     })
    //   });
    // }
  }

  // saveForIndexEvent(userdata) {
  //   var UserEvent = this.eventForBuis.exportEventUser();
  //   UserEvent['uId'] = userdata;

  //   let userRef = this.afDb.object('/indexPhone/' + userdata.phone + "/meetings/" + this.eventForBuis.BarberID + "/" + this.eventForBuis.key);
  //   userRef.update(UserEvent);
  // }once(



  updateUserEventStatusToCancel(eventData, uId) {


    let updateDate = {
      eventStatus: 5
    }
    //  this.log(eventData.uId,'updateUserEventStatusToCancel');

    return this.afDb.database.ref('/Users/' + uId + '/meetings/' + eventData.BarberID + '/' + eventData.key).update(updateDate)

  }
  checkIfRealUser(uId) {
    return this.afDb.database.ref('/Users/' + uId).once('value')

  }

  getMeet(meet) {
    return this.afDb.database.ref('/Users/' + meet.uId + '/meetings/' + meet.BarberID + '/' + meet.key).once('value');
  }

  globalEventUpdateFiled(eventData, data, filed) {
    var df = new Object();
    df[filed] = data;
    // console.log("globalEventUpdate", eventData, this.eventForBuis.convertDateToYYYY(eventData.meetDate))

    return this.afDb.database.ref('/BarberShop/' + eventData.BarberID + '/Events/' + this.eventForBuis.convertDateToYYYY(eventData.meetDate) + "/" + eventData.key).update(df)

  }
  globalEventUpdate(eventData) {
    //  this.log(eventData.uId[0].key ,'globalEventUpdate');

    // console.log("globalEventUpdate", eventData, this.eventForBuis.convertDateToYYYY(eventData.meetDate))
    return this.afDb.database.ref('/BarberShop/' + eventData.BarberID + '/Events/' + this.eventForBuis.convertDateToYYYY(eventData.meetDate) + "/" + eventData.key).update(eventData)

  }

  removeEventFromAdmin(eventData) {
    this.log(eventData.key, 'removeEventFromAdmin');

    return this.afDb.database.ref('/BarberShop/' + eventData.BarberID + '/Events/' + this.eventForBuis.convertDateToYYYY(eventData.meetDate) + "/" + eventData.key).remove()

  }

  getAllShopsStartAT() {
    return this.afDb.database
      .ref('/Codes/')


  }


  // turnOnConfetti(sec) {
  //   this.confettiFLAG = false;
  //   setTimeout(() => {
  //     this.confettiFLAG = true;
  //   }, sec);


  // }


  getUserFromUsers(userid) {
    return this.afDb.object('/Users/' + userid)
  }



  //// ---

  sendCall(u) {
    // var url = 'tel://'+u.phone;
    // window.open(url, '_blank');
    console.log(u)
    var phone = u.phone;
    this.callNumber.callNumber(phone, true).then(res => console.log('Launched dialer!', res))
      .catch(err => console.log('Error launching dialer', err));
  }

  sendSMS(u) {
    //https://api.whatsapp.com/send?phone=527420606&text=lkjl
    var url = 'sms://' + u.phone;
    window.open(url, '_blank');
  }


  // smsConfirmLine(phone, message) {
  //   console.log('message', message)
  //   console.log('phone', phone)
  //   // console.log('this.shopS.shopData.Setting.code',this.shopS.shopData.Setting.code)
  //   this.socialSharing.shareViaSMS(message, phone,then => {
  //     // Success
  //   },e=> {
  //     // Error!
  //   });
  //   // var url = 'sms://'+phone+'&body=""';
  //   // window.open(url, '_blank');
  // }
  async share(text) {
    // let url = 'https://point-app.net/download/';
    // let text = `היי, אני רוצה להמליץ לכם על Point, אפליקציה המאפשרת ללקוחות לזמן תורים בעצמם בקליק, ממש נוחה לתפעול ולניהול היומן האישי שלכם. \n הצטרפו עכשיו וקבלו חודש פרימיום מתנה! ` + url;
    if (this.environment.platform == 'app') {
      this.socialSharing.share(text, null, null, null).then(() => {
        // Success
      }).catch((e) => {
        // Error!
        console.log('err', e)
      });
    } else {
      window.open("https://api.whatsapp.com/send?text=" + text);
    }
  }


  sendWhatsapp(u) {
    if (u.phone[0] == '+') {
      var url = 'https://api.whatsapp.com/send?phone=' + u.phone + '&text=שלום  ';
    } else {
      var url = 'whatsapp://send?phone=+972' + u.phone + '&text=שלום';
    }

    console.log('this.platform', this.platform, url);
    if (this.environment.platform == 'web') {
      window.open("https://api.whatsapp.com/send?phone=" + u.phone + "&text=שלום");
    } else {
      this.socialSharing.shareViaWhatsAppToReceiver(url, 'שלום', null /* img */, null /* url */)
    }

  }

  sendWhatsapp2(u) {
    console.log('whatsapp', u);
    //https://api.whatsapp.com/send?phone=527420606&text=lkjl
    if (u.phone == '+') {
      //  alert('this is +972'+ u.phone[0]+ u.phone[1] + u.phone[2] + '         '+ u.phone);
      var url = 'https://api.whatsapp.com/send?phone=' + u.phone + '&text=שלום  ';

    } else {


      var url = 'https://api.whatsapp.com/send?phone=+972' + u.phone + '&text=שלום  ';

    }
    //  window.open(url, '_blank');


    this.socialSharing.shareViaWhatsAppToReceiver(url, 'שלום', null /* img */, null /* url */)
  }

  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  tutrialModeskip = false;
  AdminFLAG = false;
  tutrialMode = false;
  MeetEditmodalFLAG = false;
  contactUS = false;
  searchFocusFlag = false;
  meetmodalFLAG = false;
  ClientmodalFLAG = false;
  addClientmodalFLAG = false;
  openingHoursmodalFLAG = false;
  calendarSettingFLAG = false;
  MeetEditmodalFLAGADD = false;
  SharemodalFLAG = false;
  UserShareToBizFLAG = false;
  alertModal = false;
  plusAnimationFLAG = false;
  explainerFLAF = false;

  plusAnimation() {
    this.plusAnimationFLAG = true;
    setTimeout(() => {
      this.plusAnimationFLAG = false;
    }, 1450);
  }


  async presentAlertConfirm(headerD, messageD, CancelBTN, OkayBTN) {
    let result = false;
    const alert = await this.alertController.create({
      header: headerD,
      message: messageD,
      buttons: [
        {
          text: CancelBTN,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cancel Cancel: Cancel');
            result = false;
          }
        }, {
          text: OkayBTN,
          handler: () => {
            result = true;
            console.log('Okay: Okay');
          }
        }
      ]
    });

    await alert.present();
    return result;
  }


  dateSelected: any;
  disableBtn = false;
  getCurrentMeet() {
    return this.currentMeet;
  }
  SelectedUser: any = {
    fname: 'בדיקה',
    lname: 'בדיקה',
    note: '',
    phone: '0527420404',
    ProfilePic: 'https://images.unsplash.com/photo-1520271348391-049dd132bb7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80',
  }
  currentMeet: any = {
    user: {
      fname: "",
      lname: '',
      phone: '0527420404',
      ProfilePic: '',
    },
    meetType: {
      key: '-KiadQ7abrQ9H4NGNsXF',
      approve: false,
      color: "#4366FB",
      float: true,
      gender: "female",
      info: "987654",
      meetTime: "123",
      name: "דוגמא",
      pic: "לחמ",
      price: "30",
      reminder: "20"
    },
    date: {}
  }
  SelectedMeet: any = {
    event: {
      uIdData: {
        fname: 'בדיקה',
        lname: 'בדיקה',
        phone: '0527420404',
        ProfilePic: 'https://images.unsplash.com/photo-1520271348391-049dd132bb7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80',
      },
      meetTypeData: {
        key: '-',
        approve: false,
        color: "#4366fb",
        float: true,
        gender: "female",
        info: "987654",
        meetTime: "123",
        name: "תספורת גבר",
        pic: "לחמ",
        price: "30",
        reminder: "20",
        hidden: false
      },

      date: {}
    }
  }
  meetTypeData: any = {
  }

  locationSet = {
    address: '',
    country: '',
    city: '',
    street: '',
    street_number: '',
    latitude: 0,
    longitude: 0,
    isLocation: true
  }

  resetData() {
    this.SelectedUser = {
      fname: 'בדיקה',
      lname: 'בדיקה',
      note: '',
      phone: '0527420404',
      ProfilePic: 'https://images.unsplash.com/photo-1520271348391-049dd132bb7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80',
    }
    this.currentMeet = {
      user: {
        fname: "",
        lname: '',
        phone: '0527420404',
        ProfilePic: '',
      },
      meetType: {
        key: '-KiadQ7abrQ9H4NGNsXF',
        approve: false,
        color: "#4366FB",
        float: true,
        gender: "female",
        info: "987654",
        meetTime: "123",
        name: "דוגמא",
        pic: "לחמ",
        price: "30",
        reminder: "20"
      },
      date: {}
    }
    this.SelectedMeet = {
      event: {
        uIdData: {
          fname: 'בדיקה',
          lname: 'בדיקה',
          phone: '0527420404',
          ProfilePic: 'https://images.unsplash.com/photo-1520271348391-049dd132bb7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80',
        },
        meetTypeData: {
          key: '-',
          approve: false,
          color: "#4366fb",
          float: true,
          gender: "female",
          info: "987654",
          meetTime: "123",
          name: "תספורת גבר",
          pic: "לחמ",
          price: "30",
          reminder: "20",
          hidden: false
        },

        date: {}
      }
    }
    this.meetTypeData = {}
  }

  resetLocation() {
    this.locationSet = {
      address: '',
      country: '',
      city: '',
      street: '',
      street_number: '',
      latitude: 0,
      longitude: 0,
      isLocation: true
    }
    this.googlemapInputValue = '';
  }

  // -------  ---   UPLOAD
  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  updateProfile(path, obj) {
    console.log("path, obj", path, obj);
    this.afDb.object(path).update(obj);
    this.afDb.object('/BarberShop/' + this.shopID + path).update(obj)


  }


  updateStrFull(path, obj) {
    // console.log(path, obj);
    // console.log('this.shopID',this.shopID);

    this.afDb.object(path).update(obj).then(x => {
      // this.changeSettingDays();
    })

  }


  updateStr(path, obj) {
    // console.log(path, obj);
    // console.log('this.shopID',this.shopID);
    let keys = Object.keys(obj);
    this.afDb.object('/BarberShop/' + this.shopID + path).update(obj).then(x => {
      console.log(obj);
      console.log(x);
      console.log(keys);

      if (keys[0] == 'logo') {
        this.firestore.collection('Shop_Search_Info').doc(this.shopID)
          .update({
            logo: obj.logo
          });
      }
      if (keys[0] == 'cover') {
        this.firestore.collection('Shop_Search_Info').doc(this.shopID)
          .update({
            cover: obj.cover
          });
      }

      if (keys[0] == 'limitMeetAdmin') {
        this.changeSettingDays();
      }
    });
  }

  addStr(path, obj) {
    // console.log(path, obj);
    if (path) {
      this.afDb.list('/BarberShop/' + this.shopData.Setting.BarberID + path).push(obj)
    }
  }







  deleteStr(path) {
    console.log(path);
    this.afDb.list('/BarberShop/' + this.shopData.Setting.BarberID + path).remove()

  }
  convertToHoier(ht) {
    var Houer = ht / 60;
    var Minut = ht % 60;
  }


  listPosts = [];
  addPost(path, obj, url, type) {
    // console.log(path,obj,url);
    let dataToadd = {
      content: url,
      type: type,
      order: 1
    }

    this.afDb.list('/BarberShop/' + this.shopID + '/Posts/').push(dataToadd);
    this.listPosts.push(dataToadd);
  }

  addNotificationBell(type, target, data, bizid?) {
    let bID = this.shopID
    if (data.meet && data.meet.BarberID) {
      bID = data.meet.BarberID
    }
    if (bizid) {
      bID = bizid
    }
    let user = {
      fname: this.us.user.fname,
      lname: this.us.user.lname,
      ProfilePic: this.us.user.ProfilePic
    }
    let timestamp = new Date().getTime();
    let notification = {
      key: this.meetForBuis.newGuid() + '-' + timestamp,
      BarberID: bID,
      seen: false,
      timestamp: timestamp,
      userID: this.us.user.key,
      target: target,
      type: type,
      data: data
    }

    notification.data.user = user;
    console.log('newBELL', notification);

    this.firestore.collection('Notifications').doc(notification.key).set(notification);
  }

  seenNotification(key) {
    return this.firestore.collection('Notifications').doc(key).update({ seen: true });
  }

  // updateBarberMeet(request, meet, reqKey) {
  //   delete request.meet;
  //   return this.afDb.object('/BarberShop/' + request.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(meet.meetDate) + "/" + meet.key + '/paymentRequests/' + reqKey).set(request);
  // }

  // updateUserMeet(request, meet, reqKey) {
  //   delete request.meet;
  //   return this.afDb.object('/Users/' + request.userID + "/meetings/" + request.BarberID + "/" + meet.key + '/paymentRequests/' + reqKey).set(request);
  // }

  holidays: any = [];
  Holidays() {
    this.holidays = [];
    this.afDb.database.ref('/BarberShop/' + this.shopData.Setting.BarberID + '/Holidays').once('value').then(res => {
      console.log('/BarberShop/' + this.shopData.Setting.BarberID + '/Holidays', res.val());
      console.log('getting Holidays from collection........');
      let date = new Date();

      if (res.val()) {
        let arr = res.val();
        arr.forEach((item) => {
          if (new Date(item.start.date) >= date) {
            this.holidays.push(item);
          }
        });
      }
      console.log(this.holidays);

      this.firestore.collection('Holidays').get().subscribe(val => {
        console.log(val.docs);

        val.docs.forEach((doc, index) => {
          let data = doc.data();
          if (new Date(data.start.date) >= date) {
            let found = this.holidays.findIndex(x => x.id == data.id)
            if (found == -1) {
              data['change'] = false;
              data['SavedChange'] = false;
              data['notWorking'] = false;
              data['startWorkDay'] = "06:00"
              data['endWorkDay'] = "23:50"
              this.holidays.push(data);
            }
          }
        });
      });
    });
  }

  setHolidays(index, holiday) {
    console.log('SET /BarberShop/' + this.shopData.Setting.BarberID + '/Holidays/' + index, holiday);
    this.afDb.database.ref('/BarberShop/' + this.shopData.Setting.BarberID + '/Holidays/' + index).set(holiday);
  }


  // holidayCollection(holidays) {
  //   holidays.forEach(holiday => {
  //     this.firestore.collection('Holidays').doc(holiday.id).set(holiday);
  //   })
  // }


  addMeetRequest(request, meet, reqKey) {
    // delete request.meet;
    this.afDb.object('/Users/' + request.userID + "/meetings/" + request.BarberID + "/" + meet.key).update({ paymentRequestID: reqKey, payment_status: 'pending' });
    this.afDb.object('/BarberShop/' + request.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(meet.meetDate) + "/" + meet.key).update({ paymentRequestID: reqKey, payment_status: 'pending' });
  }

  // updateUserMeetRequest(request, status, paymentRes?) {
  //   this.afDb.object('/Users/' + request.userID + "/meetings/" + request.BarberID + "/" + request.meet.key + '/paymentRequests/' + request.key).update({ status: status });
  //   if (paymentRes) {
  //     this.afDb.list('/Users/' + request.userID + "/meetings/" + request.BarberID + "/" + request.meet.key + '/paymentRequests/' + request.key + '/paymentRespone/').push(paymentRes);
  //   }
  // }

  updateMeetRequest(request, status) {
    this.afDb.object('/Users/' + request.userID + "/meetings/" + request.BarberID + "/" + request.meet.key).update({ payment_status: status });
    this.afDb.object('/BarberShop/' + request.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(request.meet.meetDate) + "/" + request.meet.key).update({ payment_status: status });
    // if (paymentRes) {
    //   this.afDb.list('/Users/' + request.userID + "/meetings/" + request.BarberID + "/" + request.meet.key + '/paymentRequests/' + request.key + '/paymentRespone/').push(paymentRes);
    //   this.afDb.list('/BarberShop/' + request.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(request.meet.meetDate) + "/" + request.meet.key + '/paymentRequests/' + request.key + '/paymentRespone/').push(paymentRes);
    // }
  }

  // payMeet(request, status, paymentRes?) {
  //   this.afDb.list('/Users/' + request.userID + "/meetings/" + request.BarberID + "/" + request.meet.key + '/paymentRespone/').push({ status: status });
  // }

  // updateBusinessMeetRequest(request, status, paymentRes?) {
  //   this.afDb.object('/BarberShop/' + request.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(request.meet.meetDate) + "/" + request.meet.key + '/paymentRequests/' + request.key).update({ status: status });
  //   if (paymentRes) {
  //     this.afDb.list('/BarberShop/' + request.BarberID + "/Meetings/" + this.meetForBuis.convertDateToYYYY(request.meet.meetDate) + "/" + request.meet.key + '/paymentRequests/' + request.key + '/paymentRespone/').push(paymentRes);
  //   }
  // }

  addPaymentRequest(request) {

    return this.afDb.list('/paymentRequests/').push(request);
  }

  // addBusinessPaymentTransfer(BarberID, transfer) {
  //   return this.afDb.list('/BarberShop/' + BarberID + '/paymentTransfers/').push(transfer);
  // }

  getPaymentRequests(child, key) {
    return this.afDb.database.ref('/paymentRequests/').orderByChild(child).equalTo(key).once('value');
  }

  getPaymentRequestByID(id) {
    return this.afDb.database.ref('/paymentRequests/' + id).once('value');
  }
  // changeRequestStatus(reqKey, status) {
  //   // let obj = paymentRes ? { status: status, paymentRespone: paymentRes } : { status: status };
  //   // return this.afDb.object('/paymentRequests/' + reqKey)
  //   //   .update(obj);

  //   // let obj = paymentRes ? { status: status, paymentRespone: paymentRes } : { status: status };
  //   return this.afDb.object('/paymentRequests/' + reqKey)
  //     .update({ payment_status: status });
  // }

  updatePaymentRequest(reqKey, obj) {
    return this.afDb.object('/paymentRequests/' + reqKey)
      .update(obj);
  }



  // confirmTransform(BarberID, transKey) {
  //   return this.afDb.object('/BarberShop/' + BarberID + '/paymentTransfers/' + transKey)
  //     .update({
  //       seen: true
  //     }
  //     )
  // }

  // addDevice(userID, device) {
  //   this.afDb.list('/Users/' + userID + '/Devices/').push(device);
  // }

  // Save all shops to shop service variable
  getallNotificationBell(id) {
    return this.firestore.collection('Notifications', ref => ref.where('BarberID', '==', id).where('target', '==', 'business'));
  }

  removeSheet(meets) {
    var self = this;
    Object.keys(meets).forEach(function (key) {
      Object.keys(meets[key]).forEach(function (key2) {

        if (!meets[key][key2].BarberID) {
          console.log("removeSheet", meets[key][key2]);

          self.afDb.object('/Users/' + self.us.user.key + '/meetings/' + key + '/' + key2).remove();

        }

      })

    })

  }

  CloseLiveSub(id) {
    if (this.settingSub) {
      this.settingSub.unsubscribe();
    }

    if (this.needConfirmSub) {
      this.needConfirmSub.unsubscribe();
    }

    if (this.bizArraySub) {
      this.bizArraySub.unsubscribe();
    }

    // if (this.bizArraySub) {
    //   console.log("CLOSE bizArraySub");
    //   // this.bizArraySub.unsubscribe();

    //   this.afDb.database.ref('/Users/' + id + '/bizArray').off('child_changed', this.bizArraySub);

    // }
    if (this.UserUpdateSub) {
      this.UserUpdateSub.unsubscribe();
    }

    if (this.WorkDaysSub) {
      this.WorkDaysSub.unsubscribe();
    }
    this.stop_listen_wallet_settings();

    // if (this.UserAddSub) {
    //   this.afDb.database.ref('/BarberShop/' + id + '/Users/').off('child_added', this.UserAddSub);
    // }

    // if (this.UserRemoveSub) {
    //   this.afDb.database.ref('/BarberShop/' + id + '/Users/').off('child_removed', this.UserRemoveSub);
    // }

    // if (this.UserUpdateSub) {
    //   this.afDb.database.ref('/BarberShop/' + id + '/Users/').off('child_changed', this.UserUpdateSub);
    // }

    // if (this.meetAddSub) {
    //   this.afDb.database.ref('/BarberShop/' + id + '/Meetings/').off('child_added', this.meetAddSub);
    // }

    // if (this.meetRemoveSub) {
    //   this.afDb.database.ref('/BarberShop/' + id + '/Meetings/').off('child_removed', this.meetRemoveSub);
    // }

    // if (this.meetUpdateSub) {
    //   this.afDb.database.ref('/BarberShop/' + id + '/Meetings/').off('child_changed', this.meetUpdateSub);
    // }

    // if (this.eventSub) {
    //   this.afDb.database.ref('/BarberShop/' + id + '/Events/').off('child_changed', this.eventSub);
    // }

    // if (this.eventAddSub) {
    //   this.afDb.database.ref('/BarberShop/' + id + '/Events/').off('child_added', this.eventAddSub);
    // }

    if (this.meetTypeSub) {
      this.meetTypeSub.unsubscribe();
    }

    if (this.notificationsSub) {
      this.notificationsSub.unsubscribe();
    }

    if (this.paymentReqAddSub) {
      this.afDb.database.ref('/paymentRequests/' + id).off('child_changed', this.paymentReqAddSub);
    }

    if (this.PostsSub) {
      this.PostsSub.unsubscribe();
    }

    // if (this.eventSub) {
    //   this.afDb.database.ref('/BarberShop/' + id + '/Events/').off('child_changed', this.eventSub);
    // }

    // if (this.eventAddSub) {
    //   this.afDb.database.ref('/BarberShop/' + id + '/Events/').off('child_added', this.eventAddSub);
    // }
  }

  //big big solution
  needConfirmSub;
  bizArraySub;
  settingSub;
  WorkDaysSub;
  UserAddSub;
  UserRemoveSub;
  UserUpdateSub;
  meetAddSub;
  meetRemoveSub;
  meetUpdateSub;
  userMeetsSub;
  eventUpdateSub;
  meetTypeSub;
  PostsSub;
  userData
  paymentReqAddSub;
  notificationsSub;
  eventAddSub;
  eventSub;
  newPaymentRequest = new Rx.BehaviorSubject(null);
  // paymentRequestsChanges = new Rx.BehaviorSubject(null);
  meetingTypeChange = new Rx.BehaviorSubject(null);

  notificationsArray = [];
  displayNotif = [];
  // notifFilters = {
  //   meet_approve: true,
  //   new_meet: true,
  //   meet_cancel: true,
  //   new_client: true,
  //   user_sign_to_event: true,
  //   user_cancel_event: true
  // }

  notifFilters = [
    { name: 'all', text: 'הכל', arr: [] },
    { name: 'meet_need_approve', text: ' דורשים אישור', arr: [] },
    { name: 'meet_approved', text: 'אושרו', arr: [] },
    { name: 'meet_cancel', text: 'בוטלו', arr: [] },
    { name: 'new_client', text: 'לקוחות חדשים', arr: [] }
  ]

  // currentNotifFilter = this.notifFilters[0];
  notifFilterName = localStorage.getItem('notif_filter') ? localStorage.getItem('notif_filter') : 'all';
  currentNotifFilterVal = this.notifFilters.find(filter => filter.name == this.notifFilterName);
  currentNotifFilter = new Rx.BehaviorSubject(this.currentNotifFilterVal);


  // notifFilters = ['meet_approve', 'new_meet', 'meet_cancel', 'new_client', 'user_sign_to_event', 'user_cancel_event'];

  updateNotifFilters() {
    this.notifFilters[0].arr = this.notificationsArray;
    this.notifFilters[1].arr = this.notificationsArray.filter(notif => (notif.type == 'new_meet' && notif.data.meet.meetStatus == 3) || (notif.type == 'user_sign_to_event' && notif.data.meet.eventStatus == 3) || notif.type == 'meet_change' || notif.type == 'new_client_need_confirm');
    this.notifFilters[2].arr = this.notificationsArray.filter(notif => (notif.type == 'new_meet' && notif.data.meet.meetStatus != 3) || (notif.type == 'user_sign_to_event' && notif.data.meet.eventStatus != 3));
    this.notifFilters[3].arr = this.notificationsArray.filter(notif => notif.type == 'meet_cancel' || notif.type == 'user_cancel_event');
    this.notifFilters[4].arr = this.notificationsArray.filter(notif => notif.type == 'new_client');
    this.currentNotifFilterVal = this.notifFilters.find(filter => filter.name == this.notifFilterName);
    this.currentNotifFilter.next(this.currentNotifFilterVal);
  }

  notificationFilter(filter) {
    let foundFilter = this.notifFilters.find(filt => filt.name == filter);
    this.currentNotifFilter.next(foundFilter);
    this.notifFilterName = filter;
    localStorage.setItem('notif_filter', filter);

    console.log(this.notificationsArray);
    console.log(this.displayNotif);
  }






  // --------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------------------------




  open_meet_subscription = [];
  open_event_subscription = [];
  meet_subscription: any = {};
  event_subscription: any = {};


  close_business_meetings_sub() {

    let b2 = Object.keys(this.open_meet_subscription)
    console.log('b2: $@%%#@#$%@^#!$!', b2)
    b2.forEach((s, i) => {
      this.open_meet_subscription[s].unsubscribe()
      if (b2.length - 1 == i) {
        this.open_meet_subscription = [];
        this.meet_subscription = {};
      }
    });

    let c2 = Object.keys(this.open_event_subscription)
    console.log('c2: $@%%#@#$%@^#!$!', c2)
    c2.forEach((s, i) => {
      this.open_event_subscription[s].unsubscribe()
      if (c2.length - 1 == i) {
        this.open_event_subscription = [];
        this.event_subscription = {};
      }
    });

    this.eventArray = [];
    this.calendarIsDisplay = false;
    console.log('closeBusinessMeetingsSub0', this.calendar.mode);

  }

  concat_meetArray(startDate) {
    var selff = this;
    if (this.calendar.mode == 'week') {
      if (this.current_week_arr.includes(startDate)) {
        let week_eventArray = [];
        this.current_week_arr.forEach((d, i) => {
          if (!selff.meet_subscription[d]) {
            selff.meet_subscription[d] = []
          }
          if (!selff.event_subscription[d]) {
            selff.event_subscription[d] = []
          }
          let new_arr = selff.meet_subscription[d].concat(selff.event_subscription[d])
          week_eventArray = week_eventArray.concat(new_arr)
          if (this.current_week_arr.length - 1 == i) {
            selff.eventArray = week_eventArray;
          }
        })
        selff.reRender();
      }
    } else {
      let currentDate = selff.meetForBuis.convertDateToYYYY(selff.meetForBuis.convertDate(new Date(selff.calendar.currentDate)));
      if (currentDate == startDate) {
        if (!selff.meet_subscription[startDate]) {
          selff.meet_subscription[startDate] = []
        }
        if (!selff.event_subscription[startDate]) {
          selff.event_subscription[startDate] = []
        }
        selff.eventArray = selff.meet_subscription[startDate].concat(selff.event_subscription[startDate]);
        selff.reRender();
      }
    }
  }


  current_week_arr = [];

  open_sub_on_startday(startDate) {
    var selff = this;
    console.log(startDate);
    if (!this.open_meet_subscription[startDate]) {
      //////////////////////////// 
      //   setTimeout(() => {
      //     if(!this.openBusinessMeetingsSub_time_flag){
      //       console.log('openBusinessMeetingsSub_time_flag --- FALSE',this.open_meet_subscription);
      //       // alert('try again');
      //       this.showToast_reconnect('מתחבר מחדש')
      //       this.openBusinessMeetingsSub();
      //     }else{
      //       console.log('openBusinessMeetingsSub_time_flag --- TRUEEEE',this.open_meet_subscription);

      //     } 
      // }, 5000);

      selff.meet_subscription[startDate] = [];

      this.open_meet_subscription[startDate] = this.afDb.object('/BarberShop/' + selff.shopID + '/Meetings/' + startDate).valueChanges().subscribe(res => {
        console.log(startDate);
        
        console.log('SUBSCRIBE- TRIGGERS', res);
        this.openBusinessMeetingsSub_time_flag = true;


        let arr = selff.json2array(res);
        arr.forEach(meet => {
          meet = selff.convertToCalendarFormat(meet);
        });
        selff.meet_subscription[startDate] = arr;
        selff.concat_meetArray(startDate);
      });
    } else {
      selff.concat_meetArray(startDate);
    }
    //////////////////////////////////////////////////// 
    if (!this.open_event_subscription[startDate]) {
      selff.event_subscription[startDate] = [];
      this.open_event_subscription[startDate] = this.afDb.object('/BarberShop/' + selff.shopID + '/Events/' + startDate).valueChanges().subscribe(res => {
        let arr = selff.json2array(res);
        arr.forEach(meet => {
          meet = selff.convertToCalendarFormat(meet);
        });
        selff.event_subscription[startDate] = arr;
        selff.concat_meetArray(startDate)
      });
    } else {
      selff.concat_meetArray(startDate)
    }

  }


  async openFullscreenAlert() {
    var modal = await this.modalController.create({
      component: FullscreenAlertComponent,
      swipeToClose: true,
      cssClass: 'fullscreen_alert_modal',
      backdropDismiss: true,
      mode: "ios",
      // componentProps: { "titleData": swichClass }
    });

    await modal.present();
  }

  openBusinessMeetingsSub_time_flag = false;

  openBusinessMeetingsSub() {
    let selff = this;
    this.calendarIsDisplay = true;
    // this.close_business_meetings_sub()
    console.log("OPEN MEETING SUB");
    this.openBusinessMeetingsSub_time_flag = false;
    selff.eventArray = [];



    if (this.calendar.mode == 'week') {
      let currentDate = new Date(selff.calendar.currentDate);
      // Sunday, Monday, Tuesday, Wednesday, Thursday,  Friday, Saturday , ;
      let sunday = new Date(currentDate).setDate(currentDate.getDate() - currentDate.getDay());
      let monday = new Date(sunday).setDate(new Date(sunday).getDate() + 1);
      let tuesday = new Date(sunday).setDate(new Date(sunday).getDate() + 2);
      let wednesday = new Date(sunday).setDate(new Date(sunday).getDate() + 3);
      let thursday = new Date(sunday).setDate(new Date(sunday).getDate() + 4);
      let friday = new Date(sunday).setDate(new Date(sunday).getDate() + 5);
      let saturday = new Date(sunday).setDate(new Date(sunday).getDate() + 6);

      // let startDate = selff.meetForBuis.convertDateToYYYY(selff.meetForBuis.convertDate(sunday));
      // let endDate = selff.meetForBuis.convertDateToYYYY(selff.meetForBuis.convertDate(saturday));


      let week_arr = [
        selff.meetForBuis.convertDateToYYYY(selff.meetForBuis.convertDate(sunday)),
        selff.meetForBuis.convertDateToYYYY(selff.meetForBuis.convertDate(monday)),
        selff.meetForBuis.convertDateToYYYY(selff.meetForBuis.convertDate(tuesday)),
        selff.meetForBuis.convertDateToYYYY(selff.meetForBuis.convertDate(wednesday)),
        selff.meetForBuis.convertDateToYYYY(selff.meetForBuis.convertDate(thursday)),
        selff.meetForBuis.convertDateToYYYY(selff.meetForBuis.convertDate(friday)),
        selff.meetForBuis.convertDateToYYYY(selff.meetForBuis.convertDate(saturday)),
      ]
      console.log('week_arrweek_arrweek_arr:', week_arr)

      this.current_week_arr = week_arr
      // this.open_sub_on_startday(sunday);
      week_arr.forEach(d => {
        this.open_sub_on_startday(d);
      })
    } else if (this.calendar.mode == 'day') {
      console.log(selff.calendar.currentDate);
      let startDate = selff.meetForBuis.convertDateToYYYY(selff.meetForBuis.convertDate(new Date(selff.calendar.currentDate)));
      this.open_sub_on_startday(startDate);

    }
  }

  closeBusinessMeetingsSub() {
    this.eventArray = [];
    this.calendarIsDisplay = false;
    // console.log('closeBusinessMeetingsSub0', this.calendar.mode);

    // if (this.calendar.mode == 'week') {
    //   this.afDb.database.ref('/BarberShop/' + this.shopID + '/Meetings/').off('value', this.meetUpdateSub);
    //   this.afDb.database.ref('/BarberShop/' + this.shopID + '/Events/').off('value', this.eventUpdateSub);
    // } else if (this.calendar.mode == 'day') {
    //   if (this.meetUpdateSub) {
    //     this.meetUpdateSub.unsubscribe();
    //     this.meetUpdateSub = null;
    //   }

    //   if (this.eventUpdateSub) {
    //     this.eventUpdateSub.unsubscribe();
    //     this.eventUpdateSub = null;
    //   }
    // }
  }







  // --------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------------------------







  turnOnConfetti(time) {
    this.confettiFLAG = false;
    setTimeout(() => {
      if (!this.confettiFLAG) {
        this.confettiFLAG = true;
      }
    }, time);
  }


  userEventArray = [];

  ggg(id?) {
    return this.afDb.database.ref('/BarberShop').once('value');
  }

  openUserMeetingsSub() {
    let selff = this;
    console.log("OPEN MEETING SUB");
    console.log(this.userMeetsSub);
    console.log(!this.userMeetsSub);
    console.log(selff.us.user.key);



    if (!this.userMeetsSub) {
      this.userMeetsSub = this.afDb.object('/Users/' + selff.us.user.key + '/meetings/').valueChanges().subscribe(res => {
        console.log(res);
        console.log("OPEN MEETING SUB");
        let allMeets = [];
        if (res) {
          let businesses = Object.values(res);
          businesses.forEach(business => {
            if (!business.timestamp) {
              business = selff.json2array(business);
              business.forEach(meet => {
                if (meet.BarberID && meet.endTime) {
                  if (new Date(meet.endTime).getTime() < new Date().getTime()) {
                    // MOVE TO HISTORY
                    selff.moveToHistory(meet);
                  } else {
                    allMeets.push(meet);
                  }
                } else {
                  // DELETE MEET
                }
              });
            }
          });
        }
        allMeets = allMeets.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());
        selff.userEventArray = allMeets;
      });
    }
  }

  closeUserMeetingsSub() {
    console.log(this.userMeetsSub, !!this.userMeetsSub);

    if (this.userMeetsSub) {
      console.log("closeUserMeetingsSub");
      this.userMeetsSub.unsubscribe();
      this.userMeetsSub = null;
    }
  }

  fixfix() {
    console.log("FIXFIX");



    this.firestore.collection('Notifications').get().subscribe(val => {
      let arr = [];
      let needFix = [];
      val.docs.forEach(doc => {
        let data = doc.data();
        if (data.data.Date) {
          needFix.push(data);
        }
        arr.push(data);
      });
      console.log(arr);
      needFix.forEach(fix => {
        let obj = {};
        obj['user'] = fix.data.user;
        delete fix.data.user;
        obj['meet'] = fix.data;
        this.firestore.collection('Notifications').doc(fix.key).update({ data: obj });
      });


      console.log(needFix);
    });
  }

  currentMeetings = [];
  currentEvents = [];

  openNotificationSub(id) {
    this.notificationsSub = this.firestore.collection('Notifications', ref => ref.where('BarberID', '==', id).where('target', '==', 'business').where('seen', '==', false)).valueChanges().subscribe((val: any) => {
      this.notificationsArray = val;
      let data = val.sort((a, b) => b.timestamp - a.timestamp);
      this.notificationsArray = data;
      console.log(this.notificationsArray, 'dsahdosaihado');
      this.updateNotifFilters();
    });
  }

  openSettingSub(id) {
    this.settingSub = this.afDb.object('/BarberShop/' + id + '/Setting').valueChanges().subscribe(
      (Setting: any) => {
        console.log(Setting);
        this.shopData['Setting'] = Setting;
      });
    this.meetTypeSub = this.afDb.object('/BarberShop/' + id + '/MeetingType').valueChanges().subscribe(
      (MeetingType: any) => {
        // console.log('Setting', MeetingType);
        // console.log('this.shopData', this.shopData);
        this.shopData.MeetingType = MeetingType;
        // console.log('this.shopData.MeetingType', this.shopData.MeetingType);
      });
  }

  closeSettingSub() {
    if (this.settingSub) {
      this.settingSub.unsubscribe();
    }
  }

  openLiveSub(id, type) {
    var selff = this;
    var newDateToday = new Date();
    console.log(id, type);

    if (type == 'admin') {
      if (this.dateForView.length == 0) {
        this.daysForViewFunc();
      }
      const self = this;
      // open admin 


      console.log('this.shopData', selff.shopData);

      // check if have to wait
      if ((this.shopData['Setting']['Premium']['status'] == 'wait') || (this.shopData['Setting']['Premium']['status'] == 'fail')) {
        this.modalS.PremiumLock('')
      }

      this.start_listen_wallet_settings();

      // var timeStart = "" + (newDateToday.getFullYear()) + "-" + ('0' + (newDateToday.getMonth() + 1)).slice(-2) + "";
      // console.log("timeStart", timeStart)

      //   this.eventSub = this.afDb.database
      //     .ref('/BarberShop/' + id + '/Events/')
      //     .on('child_changed', function (snapshot) {
      //       //              console.log('snapshot.val()',snapshot.val());
      //       let newChange2 = selff.json2array(snapshot.val());
      //       console.log('---------->', newChange2);


      //       newChange2.forEach(element => {
      //         let newmeet = selff.convertToCalendarFormat(element);

      //         let index = selff.eventArray.findIndex(x => x.key === newmeet.key);

      //         // let arr = selff.eventArray;
      //         // selff.eventArray = [];

      //         // setTimeout(() => {
      //         if (index == -1) {
      //           selff.eventArray.push(newmeet);
      //         } else {
      //           selff.eventArray[index] = newmeet;
      //         }
      //         // selff.eventArray = arr;
      //         // }, 1000);

      //         console.log("index", index);

      //       })




      //       // selff.eventArray = JSON.parse(JSON.stringify(selff.eventArray));
      //       setTimeout(() => {
      //         selff.reLoad.next(Math.random());
      //         selff.reRender();
      //       }, 100);
      //     });



      //   // CHILD ADDED
      //   this.eventAddSub = this.afDb.database
      //     .ref('/BarberShop/' + id + '/Events/').orderByKey().startAt(timeStart)
      //     .on('child_added', function (snapshot) {
      //       //    console.log('snapshot.val()',snapshot.val());
      //       let newChange = selff.json2array(snapshot.val());
      //       // console.log('---------->',newChange);
      //       if (newChange.length == 1) {
      //         selff.eventArray.push(selff.convertToCalendarFormat(newChange[0]));
      //         selff.reLoad.next(Math.random());
      //         selff.reRender();

      //       } else {


      //         newChange.forEach(element => {



      //           selff.eventArray.push(selff.convertToCalendarFormat(element));

      //         });
      //       }
      //       // selff.reLoad.next(Math.random());
      //     });

      //           // CHILD CHANGE
      //   this.meetUpdateSub = this.afDb.database
      //   .ref('/BarberShop/' + id + '/Meetings/')
      //   .on('child_changed', function (snapshot) {
      //     ////MEET CHANGE
      //     console.log("childe changeed")
      //     //    console.log('snapshot.val()',snapshot.val());
      //     let newChange2 = selff.json2array(snapshot.val());
      //     console.log('---------->', newChange2);

      //     newChange2.forEach(element => {
      //       let newmeet = selff.convertToCalendarFormat(element);

      //       let index = selff.eventArray.findIndex(x => x.key === newmeet.key);

      //       if (index == -1) {
      //         selff.eventArray.push(newmeet);

      //       } else {
      //         selff.eventArray[index] = newmeet;

      //       }

      //       //    console.log("index", index);

      //     })
      //     console.log('selff.eventArray', selff.eventArray)
      //     selff.reLoad.next(Math.random());
      //     selff.reRender();




      //     //    return;





      //     let newChange = snapshot.val();

      //     //   console.log('newChange-child_changed-Meetings', newChange);
      //     let val = newChange[Object.keys(newChange)[0]];
      //     // [val.meetDateFormat]
      //     selff.shopData.Meetings[selff.meetForBuis.convertDateToYYYY(val.meetDateFormat)
      //     ] = newChange;
      //     selff.shopData.Meetings = JSON.parse(JSON.stringify(selff.shopData.Meetings));

      //     setTimeout(() => {
      //       selff.shopData.Meetings = JSON.parse(JSON.stringify(selff.shopData.Meetings));
      //       selff.reRender();

      //     }, 1000);

      //     // console.log('val',val);
      //     // console.log('newChange[val]',newChange[val.meetDateFormat]);
      //   });


      // // CHILD ADDED
      // this.meetAddSub = this.afDb.database
      //   .ref('/BarberShop/' + id + '/Meetings/').orderByKey().startAt(timeStart)
      //   // .limitToLast(1)
      //   .on('child_added', function (snapshot) {
      //     //  console.log('snapshot.key()',snapshot.key);
      //     var index = snapshot.key.indexOf("-");

      //     if (index == 4) {

      //       var date1Updated = new Date();
      //       date1Updated.setDate(date1Updated.getDate() - 1);

      //       var date2Updated = new Date(snapshot.key);

      //       if (date1Updated < date2Updated) {

      //         let newChange = selff.json2array(snapshot.val());
      //         // console.log('---------->',date1Updated,"<",date2Updated);
      //         if (newChange.length == 1) {
      //           selff.eventArray.push(selff.convertToCalendarFormat(newChange[0]));
      //           selff.reLoad.next(Math.random());
      //           selff.reRender();

      //         } else {

      //           newChange.forEach(element => {
      //             selff.eventArray.push(selff.convertToCalendarFormat(element));
      //           });
      //         }

      //         let val = newChange[Object.keys(newChange)[0]];
      //         selff.shopData.Meetings[selff.meetForBuis.convertDateToYYYY(val.meetDateFormat)] = newChange;
      //         selff.shopData.Meetings = JSON.parse(JSON.stringify(selff.shopData.Meetings));
      //       }
      //     }
      //   });


      // // CHILD REMOVE
      // this.meetRemoveSub = this.afDb.database.ref('/BarberShop/' + id + '/Meetings/')
      //   .on('child_removed', function (snapshot) {
      //     console.log('snapshot.val()', snapshot.val());



      //     let newChange = snapshot.val();
      //     console.log('newChange-child_removed-Meetings', newChange);
      //     let val = newChange[Object.keys(newChange)[0]];
      //     // [val.meetDateFormat]
      //     let formatdelet = convertDateToYYYY(val.meetDateFormat);

      //     //  let index = this.eventArray.findIndex(x => x.key === val.key);
      //     //     this.eventArray.splice(index, 1);

      //     delete selff.shopData.Meetings[formatdelet][val.key];
      //     selff.shopData.Meetings = JSON.parse(JSON.stringify(selff.shopData.Meetings));
      //     console.log('val', val);
      //     console.log('selff.shopData.Meetings[val.meetDateFormat][val.key]]', selff.shopData.Meetings[formatdelet][val.key]);
      //   });



      // this.paymentReqAddSub = this.afDb.database
      //   .ref('/paymentRequests/').orderByChild('BarberID').equalTo(id)
      //   .on('child_changed', function (snapshot) {
      //     // let newChange = selff.json2array(snapshot.val());
      //     let val = snapshot.val();
      //     val.key = snapshot.key;

      //     if (!val.seen && (val.payment_status == 'completed' || val.payment_status == 'refused')) {
      //       selff.paymentRequestsChanges.next(val);
      //       console.log("NEW CHANGE", val);
      //     }

      //   });

      this.openNotificationSub(id);

      // this.notificationsSub = this.firestore.collection('Notifications', ref => ref.where('BarberID', '==', id).where('target', '==', 'business').where('seen', '==', false)).valueChanges().subscribe((val: any) => {
      //   this.notificationsArray = val;
      //   let data = val.sort((a, b) => b.timestamp - a.timestamp);
      //   this.notificationsArray = data;
      //   console.log(this.notificationsArray, 'dsahdosaihado');
      //   this.updateNotifFilters();
      // });









      this.UserUpdateSub = this.afDb.object('/BarberShop/' + id + '/Users/').valueChanges().subscribe(res => {
        this.shopData.Users = res;
        this.shopData.Userslength = (selff.json2array(res).length) - 1;
        self.usersDataChange.next(selff.shopData.Users);
        selff.reRender();
      });













      // // CHILD CHANGE
      // this.UserUpdateSub = this.afDb.database
      //   .ref('/BarberShop/' + id + '/Users/')
      //   .on('child_changed', function (snapshot) {
      //     console.log('child_changed');

      //     let newChange = snapshot.val();
      //     selff.shopData.Users[snapshot.key] = newChange;
      //     // selff.shopData.Users = JSON.parse(JSON.stringify(selff.shopData.Users));
      //     selff.reRender();
      //     self.usersDataChange.next(selff.shopData.Users);
      //   });


      // // CHILD ADDED
      // this.UserAddSub = this.afDb.database
      //   .ref('/BarberShop/' + id + '/Users/')
      //   // .limitToLast(1)
      //   .on('child_added', function (snapshot) {
      //     console.log('child_added');

      //     let newChange = snapshot.val();
      //     //  console.log('snapshot.key', snapshot.key);
      //     //  console.log('newChange-child_added-Users', newChange);

      //     selff.shopData.Users[snapshot.key] = newChange;
      //     // selff.shopData.Users = JSON.parse(JSON.stringify(selff.shopData.Users));
      //     selff.reRender();
      //     self.usersDataChange.next(selff.shopData.Users);

      //     console.log(selff.shopData.Users);
      //     console.log('newChange', newChange);
      //     // this.shopS.addNotificationBell('new_client', 'business', {});


      //   });



      // // CHILD REMOVE
      // this.UserRemoveSub = this.afDb.database
      //   .ref('/BarberShop/' + id + '/Users/')
      //   .on('child_removed', function (snapshot) {
      //     console.log('child_removed');

      //     //    console.log('snapshot.val()',snapshot.val());
      //     // let newChange = snapshot.val();
      //     console.log('snapshot.key', snapshot.key);

      //     //    console.log('newChange-child_removed-Users', newChange);
      //     //    console.log('selff.shopData.Users', selff.shopData.Users);
      //     delete selff.shopData.Users[snapshot.key];
      //     // selff.shopData.Users = JSON.parse(JSON.stringify(selff.shopData.Users));
      //     selff.reRender();

      //     self.usersDataChange.next(selff.shopData.Users);

      //   });


      // open WorkDays subscribe 
      this.WorkDaysSub = this.afDb.object('/BarberShop/' + id + '/WorkDays').valueChanges().subscribe(
        (WorkDays: any) => {
          console.log('WorkDays', WorkDays);
          console.log('this.WorkDays', this.shopData);
          this.shopData.WorkDays = WorkDays;
          console.log('this.shopData.WorkDays', this.shopData.WorkDays);
        });


      this.PostsSub = this.afDb.object('/BarberShop/' + id + '/Posts').valueChanges().subscribe(
        (Posts: any) => {
          console.log('Posts', Posts);
          console.log('this.Posts', this.shopData);
          this.shopData['Posts'] = Posts;
          console.log('this.shopData.Posts', this.shopData.Posts);
        });



      // open MeetingType subscribe 
      this.meetTypeSub = this.afDb.object('/BarberShop/' + id + '/MeetingType').valueChanges().subscribe(
        (MeetingType: any) => {
          // console.log('Setting', MeetingType);
          // console.log('this.shopData', this.shopData);
          this.shopData.MeetingType = MeetingType;
          this.meetingTypeChange.next(MeetingType);
          // console.log('this.shopData.MeetingType', this.shopData.MeetingType);
        });

      // open Setting subscribe 
      this.settingSub = this.afDb.object('/BarberShop/' + id + '/Setting').valueChanges().subscribe(
        (Setting: any) => {
          console.log('Setting', Setting);

          this.shopData['Setting'] = Setting;

          this.updateCodeData(Setting).then(res => {
            console.log("updateeee codeeeee !!!! ")
          })




          console.log('this.shopData.Setting', this.shopData.Setting);
        });

    } else {
      // open USER
      console.log(this.us.user.key);
      console.log(this.us.user);
      setTimeout(() => {
        console.log(this.us.user.key);
        console.log(this.us.user);
      }, 5000);

      this.afDb.database
        .ref('/paymentRequests/').orderByChild('userID').equalTo(selff.us.user.key)
        .on('child_added', function (snapshot) {
          let val = snapshot.val();
          val.key = snapshot.key;

          if (val.date > newDateToday.getTime()) {
            selff.newPaymentRequest.next(val);
            console.log("NEW REQUEST", val);
          }
        });

      // open Setting subscribe 

      console.log(selff.us.user.key);

      this.needConfirmSub = this.afDb.object('/Users/' + selff.us.user.key + '/needConfirmBuis').valueChanges().subscribe(
        (arr: any) => {
          console.log(arr);

          if (arr) {
            selff.us.user.needConfirmBuis = arr;
          } else {
            // selff.us.user.needConfirmBuis = [];
            selff.us.user.needConfirmBuis = {};
          }
          selff.setNeedConfirm(selff.shopData.BarberId);
        });


      this.bizArraySub = this.afDb.object('/Users/' + selff.us.user.key + '/bizArray').valueChanges().subscribe(
        (arr: any) => {
          console.log(arr);
          if (arr == null) {
            arr = [];
          }
          selff.us.user.bizArray = arr;

          // selff.us.user.bizArray = selff.us.user.bizArray.filter(busi => busi != biz_id);

          let busi_arr = selff.businessArray.filter(busi => selff.us.user.bizArray.includes(busi.Setting.BarberID));
          console.log(busi_arr);

          selff.businessArray = JSON.parse(JSON.stringify(busi_arr));

          if (!arr.includes(selff.shopData.BarberId)) {
            if (selff.us.user.bizArray.length) {
              this.changeBiz(selff.us.user.bizArray[0]);
            } else {
              selff.resetShopData();
              selff.buttonHide = true;
              selff.us.updateShopId('');
            }
          }
          // if (arr) {
          //   selff.us.user.needConfirmBuis = arr;
          // } else {
          //   // selff.us.user.needConfirmBuis = [];
          //   selff.us.user.needConfirmBuis = {};
          // }
          // selff.setNeedConfirm(selff.shopData.BarberId);
        });

      // this.bizArraySub = this.afDb.database.ref('/Users/' + selff.us.user.key + '/bizArray').on('child_changed',
      //   (res) => {
      //     // console.log(res);
      //     // console.log(res.val());
      //     // return;
      //     let biz_id = res.exportVal();
      //     // console.log(res.exportVal());
      //     // console.log(res.key);
      //     // console.log(res.val());
      //     // console.log(res.);
      //     // console.log(res.exportVal());
      //     // console.log(res.exportVal());
      //     // console.log(res.exportVal());
      //     // console.log(res.exportVal());
      //     // if (res) {
      //     //   selff.us.user.bizArray = res;
      //     // } else {
      //     //   selff.us.user.bizArray = [];
      //     // }
      //     selff.us.user.bizArray = selff.us.user.bizArray.filter(busi => busi != biz_id);

      //     let busi_arr = selff.businessArray.filter(busi => selff.us.user.bizArray.includes(busi.Setting.BarberID));
      //     console.log(busi_arr);

      //     selff.businessArray = JSON.parse(JSON.stringify(busi_arr));

      //     // if (!selff.us.user.bizArray.includes(selff.shopData.BarberId)) {
      //     if (selff.shopData.BarberId == biz_id) {
      //       if (selff.us.user.bizArray.length) {
      //         this.changeBiz(selff.us.user.bizArray[0]);
      //       } else {
      //         selff.resetShopData();
      //         selff.buttonHide = true;
      //         selff.us.updateShopId('');
      //       }
      //     }
      //   });

    }


  }

  getBuisLogo(id) {
    return this.afDb.database
      .ref('/BarberShop/' + id + '/Setting/logo').once('value');
  }

  get_payment_requests(BarberID) {
    return this.afDb.database
      .ref('/paymentRequests/').orderByChild('BarberID').equalTo(BarberID)
      .once('value');
  }
  // fix_old_meetings() {
  //   // console.log(this.shopData.Meetings)
  //   // console.log(this.eventArray)
  //   this.removeHistory()



  //   if (this.shopData.Meetings) {
  //     var keys = Object.keys(this.shopData.Meetings);
  //     console.log(keys)
  //     keys.forEach((key => {
  //       //   console.log(meet);
  //       let meetKey = key.split('-');
  //       //    console.log(meetKey)
  //       if (meetKey[0] < '2019') {
  //         // console.log('BIGGBIGBIGIBGIBI');
  //         let newMeetArray = JSON.parse(JSON.stringify(this.shopData.Meetings[key]));



  //         //  console.log('newMeetArray',newMeetArray)
  //         let meetSf = this.json2array(newMeetArray);
  //         console.log('meetSf', meetSf)
  //         meetSf.forEach(element => {
  //           let index = this.eventArray.findIndex(x => x.key === element.key);
  //           console.log(index)

  //           if (index == -1) {
  //             //    console.log('element',element)

  //             //  console.log('startTime',element['startTime'] )
  //             element['Date'] = element['startDate'];
  //             element['startTime'] = new Date(element['startDate']);
  //             element['BarberID'] = this.shopData.Setting.BarberID;
  //             element['allDay'] = false;

  //             console.log('element.meetType', element.meetType, element);
  //             //  let MeetingType = this.json2array(this.shopData.MeetingType) ;
  //             //  let indexx = MeetingType.findIndex(x => x.key ===element['meetType']);
  //             if (element['meetTypeData']) {
  //               element['meetType'] = element['meetTypeData'];
  //             } else
  //               if (element.meetType['color']) {
  //                 element['meetTypeData'] = element['meetType'];

  //               }


  //             if (!element.uIdData) {
  //               element['uIdData'] = this.shopData.Users[element.uId];

  //             }


  //             //   element['startDate'] = new Date(element['startDate']);

  //             element['endTime'] = new Date(element['endTime']);
  //             element['zoomlink'] = '';
  //             element['barberName'] = this.shopData.Setting.name;
  //             element['meetDate'] = element.meetDateFormat;
  //             element['meetDay'] = 'יום';
  //             // console.log('dsd-------------------------------------------------')

  //             //   console.log('dsd',element)
  //             console.log('dsd', this.shopData.Meetings[element.meetDateFormat])
  //             this.shopData.Meetings[element.meetDateFormat][element.key]['meetStatus'] = 7;


  //             this.eventArray.push(element);



  //           }

  //         });

  //         //   this.eventArray.push(meet)
  //       }
  //     }))


  //     console.log('this.eventArray', this.eventArray)



  //   }

  // }


  convertToCalendarFormat(element) {
    let val = element;
    this.meetForBuis.convertDateToYYYY(val.meetDateFormat)

    // var d = new Date(element.startDate); /* midnight in China on April 13th */
    // d.toLocaleString('en-US', { timeZone: 'America/New_York' });
    // console.log('dddddd',d)


    val.title = "test";
    val.allDay = false;
    val.endTime = new Date(val.endTime);
    val.startTime = new Date(val.startDate);
    // switch (typeof val.meetType) {
    //   case "string":
    //     val.meetTypeData = this.findInJsonKey(this.shopData.MeetingType, val.meetTypeData);

    //     break;
    //   case "object":
    //     val.meetTypeData = val.meetType;

    //     break;

    //   default:
    //     val.meetTypeData = this.findInJsonKey(this.shopData.MeetingType, val.meetTypeData);

    //     break;
    // }

    if ((typeof val.meetType) == 'object') {
      val.meetTypeData = val.meetType
    } else {
      val.meetTypeData = this.findInJsonKey(this.shopData.MeetingType, val.meetTypeData);
    }

    if (val.uId == "localUserAdmin") {
      val.uIdData = this.Auser;
    } else {
      val.uIdData = this.findInJsonKey(this.shopData.Users, val.uId);
    }

    return val;
  }

  updateCodeData(Setting) {
    return this.afDb.object('/Codes/' + Setting.code)
      .update(
        Setting
      )
  }

  loadAllBuis() {
    // return this.afDb.object('/BarberShop/').valueChanges();
  }
  //big big problem
  saveAllShop() {
    this.allBusiness = [];
    // const saveAllShopSub = this.afDb.list('/BarberShop/').snapshotChanges().subscribe((result) => {
    //   const allShopRes = result;
    //   //  console.log("================ >>>>>>>>>>>>>>>>> |",result);

    //   saveAllShopSub.unsubscribe();
    //   allShopRes.map(item => {
    //     this.allBusiness.push(item.payload.val());
    //   });
    //   //   console.log("================ >>>>>>>>>>>>>>>>> |",this.allBusiness);
    // });
  }
  getFreeShop(code) {
    const codeValue = code.businessCode;
    console.log(codeValue);
    let freeBusiness = [];
    for (let i = 0; i < this.allBusiness.length; i++) {
      if (this.allBusiness[i].Setting && this.allBusiness[i].Setting.code == code.businessCode) {
        //  console.log(this.allBusiness[i].Setting.code);
        let searchResult;
        //   console.log(this.connectedBusiness);
        searchResult = this.connectedBusiness.filter(x => x.Setting.code == code.businessCode);
        //   console.log(searchResult);
        if (searchResult.length === 0) {
          freeBusiness = this.allBusiness[i];
          break;
        }
        //    console.log(searchResult);
      }
    }
    //   console.log(freeBusiness);
    return freeBusiness;
  }
  deletePost(postkey) {
    this.afDb.list('/BarberShop/' + this.shopID + '/Posts/' + postkey).remove();
  }



  getBizSetting(bizID) {
    console.log("getBizSetting")
    return this.afDb.database.ref('/BarberShop/' + bizID + '/Setting/').once('value');
    //  return this.afDb.object('/BarberShop/' + bizID + '/Setting/');
  }

  UploadFlags: any = {
    logo: false,
    cover: false,
    bussniesPostImg: false,
    CustomerBaner: false
  }

  toastss: any;
  async showToast(message) {
    this.turnOnConfetti(2500);

    // this.confettiFLAG = false;
    // setTimeout(() => {
    //   this.turnOnConfetti(2500);
    //   // this.confettiFLAG = true;
    // }, 2500);
    this.toastss = await this.toastController.create({
      message: `${message}`,
      position: 'top',
      color: 'success',
      cssClass: 'toastCustomWebc',
      duration: 2000,
      mode: 'ios'
    });
    return await this.toastss.present();
    // toast.present();
  }

  async showTostDanger(message) {
    console.log('danger', message);
    const toastss = await this.toastController.create({
      message: `${message}`,
      position: 'top',
      cssClass: 'toastCustomWebc',
      duration: 2000,
      mode: 'ios',
      color: 'danger'
    });
    return await toastss.present();
  }



  async showToast_reconnect(message) {
    console.log('danger', message);
    const toastss = await this.toastController.create({
      message: `${message}`,
      position: 'top',
      cssClass: 'toastCustomWebc',
      duration: 2000,
      mode: 'ios',
      color: 'light'
    });
    return await toastss.present();
  }



  get_file_from_url(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }


  async upload_img(file, path, callback) {
    const ref: AngularFireStorageReference = this.storage.ref(path);
    const task = ref.put(file);
    // this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        console.log('finalize');
        this.downloadURL = ref.getDownloadURL();
        ref.getDownloadURL().subscribe(function (url) {
          console.log(url)
          callback(url);
        });

      })
    )
      .subscribe()
    return ref;

  }

  // BarberID: "64b0970a-ee94-4b96-91fc-94188e82ca56"
  // addres: "התחייה 19, חולון, ישראל"
  // name: "העסק של team"
  // userManager: "6D722cw2LGW4YP23yvdvDMtteln2"

  check_send_pdf_sign(buffer, callback) {

    console.log('buffer', buffer);


    let data = {
      buffer: buffer,
      id: this.shopData.Setting.BarberID,
      contactInfo: 'test@test.com',
      name: 'מתן אברהם',
      location: 'dsadsd'
    }
    this._http.post('https://pointpdfsign.azurewebsites.net/signPdff', data).subscribe(response => {
      // console.log('response',response)
      // console.log('response[_body]',response['_body'])

      let res = JSON.parse(response['_body'])
      console.log('res', res.pdfBuffer);
      callback(res.pdfBuffer);
    })
  }



  create_certificate() {


    let data = {
      clientFileName: this.shopData.Setting.BarberID,
      // ST: 'st',
      // L: 'l',
      O: 'webc 1231432',
      // OU: 'ou',
      CN: 'matan',
      emailAddress: 'EMAIL@gmail.com',
    }
    this._http.post('http://localhost:3000/newCertificate', data).subscribe(response => {
      // console.log('response',response)
      // console.log('response[_body]',response['_body'])

      let res = JSON.parse(response['_body'])
      console.log('res', res)

      if (res.status) {
        alert(res.status);
        this.afDb.object('/Certificate/' + this.shopData.Setting.BarberID)
          .update(data);

      } else {
        alert(res.status)
      }

    })
  }


  dataURLToBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      var raw = parts[1];

      return new Blob([raw], { type: contentType });
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw: any = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  uploadImg(event, path, type) {
    var file = event.target.files[0];
    //    var file = event;
    console.log('uploadImguploadImg', event, path, type);
    // ImageCompressService.filesToCompressedImageSource(event.target.files[0]).then(observableImages => {
    //   observableImages.subscribe((image) => {
    //     file = image;
    //     console.log('filefilefilefilefilefilefilefilefilefile: ',file);

    //   }, (error) => {
    //     console.log("Error while converting");
    //   }, () => {
    //     // file = image;     
    //     console.log("done");

    //   });
    // });


    const filePath = this.newGuid();
    console.log('filePath', filePath);
    const ref: AngularFireStorageReference = this.storage.ref(filePath);

    //  const ref = this.storage.ref(filePath);
    console.log('ref', ref);
    //   const task = ref.put(file);
    const task = ref.put(file);
    console.log('task', task);

    console.log('event: ', event);
    console.log('path: ', path);
    console.log('type: ', type);
    console.log('task: ', task);
    console.log('file: ', file);
    let self = this;
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        console.log('finalize');
        this.LoadingService.dismiss();
        this.downloadURL = ref.getDownloadURL();
        ref.getDownloadURL().subscribe(function (url) {
          const object: any = {
          };
          object[type] = url;
          //   console.log('type',type);
          self.showToast('התמונה עלתה בהצלחה');
          if (type == 'bussniesPostImg') {
            self.addPost(path, object, url, 'img');
          } else if (type == 'ProfilePic') {
            console.log('uploadImage = ', path, object);
            console.log('userservice = ', self.us.user);

            self.us.user.ProfilePic = object.ProfilePic;
            self.updateProfile(path, object);
          } else {
            self.updateStr(path, object);
          }
          self.UploadFlags[type] = false;
          // self.logoUploadFlag = false;
          //  self.uploadPercent = task.percentageChanges();
        });

      })
    )
      .subscribe()
    return task;
  }

  testTest() {
    return this.afDb.database.ref('/Users/').once('value');
  }

  fixUser(id, arr) {
    this.afDb.database.ref('/Users/' + id + '/meetings').set(arr);
  }

  // data calandar

  calendar = {
    mode: 'day',
    currentDate: new Date(),
    locale: 'he',
    startHour: "6",
    endHour: "24",
    timeInterval: "15",
    step: "1",
    startingDayWeek: "0",
    formatWeekTitle: 'MMMM yyyy',
    formatHourColumn: 'HH:mm',
    noEventsLabel: 'אין מפגשים בתאריך זה',
    dir: 'rtl',
    allDayLabel: 'כל היום',
    lockSwipes: true
  };




  uploadFile(event, path, type) {
    var file = event.target.files[0];
    //    var file = event;
    console.log('uploadImguploadImg', event, path, type);
    // ImageCompressService.filesToCompressedImageSource(event.target.files[0]).then(observableImages => {
    //   observableImages.subscribe((image) => {
    //     file = image;
    //     console.log('filefilefilefilefilefilefilefilefilefile: ',file);

    //   }, (error) => {
    //     console.log("Error while converting");
    //   }, () => {
    //     // file = image;     
    //     console.log("done");

    //   });
    // });


    const filePath = this.newGuid();
    console.log('filePath', filePath);
    const ref: AngularFireStorageReference = this.storage.ref(filePath);

    //  const ref = this.storage.ref(filePath);
    console.log('ref', ref);
    //   const task = ref.put(file);
    const task = ref.put(file);
    console.log('task', task);

    console.log('event: ', event);
    console.log('path: ', path);
    console.log('type: ', type);
    console.log('task: ', task);
    console.log('file: ', file);
    var file_to_save = file;
    let self = this;
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        console.log('finalize');
        this.downloadURL = ref.getDownloadURL();
        ref.getDownloadURL().subscribe(function (url) {
          const object: any = {
          };

          object[type] = {
            url: url,
            lastModified: file_to_save.lastModified,
            name: file_to_save.name,
            size: file_to_save.size,
            mime_type: file_to_save.type,
            doc_status: 'uploaded'
          };
          switch (type) {
            case "Social_Id":
              object[type]['type'] = 1;
              self.shopData.Wallet.documents[type] = JSON.parse(JSON.stringify(object[type]));
              break;
            case "Bank_Account":
              object[type]['type'] = 2;
              self.shopData.Wallet.documents[type] = JSON.parse(JSON.stringify(object[type]));

              break;
            case "Corporate_Certificate":
              object[type]['type'] = 3;
              self.shopData.Wallet.documents[type] = JSON.parse(JSON.stringify(object[type]));

              break;
            case "Processing_Agreement":
              object[type]['type'] = 7;
              self.shopData.Wallet.documents[type] = JSON.parse(JSON.stringify(object[type]));

              break;

            default:
              break;
          }
          // object['file'] = file;
          //   console.log('type',type);
          // setTimeout(() => {

          self.LoadingService.dismiss();
          self.showToast('המסמך עלה בהצלחה');

          self.updateStr(path, object);

          self.UploadFlags[type] = false;
          // }, 2000);
          // self.logoUploadFlag = false;
          //  self.uploadPercent = task.percentageChanges();
        });

      })
    )
      .subscribe()
    return task;
  }


  // checkReminder() {
  //   let currentDateForReminder: any = new Date();
  //   currentDateForReminder.setSeconds(0);
  //   //sample data
  //   currentDateForReminder = 'Mon May 18 2020 16:15:45 GMT+0300 (Israel Daylight Time)';
  //   currentDateForReminder = new Date(currentDateForReminder);
  //   // end of samaple

  //   currentDateForReminder.setSeconds(0);
  //   currentDateForReminder = currentDateForReminder.toString();
  //   this.afDb.database
  //     .ref('/Reminders/').orderByChild('remindAt').equalTo(currentDateForReminder).once('value').then(res => {

  //       console.log('snap.val()', res.val());
  //       let value = this.json2array(res.val());
  //       value.forEach(element => {
  //         console.log(element.remindAt);
  //         console.log(element.uId);

  //         this.afDb.database.ref('/Users/' + element.uId + '/notification').once('value').then(res => {

  //           // noti var

  //           let noti: any = {
  //             BizName: "kljkjlk",

  //             meet: {
  //               event: element
  //             },
  //             notification: element.uIdata,
  //             what: "ReminderToClient"
  //           };
  //           noti.notification.notification = res.val(),



  //             // //. $obj->notification
  //             // $USERid = $obj->notification->notification->USERid;
  //             // $pushId = $obj->notification->notification->pushId;
  //             // $pushToken = $obj->notification->notification->pushToken;

  //             // $fname = $obj->notification->fname;
  //             // $phone = $obj->notification->phone;

  //             // $BizName = $obj->BizName;

  //             // //. $obj->meet
  //             // $meetName = $obj->meet->event->meetTypeData->name;
  //             // $startTimeView = $obj->meet->event->startTimeView;
  //             // $meetDate  = $obj->meet->event->meetDate;

  //             // //. $obj->$what
  //             // $what = $obj->what;



  //             console.log('res-notidfication', res.val());
  //           console.log('noti', noti);
  //           this._http.post('https://webc.co.il/pointnot/', noti).subscribe(respone => {
  //             console.log('respone: ', respone);
  //           });


  //         });

  //       });

  //     });


  //   return true
  // }




  deleteJsonFromArray(jsonArr, key, keyName) {


    const index = jsonArr.findIndex(x => x[keyName] === key);

    if (index !== undefined) jsonArr.splice(index, 1);

    //console.log("After removal:", jsonArr);
    return jsonArr;

  }





  // newGuid() {
  //   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
  //     var r = Math.random() * 16 | 0,
  //       v = c == 'x' ? r : (r & 0x3 | 0x8);
  //     return v.toString(16);
  //   });
  // }

  newGuid() {
    let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });

    let time = new Date().getTime();
    return guid + time;
  }


















  addzero(x) {
    x = x.toString();
    if (x.length == 1) {
      x = "0" + x;
    }
    return (x)
  }




  LastSeenText(lastSeen) {
    var today = new Date();
    var oldDate = new Date(lastSeen)

    var Difference_In_Time = today.getTime() - oldDate.getTime();

    // To calculate the no. of days between two dates 
    var Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    switch (Difference_In_Days) {
      case 0:
        return "התחבר היום !"
        break;
      case 1:
        return "התחבר אתמול"
        break;
      case 2:
        return "התחבר לפני יומיים"
        break;
      case 3:
        return "התחבר לפני שלושה ימים"
        break;

      default:
        var text = "התחבר לפני ";
        text += Difference_In_Days;
        text = " ימים ";
        return text;
        break;
    }



  }


  convertDate(inputFormat) {
    console.log('inputFormat', inputFormat);
    var today: any = new Date(inputFormat);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '-' + mm + '-' + yyyy;


  }


  convertDateDOTS(inputFormat) {
    console.log('inputFormat', inputFormat);
    var today: any = new Date(inputFormat);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '.' + mm + '.' + yyyy;


  }





  appDate = '';
  correctAppoin = "";
  daysName = ["יום ראשון", "יום שני", "יום שלישי", "יום רביעי", "יום חמישי", "יום שישי", "יום שבת"];



  // removeOldMeer() {
  //   // console.log("removeOldMeer",arrayOfMeet);

  //   if (arrayOfMeet != undefined) {
  //     var arrayOfMeet = Object.values(this.shopData.Meetings);
  //     arrayOfMeet.forEach(element => {
  //       var removeBack = this.shopData.Setting.history;
  //       var today = new Date();
  //       var dd3 = today.getDate();
  //       var mm3 = today.getMonth() + 1; //January is 0!
  //       var yyyy3 = today.getFullYear();
  //       var todayDay3 = mm3 + '-' + dd3 + '-' + yyyy3;

  //       today.setDate(today.getDate() - removeBack);
  //       var dd = today.getDate();
  //       var mm = today.getMonth() + 1; //January is 0!
  //       var yyyy = today.getFullYear();
  //       var todayDay = mm + '-' + dd + '-' + yyyy;
  //       var d1 = new Date(todayDay)


  //       var arr1 = element["key"].split('-');

  //       var dd2 = arr1[0]
  //       var mm2 = arr1[1] //January is 0!
  //       var yyyy2 = arr1[2]

  //       var todayDay2 = mm2 + '-' + dd2 + '-' + yyyy2;

  //       var d2 = new Date(todayDay2);

  //       if (d1 > d2) {
  //         // console.log("****",todayDay2,"******")
  //         var arrayType = Object.values(element);


  //         arrayType.forEach(meet => {


  //           var userid = meet.uId;
  //           // console.log("arrayType",meet,userid);
  //           if ((userid != undefined) && (meet.key != undefined)) {

  //             this.afDb.object('/BarberShop/' + this.shopID + "/Meetings/" + element["key"] + "/" + meet.key).remove();
  //             this.afDb.object('/Users/' + userid + '/meetings/' + this.shopID + '/' + meet.key).remove();

  //           } else {

  //           }

  //         });

  //         this.afDb.object('/BarberShop/' + this.shopID + '/Setting/')
  //           .update({
  //             historyStaus: todayDay3,

  //           })
  //       } else {
  //         // console.log("-----  ",todayDay2,"----- ")
  //       }
  //     });
  //   }

  // }


  getAllCode(x) {
    // return this.afDb.object('/BarberShop/'). .query.orderByChild("code").equalTo("12345")
    return this.afDb.list('/Codes/').valueChanges();
    return this.afDb.list('/BarberShop/Setting/', ref => ref.orderByChild("code").equalTo("12345")).valueChanges();
  }



  deletMeetFromUser(userId, shopID, Mkey) {
    console.log('/Users/' + userId + '/meetings/' + shopID + '/' + Mkey);
    return this.afDb.object('/Users/' + userId + '/meetings/' + shopID + '/' + Mkey).remove()
      .catch();

  }

  deletuser(userId) {
    console.log('/Users/' + userId);
    return this.afDb.object('/Users/' + userId).remove()
      .catch();

  }

  deleteMeetType(shopID, Mkey) {
    console.log('/BarberShop/' + shopID + '/MeetingType/' + Mkey);
    return this.afDb.object('/BarberShop/' + shopID + '/MeetingType/' + Mkey).remove()
      .catch();

  }

  smallModalForUser(path, data) {
    let userRef = this.afDb.object(path)

    // console.log("userRef : ", userRef, newMeetUser)
    this.tutrialModeskip = true;
    return userRef.set(data);
  }
  getUserMeetByShop(shopId, userId) {
    return this.afDb.object('/Users/' + userId + '/meetings/' + shopId).valueChanges();
  }
  // new meeeett

  setRepeatMeet() {
    console.log("REPEAT MEETTT")
    this.meet_api('add_meet_business', { meet: this.meetForBuis.exportMeetAdmin(), userid: this.meetForBuis.uId, ShopSetting: this.shopData.Setting, function: 'setRepeatMeet' });
    if (this.meetForBuis.uId != 'localUserAdmin') {
      this.meet_api('add_meet_user_business', { meet: this.meetForBuis.exportMeetUser(), userid: this.meetForBuis.uId, ShopSetting: this.shopData.Setting, userData: this.shopData.Users[this.meetForBuis.uId], notif_type: 'new_meet_to_client', function: 'setRepeatMeet' });
    }
  }

  setMeet(not_dismiss?) {
    // this.meetForBuis.setAdminMeet();
    this.meet_api('add_meet_business', { meet: this.meetForBuis.exportMeetAdmin(), userid: this.meetForBuis.uId, ShopSetting: this.shopData.Setting, function: 'setMeet' }, () => {
      this.finishSetMeet(not_dismiss);
    });
    if (this.meetForBuis.uId != 'localUserAdmin') {
      this.meet_api('add_meet_user_business', { meet: this.meetForBuis.exportMeetUser(), userid: this.meetForBuis.uId, ShopSetting: this.shopData.Setting, userData: this.shopData.Users[this.meetForBuis.uId], notif_type: 'new_meet_to_client', function: 'setMeet' });
    }














    // this.checkIfRealUser(this.meetForBuis.uId).then(res => {
    //   let user = res.val();
    //   console.log(user);

    //   let meet_notif = {
    //     name: this.meetForBuis.meetType.name,
    //     startTimeView: this.meetForBuis.startTimeView,
    //     meetDate: this.meetForBuis.meetDate
    //   }

    //   if (user && this.meetForBuis.uId != 'localUserAdmin') {
    //     this.meet_api('add_meet_user_business', { meet: this.meetForBuis.exportMeetUser(), userid: this.meetForBuis.uId });
    //     let data_for_reminder = this.meetForBuis.exportMeetAdmin();
    //     data_for_reminder['uIdata'] = {
    //       fname: this.currentMeet.user.fname,
    //       lname: this.currentMeet.user.lname
    //     };
    //     this.setReminder(data_for_reminder, this.meetForBuis.key);
    //     console.log(meet_notif);

    //     //NOTIFICATION
    //     let notif_data = {
    //       type: 'new_meet_to_client',
    //       users: [user],
    //       meet: meet_notif
    //     }
    //     this.send_notif(notif_data);
    //     this.finishSetMeet();

    //   } else {
    //     // this.meet_api('add_meet_business', this.meetForBuis.exportMeetAdmin());
    //     console.log(user);

    //     if (user.phone) {
    //       if (!this.check_if_date_in_past(this.meetForBuis.meetDate, this.meetForBuis.startTimeView)) {
    //         // SMS
    //         let sms_data = {
    //           type: 'new_local_client_meet',
    //           to_phone: this.currentMeet.user.phone,
    //           meet: meet_notif
    //         }
    //         console.log(sms_data);

    //         this.send_sms(sms_data);
    //         this.showToast('נשלח sms ללקוח');
    //       }
    //       this.meetForBuis.saveForIndex(this.currentMeet.user.phone);
    //     }
    //     this.finishSetMeet();

    //   }
    // });

  }

  finishSetMeet(not_dismiss?) {
    this.LoadingService.dismiss();
    if (!not_dismiss) {
      this.modalController.dismiss();
    }
    this.tutrialModeskip = false;
    this.meetService.cleanService();
    this.correctAppoin = "";
    this.moreInfo = '';
    this.addLineFLAG = false;
    this.showToast('התור זומן בהצלחה');
    this.meetForBuis.resetCLass();
    // this.calendar.currentDate = data.cal
    if (this.calendar.currentDate) {
      let currentDate = new Date(this.calendar.currentDate).setHours(0, 0, 0, 0);
      this.dateForView.forEach(date => {
        date.active = new Date(date.cal).setHours(0, 0, 0, 0) == currentDate ? true : false;
      });
    }
  }

  itemSelectedTimeAdmin() {
    // this.meetForBuis.importBarber(this.shopData.Setting.BarberID, this.shopData.Setting.name);
    // this.meetForBuis.importMoreInfo(this.moreInfo);
    // this.meetForBuis.CreateNewMeet();
    // this.meetForBuis.defineFlowAuto();
    // this.meetForBuis.importMeetStatus(1);
    // this.meetForBuis.showClass();

    // this.setMeet();
    // if ((this.currentMeet.user.local == true) || (this.meetForBuis.uId == "localUserAdmin")) {
    // } else {
    //   this.meetForBuis.setUserMeet();
    // }


    // if ((this.currentMeet.user.local == true) && (this.currentMeet.user.phone != "")) {
    //   this.smsS.sendSMS_local_client(this.meetForBuis.exportMeetAdmin(), this.currentMeet.user, 'new_local_client_meet');
    //   this.showToast('נשלח sms ללקוח');
    //   this.meetForBuis.saveForIndex(this.currentMeet.user.phone);
    // }

    // this.meetForBuis.setAdminMeet();
    // this.log(this.meetForBuis.uId, 'itemSelectedTimeAdmin');

    // this.tutrialModeskip = false;

    // this.meetForBuis.showClass();
    // this.meetForBuis.saveBuisHistory();
    // this.meetService.cleanService()
    // this.addCounter();

    // return true;
  }

  // setEvent() {
  //   var userData = this.userDataSelectedEvent;
  //   console.log("userData", userData);
  //   this.log(userData.uId, 'setEvent');

  //   this.setEventUser(this.eventForBuis.exportEventUser(), userData).then(res => {

  //     this.updateEvent(this.eventForBuis.exportEventAdmin(), userData).then(res => {


  //       this.correctAppoin = "";
  //       this.alertModal = true;
  //       this.confettiFLAG = false;

  //       this.eventForBuis.resetCLass();

  //       this.router.navigate(['tabs/home'], { replaceUrl: true });
  //       this.saveBuisHistoryEvents(this.eventForBuis.exportEventAdmin());



  //       // this.meetService.sendNoti(this.us.user, this.meetForBuis.exportMeetUser(), this.meetForBuis.exportMeetAdmin());



  //     })
  //   })


  // };


  setUser() {

  }

  // newEvent(cb?) {    
  //   var eventDate = this.eventForBuis.exportEventUser();
  //   this.addUserToEvent(eventDate, this.userDataSelectedEvent).then(res => {
  //     this.router.navigate(['tabs/home'], { replaceUrl: true });
  //     if (cb) {
  //       let price = meetType.price;
  //       if (meetType.payment_way == 'down_payment') {
  //         price = ((parseFloat(meetType.down_payment_amount) * parseFloat(meetType.price)) / 100).toString();
  //       }

  //       let obj = {
  //         name: meetType.name,
  //         price: price,
  //         BarberID: this.meetForBuis.BarberID,
  //         userid: this.meetForBuis.uId,
  //         key: 'MEET-' + this.meetForBuis.key,
  //         type: 'meet',
  //         meetID: this.meetForBuis.key,
  //         meet_date: this.meetForBuis.convertDateToYYYY(this.meetForBuis.meetDate)
  //       }
  //       console.log(obj);

  //       cb(obj);
  //     } else {
  //       this.meetApprove();
  //     }
  //   });
  // }
  currentDay = new Date().getDay();

  getMinStartTime() {
    let minTime = 24;
    this.shopData.WorkDays.forEach((day, ind) => {
      let split = day.timeStart.split(':');
      let hour = parseInt(split[0]);
      let min = parseInt(split[1]);
      let sum = hour + min;
      if (sum < minTime && sum > 0) {
        minTime = sum;
        this.currentDay = ind;
      }
    });
    console.log(minTime);
    console.log(this.currentDay);
  }

  setCurrentDay(date) {
    console.log(this.calendar.mode);

    if (this.calendar.mode == 'week') {
      this.getMinStartTime();
    } else {
      this.currentDay = new Date(date).getDay();
    }
  }

  switchDay(a) {
    // if (this.calendar.mode == 'week') {
    //   this.getMinStartTime();
    // } else {
    //   this.currentDay = new Date(a.cal).getDay();
    // }
    if (a.cal != this.calendar.currentDate) {
      // console.log('SAME DAY!!!!!!@#!@$%!%!@W!%T^%$&^%#^@#$!$');


      this.setCurrentDay(a.cal);
      this.closeBusinessMeetingsSub();
      console.log("a", a)
      this.dateForView.forEach(x => {
        x.active = false;
        if (this.formatDate(a.cal) == this.formatDate(x.cal)) {
          x.active = true;
        }
      });
      this.calendar.currentDate = a.cal;
      this.currentMeet.date = a;
      setTimeout(() => {
        this.openBusinessMeetingsSub();
      }, 10);

    }
  }

  selectEvent(meet) {
    console.log(meet);

    if (meet.eventStatus == 6) {
      this.presentAlertMeet("כבר נרשמת למפגש זה");
      return;
    }

    console.log("meet.eventCloseBefore", meet.CloseBefore)

    if (meet.CloseBefore && this.testDateInDayes(meet.CloseBefore, meet.startDate)) {
      this.presentAlertMeet("מועד ההרשמה למפגש עבר, צור קשר עם העסק");
      return;
    }

    if (this.testDate(meet.startDate)) {
      this.presentAlertMeet("שעת המפגש עברה");
      return;
    }

    // this.userDataSelectedEvent = this.us.user;
    // console.log("alll event", event, this.allevents);

    if (meet.CatchPeople >= meet.totalPeople) {
      this.presentAlertMeet("נגמרו המקומות למפגש זה ")
    } else {
      // this.eventToNotActive();
      this.eventForBuis.resetCLass();
      this.eventForBuis.importEventData(meet);
      this.eventForBuis.showClass();
      // meet.active = true;
      this.changeButtonState(5);
    }
  }

  async presentAlertMeet(msg) {
    var textT = "";
    const alert = await this.alertController.create({
      header: msg,
      message: textT,
      buttons: [
        {
          text: 'סגור',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        },

      ]
    });

    await alert.present();
  }

  async presentAlertConfirmMeet(meet, cb?) {
    const alert = await this.alertController.create({
      header: 'מחיקת תור',
      message: 'האם אתה בטוח שברצונך למחוק',
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            if (cb) cb(false);
          }
        }, {
          text: 'כן',

          handler: () => {
            if (!cb) this.LoadingService.present_for(5000);

            this.meet_api('delete_meet_business', { meet: this.meetForBuis.exportMeetAdmin(), userid: meet.uId, ShopSetting: this.shopData.Setting, function: 'presentAlertConfirmMeet' }, () => {
              this.finishDeleteMeet(meet);
            });

            if (meet.meetStatus != 5 && meet.uId != 'localUserAdmin') {
              let notif_type;
              this.meetForBuis.meetStatus = 5;
              if (meet.meetStatus == 3) {
                notif_type = 'admin_disapprove_meet';
              } else {
                notif_type = 'admin_cancel_meet';
              }
              this.meet_api('update_meet_user_business', { meet: this.meetForBuis.exportMeetUser(), userid: meet.uId, ShopSetting: this.shopData.Setting, userData: this.shopData.Users[meet.uId], notif_type, function: 'presentAlertConfirmMeet' });
            }
            if (cb) cb(true);
          }
        }
      ]
    });
    await alert.present();
  }

  finishDeleteMeet(meet) {
    this.LoadingService.dismiss();
    this.saveToTrushMeet(meet);
    this.meetmodalFLAG = false;
    this.meetForBuis.resetCLass();
    this.showToast('התור נמחק בהצלחה');
  }


  deleteMeet(meet) {
    console.log(meet);

    this.meet_api('delete_meet_business', { meet: meet, userid: meet.uId, ShopSetting: this.shopData.Setting, function: 'deleteMeet' });

    if (meet.meetStatus != 5 && meet.uId != 'localUserAdmin') {
      let notif_type;
      this.meetForBuis.meetStatus = 5;
      if (meet.meetStatus == 3) {
        notif_type = 'admin_disapprove_meet';
      } else {
        notif_type = 'admin_cancel_meet';
      }
      this.meet_api('update_meet_user_business', { meet: meet, userid: meet.uId, ShopSetting: this.shopData.Setting, userData: this.shopData.Users[meet.uId], notif_type, function: 'deleteMeet' });
    }

    this.saveToTrushMeet(meet);
  }

  // checkEventAvailable(cb?) {
  //   this.getEvent(this.eventForBuis.exportEventUser()).then(res => {
  //     let event = res.val();
  //     if (event.CatchPeople < event.totalPeople) {
  //       console.log("go next");
  //       // return true;
  //       cb(true);
  //       // this.orderSelectedAppoitment();
  //     } else {
  //       // this.changeViewHouers(this.calendar.currentDate);
  //       // this.presentAlertMeet();
  //       this.eventForBuis.CatchPeople = event.CatchPeople;
  //       this.eventForBuis.totalPeople = event.totalPeople;
  //       console.log("problemmmmmm");
  //       // return false;
  //       cb(false);
  //     }
  //   })
  // }

  // checkMeetAvailable(cb) {
  //   var appDate = new Date(this.calendar.currentDate);
  //   var stringDate = this.formatDate(appDate);
  //   var gtafusmeet = this.getDateTafus(stringDate).valueChanges().subscribe(data2 => {
  //     var gtafusevent = this.getEventTafus(stringDate).valueChanges().subscribe(data3 => {
  //       // console.log(this.meetService.meetType.meetTime);

  //       let end: any = new Date(this.meetForBuis.Date);
  //       end.setTime(end.getTime() + (Number(this.meetService.meetType.meetTime) * 60 * 1000));
  //       var endTimeView = this.meetForBuis.addzero(end.getHours()) + ':' + this.meetForBuis.addzero(end.getMinutes())
  //       var allmeetstest: any = data2.concat(data3);
  //       var errorsmeet = 0;

  //       for (var i = 0, l = allmeetstest.length; i < l; i++) {
  //         if (allmeetstest[i].endTimeView <= this.meetForBuis.startTimeView) {
  //           console.log(allmeetstest[i].startTimeView, "-", allmeetstest[i].endTimeView, "ok 1")
  //         } else {
  //           if (allmeetstest[i].startTimeView >= endTimeView) {
  //             console.log(allmeetstest[i].startTimeView, "-", allmeetstest[i].endTimeView, "ok 2")
  //           } else {
  //             if ((allmeetstest[i].meetStatus == 5) || (allmeetstest[i].flow == true) || (this.meetService.meetToChange) && (this.meetService.meetToChange.startTimeView == allmeetstest[i].startTimeView)) {
  //             } else {
  //               errorsmeet++;
  //               console.log("----------------fuckkkkkkkkk", allmeetstest[i])
  //             }
  //           }
  //         }
  //       }

  //       gtafusevent.unsubscribe();

  //       if (errorsmeet == 0) {
  //         console.log("go next");
  //         // return true;
  //         cb(true);
  //         // this.orderSelectedAppoitment();
  //       } else {
  //         // this.changeViewHouers(this.calendar.currentDate);
  //         // this.presentAlertMeet();
  //         console.log("problemmmmmm", allmeetstest[i]);
  //         // return false;
  //         // this.changeViewHouers(this.calendar.currentDate);
  //         // this.presentAlertMeet('היי התור בידיוק נתפס! נסו תור אחר');
  //         cb(false);
  //       }
  //     });

  //     gtafusmeet.unsubscribe();
  //   });
  // }

  itemSelectedTime(cb?) {
    // let meetIsFree = await this.checkMeetAvailable();

    // if (meetIsFree) {
    //   this.changeButtonState(0);
    // } else {
    //   this.changeViewHouers(this.calendar.currentDate);
    //   this.presentAlertMeet();
    //   return;
    // }
    this.meetForBuis.importBarber(this.shopData.Setting.BarberID, this.shopData.Setting.name);
    this.meetForBuis.importUserId(this.us.key);
    this.meetForBuis.importMoreInfo(this.moreInfo);
    // this.needApproveModalFlag = this.meetForBuis.meetStatusDefine();
    this.meetForBuis.CreateNewMeet();
    this.needApproveModalFlag = this.meetForBuis.meetStatus == 3 ? false : true;
    this.meetForBuis.zoomlink = "";
    this.meetForBuis.showClass();
    let meetType = this.meetForBuis.meetType;
    this.meetForBuis.newKey();

    if (this.meetService.meetToChange) {
      this.meetForBuis.meetStatus = 3;
      this.shopMessage.ok = true;
      this.shopMessage.message = "התור שונה בהצלחה ";
      // this.meetForBuis.updateBarberMeet();
      // this.meetForBuis.updateUserMeet();
      this.meet_api('change_meet_user', { meet: { user_meet: this.meetForBuis.exportMeetUser(), admin_meet: this.meetForBuis.exportMeetAdmin(), old_meet: this.meetService.meetToChange, ShopSetting: this.shopData.Setting }, notif_type: 'change_meet_user', function: 'itemSelectedTime' });
      // this.removeReminder(this.meetForBuis.exportMeetUser().key);
      // if (this.meetService.meetToChange.meetDate != this.meetForBuis.meetDate) {
      // this.updateToCancelAdminmeet(this.meetService.meetToChange);
      // this.meet_api('cancel_meet_user', this.meetService.meetToChange);
      // }
    } else {
      // this.meetForBuis.newKey();
      this.meet_api('add_meet_user', { meet: { user_meet: this.meetForBuis.exportMeetUser(), admin_meet: this.meetForBuis.exportMeetAdmin() }, userData: this.us.user, notif_type: 'add_meet_user', ShopSetting: this.shopData.Setting, function: 'itemSelectedTime' });

      // this.meet_api('add_meet_business', this.meetForBuis.exportMeetAdmin());
    }

    // await this.meetForBuis.updateUserMeet();
    // await this.meetForBuis.updateBarberMeet();

    if (cb) {
      let price = meetType.price;
      if (meetType.payment_way == 'down_payment') {
        price = ((parseFloat(meetType.down_payment_amount) * parseFloat(meetType.price)) / 100).toString();
      }

      let obj = {
        name: meetType.name,
        price: price,
        BarberID: this.meetForBuis.BarberID,
        userid: this.meetForBuis.uId,
        key: 'MEET-' + this.meetForBuis.key,
        type: 'meet',
        meetID: this.meetForBuis.key,
        meet_date: this.meetForBuis.convertDateToYYYY(this.meetForBuis.meetDate)
      }
      console.log(obj);

      cb(obj);
    } else {
      this.meetFinish();
    }
  }

  approveMeet() {
    console.log(this.shopData.Users[this.meetForBuis.uId]);
    this.meetForBuis.importMeetStatus(1);
    this.SelectedMeet.event.meetStatus = 1;
    this.meet_api('update_meet_business', { meet: this.meetForBuis.exportMeetAdmin(), userid: this.meetForBuis.uId, ShopSetting: this.shopData.Setting, function: 'approveMeet' }, () => {
      this.LoadingService.dismiss();
    });

    this.meet_api('update_meet_user_business', { meet: this.meetForBuis.exportMeetUser(), userid: this.meetForBuis.uId, ShopSetting: this.shopData.Setting, userData: this.shopData.Users[this.meetForBuis.uId], notif_type: 'Approve', function: 'approveMeet' });
  }

  meetFinish() {
    // BELL
    if (this.meetService.meetToChange) {
      this.addNotificationBell('meet_change', 'business', { meet: this.meetForBuis.exportMeetAdmin(), old_meet: this.meetService.meetToChange });
    } else {
      this.addNotificationBell('new_meet', 'business', { meet: this.meetForBuis.exportMeetAdmin() });
    }

    this.meetForBuis.sendSms = "false";
    // this.meetService.sendNoti(this.us.user, this.meetForBuis.exportMeetUser(), this.meetForBuis.exportMeetAdmin());

    // NOTIFICATION
    if (this.shopData.admin) {
      let notif_data = {
        type: this.meetForBuis.meetStatus == 3 ? 'ClientOrderNeedApprove' : 'ClientOrder',
        send_to: [this.shopData.admin],
        user: this.us.user,
        meet: {
          name: this.meetForBuis.meetType.name,
          startTimeView: this.meetForBuis.startTimeView,
          meetDate: this.meetForBuis.meetDate
        }
      }

      this.send_notif(notif_data);
    }

    // REMINDER
    // if (this.meetForBuis.meetStatus != 3) {
    //   let data_for_reminder = this.meetForBuis.exportMeetAdmin();
    //   data_for_reminder['uIdata'] = {
    //     fname: this.us.user.fname,
    //     lname: this.us.user.lname
    //   };
    // this.setReminder(data_for_reminder, this.meetForBuis.key);
    // }

    // this.log(this.meetForBuis.uId, 'itemSelectedTime');

    this.tutrialModeskip = false;
    // this.meetForBuis.saveBuisHistory();
    this.meetService.cleanService();
    this.correctAppoin = "";
    this.alertModal = true;
    // this.confettiFLAG = false;
    this.turnOnConfetti(2500);
    this.meetForBuis.resetCLass();
    this.router.navigate(['tabs/home'], { replaceUrl: true });

    for (var key in this.shopData.MeetingType) {
      let element = this.shopData.MeetingType[key];
      if (element.active == true) {
        //  console.log('this.shopData.MeetingType[key].active=false',this.shopData.MeetingType[key].active,'=',false);
        this.shopData.MeetingType[key].active = false;
      }
    }
  }

  newMeetForBuis(BarberID, barberName, endTime, meetTime, price, startTimeView, endTimeView, meetType, meetDate, meetTypeName, meetDay, meetStatus, moreInfo, Date, key, uId) {
    let newMeetUser = {
      BarberID: BarberID,
      barberName: barberName,
      endTime: endTime,
      meetTime: meetTime,
      price: price,
      startTimeView: startTimeView,
      endTimeView: endTimeView,
      meetType: meetType,
      meetDate: meetDate,
      meetTypeName: meetTypeName,
      meetDay: meetDay,
      meetStatus: meetStatus,
      moreInfo: moreInfo,
      Date: Date,
      key: key,
      uId: uId,
    }
    this.log(uId, 'newMeetForBuis');

    let userRef = this.afDb.object('/Users/' + this.us.key + "/meetings/" + this.shopData.Setting.BarberID + "/" + key);
    userRef.set(newMeetUser).then();
    return newMeetUser;
  }
  setUserMeet(meet, uId, BarberID, newID) {
    let userRef = this.afDb.object('/Users/' + uId + "/meetings/" + BarberID + "/" + newID);
    userRef.set(meet).then();
  }

  setAdminMeet(meet, BarberID, dateTime, newID) {
    let baraber = this.afDb.object('/BarberShop/' + BarberID + "/Meetings/" + dateTime + "/" + newID);
    baraber.set(meet);
  }

  // saveToTrush() {
  //   var newmeet = this.meetForBuis.exportMeetAdmin();
  //   if (this.shopData.Setting.buisType) {
  //     newmeet.BarberID = this.shopData.Setting.buisType;
  //     newmeet.barberName = this.shopData.Setting.buisType;
  //   } else {
  //     newmeet.BarberID = "no-title";
  //     newmeet.barberName = "no-title";
  //   }

  //   this.afDb.list('/Trush/' + newmeet.BarberID).push(newmeet)
  // }

  // saveEventToTrush() {
  //   var newmeet = this.eventForBuis.exportEventAdmin();

  //   if (this.shopData.Setting.buisType) {
  //     newmeet.BarberID = this.shopData.Setting.buisType;
  //     newmeet.barberName = this.shopData.Setting.buisType;
  //   } else {
  //     newmeet.BarberID = "no-title";
  //     newmeet.barberName = "no-title";
  //   }

  //   this.afDb.list('/Trush/' + newmeet.BarberID).push(newmeet)
  // }

  saveToTrushMeet(newmeet) {
    newmeet = JSON.parse(JSON.stringify(newmeet));
    if (this.shopData.Setting.buisType) {
      newmeet.BarberID = this.shopData.Setting.buisType;
      newmeet.barberName = this.shopData.Setting.buisType;
    } else {
      newmeet.BarberID = "no-title";
      newmeet.barberName = "no-title";
    }

    this.afDb.list('/Trush/' + newmeet.BarberID).push(newmeet);
  }

  // saveHistory(meet, Mdate, userId, BarberID, newID) {
  //   var BarberHistory = {
  //     "barber": meet,
  //     'Mdate': Mdate,
  //     'userId': userId,
  //     'BarberID': BarberID,
  //   }
  //   this.afDb.object('/BarberShop/' + BarberID + "/History/" + newID).set(BarberHistory);

  // }

  // saveHistoryShop(meetData) {

  //   if (meetData.meetType == 'event') {
  //     this.afDb.object('/BarberShop/' + meetData.BarberID + "/History/Events/" + this.meetForBuis.convertDateToYYYY(meetData.meetDate) + "/" + meetData.key).set(meetData);

  //   } else {
  //     this.afDb.object('/BarberShop/' + meetData.BarberID + "/History/Meetings/" + this.meetForBuis.convertDateToYYYY(meetData.meetDate) + "/" + meetData.key).set(meetData);

  //   }

  // }
  needApproveModalFlag = false;
  removeAllEmtpyUsers() {

    var useDateTodelet = Object.values(this.shopData.Users).filter(function (el: any) {
      return el.fname == ""
    });
    console.log(useDateTodelet, "useDateTodelet");
    useDateTodelet.forEach(x => {
      // this.afDb.object('/BarberShop/' + this.shopID + '/Users/' + x.key).remove();
    })




    // this.afDb.object('/BarberShop/' + this.shopID + '/Users/' + user.key).remove();
  }

  overTimeFunction(endh, endM, meetStatus) {
    console.log("this.meetService.endWork", this.meetService.TotalendTimeHour, this.meetService.TotalendTimeMinuts, endh, endM);
    if (this.meetService.TotalendTimeHour && this.meetService.TotalendTimeMinuts && endh && endM) {


      if (this.meetService.TotalendTimeHour < endh) {
        return meetStatus;
      }
      if (this.meetService.TotalendTimeHour == endh) {
        if (this.meetService.TotalendTimeMinuts < endM) {
          return 3;
        } else {
          return meetStatus;
        }
      }
    } else {
      return meetStatus;
    }

    return meetStatus;
  }
  checkPhone(phoneNumber) {

    return this.afDb.list('/BarberShop/' + this.shopID + '/Users').valueChanges();


  }
  AdminEditMode = true;
  updateCodeSetting() {
    this.shopData.Setting.codeVisible = !this.shopData.Setting.codeVisible;

    this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/')
      .update({
        codeVisible: this.shopData.Setting.codeVisible,

      })
  }

  updateAi() {
    this.shopData.Setting.ai = !this.shopData.Setting.ai;

    this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/')
      .update({
        ai: this.shopData.Setting.ai,

      })
  }

  getBuisSettingByID(BarberID) {
    return this.afDb.database.ref('/BarberShop/' + BarberID + '/Setting').once('value');
  }

  current_biz = null;
  goToBuis(biz) {
    console.log("gotobuis:", biz);
    // this.current_biz = null;
    // if (this.us.user.bizArray && this.us.user.bizArray.includes(biz.BarberID)) {
    //   this.changeButtonState(7);
    // } else {
    //   this.changeButtonState(6);
    // }
    // this.indexCurrentBuisSetting = { Setting: biz };, { replaceUrl: true }
    this.current_biz = biz;//<----
    this.router.navigate(['b/' + biz.code]);
  }

  update_invoice_auto_on_payment() {
    this.shopData.Setting.invoice_auto_on_payment = !this.shopData.Setting.invoice_auto_on_payment;

    this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/')
      .update({
        invoice_auto_on_payment: this.shopData.Setting.invoice_auto_on_payment,

      })
  }

  updatesearchByText() {
    this.shopData.Setting.searchByText = !this.shopData.Setting.searchByText;
    let score_val = 0;
    this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/')
      .update({
        searchByText: this.shopData.Setting.searchByText,

      });

    if (this.shopData.Setting.searchByText == false) {
      score_val = -100;
    } else {
      score_val = 100;
    }

    this.firestore.collection('Shop_Search_Info').doc(this.shopData.Setting.BarberID).get().subscribe(val => {
      let score_data = val.data();

      this.firestore.collection('Shop_Search_Info').doc(this.shopData.Setting.BarberID)
        .update({
          Total_Score: score_data.Total_Score + score_val,
          searchByText: this.shopData.Setting.searchByText
        });
    });
  }


  updateBuisName(name) {
    console.log('updateBuisName', name);

    this.firestore.collection('Shop_Search_Info').doc(this.shopData.Setting.BarberID)
      .update({
        BuisName: name
      });
  }


  updateBuisGeoHash() {

    setTimeout(() => {
      console.log('updateBuisGeoHash', this.locationSet);
      let body = {
        lat: this.locationSet.latitude,
        lng: this.locationSet.longitude,
        actionType: 'update Buis GeoHash',
        BarberID: this.shopData.Setting.BarberID,
        addres: this.locationSet.address//.slice(0, -8)
      }
      this.shopData.Setting.addres = this.locationSet.address.slice(0, -8);

      body = this.cryptoService.Encrypt(body);
      this.http.post(this.serverDev + '/Geo', body).subscribe(val => {
        console.log(val);
      });
    }, 1500);

  }



  updateGetMoney() {
    this.shopData.Setting.getMoney = !this.shopData.Setting.getMoney;

    this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/')
      .update({
        getMoney: this.shopData.Setting.getMoney,

      })
  }

  updateFacebookLink(link) {
    this.shopData.Setting.facebook_link = link;

    this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/')
      .update({
        facebook_link: this.shopData.Setting.facebook_link
      });
  }

  updateInstagramLink(link) {
    this.shopData.Setting.instagram_link = link;

    this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/')
      .update({
        instagram_link: this.shopData.Setting.instagram_link
      });
  }

  async updateConfirmUsers() {
    if (this.shopData.Setting.Premium.status == 'premium') {
      this.shopData.Setting.confirm_users = !this.shopData.Setting.confirm_users;

      this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/')
        .update({
          confirm_users: this.shopData.Setting.confirm_users
        });
    } else {
      var modal = await this.modalController.create({
        component: PremiumLockComponent,
        swipeToClose: false,
        cssClass: 'modalLookfullWidth',
        backdropDismiss: false,
        mode: "ios"
      });

      await modal.present();
    }
  }


  confirmUser(user) {
    console.log(user);

    user.confirm_status = 'confirm';
    this.checkNeedConfirm(this.shopData.Users);
    // this.getNeedConfirmBuis(user).then(res => {

    //   let confirm_arr = res.val();
    //   console.log(confirm_arr);
    //   if (confirm_arr) {
    // confirm_arr = confirm_arr.filter(buis => buis != this.shopID);
    this.deleteBusiFromNeedConfirm(user, this.shopData.Setting.BarberID);
    this.updateUser(user);
    // }
    // });
  }

  notConfirmUser(user) {
    // this.getNeedConfirmBuis(user).then(confirm_res => {
    // console.log(confirm_res);
    // let confirm_arr = confirm_res.val();
    // if (confirm_arr) {
    // confirm_arr = confirm_arr.filter(buis => buis != this.shopData.Setting.BarberID);
    this.deleteUserFromBarber(user);
    this.getBizArray(user).then(biz_res => {
      let biz_arr = biz_res.val();
      if (biz_arr) {
        biz_arr = biz_arr.filter(buis => buis != this.shopData.Setting.BarberID);
        this.deleteBusiFromBizArray(user, biz_arr).then(ress => {
          this.deleteBusiFromNeedConfirm(user, this.shopData.Setting.BarberID).catch(err => {
            // this.logWithData(this.us.user.key, 'deleteBusiFromNeedConfirm', err);
          });
        });
      }
    });
    // })
    // }
    // });
  }

  needConfirm = false;
  checkNeedConfirm(users) {
    let need_confirm = false;
    let usersData = this.json2array(users);
    if (usersData.length) {
      usersData.forEach(user => {
        if (user.confirm_status == 'pending') {
          need_confirm = true;
        }
      });
    }
    this.needConfirm = need_confirm;
  }

  dismissModalController() {
    this.modalController.getTop().then(modal => {
      if (modal) {
        this.modalController.dismiss();
      }
    })
  }


  sendData(username, phone, emailtxt, textdata, typep, userID) {
    return this._http.post('https://webc.co.il/pointnot/mail.php', { name: username, text: textdata, phone: phone, email: emailtxt, type: typep, userID: userID });
  }

  setVendorRequest(leadID) {
    if (!this.shopData.vendor_requests) {
      this.shopData.vendor_requests = {};
    }
    this.shopData.vendor_requests[leadID] = new Date().getTime();
    return this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/vendor_requests/')
      .update(this.shopData.vendor_requests);
  }


  setGiftOptions() {
    return this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/')
      .update({ gift_options: { used: true } });
  }

  mailPayme(mailDetails) {
    // return this._http.post('https://webc.co.il/pointnot/mail_payme.php', { name: username, text: textdata, phonenumber: phone, email: emailtxt, type: typep });
    // return this._http.post('https://webc.co.il/pointnot/mail_payme.php', { name: mailDetails.name, text: mailDetails.content, phone: mailDetails.phone, email: mailDetails.email, subject: mailDetails.subject });
    console.log(mailDetails);
    return this._http.post('https://webc.co.il/pointnot/mail_payme.php', mailDetails);
  }

  async showAlert(title, msg, button) {
    const alert = await this.alertController.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `סגור`,
          handler: () => {
            // E.g: Navigate to a specific screen
          }
        }
      ]
    })
    alert.present();
  }

  getOneSignalID(USERid) {
    let self = this;




    if (this.platform == 'android') {
      if (window.plugins) {
        console.log('getOneSignalID', USERid)
        window.plugins.OneSignal.setAppId("4b5b5a65-0dea-4565-bd02-54bb3673a8ef");

        //Prompts the user for notification permissions.
        //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
        window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
          console.log("User accepted notifications: " + accepted);
          if (self.is_phone_code_admin == false) {
            window.plugins.OneSignal.setExternalUserId(USERid);
            self.setNotificationData(USERid, USERid, USERid);
          }

        });
        window.plugins.OneSignal.setNotificationOpenedHandler(function (jsonData) {
          console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
          let msg = jsonData.payload.body;
          let title = jsonData.payload.title;
          let additionalData = jsonData.payload.additionalData;
          self.showAlert(title, msg, additionalData.button);

          // let msg = data.payload.body;
          // let title = data.payload.title;
          // let additionalData = data.payload.additionalData;
          // this.showAlert(title, msg, additionalData.button);
        });
        //Prompts the user for notification permissions.
        //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
        window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
          console.log("User accepted notifications: " + accepted);
        });
      }
      // window.plugins.OneSignal.getIds(function(jsonData) {

      //     console.log('getIds:'+ JSON.stringify(jsonData));

      // })




    } else {
      this.oneSignal.getIds().then((id) => {
        //  console.log('getOneSignalID:',id);
        // alert('userId:' +  id.userId);
        // alert('USERid:' +  USERid);
        // alert('pushToken:' +  id.pushToken);
        if (this.is_phone_code_admin == false) {
          self.setNotificationData(USERid, id.userId, id.pushToken);
        }
      });
    }

    // console.log('getOneSignalID=mitid:',USERid);
    // alert('1:' +  USERid);
    // alert('2:' +  JSON.stringify(this.oneSignal));
    // alert('3:' +  JSON.stringify(this.oneSignal.getIds()));


  }




  check_if_date_in_past(meetDate, startTimeView) {
    let split = meetDate.split('-');
    let splitH = startTimeView.split(':');

    let makeDate = new Date(split[2] + ',' + split[1] + ',' + split[0]);
    makeDate.setHours(parseInt(splitH[0]));
    makeDate.setMinutes(parseInt(splitH[1]));

    if (makeDate < new Date()) {
      return true;
    } else {
      return false;
    }
  }





  Modal_somethingwentwrong;

  async presentModal_somethingwentwrong(): Promise<HTMLIonModalElement> {
    this.menuTogglle('close')

    this.Modal_somethingwentwrong = await this.modalController.create({
      component: SomethingwentwrongComponent,
      swipeToClose: false,
      cssClass: 'modalPointBG',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",

      // Get the top-most ion-modal

    });


    await this.Modal_somethingwentwrong.present();

    const { data } = await this.Modal_somethingwentwrong.onWillDismiss();

    if (data) {
      if (data.dismissed == "finish") {

      }
    }
    return this.Modal_somethingwentwrong;

  }


  changeBiz(id) {
    console.log(id);

    if (id) {

      // this.shopS.CloseLiveSub(this.shopS.shopData.Setting.BarberID);
      // this.shopS.openLiveSub(this.shopData.BarberId, 'user');
      // let buis_id = id ? id : this.shopData.BarberId;
      this.closeSettingSub();
      this.openSettingSub(id);
      this.shopData.BarberId = id;
      this.getBusinessDataForUser(id);
      this.us.user.BarberID = id;
      this.updateStrFull('/Users/' + this.us.user.key + '/', { BarberID: id });
      this.shopID = id;
    }
  }


  async getAllBarbers() {
    console.log('getAllBarbers');
    console.log(this.us.user.bizArray);

    if (this.us.user.bizArray) {
      const self = this;
      this.businessArray = [];
      let arr = [];
      this.buttonHide = false;
      let promises = [];
      this.us.user.bizArray.forEach(element => {
        promises.push(self.getBizSetting(element));
      });//end of forEach 
      Promise.all(promises).then(values => {
        values.forEach(val => {
          let bizData = val.val();
          arr.push({ Setting: bizData, BarberId: bizData.BarberID });
        });
        console.log(arr);

        self.businessArray = arr.sort(this.compare);
      });
    } else {
      console.log('no biz array');
      this.buttonHide = true;
    }//end of if  
  }

  compare(a, b) {
    if (a.Setting && b.Setting) {
      const bandA = a.Setting.name.toLowerCase();
      const bandB = b.Setting.name.toLowerCase();

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    }
  }






  Modal_Swich;

  async presentModal_Swich(): Promise<HTMLIonModalElement> {
    this.menuTogglle('close')
    //console.log('this.Modal_Swich',this.Modal_Swich)
    const isModalOpened = await this.modalController.getTop();
    // console.log('isModalOpened',isModalOpened)
    if (!isModalOpened) {
      this.Modal_Swich = await this.modalController.create({
        component: SwichmodalComponent,
        swipeToClose: false,
        // cssClass: this.platform == 'desktop' ? 'modalDesktopFull' : '',
        cssClass: 'modalDesktopFull',
        //  presentingElement: this.routerOutlet.nativeEl,
        mode: "ios",

        // Get the top-most ion-modal

      });


      await this.Modal_Swich.present();

      const { data } = await this.Modal_Swich.onWillDismiss();

      if (data) {
        if (data.dismissed == "finish") {

        }
      }
      if (this.platform != 'desktop') {
        // this.desktopMenuTogglle('open');
        this.menuTogglle('close');
      }
      // else {
      // }
      return this.Modal_Swich;

    }

  }













  setNotificationData(USERid, id, pushToken) {
    this.afDb.object('/Users/' + USERid + '/notification/').update(
      {
        "type": "setNotificationData",
        "USERid": USERid,
        "pushId": id,
        "pushToken": pushToken
      }
    );

    //  alert('setNotificationData');
    //  alert('mitid' + sendPar.mitid);
    //  alert('id' + sendPar.pushId);
    //  alert('pushToken' + sendPar.pushToken);
    // this.http.post(this.url,sendPar).subscribe(respone=>{
    //   console.log('respone: ',respone.json());
    //  // alert('setNotificationData');
    // }); 
  }


  isNumber(isANumber) {
    return /^\d+$/.test(isANumber);
  }




  wallet_loaded_before = false;




  wallet_subscribe;
  start_listen_wallet_settings() {
    this.wallet_subscribe = this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Wallet').valueChanges().subscribe(x => {
      if (x) {
        this.shopData.Wallet = x;
        console.log("x", x);

        if (this.shopData.Wallet.documents) {



        }
      }



    });
  }


  stop_listen_wallet_settings() {
    if (this.wallet_subscribe) {

      this.wallet_subscribe.unsubscribe();
    }
  }








  checkEventAvailable(cb) {
    // let event_available;
    let event = this.eventForBuis.exportEventUser();
    console.log('-------------------CheckEventAvailable ', event);
    event = this.cryptoService.Encrypt(event);

    this.http.post(this.serverDev + '/CheckEventAvailable', event).subscribe(val => {
      console.log('CheckEventAvailable ', val);
      // cb(val);
      let event_available: any = val;
      console.log("go next, event_available:", event_available);
      if (event_available.available) {
        // return true;
        cb(true);
        // this.orderSelectedAppoitment();
      } else {
        // this.presentAlertMeet();
        if (event_available.event) {
          this.eventForBuis.CatchPeople = event_available.event.CatchPeople;
          this.eventForBuis.totalPeople = event_available.event.totalPeople;
        }
        console.log("problemmmmmm");
        // return false;
        cb(false);
      }
    });

    // this.getEvent(this.eventForBuis.exportEventUser()).once('value').then(res => {
    //   let event = res.val();
    //   if (event.CatchPeople < event.totalPeople) {
    //     console.log("go next");
    //     // return true;
    //     cb(true);
    //     // this.orderSelectedAppoitment();
    //   } else {
    //     // this.changeViewHouers(this.calendar.currentDate);
    //     // this.presentAlertMeet();
    //     this.eventForBuis.CatchPeople = event.CatchPeople;
    //     this.eventForBuis.totalPeople = event.totalPeople;
    //     console.log("problemmmmmm");
    //     // return false;
    //     cb(false);
    //   }
    // });
  }


  checkMeetAvailable(start, duration, meetID, meet, cb) {

    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let data = {
      data: {
        BarberID: this.shopData.Setting.BarberID,
        userid: this.us.user.key,
        date: new Date(this.calendar.currentDate),
        date_fix: this.current_pick_day ? this.current_pick_day : this.formatDate(start),
        start: start,
        duration: duration,
        meetID: meetID,
        meet: meet
      },
      type: 'checkMeetAvailable'
    };
    console.log(data);

    let encrypted = this.cryptoService.Encrypt(data);
    // this.allMeets = [];
    // Get free hours
    this.http.post(this.serverDev + '/meetAPI', encrypted, { headers: reqHeader }).subscribe(res => {
      console.log('checkMeetAvailable: ', res);
      // available = res;
      let respone: any = res;
      cb(respone.status);
    });


    // console.log(start, duration, meetID);
    // let obj = {
    //   BarberID: this.shopData.Setting.BarberID,
    //   date: new Date(this.calendar.currentDate),
    //   date_fix: this.current_pick_day,
    //   start: start,
    //   duration: duration,
    //   meetID: meetID
    // }
    // obj = this.cryptoService.Encrypt(obj);
    // let available;
    // this.http.post(this.serverDev + '/CheckAppointmentAvailable', obj).subscribe(res => {
    //   console.log('CheckAppointmentAvailable: ', res);
    //   // available = res;
    //   let respone: any = res;
    //   cb(respone.status);
    // });
    //  cb(available);
  }



  updateBarberMeet() {
    let meet = this.meetForBuis.exportMeetAdmin();
    meet = this.cryptoService.Encrypt(meet);
    this.http.post(this.serverDev + '/AppointmentUpdateAdmin', meet).subscribe(val => {
      console.log('AppointmentUpdateAdmin ', val);
    });
  }

  updateUserMeet() {
    let meet = this.meetForBuis.exportMeetUser();
    meet = this.cryptoService.Encrypt(meet);
    this.http.post(this.serverDev + '/AppointmentUpdateUser', meet).subscribe(val => {
      console.log('AppointmentUpdateUser ', val);
    });
  }


  deleteBarberMeet() {
    let meet = this.meetForBuis.exportMeetAdmin();
    meet = this.cryptoService.Encrypt(meet);
    this.http.post(this.serverDev + '/AppointmentDeleteAdmin', meet).subscribe(val => {
      console.log('AppointmentDeleteAdmin ', val);
    });
  }

  deleteUserMeet(user_appointment_delete) {
    user_appointment_delete = this.cryptoService.Encrypt(user_appointment_delete);
    this.http.post(this.serverDev + '/AppointmentDeleteUser', user_appointment_delete).subscribe(val => {
      console.log('AppointmentDeleteUser ', val);
    });
  }

  downloadApp() {
    window.open(this.download_url);
  }


  userToken;


  updateMarketing(key) {
    this.afDb.database.ref('/Users/' + key).update({
      marketing: new Date().getTime()
    });
  }





  async something_wrong_alert(data) {
    this.logWithDataJSON(this.us.user, 'something_wrong_alert', {}, JSON.stringify(data));
    const alert = await this.alertController.create({
      header: 'שגיאה',
      message: 'נראה שמשהו השתבש, נסו שנית',
      buttons: [
        {
          text: 'אישור',
          handler: () => {
            console.log('Cancel Cancel: Cancel');
          }
        }
      ]
    });

    await alert.present();
  }

  meet_api(type, data, cb?) {
    console.log(data);

    // this.LoadingService.present();
    if (firebase.auth().currentUser) {
      let split = type.split('_');
      let who = split[split.length - 1];

      firebase.auth().currentUser.getIdToken().then(token => {
        this.userToken = token;

        var reqHeader = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.userToken
          // 'Authorization': 'Bearer ' + expired_token
        });

        let data_to_enc = {
          type: type,
          data: data.meet,
          BarberID: this.shopData.Setting.BarberID,
          userid: (who == 'user' || !data.userid) ? this.us.user.key : data.userid,
          userData: data.userData ? data.userData : null,
          ShopSetting: data.ShopSetting ? data.ShopSetting : this.shopData.Setting,// null,
          notif_type: data.notif_type ? data.notif_type : null,
          timezone_offset: new Date().getTimezoneOffset(),
          app_version: this.us.user.app_version ? this.us.user.app_version : null,
          function: data.function
        }

        console.log(data_to_enc);

        if (data.meet.user_meet && !data.meet.user_meet.Date) {
          this.something_wrong_alert(data_to_enc);
          return;
        } else if (data.meet.admin_meet && !data.meet.admin_meet.Date) {
          this.something_wrong_alert(data_to_enc);
          return;
        } else if (data.meet.old_meet && !data.meet.old_meet.Date) {
          this.something_wrong_alert(data_to_enc);
          return;
        } else if (data.meet.timestamp && !data.meet.Date) {
          this.something_wrong_alert(data_to_enc);
          return;
        }


        let encrypted = this.cryptoService.Encrypt(data_to_enc);
        this.http.post(this.serverDev + '/meetAPI', encrypted, { headers: reqHeader }).subscribe((response: any) => {
          // this.LoadingService.dismiss();
          console.log('response', response);
          if (response.status) {
            if (cb) cb(response);
          } else {
            this.showTostDanger('אירעה שגיאה');
            this.LoadingService.dismiss();
            if (response.msg) console.log(response.msg);
          }
        }, err => {
          console.log(err);
          this.showTostDanger('אירעה שגיאה');
          this.LoadingService.dismiss();
        })// end of http post
      });// end of token

    } else {
      if (cb) cb({ status: false, err: 'no-auth' });
      this.showTostDanger('אירעה שגיאה');
    }

  }


  send_sms(data) {
    if (firebase.auth().currentUser) {

      firebase.auth().currentUser.getIdToken().then(token => {
        this.userToken = token;

        var reqHeader = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.userToken
        });

        let data_to_enc = {
          type: 'sms_exclusive',
          to_phone: data.phone,
          message: data.msg
        }

        console.log(data_to_enc);

        let encrypted = this.cryptoService.Encrypt(data_to_enc);
        this.http.post(this.serverDev + '/send_sms', encrypted, { headers: reqHeader }).subscribe((response: any) => {
          // this.LoadingService.dismiss();
          console.log('response', response);
          if (response.status) {
            // if (cb) cb(response);
            this.showToast('ההודעה נשלחה בהצלחה!');
          } else {
            this.showTostDanger('אירעה שגיאה');
            if (response.msg) console.log(response.msg);
          }
        }, err => {
          console.log(err);
          this.showTostDanger('אירעה שגיאה');
        })// end of http post
      });// end of token

    }

  }


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  add_googleID_barber_meet(BarberID, key, meetings_date, googleId, createdAt, val) {
    console.log('add_googleID_barber_meet', '/BarberShop/' + BarberID + '/Meetings/' + this.meetForBuis.convertDateToYYYY(meetings_date) + '/' + key + '/googleId', googleId);

    this.afDb.object('/BarberShop/' + BarberID + '/Meetings/' + this.meetForBuis.convertDateToYYYY(meetings_date) + '/' + key + '/').update({
      googleId: googleId,
      createdAt: createdAt,
      calSynced: val
    });

    // // console.log('add_googleID_barber_meet', '/BarberShop/' + BarberID + '/Meetings/' + meetings_date + '/' + key + '/googleId', googleId);
    // this.afDb.object('/BarberShop/' + BarberID + '/Meetings/' + meetings_date + '/' + key + '/googleId').set(googleId);

    // // console.log('add_googleID_barber_meet', '/BarberShop/' + BarberID + '/Meetings/' + meetings_date + '/' + key + '/createdAt', createdAt);
    // this.afDb.object('/BarberShop/' + BarberID + '/Meetings/' + meetings_date + '/' + key + '/createdAt').set(createdAt);

    // console.log('add_googleID_barber_meet', '/BarberShop/' + BarberID + '/Meetings/' + meetings_date + '/' + key + '/calSynced', false);
    // this.afDb.object('/BarberShop/' + BarberID + '/Meetings/' + meetings_date + '/' + key + '/calSynced').set(false);
  }

  getShopMeetings(cb) {
    let date = this.formatDate(new Date());
    console.log(date);

    let startDate = this.meetForBuis.convertDateToYYYY(date);
    console.log(startDate);

    this.afDb.database.ref('/BarberShop/' + this.shopData.Setting.BarberID + '/Meetings/').orderByKey().startAt(startDate).once('value').then(res => {
      console.log(res.val());

      cb(res.val())
    });
  }

  google_meet_import() {
    //////////////////////////////////set GoogleCalendar/googleCalImport val and add +1 , set GoogleCalendar/googleLastImport to cur date timestamp
    this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/GoogleCalendar/googleLastImport').set(new Date().getTime());

    let val = 0;
    this.afDb.database.ref('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/GoogleCalendar/googleCalImport').once('value').then(res => {
      console.log(res.val());
      if (res.val()) {
        val = res.val();
      }
      this.afDb.object('/BarberShop/' + this.shopData.Setting.BarberID + '/Setting/GoogleCalendar/googleCalImport').set(val + 1);
    })

  }

  add_googld_db(googleId) {
    console.log(' add to Meet_GoogleId', googleId);
    this.firestore.collection('Meet_GoogleId').doc(this.shopData.BarberId).update({
      googleId: firebase.firestore.FieldValue.arrayUnion(googleId)
    });
  }

  get_googleId(cb) {
    this.firestore.collection('Meet_GoogleId').doc(this.shopData.BarberId).get().subscribe(val => {
      let value = val.data();
      console.log('get Meet_GoogleId', value);
      if (!value) {

        this.firestore.collection('Meet_GoogleId').doc(this.shopData.BarberId).set({
          googleId: []
        }).then(res => {
          cb(false);
          console.log('creating collection Meet_GoogleId', res);
        });

      } else {
        let doc = value['googleId'];
        console.log('Document data:', doc);
        // if (doc.length < 1 || (!doc)) {
        if (!doc) {

          this.firestore.collection('Meet_GoogleId').doc(this.shopData.BarberId).update({
            googleId: []
          }).then(res => {
            cb(false);
            console.log('update collection Meet_GoogleId', res);
          });
        } else {
          cb(doc);
        }
      }
    })
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  getShopBizBarber(bizID: string) {////////////////////////////
    console.log('///////////////////////////////getShopBizBarber');

    return this.afDb.database.ref('/BarberShop/' + bizID + '/Setting/').once('value');
    // return this.afDb.object('/BarberShop/' + bizID + '/Setting/').valueChanges();
  }


  getShopBarber() {
    return this.afDb.database.ref('/BarberShop/').once('value');
    // return this.afDb.object('/BarberShop/').valueChanges();
  }

  remove_address(address) {
    this.afDb.database
      .ref('/Users/' + this.us.user.key + '/index_address_list/' + address.key).remove();
  }

  add_address(address) {
    return this.afDb.database
      .ref('/Users/' + this.us.user.key + '/index_address_list').push(address);
  }

  // update_address(key, bool) {
  //   this.afDb.database.ref('/Users/' + this.us.user.key + '/index_address_list/' + key)
  //     .update({ choosen: bool });
  // }

  createNewUser(key, user) {
    return this.afDb.object('/Users/' + key).update(user);
  }

  checkIndexPhone(user) {
    let registrationdate = new Date().toISOString();
    let update_obj: any = {
      registrationdate: registrationdate
    };
    let phone = user.phone.replaceAll('+972', '0');
    let uId = user.key;
    this.afDb.database.ref('/indexPhone/' + phone)
      .once('value').then(res => {
        let result = res.val();
        if (result) {
          console.log(res.key);

          if (result.Barbers) {
            let barber_array = Object.keys(result.Barbers);
            update_obj.bizArray = barber_array;
          }

          if (result.meetings) {
            update_obj.meetings = result.meetings;
          }

        }

        this.afDb.object('/Users/' + uId).update(update_obj);
      })
  }




  ManageUsers(data, cb) {
    console.log('ManageUsers:', data);

    return this._http.post('' + this.serverDev + '/ManageUsers', data).subscribe(res => {
      console.log('ManageUsers resssssssssss', res);

      switch (data.type) {
        case 'close buis'://saving the closed buis data in 'Delete_Buis_User' collection
          if (this.us.user.key == this.shopData.Setting.userManager) {
            this.firestore.collection('Delete_Buis_User').doc(this.shopData.Setting.userManager).set({
              BarberID: data.BarberID,
              status: data.type,
              phone: this.shopData.Setting.phone
            }).then(res => {
              cb('buis closed');
              console.log('update collection Delete_Buis_User', res);
            });
          }
          break;

        case 'delete user'://saving the deleted user data in 'Delete_Buis_User' collection
          let obj = {
            userManager: data.UserId,
            status: data.type,
            phone: this.us.user.phone
          }
          if (this.us.user.shopOwnerKey) {
            if (this.us.user.shopOwnerKey.id) {
              obj['BarberID'] = this.us.user.shopOwnerKey.id;
            }
          }
          this.firestore.collection('Delete_Buis_User').doc(data.UserId).set(obj).then(res => {
            cb('deleted user');
            console.log('update collection Delete_Buis_User', res);
          });
          break;
      }
    });


  }

}// end of ts







function isA(ad) {
  return ad.local == 2;
}

function convertDateToYYYY(inputFormat) {
  //   console.log('inputFormat',inputFormat)
  if (inputFormat) {
    let t = inputFormat.split("-");
    return t[2].toString() + "-" + t[1].toString() + "-" + t[0].toString();
  }

}
