import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(    private _http: Http    ) { }


  sendLog(adress,sendPar){
  if(adress){

    this._http.post(adress,sendPar).subscribe(respone=>{
      //console.log('respone: ',respone);
    }); 
  }
  }

  

}
