import { userService } from './user.service';
import { userD } from './../app/UserDClass';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs-compat';
import { AngularFireDatabase, } from '@angular/fire/database';

// import 'rxjs/add/operator/map';
// import { map } from 'rxjs/operators';
@Injectable()

export class meetService {
    shopId;
    hStart;
    hEnd;
    timeStep = 5;
    newMeetTime;
    meetType;
    DateMeet;
    nWday;
    workDayTime = [];
    dayWork;
    TotalendTimeHour:any=24;
    TotalendTimeMinuts:any="00";

    startWork;
    meetToChange;
    endWork;
    url = "https://webcut-2001a.firebaseio.com/";
    public houers;

    constructor(
        private afDb: AngularFireDatabase,
        private _http: Http,
        public userService: userService,



    ) {
    }



    setMeetType(type) {

        console.log("setMeetType",type)
        this.meetType = type;

    }


    sendNoti(a, b, c) {
        console.log('abc');

        //  console.log(a,b,c);

        b.event = c;
        console.log(a, b, c);
        // if line need approvement
        let what: any;

        // console.log('this.meetType',this.meetType);


        if (this.meetType.approve) {
            what = 'ClientOrderNeedApprove';
        } else {
            what = 'ClientOrder';
        }
        // var sendPar = {
        //     notification: '',
        //     what: 'newLine',
        //     meet: b
        //   }

        // let self = this;

        // console.log('/BarberShop/' + a.BarberID + '/Setting/userManager');
        var getUser2 = this.afDb.object('/BarberShop/' + a.BarberID + '/Setting/userManager').valueChanges().subscribe((result: any) => {
              console.log('......result',result);
            b['newFname'] = a.fname;

            b.event['meetTypeData'] = { 'name': b.meetTypeName }
            // b.event['meetTypeData']['name'] = b.meetTypeName;
            b.event['meetDate'] = b.event.meetTypeData.meetDateFormat;

            var getUser = this.afDb.object('/Users/' + result).valueChanges().subscribe((result2: any) => {
                //     console.log('......result2',result2);

                var sendPar = {
                    notification: result2,
                    what: what,
                    meet: b
                }
                //     console.log('sendPar',sendPar);
                this._http.post('https://webc.co.il/pointnot/', sendPar).subscribe(respone => {
                    //         console.log('respone: ',respone);
                });
                getUser.unsubscribe();

            });
            getUser2.unsubscribe();


            // this.shopS.getUserFromUsers(result).valueChanges().subscribe( (result:any) => {


            // }); 


        });
    }

    // removeReminder(newID){
    //     this.afDb.object('/Reminders/' + newID).remove();
    //   }

    alertNotiToBarber(meetData, what, admin) {
        // console.log('admin',admin);
        // console.log('meetData',meetData);
        // console.log('this.userService.fName',this.userService.user.fname);
        meetData.event = {
            'newFname': this.userService.user.fname,
            'startTimeView': meetData.startTimeView,
            'meetTypeData': {
                'name': meetData.meetTypeName
            },
            'meetDate': meetData.meetDate
        }

        // meetData.event['meetTypeData'] = { 'name': meetData.meetTypeName}  
        // b.event['meetTypeData']['name'] = b.meetTypeName;

        // meetData.event['meetDate'] = meetData.event.meetTypeData.meetDateFormat;
        //    console.log('meetData',meetData);
      
        console.log('/BarberShop/' + meetData.BarberID)
        var getUser2 = this.afDb.object('/BarberShop/' + meetData.BarberID +'/Setting/userManager').valueChanges().subscribe((result: any) => {
            // console.log('result',result);

            var getUser = this.afDb.object('/Users/' + result + '/notification').valueChanges().subscribe((result2: any) => {

                if (!admin) {



                    //   console.log('......result2',result2);
                    var sendPar = {
                        notification: {notification:result2},
                        what: what,
                        meet: meetData,
                        BizName: meetData.barberName
                    }
                    //     console.log('sendPar',sendPar);
                    this._http.post('https://webc.co.il/pointnot/', sendPar).subscribe(respone => {
                        //         console.log('respone: ',respone);
                    });
                }
                getUser.unsubscribe();

            });
            getUser2.unsubscribe();


            // this.shopS.getUserFromUsers(result).valueChanges().subscribe( (result:any) => {


            // }); 


        });
    }


    // removeTafus(meetToRemove, admin) {
    //     console.log("meetToRemove", meetToRemove, admin);
    //     if (!admin) {
    //         this.alertNotiToBarber(meetToRemove, 'ClientDeleteLine', admin);
    //     }
    //     this.removeReminder(meetToRemove.key);
    //     return 1;


    // }

    // מחסיר את התורים מהתורים הריקים 
    getEmptyMeets(meets) {


        var key;
        // console.log("meets : ",Object.keys(meets).length)
        for (var i = 0; i < Object.keys(meets).length; i++) {
            key = meets[i].$key
            //   console.log("key : ", key)
            if (key != undefined) {
                //console.log(key)
                //Flow meet
                if (!meets[i].flow) {
                    var newMeet = key.split(":", 2).map(Number);
                    var newMeet2 = key.split(":", 2).map(Number);

                    var meetTime = meets[i].meetTime;//קדימה זמן התור הרצוי
                    meetTime = meetTime / 5;

                    this.newMeetTime = this.meetType.meetTime;// זמן התור הרצוי אחורה
                    this.newMeetTime = this.newMeetTime / 5;

                    // console.log("newMeetTime: ", this.newMeetTime)
                    //  
                    //בדיקת של תורים שקיימים

                    for (var m = 0; m < meetTime; m++) {

                        if ((this.nWday[newMeet[0]][newMeet[1]]) != undefined) {
                            this.nWday[newMeet[0]][newMeet[1]] = 0;


                            if (newMeet[1] == 55) {
                                newMeet[1] = 0;
                                newMeet[0]++;
                            }
                            else {
                                newMeet[1] += 5;
                            }
                        }

                    }

                    //  console.log("split ",newMeet2)

                    for (var d = 0; d < (this.newMeetTime - 1); d++) {
                        // console.log(newMeet2[0], newMeet2[1])
                        if (newMeet2[1] == 0) {
                            newMeet2[1] = 55;
                            newMeet2[0] -= 1;
                        } else {
                            newMeet2[1] -= 5;
                        }
                        if (this.nWday[newMeet2[0]]) {
                            if ((this.nWday[newMeet2[0]][newMeet2[1]] != undefined) && (this.nWday[newMeet2[0]][newMeet2[1]] != 0)) {
                                this.nWday[newMeet2[0]][newMeet2[1]] = 2;
                            }
                        } else {
                            console.log("no")
                        }
                        // console.log(this.nWday[newMeet2[0]][newMeet2[1]])
                        //     console.log("now cheak : ",this.nWday[newMeet2[0]][newMeet2[1]]);
                        //  if ((this.nWday[newMeet2[0]][newMeet2[1]] != undefined) && (this.nWday[newMeet2[0]][newMeet2[1]] != 0)) {
                        ///       this.nWday[newMeet2[0]][newMeet2[1]] = 2;
                        // }

                    }


                    //console.log( this.workDayTime);

                    //           console.log(this.nWday);
                }

            }
        }

    }












    getCompleteMeets() {
        var compMeet = [];
        var houer = {};
        var newarray = [];
        this.hStart

        var minEnd = 60;
        var minStat = this.hStart[1];
        var a;
        for (var i = this.hStart[0]; i < (this.hEnd[0] + 1); i++) {

            houer["houer"] = i;


            if (i == this.hEnd[0]) {
                minEnd = this.hEnd[1];
            }
            a = 0

            for (var m = minStat; m < minEnd; m += 5) {

                if (this.nWday[i][m] == 1) {

                    newarray[a] = m.toString();
                    if (m == 5) {
                        newarray[a] = "05"
                    }
                    if (m == 0) {
                        newarray[a] = "00"
                    }
                    a++;
                } else {
                    //  console.log(i, " : ", m)
                }


            }
            houer["min"] = newarray;
            newarray = [];
            minStat = 0;
            compMeet.push(houer);
            houer = {};
        }
        return compMeet;
    }

    getDateTafus(newDate) {
        this.DateMeet = newDate;

        // this._http.get(this.url + '/BarberShop/' + this.shopId + "/Meetings/" + this.DateMeet + ".json")
        // console.log('/BarberShop/' + this.shopId + "/Meetings/" + this.DateMeet )
        // מחזיר את כל התורים שיש באותו יום

        console.log("DateMeet",this.DateMeet,'/BarberShop/' + this.shopId + "/Meetings/" + this.DateMeet)
        return this.afDb.list('/BarberShop/' + this.shopId + "/Meetings/" + this.DateMeet)
        //  .map(response => response.json())

    }
    setShopId(x) {
        this.shopId = x;


    }
    // מחזיר את השעות עבודה של העסק
    getShopScez() {


        // return   this.afDb.list(this.url + '/BarberShop/');

        return this.afDb.list('/BarberShop/' + this.shopId + '/WorkDays/')
        // .map(response => response.json())

    }

    //יוצר מערך שלש עות עבודה לי הלוז
    arrayOfHouers() {
    
        this.hStart = this.startWork.split(":", 2).map(Number);
        this.hEnd = this.endWork.split(":", 2).map(Number);
        var minEnd = 60;
        var minStat = this.hStart[1];

        this.TotalendTimeHour=this.hEnd[0];
        this.TotalendTimeMinuts=this.hEnd[1];

        for (var i = this.hStart[0]; i < (this.hEnd[0] + 1); i++) {

            this.workDayTime[i] = [];

            if (i == this.hEnd[0]) {
                minEnd = this.hEnd[1];
            }

            for (var m = minStat; m < minEnd; m += this.timeStep) {

                this.workDayTime[i][m] = 1;

            }
            minStat = 0;
        }
        // console.log("this.workDayTime",this.workDayTime);
        this.nWday = this.workDayTime;

    }
    cheakIfTimeExist(h, m) {
        return this.afDb.object('/BarberShop/' + this.shopId + "/Meetings/" + this.DateMeet + "/" + h + ":" + m)
        //    .map(response => response.json())
    }


    cleanService() {
        this.hStart = 0;
        this.hEnd = 0;
        this.workDayTime = [];
        this.dayWork = null;
        this.startWork = null;
        this.endWork = null;
        this.meetToChange = null;
    }
}