import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';

@Component({
  selector: 'app-price-plans',
  templateUrl: './price-plans.page.html',
  styleUrls: ['./price-plans.page.scss'],
})
export class PricePlansPage implements OnInit {

  constructor(private modalController:ModalController,
    public shopS:ShopService) { }

  ngOnInit() {
    console.log('dsadas')
  }


  sendSms(){
    let data = [
    //   {
    //   "sms_message_name":"sms_message_name",
    //   "sms_message":"sms_message",
    //   "phone":"0547895027",
    //   "from_phone":"0549438404",
    //   "key": "aldaslkdj"
    // },
    { 
      "sms_message_name":"בידקה",
      "sms_message":"sms_message",
      "phone":"0527420606",
      "from_phone":"0549438404",
      "key": "aldaslkdj"
    }
  
  
  ]
    

  let date = {
    meetDate: "15-10-2020",
    startTimeView: "22:10"
  };
  

  console.log(date)
  let split = date.meetDate.split('-');
  let splitH = date.startTimeView.split(':');

let makeDate = new Date(split[2]+','+split[1]+','+split[0]);
makeDate.setHours(parseInt(splitH[0]));
makeDate.setMinutes(parseInt(splitH[1]));

console.log(makeDate)

if(makeDate < new Date()){
alert('small');
}else{
  alert('big')
}

  //  this.shopS.smsS.sendSMS(data);
  }










  dismissModal(){
    this.modalController.dismiss('data');
  }

}
