import { Component, OnInit } from '@angular/core';
import { PaymentService } from "../payment-service/payment.service";

@Component({
  selector: 'app-paymeny-history',
  templateUrl: './paymeny-history.page.html',
  styleUrls: ['./paymeny-history.page.scss'],
})
export class PaymenyHistoryPage implements OnInit {

  constructor(
    public PaymentService:PaymentService,
  ) { }

  ngOnInit() {
    console.log('history init')
    this.PaymentService.getUserHistorys();
  }


  ionViewWillEnter(){
    console.log('history ionViewWillEnter')

  }

}
