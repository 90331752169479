import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PaymenyHistoryPageRoutingModule } from './paymeny-history-routing.module';

import { PaymenyHistoryPage } from './paymeny-history.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PaymenyHistoryPageRoutingModule
  ],
  declarations: [PaymenyHistoryPage],
  entryComponents: [PaymenyHistoryPage]
})
export class PaymenyHistoryPageModule {}
