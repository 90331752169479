import { Component, OnInit } from '@angular/core';
import { ShopService } from "../../ShopService/shop.service";
import { ConfigService } from "../config.service";
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-version-update',
  templateUrl: './version-update.component.html',
  styleUrls: ['./version-update.component.scss'],
})
export class VersionUpdateComponent implements OnInit {

  constructor(
    public shopS: ShopService,
    public configS:ConfigService,
    private toastController: ToastController

  ) {
    

   }
  showUpdate = false;
  ngOnInit() {
    // while (this.shopS.configDATA.version.min > 0) {
    //   console.log('configDATA.version.min',this.shopS.configDATA.version.min);
    // }
    // while (this.shopS.configDATA.version.min < 0) {
    //   console.log('configDATA.version.min2',this.shopS.configDATA.version.min);
    // }
//  console.log(this.configS.currentVersion);  
    setTimeout(() => {
   //   console.log('minVersion:',this.shopS.configDATA.version.min);
   //   console.log('this.configS.currentVersion:',this.configS.currentVersion);

      if(this.shopS.configDATA.version.min > this.configS.currentVersion){
        this.showUpdate = true;
      }else{
    //    console.log('this.shopS.configDATA.version.recommended:',this.shopS.configDATA.version.recommended);

        if(this.shopS.configDATA.version.recommended > this.configS.currentVersion){
       //   console.log('this.configS.currentVersion:',this.configS.currentVersion);

          this.showToast();
        }
       
      }

    }, 7000);
  }

  linkToStore:any = {
    android: 'https://play.google.com/store/apps/details?id=com.webc.point',
    ios: 'https://apps.apple.com/il/app/point-%D7%A4%D7%95%D7%99%D7%A0%D7%98/id1485157555'
  }

  updateApp(){

    //this.linkToStore[this.shopS.platform];

  
    console.log('dsadasda',this.linkToStore[this.shopS.platform]);
    window.location.href = this.linkToStore[this.shopS.platform];
  }




 

  toastss:any;
  async showToast() {

    this.toastss = await this.toastController.create({
      message: this.shopS.configDATA.version.recommendedText,
      position: 'top',
      color:'dark',
      cssClass: 'toastCustomWebc',
      duration: 5000,
      mode: 'ios',
      buttons: [
        {
          side: 'end',
          text: 'לעדכון לחצו כאן',
          handler: () => {
            console.log('Favorite clicked');
            window.location.href = this.linkToStore[this.shopS.platform];
          }
        }
      ]
    });
    return await this.toastss.present();
    // toast.present();
  }

}
