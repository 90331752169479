import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
// import { File } from '@ionic-native/file/ngx';
import { ModalController } from '@ionic/angular';
import { ToastService } from 'src/providers/toast-service';
import { LoadingService } from 'src/providers/loading.service';
import { ContactListService } from 'src/app/contact-list/contact-list-service/contact-list.service';
import { ContactListComponent } from 'src/app/contact-list/contact-list.component';
import { AlertController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-social-sharing',
  templateUrl: './social-sharing.page.html',
  styleUrls: ['./social-sharing.page.scss'],
})
export class SocialSharingPage implements OnInit {

  constructor(
    public shopS: ShopService,
    private socialSharing: SocialSharing,
    //  private file: File,
    public contactsS: ContactListService,
    private toast: ToastService,
    private alertController: AlertController,
    public LoadingService: LoadingService,
    private ModalController: ModalController
  ) { }

  ngOnInit() {
    this.shopS.log(this.shopS.us.key, 'open_social_share');

  }
  open_contacts() {
    this.shopS.log(this.shopS.us.key, 'open_contacts');

    this.contactsS.loadContacts(cb => {
      console.log(cb);
      this.LoadingService.dismiss();

      if (cb) {
        localStorage.setItem('point_contact_list_premission', 'true')
        this.newModal();
      }
    })
  }
  async newModal() {

    const modal = await this.ModalController.create({
      component: ContactListComponent,
      cssClass: 'modalLookLikeNativ',
      mode:'ios',
    });
    return await modal.present();
  }

  async goTOaddContacts() {

    let point_contact_list_premission = localStorage.getItem('point_contact_list_premission');
    if (point_contact_list_premission == 'true') {
      console.log('point_contact_list_premission', point_contact_list_premission)
      this.LoadingService.presentforever_with_text('טוען אנשי קשר');
      this.open_contacts();
    } else {
      console.log('point_contact_list_premission', point_contact_list_premission)


      var textT = `<img class='imgalert2 contactimg' src="../../assets/contactlist.png" >`;
      textT += 'לאחר הודעה זו תופיע בקשת גישה לאנשי הקשר במכשיר שלך. חשוב לאשר את הבקשה';
      const alert = await this.alertController.create({
        header: 'ייבוא מאנשי קשר',
        message: textT,
        buttons: [
          {
            text: 'מאוחר יותר',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Cancel Cancel: Cancel');
              return false;
            }
          }, {
            text: 'מתן הרשאות',
            handler: () => {

              console.log('Okay: Okay');
              //confirm order 
              this.LoadingService.presentforever_with_text('טוען אנשי קשר');
              this.open_contacts();

            }
          }
        ]
      });
      await alert.present();
    }
    //  this.LoadingService.presentLoadingContact()
  }

  toggleModal(event) {
    event.stopPropagation();
    this.ModalController.dismiss()
    console.log('toggleModal=======this.shopS.SharemodalFLAG', this.shopS.SharemodalFLAG);
  }

  text = '';
  url = 'https://point-app.net/download/';

  async shareInstagram() {
    // Either URL or Image
    this.text = `שלום לכם לקוחות יקרים!
    התחלתי לעבוד עם שירות לקביעת תורים.
    מעכשיו תוכלו להזמין אצלי תור בפשטות וקלות, הרשמו והזינו את קוד העסק `+ this.shopS.shopData.Setting.code + ' אתם מוזמנים להכנס ללינק מטה ולהוריד את האפליקציה:' + this.url;

    this.socialSharing.shareViaInstagram(this.text, null).then(() => {
      // Success
    }).catch((e) => {
      // Error!
    });
  }

  copy_text() {
    let text = 'שלום לכם לקוחות יקרים! התחלתי לעבוד עם שירות לקביעת תורים, מעכשיו תוכלו להזמין אצלי תורים בפשטות ובקלות. הורידו את האפליקציה בלינק למטה, הירשמו וחפשו לפי קוד העסק: ' + this.shopS.shopData.Setting.code + ' ' + this.url;
    navigator.clipboard.writeText(text);
    this.toast.showToast2('טקסט הועתק בהצלחה!')
  }

  async share() {
    let line_break = this.shopS.environment.platform == 'web' ? '%0a' : '\n';
    let text = 'שלום לכם לקוחות יקרים!' + line_break + 'התחלתי לעבוד עם שירות לקביעת תורים, מעכשיו תוכלו להזמין אצלי תורים בפשטות ובקלות.' + line_break + 'הורידו את האפליקציה בלינק למטה, הירשמו וחפשו לפי קוד העסק: ' + this.shopS.shopData.Setting.code + line_break + this.url;
    this.shopS.share(text);
    // this.socialSharing.share
    // (this.text, null,null,null).then(() => {
    //   // Success
    // }).catch((e) => {
    //   // Error!
    //   console.log('err',e)
    // });
  }
  async shareProfile() {
    let line_break = this.shopS.environment.platform == 'web' ? '%0a' : '\n';
    let url = '';
    url = 'https://app.point-app.net/b/' + this.shopS.shopData.Setting.code;
    let text = 'שלום לכם לקוחות יקרים!' + line_break + 'התחלתי לעבוד עם שירות לקביעת תורים, מעכשיו תוכלו להזמין אצלי תורים בפשטות ובקלות.' + line_break + 'אתם מוזמנים להיכנס ללינק מטה ולהתחיל להזמין!' + line_break + url;
    this.shopS.share(text);
    // if (this.shopS.environment.platform == 'app') {
    //   let text = `שלום לכם לקוחות יקרים!
    //   התחלתי לעבוד עם שירות לקביעת תורים.
    //   מעכשיו תוכלו להזמין אצלי תור בפשטות וקלות `+ this.shopS.shopData.Setting.code + ' אתם מוזמנים להכנס ללינק מטה ולהוריד את האפליקציה:' + url;
    //   this.socialSharing.share(text, null, null, null).then(() => {
    //     // Success
    //   }).catch((e) => {
    //     // Error!
    //     console.log('err', e)
    //   });
    // } else {
    //   navigator.clipboard.writeText(url);
    //   this.toast.showToast2('קישור לפרופיל הועתק בהצלחה!')
    // }
  }


  async shareSMS() {
    // Either URL or Image
    this.text = `שלום לכם לקוחות יקרים!
    התחלתי לעבוד עם שירות לקביעת תורים.
    מעכשיו תוכלו להזמין אצלי תור בפשטות וקלות, הרשמו והזינו את קוד העסק `+ this.shopS.shopData.Setting.code + ' אתם מוזמנים להכנס ללינק מטה ולהוריד את האפליקציה:' + this.url;
    console.log('this.text', this.text)
    console.log('this.url', this.url)
    console.log('this.shopS.shopData.Setting.code', this.shopS.shopData.Setting.code)
    this.socialSharing.shareViaSMS(this.text, null).then(() => {
      // Success
    }).catch((e) => {
      // Error!
    });
  }


  async shareTwitter() {
    this.text = `שלום לכם לקוחות יקרים!
    התחלתי לעבוד עם שירות לקביעת תורים.
    מעכשיו תוכלו להזמין אצלי תור בפשטות וקלות, הרשמו והזינו את קוד העסק `+ this.shopS.shopData.Setting.code + ' אתם מוזמנים להכנס ללינק מטה ולהוריד את האפליקציה:';

    // Either URL or Image
    this.socialSharing.shareViaTwitter(null, null, this.url).then(() => {
      // Success
    }).catch((e) => {
      // Error!
    });
  }

  async shareWhatsApp() {
    this.text = `שלום לכם לקוחות יקרים!
    התחלתי לעבוד עם שירות לקביעת תורים.
    מעכשיו תוכלו להזמין אצלי תור בפשטות וקלות, הרשמו והזינו את קוד העסק `+ this.shopS.shopData.Setting.code + ' אתם מוזמנים להכנס ללינק מטה ולהוריד את האפליקציה:';

    // Text + Image or URL works

    if (this.shopS.environment.platform == 'web') {
      window.open("https://api.whatsapp.com/send?text=" + this.text + " " + this.url);
    } else {
      this.socialSharing.shareViaWhatsApp(this.text, null, this.url).then(() => {
      }).catch((e) => {
        console.log('whatsapp error - ', e);
      });
    }



    // this.socialSharing.shareViaWhatsApp(this.text, null, this.url).then(() => {
    //   // Success
    // }).catch((e) => {
    //   // Error!
    // });
  }

  async resolveLocalFile() {
    // return this.file.copyFile(`${this.file.applicationDirectory}www/assets/imgs/`, 'academy.jpg', this.file.cacheDirectory, `${new Date().getTime()}.jpg`);
  }

  removeTempFile(name) {
    // this.file.removeFile(this.file.cacheDirectory, name);
  }

  async shareEmail() {
    let file = await this.resolveLocalFile();
    this.text += this.shopS.shopData.Setting.code + ' אתם מוזמנים להכנס ללינק מטה ולהוריד את האפליקציה:' + this.url;

    this.socialSharing.shareViaEmail(this.text, 'שלום', [], null, null, null).then(() => {

    }).catch((e) => {
      // Error!
    });
  }

  async shareFacebook() {
    // let file = await this.resolveLocalFile();

    // Image or URL works
    // this.socialSharing.shareViaFacebook(null, null, null).then(() => {
    //   this.removeTempFile(file.name);
    // }).catch((e) => {
    //   // Error!
    // });
  }













}
