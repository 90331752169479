// import { ProfileUserComponent } from './profile-user/profile-user.component';
import { LoginPhoneComponent } from './login-phone/login-phone.component';
// import { FunctionComponent } from './function/function.component';
// import { AboutClinetComponent } from './about-clinet/about-clinet.component';
//import { IonRouterOutlet } from '@ionic/angular';
import { TutorialComponent } from './tutorial/tutorial.component';
// import { ModalNewmeetComponent } from './modal-newmeet/modal-newmeet.component';
// import { AppointmentPage } from './../pages/appointment/appointment';
// import { chooseDate } from './../pages/chooseDate/chooseDate';
import { NgModule } from '@angular/core';
import { RouterModule,PreloadAllModules, Routes } from '@angular/router';
// import { IonicModule } from '@ionic/angular';

// import { SettingsPage } from '../pages/Setting/Setting.component';
import { loginPage } from '../pages/login/login';
import { SocialLoginPage } from '../pages/socialLogin/socialLogin.page';
// import { choseShop } from '../pages/chooseShop/chooseShop';
import { registerU } from '../pages/register/registerUser';
// import { SetPasswordPage } from '../pages/setPassword/setPassword.page';
// import { SmsCodePage } from '../pages/smsCode/smsCode.page';
// import { AskSmsCodePage } from '../pages/askSmsCode/askSmsCode.page';
import { AddBusinessPage } from '../pages/addBusiness/addBusiness.page';
// import { ClientsPage } from '../pages/clients/clients.page';
import { TabsAdminPage } from '../pages/tabsAdmin/tabsAdmin.page';
// import { CalendarPage } from '../pages/calendar/calendar.page';
import { AddLineAdminPage } from '../pages/add-line-admin/add-line-admin.page';
// import { BLinkPage } from '../pages/b-link/b-link.page';
import { AboutPage } from 'src/pages/about/about';
//import {AdminRegisterPage  } from 'src/pages/admin-register/admin-register.page';


import {AdminRegisterPage  } from 'src/pages/admin-register/admin-register.page';


// import { BusinessProfileAdminPage } from 'src/pages/business-profile-admin/business-profile-admin.page';
// import { LoginadminPage } from '../pages/loginadmin/loginadmin.page';
import { AuthGuard } from '../services/auth-guard.service';
import { BuisProfileComponent } from './buis-profile/buis-profile.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { PhoneLoginComponent } from './phone-login/phone-login.component';
import { PaymentLinkComponent } from './wallet/payment-link/payment-link.component';



const routes: Routes = [
  /// change the page that will start
  { path: '', component: SplashScreenComponent, pathMatch: 'full' },
//  { path: '', redirectTo: 'loginadmin', pathMatch: 'full' },
//   { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: 'tabs', loadChildren: '../pages/tabs.module#TabsPageModule' , canActivate: [AuthGuard]},

  { path: 'tabs', loadChildren: '../pages/tabs/tabs.module#TabsPageModule' , canActivate: [AuthGuard]},
  { path: 'tabsAdmin', loadChildren: '../pages/tabsAdmin/tabsAdmin.module#TabsAdminPageModule' , canActivate: [AuthGuard]},
  // { path: 'settings', component: SettingsPage , canActivate: [AuthGuard]},
  { path: 'about', component: AboutPage , canActivate: [AuthGuard]},
  // { path: 'aboutClient', component: AboutClinetComponent , canActivate: [AuthGuard]},
  { path: 'socialLogin', component: SocialLoginPage },
  { path: 'loginmail', component: loginPage},

  { path: 'login', component: PhoneLoginComponent},
  { path: 'login/:type', component: PhoneLoginComponent },
  { path: 'login/:type/:phone', component: PhoneLoginComponent },

  // { path: 'AppointmentPage', component: AppointmentPage, canActivate: [AuthGuard]},
  { path: 'signUp', component: registerU},
  { path: 'login-phone', component: LoginPhoneComponent},
  // { path: 'chooseShop', component: choseShop, canActivate: [AuthGuard]},
  // { path: 'chooseDate', component: chooseDate, canActivate: [AuthGuard]},
  // { path: 'modalNewMeet', component: ModalNewmeetComponent, canActivate: [AuthGuard]},
  // { path: 'setPassword', component: SetPasswordPage },
  // { path: 'smsCode', component: SmsCodePage},
  // { path: 'askSms', component: AskSmsCodePage },
  { path: 'addBusiness', component: AddBusinessPage , canActivate: [AuthGuard]},
  // { path: 'clients', component: ClientsPage , canActivate: [AuthGuard]},
  // { path: 'calendar', component: CalendarPage , canActivate: [AuthGuard]},
  { path: 'addline', component: AddLineAdminPage , canActivate: [AuthGuard]},
  // { path: 'Business', component: BusinessProfileAdminPage , canActivate: [AuthGuard]},
  // { path: 'loginadmin', component: LoginadminPage },  
  { path: 'tutorial', component: TutorialComponent },  
  { path: 'admin-register', component: AdminRegisterPage,canActivate: [AuthGuard] },
  // { path: 'business-profile', component: ProfileUserComponent,canActivate: [AuthGuard] },
  
  { path: 'b/:id', component: BuisProfileComponent },
  { path: 'pay/:id', component: PaymentLinkComponent },


  {
    path: 'payments',loadChildren: './payments/payments.module#PaymentsPageModule',canActivate: [AuthGuard]  },
  // { path: 'function', component: FunctionComponent  ,canActivate: [AuthGuard] },
  {
    path: 'statistics',loadChildren: './statistics/statistics.module#StatisticsPageModule',canActivate: [AuthGuard] 
   },
   {
    path: 'invoice',loadChildren: './invoice/invoice.module#InvoicePageModule',canActivate: [AuthGuard] 
   },
   {
    path: 'products',loadChildren: './products/products.module#ProductsPageModule',canActivate: [AuthGuard] 
   },
   
   {
    path: 'wallet',loadChildren: './wallet/wallet.module#WalletPageModule',canActivate: [AuthGuard]  },




  
  // { path: 'b/:name', component: BLinkPage },

 // admin-register
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  
  exports: [RouterModule]
})
export class AppRoutingModule { }

// { path: 'home', loadChildren: './pages/home/home.module#HomePageModule' },
// { path: 'menu', loadChildren: './pages/menu/menu.module#MenuPageModule' },

// {path: 'home', loadChildren: './pages/home/home.module#HomePageModule'},
// {path: 'menu', component: MenuPage},
// { path: 'basket', loadChildren: './pages/basket/basket.module#BasketPageModule' },
// { path: 'scan-qr', loadChildren: './pages/scan-qr/scan-qr.module#ScanQrPageModule' },
// { path: 'map', loadChildren: './pages/map/map.module#MapPageModule' },
