import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastService } from 'src/providers/toast-service';
import { ShopService } from 'src/ShopService/shop.service';
import { WalletServiceService } from '../wallet-service/wallet-service.service';

@Component({
  selector: 'app-wallet-meet-settings',
  templateUrl: './wallet-meet-settings.component.html',
  styleUrls: ['./wallet-meet-settings.component.scss'],
})
export class WalletMeetSettingsComponent implements OnInit {

  constructor(
    public modalController: ModalController,
    public walletS: WalletServiceService,
    public shopS: ShopService,
    private toast: ToastService,
  ) { }

  currentService;

  ngOnInit() {
    console.log(this.shopS.shopData.MeetingType);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  chooseService(service) {
    console.log(service);
    this.currentService = service;
  }

  // saveMeetType() {
  //   if (this.shopS.checkEmptyAndSpaceOnly(this.currentService.name)) {
  //     this.shopS.shopData.MeetingType[this.currentService.key] = this.currentService;
  //     this.shopS.shopData.MeetingType = JSON.parse(JSON.stringify(this.shopS.shopData.MeetingType));

  //     console.log('this.shopS.shopData.MeetingType', this.shopS.shopData.MeetingType)
  //     console.log('this.shopS.shopData.MeetingType[this.currentService.key]', this.shopS.shopData.MeetingType[this.currentService.key])
  //     this.shopS.updateStr('/MeetingType/' + this.currentService.key + '/', this.currentService);
  //     this.toast.showToast('השירות נשמר בהצלחה');

  //   } else {
  //     // this.presentEmptyAndSpaceOnly()
  //   }
  // }

  asd(s) {
    console.log(s);

  }


  checkMinPrice(service) {
    if (service.payment_way == 'pre_payment' && service.price < 5) {
      return 'min5';
    } else if (service.payment_way == 'down_payment') {
      let minPrecentage = Math.min(parseFloat(service.down_payment_amount), 100 - parseFloat(service.down_payment_amount));
      if (!service.down_payment_amount || (minPrecentage * parseFloat(service.price)) / 100 < 5) {
        return 'down_payment_min5';
      }
    }
  }

  checkConditions(service) {
    let msg = 'ok';
    if (service.approve && service.payment_way && (service.payment_way == 'pre_payment' || service.payment_way == 'down_payment')) {
      msg = 'לא ניתן לדרוש תשלום ואישור על תור בו זמנית';
      // this.toast.showTostDanger(msg);
    }
    let checkPrice = this.checkMinPrice(service);
    if (checkPrice == 'min5' || checkPrice == 'down_payment_min5') {
      msg = 'יש להזין תשלום מינימלי של 5 שקלים';
      // this.toast.showTostDanger(msg);
    }
    return msg;
  }


  async saveMeetType(val, prop, service) {
    console.log(val, prop, service);
    
    if (val == 'toggle') {
      service[prop] = !service[prop];
    } else {
      service[prop] = val;
    }
    delete service.msg;
    let msg = this.checkConditions(service);
    if (msg == 'ok') {
      this.shopS.updateStr('/MeetingType/' + service.key + '/', service);
    } else {
      service.msg = msg;
    }

  }

  toggleSwitch(service, field) {
    console.log(field);
    service[field] = !service[field];
  }

  // setDownPaymentAmount(service, amount) {
  //   service.down_payment_amount = amount;
  //   this.saveMeetType(service);
  // }

  cancel() {
    this.currentService = null;
  }

}
