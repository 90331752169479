import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PaymentsPageRoutingModule } from './payments-routing.module';

import { PaymentsPage } from './payments.page';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { AddCreditCardComponent } from "./add-credit-card/add-credit-card.component";
// import { CardModule } from 'ngx-card/ngx-card';
import { PaynowComponent } from "./paynow/paynow.component";
import { PaymenyHistoryPageModule } from './paymeny-history/paymeny-history.module';

import { ChangeCardSubscriptionComponent } from "./change-card-subscription/change-card-subscription.component";
import { AddCardIframeComponent } from "./add-card-iframe/add-card-iframe.component";
import { NotificationBellModule } from 'src/components/notification-bell/notification-bell.module';
import { CardOptionsComponent } from './card-options/card-options.component';
import { AddCreditCardPaymeComponent } from './add-credit-card-payme/add-credit-card-payme.component';
import { MainModule } from '../main/main.module';

 

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SuperTabsModule,
    // CardModule,
    MainModule,
    PaymentsPageRoutingModule,
    PaymenyHistoryPageModule,
    NotificationBellModule
  ],
  declarations: [PaymentsPage,AddCreditCardComponent,PaynowComponent,ChangeCardSubscriptionComponent,AddCardIframeComponent,CardOptionsComponent,
    AddCreditCardPaymeComponent
  ],
  entryComponents: [AddCreditCardComponent,PaynowComponent,ChangeCardSubscriptionComponent,AddCardIframeComponent,CardOptionsComponent,
    AddCreditCardPaymeComponent
  ],
  // exports: [    CardModule,
  // ]
})
export class PaymentsPageModule { }
