import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from "../providers/auth-service";
import { ShopService } from "../ShopService/shop.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public authenticationService: AuthService,
    public router: Router,
    public shopS: ShopService
  ) { }
  canActivate(): boolean {
    //   console.log('canActivate');
    //  console.log('this.shopS.tutrialModeskip',this.shopS.tutrialModeskip);
    console.log("AUTH GUARD", this.authenticationService.auth_state);
    if (this.shopS.tutrialModeskip == true) {
      return;
    }

    // console.log(!this.authenticationService.isAuthenticated());

    if (this.authenticationService.auth_state.getValue() == 'not-auth') {
      //   console.log('this.authenticationService.isAuthenticated()',this.authenticationService.isAuthenticated());
      //     console.log('this.shopS.clientSide',this.shopS.clientSide);

      // if(this.shopS.clientSide){
      //   this.router.navigate(['socialLogin']);

      // }else{
      this.router.navigate(['login'], { replaceUrl: true });
      // }
      //     console.log('this.authenticationService.isAuthenticated()',this.authenticationService.isAuthenticated());

      return false;
    } else if (this.authenticationService.auth_state.getValue() == 'auth') {
      return true;
    } else {
      return false;
    }



  }

}