import { Component, OnInit } from '@angular/core';
import { NotificationService } from "../notification.service";
import {ShopService} from '../../ShopService/shop.service';
import { ModalController } from '@ionic/angular';
import { AlertExample } from 'src/providers/alert.service';


class Port {
  public id: string;
  public name: string;
  public userData: object;

}


@Component({
  selector: 'app-notification-to-all',
  templateUrl: './notification-to-all.component.html',
  styleUrls: ['./notification-to-all.component.scss'],
})
export class NotificationToAllComponent implements OnInit {
  note = '';
  title= '';
  userArray;
  searchText:any = '';
  ports: Port[];
  ports2 =  [];
  port: Port;
  UserSelected:any;



  constructor(
    public NotificationService:NotificationService,
    public shopS:ShopService,
    private modalController:ModalController,
    private alertS:AlertExample,
  ) { }



closeModal(){
	this.modalController.dismiss()
}




  templateInsert(t){
    console.log('t',t);
    this.note = t.text;
    this.title = t.title;
  } 


  InsertTo(text){
    this.note += text;
  }


noti_to_all_list = [];
  ngOnInit() {


 //this.noti_to_all_list = this.shopS.configDATA.notiToAll

this.shopS.configDATA.notiToAll.forEach(element => {
  
console.log(element)
let temp_ele = {
  text: this.replace_biz_Text(element.text),
  title: this.replace_biz_Text(element.title)
}
console.log('temp_ele',temp_ele)

this.noti_to_all_list.push(temp_ele);

});




console.log(this.shopS.configDATA.notiToAll)


    this.userArray = this.shopS.json2array(this.shopS.shopData.Users);
    this.ports = [];


    this.userArray.forEach(element => {

      console.log("elemnts",element.fullname)
      var n = element.fname + ' ' + element.lname;
        if(!element.local){
        this.ports.push({
          id: element.key,
          name: n,
          userData: element
        })
      }

    });
  
    this.ports2=this.ports;
    console.log(this.ports)

  }

  auto_height(elem) {  /* javascript */
    console.log(elem)
    elem.target.style.height = "1px";
    elem.target.style.height = (elem.target.scrollHeight)+"px";
}

replace_biz_Text(text){
  return text.split(['[-Shopname-]']).join(this.shopS.shopData.Setting.name);
 }



  maskText(text,element){
   return text.split(['[-fname-]']).join(element.fname);
  }



    sendToAllClients(){
      console.log('this.note',this.note)
      console.log('this.title',this.title)
   

      if(this.note == '' || this.title == ''){

        this.alertS.aleryByTextgood('לא ניתן לשלוח הודעה ריקה')
        
      }else{

     




     let users =  this.shopS.json2array(this.shopS.shopData.Users);

     users.forEach(element => {
        if(!element.local){


         


          console.log('element',element);
          element['uId'] = element.key;
          element['message'] =  this.maskText(this.note,element)  ;
          element['title'] =  this.maskText(this.title,element)  ;
          this.NotificationService.sendNotificationToAll(element,'notiToAll');

        }

       

      });   




      this.shopS.showToast('נשלח לכולם בצלחה');
      //this.alertS.aleryByTextgood('נשלח לכולם בצלחה')
      this.closeModal();
    }

    }











}
