import { eventNames } from 'process';
import * as moment from 'moment-timezone';

export class EventClass {

    constructor() {
        this.newGuid();
    }

    public CloseBefore: any;
    public CatchPeople: any;
    public totalPeople: any;
    public eventName: string;
    public eventTime: any;
    public timestamp: any;
    public needConfirm = 1;
    public zoomlink = "";
    public meetTime: any;
    public afDb: any;
    private workTimeArray;
    public reminder: any;
    public BarberID: string;
    public barberName: string;
    public endTime: string;
    // public startTime: any;
    public sendSms = true;
    public price: string = "0";
    public startTimeView: string;
    public endTimeView: string;
    public meetType = "event";
    public meetDate: any;
    public meetTypeName: string;
    public meetDay: string;
    public eventStatus: number;
    public moreInfo: string = "";
    public Date: any;
    public flow: any;
    public timezone: any;
    public uIdData: object;
    public startDate: any;
    public key: string = this.newGuid();
    public uId: any;
    public meetDateFormat: any;
    public eventCover: any;
    public payment_way: any;
    public down_payment_amount: any;
    private repeatKey;
    private arrayRepet = [];
    public mType = {
        type: "",
        duplicated: "",
        repeat: ""
    }
    private daysName = ["יום ראשון", "יום שני", "יום שלישי", "יום רביעי", "יום חמישי", "יום שישי", "יום שבת"];

    public StartTimeObj = {
        Hour: "00",
        Minuts: "00"
    }
    public EndTimeObj = {
        Hour: "00",
        Minuts: "00"
    }



    newKey() {
        this.key = this.newGuid();
    }



    showClass() {
        console.log("showClass : " +
            "\n", "BarberID ", this.BarberID,
            "\n barberName ", this.barberName,
            "\n endTime ", this.endTime,
            "\n timestamp ", this.timestamp,
            "\n eventTime ", this.eventTime,
            "\n meetTime ", this.meetTime,
            "\n price ", this.price,
            "\n startTimeView ", this.startTimeView,
            "\n endTimeView ", this.endTimeView,
            "\n meetType ", this.meetType,
            "\n meetDate ", this.meetDate,
            "\n meetTypeName ", this.meetTypeName,
            "\n meetDay ", this.meetDay,
            "\n eventStatus ", this.eventStatus,
            "\n moreInfo ", this.moreInfo,
            "\n Date ", this.Date,
            "\n key ", this.key,
            "\n uId ", this.uId,
            "\n meetDateFormat ", this.meetDateFormat,
            "\n startDate ", this.startDate,
            "\n flow ", this.flow,
            "\n CloseBefore ", this.CloseBefore,
            "\n CatchPeople ", this.CatchPeople,
            "\n totalPeople ", this.totalPeople,
            "\n eventName ", this.eventName,
            "\n eventTime ", this.eventTime,
            "\n sendSms ", this.sendSms,
            "\n zoomlink ", this.zoomlink,
            "\n eventCover ", this.eventCover,
            "\n payment_way ", this.payment_way,
            "\n down_payment_amount ", this.down_payment_amount



        )
    }


    importEventData(fMeet: any) {
        console.log(fMeet)
        this.eventName = fMeet.eventName;
        this.timestamp = fMeet.timestamp ? fMeet.timestamp : null;
        this.CatchPeople = fMeet.CatchPeople;
        this.totalPeople = fMeet.totalPeople;
        this.CloseBefore = fMeet.CloseBefore;
        this.meetTime = fMeet.meetTime;
        this.BarberID = fMeet.BarberID;
        this.Date = fMeet.Date;
        this.needConfirm = fMeet.needConfirm;
        this.barberName = fMeet.barberName;
        this.endTime = fMeet.endTime;
        this.endTimeView = fMeet.endTimeView;
        this.flow = fMeet.flow;
        this.key = fMeet.key;
        this.meetDate = fMeet.meetDate;
        this.meetDateFormat = fMeet.meetDateFormat;
        this.eventStatus = fMeet.meetStatus;
        this.eventTime = fMeet.meetTime;
        this.startTimeView = fMeet.startTimeView;
        this.meetType = fMeet.meetType;
        this.moreInfo = fMeet.moreInfo;
        this.price = fMeet.price;
        this.meetTypeName = fMeet.meetTypeName;
        this.startDate = fMeet.startDate;
        // this.startTime = fMeet.startTime;
        this.uId = fMeet.uId;
        this.uIdData = fMeet.uIdData;
        this.sendSms = fMeet.sendSms;
        this.zoomlink = fMeet.zoomlink;
        this.eventCover = fMeet.eventCover;
        this.payment_way = fMeet.payment_way;
        this.down_payment_amount = fMeet.down_payment_amount;

        var d = new Date(this.startDate);
        this.meetDay = this.daysName[d.getDay()]


        this.importViewEnd(fMeet.endTimeView);
        this.imporViewtStart(fMeet.startTimeView);

        if (typeof fMeet.meetType == "object") {
            this.meetTypeName = fMeet.meetType.name;
        } else {
            this.meetTypeName = "";

        }


    }


    importEvent(eventObject) {
        console.log("eventObject", eventObject);

        this.meetDate = eventObject.dateTime;
        this.meetTypeName = 'Event';
        this.needConfirm = eventObject.needConfirm;
        this.flow = false;
        this.CatchPeople = 0;
        this.meetType = 'event';
        // this.uId = "newevent";
        this.uId = [];
        this.timestamp = eventObject.timestamp ? eventObject.timestamp : null;

        //step 1 - start time
        this.importZoomlink(eventObject.zoomlink);
        this.importeventCover(eventObject.eventCover);
        this.importStartDateAndHoureview(eventObject.dateTime);
        this.importBarber(eventObject.BarberID, eventObject.barberName);
        this.importMoreInfo(eventObject.moreInfo);
        this.importsendSms(eventObject.sendSms)
        this.defineeventTime(eventObject.valueTime);
        this.defineMeetPrice(eventObject.eventPrice);
        this.defineEventPeople(eventObject.eventPeople);
        this.defineEventName(eventObject.eventName);
        this.defineStartTimeAndEndTime(eventObject.dateTime, eventObject.valueTime);
        this.defineEventCloseBefore(eventObject.eventCloseBefore);
        this.definePaymentWay(eventObject.payment_way);
        this.defineDownPaymentAmount(eventObject.down_payment_amount);



        this.showClass();


    }
    importsendSms(data) {
        this.sendSms = data;
    }
    importeventCover(data) {
        this.eventCover = data;
    }

    resetCLass() {

        this.BarberID = "";
        this.barberName = "";
        this.endTime = "";
        this.eventTime = "";
        this.price = "";
        this.startTimeView = "";
        this.endTimeView = "";
        this.meetType = "";
        this.timestamp = "";
        this.meetDate = "";
        this.meetTypeName = "";
        this.meetDay = "";
        this.eventStatus = 0;
        this.moreInfo = "";
        this.Date = "";
        this.key = this.newGuid();
        this.uId = "";
        this.eventName = "";
        this.CatchPeople = 0;
        this.CloseBefore = 0;
        this.totalPeople = 0;
        this.zoomlink = "";
        this.eventCover = '';

    }

    importZoomlink(zoomlink) {
        console.log("zoomlink", zoomlink)
        if (zoomlink) {

            this.zoomlink = zoomlink;
        }
    }
    exportExtraData() {

        return {
            key: this.key,
            BarberID: this.BarberID,
            barberName: this.barberName,
            Date: this.Date,
            meetDateFormat: this.meetDateFormat,
            startDate: this.Date.toString(),
            startTimeView: this.startTimeView,
            meetDate: this.meetDate,
            endTime: this.endTime,
            endTimeView: this.endTimeView,
            meetTime: this.eventTime,
            price: this.price,
            flow: false,
            meetType: this.meetType,
            meetStatus: this.eventStatus,
            moreInfo: this.moreInfo,
            mType: this.mType,
            eventTime: this.eventTime,
            zoomlink: this.zoomlink,
            eventStatus: this.needConfirm,
            totalPeople: this.totalPeople,
            eventName: this.eventName,
            sendSms: this.sendSms,
            eventCover: this.eventCover,
            typem: "event"


        }
    }
    exportEventAdmin() {

        this.fixDate();

        return {
            key: this.key,
            // uId: this.uId ? this.uId : "newevent",
            uId: this.uId ? this.uId : [],
            BarberID: this.BarberID,
            barberName: this.barberName,
            timestamp: this.timestamp ? this.timestamp : new Date().getTime(),
            Date: this.Date,
            meetDateFormat: this.meetDateFormat,
            startDate: new Date(this.Date).toString(),
            startTimeView: this.startTimeView,
            meetDate: this.meetDate,
            endTime: this.endTime,
            endTimeView: this.endTimeView,
            meetTime: this.eventTime,
            price: this.price,
            flow: this.flow,
            meetType: this.meetType,
            meetStatus: this.eventStatus,
            moreInfo: this.moreInfo,
            mType: this.mType,
            needConfirm: this.needConfirm,
            eventTime: this.eventTime,
            eventStatus: this.eventStatus,
            CloseBefore: this.CloseBefore,
            CatchPeople: this.CatchPeople,
            totalPeople: this.totalPeople,
            eventName: this.eventName,
            sendSms: this.sendSms,
            zoomlink: this.zoomlink,
            eventCover: this.eventCover,
            payment_way: this.payment_way,
            down_payment_amount: this.down_payment_amount,
            typem: "event"


        }

    }
    exportEventUser(event?) {

        this.fixDate();

        return {
            key: event ? event.key : this.key,
            BarberID: event ? event.BarberID : this.BarberID,
            barberName: event ? event.barberName : this.barberName,
            Date: event ? event.Date : this.Date,
            timestamp: event ? event.timestamp : this.timestamp,
            meetDateFormat: event ? event.meetDateFormat : this.meetDateFormat,
            startDate: event ? event.startDate : this.startDate,
            startTimeView: event ? event.startTimeView : this.startTimeView,
            meetDate: event ? event.meetDate : this.meetDate,
            endTime: event ? event.endTime : this.endTime,
            endTimeView: event ? event.endTimeView : this.endTimeView,
            meetTime: event ? event.eventTime : this.eventTime,
            price: event ? event.price : this.price,
            flow: event ? event.flow : false,
            meetType: event ? event.meetType : this.meetType,
            meetStatus: event ? event.eventStatus : this.needConfirm,
            moreInfo: event ? event.moreInfo : this.moreInfo,
            mType: event ? event.mType : this.mType,
            eventStatus: event ? event.eventStatus : this.needConfirm,
            eventTime: event ? event.eventTime : this.eventTime,
            totalPeople: event ? event.totalPeople : this.totalPeople,
            eventName: event ? event.eventName : this.eventName,
            sendSms: event ? event.sendSms : this.sendSms,
            zoomlink: event ? event.zoomlink : this.zoomlink,
            eventCover: event ? event.eventCover : this.eventCover,
            reminder: event ? event.reminder : (this.reminder ? this.reminder : 60),
            payment_way: event ? event.payment_way : this.payment_way,
            down_payment_amount: event ? event.down_payment_amount : this.down_payment_amount,
            typem: "event",
            timezone: this.timezone
        }
    }

    defineEventCloseBefore(closeBefore) {
        this.CloseBefore = closeBefore;
    }

    defineEventName(Ename) {
        this.eventName = Ename;
    }
    defineMeetPrice(price) {
        this.price = price;

    }
    defineEventPeople(people) {
        this.totalPeople = people;
    }

    definePaymentWay(way) {
        this.payment_way = way;
    }
    defineDownPaymentAmount(amount) {
        this.down_payment_amount = amount;
    }


    // CreateNewMeet() {
    //     console.log("ifmeet");
    //     if (this.ifmeet()) {
    //         console.log("ifmeet", this.Date, this.meetType.eventTime);
    //         this.defineStartTimeAndEndTime(this.Date, this.meetType.eventTime);
    //         this.price = this.meetType.price;
    //         this.eventTime = this.meetType.eventTime;
    //         this.meetTypeName = this.meetType.name;
    //         this.eventStatusDefine();



    //     }
    // }

    fixDate() {
        var my_zone_name = moment.tz.guess();
        var busi_timezone = moment.tz("Asia/Jerusalem");
        let busi_utcOffset = moment.parseZone(busi_timezone).utcOffset();

        let my_offset = new Date().getTimezoneOffset();

        let fixed_date_start = new Date(this.Date + (my_offset * 60000) + (busi_utcOffset * 60000));
        let fixed_date_end = new Date(this.Date + (my_offset * 60000) + (busi_utcOffset * 60000) + (parseInt(this.meetTime) * 60000));
        this.startDate = new Date(this.Date).toString();
        this.endTime = new Date(this.Date + (parseInt(this.meetTime) * 60000)).toString();
        this.startTimeView = this.addzero(fixed_date_start.getHours()) + ':' + this.addzero(fixed_date_start.getMinutes());
        this.endTimeView = this.addzero(fixed_date_end.getHours()) + ':' + this.addzero(fixed_date_end.getMinutes());
        this.meetDate = this.addzero(fixed_date_start.getDate()) + '-' + (this.addzero(fixed_date_start.getMonth() + 1)) + '-' + this.addzero(fixed_date_start.getFullYear());
        this.meetDateFormat = this.meetDate;
        this.timezone = my_zone_name;
    }


    defineStartTimeAndEndTime(currentDate, eventTime) {
        console.log(currentDate, eventTime);

        let startT = new Date(currentDate);
        this.startDate = startT;
        startT.setHours(Number(this.StartTimeObj.Hour), Number(this.StartTimeObj.Minuts)).toString();
        // this.Date = new Date(startT).getTime();
        let end: any = new Date(currentDate);
        end.setTime(this.Date + (eventTime * 60 * 1000));
        this.endTimeView = this.addzero(end.getHours()) + ':' + this.addzero(end.getMinutes())
        this.meetDay = this.daysName[end.getDay()]
        this.endTime = end.toString();
    }


    ifmeet() {
        console.log(this.BarberID, this.meetDate, this.meetType, this.startTimeView)
        if (this.BarberID && this.meetDate && this.meetType && this.startTimeView) {
            return true;
        } else {
            return false;
        }
    }


    importBarber(id, bName) {
        this.BarberID = id;
        this.barberName = bName;
    }

    importMoreInfo(info) {
        this.moreInfo = info;
    }

    defineeventTime(t) {
        this.eventTime = t;
        this.meetTime = t;
        // this.meetType.eventTime = t;
    }
    importStartDateAndHoureview(sDate) {

        // this.startTime = sDate;
        this.Date = new Date(sDate).getTime();
        this.meetDate = this.meetDateFormatDefine(sDate);
        this.startTimeView = this.addzero(new Date(this.Date).getHours()) + ":" + this.addzero(new Date(this.Date).getMinutes());
        this.startDate = new Date(this.Date);

        this.StartTimeObj.Hour = this.addzero(new Date(this.Date).getHours());
        this.StartTimeObj.Minuts = this.addzero(new Date(this.Date).getMinutes());



    }



    changeStartTime(oldtime) {
        let t = oldtime.split(":", 2).map(Number);
        this.StartTimeObj.Hour = t[0].toString();
        this.StartTimeObj.Minuts = t[1].toString();
        this.startTimeView = oldtime
        var StartTime = new Date(this.Date);
        StartTime.setHours(Number(this.StartTimeObj.Hour), Number(this.StartTimeObj.Minuts)).toString();
        // this.startTime = StartTime;
        this.Date = new Date(StartTime).getTime();
        this.startDate = new Date(StartTime);
        this.defineEndTime();
    }

    changeMeetTime(newTime) {
        this.eventTime = newTime;
        this.meetTime = newTime;
        this.defineStartTimeAndEndTime(this.Date, newTime)
    }



    defineEndTime() {


        let end: any = new Date(this.Date);
        end.setTime(end.getTime() + (Number(this.eventTime) * 60 * 1000));
        this.endTimeView = this.addzero(end.getHours()) + ':' + this.addzero(end.getMinutes())
        this.meetDay = this.daysName[end.getDay()]
        this.endTime = end.toString();


    }

    addzero(x) {
        x = x.toString();
        if (x.length == 1) {
            x = "0" + x;
        }
        return (x)
    }

    meetDateFormatDefine(meetdate) {

        this.meetDateFormat = this.convertDate(meetdate);
        return this.meetDateFormat;

    }


    importViewEnd(oldtime) {

        let t = oldtime.split(":", 2).map(Number);
        this.EndTimeObj.Hour = t[0].toString();
        this.EndTimeObj.Minuts = t[1].toString();
        this.endTimeView = oldtime
    }

    imporViewtStart(oldtime) {

        let t = oldtime.split(":", 2).map(Number);
        this.StartTimeObj.Hour = t[0].toString();
        this.StartTimeObj.Minuts = t[1].toString();
        this.startTimeView = oldtime
    }

    // importStartDate(sDate) {

    //     // this.startTime = sDate;
    //     this.Date = new Date(sDate).getTime();
    //     this.startDate = new Date(sDate);
    //     this.meetDate = this.meetDateFormatDefine(sDate);

    // }



    newGuid() {
        return 'xxxx5xxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    convertDate(inputFormat) {

        var today: any = new Date(inputFormat);
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        return dd + '-' + mm + '-' + yyyy;

    }

    convertDateToYYYY(inputFormat) {
        console.log(inputFormat);


        let t = inputFormat.split("-");
        return t[2].toString() + "-" + t[1].toString() + "-" + t[0].toString();


    }
}


