import { Component, OnInit } from '@angular/core';
import { TasksService } from "./tasks-service/tasks.service";
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {

  constructor(
    public taskS:TasksService
  ) { }
  
  ngOnInit() {
  }

  newTask(){
    console.log('newtask')
     this.taskS.new_task(this.taskS.default_task);   
  }




}
