import { PremiumService } from './../../app/premium/premium-service/premium.service';
import { ModalController } from '@ionic/angular';
import { UserHistoryComponent } from './../../app/user-history/user-history.component';
import { AlertExample } from './../../providers/alert.service';
import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';
import { ToastService } from '../../providers/toast-service';
import { WalletServiceService } from 'src/app/wallet/wallet-service/wallet-service.service';


@Component({
  selector: 'client-modal',
  templateUrl: './client-modal.component.html',
  styleUrls: ['./client-modal.component.scss'],
})
export class ClientModalComponent implements OnInit {
  Nuser = {
    fname: '',
    lname: '',
    phone: '',
    local: true,
  };
  sms_to_client = '';
  sms_open = false; 

  constructor(
    public shopS: ShopService,
    private AlertExample: AlertExample,
    private toast: ToastService,
    private premiumService: PremiumService,
    private modalController: ModalController,
    public walletS: WalletServiceService

  ) { }

  ngOnInit() {


  }
  oldNote = '';
  focusText(data) {
    this.oldNote = data.note;
  }

  BlourText(data) {
    console.log('data', data);
    console.log('this.oldNote', this.oldNote);
    if (data.note != this.oldNote) {
      this.toast.showToast('ההערה עודכנה בהצלחה')
      this.shopS.updateStr('/Users/' + data.key + '/',
        { note: data.note });
    }

  }

  send_sms_exc(ev) {
    ev.stopPropagation();
    this.sms_open = false;
    let data = {
      phone: this.shopS.SelectedUser.phone,
      msg: this.sms_to_client + '\nתודה, ' + this.shopS.shopData.Setting.name + '.'
    }
    this.sms_to_client = '';
    this.shopS.send_sms(data);
  }

  toggleSms() {
    this.sms_open = !this.sms_open;
  }
  
  stopProp(ev) {
    ev.stopPropagation();
  }

  toggleModal(event) {
    event.stopPropagation();
    this.shopS.ClientmodalFLAG = !this.shopS.ClientmodalFLAG;
    console.log('toggleModal=======this.shopS.ClientmodalFLAG', this.shopS.ClientmodalFLAG);
  }


  loadHistoryUser(user) {
    console.log("loadHistoryUser", user.key);
    if (user.key && this.shopS.shopData.Setting.BarberID) {
      this.openUserHistoryModal(user, this.shopS.shopData.Setting.BarberID);
    }
  }

  loadHistoryLocalUser(user) {
    this.openUserHistoryModal(user, this.shopS.shopData.Setting.BarberID, true);
  }

  async openUserHistoryModal(user, BarberID, local?) {

    const modal = await this.modalController.create({
      component: UserHistoryComponent,
      swipeToClose: true,
      cssClass: 'modalForPayments modalGreyBG modal-half-hight',
      componentProps: {
        'user': user,
        'barber': BarberID,
        'local': local

      },
      mode: "ios",
      // Get the top-most ion-modal
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();


  }

  toggleModalOpenAnotherModal() {
    event.stopPropagation();
    this.shopS.ClientmodalFLAG = !this.shopS.ClientmodalFLAG;
    this.shopS.userUpdatedFLAG = true;

    this.shopS.userUpdated = JSON.parse(JSON.stringify(this.shopS.SelectedUser));

    this.shopS.addClientmodalFLAG = !this.shopS.addClientmodalFLAG;
  }

  deleteUserFromBiz(user) {
    console.log('user: ', user);


    this.presentAlertConfirm(user);
  }

  async presentAlertConfirm(user) {
    const alert = await this.AlertExample.alertController.create({
      header: 'מחיקת לקוח',
      message: 'בטוחים שתרצו למחוק ?',
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'כן',

          handler: () => {
            this.shopS.deleteUserFrombiz(user);
            this.shopS.ClientmodalFLAG = false;
            this.toast.showTostDanger('הלקוח נמחק ');

          }
        }
      ]
    });
    await alert.present();
  }


  newLine(event, u) {
    event.stopPropagation();

    console.log('newLine-u', u);
    this.shopS.currentMeet.user = u;
    this.shopS.ClientmodalFLAG = false;
    this.shopS.navAdminTab('add');

  }


}
