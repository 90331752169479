import { AdminHistoryComponent } from './../../app/admin-history/admin-history.component';
import { PremiumService } from './../../app/premium/premium-service/premium.service';
import { CaltimePipPipe } from './../../app/caltime-pip.pipe';
import { PremiumPopoverComponent } from './../../app/premium-popover/premium-popover.component';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';
import { AlertController, PopoverController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { CoronavirusComponent } from './../../app/coronavirus/coronavirus.component';
import { KnoknowledgeModalComponent } from './../../app/components/knoknowledge-modal/knoknowledge-modal.component';
import { LoginPhoneComponent } from 'src/app/login-phone/login-phone.component';
import { HolidayListComponent } from 'src/app/holiday-list/holiday-list.component';
import { log } from 'console';
// import { AlertExample } from 'src/providers/alert.service';



@Component({
  selector: 'app-calendar-settings-modal',
  templateUrl: './calendar-settings-modal.component.html',
  styleUrls: ['./calendar-settings-modal.component.scss'],
})
export class CalendarSettingsModalComponent implements OnInit {
  hourarray = ["5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60"];
  cssForPremium = {
    cssClass: "premium-class",
    buttons: [
      {
        text: 'Add Row',
        role: 'destructive',
        handler: () => {
          console.log('Do Something');
        }
      }]

  }

  constructor(
    public shopS: ShopService,
    private premiumService: PremiumService,
    public alertController: AlertController,
    public modalController: ModalController,
    public popoverController: PopoverController

  ) { }


  showPremium() {
    // this.popoverController.dismiss();
    this.premiumService.PremiumLock("go");
  }

  async presentPopover(typePop) {
    const popover = await this.popoverController.create({
      component: PremiumPopoverComponent,
      cssClass: 'my-custom-class popmodal',

      translucent: true,
      componentProps: { "type": typePop }



    });
    return await popover.present();
  }


  ngOnInit() {
    // Pass a custom class to each select interface for styling
    // Pass a custom class to each select interface for styling




    // var newarray = [];
    // var index = 0;
    // for (var i = 5; i <= 60; i += 5) {
    //   newarray[index] = i;
    //   index++;
    // }
    // console.log(newarray);

  }

  // toggleModal(event){
  //   event.stopPropagation();
  //   this.shopS.calendarSettingFLAG = !this.shopS.calendarSettingFLAG;
  //   // console.log('toggleModal=======this.shopS.calendarSettingFLAG',this.shopS.calendarSettingFLAG);
  // }


  //// setting Update
  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////



  settingUpdate = {};




  cutemptyspace() {
    this.shopS.shopData.Setting.cutemptyspace = !this.shopS.shopData.Setting.cutemptyspace;
    this.settingUpdate["cutemptyspace"] = this.shopS.shopData.Setting.cutemptyspace;
    this.shopS.updateStr('/Setting/', this.settingUpdate);
  }

  settingChange(event, kind) {
    console.log('event', event);
    console.log('kind', kind);

    // console.log('this.shopS.shopData=before',this.shopS.shopData);
    // this.shopS.shopData[kind] = event.detail.value;
    // console.log('this.shopS.shopData=after',this.shopS.shopData);

    console.log(this.settingUpdate);
    this.settingUpdate[kind] = event;
    this.shopS.updateStr('/Setting/', this.settingUpdate);
    const select: any = document.querySelector('.custom-options ');
    console.log('seleeeedct', select);
    if (select) {
      select.interfaceOptions = {
        cssClass: 'my-custom-interface'
      };
    }
  }

  changeOrder

  //// Time calendar Change
  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////


  TimecalChange(ev) {
    if (ev) {
      this.shopS.calendarIsDisplay = false;
      localStorage.setItem("calendarTimeInterval", ev);
      this.shopS.calendar.timeInterval = ev;
      setTimeout(() => {
        this.shopS.calendarIsDisplay = true;
      }, 10);
    }
  }
 
 
 @ViewChild('dismissmodalbtn',{static:false}) dismissmodalbtn: ElementRef<HTMLElement>;
 
 closeModal() {
  setTimeout(() => {
    this.modalController.dismiss()
  }, 400);
  }


  dismissModal() {
    if (this.modalController) {
      this.modalController.dismiss({
        'dismissed': false
      }).then(() => { this.modalController = null; });
    }
  }


  chaneTrain(event) {
    this.shopS.shopData.Setting.trainMeet = !this.shopS.shopData.Setting.trainMeet;

    this.settingUpdate["trainMeet"] = this.shopS.shopData.Setting.trainMeet
    this.shopS.updateStr('/Setting/', this.settingUpdate);
  }

  // async changeHistory(event) {

  //   if (this.shopS.shopData.Setting.history) {
  //     const alert = await this.alertController.create({
  //       header: ' ברצונך למחוק היסטוריה? תהליך זה בלתי הפיך',
  //       message: '',
  //       buttons: [
  //         {
  //           text: 'המשך לשמור',
  //           role: 'cancel',
  //           cssClass: 'secondary',
  //           handler: (blah) => {
  //             console.log('Cancel Cancel: Cancel');
  //             return false;
  //           }
  //         }, {
  //           text: 'מחק היסטוריה',
  //           handler: () => {

  //             console.log('Okay: Okay');
  //             //confirm order 
  //             this.shopS.shopData.Setting.history = !this.shopS.shopData.Setting.history;

  //             this.settingUpdate["history"] = this.shopS.shopData.Setting.history
  //             this.shopS.updateStr('/Setting/', this.settingUpdate);
  //             this.shopS.removeHistory();


  //           }
  //         }
  //       ]
  //     });
  //     await alert.present();
  //   } else {
  //     this.shopS.shopData.Setting.history = !this.shopS.shopData.Setting.history;

  //     this.settingUpdate["history"] = this.shopS.shopData.Setting.history
  //     this.shopS.updateStr('/Setting/', this.settingUpdate);

  //   }






  // }
  async alertTorefresh(ev) {
    console.log(ev);

    let result = false;
    const alert = await this.alertController.create({
      header: 'עדכון השינוי',
      message: 'בשלב זה צריך לכבות את האפליקציה ולהדליק על מנת שהשינוי יכנס לתוקף',
      buttons: [
        {
          text: 'כן הבנתי',
          handler: () => {
            this.shopS.calendar.currentDate = new Date();
            if (ev) {
              localStorage.setItem("calendarTimeInterval", ev);
              this.shopS.calendarIsDisplay = false;
              this.shopS.calendar.timeInterval = ev;
              setTimeout(() => {
                this.shopS.calendarIsDisplay = true;
              }, 10);
            }
            // localStorage.setItem("calendarTimeInterval",event.detail.value);
            // this.shopS.calendar.timeInterval = event.detail.value;
          }
        }
      ]
    });

    await alert.present();
    return result;
  }

  deleteData() {

  }


  openExplainModal(swichClass) {
    this.shopS.modalS.presentModalKnowoge(swichClass);
  }

  calChange(event, kind) {
    this.shopS.closeBusinessMeetingsSub();
    this.shopS.log(event, 'calendar_change_state');
    console.log(event);
    this.shopS.calendar.currentDate = new Date();
    this.shopS.dateForView.forEach(x => {
      x.active = false;
    });
    this.shopS.dateForView[0].active = true;

    localStorage.setItem("calendarMode", event);
    setTimeout(() => {
      this.shopS.calendar.mode = event;
      this.shopS.setCurrentDay(new Date());
      this.shopS.openBusinessMeetingsSub();
    }, 100);
  }


  //// corona present modal
  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  async presentModal() {
    const modal = await this.modalController.create({
      component: CoronavirusComponent,
      mode: 'ios',
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
  }


  historyCheckPremium() {
    if (this.shopS.shopData.Setting.Premium.History) {
      this.openAdminHistoryModal();
    } else {
      this.showPremium();
    }
  }


  async openHolidaysModal() {

    const modal = await this.modalController.create({
      component: HolidayListComponent,
      swipeToClose: false,
      mode: 'ios',
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalForPayments modalGreyBG modal-half-hight',
      componentProps: {

      },

      // Get the top-most ion-modal
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    console.log('modal dissmiss data',data)
    if(data == 'switchDay'){
      setTimeout(() => {
        this.closeModal()
      }, 200);
    }
  }
  // showHistory() {
  //   this.openAdminHistoryModal();

  // }

  async openAdminHistoryModal() {

    const modal = await this.modalController.create({
      component: AdminHistoryComponent,
      swipeToClose: false,
      mode: 'ios',
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalForPayments modalGreyBG modal-half-hight',
      componentProps: {

      },

      // Get the top-most ion-modal
    });

    await modal.present();
  }

  newLine(event, u) {
    event.stopPropagation();
    // console.log('newLine-u',u); 
    this.shopS.calendarSettingFLAG = false;

  }


}
