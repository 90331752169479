import { NetworkStatusAngularService } from 'network-status-angular';

import { Http } from '@angular/http';
import { LoadingService } from 'src/providers/loading.service';
import { NavController, AlertController, ModalController, MenuController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { ChangeUserModal } from '../modals/changeUser/changeUser.modal';

import { AngularFireAnalytics } from '@angular/fire/analytics';


import { userService } from '../ShopService/user.service';
import { ShopService } from '../ShopService/shop.service';
import * as Rx from "rxjs";
// import { WalletServiceService } from 'src/app/wallet/wallet-service/wallet-service.service';



@Injectable()
export class AuthService {
  // isconnect = new BehaviorSubject(false);
  isconnect = new Rx.BehaviorSubject(false);
  // FirestTime = true;
  // isLogin = false;
  // uid = "";
  // LoginSocial = false;
  config = {
    userUpdate: ""
  }

  constructor(
    private nav: NavController,
    private router: Router,
    private _http: Http,
    private loadingService: LoadingService,
    private MenuController: MenuController,
    private modalController: ModalController,
    private afAuth: AngularFireAuth,
    private networkStatusAngularService: NetworkStatusAngularService,
    // public walletS: WalletServiceService,
    private us: userService,
    public shopS: ShopService,
    public alertController: AlertController,
    private afDb: AngularFireDatabase,
    private AngularFireAnalytics: AngularFireAnalytics,
    private localStorageService: LocalStorageService) {
    //console.log('Hello AuthService Provider');
    this.getConfigData();
    this.ifUserLogin();

    // this.afAuth.authState.subscribe((result) => {
    //   console.log("ASDASDSADASD", result);

    // })
  }
  login(email, password) {
    // this.isLogin = true;
    // localStorage.setItem('u', 'true');

    return this.afAuth.auth.signInWithEmailAndPassword(
      email,
      password
    );
  }

  logout() {
    // console.log('AAWDAWD'); 
    this.shopS.log(this.us.key, 'logout');
    localStorage.setItem('type', 'user');
    localStorage.setItem('userdata', '');
    // localStorage.setItem('u', 'false');
    // this.USERliveDataFlow.unsubscribe();
    // if (this.BARBERliveDataFlow) {
    //   this.BARBERliveDataFlow.unsubscribe();
    // }


    // closing cal sub 
    this.shopS.close_business_meetings_sub();



    this.shopS.closeUserMeetingsSub();
    this.shopS.CloseLiveSub(this.shopS.shopData.Setting.BarberID);
    // this.shopS.closeSettingSub();
    this.localStorageService.cleanLocalStorage();
    console.log("this.shopS.changeButtonState(-1);")
    this.shopS.changeButtonState(-1);



    this.us.resetUser();
    this.shopS.resetShopData();
    this.shopS.businessArray = [];

    this.afAuth.auth.signOut().then((result) => {
      // console.log(result);
      this.shopS.AdminFLAG = false;
      this.auth_state.next('not-auth');
      // this.isLogin = false;
      this.shopS.route_to('login');


      // this.nav.navigateRoot(['socialLogin'])
    });

  }


  // async logOutForLogin() {
  //   // console.log('AAWDAWD');
  //   localStorage.setItem('userdata', '');
  //   localStorage.setItem('u', 'false');

  //   //  if(this.shopS.AdminFLAG){
  //   this.shopS.CloseLiveSub(this.shopS.shopData.Setting.BarberID);
  //   // }
  //   this.shopS.AdminFLAG = false;

  //   // console.log(result);
  //   // this.isLogin = false;
  //   // this.USERliveDataFlow.unsubscribe();
  //   // if (this.BARBERliveDataFlow) {
  //   //   this.BARBERliveDataFlow.unsubscribe();
  //   // }
  //   this.localStorageService.cleanLocalStorage();
  //   // console.log("his.shopS.changeButtonState(-1);")
  //   // this.shopS.changeButtonState(-1);
  //   this.shopS.eventArray = [];




  //   this.ifUserLogin();

  //   // this.nav.navigateRoot(['socialLogin'])
  // }

  USERliveDataFlow: any;
  // BARBERliveDataFlow: any;
  user_obj: any = null;
  is_user_phone_needed = false;
  signin_redirect = true;
  auth_state = new Rx.BehaviorSubject('');


  ifUserLogin() {

    console.log("---------- ------------- -------------- userlogin ------------ ----------")
    this.afAuth.authState.subscribe((result) => {
      console.log("if user login +-= ", result);


      if (firebase.auth().currentUser) {
        let userdata: any = firebase.auth().currentUser.providerData;
        console.log('ddddd:', userdata);
        this.AngularFireAnalytics.setUserId(result.uid)
        this.AngularFireAnalytics.setUserProperties(userdata)

        firebase.auth().currentUser.getIdToken().then(token => {
          console.log('TOKEN', token)
          this.us.userToken = token;
        });


      }

      setTimeout(() => {
        this.loadingService.dismiss();
      }, 2000);

      // console.log(result);
      // this.localStorageService

      // console.log('resultAuth', JSON.stringify(result));

      if (result) {
        console.log('auth_state', this.auth_state.value);

        this.auth_state.next('auth');
        this.shopS.userData = result;
        var found = result.providerData.some(el => el.providerId === 'phone');
        console.log('phone-auth  found: ', found);
        if (!found) {
          this.is_user_phone_needed = true;
        }

        // var res: any = result;
        // if (result.uid) {
        //   this.uid = result.uid;
        // }

        //  this.authState.next(true);
        // localStorage.setItem('u', 'true');
        // this.USERliveDataFlow = this.afDb.object('/Users/' + result.uid).snapshotChanges().subscribe((data: any) => {
        this.getUserData(result.uid);
      } else {
        console.log('auth_state', this.auth_state.value);
        this.auth_state.next('not-auth');
        // if (this.LoginSocial == false) {
        console.log(this.router.url);
        // localStorage.setItem('u', 'false');
        if (!this.router.url.includes('/login')) {
          this.logout();
        }
        // this.shopS.route_to('login');
        // if (this.shopS.check_if_b_url()) {
        // }
        // this.isLogin = false;
        /// this.authState.next(false);
        // }

      }

      //    this.authState.next(true);


    }, (error) => {

      console.log(error);
      // this.router.navigate(['socialLogin']);
      // this.isLogin = false;

    });
    // if (this.afAuth.auth.currentUser) {
    //   this.router.navigate(['tabs']);
    // } else {
    //   // this.router.navigate(['login']);
    //   this.router.navigate(['socialLogin']);
    // }
    // return this.afAuth.auth.currentUser;
  }

  checkType(user) {
    console.log("CHECKTYPE");

    let self = this;
    let type = localStorage.getItem('type') ? localStorage.getItem('type') : 'user';
    this.shopS.resetData();
    this.shopS.CloseLiveSub(this.shopS.shopData.Setting.BarberID);
    this.loadingService.dismiss();
    this.shopS.dismissModalController();
    

    // this.dismiss_swich_modal();
    console.log(user);

    if (type == 'admin') {
      this.loginFlag = 0;
      this.checkAdminId(user.shopOwnerKey);
    } else {
      // if (!user.BarberID) {
      this.shopS.AdminFLAG = false;
      if (user.registrationdate) {
        this.checkBarberId(user);
        // self.router.navigate(['tabs/home'], { replaceUrl: true });
        this.removSplesh();
        // this.dismiss_swich_modal();
      } else {
        if (user.fname) {
          this.us.superNew = false;
          if(user.phone){
            let phone = user.phone.replaceAll('+972', '0');
            this.shopS.reloadUserNew(phone);
          }


          if (this.shopS.params && this.shopS.params.type) {
            if (this.shopS.params.type == 'user') {
              localStorage.setItem('type', 'user');
              this.us.registrationdate();
              this.router.navigate(['tabs/home'], { replaceUrl: true });
            } else if (this.shopS.params.type == 'business') {
              localStorage.setItem('type', 'admin');
              this.router.navigate(['admin-register'], { replaceUrl: true })
            } else {
              self.router.navigate(['addBusiness'], { replaceUrl: true });
            }
          } else {
            if (!this.shopS.external_login) {
              self.router.navigate(['addBusiness'], { replaceUrl: true });
            } else {
              console.log("EXTERNAL");
              this.us.registrationdate();
              localStorage.setItem('type', 'user');
              // this.router.navigate(['tabs/home'], { replaceUrl: true });
              // this.modalController.dismiss();
            }
          }
          this.loadingService.dismiss();
        } else {
          this.shopS.noUserModal = true;
        }
        // }
      }
      // else {
      //   // this.checkBarberId(user);
      //   this.loginFlag = 2;
      //   this.shopS.route_to('tabs/home')
      //   // this.RoutingPag(user);
      //   this.us.superNew = true;
      //   this.removSplesh();
      // }
    }
  }

  getUserData(uid) {
    this.afDb.database.ref('/Users/' + uid).once('value').then((data: any) => {
      // console.log(data.payload.val());

      // this.uid = result.uid;
      // console.log('login power auth server', JSON.stringify(data));

      // const LOCALtype = localStorage.getItem('type');
      // const LOCALBarberID = localStorage.getItem('BarberID');
      // console.log('LOCALtype',LOCALtype);
      // console.log('LOCALBarberID',LOCALBarberID);
      let user = data.val();
      if (user) {
        user.key = data.key;
        this.shopS.noUserModal = false;
        console.log(user);
        console.log(data.key);
        // console.log("---------------------------m---", m);

        // if (user.BarberID) {
        //   this.shopS.shopID = user.BarberID;
        // }
        // if (m.isValid != "Verify" && m.NoVerify != "yes") {
        //   this.shopS.isVerify();
        // }

        // if (user) {
        this.checkUserStatus(user);

        // if (!user.shopOwnerKey && user.shopOwner) {
        //   user.shopOwnerKey = user.shopOwner;
        // }

        // }
        this.us.saveUserData(user, data.key);

        this.shopS.getOneSignalID(uid);
        this.MenuController.enable(true);
        console.log("m.shopOwnerKey ------ >", user.shopOwnerKey)
        // if ((m.shopOwner)) {
        //   localStorage.setItem('type', "admin");
        //   const LOCALtype = 'admin';
        // }


        this.checkType(user);

        // if (user.shopOwner) {
        //   // localStorage.setItem('type', "admin");

        //   // console.log('shopOwner',m.shopOwner);
        //   // console.log('LOCALtype==admin',m.shopOwner);
        //   // this.collectAndSaveA(user);
        //   this.loginFlag = 0;
        //   this.shopS.AdminFLAG = true;
        //   // this.localStorageService.saveToLocalStorage('BarberID', user.shopOwner.id);

        //   this.checkAdminId(user.shopOwner);
        //   // this.router.navigate(['tabsAdmin/calendar'], {replaceUrl: true});
        //   // self.USERliveDataFlow.unsubscribe();

        // } else {
        //   // console.log("data.barberI,",data.BarberID,m);
        //   // console.log('m1',m);

        //   if (!user.BarberID) {
        //     if (user.registrationdate) {
        //       this.isconnect.next(true);
        //       self.router.navigate(['tabs/home'], { replaceUrl: true });
        //       // self.USERliveDataFlow.unsubscribe();
        //       this.removSplesh();
        //       this.dismiss_swich_modal();
        //     } else {
        //       if (user.fname) {
        //         this.us.superNew = false;
        //         let phone = user.phone.replaceAll('+972', '0');
        //         this.shopS.reloadUserNew(phone);


        //         if (this.shopS.params && this.shopS.params.type) {
        //           if (this.shopS.params.type == 'user') {
        //             this.us.registrationdate();
        //             this.router.navigate(['tabs/home'], { replaceUrl: true });
        //           } else if (this.shopS.params.type == 'business') {
        //             this.router.navigate(['admin-register'], { replaceUrl: true })
        //           } else {
        //             self.router.navigate(['addBusiness'], { replaceUrl: true });
        //           }
        //         } else {
        //           self.router.navigate(['addBusiness'], { replaceUrl: true });
        //         }
        //         this.loadingService.dismiss();
        //       } else {
        //         this.shopS.noUserModal = true;
        //       }
        //     }
        //   } else {
        //     // this.collectAndSave(user);
        //     // this.localStorageService.saveToLocalStorage('BarberID', user.BarberID);
        //     // this.localStorageService.saveToLocalStorage('type', 'user');
        //     this.checkBarberId(user);
        //     this.RoutingPag(user);
        //     this.us.superNew = true;
        //     this.removSplesh();
        //   }


        // }

      }//end of if payload
      else {
        this.shopS.route_to('login');
        this.shopS.noUserModal = true;
      }



    }, (error: any) => {
      // alert('err');
      if (error) {
        console.log('Error in auth.subscribe : ');
        console.log(error);
      } else {
        console.log('No Error detected in auth.subscribe');
      }

    });
  }


  // isAuthenticated() {
  //   let auth = localStorage.getItem('u');
  //   console.log('d', auth);
  //   if (auth) {
  //     return auth;
  //   } else {
  //     return true;
  //   }
  // }

  async alert_register_on_desktop() {
    const alert = await this.alertController.create({
      header: 'חייב להרשם תחילה דרך האפליקציה',
      subHeader: '',
      message: 'על מנת להנות מתהליך הרשמה נכון.',
      buttons: ['הבנתי']
    });

    await alert.present();

  }


  async openChangeUserModal() {
    const modal = await this.modalController.create({
      component: ChangeUserModal,
      backdropDismiss: false,
      mode: 'ios',
      cssClass: 'my-custom-modal-css',
    });
    return await modal.present();
  }


  checkUserStatus(checkUser: any) {

    // console.log("user data", this.us.user, checkUser.fname, checkUser.lname, checkUser.phone)
    // if (((!checkUser.fname) || (!checkUser.lname) || (!checkUser.phone)) && (checkUser.email)) {
    if (((!checkUser.fname) || (!checkUser.lname)) && (checkUser.email)) {

      // console.log("need to updateeeeeee");
      this.us.emptyUser = true;
      this.openChangeUserModal();


    } else {
      //  console.log("ok ok ok ok ok ok ok ok ok")
    }
  }

  removSplesh() {
    setTimeout(() => {
      if (this.shopS.loaderoff) {
        this.shopS.log(this.us.user.key, 'removSplesh')
        this.shopS.loaderoff = false;
      }
    }, 11000);
  }

  // configUserData(credential, aToken) {
  //   //console.log(credential);
  //   const user = {
  //     accessToken: aToken,
  //     name: credential.user.displayName,
  //     email: credential.user.email,
  //     phone: credential.user.phoneNumber,
  //     image: credential.user.photoURL
  //   };
  //   return user;
  // }

  // collectAndSaveA(data) {
  //   let finalData = {
  //     shopOwner: { id: '' },
  //     BarberID: '',
  //     uid: this.shopS.userData.uid
  //   };
  //   for (let key in data) {
  //     if (key === 'shopOwner') {
  //       finalData.shopOwner.id = data[key];
  //     }
  //   }
  //   // finalData.uid = this.shopS.userData.uid;
  //   // console.log(finalData);
  //   this.localStorageService.saveToLocalStorage('user', finalData);
  // }

  // collectAndSave(data) {
  //   // console.log('collectAndSave==data',data);
  //   let finalData = {
  //     BarberID: '',
  //     uid: ''
  //   };
  //   for (let key in data) {
  //     if (key === 'BarberID') {
  //       finalData.BarberID = data[key];
  //     }
  //   }
  //   //finalData.uid = this.uid;
  //   // console.log(finalData);
  //   this.localStorageService.saveToLocalStorage('user', finalData);
  // }


  loginFlag = 0;
  checkBarberId(data: any) {
    this.shopS.route_to('tabs/home');
    this.isconnect.next(true);
    this.shopS.getAllBarbers();

    if (data.BarberID) {
      this.shopS.getShopDetilsForUser(data.BarberID).once('value').then(
        // 
        (res: any) => {
          let shopD = res.val();
          let dat: any = {
            Setting: shopD
          }
          console.log(shopD);
          this.shopS.shopData = dat;
          this.shopS.shopData['BarberId'] = data.BarberID;
          this.shopS.shopID = data.BarberID;
          this.shopS.saveShopDetils(dat);
          console.log('ddddddaataaaa', data);
          this.shopS.openLiveSub(data.BarberID, 'user');
          this.shopS.openSettingSub(data.BarberID);
          this.shopS.getBusinessDataForUser(data.BarberID);
          this.loginFlag = 1;
          console.log("111");
          console.log('ISCONNECT1');

          // this.isconnect.next(true);
        }, (error) => {
            console.log(error);
        });
    } else {
      this.loginFlag = 2;
    }
  }


  dismiss_swich_modal() {
    console.log("dismiss_swich_modal", this.shopS.Modal_Swich);
    //this.shopS.Modal_somethingwentwrong();
    if (this.shopS.Modal_Swich) {
      setTimeout(() => {
        this.shopS.Modal_Swich.dismiss();
      }, 1500);

    }
  }


  changeToAdmin() {
    this.shopS.menuTogglle('close');
    localStorage.setItem('type', 'admin');


    // var data = {
    //   userData: this.us.user,
    // }

    // console.log(data);
    // var newSend = this.shopS.cryptoService.Encrypt(data);

    // console.log(newSend);
    //   this.shopS.LoadingService.presentforever();

    this.loadingService.dismiss();
    this.checkType(this.us.user);





    // this._http.post('' + this.shopS.serverDev + '/changeToAdmin', newSend).subscribe(res => {
    //   this.loadingService.dismiss();
    //   if (res['_body'] == 'ok') {
    //     // setTimeout(() => {
    //     // this.logOutForLogin();
    //     this.checkType(this.us.user);

    //     //   this.dismiss_swich_modal();
    //     // this.shopS.LoadingService.dismiss();
    //     // }, 500);

    //   } else if (res['_body'] == 'reg') {
    //     this.shopS.newadminfromuser = true;
    //     //    this.shopS.LoadingService.dismiss();;
    //     //   this.dismiss_swich_modal();
    //     this.router.navigate(['admin-register'], { replaceUrl: true })
    //   }
    //   // else {
    //   //        this.shopS.LoadingService.dismiss();;
    //   //  this.dismiss_swich_modal();
    //   // }




    //   this.shopS.menuTogglle('close')

    // });




  }


  changeToUser() {
    this.shopS.menuTogglle('close');
    // localStorage.setItem('type', 'user');
    // var data = {
    //   userData: this.us.user.key,
    //   BarberId: this.shopS.shopData.Setting.BarberID,
    // }

    // console.log(data);
    // var newSend = this.shopS.cryptoService.Encrypt(data);
    // console.log(newSend);
    // this.shopS.LoadingService.presentforever();


    this.shopS.close_business_meetings_sub();
    localStorage.setItem('type', 'user');
    this.checkType(this.us.user);
    // this.shopS.menuTogglle('close');



    // this._http.post('' + this.shopS.serverDev + '/changeToUser/', newSend).subscribe(res => {
    //   console.log(res)
    //   if (res['_body'] == 'ok') {
    //     // setTimeout(() => {
    //     localStorage.setItem('type', 'user');
    //     // closing cal sub 
    //     this.shopS.close_business_meetings_sub();


    //     // this.logOutForLogin();
    //     this.checkType(this.us.user);
    //     //   this.dismiss_swich_modal();
    //     // this.shopS.LoadingService.dismiss();
    //     // }, 500);
    //   } else {
    //     if (res['_body'] == 'notsame') {
    //     }
    //   }
    //   this.shopS.menuTogglle('close');
    // });



  }




  // RoutingPag(ad) {
  //   if (ad.BarberID) {
  //     //  console.log('if ad.BarberID',ad.BarberID);
  //   } else {
  //     //('else ad.BarberID',ad.BarberID);
  //     // this.router.navigate(['addBusiness'], { replaceUrl: true });
  //     this.shopS.route_to('addBusiness');

  //   }
  // }


  removebuis() {
    //("this.us.user",this.us.user);
    var newUser: any = this.us.user;
    newUser.BarberID = "";
    newUser.shopOwnerKey = "";

    this.us.RemoveBarber(newUser).then(res => {
      // this.logOutForLogin();
    })
  }

  businessLoaded = new Rx.BehaviorSubject(false);

  checkAdminId(data: any) {
    console.log("checkAdminId", data);
    if (data && data.id) {
      // this.router.navigate(['tabsAdmin/clients'], { replaceUrl: true });
      console.log("222");

      // this.shopS.route_to('tabsAdmin/clients');
      console.log('ISCONNECT2');

      this.shopS.getFilteredShopDetils(data.id, (shopD) => {
        console.log(shopD, 'shopD');
        if (shopD == null) {
          this.removebuis();
        } else {
          console.log("22222");
          this.shopS.shopData = shopD;
          this.shopS.shopData['BarberId'] = data.id;
          this.shopS.shopID = data.id;
          this.businessLoaded.next(true);
          this.shopS.saveShopDetils(shopD);
          this.shopS.openLiveSub(data.id, 'admin');
          this.isconnect.next(true);

          if (this.shopS.shopData.Setting.buisStatus == "new") {
            this.shopS.route_to('tutorial');
          } else {
            this.shopS.route_to('tabsAdmin/clients');
          }
        }
        this.shopS.AdminFLAG = true;

        // if (this.loginFlag != 1) {
        //   this.loginFlag = 1;
        //   // localStorage.setItem('BarberID', data.id);
        //   // localStorage.setItem('type', 'admin');
        //   // setTimeout(() => {
        //   if (this.shopS.shopData.Setting.buisStatus == "new") {
        //     // this.router.navigate(['tutorial'], { replaceUrl: true });
        //     // if (this.check_if_b_url()) {
        //     this.shopS.route_to('tutorial');
        //     // }

        //   } 
        //   // else {
        //   //   console.log("!#%!#%!^!FFFFFFFF");


        //   //   // if (this.check_if_b_url()) {
        //   //   console.log("333");

        //   //   this.shopS.route_to('tabsAdmin/clients');
        //   //   // }
        //   //   // this.shopS.route_to('tabsAdmin/clients');

        //   //   // this.router.navigate(['tabsAdmin/clients'], { replaceUrl: true });
        //   // }
        //   this.shopS.userStatus();
        //   this.loadingService.dismiss();
        //   this.dismiss_swich_modal();
        //   // }, 2000);
        // }
      });


      // var getShopDetilsSUB = this.shopS.getShopDetils(data.id).valueChanges().subscribe(
      //   (shopD: any) => {
      //     console.log(shopD, 'shopD');
      //     if (shopD == null) {
      //       this.removebuis();
      //       return
      //     }
      //     console.log("22222");
      //     this.shopS.shopData = shopD;
      //     this.shopS.shopData['BarberId'] = data.id;
      //     this.businessLoaded.next(true);
      //     this.shopS.saveShopDetils(shopD);
      //     this.shopS.openLiveSub(data.id, 'admin')
      //     getShopDetilsSUB.unsubscribe();
      //     if (this.loginFlag != 1) {
      //       this.loginFlag = 1;
      //       localStorage.setItem('BarberID', data.id);
      //       localStorage.setItem('type', 'admin');
      //       setTimeout(() => {
      //         if (this.shopS.shopData.Setting.buisStatus == "new") {
      //           this.router.navigate(['tutorial'], { replaceUrl: true });
      //         } else {
      //           console.log("!#%!#%!^!FFFFFFFF");
      //           this.router.navigate(['tabsAdmin/clients'], { replaceUrl: true });
      //         }
      //         this.shopS.userStatus();
      //         this.loadingService.dismiss();
      //         this.dismiss_swich_modal();
      //       }, 2000);
      //     }
      //   }, (error) => {
      //     console.log(error);
      //   });
    } else {
      this.loginFlag = 2;
      this.router.navigate(['admin-register'], { replaceUrl: true });
      this.shopS.AdminFLAG = true;
    }
  }


  getConfigData() {

    var getconfig = this.afDb.object('/template/').valueChanges().subscribe(element => {
      var el: any = element;
      // console.log('element',element);
      this.config = el;
      getconfig.unsubscribe();
    });

  }


}
