import { Component, OnInit, OnDestroy } from '@angular/core';
import { loginPage } from '../../pages/login/login';
import { userService } from '../../ShopService/user.service';
import { ShopService } from '../../ShopService/shop.service'

import { NavController, MenuController } from '@ionic/angular';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'page-home',
  templateUrl: 'home.html'
})
export class HomePage implements OnInit, OnDestroy {
  uID;
  userName;
  shopData;
  listOfmeet;
  bbID;
  logo;
  userUid;
  listOfmeet2 = [];



  constructor(
    public navCtrl: NavController,
    private us: userService,
    private menu: MenuController,
    public shopS: ShopService,
    private afAuth: AngularFireDatabase) {

  }
  ngOnInit() {
    console.log('Home Page');

    this.userName = this.us.user.fName;
    this.logo = this.shopS.Setting.logo;

    this.loadMeets();



    // this.uID = this.af.database.object('/Users/' + this.us.key).take(1);
    // this.userUid = this.us.key;
    // this.userName = this.uID;
    // this.uID
    //   .subscribe(x => {
    //     this.bbID = x.BarberID;
    //     if (x.meetings) {
    //       this.listOfmeet = this.af.database.list('/Users/' + this.us.key + "/meetings/" + this.bbID,
    //         { preserveSnapshot: true })
    //         .subscribe(snapshots => {
    //           snapshots.forEach(snapshot => {

    //             this.listOfmeet2.push(snapshot.key, snapshot.val())
    //             console.log(this.listOfmeet2[0]);
    //           })


    //         });

    //     }
    //     console.log(this.listOfmeet)

    //     this.shopData = this.af.database.object('/BarberShop/' + this.bbID).take(1);

    //   })



  }

  doClick() {
    console.log('do Click');
  }

  deletMeet(timeM, dayM, key,i) {

    console.log('/BarberShop/' + this.shopS.shopID + "/Meetings/" + dayM + "/" + timeM)
    console.log('/Users/' + this.us.key + "/meetings/" + this.shopS.shopID + "/" + key)
    this.us.deletMeet(this.shopS.shopID, key);
    this.shopS.deletMeet(timeM, dayM);
    this.listOfmeet2.splice(i,1);
    //this.loadMeets();


  }


  loadMeets() {

    if (this.listOfmeet) {

      this.listOfmeet = "";
      this.listOfmeet2 = [];
    }



    // this.listOfmeet = this.us.getUserMeetByShop(this.shopS.shopID).valuechange()
    //   .subscribe(snapshots => {
    //     snapshots.forEach(snapshot => {
    //       let nap = snapshot.payload.val();
    //       // nap.key = snapshot.key;
    //       this.listOfmeet2.push(nap);
    //     })

    //  //  this.listOfmeet.unsubscribe();
    //   },
    //     error => {
    //         // this is not called. Ok!
    //     },
    //     () => { // when complete
    //      //  this.listOfmeet.unsubscribe();
    //     })
      
      
  }

  ngOnDestroy() {
if(this.listOfmeet){
  this.listOfmeet.unsubscribe();

}


  }

}
