import { AlertExample } from 'src/providers/alert.service';
import { OrderButtonComponent } from '../../components/order-button/order-button.component';
import { meetService } from '../../ShopService/meetS.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { userService } from '../../ShopService/user.service';
import { ShopService } from '../../ShopService/shop.service';
import { Router } from '@angular/router';

import { AuthService } from '../../providers/auth-service';
import { AlertController } from '@ionic/angular';


import { NavController } from '@ionic/angular';


@Component({
  selector: 'app-about-clinet',
  templateUrl: './about-clinet.component.html',
  styleUrls: ['./about-clinet.component.scss'],
})
export class AboutClinetComponent implements OnInit {
  meetsType;
  BarberID;
  allevents = [];
  meetType = "meet";
  Mtime = 0;
  eventsDATA: any = [];
  selectedArray = [];
  pages: Array<{ title: string, component: any }>;
  monthsList = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];

  constructor(
    public navCtrl: NavController,
    private us: userService,
    private alertExample: AlertExample,
    private orderButtonComponent: OrderButtonComponent,
    public shopS: ShopService,
    private router: Router,
    private authService: AuthService,
    private alertController: AlertController,

    public ms: meetService) {
  }

  ionViewDidEnter() {
    this.selectedArray = [];
    this.Mtime = 0;


    var self = this;
    this.allevents = [];
    var loadEvents = this.shopS.loadEvents().once('value', function (res) {


      var eventsDatas = res.val();
      console.log("events", eventsDatas);

      self.eventsDATA = self.shopS.json2array(eventsDatas)
      console.log("this.eventsDATA", self.eventsDATA);



      var beforeSort = self.shopS.json2array(res.val());

      beforeSort.sort(self.compare);




      //   this.historyMeet=beforeSort.reverse();
      var afterall = self.createMonth(beforeSort);

      //  console.log("afterall",afterall)
      //  this.allevents=afterall;


      self.markEvent();


    })







    // if (this.shopS.shopData.MeetingType) {
    //   // has meetings
    //   this.meetsType = this.shopS.json2array(this.shopS.shopData.MeetingType);
    //   for (var i = 0; i < this.meetsType.length; i++) {
    //     this.meetsType[i].active = false;
    //   }
    // } else {
    // 



    console.log('none meet', this.shopS.shopData)
    // const getShopMeetingTypeForUser = this.shopS.getShopMeetingTypeForUser(this.shopS.shopData.Setting.BarberID).valueChanges().subscribe(bizData => {

    //   console.log('bizData', bizData);
    //   this.meetsType = bizData;
    //   this.shopS.shopData.MeetingType = bizData;
    //   for (var i = 0; i < this.meetsType.length; i++) {
    //     this.meetsType[i].active = false;
    //   }

    //   // console.log('meetsType',this.meetsType);
    //   // console.log('this.meetsType.length',this.meetsType.length);
    //   getShopMeetingTypeForUser.unsubscribe()
    // });



    // }

  }


  markEvent() {
    this.us.user, this.allevents;

    this.allevents.forEach(res => {
      res.meets.forEach(element => {
        if (element.uId && typeof element.uId != 'string') {
          element.uId.forEach(element2 => {
            if (element2.uId == this.us.user.key) {

              console.log("change stat to 6 ")
              element.eventStatus = 6;
            }
          });
        }
      });
    })

  }
  eventToNotActive() {
    this.allevents.forEach(x => {
      console.log(x);
      x.meets.forEach(element => {
        element.active = false;
      });
    })
  }

  // selectEvent(event, meet) {

  //   if (meet.eventStatus == 6) {
  //     this.alertExample.aleryByTextPayAttention("כבר נרשמת למפגש זה");
  //     return;
  //   }

  //   console.log("meet.eventCloseBefore", meet.CloseBefore)

  //   if (meet.CloseBefore && this.shopS.testDateInDayes(meet.CloseBefore, meet.endTime)) {
  //     this.alertExample.aleryByText("מועד ההרשמה למפגש עבר, צור קשר עם העסק");
  //     return;
  //   }

  //   if (this.shopS.testDate(meet.endTime)) {
  //     this.alertExample.aleryByText("שעת המפגש עברה");
  //     return;
  //   }

  //   this.shopS.userDataSelectedEvent = this.us.user;
  //   console.log("alll event", event, this.allevents);

  //   if (meet.CatchPeople >= meet.totalPeople) {
  //     this.alertExample.aleryByText("נגמרו המקומות למפגש זה ")
  //   } else {
  //     this.eventToNotActive();
  //     this.shopS.eventForBuis.resetCLass();
  //     this.shopS.eventForBuis.importEventData(meet);
  //     this.shopS.eventForBuis.showClass();
  //     meet.active = true;
  //     this.shopS.changeButtonState(5);
  //   }
  // }

  createMonth(dataMeets) {
    var dataMeet = "";
    var arrayOfMeet = [];
    var objec = {
      "dates": "",
      "meets": []
    }

    console.log("------->dataMeets", dataMeets);
    var r = 1;
    var relemntscount = dataMeets.length;

    dataMeets.forEach(element2 => {

      var i = 1;
      var elmLenth = Object.keys(element2).length - 1;
      Object.keys(element2).forEach(key => {



        if (typeof element2[key] != "string") {


          var xs = element2[key];





          console.log("elemnts length ", i, elmLenth)


          var nameDates = this.get_month_from_key(element2[key].meetDate) + " ," + this.get_year_from_key(element2[key].meetDate)
          console.log("element", xs)


          if (objec.dates == nameDates) {

            objec.meets.push(xs);


            console.log("samessss");
            if (i == elmLenth && r == relemntscount) {
              this.allevents.push(objec);
            }

          } else {
            if (objec.dates) {
              this.allevents.push(objec);
            }

            objec = {
              "dates": nameDates,
              "meets": []
            }


            objec.meets.push(xs);

            if (i == elmLenth && r == relemntscount) {
              this.allevents.push(objec);
            }
          }

        }

        i++;
      });


      // var nameOF=this.get_month_from_key(element.meetDate)+" ,"+ this.get_year_from_key(element.meetDate);
      // this.historyMeet[nameOF].push(element);
      r++;
    });

    console.log("historyMeet", this.allevents);

    return objec;

  }

  get_year_from_key(key) {
    // console.log(key)
    let s = key.split('-');
    return s[0];
  }

  get_month_from_key(key) {
    //console.log(key)
    let s = key.split('-');
    return this.monthsList[parseInt(s[1]) - 1];
  }



  changeToEventToggel(data) {
    this.shopS.changeButtonState(1);
    this.eventToNotActive();

    this.meetType = data.detail.value;
  }
  changeToEvent(data) {
    this.shopS.changeButtonState(1);
    this.eventToNotActive();

    this.meetType = data;
  }
  ngOnInit() {
    this.BarberID = this.us.user.BarberID;
    // if (this.shopS.external_login) {
    //   var meetFor = JSON.parse(JSON.stringify(x));
    //   let meetTime = parseInt(element.meetTime);
    //   let meetName = element.name;


    //   var newmeet = this.createNewMeet(meetFor, meetTime.toString(), meetName);
    //   this.shopS.meetForBuis.importMeetType(newmeet);
    //   this.ms.setMeetType(newmeet);
    //   this.shopS.currentMeet.meetType = newmeet;
    //   this.shopS.changeButtonState(2);
    // }
  }


  compare(a, b) {
    // Use toUpperCase() to ignore character casing

    // var d1=this.convertDate(a.startDate);
    // var d2=this.convertDate(b.startDate);

    const bandA = new Date(a.startDate);
    const bandB = new Date(b.startDate);

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  convertDate(inputFormat) {

    var today: any = new Date(inputFormat);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '-' + mm + '-' + yyyy;

  }


  goBack() {
    console.log('Go', this.shopS.userOrder.stage);
    //  this.shopS.userOrder.stage = 0;
    this.meetType = 'meet';
    this.shopS.changeButtonState(0);
    this.shopS.meetService.meetToChange = '';

    this.clearSeletion();
    this.router.navigate(['tabs/home']);
  }

  ionViewCanLeave() {
    this.allevents = [];
    return false;

  }

  logout() {
    this.authService.logout();
  }

  checkpay(x) {
    console.log(this.shopS.currentMeet.meetType, x);
    if (this.shopS.currentMeet.meetType && this.shopS.currentMeet.meetType.active == true) {
      if (this.shopS.currentMeet.meetType.name != 'דוגמא' && x.active == false) {
        if (this.shopS.currentMeet.meetType.payment_way) {
          if (this.shopS.currentMeet.meetType.payment_way != 'none') {
            this.showAlert(x);
          } else {
            if (x.payment_way) {
              if (x.payment_way != 'none') {
                console.log('diff payment_way!!', this.shopS.currentMeet.meetType, x);
                this.showAlert(x);
              } else {
                this.PushMeetType(x);
              }
            } else {
              this.PushMeetType(x);
            }
          }
        } else {
          if (x.payment_way) {
            if (x.payment_way != 'none') {
              console.log('diff payment_way!!', this.shopS.currentMeet.meetType, x);
              this.showAlert(x);
            } else {
              this.PushMeetType(x);
            }
          } else {
            this.PushMeetType(x);
          }
        }
      } else {
        this.PushMeetType(x);
      }
    } else {
      this.PushMeetType(x);
    }

  }

  showAlert(x) {

    var textT = '<span class="aaaa" style="">';
    var text = 'לא ניתן לבחור סוגי שירות עם שיטות תשלום שונות, יש להזמין את השירותים בתורים נפרדים ';

    console.log('textT', textT, text);
    textT += text + '</span>';
    this.alertModal(textT);
    this.shopS.log({meetType1:this.shopS.currentMeet.meetType,meetType2:x}, 'diff payment_way');
  }

  async alertModal(textT) {

    const alert = await this.alertController.create({
      header: 'בחירת שירות',
      message: textT,
      buttons: [
        {
          text: 'הבנתי',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cancel Cancel: Cancel');
            return false;
          }
        },
        // {
        //   text: 'אישור ושמירה',
        //   handler: () => {

        //     console.log('Okay: Okay');
        //     //confirm order 

        //   }
        // }
      ]
    });
    await alert.present();

  }

  PushMeetType(x) {



    if (this.shopS.AdminFLAG) {
      this.clearSeletion();
    }


    x.active = !x.active;
    console.log("xxxxx : ", x);

    if (this.shopS.AdminFLAG) {

      this.shopS.currentMeet.meetType = x;
      //  this.navCtrl.navigateBack(['tabsAdmin/add']);
      //   this.shopS.addLineFLAG = true;
      this.shopS.plusAnimation();

    } else {
      this.pages = [];
      var meetTime = 0;
      var meetPrice = 0;
      var meetName = "";
      var meetFor = JSON.parse(JSON.stringify(x));
      console.log("meetFor", meetFor)
    }



    if (this.shopS.AdminFLAG) {

      this.ms.setMeetType(x);
    }
    else {
      this.selectedArray = [];

      for (var key in this.shopS.shopData.MeetingType) {
        let element = this.shopS.shopData.MeetingType[key];
        if (element.active == true) {
          this.selectedArray.push(element);
          meetTime = meetTime + parseInt(element.meetTime);
          meetName = meetName + " " + element.name;
          meetPrice = meetPrice + parseInt(element.price);
        }
      }

      // this.shopS.shopData.MeetingType.forEach(element => {
      //   if (element.active == true) {
      //     this.selectedArray.push(element);
      //     meetTime = meetTime + parseInt(element.meetTime);
      //     meetName = meetName + " " + element.name;
      //   }
      // });
      // console.log(this.selectedArray.length, "this.selectedArray.length")
      if (this.selectedArray.length > 1) {

        var newmeet = this.createNewMeet(meetFor, meetTime.toString(), meetName, meetPrice);
        console.log('newmeet', newmeet);

        this.shopS.meetForBuis.importMeetType(newmeet);
        this.ms.setMeetType(newmeet);
        this.shopS.currentMeet.meetType = newmeet;
        this.shopS.changeButtonState(2);
      } else {
        if (this.selectedArray.length == 0) {

          this.orderButtonComponent.goToAboutParam();
          this.Mtime = 0;
          this.ms.setMeetType(newmeet);
          this.shopS.currentMeet.meetType = newmeet;
          this.shopS.meetForBuis.importMeetType(newmeet)
        } else {


          var newmeet = this.selectedArray[0];
          this.Mtime = parseInt(newmeet.meetTime);
          this.shopS.changeButtonState(2);
          this.ms.setMeetType(newmeet);
          this.shopS.meetForBuis.importMeetType(newmeet);
          this.shopS.currentMeet.meetType = newmeet;
        }

      }
      this.shopS.meetForBuis.showClass();
      // this.ms.setMeetType(newmeet);

    }



  }

  createNewMeet(meet, time, name, price) {
    var meet2 = meet;
    meet2.meetTime = time;
    this.Mtime = time;
    meet2.name = name;
    meet2.price = price;
    meet2.approve = true;
    meet2.flow = false;

    return meet2;
  }

  clearSeletion() {
    //  console.log(this.shopS.shopData.MeetingType);


    for (var key in this.shopS.shopData.MeetingType) {
      if (this.shopS.shopData.MeetingType.hasOwnProperty(key)) {
        // console.log(key + " -> " + this.shopS.shopData.MeetingType[key]);
        this.shopS.shopData.MeetingType[key].active = false;
      }
    }


    for (var val of this.shopS.shopData.MeetingType) {
      // console.log(val);
      if (val) {
        val.active = false;
      }
      // console.log(val); // prints values: 10, 20, 30, 40
    }
  }

  doClick() {
    console.log('do Click');
  }

  ngOnDestroy() {
  }

  checkHidden(hidden) {
    if (hidden && !this.shopS.AdminFLAG) {
      return true;
    } else {
      return false;
    }
  }

}

