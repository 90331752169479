import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {
  @Input() heading: string;
  @Input() color: string;  
  @Input() isinline: boolean;
  @Output() event: EventEmitter<string> = new EventEmitter<string>();

  public show = false;
  public defaultColors: string[] = [
    '#fb434a',
    '#000272',
    '#4366FB',
    '#3f7a89',
    '#434a54',
    '#fb8043',
    '#8343fb',
    '#fb43b0',
    '#51b332',
    '#32b39c',
    // '#4b4fce',
    // '#4e0a77',
    // '#a367b5',
    // '#ee3e6d',
    // '#d63d62',
    // '#c6a670',
    // '#f46600',
    // '#cf0500',
    // '#efabbd',
    // '#8e0622',
    // '#f0b89a',
    // '#f0ca68',
    // '#62382f',
    // '#c97545',
    // '#c1800b'
  ];

  constructor() {
    console.log('this.show',this.show);
    console.log('this.isinline',this.isinline);
    console.log('this.color',this.color);
    if(this.isinline){
      console.log('opend!!!')
    }else{
      console.log('closed!!!')
    }
  }

  /**
   * Change color from default colors
   * @param {string} color
   */
  public changeColor(color: string): void {
    this.color = color;
    this.event.emit(this.color);
    this.show = false;


  }


  /**
   * Change color from input
   * @param {string} color
   */
  public changeColorManual(color: string): void {
    const isValid = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);

    if (isValid) {
      this.color = color;
      this.event.emit(this.color);
    }
  }

  /**
   * Change status of visibility to color picker
   */
  public toggleColors(): void {
    this.show = !this.show;
  }
}
