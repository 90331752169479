import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { WalletServiceService } from '../wallet-service/wallet-service.service';
import { ShopService } from 'src/ShopService/shop.service';
import { PaymentService } from 'src/app/payments/payment-service/payment.service';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-wallet-show-new-sale',
  templateUrl: './wallet-show-new-sale.page.html',
  styleUrls: ['./wallet-show-new-sale.page.scss'],
})
export class WalletShowNewSalePage implements OnInit {

  constructor(
    public sanitizer: DomSanitizer,
    public walletS: WalletServiceService,
    public modalController: ModalController,
    public PaymentService: PaymentService,
    public shopS: ShopService) { }


  @Input() iframe;
  // iframe: any;
  iframeHTML: any = '<div></div>';
  listen_new_transaction_subscribe;

  ngOnInit() {
    // this.iframe = this.walletS.show_new_sale.sale_url;
    console.log(this.iframe);
    console.log(this.walletS.show_new_sale);

    // this.PaymentService.transaction_data = this.walletS.show_new_sale;
    // let split = this.iframe.split('/');
    // this.PaymentService.transaction_id = split[split.length - 1];

    var trustURL: any = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframe);
    console.log('trustURL', trustURL);
    this.iframeHTML = this.sanitizer.bypassSecurityTrustHtml('<iframe name="wedcapp" style="width: 100%; height: 100%; padding: 10px; overflow: hidden; border: 0px;" class="iframe-full" src="' + trustURL.changingThisBreaksApplicationSecurity + '" ></iframe>');

    // this.PaymentService.set_new_transaction_iframe({ key: this.walletS.generate_sale.userID });
    // this.listen_new_transaction_iframe();
  }

  // sss() {
  //   this.PaymentService.set_new_transaction_iframe();
  //   this.listen_new_transaction_iframe();
  // }

  // listen_new_transaction_iframe() {


  //   // console.log('transaction_id',this.transaction_id)
  //   // admin.database().ref('/BarberShop/'+data.BarberId+'/Payments/history/').child(BarberHistoyKey).set(data);
  //   this.listen_new_transaction_subscribe = this.afDb.object('/Transaction/' + this.PaymentService.transaction_id).valueChanges().subscribe(val => {
  //     let newChange: any = val;
  //     console.log('newChange-child_changed', newChange);

  //     switch (newChange.status) {
  //       case 'open':

  //         break;
  //       // case '004':

  //       // break;

  //       case 'success':
  //         // הצלחה
  //         // this.shopS.turnOnConfetti(3000);
  //         // this.ToastService.showToast('הכרטיס אומת ונוסף בהצלחה');
  //         // this.shopS.log('', 'payment_newCard_success');

  //         // this.card_iframe_modal.dismiss();
  //         // this.getUserCards();

  //         break;


  //       default:
  //         // סירוב
  //         this.shopS.log('', 'payment_newCard_fail');

  //         // this.card_iframe_modal.dismiss();
  //         // this.ToastService.showTostDanger('  קיבלנו סירוב מחברת האשראי נסו שנית');
  //         // this.sendToIframe();
  //         break;
  //     }




  //   });


  // }

}
