import { Component, OnInit } from '@angular/core';
import { ShopService } from 'src/ShopService/shop.service';

@Component({
  selector: 'app-view-ui',
  templateUrl: './view-ui.component.html',
  styleUrls: ['./view-ui.component.scss'],
})
export class ViewUiComponent implements OnInit {

  constructor(public shopS:ShopService) { }

  ngOnInit() {
    
  }
 
  delete_CustomerBaner(){
    this.shopS.remove_CustomerBaner();
    this.shopS.modalS.dismissModal()
  }

}
