import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';

@Component({
  selector: 'app-explainer-cover',
  templateUrl: './explainer-cover.component.html',
  styleUrls: ['./explainer-cover.component.scss'],
})
export class ExplainerCoverComponent implements OnInit {

  constructor(
    public shopS:ShopService
  ) { }

  ngOnInit() {}

  toggleModal(event){
    console.log(event);
    
    event.stopPropagation();
    this.shopS.explainerFLAF = !this.shopS.explainerFLAF;
  // console.log('toggleModal=======this.shopS.explainerFLAF',this.shopS.explainerFLAF);
  }

}
