import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { PremiumService } from 'src/app/premium/premium-service/premium.service';
import { ShopService } from 'src/ShopService/shop.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { userService } from 'src/ShopService/user.service';
import { LoadingService } from 'src/providers/loading.service';
import { CryptoService } from 'src/app/crypto.service';
import { Http } from '@angular/http';

@Component({
  selector: 'app-premium-gift',
  templateUrl: './premium-gift.component.html',
  styleUrls: ['./premium-gift.component.scss'],
})
export class PremiumGiftComponent implements OnInit {

  constructor(
    public premiumService: PremiumService,
    public shopS: ShopService,
    public us: userService,
    public LoadingService: LoadingService,
    public cryptoService: CryptoService,
    public _http: Http,
    private socialSharing: SocialSharing
  ) { }

  text = '';
  used = false;
  cupon = false;
  cuponData = '';

  ngOnInit() {

    console.log('Setting.gift_options', this.shopS.shopData.Setting.gift_options)
    console.log('configDATA.gift_options', this.shopS.configDATA.gift_options)
    // this.shopS.shopData.Setting.gift_options
    if (this.shopS.shopData.Setting.gift_options) {
      this.text = this.shopS.shopData.Setting.gift_options.text;
      this.used = this.shopS.shopData.Setting.gift_options.used;
    } else {
      if (this.shopS.configDATA.gift_options) {
        this.text = this.shopS.configDATA.gift_options.text;
      } else {
        setTimeout(() => {
          this.ngOnInit()
        }, 1000);
      }
    }
  }

  async share() {
    let line_break = this.shopS.environment.platform == 'web' ? '%0a' : '\n';
    let url = 'https://point-app.net/download/';
    let text = 'היי, אני רוצה להמליץ לכם על Point.' + line_break + 'אפליקציה המאפשרת ללקוחות להזמין תורים בעצמם בקליק, ממש נוחה לתפעול ולניהול היומן האישי שלכם.' + line_break + 'הצטרפו עכשיו וקבלו חודש פרימיום מתנה!' + line_break + url;
    this.shopS.share(text);
  }


  showCupon() {
    this.cupon = !this.cupon;
  }

  cuponSend() {
    if (this.cuponData) {
      this.shopS.sendCupon(this.cuponData).then(res => {
        console.log(res);
      });



      // var data = {
      //   cupon: this.cuponData,
      //   uid: this.us.user.key,
      //   BarberId: this.shopS.shopData.Setting.BarberID,
      //   username: this.us.user.fname + ' ' + this.us.user.lname
      // }
      // this.LoadingService.presentforever();
      // console.log(data);
      // var newSend = this.cryptoService.Encrypt(data);

      // console.log(newSend);

      // this._http.post('' + this.shopS.serverDev + '/getCupon/', newSend).subscribe((res: any) => {
      //   console.log(res);
      //   if (res.validity) {

      //   }

      //   this.LoadingService.dismiss();
      // });



      // this.cupon = false;
      // setTimeout(() => {
      //   this.cuponData = '';
      // }, 1000)
    }
  }


  get_premium_gift() {
    this.premiumService.PremiumLock("go");
  }

  modalFLAG = false;

  openModal() {
    if (!this.modalFLAG) {
      this.modalFLAG = true;
      console.log(this.modalFLAG);
    }
  }
  closeModal(e) {
    e.stopPropagation();
    this.modalFLAG = false;
    console.log(this.modalFLAG);
  }

  options: AnimationOptions = {
    path: '../assets/lottie/1.json',
  };
  options2: AnimationOptions = {
    path: '../assets/lottie/2.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }


}
