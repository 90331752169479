import { Component, OnInit } from '@angular/core';
import { ShopService } from './../../ShopService/shop.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {

  constructor(public shopS:ShopService) { }

  ngOnInit() {}

}
