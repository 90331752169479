import { state } from '@angular/animations';
import { LoadingService } from './../../../providers/loading.service';
import { ShopService } from 'src/ShopService/shop.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
})
export class SettingsModalComponent implements OnInit {

  public imagePath;
  imgURL: any;
  public message: string;
  typeSetting:any;
  currentImg:any = {
    cover: '',
    logo: '',
    post: ''
  };
  currentImg2:any;
  showtutorial=false;
  aShow=false;
  UserD:any;
  address:string;
    open = false;
    mySetting:any = {};
    jso:any = {};

  constructor(public shopS:ShopService,  
      private loadingService:LoadingService,
    ) { }

  ngOnInit() {
    // this.shopS.smsS.sendSMS("")
  }


  preview(event,where) {
    this.typeSetting = where;
    this.currentImg[where] = event;
    var self = this;
     console.log('this.currentImg:',this.currentImg);
    var filess = event.srcElement.files ;
    // console.log('filess:',filess);
  
    if (filess.length === 0)
      return;
  
    var mimeType = filess[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
  
    var reader = new FileReader();
    reader.readAsDataURL(filess[0]); 
    reader.onload = (_event) => { 
      console.log('reader.result',reader.result);
      console.log('this.mySetting',self.mySetting);
      self.mySetting[where] = reader.result; 
      self.shopS.UploadFlags[where] = true;
    }
  }

    
  inputchange(event,kind){
    // console.log('kind',kind);
    if(kind == 'color'){
      this.jso[kind] = event;
    }else{
      // console.log('event.target.value',event.target.value);
      this.jso[kind] = event.target.value;
    }
 //  console.log('jso[kind]',this.jso[kind]);
  //  console.log('jso',this.jso);
   this.shopS.updateStr('/Setting/',this.jso);
  }

  uploadThatImg(type){
    console.log('uploadThatImg',this.currentImg,this.typeSetting);
  this.loadingService.presentforever();
   let result = this.shopS.uploadImg(this.currentImg[type],'/Setting/',type).then(resullt => {
   this.loadingService.dismiss();
   }).catch((e) => {
    this.loadingService.dismiss();
  });
   console.log('result:',result);
  }
  

dissmisThatImage(type){
  if(type == 'logo'){
  this.mySetting.logo = this.shopS.shopData.Setting.logo;
  this.shopS.UploadFlags.logo = false;
  (document.getElementById('logoUpload') as HTMLInputElement).value = ''; 
  
  }else{
    this.mySetting.cover = this.shopS.shopData.Setting.cover;
    this.shopS.UploadFlags.cover = false;
    (document.getElementById('coverUpload') as HTMLInputElement).value = ''; 
  
    
  }
  }
  
  uploadThatPost(){
     console.log('.currentImg:',this.currentImg); 
     console.log('.typeSetting:',this.typeSetting); 
    this.loadingService.presentforever();
  
    let result = this.shopS.uploadImg(this.currentImg[this.typeSetting],'/Setting/',this.typeSetting).then(data =>{
      console.log('11111111-----data:',data); 
    
    });
    console.log('result:',result); 
  
  }

  
}
