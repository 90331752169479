import { Component } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'alert-example',
  template: '<span></span>',
  //   styleUrls: ['./alert-example.css'],
})
export class AlertExample {

  constructor(public alertController: AlertController) { }


  // async presentAlertNewAdmin() {
  //   const alert = await this.alertController.create({
  //     header: 'ברוכים הבאים',
  //     subHeader: '',
  //     message: 'העסק שלכם נוצר בהצלחה',
  //     buttons: [{
  //       text: 'כניסה',
  //       role: 'ok',
  //       cssClass: 'secondary',
  //       handler: (blah) => {
  //         this.loadingService.present();
  //         this.auth.logOutForLogin();
  //       }
  //     }]
  //   });

  //   await alert.present();
  // }
  async flexAlert(header, subHeader, message, btn_cancel, btn_ok, cb) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: [
        {
          text: btn_cancel,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            cb(false);
          }
        }, {
          text: btn_ok,
          handler: () => {
            cb(true);
            // if (!this.us.user.registrationdate) {
            //   this.us.registrationdate();
            // }
            // this.goBack()
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Alert',
      subHeader: 'Subtitle',
      message: 'This is an alert message.',
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentAlertMultipleButtons() {
    const alert = await this.alertController.create({
      header: 'Alert',
      subHeader: 'Subtitle',
      message: 'This is an alert message.',
      buttons: ['Cancel', 'Open Modal', 'Delete']
    });

    await alert.present();
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: 'Confirm!',
      message: 'האם אתה בטוח שברצונך למחוק',
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel:', blah);
          }
        }, {
          text: 'כן',

          handler: () => {
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlertPrompt() {
    const alert = await this.alertController.create({
      header: 'Prompt!',
      inputs: [
        {
          name: 'name1',
          type: 'text',
          placeholder: 'Placeholder 1'
        },
        {
          name: 'name2',
          type: 'text',
          id: 'name2-id',
          value: 'hello',
          placeholder: 'Placeholder 2'
        },
        {
          name: 'name3',
          value: 'http://ionicframework.com',
          type: 'url',
          placeholder: 'Favorite site ever'
        },
        // input date with min & max
        {
          name: 'name4',
          type: 'date',
          min: '2017-03-01',
          max: '2018-01-12'
        },
        // input date without min nor max
        {
          name: 'name5',
          type: 'date'
        },
        {
          name: 'name6',
          type: 'number',
          min: -5,
          max: 10
        },
        {
          name: 'name7',
          type: 'number'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Ok');
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlertRadio() {
    const alert = await this.alertController.create({
      header: 'Radio',
      inputs: [
        {
          name: 'radio1',
          type: 'radio',
          label: 'Radio 1',
          value: 'value1',
          checked: true
        },
        {
          name: 'radio2',
          type: 'radio',
          label: 'Radio 2',
          value: 'value2'
        },
        {
          name: 'radio3',
          type: 'radio',
          label: 'Radio 3',
          value: 'value3'
        },
        {
          name: 'radio4',
          type: 'radio',
          label: 'Radio 4',
          value: 'value4'
        },
        {
          name: 'radio5',
          type: 'radio',
          label: 'Radio 5',
          value: 'value5'
        },
        {
          name: 'radio6',
          type: 'radio',
          label: 'Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 ',
          value: 'value6'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Ok');
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlertFound(buisName) {
    var textT = "האם תרצה להתחבר לעסק של ?..";
    textT += buisName;
    const alert = await this.alertController.create({
      header: 'עסק נמצא!',
      message: textT,
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'כן התחבר!',

          handler: () => {

          }
        }
      ]
    });

    await alert.present();
  }

  async aleryByTextConfirm(str, title) {
    const alert = await this.alertController.create({
      header: title,
      subHeader: '',
      message: str,
      buttons: [
        {
          text: 'ביטול',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return false;
          }
        }, {
          text: 'כן אני רוצה!',
          handler: () => {
            return true;
          }
        }
      ]
    });

    return await alert.present();

  }
  async aleryByText2(str) {
    const alert = await this.alertController.create({
      header: 'פתיחת עסק חדש',
      subHeader: '',
      message: str,
      buttons: [
        {
          text: 'ביטול',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return false;
          }
        }, {
          text: 'כן אני רוצה!',
          handler: () => {
            return true;
          }
        }
      ]
    });

    await alert.present();
  }
  async aleryByText(str) {
    const alert = await this.alertController.create({
      header: 'שגיאה',
      subHeader: '',
      message: str,
      buttons: ['סגור']
    });

    await alert.present();
  }

  async aleryByTextPayAttention(str) {
    const alert = await this.alertController.create({
      header: 'שים לב',
      subHeader: '',
      message: str,
      buttons: ['סגור']
    });

    await alert.present();
  }

  async timezoneAlert() {
    const alert = await this.alertController.create({
      header: 'שימו לב',
      subHeader: '',
      message: 'נראה שאתם נמצאים בחול, לא ניתן לבצע שינויים ביומן',
      buttons: ['סגור']
    });

    await alert.present();
  }

  async eventDetailsAlart(str) {
    const alert = await this.alertController.create({
      header: 'שגיאה',
      subHeader: 'הפרטים הבאים חסרים',
      message: str,
      buttons: ['סגור']
    });

    await alert.present();
  }

  async aleryByTextgood(str) {
    const alert = await this.alertController.create({
      header: 'התראה',
      subHeader: '',
      message: str,
      buttons: ['סגור']
    });

    await alert.present();
  }

  // async alertTorefresh(event) {
  //   console.log(event);

  //   let result = false;
  //   const alert = await this.alertController.create({
  //     header: 'עדכון השינוי',
  //     message: 'בשלב זה צריך לכבות את האפליקציה ולהדליק על מנת שהשינוי יכנס לתוקף',
  //     buttons: [
  //       {
  //         text: 'כן הבנתי',
  //         handler: () => {
  //           this.shopS.calendar.currentDate = new Date();
  //           if (event.detail.value) {
  //             localStorage.setItem("calendarTimeInterval", event.detail.value);
  //             this.shopS.calendar.timeInterval = event.detail.value;
  //           }
  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  //   return result;
  // }


  async presentAlertCheckbox() {
    const alert = await this.alertController.create({
      header: 'Checkbox',
      inputs: [
        {
          name: 'checkbox1',
          type: 'checkbox',
          label: 'Checkbox 1',
          value: 'value1',
          checked: true
        },

        {
          name: 'checkbox2',
          type: 'checkbox',
          label: 'Checkbox 2',
          value: 'value2'
        },

        {
          name: 'checkbox3',
          type: 'checkbox',
          label: 'Checkbox 3',
          value: 'value3'
        },

        {
          name: 'checkbox4',
          type: 'checkbox',
          label: 'Checkbox 4',
          value: 'value4'
        },

        {
          name: 'checkbox5',
          type: 'checkbox',
          label: 'Checkbox 5',
          value: 'value5'
        },

        {
          name: 'checkbox6',
          type: 'checkbox',
          label: 'Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6',
          value: 'value6'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Ok');
          }
        }
      ]
    });

    await alert.present();
  }

  async alertConfirm(header, subHeader, str, yesText, noText, cb) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: str,
      buttons: [
        {
          text: noText,
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            cb(false);
          }
        }, {
          text: yesText,
          handler: () => {
            cb(true);
          }
        }
      ]
    });

    await alert.present();
  }

}