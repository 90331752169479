import { Component, OnInit, Input, OnDestroy, AfterViewInit, AfterContentInit, Output } from '@angular/core';

@Component({
  selector: 'app-toast-point',
  templateUrl: './toast-point.component.html',
  styleUrls: ['./toast-point.component.scss'],
})
export class ToastPointComponent implements OnInit {
  @Input() data: any;

  constructor() { }

  ngOnInit() {}

}
