import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertExample } from 'src/providers/alert.service';
import { ToastService } from 'src/providers/toast-service';
import { ShopService } from 'src/ShopService/shop.service';
import { userService } from 'src/ShopService/user.service';
import { WalletServiceService } from '../wallet-service/wallet-service.service';

@Component({
  selector: 'app-wallet-modal-info',
  templateUrl: './wallet-modal-info.component.html',
  styleUrls: ['./wallet-modal-info.component.scss'],
})
export class WalletModalInfoComponent implements OnInit {

  businessType;
  sellerInc;
  currentPage = 0;
  terms = "short";
  other_subject = '';
  mailDetails = {
    subject_num: '0',
    subject: '',
    content: '',
    phone: this.us.user.phone,
    email: this.us.user.email,
    name: this.us.user.fname + ' ' + this.us.user.lname,
    payme_id: this.walletS.current_seller.seller_payme_id
  }
  // mailSubjects = ['', 'נושא6', 'נושא5', 'נושא4', 'נושא3', '', 'נושא1'];
  mailSubjects = ['אחר', 'נושא1', 'נושא2', 'נושא3', 'נושא4', 'נושא5', 'נושא6'];

  constructor(
    public modalController: ModalController,
    public walletS: WalletServiceService,
    private us: userService,
    public shopS: ShopService,
    private alertS: AlertExample,
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.businessType = this.walletS.businesses_types.find(type => type.value == this.walletS.current_seller.seller_business_details.seller_business_type).name;
    this.sellerInc = this.walletS.businesses_incs.find(inc => inc.value == this.walletS.current_seller.seller_business_details.seller_inc).name;
  }

  mailPayme() {
    if (this.mailDetails.subject_num == '0') {
      if (!this.other_subject) {
        this.alertS.aleryByText('יש להכניס נושא פנייה');
        return;
      } else {
        this.mailDetails.subject = this.other_subject + ' ' + this.mailDetails.payme_id;
      }
    } else {
      this.mailDetails.subject = this.mailSubjects[this.mailDetails.subject_num] + ' ' + this.mailDetails.payme_id;
    }

    this.shopS.mailPayme(this.mailDetails).subscribe(respone => {
      console.log('respone: ', respone);
      var respone2: any = respone
      if (respone2.ok == true) {
        this.toast.showToast('ההודעה נשלחה בהצלחה');
        this.modalController.dismiss();
      } else {
        this.toast.showTostDanger('קרתה תקלה בשליחת ההודעה אנא נסו שנית');
      }
    });
  }
  
  dismissModal() {
    if (this.modalController) {
      this.modalController.dismiss({
        'dismissed': false
      }).then(() => { this.modalController = null; });
    }
  }

}
