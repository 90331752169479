import { OrderButtonComponent } from './../../components/order-button/order-button.component';
import { userService } from './../../ShopService/user.service';
import { meetService } from './../../ShopService/meetS.service';
import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ShopService } from '../../ShopService/shop.service';
import { Router } from '@angular/router';


@Component({
  selector: 'page-appointment',
  templateUrl: 'appointment.html',
  styleUrls: ['appointment.scss']
})
export class AppointmentPage {
  self: string = "me";
  activeFlag = '33:00';
  addNote = false;
  lang = "he";
  firstTime = false;
  fixTop = false;
  dateForView = [];

  days = {
    "en": ["ראשון", "שני", "שלישי", "רבעי", "חמישי", "שישי", "שבת"],
    "he": ["א׳", "ב׳", "ג׳", "ד׳", "ה׳", "ו׳", "ש׳"]
  }

  allMeets: any = [];
  extraText = "";
  meetType;
  BarberID;

  newMeetsComplete = [];
  limitCal = 90;
  cheakData;
  completMeet;
  charDate;
  cheak;
  appDate = '';
  day;
  a;
  newMeetsComplete2 = [];
  fir;
  sec;
  daysName = ["יום ראשון", "יום שני", "יום שלישי", "יום רבעי", "יום חמישי", "יום שישי", "יום שבת"];
  eventSource;
  viewTitle;
  isToday: boolean;
  calendar = {
    autoSelect: false,
    noEventsLabel: `בחר תור`,
    showEventDetail: false,
    mode: 'month',
    currentDate: new Date()
  }; // these are the variable used by the calendar.
  minDate = new Date().toISOString();
  array;
  correctAppoin = "";

  constructor(public navCtrl: NavController,
    private meetService: meetService,
    private router: Router,
    public shopS: ShopService,
    private orderButtonComponent: OrderButtonComponent) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.addNote = false;
    this.shopS.moreInfo = "";
    this.shopS.timeZoneDiff = this.shopS.checkTimeZoneDiff();

    if (!this.meetService.meetType) {
      this.router.navigate(['tabs/about']);
      return;
    }


    console.log("appoints page");

    var today = new Date()
    this.day = today.getDay();


    //new meet or change meet
    if (this.meetService.meetToChange) {

      console.log("this.dateForView this.dateForView ", this.dateForView);
      var newDate = new Date(this.meetService.meetToChange.Date);
      this.shopS.calendar.currentDate = newDate;
      this.shopS.meetForBuis.importStartDate(newDate);
      this.appDate = this.formatDate(newDate);
      this.shopS.appDate = this.formatDate(newDate);
    } else {
      this.shopS.appDate = this.formatDate(this.shopS.calendar.currentDate);
      this.appDate = this.formatDate(this.shopS.calendar.currentDate);
    }


    this.daysForViewFunc();

  }

  ionViewWillEnter() {
    this.firstTime = false;
  }

  // today(a) {

  //   this.dateForView.forEach(x => {
  //     if (x.active == "nowork") {
  //       x.active = false;
  //     }
  //   })
  //   a.active = true;

  //   this.shopS.calendar.currentDate = a.cal;
  //   this.shopS.meetForBuis.importStartDate(a.cal)
  //   this.appDate = this.formatDate(this.shopS.calendar.currentDate);
  //   this.shopS.appDate = this.formatDate(this.shopS.calendar.currentDate);
  //   this.shopS.changeViewHouers(a.cal);
  // }

  focusText() {
    this.fixTop = true;
  }

  BlourText() {
    this.fixTop = false;
  }

  TorIsOk(i, tor, tfusim, timeWait) {




    for (var m = 0; m < timeWait; m++) {
      for (var t = 0; t < tfusim.length; t++) {

        if ((tor[i + m] == tfusim[t].startTimeView) || (tor[i + m] == undefined)) {


          return false;
        }

      }

    }



    return true;
  }

  goBack() {
    this.shopS.userOrder.stage = 0;
    this.orderButtonComponent.goToAbout()
  }

  addzero(x) {
    x = x.toString();
    if (x.length == 1) {
      x = "0" + x;
    }
    return (x)
  }

  daysFromRow(a) {
    console.log(a);
    // console.log(this.dateForView);

    if ((this.formatDate(a.cal) == this.formatDate(this.shopS.calendar.currentDate)) && (this.firstTime == true)) {
      return;
    }

    this.firstTime = true;
    this.dateForView.forEach(x => {
      if (x.active != "nowork") {
        x.active = false;
      }
    });

    a.active = true;
    this.shopS.current_pick_day = this.shopS.formatDate(a.cal);
    console.log(this.shopS.current_pick_day);
    
    this.shopS.meetForBuis.importStartDate(a.cal);
    this.shopS.calendar.currentDate = a.cal;
    this.appDate = this.formatDate(this.shopS.calendar.currentDate);
    this.shopS.appDate = this.formatDate(this.shopS.calendar.currentDate);
    this.shopS.getFreeHours(a.cal);
    // this.shopS.changeViewHouers(a.cal);
    var viewDate = new Date(a.cal)
    let f = this.shopS.monthNames[this.lang];
    this.viewTitle = f[viewDate.getMonth()] + ', ' + viewDate.getFullYear();
    this.addNote = false;
    this.correctAppoin = "";
    this.shopS.changeButtonState(3);
  }

  onViewTitleChanged(title) {
    // console.log('onViewTitleChanged-title',title);
    this.viewTitle = title;

  }


  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
  }

  daysForViewFunc() {
    this.dateForView = [];
    var today2 = new Date();


    var dayToday = today2.getDay();
    var limitMeet = parseInt(this.shopS.shopData.Setting.limitMeet);
    if (!limitMeet) {
      limitMeet = 7;
    }

    var start = Number(this.shopS.shopData.Setting.daysOrderBlock);
    if (!start) {
      start = 0;
    }
    //  start=0;





    today2.setDate(today2.getDate() + start);
    dayToday = today2.getDay();

    var forchangeview = new Date(today2);




    for (var i = 0; i < limitMeet; i++) {
      // console.log("this.shopS.shopData",this.shopS.shopData.WorkDays)



      if (this.lang == "he") {
        this.days.he[dayToday];
        var calDate = today2.toString();
        if (i == 0) {
          if (this.shopS.shopData.WorkDays[dayToday].timeEnd != "00:00") {
            this.dateForView.push(
              {
                "name": this.days.he[dayToday],
                "date": today2.getDate(),
                'cal': new Date(calDate),
                'active': true
              });
          }
        } else {
          if (this.shopS.shopData.WorkDays[dayToday].timeEnd != "00:00") {
            this.dateForView.push(
              {
                "name": this.days.he[dayToday],
                "date": today2.getDate(),
                'cal': new Date(calDate),
                'active': false
              });
          } else {
            this.dateForView.push(
              {
                "name": this.days.he[dayToday],
                "date": today2.getDate(),
                'cal': new Date(calDate),
                'active': "nowork"
              });
          }
        }

      }
      if (dayToday != 6) {
        dayToday++;
      } else {
        dayToday = 0;
      }

      today2.setDate(today2.getDate() + 1);






    }

    if (this.meetService.meetToChange) {

      console.log("----> ", this.dateForView, this.meetService.meetToChange);

      for (i = 0; i < this.dateForView.length; i++) {


        var d1 = new Date(this.meetService.meetToChange.Date)
        var d2 = new Date(this.dateForView[i].cal);

        var d11 = d1.getDate() + "/" + d1.getMonth() + "/" + d1.getFullYear();
        var d22 = d2.getDate() + "/" + d2.getMonth() + "/" + d2.getFullYear();

        if (d11 == d22) {
          // this.dateForView[i].active =true;

          console.log("this.daysFromRow", this.daysFromRow[i])
          this.daysFromRow(this.dateForView[i]);



        } else {
          // this.dateForView[i].active =false;
        }
      }



    } else {
      console.log("i go to change vie", forchangeview)
      // this.shopS.changeViewHouers(forchangeview);

      this.daysFromRow(this.dateForView[0])
    }

  }

  itemSelected(comp) {
    console.log(comp);
    
    if(comp.hour_view) {
      if (comp.hour_view == this.correctAppoin) {
        this.correctAppoin = "";
        this.addNote = false;
        this.shopS.changeButtonState(3);
  
      } else {
        this.correctAppoin = comp.hour_view;
        this.shopS.correctAppoin = comp.hour_view;
        // this.shopS.meetForBuis.importViewStart(comp.hour_view);
        this.shopS.meetForBuis.setDate(comp.timestamp, comp.hour_view);
        this.shopS.changeButtonState(4);
        this.addNote = true;
      }
    }

    // if(comp) {
    //   if (comp == this.correctAppoin) {
    //     this.correctAppoin = "";
    //     this.addNote = false;
    //     this.shopS.changeButtonState(3);
  
    //   } else {
    //     this.correctAppoin = comp;
    //     this.shopS.correctAppoin = comp;
    //     this.shopS.meetForBuis.importViewStart(comp);
    //     this.shopS.changeButtonState(4);
    //     this.addNote = true;
    //   }
    // }
  }

  closeNote() {
    this.addNote = false;
  }

  convertDate(inputFormat) {
    console.log('inputFormat', inputFormat);
    var today: any = new Date(inputFormat);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '.' + mm + '.' + yyyy;


  }

  public trackItem(index: number, item) {
    return item.trackId;
  }
  // splitTheHouers(tfusim) {
  //   this.newMeetsComplete = [];
  //   var dupmeets = [];
  //   var origin = [];
  //   // console.log("completMeet", this.completMeet, tfusim)
  //   this.completMeet.forEach(element => {
  //     element.min.forEach(a => {
  //       var h = this.addzero(element.houer);
  //       var m = this.addzero(a);
  //       this.newMeetsComplete.push(h + ":" + m);
  //       dupmeets.push(h + ":" + m);
  //       origin.push(h + ":" + m);
  //     });

  //   });
  //   // מוחק את התורים התפוסים
  //   var findem = false;
  //   if (tfusim.length != 0) {
  //     var reversTime = this.meetService.meetType.meetTime / this.meetService.timeStep;


  //     for (var t = 0; t < tfusim.length; t++) {

  //       findem = false;
  //       for (var i = 0; i < this.newMeetsComplete.length; i++) {

  //         if (tfusim[t].startTimeView == this.newMeetsComplete[i]) {

  //           findem = true;
  //           if ((tfusim[t].meetStatus == 5) || (tfusim[t].flow == true) || (this.meetService.meetToChange) && (this.meetService.meetToChange.startTimeView == tfusim[t].startTimeView)) {



  //           } else {


  //             var forword = tfusim[t].meetTime / this.meetService.timeStep;
  //             //forword
  //             // console.log(forword, "forword");
  //             for (var w = 0; w < forword; w++) {
  //               this.newMeetsComplete[i + w] = "x";
  //             }
  //             // console.log(reversTime, "backword");
  //             //backword
  //             for (var n = 0; n < reversTime; n++) {
  //               this.newMeetsComplete[i - n] = "x";
  //             }


  //           }
  //         }


  //       }
  //       // new changes x problem
  //       if (findem == false) {



  //         for (var i = 0; i < this.newMeetsComplete.length; i++) {

  //           if (tfusim[t].startTimeView == dupmeets[i]) {

  //             if ((tfusim[t].meetStatus == 5) || (tfusim[t].flow == true) || (this.meetService.meetToChange) && (this.meetService.meetToChange.startTimeView == tfusim[t].startTimeView)) {



  //             } else {
  //               // console.log(this.newMeetsComplete, dupmeets);


  //               // console.log(tfusim[t].startTimeView, dupmeets[i], "match")
  //               var forword = tfusim[t].meetTime / this.meetService.timeStep;
  //               //forword
  //               // console.log(forword, "forword");
  //               for (var w = 0; w < forword; w++) {
  //                 dupmeets[i + w] = "x";
  //               }
  //               // console.log(reversTime, "backword");
  //               //backword
  //               for (var n = 0; n < reversTime; n++) {
  //                 dupmeets[i - n] = "x";
  //               }
  //               this.newMeetsComplete = this.comperArray(this.newMeetsComplete, dupmeets);

  //               dupmeets = origin

  //             }



  //           }
  //         }
  //       }


  //     }




  //     for (var f = this.newMeetsComplete.length - 1; f >= 0; f--) {
  //       if (this.newMeetsComplete[f] === "x") {
  //         this.newMeetsComplete.splice(f, 1);
  //       }
  //     }
  //     if (this.shopS.istoday(this.shopS.calendar.currentDate)) {

  //       this.newMeetsComplete = this.shopS.cleanTodayTime(this.newMeetsComplete);
  //     }


  //     this.allMeets = this.newMeetsComplete;

  //     this.beterView();

  //     // this.allMeets=this.newMeetsComplete;
  //     // for(var i =0;i<this.newMeetsComplete.length;i++){
  //     //   if( this.TorIsOk(i,this.newMeetsComplete,tfusim,reversTime)){
  //     //     this.allMeets.push(this.newMeetsComplete[i]);

  //     //   }else{

  //     //   }

  //     // }



  //     // console.log("allMeets",this.allMeets)
  //     /*
  //           // old meet tafus
  //           tfusim.forEach(used => {

  //             var removeMeet = used.startTimeView;
  //             var meetSkip = used.meetTime / 5;
  //             for (var i = 0; i < this.newMeetsComplete.length; i++) {
  //               if (this.newMeetsComplete[i] == removeMeet) {
  //                 // בדיקה האם קיים תור להחלפלה
  //                 if (this.meetService.meetToChange) {
  //                   console.log(this.meetService.meetToChange.startTimeView, this.newMeetsComplete[i])
  //                   if (this.meetService.meetToChange.startTimeView == this.newMeetsComplete[i]) {

  //                   } else {
  //                     if (i - (meetSkip - 1) < 0) {
  //                       this.newMeetsComplete.splice(0, ((meetSkip * 2) - 1) + (i - (meetSkip - 1)));

  //                     } else {
  //                       this.newMeetsComplete.splice(i - (meetSkip - 1), (meetSkip * 2) - 1);

  //                     }
  //                   }
  //                 } else {

  //                   var reversTime=this.meetService.meetType.meetTime/5;
  //                   if (i - (meetSkip - 1) < 0) {
  //                     this.newMeetsComplete.splice(0, ((meetSkip * 2) - 1) + (i - (meetSkip - 1)));

  //                   } else {
  //                     this.newMeetsComplete.splice(i - (meetSkip - 1), (meetSkip * 2) - 1);

  //                   }
  //                 }

  //               }

  //             }




  //           });
  //           */
  //   } else {
  //     console.log("אין תורים תפוסים")
  //     if (this.shopS.istoday(this.shopS.calendar.currentDate)) {

  //       this.newMeetsComplete = this.shopS.cleanTodayTime(this.newMeetsComplete);
  //     }

  //     this.allMeets = this.newMeetsComplete;

  //     this.beterView();
  //   }


  // }
  // changeViewHouers(event: Date) {


  //   var today = new Date();
  //   this.isToday = today.getTime() === event.getTime();


  //   var appDate2 = event.getDay();
  //   var SendDate = new Date(event.getFullYear(), event.getMonth(), event.getDate())
  //   this.day = event.getDay();


  //   if (appDate2 != 7) {
  //     // מחזיר את השעות עבודה של העסק

  //     var data = this.shopS.shopData.WorkDays;
  //     if (this.shopS.shopData.Setting.timeStep) {
  //       this.meetService.timeStep = Number(this.shopS.shopData.Setting.timeStep);
  //     }
  //     this.meetService.dayWork = data[appDate2].dayName;
  //     this.meetService.startWork = data[appDate2].timeStart;
  //     this.meetService.endWork = data[appDate2].timeEnd;
  //     this.meetService.setShopId(this.us.user.BarberID);

  //     //יוצר מערך שלש עות עבודה לי הלוז
  //     this.meetService.arrayOfHouers();

  //     // מחזיר את כל התורים שיש באותו יום
  //     var meetgetsub = this.shopS.getDateTafus(this.appDate).valueChanges()
  //       .subscribe(data2 => {

  //         this.completMeet = this.meetService.getCompleteMeets();
  //         this.splitTheHouers(data2);
  //         this.meetService.cleanService();
  //         meetgetsub.unsubscribe();

  //       })



  //   }


  // }


  // comperArray(a1, a2) {

  //   var a3 = [];
  //   for (var i = 0; i < a1.length; i++) {

  //     if (a1[i] == 'x' || a2[i] == 'x') {
  //       a3.push('x');
  //     } else {
  //       a3.push(a1[i]);
  //     }

  //   }
  //   return a3;

  // }
  // beterView() {

  //   var lastHour = "01";
  //   var newArrayMeet = [];

  //   this.allMeets.forEach(x => {
  //     var newTime = x.split(":");
  //     var newHour = newTime[0];
  //     var newMinuts=newTime[1];




  //     switch (this.shopS.shopData.Setting.timeStep) {
  //       case "60":
  //       if(newMinuts == "00"){
  //         if (lastHour != newHour) {
  //           newArrayMeet.push(newHour);
  //           lastHour = newHour;
  //         }
  //         newArrayMeet.push(newHour + ":" + newTime[1]);

  //       }  
  //       break;
  //       case "30":
  //         if((newMinuts == "00")||(newMinuts == "30")){
  //           if (lastHour != newHour) {
  //             newArrayMeet.push(newHour);
  //             lastHour = newHour;
  //           }
  //           newArrayMeet.push(newHour + ":" + newTime[1]);
  //         }  
  //       break;
  //       case "15":
  //         if((newMinuts == "00")||(newMinuts == "30")||(newMinuts == "15")||(newMinuts == "45")){
  //           if (lastHour != newHour) {
  //             newArrayMeet.push(newHour);
  //             lastHour = newHour;
  //           }
  //           newArrayMeet.push(newHour + ":" + newTime[1]);
  //         } 
  //       break;

  //       default:
  //         if (lastHour != newHour) {
  //           newArrayMeet.push(newHour);
  //           lastHour = newHour;
  //         }
  //         newArrayMeet.push(newHour + ":" + newTime[1]);
  //       break;
  //     }


  //   });
  //   this.allMeets = [];
  //   this.allMeets = newArrayMeet;

  // }






  /// haim code new
  //   var meetings = [{
  //     meeting: 'one',
  //     start_time: "07:15:00",
  //     end_time: "08:15:00"
  // },
  // {
  //     meeting: 'two',
  //     start_time: "08:15:00",
  //     end_time: "18:15:00"
  // }];

  // function subtractMinute(time){
  //     var h = +time.substr(0, 2);
  //     var m = +time.substr(3, 2);

  //     if(m > 0){
  //         m -= 1;
  //     }else{
  //         if(h > 0){
  //             h -= 1;
  //         }else{
  //             return false;
  //         }
  //         m = 59;
  //     }

  //     if(h < 10)
  //         h = '0'+h;

  //     if(m < 10)
  //         m = '0'+m;

  //     return h+':'+m+':00';
  // }

  // function addMinute(time){
  //     var h = +time.substr(0, 2);
  //     var m = +time.substr(3, 2);

  //     if(m < 59){
  //         m += 1;
  //     }else{
  //         if(h < 22){
  //             h += 1;
  //         }else{
  //             return false;
  //         }
  //         m = 0;
  //     }

  //     if(h < 10)
  //         h = '0'+h;

  //     if(m < 10)
  //         m = '0'+m;

  //     return h+':'+m+':00';
  // }

  // //If meetings is already sorted by time
  // //you can skip this next bit of code
  // meetings.sort(function(a, b){
  //     return a.start_time > b.start_time? 1: -1;
  // });

  // var schedule = [];
  // var start_time = '00:00:00';
  // var end_time = '23:59:00';
  // for(var i=0, l=meetings.length; i<l; i++){
  //     end_time = subtractMinute(meetings[i].start_time);

  //     if(i)
  //         start_time = addMinute(meetings[i-1].end_time);   

  //     if((end_time && !i) || (end_time && i && meetings[i-1].end_time < meetings[i].start_time))
  //         schedule.push({meeting: 'free time', start_time: start_time, end_time: end_time});

  //     schedule.push(meetings[i]);

  //     if(i+1 === l){
  //         start_time = addMinute(meetings[i].end_time);

  //         if(start_time)
  //             schedule.push({meeting: 'free time', start_time: start_time, end_time: '23:59:00'});
  //     }
  // }

  // console.log(schedule);


}
