import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmenuComponent } from '../dmenu/dmenu.component';
import { IonicModule } from '@ionic/angular';
import { SymbolComponent } from 'src/components/symbol/symbol.component';
import { FormsModule } from '@angular/forms';
import { GooglemapsComponent } from '../googlemaps/googlemaps.component';
import { AgmCoreModule } from '@agm/core';



@NgModule({
  declarations: [
    DmenuComponent,
    SymbolComponent,
    GooglemapsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    AgmCoreModule.forRoot({
      language: 'iw',
      apiKey: "AIzaSyDJYGsppHU_r_BjvfYFw-lwaQsbPqVV2zw",
      libraries: ["places"],
    }),
  ],
  exports: [
    DmenuComponent,
    SymbolComponent,
    GooglemapsComponent
  ],
  entryComponents: [
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class MainModule { }
