import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WalletGenerateSalePage } from './wallet-generate-sale/wallet-generate-sale.page';
import { WalletGenerateSubscriptionComponent } from './wallet-generate-subscription/wallet-generate-subscription.component';
import { WalletModalInfoComponent } from './wallet-modal-info/wallet-modal-info.component';
import { WalletServiceService } from './wallet-service/wallet-service.service';
import { LoadingService } from '../../providers/loading.service';
import { ShopService } from '../../ShopService/shop.service';
import { WalletViewDocComponent } from "./wallet-view-doc/wallet-view-doc.component";
import { curveBasis, curveCatmullRom, curveMonotoneY } from 'd3-shape';
import { InvoiceServiceService } from 'src/app/invoice/invoice-service/invoice-service.service';
import { AnimationController, IonContent } from '@ionic/angular';
import { ModalAnimationService } from '../animations/modal-animation.service'
@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.page.html',
  styleUrls: ['./wallet.page.scss'],
})
export class WalletPage implements OnInit {

  months = [];
  monthNames = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני",
    "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"
  ];

  fake_loader_run() {
    this.load_seller = false;
    setTimeout(() => {
      this.load_seller = true;
    }, 4000)
  }

  click_on_upload_doc(type) {
    console.log('type', type)
    console.log('.documents[type]', this.shopS.shopData.Wallet.documents[type])
    if (this.shopS.shopData.Wallet.documents[type]) {
      window.open(this.shopS.shopData.Wallet.documents[type].url, '_blank');

    } else {
      document.getElementById(type).click();
    }
  }

  constructor(public modalController: ModalController, public walletS: WalletServiceService,
    private loadingService: LoadingService,
    public shopS: ShopService,
    public modalCtrl: ModalController,
    public invoiceS: InvoiceServiceService,
    public ModalAnimationService: ModalAnimationService

  ) { }
  backgroundIMG = '../../assets/wallet/bg.png';

  load_seller = false;
  chartData = [{ name: 'הכנסות', series: [] }];
  lineColorScheme = {
    domain: ['#75b875', '#52b452']
    // domain: ['#75b875', 'transparent']
  };

  slideOpts = {
    initialSlide: 1,
    speed: 400,
    loop: false
  };
  wallet_amount = 2000;
  config = {
    sideMenu: 'top',
    dragThreshold: 150000,
    // shortSwipeDuration: 70000,
    allowElementScroll: false,
    // transitionDuration: 70000,
    scrollable: true,
    // sideMenuThreshold:500
  };
  tabIndex = 1;
  withdrawals_init = false;
  sales_loaded = false;
  // curve = curveCatmullRom.alpha(1);
  curve;

  chart_period = 'month';

  invoice_supplier = [];
  onTabChange(event) {
    console.log(event);
    if (event.detail.changed) {
      this.tabIndex = event.detail.index;
      console.log('this.tabIndex', this.tabIndex);
      if (this.tabIndex == 0 && !this.withdrawals_init) {
        this.get_withdrawals();
        this.get_next_withdrawal();

      }

    }
  }




  check_user_invoice() {
    // this.shopS.shopData.Setting.invoice_supplier = null;
    this.invoiceS.check_user_invoice();
  }




  async openModal(type) {
    let modal_type;
    this.walletS.info_modal = type;
    switch (type) {
      case 'business-info':
      case 'contact-info':
      case 'bank-info':
      case 'plan-info':
      case 'document-info':
      case 'contact-us':
      case 'agreement-info':

        modal_type = WalletModalInfoComponent;
        break;

      default:
        break;
    }

    const modal = await this.modalController.create({
      component: modal_type,
      swipeToClose: true,
      cssClass: 'modalForPayments modalGreyBG modal-half-hight',
      mode: "ios",
    });
    return await modal.present();




  }


  doRefresh(event) {

  }



  show_next_withdraw(next_withdraws) {
    alert(JSON.stringify(next_withdraws));
  }

  withdrawals_array = [];
  next_withdraws: any = {
    date: '',
    total: 0,
    data: []
  };
  get_next_withdrawal() {
    this.next_withdraws = {
      date: '',
      total: 0,
      data: []
    };

    console.log('get_next_withdrawal');
    console.log('withdraws_from_sales_array', this.withdraws_from_sales_array);
    let current_month = new Date().getMonth() + '.' + new Date().getFullYear();
    console.log('current_month', current_month);
    let next_month = new Date().getMonth() + 2 + '.' + new Date().getFullYear();
    console.log('next_month', next_month);
    if (this.withdraws_from_sales_array[next_month]) {

      let next_month_data = JSON.parse(JSON.stringify(this.withdraws_from_sales_array[next_month]));
      this.next_withdraws.date = next_month;
      next_month_data.forEach(sell => {
        console.log('sell: ', sell);
        if (sell.sale_status == "completed") {

          this.next_withdraws['total'] += parseInt(sell.sale_price_after_fees);

        }
      })
      this.next_withdraws.data = next_month_data;
      this.next_withdraws.withdrawal_description = 'צפי למשיכה הבאה';
      this.next_withdraws.withdrawal_currency = 'ILS';

      console.log('this.next_withdraws', this.next_withdraws);
    }// end of if 
    else {
      // setTimeout(() => {
      //   this.get_next_withdrawal()
      // },2000)
    }


  }

  get_withdrawals() {
    console.log('get withrows', this.walletS.shopS.shopData.Setting.BarberID)
    this.walletS.send_to_api('get-withdrawals', { seller_id: this.walletS.shopS.shopData.Setting.BarberID }, value => {
      console.log('withdrawals', value)
      if (value.status) {
        this.withdrawals_array = value.res.items;

      }
    });
    // this.withdrawals_init = true;
  }

  // toggleSwitch(field,event) {
  //   console.log(field,event);
  //   this.shopS.shopData.Setting[field] = !this.shopS.shopData.Setting[field];

  // }

  payment_requests: any = [];
  current_month;


  ngOnInit() {
    for (let i = 0; i < 12; i++) {
      let date = new Date(new Date().setMonth(new Date().getMonth() - i));
      let obj = {
        display: this.monthNames[date.getMonth()] + ' ' + date.getFullYear(),
        month: date.getMonth(),
        year: date.getFullYear(),
        date: date
      }
      this.months.push(obj);
      if (i == 0) {
        this.current_month = obj;
      }
    }
    console.log(this.months);

    if (this.shopS.wallet_loaded_before) {
      this.load_seller = true
    } else {
      this.load_seller = false

    }
    this.curve = curveBasis;

    console.log('this.shopS.shopData.Wallet', this.shopS.shopData.Wallet);
    // this.shopS.start_listen_wallet_settings();
    if (!this.shopS.shopData.Wallet) {
      // this.shopS.shopData['Wallet'] = {};
    } else {
      if (this.shopS.shopData.Wallet.files_submit) {
        this.walletS.current_toast_message.toast_data = JSON.parse(JSON.stringify(this.walletS.toast_message.toast_ongoing_data))
        this.walletS.current_toast_message.toast_data_inline = JSON.parse(JSON.stringify(this.walletS.toast_message.toast_ongoing_data_inline))
      } else {
        this.walletS.current_toast_message.toast_data = JSON.parse(JSON.stringify(this.walletS.toast_message.toast_warning_data))
        this.walletS.current_toast_message.toast_data_inline = JSON.parse(JSON.stringify(this.walletS.toast_message.toast_warning_data_inline))
      }

    }

    this.invoice_supplier = this.invoiceS.invoice_supplier.filter(x => x.name == this.shopS.shopData.Setting.invoice_supplier);
    // this.invoice_supplier = [];
    setTimeout(() => {
      if (!this.shopS.shopData.Wallet.documents) {
        this.shopS.shopData.Wallet['documents'] =
        {
          display: 'yes',
        };
      }
    }, 2000);


    this.get_subscription();
    this.get_sales(this.months[11].date, this.months[0].date, 'sales');
    this.get_sales(this.months[0].date, this.months[0].date, 'charts');

    // this.get_sales(date.getMonth(), date.getFullYear(), this.getChartData.bind(this));

    console.log(this.sales);



    // this.shopS.get_payment_requests(this.walletS.shopS.shopData.Setting.BarberID,cb=>{
    //   this.payment_requests = cb;
    //   console.log("payment_requests array", this.payment_requests);
    //   console.log("sales_array", this.sales_array);
    // })

    this.shopS.get_payment_requests(this.walletS.shopS.shopData.Setting.BarberID).then(res => {
      console.log(res.val());

      if (res.val()) {
        this.payment_requests = this.shopS.json2array(res.val());
        console.log('payment_requests', this.payment_requests);
      }
    });




    this.walletS.send_to_api('get_seller', { seller_id: this.walletS.shopS.shopData.Setting.BarberID }, value => {
      if (value.status == 'no auth') {
        setTimeout(() => {
          this.ngOnInit();
        }, 500)
        return
      }

      value = value.res;
      console.log(value);
      if (value.status_code == 0 && value['items'].length > 0) {

        value['items'][0]['currentFee'] = parseFloat(value['items'][0]['seller_fees']['fee_default_processing_fee']) + parseFloat(value['items'][0]['seller_fees']['fee_market_fee'])
        value['items'][0]['seller_fees']['fee_foreign_processing_fee'] = parseFloat(value['items'][0]['seller_fees']['fee_foreign_processing_fee']);

        this.walletS.current_seller = value['items'][0];
        this.shopS.wallet_loaded_before = true;

        this.set_bank_img()


        this.load_seller = true;
        this.walletS.load_seller = true;


      } else {
        alert('error')
      }
    })


  }




  set_bank_img() {
    console.log('seller_bank_account_code', this.walletS.current_seller.seller_business_details.seller_bank_account_code);
    this.walletS.current_seller.seller_business_details['seller_bank_account_img'] = 'dssadsadas';
    console.log('seller_bank_account_img', this.walletS.current_seller.seller_business_details.seller_bank_account_img);

    let found = this.walletS.banks.filter(x => x.value == parseInt(this.walletS.current_seller.seller_business_details.seller_bank_account_code));
    console.log('found', found);
    this.walletS.current_seller.seller_business_details['seller_bank_account_img'] = found[0].src;
    console.log('seller_bank_account_img', this.walletS.current_seller.seller_business_details.seller_bank_account_img);

    // this.walletS.current_seller.seller_business_details.seller_bank_account_code;
  }



  sales = [];

  textcheck = '';

  dateFormat(date) {
    let newDate = new Date(date);

    let newDate_day = newDate.getDate();
    let newDate_month = (newDate.getMonth() + 1);
    let newDate_year = newDate.getFullYear();
    let newDate_hours = newDate.getHours();
    let newDate_minutes = newDate.getMinutes();
    let newDate_seconds = newDate.getSeconds();

    let fixedDate = newDate_year + '-' + length_two(newDate_month) + '-' + length_two(newDate_day) + ' ' + length_two(newDate_hours) + ':' + length_two(newDate_minutes) + ':' + length_two(newDate_seconds);

    function length_two(num) {
      let fixedNum = num.toString();
      if (fixedNum.length == 1) {
        fixedNum = '0' + fixedNum;
      }
      return fixedNum;
    }

    return fixedDate;
  }

  setCurrentMonth(month) {
    this.current_month = month;
    this.get_sales(this.current_month.date, this.current_month.date, 'charts');
  }

  get_sales(start, end, type) {
    this.sales_loaded = false;
    // let date = new Date();
    // date.setFullYear(year);
    // date.setMonth(month);
    // let month_start = new Date(date.setDate(1)).setHours(0, 0, 0, 0);
    // let copyMonthStart = new Date(new Date(date.setDate(1)).setHours(0, 0, 0, 0));
    // let month_end = new Date(copyMonthStart.setMonth(copyMonthStart.getMonth() + 1)).setHours(0, 0, 0, -1);

    // // if (loadMore) {
    // //   month_start = new Date(month_start).setMonth(new Date(month_start).getMonth() - loadMore);
    // // }
    // console.log(month_start);
    // console.log(month_end);
    start = new Date(new Date(start).setDate(1)).setHours(0, 0, 0, 0);
    // end = new Date(new Date(new Date(end).setDate(0)).setMonth(end.getMonth() + 1)).setDate(0);
    // end = new Date(new Date(end).setDate(0)).setMonth(end.getMonth() + 1)).setDate(0);
    end = new Date(new Date(new Date(end).setMonth(new Date(end).getMonth() + 1)).setDate(1)).setHours(0, 0, 0, -1);
    console.log(new Date(start));
    console.log(new Date(end));

    let data = {
      // seller_payme_id: this.walletS.shopS.shopData.Wallet.wallet_id,
      sale_created_min: this.dateFormat(start),
      sale_created_max: this.dateFormat(end),
      // seller_payme_id: this.walletS.shopS.shopData.Wallet.wallet_id
      seller_id: this.walletS.shopS.shopData.Setting.BarberID
    }
    console.log('GETSALEDATA', data);

    this.walletS.send_to_api('get-sales', data, val => {
      val = val.res;
      if (val) {
        console.log(val);
        if (val['status_code'] == 0) {
          if (type == 'sales') {
            this.set_sale_array(val.items);
          } else if (type == 'charts') {
            this.getChartData(val.items);
          }
          // this.getChartData(val.items);
        }

      }
    });
  }

  searchText: any = '';
  sales_array = [];
  withdraws_from_sales_array: any = [];

  set_sale_array(val) {
    var document_obj = {};
    var withdraws_obj = {};
    let clients_array = this.shopS.json2array2(this.shopS.shopData.Users);
    // console.log('clients_array.Users',clients_array)
    console.log(val);

    val.forEach(element => {
      let client_phone_index = clients_array.findIndex(x => x.phone == element.sale_buyer_details.buyer_phone)
      if (client_phone_index == -1) {
        // no client in listt
        element.sale_buyer_details.buyer_name = element.sale_buyer_details.buyer_name
        // + ' (לא ברשימת הלקוחות)'
      } else {
        element['sale_client_details'] = clients_array[client_phone_index];
      }
      let before = element.sale_price;
      let after = element.sale_price_after_fees;
      let total_fee = (before - after) / before;
      element.total_fee = parseFloat((total_fee * 100).toFixed(2));

      // witheraws
      if (withdraws_obj[new Date(element.sale_release_date).getMonth() + 1 + '.' + new Date(element.sale_release_date).getFullYear()]) {
        withdraws_obj[new Date(element.sale_release_date).getMonth() + 1 + '.' + new Date(element.sale_release_date).getFullYear()].push(element);
      } else {
        withdraws_obj[new Date(element.sale_release_date).getMonth() + 1 + '.' + new Date(element.sale_release_date).getFullYear()] = [];
        withdraws_obj[new Date(element.sale_release_date).getMonth() + 1 + '.' + new Date(element.sale_release_date).getFullYear()].push(element);
      }

      // this.withdraws_from_sales_array  = this.shopS.json2array(withdraws_obj).reverse();
      this.withdraws_from_sales_array = withdraws_obj;



      // add document
      if (document_obj[new Date(element.sale_paid_date).getMonth() + '.' + new Date(element.sale_paid_date).getFullYear()]) {
        document_obj[new Date(element.sale_paid_date).getMonth() + '.' + new Date(element.sale_paid_date).getFullYear()].push(element);
      } else {
        document_obj[new Date(element.sale_paid_date).getMonth() + '.' + new Date(element.sale_paid_date).getFullYear()] = [];
        document_obj[new Date(element.sale_paid_date).getMonth() + '.' + new Date(element.sale_paid_date).getFullYear()].push(element);
      }
      // console.log(element.sale_paid_date)
      // console.log(new Date(element.sale_paid_date).getMonth())
    });
    console.log(document_obj);
    let arr = this.shopS.json2array(document_obj);

    // this.sales_array_chart = arr;
    // if (this.chart_period != '6month') {
    this.sales_array = arr;
    // }
    console.log(this.sales_array);

    // this.sales_array = val;
    // this.getChartData(val);
    console.log('this.sales_array', this.sales_array);

    this.walletS.sales_array = this.sales_array;

    console.log('withdraws_from_sales_array:', this.withdraws_from_sales_array);

    this.sortSalesPayment();
  }

  sortSalesPayment() {

    this.sales_array[0].forEach(s => {
      s['date'] = new Date(s.sale_paid_date).getTime()
    })

    this.payment_requests.forEach(paymentRequest => {
      let year = new Date(paymentRequest.date).getFullYear();
      let month = new Date(paymentRequest.date).getMonth();
      if (this.sales_array[0].key.split('.')[0] == month && this.sales_array[0].key.split('.')[1] == year) {
        if (paymentRequest.payment_status != 'completed') {
          this.sales_array[0].push(paymentRequest);
        }
      }
    });

    this.sales_array[0].sort((a, b) => b.date - a.date)

    console.log("push(this.payment_requests)", this.sales_array);
  }

  sales_array_chart = [];

  // changePeriod(period) {
  //   this.chart_period = period;
  //   let date = new Date();
  //   if (period == '6month') {
  //     this.get_sales(date.getMonth(), date.getFullYear(), this.getChartData.bind(this), 6);
  //   } else {
  //     this.get_sales(date.getMonth(), date.getFullYear(), this.getChartData.bind(this));
  //   }
  //   // this.getChartData();
  // }

  getChartData(data) {
    console.log(data);

    this.chartData = [{ name: 'הכנסות', series: [] }];
    if (data.length) {
      // let today_start = new Date().setHours(0, 0, 0, 0);
      let maxVal = 0;
      // data.forEach(sale => {
      //   sale.timeStamp = new Date(sale.sale_created).setHours(0, 0, 0, 0);
      //   sale.date
      // });
      let days;
      if (this.current_month.month == new Date().getMonth() && this.current_month.year == new Date().getFullYear()) {
        days = new Date().getDate();
      } else {
        let month_end = new Date(this.current_month.year, this.current_month.month + 1, 0);
        days = month_end.getDate();
      }
      console.log(days);

      for (let i = 1; i <= days; i++) {
        this.chartData[0].series.push({
          name: i + '/' + (this.current_month.month + 1),
          value: 0,
          min: 0,
          max: 0
        });
      }
      console.log(this.chartData);

      data.forEach(sale => {
        let date_name = new Date(sale.sale_created).getDate() + '/' + (new Date(sale.sale_created).getMonth() + 1);

        let foundDay = this.chartData[0].series.find(day => day.name == date_name);
        if (foundDay) {
          foundDay.value += (sale.sale_price) / 100;
          foundDay.max += (sale.sale_price) / 100;
          maxVal = foundDay.max > maxVal ? foundDay.max : maxVal;
        }
        // else {
        //   this.chartData[0].series.push({
        //     name: date_name,
        //     value: (sale.sale_price) / 100,
        //     min: 0,
        //     max: (sale.sale_price) / 100
        //   });
        //   maxVal = (sale.sale_price) / 100 > maxVal ? (sale.sale_price) / 100 : maxVal;
        // }

      });

      this.chartData[0].series.forEach(dat => {
        dat.min = 0 - (0.01 * maxVal);
      });

      console.log(this.chartData);
    }
    this.sales_loaded = true;
  }


  currentMonth = [];



  minY = 0;

  // chartDataDisplay = [];

  subscriptions = [];

  aaaa = 0;

  get_subscription() {
    let data = {
      // seller_payme_id: this.walletS.shopS.shopData.Wallet.wallet_id
      seller_id: this.walletS.shopS.shopData.Setting.BarberID

    }
    this.walletS.send_to_api('get-subscriptions', data, val => {

      val = val.res;
      // this.sales
      if (val) {
        if (val['status_code'] == 0) {
          // ok
          this.subscriptions = val.items;
        }
        console.log('get-subscriptions', val);
      }
    });

  }





  refund(payme_sale_id, sale_refund_amount) {
    let data = {
      seller_payme_id: this.walletS.shopS.shopData.Wallet.wallet_id,
      payme_sale_id: payme_sale_id,
      sale_refund_amount: sale_refund_amount
    }
    this.walletS.send_to_api('refund-sale', data, val => {
      val = val.res;
      console.log(val);
      this.walletS.presentAlert('create-seller', JSON.stringify(val));
    });


  }




  // async generate_subscription() {
  //   this.walletS.generate_subscription.seller_payme_id = this.walletS.current_seller.seller_payme_id;
  //   this.walletS.generate_subscription.sale_email = this.walletS.userService.user.email;
  //   this.walletS.generate_subscription.sale_mobile = this.walletS.userService.user.phone;
  //   this.walletS.generate_subscription.sale_name = this.walletS.userService.user.fname + " " + this.walletS.userService.user.lname;
  //   const modal = await this.modalController.create({
  //     component: WalletGenerateSubscriptionComponent,
  //     cssClass: 'my-custom-class',
  //     mode: 'ios',
  //   });
  //   return await modal.present();


  // }

  get_subscription_now(num) {
    let data = { sale_parent_code: num };
    this.walletS.send_to_api('get-sales', data, val => {
      val = val.res;
      // this.sales
      if (val['status_code'] == 0) {
        // ok
        // this.subscriptions = val.items;
      }
      console.log('get-sales_now', val);
    });
  }

  payOptionsModal = false;

  stopPar(ev) {
    ev.stopPropagation();
  }

  requestPay(ev) {
    ev.stopPropagation();
    this.walletS.openModal('request', {});
    this.payOptionsModal = false;
  }

  methodChoose = false;

  pickPaymentMethod(ev) {
    ev.stopPropagation();
    this.methodChoose = true;
  }

  payCredit(ev) {
    ev.stopPropagation();
    this.walletS.openModal('transfer_credit', {});
    this.payOptionsModal = false;
    this.methodChoose = false;
  }

  payBit(ev) {
    ev.stopPropagation();
    this.walletS.openModal('transfer_bit', {});
    this.payOptionsModal = false;
    this.methodChoose = false;
  }

  payOptions() {
    this.payOptionsModal = true;
  }

  closeModal() {
    this.payOptionsModal = false;
    this.methodChoose = false;
  }

  // async generate_sale(ev) {
  //   ev.stopPropagation();
  //   this.walletS.generate_sale.seller_payme_id = this.walletS.current_seller.seller_payme_id;
  //   this.walletS.generate_sale.sale_email = this.walletS.userService.user.email;
  //   this.walletS.generate_sale.sale_mobile = this.walletS.userService.user.phone;
  //   this.walletS.generate_sale.sale_name = this.walletS.userService.user.fname + " " + this.walletS.userService.user.lname;
  //   const modal = await this.modalController.create({
  //     component: WalletGenerateSalePage,
  //     cssClass: 'my-custom-class',
  //     mode: 'ios',
  //   });
  //   return await modal.present();
  // }


  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  send_documents() {
    // console.log(this.shopS.shopData.Wallet.documents);
    // console.log(this.shopS.json2array(this.shopS.shopData.Wallet.documents));
    let data_to_send = {
      seller_id: this.walletS.shopS.shopData.Setting.BarberID,
      seller_files: this.shopS.json2array(this.shopS.shopData.Wallet.documents)
    };
    console.log(data_to_send);
    this.walletS.LoadingService.presentforever();
    this.walletS.send_to_api('upload-seller-files', data_to_send, value => {
      console.log('upload-seller-files', value)
      value = value.res;
      // this.sales
      if (value['status_code'] == 0) {
        this.walletS.LoadingService.dismiss();
        this.walletS.ToastService.showToast('המסמכים נשלחו בהצלחה');
        this.shopS.shopData.Wallet['files_submit'] = true;
        this.walletS.afDb.object('/BarberShop/' + this.shopS.shopData.Setting.BarberID + '/Wallet').update({ files_submit: true });
        this.walletS.current_toast_message.toast_data = JSON.parse(JSON.stringify(this.walletS.toast_message.toast_ongoing_data))
        this.walletS.current_toast_message.toast_data_inline = JSON.parse(JSON.stringify(this.walletS.toast_message.toast_ongoing_data_inline))

        // ok
        // this.subscriptions = val.items;
      } else {
        this.walletS.LoadingService.dismiss();
        this.walletS.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');

      }
    });

  }






  typeSetting;
  currentImg = {
    Social_Id: '',
    Bank_Account: '',
    Corporate_Certificate: '',
    Processing_Agreement: ''
  };
  message;
  myFiles = {
    Social_Id: '',
    Bank_Account: '',
    Corporate_Certificate: '',
    Processing_Agreement: ''

  };
  preview(event, where) {
    this.typeSetting = where;
    this.currentImg[where] = event;
    console.log('this.currentImg:', this.currentImg);
    var filess = event.srcElement.files;
    // console.log('filess:',filess);

    if (filess.length === 0)
      return;

    var mimeType = filess[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(filess[0]);
    reader.onload = (_event) => {
      console.log('reader.result', reader.result);
      console.log('this.mySetting', this.myFiles);
      this.myFiles[where] = reader.result;
      this.shopS.UploadFlags[where] = true;
      this.uploadThatImg(where);
    }
  }




  uploadThatImg(type) {
    console.log('uploadThatImg', this.currentImg, this.typeSetting);
    this.loadingService.presentforever();
    console.log(type);

    console.log(this.currentImg[type]);

    let result = this.shopS.uploadFile(this.currentImg[type], '/Wallet/documents/', type).then(resullt => {
      // this.loadingService.dismiss();
    }).catch((e) => {
      this.walletS.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');

      this.loadingService.dismiss();
    });
    console.log('result:', result);
  }



  async view_document(c) {
    console.log('c', c)
    this.walletS.view_doc = c;
    // let modalid = 'dadsa'+new Date().getTime();

    this.walletS.modal_view_doc = await this.modalCtrl.create({
      component: WalletViewDocComponent,
      swipeToClose: true,
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom modalTransparent' : 'modalLookLikeNativ modalTransparent',
      backdropDismiss: this.shopS.platform == 'desktop' ? true : false,
      mode: "ios",
      // id:modalid,
      // enterAnimation: this.ModalAnimationService.iosEnterAnimation_point,
      // leaveAnimation: this.ModalAnimationService.iosLeaveAnimation_point,
      //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
      // Get the top-most ion-modal

    });
    await this.walletS.modal_view_doc.present();
    this.walletS.modal_view_doc.onDidDismiss().then(res => {
      console.log(res);

    })

    // this.walletS.modal_view_doc.onWillDismiss().then(res => {
    //   console.log('sadasdasad',res); 
    //   document.getElementById(modalid).classList.remove("backdrop-filter-modal")
    // })
    return this.walletS.modal_view_doc;

  }





  ngOnDestroy() {
    this.shopS.stop_listen_wallet_settings();
  }

}
