import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PaymentService } from "../payment-service/payment.service";
import { LoadingService } from 'src/providers/loading.service';
import { ModalController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';
import { userService } from 'src/ShopService/user.service';

@Component({
  selector: 'app-add-card-iframe',
  templateUrl: './add-card-iframe.component.html',
  styleUrls: ['./add-card-iframe.component.scss'],
})
export class AddCardIframeComponent implements OnInit {
  iframe: any;
  iframeHTML: any = '<div></div>';

  constructor(private LoadingService: LoadingService, private userService: userService,
    public sanitizer: DomSanitizer, public PaymentService: PaymentService, public ModalController: ModalController, public shopS: ShopService) { }

  ngOnInit() { }

  dismissModal() {
    this.ModalController.dismiss();
  }


  async creditguard_iframe() {
    var self = this;

    this.LoadingService.presentforever_payment_iframe();
    await this.PaymentService.iframe().subscribe((respone: any) => {


      self.PaymentService.iframeUrl = respone['_body'];

      self.PaymentService.transaction_id = self.PaymentService.iframeUrl.split('=');
      self.PaymentService.transaction_id = self.PaymentService.transaction_id[1].replace(/\s/g, '');


      self.PaymentService.set_new_transaction_iframe(self.userService.getUserObj().key);

      self.iframe = self.PaymentService.iframeUrl;
      var trustURL: any = self.sanitizer.bypassSecurityTrustResourceUrl(this.iframe);
      console.log('trustURL', trustURL);
      self.iframeHTML = self.sanitizer.bypassSecurityTrustHtml('<iframe id="wedcapp" name="wedcapp" style="width: 100%;height: 100vh;    overflow: hidden; position: relative; top: 0px; left: 0px; border: 0px;" class="iframe-full" src="' + trustURL.changingThisBreaksApplicationSecurity + '" > </iframe>');

      // var iframe:any = document.getElementById('wedcapp');
      // var style = document.createElement('style');
      // style.textContent =
      //   'body {' +
      //   '  background-color: red;' +
      //   '}' 
      // ;
      // iframe.contentDocument.head.appendChild(style);



      self.transtaction_listen();

      setTimeout(() => {
        self.LoadingService.dismiss();
      }, 1500);


    });

  }

  async ionViewWillEnter() {

    var self = this;
    this.LoadingService.presentforever_payment_iframe();
    await this.PaymentService.new_iframe('generate-token', this.shopS.us.getUserObj(), respone => {

      console.log('now!', respone)

      self.PaymentService.iframeUrl = respone.res.sale_url;

      self.PaymentService.transaction_id = respone.res.payme_sale_id
      self.PaymentService.transaction_data = respone.res


      self.PaymentService.set_new_transaction_iframe(self.userService.getUserObj().key);

      self.iframe = self.PaymentService.iframeUrl;
      var trustURL: any = self.sanitizer.bypassSecurityTrustResourceUrl(this.iframe);
      console.log('trustURL', trustURL);
      self.iframeHTML = self.sanitizer.bypassSecurityTrustHtml('<iframe id="wedcapp" name="wedcapp" style="width: 100%; height: 100%; overflow: hidden; position: relative; top: 0px; left: 0px; border: 0px;" class="iframe-full" src="' + trustURL.changingThisBreaksApplicationSecurity + '" > </iframe>');

      self.transtaction_listen();

      setTimeout(() => {
        self.LoadingService.dismiss();
      }, 1500);


    });

  }



  transtaction_listen() {
    // console.log('transaction_id',this.PaymentService.transaction_id)
    this.PaymentService.listen_new_transaction_iframe();
  }







}
