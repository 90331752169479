import { Injectable } from '@angular/core';
import { PremiumLockComponent } from "../premium-lock/premium-lock.component";
import { ModalController } from '@ionic/angular';
import { PricePlansPage } from "../price-plans/price-plans.page";
import { PaymentService } from "../../payments/payment-service/payment.service";

//import { PricePlansPageModule } from "./price-plans/price-plans.module";

@Injectable({
  providedIn: 'root'
})
export class PremiumService {

  constructor(
    public PaymentService:PaymentService,
    public modalCtrl: ModalController,

  ) { }




////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////




async PremiumLock(data){
    
  let classType= 'modalLookLikeNativ'
  let classType2= 'modalLookfullWidth'


  var modal = await this.modalCtrl.create({
    component: PremiumLockComponent,
    swipeToClose: false,
    cssClass: classType2,
    backdropDismiss:false,
    mode:"ios"

  //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal

    
   // Get the top-most ion-modal

  });

  

  await modal.present();
  modal.onDidDismiss().then(res=>{
    console.log(res);
    if(res.data){

  }
  })
  return modal;
}


////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////






////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////




async pricePlan(){
    
  var modal = await this.modalCtrl.create({
    component: PricePlansPage,
    swipeToClose: true,
    cssClass: 'modalLookLikeNativ',
    backdropDismiss:true,
    mode:"ios"

  //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal

    
   // Get the top-most ion-modal

  });

  

  await modal.present();
  modal.onDidDismiss().then(res=>{
    console.log(res);
    if(res.data){

  }
  })
  return modal;
}


////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////






}
