import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WalletServiceService } from '../wallet-service/wallet-service.service';

import { WalletShowNewSalePage } from '../wallet-show-new-sale/wallet-show-new-sale.page';
@Component({
  selector: 'app-wallet-generate-sale',
  templateUrl: './wallet-generate-sale.page.html',
  styleUrls: ['./wallet-generate-sale.page.scss'],
})
export class WalletGenerateSalePage implements OnInit {

  constructor(public walletS: WalletServiceService, public modalController: ModalController) { }

  ngOnInit() {
  }

  generate() {
    if (this.walletS.generate_sale.sale_type == "") {
      this.walletS.generate_sale.sale_type = undefined;
    }
    console.log(this.walletS.generate_sale);

    this.walletS.send_to_api('generate-sale', this.walletS.generate_sale, val => {
      val = val.res;

      console.log('dsds', val)
      this.walletS.show_new_sale = val;
      this.show_new_sale_modal();
    })
  }



  async show_new_sale_modal() {
    const modal = await this.modalController.create({
      component: WalletShowNewSalePage,
      cssClass: 'my-custom-class',
      mode: 'ios',
      componentProps: { iframe: this.walletS.show_new_sale.sale_url }
    });
    return await modal.present();

  }




  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
