import { Injectable } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { SortlistComponent } from '../sortlist.component';

@Injectable({
  providedIn: 'root'
})
export class SortlistService {

default_local = [
  {
    type: 'clients',
    data:'fname',
    order:'asc',
  }
]
  constructor( public modalController: ModalController,
    private PopoverController:PopoverController) { 

   this.local_types = localStorage.getItem('selectedTypes');

   if(this.local_types){
    console.log('this.current_type',this.local_types);
    this.local_types = JSON.parse(this.local_types);
   }else{
    console.log('this.current_type null',this.local_types);
    localStorage.setItem('selectedTypes',JSON.stringify(this.default_local));
    this.local_types = this.default_local;
   }

   this.local_types.forEach(element => {
     console.log(element);
     this.types[element.type].selected = element.data
     this.types[element.type].order = element.order
   });


   console.log(this.types)

   


  }
  local_types;
  current_type = '';

  types = {
    clients: {
      selected: '',
      order:'asc',
      preview: 'לקוחות',
      data:
      [
        {
          preview: ' מיון לפי שם ',
          content: 'fname'
        },
        {
          preview: ' מיון לפי תאריך הצטרפות',
          content: 'registrationdate'
        },
        {
          preview: ' מיון לפי נראה לאחרונה ',
          content: 'lastSeen'
        },
    ]
  }
  }


  async  open_sortlist_modal(type,ev){
this.current_type = type;
    console.log('type',type)
    const popover = await this.PopoverController.create({
      component: SortlistComponent,
      event: ev,
      translucent: true,
      mode:"ios",
    });

return await popover.present();  
  }



}
