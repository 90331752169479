import { Component, OnInit } from '@angular/core';
import { PaymentService } from "../payment-service/payment.service";

@Component({
  selector: 'app-change-card-subscription',
  templateUrl: './change-card-subscription.component.html',
  styleUrls: ['./change-card-subscription.component.scss'],
})
export class ChangeCardSubscriptionComponent implements OnInit {

  constructor(
    public PaymentService:PaymentService,
  ) { }
selected_FLAG = false;
  ngOnInit() {
    this.clearSeletion();
    console.log('this.PaymentService.currentSubscriptionEdit',this.PaymentService.currentSubscriptionEdit)
    this.PaymentService.selectedCard = this.PaymentService.currentSubscriptionEdit.ccard;
console.log('this.PaymentService.selectedCard',this.PaymentService.selectedCard)
  }


  changeSubscription(){
  //  console.log(this.PaymentService.selectedCard);
  //  console.log(this.PaymentService.currentSubscriptionEdit);

    this.PaymentService.currentSubscriptionEdit.ccard = {
      cardDisplay: this.PaymentService.selectedCard.cardDisplay,
      cardExpiration: this.PaymentService.selectedCard.cardExpiration,
      cardNo: this.PaymentService.selectedCard.cardNo,
      cardType: this.PaymentService.selectedCard.cardType,
      fullName: this.PaymentService.selectedCard.fullName,
      key: this.PaymentService.selectedCard.key,
      tz: this.PaymentService.selectedCard.tz,
    }
    
    console.log('after: ',this.PaymentService.currentSubscriptionEdit);

 this.PaymentService.updateSubscriptionCard(this.PaymentService.currentSubscriptionEdit.ccard,this.PaymentService.currentSubscriptionEdit.key)
 this.PaymentService.enableSubscription(this.PaymentService.currentSubscriptionEdit.key)

  }




  PushCard(x) {
    this.clearSeletion();
    this.selected_FLAG = true;
    console.log('x',x)
        x.active = true;
        this.PaymentService.selectedCard = x;
    }


    clearSeletion(){
      this.selected_FLAG = false;
      for (var key in this.PaymentService.userCards) {
      this.PaymentService.userCards[key]['active'] = false;
    }
  }





}




