import { userService } from './../../ShopService/user.service';
import { AlertExample } from './../../providers/alert.service';
import { ShopService } from './../../ShopService/shop.service';
import { Component, OnInit } from '@angular/core';

import { FormsModule, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertController, NavController } from '@ionic/angular';
import { TabsPage } from '../pages/tabs/tabs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { LocalStorageService } from '../../providers/local-storage.service';
import { Router } from '@angular/router';
import { ToastService } from '../../providers/toast-service';
import { ConfigService } from "../../app/config.service";


@Component({
  selector: 'add-client-modal',
  templateUrl: './add-client-modal.component.html',
  styleUrls: ['./add-client-modal.component.scss'],
})
export class addClientModalComponent implements OnInit {
  registrationForm2: FormGroup;
  PhoneErorr = false;;
  textProb = false;
  Nuser = {
    fname: '',
    lname: '',
    ProfilePic: null,
    phone: '',
    local: true,
  };
  constructor(
    public shopS: ShopService,
    private fb: FormBuilder,
    private AlertExample: AlertExample,
    private us: userService,
    private toast: ToastService

  ) { }

  ngOnInit() {

    // this.generateForm()

  }

  toggleModal() {
    // event.stopPropagation();
    this.shopS.userUpdatedFLAG = false;
    this.textProb = false;
    this.PhoneErorr = false;
    this.shopS.addClientmodalFLAG = !this.shopS.addClientmodalFLAG;
    this.cleanNuser();
    console.log('toggleModal=======this.shopS.addClientmodalFLAG', this.shopS.addClientmodalFLAG);
  }


  deleteUserFromBiz(user) {
    console.log('user: ', user);
    this.presentAlertConfirm(user);
    // if(confirm('פעולה זאת לא ניתנת לביטול')){
    //   this.shopS.deleteUserFrombiz(user);
    //   this.toast.showTostDanger('הלקוח נמחק ');

    //   this.shopS.addClientmodalFLAG = false;
    // }
  }


  async presentAlertConfirm(user) {
    const alert = await this.AlertExample.alertController.create({
      header: 'מחיקת לקוח',
      message: 'בטוחים שתרצו למחוק ?',
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'כן',

          handler: () => {

            this.shopS.deleteUserFrombiz(user);
            this.toast.showTostDanger('הלקוח נמחק ');

            this.shopS.addClientmodalFLAG = false;




          }
        }
      ]
    });
    await alert.present();
  }




  cleanNuser() {
    this.Nuser = {
      fname: '',
      lname: '',
      ProfilePic: null,
      phone: '',
      local: true,
    };
  }




  updateUser() {
    console.log(this.shopS.userUpdated);





    if (((this.us.PhoneValidtionIsrael(this.shopS.userUpdated.phone) || this.shopS.userUpdated.phone == "")) && ((this.shopS.userUpdated.fname != "") || (this.shopS.userUpdated.lname != ""))) {

      var self = this
      var useDate = Object.values(this.shopS.shopData.Users).filter(function (el: any) {
        return el.phone == self.shopS.userUpdated.phone &&
          el.phone != "" && el.key != self.shopS.userUpdated.key
      });

      // alert(useDate.length)
      //         return ;
      if (useDate.length == 0) {
        this.shopS.updateUserToBarberNew(this.shopS.userUpdated, this.shopS.userUpdated.key);
        this.toast.showToast('הלקוח עודכן בהצלחה');
        // this.shopS.addClientmodalFLAG = !this.shopS.addClientmodalFLAG;
        // this.textProb = false;
        // this.PhoneErorr = false;
        // this.shopS.userUpdatedFLAG = false;
        // this.cleanNuser();
        this.toggleModal();
      } else {
        console.log(useDate.length);
        
        this.AlertExample.aleryByText("מספר הטלפון כבר קיים במערכת")
      }

    } else {
      this.AlertExample.aleryByText("נא למלא שם וטלפון תקינים")
    }
  }
  newUser(event) {





    if (((this.us.PhoneValidtionIsrael(this.Nuser.phone) || this.Nuser.phone == "")) && ((this.Nuser.fname != "") || (this.Nuser.lname != ""))) {
      this.Nuser.phone = this.Nuser.phone.replace(/\s/g, '');
      this.Nuser.phone = this.Nuser.phone.replace(/-/g, '');


      var self = this;


      var useDate = Object.values(this.shopS.shopData.Users).filter(function (el: any) {
        return el.phone == self.Nuser.phone &&
          el.phone != ""
      });

      console.log("useDateuseDate", useDate)

      if (useDate.length == 0) {
        self.Nuser.ProfilePic = self.shopS.profilePicLocalUser;
        self.toast.showToast('הלקוח נוסף בהצלחה');
        self.shopS.addUserToBarber(this.Nuser);
        // self.shopS.addClientmodalFLAG = !this.shopS.addClientmodalFLAG;
        // self.textProb = false;
        // self.PhoneErorr = false;
        // this.shopS.userUpdatedFLAG = false;
        // console.log(this.Nuser);
        

        // self.cleanNuser();
        this.toggleModal();

      } else {
        self.AlertExample.aleryByText("מספר הטלפון כבר קיים במערכת")
      }



    } else {
      if (!this.us.PhoneValidtionIsrael(this.Nuser.phone) && this.Nuser.phone != "") {
        this.PhoneErorr = true;
      } else {
        this.PhoneErorr = false;
      }


      if ((this.Nuser.fname == "") && (this.Nuser.lname == "")) {
        this.textProb = true;
      } else {
        this.textProb = false;
      }


    }



  }
  newLine(event, u) {
    event.stopPropagation();

    console.log('newLine-u', u);
    this.shopS.currentMeet.user = u;
    this.shopS.ClientmodalFLAG = false;
    this.shopS.navAdminTab('add');

  }



}

function validMobileNumber(number) {
  let regex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
    result = regex.test(number);
  console.log(number, result);
  return result;
}
