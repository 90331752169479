import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShopService } from '../../ShopService/shop.service';
import { AlertController, PopoverController } from '@ionic/angular';
import { NotificationToAllComponent } from "../../app/notification-to-all/notification-to-all.component";
import { ModalController } from '@ionic/angular';
import { PremiumService } from '../premium/premium-service/premium.service';
import { ContactListService } from '../contact-list/contact-list-service/contact-list.service';
import { ContactListComponent } from '../contact-list/contact-list.component';
import { LoadingService } from 'src/providers/loading.service';
import { SocialSharingPage } from 'src/components/social-sharing/social-sharing.page';
// import { environment } from '../environments/environment';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  ggg = environment;
  constructor(
    public shopS: ShopService,
    private alertController: AlertController,
    private modalController: ModalController,
    public popoverController: PopoverController,
    private premiumService: PremiumService,
    public contactsS: ContactListService,
    public LoadingService: LoadingService
  ) { }

  ngOnInit() {

    console.log('config', this.shopS.configDATA.premium_config.noti_to_all);

  }

  // goTOaddContacts(){
  //   this.router.navigate(['tabsAdmin/contacts']);
  //   this.popoverController.dismiss();
  // }



  async toggleModalShare_new(event) {

    const modal = await this.modalController.create({
      component: SocialSharingPage,
      swipeToClose: true,
      cssClass: 'popupModal',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",

      // Get the top-most ion-modal

    });


    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data) {
      if (data.dismissed == "finish") {

      }
    }
    return modal;

  }


  async goTOaddContacts() {

    let point_contact_list_premission = localStorage.getItem('point_contact_list_premission');
    if (point_contact_list_premission == 'true') {
      console.log('point_contact_list_premission', point_contact_list_premission)
      this.LoadingService.presentforever_with_text('טוען אנשי קשר');
      this.open_contacts();
    } else {
      console.log('point_contact_list_premission', point_contact_list_premission)


      var textT = `<img class='imgalert2 contactimg' src="../../assets/contactlist.png" >`;
      textT += 'לאחר הודעה זו תופיע בקשת גישה לאנשי הקשר במכשיר שלך. חשוב לאשר את הבקשה';
      const alert = await this.alertController.create({
        header: 'ייבוא מאנשי קשר',
        message: textT,
        buttons: [
          {
            text: 'מאוחר יותר',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Cancel Cancel: Cancel');
              return false;
            }
          }, {
            text: 'מתן הרשאות',
            handler: () => {

              console.log('Okay: Okay');
              //confirm order 
              this.LoadingService.presentforever_with_text('טוען אנשי קשר');
              this.open_contacts();

            }
          }
        ]
      });
      await alert.present();
    }
    //  this.LoadingService.presentLoadingContact()
  }
  
  open_contacts() {
    this.shopS.log(this.shopS.us.key, 'open_contacts');

    this.contactsS.loadContacts(cb => {
      console.log(cb);
      this.LoadingService.dismiss();

      if (cb) {
        localStorage.setItem('point_contact_list_premission', 'true')
        this.newModal();
      }
    })
  }

  async newModal() {

    const modal = await this.modalController.create({
      component: ContactListComponent,
      cssClass: 'modalLookLikeNativ',
      mode:'ios',
    });
    return await modal.present();
  }

  async sendNotificationToAll(): Promise<HTMLIonModalElement> {




    if (this.shopS.shopData.Setting.Premium.Statistics != "premium") {
      this.premiumService.PremiumLock("go");


    } else {






      this.popoverController.dismiss();

      const modal = await this.modalController.create({
        component: NotificationToAllComponent,
        swipeToClose: true,
        cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',
        //  presentingElement: this.routerOutlet.nativeEl,
        mode: "ios",

        // Get the top-most ion-modal

      });


      await modal.present();

      const { data } = await modal.onWillDismiss();

      if (data) {
        if (data.dismissed == "finish") {

        }
      }
      return modal;
    }
  }


  toggleModalShare(event) {
    event.stopPropagation();
    this.popoverController.dismiss();
    // this.shopS.SharemodalFLAG = !this.shopS.SharemodalFLAG;
    // console.log('toggleModal=======this.shopS.SharemodalFLAG',this.shopS.SharemodalFLAG);
    this.toggleModalShare_new(event);
  }

  toggleModaladdClientmodalFLAG(event) {
    event.stopPropagation();
    this.resetUsers()
    this.popoverController.dismiss();
    this.shopS.addClientmodalFLAG = !this.shopS.addClientmodalFLAG;
    console.log('toggleModal=======this.shopS.addClientmodalFLAG', this.shopS.addClientmodalFLAG);

  }

  resetUsers() {

    this.shopS.userUpdated = {
      fname: '',
      lname: '',
      ProfilePic: "",
      phone: '',
      key: "",
      local: "1",

    }
  }

}
