import { userD } from './../app/UserDClass';
import { Injectable, EventEmitter, OnChanges } from '@angular/core';
import { Http, Response } from '@angular/http';
import { BehaviorSubject, Observable } from 'rxjs-compat';
import { AngularFireDatabase } from '@angular/fire/database';
// import 'rxjs/add/operator/map';
// import { map } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ConfigService } from 'src/app/config.service';



@Injectable()

export class userService {


  userToken = '';
  // getUserEvent = new EventEmitter<any>();
  firstLetter: string;
  emptyUser = false;
  ProfilePic: string;
  BarberID: string;
  email: string;
  // fName: string;
  superNew = false;
  // lName: string;
  Birthday: string;
  StarsCount: number;
  key: any = '';
  emptykey: any = {

  };
  user: any = {
    email: '',
    fname: '',
    lname: '',
    ProfilePic: '',
    isValid: ''


  }


  constructor(
    private afDb: AngularFireDatabase
    // private ConfigService: ConfigService
  ) {

  }
  userUid: string;

  resetUser() {
    this.user = {
      email: '',
      fname: '',
      lname: '',
      ProfilePic: '',
      isValid: ''

    }
  }
  // getFirstLetter() {
  //   if (this.user.fName) {
  //     this.firstLetter = this.user.fName.substring(0, 1);
  //   } else if (this.user.lName) {
  //     this.firstLetter = this.user.lName.substring(0, 1);
  //   }
  //   console.log(this.firstLetter);
  // }


  addDevice() {
    const userAgent = window.navigator.userAgent;
    // const device = {
    //   name: userAgent,
    //   date: new Date(),
    //   active: false
    // }

    if (this.user.Devices) {
      // let devices:any = Object.values(this.user.Devices);
      // let devicesNames = devices.map(dd => dd.name);
      if (this.user.Devices.indexOf(userAgent) == -1) {
        this.afDb.list('/Users/' + this.user.key + '/Devices/').push(userAgent);
        this.user.Devices.push(userAgent);
      }
    } else {
      this.afDb.list('/Users/' + this.user.key + '/Devices/').push(userAgent);
      this.user.Devices = [userAgent];
    }
  }

  // checkUserExciting() {
  //   // if there is no data in user service object user
  //   if (!(this.user.fName && this.user.lName)) {
  //     // we check local storage for uid number
  //     if (this.checkLocalStorage()) {
  //       const self = this;
  //       var getUserDetalisByUid = this.getUserDetalisByUid(this.userUid).snapshotChanges().subscribe((result) => {
  //         const getUserResult = result;
  //         console.log(getUserResult.payload.val());
  //         this.saveUserData(getUserResult.payload.val(), this.userUid);
  //         getUserDetalisByUid.unsubscribe();
  //       }, (error) => {
  //         console.log(error);
  //         // If we catch error we make auto log out and clean localStorage.
  //         //     self.authService.logout();
  //       });
  //     } else {
  //       //     this.authService.logout();
  //     }
  //   }
  // }


  // checkLocalStorage() {
  //   if (localStorage.getItem('user')) {
  //     const localStorageData = JSON.parse(localStorage.getItem('user'));
  //     if (localStorageData.uid) {
  //       this.userUid = localStorageData.uid;
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  updateUserDataNew(biz, id, userKey) {


    this.objectArray(biz); // REMOVE BIZOBJ

    return this.afDb.object('/Users/' + userKey).update({

      BarberID: id,
      bizArray: biz

    });
  }
  updateUserData(newUserData) {
    console.log("update userdata", this.user);
    if (this.user.BarberID) {
      let new_user_data = {
        email: newUserData.email,
        fname: newUserData.fname,
        key: this.key,
        lname: newUserData.lname,
        phone: this.user.phone,
      }


      return this.afDb.object('/BarberShop/' + this.user.BarberID + '/Users/' + this.key).update(new_user_data).then(x => {
        return this.afDb.object('/Users/' + this.key).update({
          ...newUserData
        });
      })
    } else {

      return this.afDb.object('/Users/' + this.key).update({
        ...newUserData
      });

    }

  }
  registrationdate() {
    this.user.registrationdate = new Date();
    return this.afDb.object('/Users/' + this.key).update({
      registrationdate: new Date()
    })

  }
  lastSeen() {
    var newDate = new Date();
    // console.log("this.userthis.userthis.userthis.userthis.userthis.user",this.user)


    this.afDb.object('/BarberShop/' + this.user.BarberID + '/Users/' + this.user.key).update({
      lastSeen: newDate
    });
  }


  RemoveBarber(user) {
    return this.afDb.object('/Users/' + user.key).set(user)



  }

  copyUserObject() {


    const userEditObject = {
      "fname": this.user.fname,
      "lname": this.user.lname,
      "email": this.user.email,
      "phone": this.user.phone,
      "ProfilePic": this.user.ProfilePic
    };
    return JSON.parse(JSON.stringify(userEditObject));
  }

  updateBarberID(BarberIDArr) {
    this.user.BarberID = BarberIDArr;
    console.log(this.user.BarberID);
  }





  PhoneValidtionIsrael(phone) {

    // var phoneNumberPattern = /^(0(?:[23489]|5[024]|77))-?(?!0)(\d{7})$/;  

    var phoneNumberPattern = /^[0][5][0|2|1|6|3|4|7|8|5|9]{1}[-]{0,1}[0-9]{3}[-]{0,1}[0-9]{4}$/;
    //  var phoneNumberPattern = /^-?(0|[1-9]\d*)?$/;

    if ((phone.match(phoneNumberPattern)) || (phone.length > 7)) {
      return true;
    } else {
      return false;
    }
  }

  userLoaded = new BehaviorSubject(false);
  saveUserData(uData, key) {
    console.log("saveUserData", uData);
    this.key = key;
    if (uData.Devices) {
      uData.Devices = Object.values(uData.Devices);
    }
    // this.user.ProfilePic = uData.ProfilePic;
    // this.user.BarberID = uData.BarberID;
    // this.user.email = uData.email;
    // this.user.fName = uData.fname;
    // this.user.lName = uData.lname;
    // this.user.phone = uData.phone;
    // this.user.Birthday = uData.Birthday;
    // this.user.StarsCount = uData.starsCount;
    this.user = uData;
    console.log(this.user);
    // setInterval(() => {
      // console.log(this.user);
    // }, 1000)

    this.user.key = key;

    localStorage.setItem('userdata', JSON.stringify(this.user));
    //  this.getFirstLetter();
    this.userLoaded.next(true);
    // this.getUserEvent.emit();
    this.addDevice();
  }

  getUserObj() {
    // console.log(this.user);
    // localStorage.setItem('userdata',JSON.stringify(this.user));
    let locUSER = localStorage.getItem('userdata');
    // console.log('locUSER',locUSER);
    if (locUSER) {
      return JSON.parse(locUSER);
    } else {
      return 'empty';
    }
    // return JSON.parse(locUSER);
  }

  cheakIfHave() {
    if (this.user.BarberID)
      return true;

    return false;
  }

  updateShopId(shopId) {
    // console.log(this.key, 'this.key');
    return this.afDb.object('/Users/' + this.key)
      .update({
        BarberID: shopId
      });

  }


  addBizConfirm(buisId) {
    return this.afDb.object('/Users/' + this.key + '/needConfirmBuis/').set(buisId);

  }

  addBizArray(buisId) {
    this.objectArray(buisId); // REMOVE BIZOBJ
    return this.afDb.object('/Users/' + this.key + '/bizArray/').set(buisId);
  }

  // REMOVE BIZOBJ
  objectArray(obj) {
    return;
    console.log("buizobject", obj);
    var arys = {};
    obj.forEach(element => {
      arys[element] = element;
    });

    this.afDb.object('/Users/' + this.key + '/bizObject/').set(arys).then(res => {

    })




  }


  addUserOnShop(shopId, user) {
    // console.log(shopId, 'shopId'); 
    return this.afDb.list('/BarberShop/' + shopId + '/Users/').push(user);
  }



  // getUserMeetsMoreThenOne() {
  //   // return this.afDb.object('/Users/' + this.key + '/meetings/').valueChanges();
  //   return this.afDb.database.ref('/Users/' + this.key + '/meetings/').once('value');
  // }

  getUserMeets() {
    // return this.afDb.list('/Users/' + this.key + '/meetings/').valueChanges();
    return this.afDb.database.ref('/Users/' + this.key + '/meetings/').once('value');
  }

  getUserMeetByShop(shopid) {
    return this.afDb.list('/Users/' + this.key + '/meetings/' + shopid).valueChanges();
  }

  deletMeet(shopID, Mkey) {
    return this.afDb.object('/Users/' + this.key + '/meetings/' + shopID + '/' + Mkey).remove()
      .catch();

  }

  getUserDetalisByUid(uid) {
    return this.afDb.object('/Users/' + uid)
  }

  // setReminder(newMeet, newID, userId, BarberID) {
  //   // console.log('newMeetnewMeetnewMeet',newMeet,newID,userId,BarberID);
  //   this.afDb.object('/Reminders/' + newID).set(newMeet);
  // }

  // setMeeting(userId, BarberID, newID, newMeetUser) {

  //   console.log('newMeetUser', newMeetUser);


  //   let userRef = this.afDb.object('/Users/' + userId + "/meetings/" + BarberID + "/" + newID)

  //   userRef.set(newMeetUser);

  //   this.setReminder(newMeetUser, newID, userId, BarberID);

  // }
  getAllUsers() {
    return this.afDb.object('/Users/').valueChanges();
  }
  // updateToBiz(id, userid) {
  //   var biz = [id];

  //   this.objectArray(biz) // REMOVE BIZOBJ
  //   return this.afDb.object('/Users/' + userid).update({
  //     bizArray: biz
  //   });
  // }

  haimfunction() {
    return;
    //   console.log("hello haim");
    // var ti= this.us.getAllUsers().subscribe(res=>{
    //     // console.log(res);
    //     var newarray:any=this.shopS.json2array2(res)
    //     newarray.forEach(element => {
    //       // console.log(element);;
    //       if(element.BarberID){
    //         this.us.updateToBiz(element.BarberID,element.key2)
    //       }
    //       ti.unsubscribe();
    //     });
    //   })

  }





















}
