import { Pipe, PipeTransform } from '@angular/core';
import { ShopService } from '../ShopService/shop.service'
// interface TrackByFunctionCache {
// 	[ args: string ]: <T>( index: number, item: T ) => any;
// }

// var cache: TrackByFunctionCache = Object.create( null );

@Pipe({
  name: 'clientKey'
})
export class ClientKeyPipe implements PipeTransform {

  constructor(public shops: ShopService) {

  }
  transform(value, args:string[]) : any {
    let keys = [];
   // console.log('keys before',keys)
   // console.log('value before',value)
    for (let key in value) {
   //   console.log('useruseruserkey',key)
      value[key].key = key;
      value[key].fullname = value[key].fname + ' ' + value[key].lname;
      



      keys.push(value[key]);
   //   console.log('keyskeyskeyskeyskeyskeyskeys',keys)
 
    }
    console.log('user keys after',keys)
 //   console.log('value after',value)
    return keys;
  }
}
