import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading = false;

  constructor(public loadingController: LoadingController,private router:Router) { }


  
  async present1sec() {
    this.isLoading = true;
    return await this.loadingController.create({
      duration: 500,
      spinner:"dots",
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }


  async present_for(ms) {
    this.isLoading = true;
    return await this.loadingController.create({
      duration: ms,
      spinner:"dots",
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }



  async presentContactWait(){
    const loading = await this.loadingController.create({
      message: 'אנחנו רואים שיש לכם הרבה אנשי קשר..זה יקח קצת זמן',
      duration: 30000,
      cssClass: 'text-center',
    });
    await loading.present();
  }

  async present() {
    console.log("PRESENT");
    
    this.isLoading = true;
    return await this.loadingController.create({
      duration: 5000,
      spinner:"dots",
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }


  async present8() {
    this.isLoading = true;
    return await this.loadingController.create({
      duration: 8000,
      spinner:"dots",
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async presentLoadingContact() {
    const loading = await this.loadingController.create({
      message: 'אנחנו רואים שיש לכם הרבה אנשי קשר..זה יקח קצת זמן',
      duration: 6000,
      cssClass: 'text-center',
    });
    await loading.present();
  }

  async presentLoadingContactIOS() {
    const loading = await this.loadingController.create({
      message: 'אנחנו רואים שיש לכם הרבה אנשי קשר..זה יקח קצת זמן',
      duration: 0,
      cssClass: 'text-center',
    });
    await loading.present();
  }


  async presentforever() {
    this.isLoading = true;
    return await this.loadingController.create({
      duration: 5000000,
      spinner:"dots",
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }
 

  async presentforever_with_text(text) {
    this.isLoading = true;
    return await this.loadingController.create({
      duration: 5000000,
      spinner:"dots",
      message: text
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }
 






  async presentforever_payment_iframe() {
    this.isLoading = true;
    return await this.loadingController.create({
      duration: 5000000,
      spinner:"dots",
      cssClass: 'payment-iframe-loading',
      message: 'מכין טופס מאובטח',
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }






  async presentAFTERREG() {
    const loading = await this.loadingController.create({
      duration: 4000,
      spinner:"dots",
      message: 'אנחנו יוצרים לך את המשתמש..! זה יקח כמה שניות',
      translucent: true,
      cssClass: 'custom-class custom-loading',
      backdropDismiss: true
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed with role:', role);
    this.router.navigate(["tutorial"])
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }
}