import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { ConfigService } from "../../app/config.service";


@Component({
  selector: 'askSmsCode',
  templateUrl: 'askSmsCode.page.html',
  styleUrls: ['./askSmsCode.page.scss']
})

export class AskSmsCodePage implements OnInit {
  phoneNumberForm: FormGroup;

  constructor(private router: Router,
    public configS: ConfigService,
              private fb: FormBuilder) {}

  ngOnInit() {
    this.generatePhoneForm();
  }

  generatePhoneForm() {
    this.phoneNumberForm = this.fb.group({
      phoneNumber: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(15),
        Validators.pattern(/^[0][5][0|1|6|2|3|4|7|5|9]{1}[-]{0,1}[0-9]{3}[-]{0,1}[0-9]{4}$/)
      ])]
    });
  }

  askCodeSubmit() {
    console.log(this.phoneNumberForm.value);
    this.router.navigate(['smsCode']);
  }

  goToRegisterPage() {
    this.router.navigate(['signUp']);
  }

}
