import { Injectable } from '@angular/core';
import { ShopService } from 'src/ShopService/shop.service';
import { ToastService } from 'src/providers/toast-service';
import { AngularFireDatabase } from '@angular/fire/database';
import { ModalController } from '@ionic/angular';
import { TaskViewComponent } from "../task-view/task-view.component";
import { CryptoService } from 'src/app/crypto.service';
import { LoadingService } from 'src/providers/loading.service';
import { Http, Response } from '@angular/http';
import { userService } from 'src/ShopService/user.service';
import { PremiumService } from 'src/app/premium/premium-service/premium.service';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(
    public shopS: ShopService,
    public ToastService: ToastService,
    private afDb: AngularFireDatabase,
    public modalCtrl: ModalController,
    public LoadingService: LoadingService,
    public CryptoService: CryptoService,
    public _http: Http,
    public premiumService: PremiumService,
    public userService: userService
  ) { }


  current_view_task: any;

  async new_product_modal(task) {
    this.current_view_task = task;
    var modal = await this.modalCtrl.create({
      component: TaskViewComponent,
      swipeToClose: true,
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalForPaymentsChangeSubscriptionCard',
      backdropDismiss: true,
      mode: "ios"
    });

    await modal.present();
    modal.onDidDismiss().then(res => {
      console.log(res);
      if (res.data) {

      }
    })
    return modal;
  }

  default_task: any = {
    name: '30 ימי פרמיום חינם',
    goals: [
      {
        type: 'clients',
        target: 20,
        text: 'כמות לקוחות'
      },
      {
        type: 'events',
        target: 3,
        text: 'כמות תורים'
      }
    ]
    ,
    status: 'running',
    award: [{
      type: 'product',
      forDays: 31,
      BarberID: '056538ed-563b-46d1-b001-a90e69b50f4b',
      productid: '-MGXUUY-621pbguAGGHm'
    }],
    task_img: 'https://i.ibb.co/hXrcCyT/Screen-Shot-2020-07-09-at-19-54-323231.png'
  }

  tasks: any = [];



  new_task(data) {
    console.log('new task!!!!', this.shopS.shopData.Setting.BarberID)
    this.afDb.list('/Tasks/' + this.shopS.shopData.Setting.BarberID + '/').push(data)

  }
  checkRunningTask() {
    let counter = 0;
    console.log(this.tasks);
    
    this.tasks.forEach(element => {
      console.log('runningrunningrunningrunning', element.status);
      if (element.status == 'running') {

        counter++;
      }
    });

    if (counter > 0) {
      this.activeFlag = true;
      console.log('activeFlag', this.activeFlag)

    } else {
      this.activeFlag = false;

    }

  }


  activeFlag = false;
  get_tasks() {

    if (this.shopS.shopData.Setting.BarberID) {

      if (this.tasks.length == 0) {


        this.afDb.database.ref('/Tasks/' + this.shopS.shopData.Setting.BarberID + '/').once('value').then(val => { 
          console.log('val', val.val())
          this.tasks = this.shopS.json2array(val.val());
          this.tasks.forEach(element => {
            this.checkRunningTask();

            this.calc_percentage(element);
          });
        });

      } else {
        this.tasks.forEach(element => {
          this.checkRunningTask();

          this.calc_percentage(element);
        });
      }

    }
  }

  update_task(data) {
    this.afDb.object('/Tasks/' + this.shopS.shopData.Setting.BarberID + '/' + data.key).update(data)
  }


  remove_task(key) {
    this.afDb.object('/Tasks/' + this.shopS.shopData.Setting.BarberID + '/' + key).remove().catch();
  }



  calc_percentage(t) {
    let total_pre = 0;
    t.goals.forEach(element => {
      let ret: any;
      switch (element.type) {
        case 'clients':

          ret = this.calc_clients(element);
          element['percentage'] = ret.pre;
          element['current'] = ret.current;
          total_pre += element['percentage'];

          break;

        case 'events':

          ret = this.calc_events(element);
          element['percentage'] = ret.pre;
          element['current'] = ret.current;

          total_pre += element['percentage'];

          break;


        default:
          break;
      }





    });

    //console.log(t)
    t['total_percentage'] = (total_pre / (t.goals.length * 100)) * 100
    t['total_percentage'] = t['total_percentage'].toFixed(0);
    return t['total_percentage'] + '%';
  }





  async give_reward(task) {

    console.log('task reward', task);
    task['user'] = this.userService.getUserObj()
    task['user']['Payments'] = [];
    task['user']['History'] = [];
    task['user']['meetings'] = [];


    this.shopS.log(task.name, 'task_finish');


    task['BarberID'] = this.shopS.shopData.Setting.BarberID
    let encrypted = this.CryptoService.Encrypt(task);

    this.LoadingService.presentforever();

    await this._http.post(this.shopS.serverDev + '/giveReward', encrypted).subscribe((respone: any) => {
      respone = JSON.parse(respone['_body']);
      console.log('res-service', respone);

      this.shopS.turnOnConfetti(3000);
      this.ToastService.showToast(task.name);

      // this.tasks = this.tasks.filter( el => el.key !== task.key );
      let index = this.tasks.findIndex(x => x.key == respone.key);

      this.tasks[index]['status'] = "complete";

      this.modalCtrl.dismiss();

      setTimeout(() => {

        this.LoadingService.dismiss();

        this.checkRunningTask();

        respone.award.forEach(element => {
          // if premium
          if (element.productid == "-MGXUUY-621pbguAGGHm") {
            this.shopS.shopData.Setting.Premium = this.shopS.Premium.exportPremium();
            this.premiumService.PremiumLock("go");

          }
        });

      }, 2000);



    }, (error: any) => {
      this.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');
      this.LoadingService.dismiss();

    });

  }




  calc_clients(element) {
    //console.log(element);

    let client_num = this.shopS.shopData.Userslength;

    let pre: any = (client_num / element.target) * 100
    pre = parseInt(pre)

    if (pre > 100) {
      pre = 100;
    }

    return { pre: pre, current: client_num }

  }


  calc_events(element) {
    console.log(element)

    // let counter = 0;
    // let meeting_dates = this.shopS.shopData.Meetings ? Object.values(this.shopS.shopData.Meetings) : [];
    // meeting_dates.forEach(date => {
    //   console.log(counter);
    //   if (counter < 3) {
    //     let dateArr = Object.values(date)
    //     counter += dateArr.length;
    //   } else {
    //     return;
    //   }
    // });
    let counter = this.shopS.shopData.meetCounter ? this.shopS.shopData.meetCounter : 0;
    let max_value = Math.min(counter, this.default_task.goals[1].target);

    let pre: any = (max_value / element.target) * 100
    pre = parseInt(pre)

    if (pre > 100) {
      pre = 100;
    }

    return { pre: pre, current: max_value }
  }

}
