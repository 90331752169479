import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremiumLockComponent } from "./premium-lock/premium-lock.component";
import { PricePlansPageModule } from "./price-plans/price-plans.module";
import { PremiumService } from "./premium-service/premium.service";
import { IonicModule } from '@ionic/angular'; //import

@NgModule({
  declarations: [PremiumLockComponent],
  imports: [
    CommonModule,
    PricePlansPageModule,
    IonicModule
  ],
  providers:[PremiumService],
  entryComponents:[PremiumLockComponent]
})
export class PremiumModule { }
