import { PrivacypolicyComponent } from './../../app/privacypolicy/privacypolicy.component';
import { LoadingService } from 'src/providers/loading.service';
import { AddBusinessPage } from './../addBusiness/addBusiness.page';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AlertController, NavController, Platform, ModalController, IonRouterOutlet } from '@ionic/angular';
import { TabsPage } from '../pages/tabs/tabs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { LocalStorageService } from '../../providers/local-storage.service';
import { Router } from '@angular/router';
import { ToastService } from '../../providers/toast-service';
import { ConfigService } from "../../app/config.service";
import { AuthService } from 'src/providers/auth-service';
import { ShopService } from 'src/ShopService/shop.service';




@Component({
  selector: 'reg',
  templateUrl: 'registerUser.html',
  styleUrls: ['./registerUser.scss']


})

export class registerU implements OnInit {
  registrationForm: FormGroup;
  todo = {
    fname: '',
    lname: '',
    email: '',
    pass: '',
    gender: '',
    myDate: ''
  };
  today = new Date();
  uid;
  userRef;
  userRef2;
  termsContentmodal = false;


  constructor(
    public navCtrl: NavController,
    private fb: FormBuilder,
    private platform: Platform,
    private modalController: ModalController,
    private afAuth: AngularFireAuth,
    private afDb: AngularFireDatabase,
    private localStorageService: LocalStorageService,
    private router: Router,
    private auth: AuthService,
    public configS: ConfigService,
    private loadingService: LoadingService,
    private alertCtrl: AlertController,
    public shopS: ShopService,
    private routerOutlet: IonRouterOutlet
  ) { }

  ngOnInit() {

    this.generateForm();

    //   document.addEventListener('backbutton', () => {
    //     console.log('register user - - - - backbutton action!');

    //    this.goBack();

    // }, false);

  }


  ionViewDidEnter() {

    localStorage.setItem('userdata', '');
    // localStorage.setItem('u', 'false');

    this.afAuth.auth.signOut().then((result) => {
      console.log("reg-out")

    })


  }


  genrateForm() {
    this.registrationForm
  }
  // Validators.pattern(/^0\d([\d]{0,1})([-]{0,1})\d{7}$/)
  generateForm() {
    this.registrationForm = this.fb.group({
      fname: ['', Validators.compose([
        Validators.required, Validators.maxLength(20)
      ])],
      lname: ['', Validators.compose([
        Validators.required, Validators.maxLength(20)
      ])],
      email: ['', Validators.compose([
        Validators.required, Validators.maxLength(50)
      ])],
      //   phone: ['', Validators.compose([ 
      //     Validators.required, Validators.pattern(/^[0][5][0|2|3|4|5|9|7|8|6]{1}[-]{0,1}[0-9]{3}[-]{0,1}[0-9]{4}$/)
      // //    Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])

      //   ])],
      myDate: ['', Validators.compose([Validators.required])],
      pass: ['', Validators.compose([
        Validators.required, Validators.minLength(6), Validators.maxLength(20)
      ])],
      // gender: ['f', Validators.required],
      confirm: [false, Validators.requiredTrue]
    });
  }


  openModal() {
    // console.log(this.modal);
    this.termsContentmodal = true;
  }
  closeModal() {
    this.termsContentmodal = false;
  }

  showFormValue() {
    // console.log(this.registrationForm.value);
    // console.log(this.registrationForm.valid);
  }
  OnDestroy() {
    if (this.userRef) {
      this.userRef.unsubscribe()

    }
  }
  goBack() {

    this.router.navigate(['login'], { replaceUrl: true });
  }
  collectAndSave(data) {
    let finalData = {
      BarberID: '',
      uid: ''
    };
    for (let key in data) {
      if (key === 'BarberID') {
        finalData.BarberID = data[key];
      }
    }
    finalData.uid = this.uid;
    this.localStorageService.saveToLocalStorage('user', finalData);
  }
  focusFLAG = false;
  focusFLAGassist = false;

  focus(type) {
    if (type == 'in') {
      this.focusFLAGassist = true;
      this.focusFLAG = true;
    } else {
      this.focusFLAGassist = false;
      setTimeout(() => {
        if (!this.focusFLAGassist) {
          this.focusFLAG = false;
        }
      }, 500);
    }
  }

  register() {
    this.loadingService.presentforever();
    this.registrationForm.value.ProfilePic = "https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/male_avatar_323b.svg"
    // console.log('register -----------');
    // console.log('register -----this.registrationForm-',this.registrationForm);

    this.afAuth.auth.createUserWithEmailAndPassword(
      this.registrationForm.value.email,
      this.registrationForm.value.pass
    ).then(authState => {
      // authState.user.sendEmailVerification();
      this.uid = authState.user.uid;
      this.shopS.log(this.uid, 'user_register_success');

      console.log('register -----authState.user-', authState.user);
      delete this.registrationForm.value.pass;
      this.userRef = this.afDb.object('/Users/' + this.uid);

      if (this.uid) {
        //  console.log('register -----this.uid-true',this.uid);
        this.userRef.set(this.registrationForm.value).then((result) => {
        }, (error) => {
          // this.toastService.showToast('SOME ERROR');
        });
        // console.log(this.userRef.snapshotChanges());
        this.userRef2 = this.userRef.snapshotChanges().subscribe((result) => {
          // console.log('register -----this.userRef.snapshotChanges---result',result);
          this.collectAndSave(result);
          // userRef.unsubscribe()
          this.loadingService.dismiss();
          return
          // haim change
          // this.router.navigate(['/addBusiness'], {replaceUrl: true})

        });

        // if (user !== null) {
        //   const uid = user.i
        // }

        // this.navCtrl.pop();
      }
    }).catch(async (error) => {
      this.loadingService.dismiss();

      console.log(error)
      var TextReg = "יש בעיה בהרשמה תדבוק את השדות שוב";
      if (error.message.includes('The email address is already in use')) {
        TextReg = "המייל כבר קיים במערכת";
      }
      if (error.message.includes('The email address is badly formatted')) {
        TextReg = "כתובת מייל לא תקינה";

      }


      const alert = await this.alertCtrl.create({
        header: 'התראה',
        message: TextReg,
        buttons: ['סגור']
      });

      await alert.present();

    });
  }

  ngOnDestroy() {
    if (this.userRef2) {
      this.userRef2.unsubscribe()

    }
  }

  async presentPrivacypolicy(): Promise<HTMLIonModalElement> {

    if (this.platform.is("ios")) {

      var modal = await this.modalController.create({
        component: PrivacypolicyComponent,

        swipeToClose: false,
        cssClass: '',
        presentingElement: this.routerOutlet.nativeEl,
        mode: "ios",
        //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal


        // Get the top-most ion-modal

      });
    } else {
      var modal = await this.modalController.create({
        component: PrivacypolicyComponent,
        swipeToClose: false,
        mode: 'ios',
        cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',

        //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal


        // Get the top-most ion-modal

      });
    }


    await modal.present();
    return modal;


  }
  //  console.log(this.todo)
}
