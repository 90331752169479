import { Injectable } from '@angular/core';
import { ShopService } from '../ShopService/shop.service';
import { Http, Response } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public shopS: ShopService,
    private _http: Http
  ) { }

  typeNewMeet(barber, user, date, hour, type) {
    var Sname = "תור חדש מ";
    Sname += user;
    Sname += " בתאריך ";
    Sname += date;
    Sname += " בשעה ";
    Sname += hour;
    Sname += " מסוג ";
    Sname += type;


    console.log('barber', barber)
    console.log('user', user)
    console.log('date', date)
    console.log('hour', hour)
    console.log('type', type)
    // this.sendNotification(barber,Sname)


  }


  ngOnInit() {
  }



  check_if_date_in_past(meetDate, startTimeView) {

    // let date = {
    //   meetDate: "15-10-2020",
    //   startTimeView: "22:10"
    // };


    // console.log(date)
    let split = meetDate.split('-');
    let splitH = startTimeView.split(':');

    let makeDate = new Date(split[2] + ',' + split[1] + ',' + split[0]);
    makeDate.setHours(parseInt(splitH[0]));
    makeDate.setMinutes(parseInt(splitH[1]));

    //console.log(makeDate)

    if (makeDate < new Date()) {
      return true;
    } else {
      return false;
    }

  }

  arrange_JSON_Notification_event(meet, user, what) {

    console.log('meet', meet);
    console.log('what', what);
    console.log('user', user);
    let event: any = {
      startTimeView: meet.startTimeView,
      meetDate: meet.meetDate,
      meetTypeData: { name: meet.eventName }
    };


    if (!this.check_if_date_in_past(event.meetDate, event.startTimeView)) {
      //  event['startTimeView'] = meet.startTimeView;
      //  event['meetDate'] = meet.meetDate;
      //  event['meetTypeData']['name'] = meet.meetTypeName;

      console.log('event', event);


      var getUser = this.shopS.getUserFromUsers(user.key).valueChanges().subscribe((result: any) => {
        console.log('data --- FROM USERS DB = ====== == ==', result);
        // if(result.notification){
        console.log('result.notification!!!!!!!-1--1', result.notification);
        //   result.pass = 12313123;

        if (what == 'user_add_event') {
          result['fname'] = this.shopS.us.user.fname;
          result['lname'] = this.shopS.us.user.lname;

        }


        result['History'] = [];
        result['meetings'] = [];
        result['accessToken'] = '';
        let sendPar = {
          notification: result,
          what: what,
          BizName: this.shopS.shopData.Setting.name,
          meet: { event: event },
        }

        this.AjaxToNotification(sendPar);
        //  }else{
        //sendSMS prompt
        //}

        getUser.unsubscribe();





      });
    } else {

    }

  }







  arrangeJSONforNotificationFROMmeet(meet, what) {
    console.log('meet', meet);
    console.log('what', what);
    let event: any = {
      startTimeView: meet.startTimeView,
      meetDate: meet.meetDate,
      meetTypeData: { name: meet.meetType.name }
    };


    if (!this.check_if_date_in_past(event.meetDate, event.startTimeView)) {
      //  event['startTimeView'] = meet.startTimeView;
      //  event['meetDate'] = meet.meetDate;
      //  event['meetTypeData']['name'] = meet.meetTypeName;

      console.log('event', event);


      var getUser = this.shopS.getUserFromUsers(meet.uId).valueChanges().subscribe((result: any) => {
        console.log('data --- FROM USERS DB = ====== == ==', result);
        // if(result.notification){
        console.log('result.notification!!!!!!!-1--1', result.notification);
        //  result.pass = 12313123;
        result['History'] = [];
        result['meetings'] = [];
        result['accessToken'] = '';
        let sendPar = {
          notification: result,
          what: what,
          BizName: this.shopS.shopData.Setting.name,
          meet: { event: event },
        }

        this.AjaxToNotification(sendPar);
        //  }else{
        //sendSMS prompt
        //}

        getUser.unsubscribe();





      });
    } else {

    }
    //  let sendParam = {
    //    BizName: this.shopS.shopData.Setting.name,
    //    event: event,
    //    notification: ''
    // }
    // console.log('sendParam',sendParam);

    // $USERid = $obj->notification->notification->USERid;
    // $pushId = $obj->notification->notification->pushId;
    // $pushToken = $obj->notification->notification->pushToken;

    // $fname = $obj->notification->fname;
    // $phone = $obj->notification->phone;

    // $BizName = $obj->BizName;



    // //. $obj->meet
    // $meetName = $obj->meet->event->meetTypeData->name;
    // $startTimeView = $obj->meet->event->startTimeView;
    // $meetDate  = $obj->meet->event->meetDate;


    // //. $obj->$what
    // $what = $obj->what;



    // meet

    //   BarberID: "e0b899c0-199a-4811-a205-4fc52b623069"
    // Date: "Thu Apr 23 2020 19:00:39 GMT+0300 (Israel Daylight Time)"
    // endTime: "Sat Apr 25 2020 08:38:45 GMT+0300 (Israel Daylight Time)"
    // key: "adbf96db-04d3-40b0-992c-fcbacee52993"
    // meetDate: "25-04-2020"
    // meetDay: "יום שבת"
    // meetStatus: 1
    // meetTime: 30
    // meetType: "0"
    // meetTypeName: "תספורת גבר"
    // startTimeView: "08:10"
    // uId: "X7uno8UEKHWf00syBLzNPKMx9N82"


    // to

    // BarberID: "e0b899c0-199a-4811-a205-4fc52b623069"
    // allDay: false
    // endTime: Sat Apr 25 2020 08:38:45 GMT+0300 (Israel Daylight Time) {}
    // endTimeView: "08:40"
    // flow: false
    // key: "adbf96db-04d3-40b0-992c-fcbacee52993"
    // meetDate: "25-04-2020"
    // meetDateFormat: "25.04.2020"
    // meetStatus: 1
    // meetTime: 30
    // meetType: "0"
    // meetTypeData:
    // approve: false
    // color: "#ac420f"
    // flow: false
    // info: "987654"
    // ischecked: true
    // key: "0"
    // meetTime: 30
    // name: "תספורת גבר"
    // price: 50
    // reminder: 30
    // __proto__: Object
    // startDate: "Sat Apr 25 2020 08:10:45 GMT+0300 (Israel Daylight Time)"
    // startTime: Sat Apr 25 2020 08:10:45 GMT+0300 (Israel Daylight Time) {}
    // startTimeView: "08:10"
    // uId: "X7uno8UEKHWf00syBLzNPKMx9N82"
    // uIdData:
    // ProfilePic: "https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/male_avatar_323b.svg"
    // barberID: "e0b899c0-199a-4811-a205-4fc52b623069"
    // email: "sadasd@dsada.com"
    // fname: "גדשדג"
    // key: "X7uno8UEKHWf00syBLzNPKMx9N82"
    // lastSeen: "2020-04-23T14:49:28.505Z"
    // lname: "גשדגשד"
    // myDate: "2020-04-23T17:26:02.360+03:00"
    // phone: "0527420606"



    // console.log('what',what);
  }

  sendNotificationToAll(to, what) {
    // console.log('to,what!!!!#@!$%^$&^$%^$#@#%@#!#$@%#$^%$&',to,what);
    var getUser = this.shopS.getUserFromUsers(to.uId).valueChanges().subscribe((result: any) => {
      //   console.log('data --- FROM USERS DB = ====== == ==',result);
      if (result) {

        if (result.notification) {

          let sendPar = {
            ProfilePic: this.shopS.shopData.Setting.logo,
            notification: {
              notification: result.notification
            },
            what: what,
            message: to.message,
            title: to.title,
            BizName: this.shopS.shopData.Setting.name,
          }
          console.log('sendPar', sendPar)
          this.AjaxToNotification(sendPar);
        } else {
          //sendSMS prompt
        }

        getUser.unsubscribe();

      }
    });



  }





  sendNotification(to, what) {
    // what OPTIONS : ZimonToClient, ClientDeleteLine, newClientToBiz, ClientOrderNeedApprove, Approve, reminderToClient,cancel
    console.log('to,what!!!!#@!$%^$&^$%^$#@#%@#!#$@%#$^%$&', to, what);
    console.log('this.shopS.shopData$^%$&', this.shopS.shopData.Setting);
    console.log('to.uId$^%$&', to.uId);



    var getUser = this.shopS.getUserFromUsers(to.uId).valueChanges().subscribe((result: any) => {
      console.log('data --- FROM USERS DB = ====== == ==', result);
      if (result.notification) {
        console.log('result.notification!!!!!!!-1--1', result.notification);

        let sendPar = {
          notification: result,
          what: what,
          meet: this.shopS.SelectedMeet,
          BizName: this.shopS.shopData.Setting.name,
          ProfilePic: this.shopS.shopData.Setting.logo

        }

        this.AjaxToNotification(sendPar);
      } else {
        //sendSMS prompt
      }

      getUser.unsubscribe();
      //  });



      //  alert('noti: to' + to);
      //  alert('noti: what' + what);
    });
  }




  AjaxToNotification(sendPar) {
    this._http.post('https://webc.co.il/pointnot/', sendPar).subscribe(respone => {
      console.log('respone: ',respone);
    });
  }


}
