import { Component } from '@angular/core';


@Component({
  selector: 'symbol',
  templateUrl: 'symbol.component.html',
  styleUrls: ['./symbol.component.scss']
})

export class SymbolComponent {


}
