import { Injectable, NgZone } from '@angular/core';
import * as _ from 'lodash';
import { GoogleAuthService } from 'ng-gapi';
import GoogleUser = gapi.auth2.GoogleUser;
import GoogleAuth = gapi.auth2.GoogleAuth;
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { env } from 'src/app/app.module';
import { userService } from '../../ShopService/user.service';
import { ShopService } from '.././../ShopService/shop.service';
import { CryptoService } from "../../app/crypto.service";
import { AlertController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable()
export class GoogleCalendarService {
  public static SESSION_STORAGE_KEY: string;
  private user: GoogleUser = undefined;


  constructor(
    private googleAuthService: GoogleAuthService,
    private ngZone: NgZone,
    private http: HttpClient,
    public CryptoService: CryptoService,
    public userService: userService,
    public shopS: ShopService,
    private iab: InAppBrowser,
    private alertController: AlertController
  ) { }


  user_type;// = "Premium";//this.shopS.shopData.Setting.Premium.status;
  calendar_auth;// = false;//this.shopS.shopData.Setting.GoogleCalendar.GoogleCalendar;
  GoogleCalSynced;// = false;//this.shopS.shopData.Setting.GoogleCalendar.GoogleCalSynced;
  import_events;// = 0;//this.shopS.shopData.Setting.GoogleCalendar.googleCalImport;
  res_url;
  google_credentials = false;
  user_mail;
  user_name;
  user_pic;


  log_in_stage = "";
  log_in_cal(stage) {
    this.log_in_stage = stage;
    console.log('log_in_cal(stage)', stage);

    if (stage == 'auth-wait') {
      this.google_cal_auth();
    }
  }

  google_cal_auth() { // login to google account and authenticat
    // this.calendar_auth = this.shopS.shopData.Setting.Premium.GoogleCalendar;

    console.log('google_cal_auth shopData: ', this.shopS.shopData);
    console.log('BarberId: ', this.shopS.shopData.BarberId);

    let body = {
      BarberId: this.shopS.shopData.BarberId,
      userId: this.shopS.shopData.Setting.userManager,
      type: 'get_auth_url'
    }
    console.log(body);

    body = this.shopS.cryptoService.Encrypt(body);
    this.http.post(this.shopS.serverDev + '/GooglePointCalendarEvent', body).subscribe(val => {
      this.res_url = this.iab.create(val['url'].toString()); // opens the scopes authorize window
      // console.log('get_auth_url res_url: ', this.res_url);
      // window.open(val['url'], "googlecalendarAuth", "height=600,width=400,scrollbars=no");
      this.calendar_auth = val['GoogleCalendar'];
      // this.log_in_stage = "";
      console.log('this.calendar_auth', this.calendar_auth, "val['GoogleCalendar']", val['GoogleCalendar'], this.user_type, 'this.log_in_stage', this.log_in_stage);
    });
  }

  get_user_info() {
    let user_info = {
      type: 'get_user_info',
      BarberId: this.shopS.shopData.BarberId
    }
    user_info = this.shopS.cryptoService.Encrypt(user_info);
    this.http.post(this.shopS.serverDev + '/GooglePointCalendarEvent', user_info).subscribe(info => {
      console.log(info);

    });
  }

  /////////////////////////////////////////////////////////////////////////////////
  log_out_cal() {
    var textT = '<span class="aaaa" style="">';
    var text = 'בטוח שברצונך להתנתק?';

    console.log('textT', textT, text);
    textT += text + '</span>';
    this.alertModal(textT, 'התנתקות מיומן גוגל', 'google_cal_logout', 'כן, התנתק', 'ביטול');
  }


  google_cal_logout() { // logs out the user from google
    this.log_in_stage = 'signing-out';
    if (this.GoogleCalSynced) { // if the use is synced -> stop the sync
      this.stopNotificationsEvent();
    }


    let body = {
      BarberId: this.shopS.shopData.BarberId,
      type: 'logout_Google_Calendar'
    }
    console.log(body);

    body = this.shopS.cryptoService.Encrypt(body);
    this.http.post(this.shopS.serverDev + '/GooglePointCalendarEvent', body).subscribe(val => {
      this.calendar_auth = val['GoogleCalendar'];
      if (this.user_type == 'Premium') {
        this.GoogleCalSynced = val['GoogleCalSynced'];
      }
      this.google_credentials = false;
      // this.log_in_stage = "";
      console.log('this.calendar_auth', this.calendar_auth, this.GoogleCalSynced, this.user_type);
    });
  }

  callWatchEvent() { // start the sync 'watch' on the calendar
    console.log('Watch Events On Calendar  ', this.shopS.shopData.BarberId, 'user type:', this.shopS.shopData.Setting.Premium.status);
    let event = {
      BarberId: this.shopS.shopData.BarberId,
      type: 'Watch Events On Calendar'
    }
    event = this.shopS.cryptoService.Encrypt(event);
    this.http.post(this.shopS.serverDev + '/CalendarNotifications', event).subscribe(val => {

      this.GoogleCalSynced = val['GoogleCalSynced'];
      console.log('Watch_val', val, 'GoogleCalSynced', this.GoogleCalSynced);
    });
  }


  stopGoogleSync() {
    var textT = '<span class="aaaa" style="">';
    var text = 'בטוח שברצונך להפסיק סנכרון?';

    console.log('textT', textT, text);
    textT += text + '</span>';
    this.alertModal(textT, 'הפסקת סנכרון עם יומן גוגל', 'stopNotificationsEvent', 'כן, הפסק סנכרון', 'ביטול');

  }

  stopNotificationsEvent() { //stop the sync 'watch' on the calendar
    console.log('Stop Notifications On Calendar  ', this.shopS.shopData.BarberId);
    let event = {
      BarberId: this.shopS.shopData.BarberId,
      type: 'Stop Notifications On Calendar'
    }
    event = this.shopS.cryptoService.Encrypt(event);
    this.http.post(this.shopS.serverDev + '/CalendarNotifications', event).subscribe(val => {

      this.GoogleCalSynced = val['GoogleCalSynced'];
      console.log('stop_val ', val, 'GoogleCalSynced', this.GoogleCalSynced);
    });
  }



  google_events_array = [];
  zoomlink = "";
  sendSms = true;
  dateTime;
  moreInfo;


  get_googleId(cbb) {
    this.shopS.get_googleId(cb => {
      if (cb) {
        console.log('Meet_GoogleId', cb.length);
        // cbb( cb)
      }
      else {
        console.log('googleId_array empty');
        // cbb(cb)
      }
      cbb(cb);
    });
  }


  importGoogleMeets() { 
    console.log("importGoogleMeets");

    this.GetCalendarEvents(cb => {
      console.log("finished import", cb);
      this.showAlert(cb, '');
      this.import_events = this.shopS.shopData.Setting.GoogleCalendar.googleCalImport || 1;
      console.log('this.calendar_auth', this.calendar_auth, "this.import_events", this.import_events, this.user_type);

    });
  }

  GetCalendarEvents(cb) { //import google evens to point, check if the event is already imported by googleId, converting to point meet obj
    //////////////////////////////////get googleId collection
    let googleId_array;

    this.get_googleId(cbb => { // getting all the googleId from 'Meet_GoogleId' collection to array
      console.log('get_googleId:', cbb);
      googleId_array = cbb;

      console.log('Get Calendar Evens, user type:', this.shopS.shopData.Setting.Premium.UserData);
      //////////////////////////////////premium 1 year forward, basic 1 month forward
      let start_date = new Date().getTime();
      let end_date;

      console.log();

      if (this.shopS.shopData.Setting.Premium.UserData == 'premium') {
        end_date = new Date().setFullYear(new Date().getFullYear() + 1);
      }
      else {
        end_date = new Date().setMonth(new Date().getMonth() + 1);
      }

      let body = {
        BarberId: this.shopS.shopData.BarberId,
        type: 'Get Calendar Evens',
        timeMin: new Date().toISOString()
      }
      // console.log('end_time', new Date(end_date), 'start_time', new Date(start_date), body);
      body = this.shopS.cryptoService.Encrypt(body);

      this.http.post(this.shopS.serverDev + '/GooglePointCalendarEvent', body).subscribe(val => { //getting all google events
        console.log('GetCalendarEvents val: ', val);

        this.google_events_array = val['items'];
        console.log(this.google_events_array);
        let count_imported = 0;
        let not_imported = 0;
        this.google_events_array.forEach((item, index) => {
          ////////////////////////////////// check if googleId in collection
          let googleId = item.id;
          if ((!googleId_array) || (googleId_array.includes(googleId) == false)) { // checking if array is empty or googleId is not in array
            // console.log('googleId is not in Meet_GoogleId', googleId);
            if (item.start) {
              if (item.start.dateTime) {

                // console.log(index, 'get time:', new Date(item.start.dateTime).getTime(), '>=', new Date().getTime());
                let item_date = new Date(item.start.dateTime).getTime();
                if ((item_date >= start_date) && (item_date <= end_date)) {
                  count_imported++;
                  console.log('importing meet:', googleId, index);

                  this.convertPointToGoogleMeet(item, googleId);

                  // let meetTypeObj = {
                  //   "approve": false,
                  //   "color": "#000272",
                  //   "flow": false,
                  //   "info": "987654",
                  //   "ischecked": true,
                  //   "key": "1",
                  //   "meetTime": "",
                  //   "name": "",
                  //   "price": 0,
                  //   "reminder": 30,
                  //   "active": true
                  // }
                  // // console.log(index, item, 'meetTypeObj: ', meetTypeObj);

                  // let start_time = new Date(item.start.dateTime);
                  // let end_time = new Date(item.end.dateTime);
                  // // console.log('event start time:', start_time, 'end time: ', end_time);

                  // meetTypeObj.meetTime = (Math.floor(((end_time.valueOf() - start_time.valueOf()) / 1000) / 60)).toString();
                  // // console.log('meetTypeObj.meetTime: ', (Math.floor(((end_time.valueOf() - start_time.valueOf()) / 1000) / 60)).toString());
                  // let reminder = this.shopS.json2array(item.reminders.overrides);

                  // if (reminder.length > 0) {

                  //   meetTypeObj.reminder = reminder[0].minutes;
                  //   // console.log('reminder', reminder);
                  // }
                  // if (meetTypeObj.meetTime != 'NaN') {
                  //   this.moreInfo = item.description || "";
                  //   meetTypeObj.name = "תור מיומן גוגל - " + item.summary;

                  //   // console.log('this.moreInfo', this.moreInfo);
                  //   // console.log('meetTypeObj.name: ', "תור מיומן גוגל - " + item.summary);
                  //   // console.log('meetTypeObj:', index, meetTypeObj);
                  //   // console.log('MEET KEY!!!', this.shopS.meetForBuis.key);
                  //   this.shopS.meetForBuis.key = this.shopS.meetForBuis.key.slice(0, 23) + googleId;
                  //   // console.log('MEET KEY!!!', this.shopS.meetForBuis.key);


                  //   this.shopS.meetForBuis.add_googleID_meet(googleId, 'Google', false);

                  //   this.shopS.meetForBuis.importMeetType(meetTypeObj)
                  //   this.shopS.meetForBuis.importMeetType(meetTypeObj);
                  //   this.shopS.meetForBuis.importZoomlink(this.zoomlink)
                  //   this.shopS.meetForBuis.importSendSms(this.sendSms);
                  //   this.shopS.meetForBuis.importStartDateAndHoureview(item.start.dateTime);

                  //   this.shopS.meetForBuis.importBarber(this.shopS.shopData.Setting.BarberID, this.shopS.shopData.Setting.name);
                  //   this.shopS.meetForBuis.importUserId('localUserAdmin');//this.shopS.currentMeet.user.key
                  //   this.shopS.meetForBuis.importMoreInfo(this.moreInfo);
                  //   this.shopS.meetForBuis.defineMeetTime(meetTypeObj.meetTime);//this.valueTime 
                  //   this.shopS.meetForBuis.CreateNewMeet();
                  //   this.shopS.meetForBuis.importPrice();
                  //   this.shopS.meetForBuis.defineFlow(meetTypeObj.flow);
                  //   this.shopS.meetForBuis.defineStatus(1);
                  //   this.shopS.meetForBuis.showClass();

                  //   this.shopS.setMeet(); //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


                  //   let meet = this.shopS.meetForBuis.exportMeetAdmin();
                  //   let meeting_date = (item.start.dateTime).split('T')[0];
                  //   // console.log('add_googleID_barber_meet', JSON.stringify(meet), meeting_date, googleId);

                  //   ////////////////////////////////// add googleId to collection
                  //   this.shopS.add_googld_db(googleId);

                  //   //////////////////////////////////set /googleCalImport val and add +1 , set /googleLastImport to cur date timestamp
                  //   this.shopS.google_meet_import();

                  //   // setTimeout(() => {
                  //   // this.shopS.add_googleID_barber_meet(this.shopS.shopData.BarberId, meet.key, meeting_date, googleId, 'Google');
                  //   this.import_events = this.shopS.shopData.Setting.GoogleCalendar.googleCalImport;
                  //   console.log('import_events', this.import_events);

                  //   // }, 1500);
                  // }


                } else if (item.recurrence) { // if item has recurrence and no start date
                  count_imported++;
                  console.log(index, 'google meet obj recurrence but date past', item);
                  this.checkRecurrenceDate(item, googleId);

                } else {
                  console.log(index, 'google meet obj error date past', item);
                }
              } else {
                console.log(index, 'google meet obj error missing info item.start.dateTime', item);
              }
            } else {
              console.log(index, 'google meet obj error missing info item.start', item);
            }
          } else {
            console.log(index, 'google meet obj error already imported', googleId_array.includes(googleId), googleId, item);
          }
        });
        // console.log('finished import!');
        not_imported = this.google_events_array.length - count_imported;
        // console.log('total', this.google_events_array.length, 'imported', count_imported, 'not imported', not_imported);
        cb({
          status: "finished import",
          total: this.google_events_array.length,
          count_imported,
          not_imported
        });

      });
    });
  }


  convertPointToGoogleMeet(item, googleId) { // convert google event obj to point meet obj and set in point calendar
    let meetTypeObj = {
      "approve": false,
      "color": "#000272",
      "flow": false,
      "info": "987654",
      "ischecked": true,
      "key": "1",
      "meetTime": "",
      "name": "",
      "price": 0,
      "reminder": 30,
      "active": true
    }
    // console.log(index, item, 'meetTypeObj: ', meetTypeObj);

    let start_time = new Date(item.start.dateTime);
    let end_time = new Date(item.end.dateTime);
    // console.log('event start time:', start_time, 'end time: ', end_time);

    meetTypeObj.meetTime = (Math.floor(((end_time.valueOf() - start_time.valueOf()) / 1000) / 60)).toString();
    // console.log('meetTypeObj.meetTime: ', (Math.floor(((end_time.valueOf() - start_time.valueOf()) / 1000) / 60)).toString());
    let reminder = this.shopS.json2array(item.reminders.overrides);

    if (reminder.length > 0) {

      meetTypeObj.reminder = reminder[0].minutes;
      // console.log('reminder', reminder);
    }
    if (meetTypeObj.meetTime != 'NaN') {
      this.moreInfo = item.description || "";
      meetTypeObj.name = "תור מיומן גוגל - " + item.summary;

      // console.log('this.moreInfo', this.moreInfo);
      // console.log('meetTypeObj.name: ', "תור מיומן גוגל - " + item.summary);
      // console.log('meetTypeObj:', index, meetTypeObj);
      // console.log('MEET KEY!!!', this.shopS.meetForBuis.key);
      this.shopS.meetForBuis.key = this.shopS.meetForBuis.key.slice(0, 23) + googleId;
      // console.log('MEET KEY!!!', this.shopS.meetForBuis.key);


      this.shopS.meetForBuis.add_googleID_meet(googleId, 'Google', false);

      this.shopS.meetForBuis.importMeetType(meetTypeObj)
      this.shopS.meetForBuis.importMeetType(meetTypeObj);
      this.shopS.meetForBuis.importZoomlink(this.zoomlink)
      this.shopS.meetForBuis.importSendSms(this.sendSms);
      this.shopS.meetForBuis.importStartDateAndHoureview(item.start.dateTime);

      this.shopS.meetForBuis.importBarber(this.shopS.shopData.Setting.BarberID, this.shopS.shopData.Setting.name);
      this.shopS.meetForBuis.importUserId('localUserAdmin');//this.shopS.currentMeet.user.key
      this.shopS.meetForBuis.importMoreInfo(this.moreInfo);
      this.shopS.meetForBuis.defineMeetTime(meetTypeObj.meetTime);//this.valueTime 
      this.shopS.meetForBuis.CreateNewMeet();
      this.shopS.meetForBuis.importPrice();
      this.shopS.meetForBuis.defineFlow(meetTypeObj.flow);
      this.shopS.meetForBuis.defineStatus(1);
      this.shopS.meetForBuis.showClass();

      this.shopS.setMeet(); //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


      if (item.recurrence) {
        //RRULE:FREQ=WEEKLY;WKST=SU;UNTIL=20230226T215959Z;BYDAY=WE
        //RRULE:FREQ=WEEKLY;WKST=SU;COUNT=3;BYDAY=TH
        // console.log('item.recurrence', item.recurrence);
        this.recurrenceGoogleMeet(item);

      }



      // let meet = this.shopS.meetForBuis.exportMeetAdmin();
      // let meeting_date = (item.start.dateTime).split('T')[0];
      // console.log('add_googleID_barber_meet', JSON.stringify(meet), meeting_date, googleId);

      ////////////////////////////////// add googleId to 'Meet_GoogleId' collection
      this.shopS.add_googld_db(googleId);

      //////////////////////////////////set /googleCalImport val and add +1 , set /googleLastImport to cur date timestamp
      this.shopS.google_meet_import();

      this.import_events = this.shopS.shopData.Setting.GoogleCalendar.googleCalImport;
      console.log('import_events', this.import_events);
    }
  }


  checkRecurrenceDate(item, googleId) { // check recurrence parameters 
    let recurrence = (item.recurrence[0].slice(6)).split(';');
    let obj = {};
    let untilDate, Today = new Date().getTime();

    recurrence.forEach(el => {
      el = el.split('=')
      obj[el[0]] = el[1]
    });

    if (obj['UNTIL']) {
      let date = (obj['UNTIL'].slice(0, 4) + '-' + obj['UNTIL'].slice(4, 6) + '-' + obj['UNTIL'].slice(6, 8)).toString();
      untilDate = new Date((new Date(date)).setDate((new Date(date)).getDate() + 1));
    }
    else if (obj['COUNT']) {
      let item_date = new Date(item.start.dateTime);
      if (obj['FREQ'] == 'WEEKLY') {
        untilDate = new Date((item_date).setDate((item_date).getDate() + 7 * (parseInt(obj['COUNT']) - 1)));
      } else if (obj['FREQ'] == 'DAILY') {
        untilDate = new Date((item_date).setDate((item_date).getDate() + (parseInt(obj['COUNT']) - 1)));
      }
    } else {
      let item_date = new Date(item.start.dateTime);
      if (this.shopS.shopData.Setting.Premium.exclusive) {
        untilDate = new Date((item_date).setMonth((item_date).getMonth() + 3));
      } else {
        untilDate = new Date((item_date).setMonth((item_date).getMonth() + 1));
      }
    }

    console.log('recurrence', obj, untilDate, new Date(untilDate));
    if (untilDate.getTime() >= Today) {
      console.log('recurrence', untilDate.getTime(), '<=', Today, untilDate);

      this.convertPointToGoogleMeet(item, googleId);
    }


  }

  recurrenceGoogleMeet(item) { // check recurrence parameters and creat repeat meetings in point calendar
    let recurrence = (item.recurrence[0].slice(6)).split(';');
    let obj = {};
    let repEveryTime, untilDate;

    recurrence.forEach(el => {
      el = el.split('=')
      obj[el[0]] = el[1]
    });

    if (obj['FREQ']) {
      if (obj['FREQ'] == 'WEEKLY') {
        repEveryTime = 'week';
      } else if (obj['FREQ'] == 'DAILY') {
        repEveryTime = 'day';
      }
    }

    if (obj['UNTIL']) {
      let date = (obj['UNTIL'].slice(0, 4) + '-' + obj['UNTIL'].slice(4, 6) + '-' + obj['UNTIL'].slice(6, 8)).toString();
      untilDate = new Date((new Date(date)).setDate((new Date(date)).getDate() + 1))
    }
    else if (obj['COUNT']) {
      let item_date = new Date(item.start.dateTime);
      if (obj['FREQ'] == 'WEEKLY') {
        untilDate = new Date((item_date).setDate((item_date).getDate() + 7 * (parseInt(obj['COUNT']) - 1)));
      } else if (obj['FREQ'] == 'DAILY') {
        untilDate = new Date((item_date).setDate((item_date).getDate() + (parseInt(obj['COUNT']) - 1)));
      }
    } else {
      let item_date = new Date(item.start.dateTime);
      if (this.shopS.shopData.Setting.Premium.exclusive) {
        untilDate = new Date((item_date).setMonth((item_date).getMonth() + 3));
      } else {
        untilDate = new Date((item_date).setMonth((item_date).getMonth() + 1));
      }
    }

    console.log('recurrence', obj, untilDate, new Date(untilDate), repEveryTime);
    this.shopS.initRepeatMeet(repEveryTime, new Date(untilDate))

  }


  exportPointGoogleMeets() { //exports all the point meets to google calendar and then starts the sync
    console.log('exportPointGoogleMeets');

    ////////////////////////////////// check if premium > get all Meetings > send 
    if (this.user_type == 'exclusive') {
      // console.log("AAAAA");

      this.shopS.getShopMeetings(cb => {
        // console.log('Meetings', cb);
        let dates = this.shopS.json2array(cb);
        console.log('dates', dates);
        if (dates.length) {
          dates.forEach((date, index) => {
            // console.log("date: ", date);
            // console.log('/BarberShop/' + BarberId + '/Meetings/');
            let appointments = this.shopS.json2array(date);
            console.log('appointments', appointments);

            appointments.forEach(pointMeet => {
              console.log('Add Event To Calendar pointMeet: ', pointMeet);

              if (!pointMeet.googleId && pointMeet.meetStatus == 1) {

                let meetings_date = this.shopS.meetForBuis.convertDateToYYYY(pointMeet.meetDate);
                let googleEvent;

                let body = {
                  BarberId: this.shopS.shopData.BarberId,
                  pointMeet,
                  type: 'Add Event To Calendar'
                }
                console.log('Add Event To Calendar body: ', body);
                body = this.shopS.cryptoService.Encrypt(body);

                this.http.post(this.shopS.serverDev + '/GooglePointCalendarEvent', body).subscribe(val => {
                  googleEvent = val;
                  console.log('Add Event To Calendar val: ', googleEvent);
                  this.shopS.add_googld_db(googleEvent.id);
                  this.shopS.add_googleID_barber_meet(this.shopS.shopData.BarberId, pointMeet.key, pointMeet.meetDate, googleEvent.id, 'Point', true);//.meetForBuis.add_googleID_meet(googleEvent.id, 'Point', false);

                });
              }
            });
            // if (index == (dates.length - 1)) {
            //   ////////////////////////////////// start full sync - 
            //   console.log('callWatchEvent', index, dates.length - 1);

            //   this.callWatchEvent();
            // }
          });
          this.callWatchEvent();

        } else {
          console.log('no dates callWatchEvent');

          this.callWatchEvent();
        }
      });
    }
  }


  startGoogleSync() { // start the sync -> getting all google calendar events and import them to point calendar then export point meet to google and start sync
    if (this.user_type == 'exclusive') {
      this.GetCalendarEvents(cb => {
        console.log("finished import", cb);
        if (cb.status == "finished import") {
          this.showAlert(cb, ' עכשיו נתחיל בסנכרון!');
          this.import_events = this.shopS.shopData.Setting.GoogleCalendar.googleCalImport || 1;
          console.log('this.calendar_auth', this.calendar_auth, "this.import_events", this.import_events, this.user_type);
          this.exportPointGoogleMeets();
        }
      });
    }
  }



  showAlert(imported_events, txt) {

    var textT = '<span class="aaaa" style="">';
    var text = 'סה"כ פגישות שיובאו - ' + imported_events.count_imported + ' מתוך ' + imported_events.total + '. \n' + txt;

    console.log('textT', textT, text);
    textT += text + '</span>';
    this.alertModal2(textT, 'יבוא פגישות מגוגל', false, 'סבבה');

  }

  async alertModal(textT, header, action, btnTxt, cnlBtn) {

    const alert = await this.alertController.create({
      header: header,
      message: textT,
      buttons: [
        {
          text: btnTxt,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            switch (action) {

              case 'google_cal_logout':
                this.google_cal_logout();
                break;

              case 'stopNotificationsEvent':
                this.stopNotificationsEvent();
                break;

              default:
                // return false;
                break;
            }

          }
        },
        {
          text: cnlBtn,
          handler: () => {

            console.log('Okay: Okay');
            //confirm order 

          }
        }
      ]
    });
    await alert.present();

  }

  async alertModal2(textT, header, action, btnTxt) {

    const alert = await this.alertController.create({
      header: header,
      message: textT,
      buttons: [
        {
          text: btnTxt,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            switch (action) {
              case 'no refresh_token':
                this.res_url = this.iab.create('https://myaccount.google.com/permissions?pli=1');
                console.log('action res_url', action);
                this.alertModal2("התחברות מחדש ליומן גוגל", 'התחברות מחדש ליומן גוגל', "no Credentials", 'התחבר');
                break;

              case 'no Credentials':
                this.log_in_stage='auth-wait';
                this.google_cal_auth();
                break;

              default:
                // return false;
                break;
            }

          }
        },
        // {
        //   text: 'ביטול',
        //   handler: () => {

        //     console.log('Okay: Okay');
        //     //confirm order 

        //   }
        // }
      ]
    });
    await alert.present();

  }


  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////


  // addGoogleMeet(pointMeet) {
  //   if (this.shopS.shopData.Setting.Premium.exclusive) {
  //     if (this.shopS.shopData.Setting.GoogleCalendar.GoogleCalSynced) {
  //       console.log('Add Event To Calendar pointMeet: ', pointMeet);

  //       if (!pointMeet.googleId && pointMeet.meetStatus == 1) {

  //         let meetings_date = this.shopS.meetForBuis.convertDateToYYYY(pointMeet.meetDate);
  //         let googleEvent;

  //         let body = {
  //           BarberId: this.shopS.shopData.BarberId,
  //           pointMeet,
  //           type: 'Add Event To Calendar'
  //         }
  //         console.log('Add Event To Calendar body: ', body);
  //         body = this.shopS.cryptoService.Encrypt(body);

  //         this.http.post(this.shopS.serverDev + '/GooglePointCalendarEvent', body).subscribe(val => {
  //           googleEvent = val;
  //           console.log('Add Event To Calendar val: ', googleEvent);
  //           this.shopS.add_googld_db(googleEvent.id);
  //           this.shopS.meetForBuis.add_googleID_meet(googleEvent.id, 'Point', false);

  //         });
  //       }
  //     }
  //   }
  // }



  // updateGoogleMeet(newMeet, pointMeet) {
  //   if (this.shopS.shopData.Setting.Premium.exclusive) {
  //     if (this.shopS.shopData.Setting.GoogleCalendar.GoogleCalSynced) {

  //       if (!pointMeet.googleId && pointMeet.meetStatus == 1) {

  //         console.log('Edit Event Of Calendar pointMeet: ', pointMeet);
  //         console.log('Edit Event Of Calendar newMeet: ', newMeet);
  //         let body = {
  //           BarberId: this.shopS.shopData.BarberId,
  //           pointMeet,
  //           newMeet,
  //           type: 'Edit Event Of Calendar'
  //         }
  //         console.log('Edit Event Of Calendar body', body);
  //         body = this.shopS.cryptoService.Encrypt(body);

  //         this.http.post(this.shopS.serverDev + '/GooglePointCalendarEvent', body).subscribe(val => {
  //           console.log('Edit Event Of Calendar val: ', val);
  //         });
  //       }
  //     }
  //   }
  // }

  // deleteGoogleMeet(pointMeet) {
  //   if (this.shopS.shopData.Setting.Premium.exclusive) {
  //     if (this.shopS.shopData.Setting.GoogleCalendar.GoogleCalSynced) {

  //       if (pointMeet.googleId) {
  //         let MeetgoogleId = pointMeet.googleId;
  //         console.log('Delete Event From Calendar pointMeet.meetType.googleId: ', MeetgoogleId);

  //         let body = {
  //           BarberId: this.shopS.shopData.BarberId,
  //           googleId: MeetgoogleId,
  //           type: 'Delete Event From Calendar'
  //         }
  //         console.log('Delete Event From Calendar body', body);
  //         body = this.shopS.cryptoService.Encrypt(body);

  //         this.http.post(this.shopS.serverDev + '/GooglePointCalendarEvent', body).subscribe(val => {
  //           console.log('Delete Event val: ', val);
  //         });
  //       }
  //     }
  //   }
  // }






  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////


  // send_to_api_events(data, cb?) {
  //   if (firebase.auth().currentUser) {

  //     firebase.auth().currentUser.getIdToken().then(token => {
  //       this.userService.userToken = token;

  //       // this.userService.userToken
  //       // let expired_token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjJjZGFiZDIwNzVjODQxNDI0NDY3MTNlM2U0NGU5ZDcxOGU3YzJkYjQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vd2ViY3V0LWRldiIsImF1ZCI6IndlYmN1dC1kZXYiLCJhdXRoX3RpbWUiOjE2MjcyNDE5NjAsInVzZXJfaWQiOiJFSTcwZ0lqOTluYldZV25hMGtheWxjcG50TW0yIiwic3ViIjoiRUk3MGdJajk5bmJXWVduYTBrYXlsY3BudE1tMiIsImlhdCI6MTYzMDkyOTk5MCwiZXhwIjoxNjMwOTMzNTkwLCJlbWFpbCI6Im1hbWFAbWFtYS5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibWFtYUBtYW1hLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.Omv88w6aLFy-EFYw4J1xJ4tZhywwynSvtaSQSygcne7ZW8TeGzQ5EqVu542yt4ErqG5aipWsLZZGMYd7-of7jv_oCBHkonIQ5Xj1XDXcWs3Fc5FWKUCxDpx5mtJfBTAI57S76423DyhHkuAdy7_ft0cwN08LNCpZMKJwVv2s8wCFloX1eSZc7tHhsFdKuuZ7pNBbv2zoiQNYYNwZ3i_5Dj3UpyL9X3bYBWSBLqMzxVb1_ysEi-8-j0ZtadwsPUmuY0x8FPAabVTk7js6wfQDWwhc2axi3vWZav40fySiI3cfvldWMmPaixJyyGnzBvckHMR_kilWT7wbpcDghW2q0A';
  //       var reqHeader = new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + this.userService.userToken,
  //         // 'Authorization': 'Bearer ' + expired_token
  //         // 'Access-Token': this.getToken(),
  //       });
  //       let body = data;
  //       body['BarberID'] = this.shopS.shopData.Setting.BarberID;
  //       let encrypted = this.CryptoService.Encrypt(body);
  //       this.http.post(this.shopS.serverDev + '/CalendarEvents', encrypted, { headers: reqHeader }).subscribe((response: any) => {
  //         console.log('response', response);
  //         if (response.status) {
  //           cb(response);
  //         } else {
  //           // alert('problem')
  //           // if(response.res.code == 'auth/id-token-expired'){
  //           //   // alert('expired');
  //           //   firebase.auth().currentUser.getIdToken().then(token => {
  //           //     console.log('TOKEN',token)
  //           //     this.userService.userToken = token;
  //           //     this.send_to_api(type,data,cb);
  //           //   });


  //           // }
  //         }
  //       })// end of http post
  //     });// end of token

  //   } else {
  //     cb({ status: 'no auth' })
  //   }

  // }

  // authorization_code;
  // access_token;




  //https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&state=abcderf1234567890abcderf1234567890&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&response_type=code&client_id=353723050664-2g0ia6bld3jrn4gbfea2l6tlhsq4huao.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fus-central1-webcut-dev.cloudfunctions.net%2Fgoogle_calendar_token


  // send_to_api_watch(data, cb?) {
  //   if (firebase.auth().currentUser) {

  //     firebase.auth().currentUser.getIdToken().then(token => {
  //       this.userService.userToken = token;

  //       // this.userService.userToken
  //       // let expired_token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjJjZGFiZDIwNzVjODQxNDI0NDY3MTNlM2U0NGU5ZDcxOGU3YzJkYjQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vd2ViY3V0LWRldiIsImF1ZCI6IndlYmN1dC1kZXYiLCJhdXRoX3RpbWUiOjE2MjcyNDE5NjAsInVzZXJfaWQiOiJFSTcwZ0lqOTluYldZV25hMGtheWxjcG50TW0yIiwic3ViIjoiRUk3MGdJajk5bmJXWVduYTBrYXlsY3BudE1tMiIsImlhdCI6MTYzMDkyOTk5MCwiZXhwIjoxNjMwOTMzNTkwLCJlbWFpbCI6Im1hbWFAbWFtYS5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibWFtYUBtYW1hLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.Omv88w6aLFy-EFYw4J1xJ4tZhywwynSvtaSQSygcne7ZW8TeGzQ5EqVu542yt4ErqG5aipWsLZZGMYd7-of7jv_oCBHkonIQ5Xj1XDXcWs3Fc5FWKUCxDpx5mtJfBTAI57S76423DyhHkuAdy7_ft0cwN08LNCpZMKJwVv2s8wCFloX1eSZc7tHhsFdKuuZ7pNBbv2zoiQNYYNwZ3i_5Dj3UpyL9X3bYBWSBLqMzxVb1_ysEi-8-j0ZtadwsPUmuY0x8FPAabVTk7js6wfQDWwhc2axi3vWZav40fySiI3cfvldWMmPaixJyyGnzBvckHMR_kilWT7wbpcDghW2q0A';
  //       var reqHeader = new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + this.userService.userToken,
  //         // 'Authorization': 'Bearer ' + expired_token
  //         'Access-Token': this.getToken(),
  //       });
  //       let body = {
  //         event: 'Watch Events On Calendar',
  //         BarberID: this.shopS.shopData.Setting.BarberID
  //       };
  //       // let body = data;
  //       // body['BarberID'] = this.shopS.shopData.Setting.BarberID;
  //       let encrypted = this.CryptoService.Encrypt(body);
  //       this.http.post(this.shopS.serverDev + '/CalendarNotifications', encrypted, { headers: reqHeader }).subscribe((response: any) => {
  //         console.log('response', response);
  //         if (response.status) {
  //           cb(response);
  //         } else {
  //           // alert('problem')
  //           // if(response.res.code == 'auth/id-token-expired'){
  //           //   // alert('expired');
  //           //   firebase.auth().currentUser.getIdToken().then(token => {
  //           //     console.log('TOKEN',token)
  //           //     this.userService.userToken = token;
  //           //     this.send_to_api(type,data,cb);
  //           //   });


  //           // }
  //         }
  //       })// end of http post
  //     });// end of token

  //   } else {
  //     cb({ status: 'no auth' })
  //   }

  // }

  // send_to_api_stop(data, cb?) {
  //   if (firebase.auth().currentUser) {

  //     firebase.auth().currentUser.getIdToken().then(token => {
  //       this.userService.userToken = token;

  //       // this.userService.userToken
  //       // let expired_token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjJjZGFiZDIwNzVjODQxNDI0NDY3MTNlM2U0NGU5ZDcxOGU3YzJkYjQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vd2ViY3V0LWRldiIsImF1ZCI6IndlYmN1dC1kZXYiLCJhdXRoX3RpbWUiOjE2MjcyNDE5NjAsInVzZXJfaWQiOiJFSTcwZ0lqOTluYldZV25hMGtheWxjcG50TW0yIiwic3ViIjoiRUk3MGdJajk5bmJXWVduYTBrYXlsY3BudE1tMiIsImlhdCI6MTYzMDkyOTk5MCwiZXhwIjoxNjMwOTMzNTkwLCJlbWFpbCI6Im1hbWFAbWFtYS5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibWFtYUBtYW1hLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.Omv88w6aLFy-EFYw4J1xJ4tZhywwynSvtaSQSygcne7ZW8TeGzQ5EqVu542yt4ErqG5aipWsLZZGMYd7-of7jv_oCBHkonIQ5Xj1XDXcWs3Fc5FWKUCxDpx5mtJfBTAI57S76423DyhHkuAdy7_ft0cwN08LNCpZMKJwVv2s8wCFloX1eSZc7tHhsFdKuuZ7pNBbv2zoiQNYYNwZ3i_5Dj3UpyL9X3bYBWSBLqMzxVb1_ysEi-8-j0ZtadwsPUmuY0x8FPAabVTk7js6wfQDWwhc2axi3vWZav40fySiI3cfvldWMmPaixJyyGnzBvckHMR_kilWT7wbpcDghW2q0A';
  //       var reqHeader = new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + this.userService.userToken,
  //         // 'Authorization': 'Bearer ' + expired_token
  //         'Access-Token': this.getToken(),
  //       });

  //       let body = {
  //         event: 'Stop Notifications On Calendar',
  //         BarberID: this.shopS.shopData.Setting.BarberID
  //       };
  //       // let body = data;
  //       // body['BarberID'] = this.shopS.shopData.Setting.BarberID;
  //       let encrypted = this.CryptoService.Encrypt(body);
  //       this.http.post(this.shopS.serverDev + '/CalendarNotifications', encrypted, { headers: reqHeader }).subscribe((response: any) => {
  //         console.log('response', response);
  //         if (response.status) {
  //           cb(response);
  //         } else {
  //           // alert('problem')
  //           // if(response.res.code == 'auth/id-token-expired'){
  //           //   // alert('expired');
  //           //   firebase.auth().currentUser.getIdToken().then(token => {
  //           //     console.log('TOKEN',token)
  //           //     this.userService.userToken = token;
  //           //     this.send_to_api(type,data,cb);
  //           //   });


  //           // }
  //         }
  //       })// end of http post
  //     });// end of token

  //   } else {
  //     cb({ status: 'no auth' })
  //   }

  // }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////


  // public setUser(user: GoogleUser): void {
  //   this.user = user;
  // }

  // public getCurrentUser(): GoogleUser {
  //   return this.user;
  // }

  // public getToken(): string {
  //   let token: string = sessionStorage.getItem(GoogleCalendarService.SESSION_STORAGE_KEY);
  //   if (!token) {
  //     throw new Error('no token set, authentication required');
  //   }
  //   // return sessionStorage.getItem(GoogleCalendarService.SESSION_STORAGE_KEY);
  //   // console.log(token);

  //   return token;
  // }

  // public signIn() {
  //   this.googleAuthService.getAuth().subscribe((auth) => {
  //     auth.signIn().then(
  //       (res) => {
  //         console.log(res);
  //         this.signInSuccessHandler(res);
  //         // this.access_token = this.getToken();
  //         // console.log(this.access_token);

  //         // this.client_id = auth.Ha.clientId;
  //         // console.log(this.client_id);
  //         // let redirecturi = auth.Ha.authParameters.redirect_uri;
  //         // let token_uri = "https://oauth2.googleapis.com/token";

  //         // auth.grantOfflineAccess().then(val => {
  //         //   console.log('val code:', val.code);
  //         //   this.authorization_code = val.code;
  //         //   // let data = {
  //         //   //   client_id: this.client_id,
  //         //   //   code: val.code,
  //         //   //   client_secret: "tyHuPOxBO0Z3iu3yD9Sda4si",
  //         //   //   redirect_uri: "https://developers.google.com/oauthplayground",
  //         //   //   grant_type: 'authorization_code'
  //         //   // }

  //         //   // this.http.post(token_uri, data).subscribe(val => {
  //         //   //   console.log('val exchangeAuthCode', val);
  //         //   // });

  //         // });

  //         console.log('auth', auth);

  //       },
  //       (err) => this.signInErrorHandler(err),
  //     );
  //   });
  // }

  // public signOut(): void {
  //   this.googleAuthService.getAuth().subscribe((auth) => {
  //     try {
  //       auth.signOut();
  //     } catch (e) {
  //       console.error(e);
  //     }
  //     sessionStorage.removeItem(GoogleCalendarService.SESSION_STORAGE_KEY);
  //   });
  // }

  // public isUserSignedIn(): boolean {
  //   return !_.isEmpty(sessionStorage.getItem(GoogleCalendarService.SESSION_STORAGE_KEY));
  // }

  // private signInSuccessHandler(res: GoogleUser) {
  //   this.ngZone.run(() => {
  //     this.user = res;
  //     sessionStorage.setItem(
  //       GoogleCalendarService.SESSION_STORAGE_KEY,
  //       res.getAuthResponse().access_token
  //     );
  //     console.log("signInSuccessHandler: ", res.getAuthResponse());
  //   });
  // }

  // private signInErrorHandler(err) {
  //   console.warn(err);
  // }

  // calendarEvents(event: any) {
  //   event.BarberId = this.shopS.shopData.BarberId;
  //   this.http.post('https://us-central1-webcut-dev.cloudfunctions.net/CalendarEvents', event).subscribe(val => {
  //     console.log('val', val);
  //   });
  // }



  //######################################################################################################
  //
  // POINT MEET OBJ
  //  {
  //   "BarberID": "f366aa26-25c6-4f5e-9e70-7f1f4d4ff6b1",
  //   "Date": "Mon May 23 2022 09:00:00 GMT+0300 (Israel Daylight Time)",////////////////////
  //   "barberName": "jgg",
  //   "endTime": "2022-05-23T06:30:00.000Z",
  //   "endTimeView": "09:30",
  //   "flow": false,
  //   "key": "f7078eac-0ef9-4bab-975a-41d65bf57ee6",
  //   "mType": {
  //     "duplicated": "",
  //     "repeat": "",
  //     "type": "Admin"
  //   },
  //   "meetDate": "23-05-2022",
  //   "meetDateFormat": "23-05-2022",
  //   "meetStatus": 1,
  //   "meetTime": "30",/////////////////////////////
  //   "meetType": {
  //     "active": false,
  //     "approve": false,
  //     "cancelation": "0",
  //     "color": "#4366FB",
  //     "flow": false,
  //     "gender": "female",
  //     "info": "987654",
  //     "key": "-MznyuK-apgL7uMprELA",
  //     "meetTime": "30",
  //     "name": "משהו",/////////////////////////
  //     "pic": "לחeמ",
  //     "price": "30",
  //     "reminder": 0
  //   },
  //   "moreInfo": "ckvkckvkv",//////////////////
  //   "price": "30",
  //   "reminder": 0,///////////////////
  //   "sendSms": true,
  //   "startDate": "Mon May 23 2022 09:00:00 GMT+0300 (Israel Daylight Time)",/////////////////////////
  //   "startTimeView": "09:00",
  //   "typem": "meet",
  //   "uId": "localUserAdmin",
  //   "zoomlink": "",
  //   "title": "test",
  //   "allDay": false,
  //   "startTime": "2022-05-23T06:00:00.000Z",
  //   "meetTypeData": {
  //     "active": false,
  //     "approve": false,
  //     "cancelation": "0",
  //     "color": "#4366FB",
  //     "flow": false,
  //     "gender": "female",
  //     "info": "987654",
  //     "key": "-MznyuK-apgL7uMprELA",
  //     "meetTime": "30",
  //     "name": "משהו",///////////////////////////////
  //     "pic": "לחeמ",
  //     "price": "30",
  //     "reminder": 0////////////////////////////////////
  //   },
  //   "uIdData": {
  //     "ProfilePic": "https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/icon.png?alt=media&token=9663ccd6-2c4e-415a-ae30-975e3fa2eb8f",
  //     "fname": "jgg",
  //     "key": "localUserAdmin",
  //     "lname": "",
  //     "local": "2",
  //     "phone": ""
  //   }
  // }


  //   {
  //     "BarberID": "f366aa26-25c6-4f5e-9e70-7f1f4d4ff6b1",
  //     "Date": "Thu May 26 2022 10:30:00 GMT+0300 (Israel Daylight Time)",
  //     "barberName": "jgg",
  //     "endTime": "2022-05-26T08:25:00.000Z",
  //     "endTimeView": "11:25",
  //     "flow": false,
  //     "key": "a35860ea-e65f-4158-87e5-dc1f64fb4092",
  //     "mType": {
  //         "duplicated": "",
  //         "repeat": "",
  //         "type": "Admin"
  //     },
  //     "meetDate": "26-05-2022",
  //     "meetDateFormat": "26-05-2022",
  //     "meetStatus": 1,
  //     "meetTime": "55",
  //     "meetType": {
  //         "active": false,
  //         "approve": false,
  //         "cancelation": "0",
  //         "color": "#4366FB",
  //         "flow": false,
  //         "gender": "female",
  //         "info": "987654",
  //         "key": "-MznyuK-apgL7uMprELA",
  //         "meetTime": "55",
  //         "name": "משהו",
  //         "pic": "לחeמ",
  //         "price": "30",
  //         "reminder": 0
  //     },
  //     "moreInfo": "",
  //     "price": "30",
  //     "reminder": 0,
  //     "sendSms": true,
  //     "startDate": "Thu May 26 2022 10:30:00 GMT+0300 (Israel Daylight Time)",
  //     "startTimeView": "10:30",
  //     "typem": "meet",
  //     "uId": "eRxG1mJsL2ZqlrIxJZbbuEWw8dp2",
  //     "zoomlink": "",
  //     "title": "test",
  //     "allDay": false,
  //     "startTime": "2022-05-26T07:30:00.000Z",
  //     "meetTypeData": {
  //         "active": false,
  //         "approve": false,
  //         "cancelation": "0",
  //         "color": "#4366FB",
  //         "flow": false,
  //         "gender": "female",
  //         "info": "987654",
  //         "key": "-MznyuK-apgL7uMprELA",
  //         "meetTime": "55",
  //         "name": "משהו",
  //         "pic": "לחeמ",
  //         "price": "30",
  //         "reminder": 0
  //     },
  //     "uIdData": {
  //         "email": "fdsfds@fdsfsfds.ff",
  //         "fname": "הדס",
  //         "lastSeen": "2022-05-22T09:32:02.020Z",
  //         "lname": "ישראלי"
  //     }
  // }


  //#################################################################################3
  // GOOGLE CAL OBJ
  // {
  //   "kind": "calendar#event",
  //   "etag": "\"3306756599834000\"",
  //   "id": "6kl28uff3usm4ngrb5jqavjnqc", //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<FOR EDIT EVENT/MEET
  //   "status": "confirmed",
  //   "htmlLink": "https://www.google.com/calendar/event?eid=NmtsMjh1ZmYzdXNtNG5ncmI1anFhdmpucWMgaGFkYXNAd2ViYy5jby5pbA",
  //   "created": "2022-05-24T07:44:59.000Z",
  //   "updated": "2022-05-24T07:44:59.942Z",
  //   "summary": "כללי - jgg ",
  //   "creator": {
  //       "email": "hadas@webc.co.il",
  //       "self": true
  //   },
  //   "organizer": {
  //       "email": "hadas@webc.co.il",
  //       "self": true
  //   },
  //   "start": {
  //       "dateTime": "2022-05-27T09:30:00+03:00",
  //       "timeZone": "UTC+03:00"
  //   },
  //   "end": {
  //       "dateTime": "2022-05-27T09:50:00+03:00",
  //       "timeZone": "UTC+03:00"
  //   },
  //   "iCalUID": "6kl28uff3usm4ngrb5jqavjnqc@google.com",
  //   "sequence": 0,
  //   "reminders": {
  //       "useDefault": false,
  //       "overrides": [
  //           {
  //               "method": "popup",
  //               "minutes": 30,
  //               "key": "0"
  //           }
  //       ]
  //   },
  //   "eventType": "default"
  // }












}
