import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingService } from 'src/providers/loading.service';
import { ModalController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';
import { WalletServiceService } from '../wallet-service/wallet-service.service';
import { InvoiceServiceService } from 'src/app/invoice/invoice-service/invoice-service.service';
import { NgForm } from '@angular/forms';
import { PaymentService } from "../../payments/payment-service/payment.service"
import { Router } from '@angular/router';
import { userService } from 'src/ShopService/user.service';

@Component({
  selector: 'app-wallet-setup',
  templateUrl: './wallet-setup.component.html',
  styleUrls: ['./wallet-setup.component.scss'],
})
export class WalletSetupComponent implements OnInit {

  today = new Date();

  constructor(
    public walletS: WalletServiceService,
    public shopS: ShopService,
    private LoadingService: LoadingService,
    public ModalController: ModalController,
    public invoiceS: InvoiceServiceService,
    public PaymentService: PaymentService,
    private router: Router,
    private us: userService
  ) { }

  terms = 'short';
  new_seller: any = {
    // seller_id: "fadsdsaadsadsa!j", 
    // seller_first_name: "First",
    // seller_last_name: "Last",
    // seller_social_id: "9999999999",
    // seller_bank_code: "10",
    // seller_bank_branch: "501",
    // seller_bank_account_number: "57288647",
    // seller_email:"ggdsad@webc.co.il",
    // seller_person_business_type: 1130,
    // seller_phone:"0527420606",
    // seller_birthdate:"22/07/1990",
    // seller_inc: "3",
    // seller_merchant_name: "Business Name",
    // seller_inc_code: "321312312",
    // seller_contact_email: "knlknlk@test.com",
    // seller_contact_phone: "0512345678",
    // seller_site_url: "www.paymeservice.com",
    // seller_address_city: "city",
    // seller_address_street: "street",
    // seller_address_street_number : "street number"
  }

  submit_new_seller() {
    console.log(this.new_seller);
    this.walletS.send_to_api('create-seller', this.new_seller, val => {
      this.LoadingService.dismiss();
      if (val) {
        val = val.res;
        console.log(val);

        // let message;
        if (val['status_code'] == 0) {
          this.shopS.log(this.us.user.key, 'wallet_create_success');
          this.router.navigate(['wallet'], { replaceUrl: true });
          this.ModalController.dismiss();
        } else {
          this.shopS.log(this.us.user.key, 'wallet_create_fail');
          let foundStep = null;
          let new_msg = '';
          let wrong_field = val['status_additional_info'];
          if (wrong_field) {
            if (wrong_field == 'seller_birthdate') {
              wrong_field = 'seller_full_birthdate';
            }
            if (wrong_field == 'seller_social_id_issued') {
              wrong_field = 'seller_full_social_id_issued';
            }
            if (wrong_field == 'seller_address_city' || wrong_field == 'seller_address_street' || wrong_field == 'seller_address_street_number') {
              wrong_field = 'seller_address';
            }

            this.setup_steps.forEach((step, index) => {
              let found = false;
              if (step.fields) {
                step.fields.forEach(field => {
                  if (field.field == wrong_field) {
                    new_msg += field.name;
                    foundStep = index;
                    found = true;
                    return;
                  }
                });
              }
              if (found) {
                return;
              }
            });
          }
          if (!new_msg) {
            new_msg = val['status_error_details'];
          }

          this.walletS.presentAlert(foundStep, new_msg).then(() => {
            if (foundStep != null) {
              this.currentStep = foundStep
              let el = document.getElementById(wrong_field);
              el.classList.add('wallet_form_invalid');
            }
          });
        }
      }
    });
  }

  confirmCheckBox = [{ text: 'קראתי ואני מאשר את תנאי השימוש', value: false }, { text: 'אני עסק מורשה בישראל', value: false }, { text: 'עוד תנאי שימוש', value: false }];

  @ViewChild('personalForm', { static: false }) personalForm: NgForm;
  @ViewChild('businessForm', { static: false }) businessForm: NgForm;
  @ViewChild('bankForm', { static: false }) bankForm: NgForm;


  setup_steps = [
    {
      prece: '10%',
      txt: 'לפרטי העסק',
      fields: [{
        name: 'שם פרטי',
        field: 'seller_first_name'
      }, {
        name: 'שם משפחה',
        field: 'seller_last_name'
      }, {
        name: 'מספר תעודת זהות',
        field: 'seller_full_social_id'
      }, {
        name: 'תאריך הנפקת תעודת זהות',
        field: 'seller_social_id_issued'
      }, {
        name: 'מין',
        field: 'seller_gender'
      }, {
        name: 'דואר אלקטרוני',
        field: 'seller_email'
      }, {
        name: 'תאריך לידה',
        field: 'seller_full_birthdate'
      }, {
        name: 'טלפון',
        field: 'seller_phone'
      }],
      submitted: false,
      form: null
    },
    {
      prece: '50%',
      txt: 'לפרטי חשבון הבנק',
      fields: [{
        name: 'שם העסק',
        field: 'seller_merchant_name'
      }, {
        name: 'סוג העסק',
        field: 'seller_inc'
      }, {
        name: 'מספר ש.מ/ח.פ',
        field: 'seller_inc_code'
      }, {
        name: 'ענף פעילות',
        field: 'seller_person_business_type'
      }, {
        name: 'אתר העסק',
        field: 'seller_site_url'
      }, {
        name: 'כתובת',
        field: 'seller_address'
      }],
      submitted: false,
      form: null
    },
    {
      prece: '75%',
      txt: 'לתנאי השימוש',
      fields: [{
        name: 'מספר בנק',
        field: 'seller_bank_code'
      }, {
        name: 'מספר סניף בנק',
        field: 'seller_bank_branch'
      }, {
        name: 'מספר חשבון בנק',
        field: 'seller_bank_account_number'
      }],
      submitted: false,
      form: null
    },
    {
      prece: '90%',
      txt: 'יצירת ארנק',
      submitted: false,
      form: { valid: false }
    },
    {
      prece: '90%',
      txt: 'פרימיום משלמים פחות',
      submitted: false,
      form: null
    }
  ]

  previous_step() {
    if (this.currentStep >= 0) {
      this.currentStep--;
      if (this.currentStep > -1) {
        if (this.setup_steps[this.currentStep].form) {
          this.currentForm = this.setup_steps[this.currentStep].form;
        }
      }
    } else {
      this.haveAccount = false;
    }
  }

  selectFocus(field) {
    // form.controls[field].clicks = form.controls[field].clicks ? (form.controls[field].clicks + 1) : 1;
    // console.log(form);

    field.clicks = field.clicks ? (field.clicks + 1) : 1;
    console.log(field);
    console.log(this.new_seller);


  }

  banksListOpen = false;
  choosenBank;

  chooseBank(bank) {
    this.choosenBank = bank;
    this.new_seller['seller_bank_code'] = bank.value;
    this.banksListOpen = false;
    this.onChange({ valid: true });
  }

  getBank() {
    console.log(this.new_seller.seller_bank_code);

    if (this.new_seller.seller_bank_code) {
      let foundBank = this.walletS.banks.find(bank => bank.value == this.new_seller.seller_bank_code);
      console.log(foundBank);

      if (foundBank) {
        this.choosenBank = foundBank;
      }
    }
  }

  confirmCheck(ev) {
    if (!this.confirmCheckBox.map(val => val.value).includes(false)) {
      this.setup_steps[3].form.valid = true;
    } else {
      this.setup_steps[3].form.valid = false;
    }
  }

  haveAccount = false;
  currentStep = -1;

  next_step() {
    if (this.currentStep >= 0) {
      this.setup_steps[this.currentStep].submitted = true;
      if (this.currentForm.valid) {
        if (this.currentStep == 1) {
          this.getBank();
        }
        if (this.currentStep == 3) {
          if (!this.confirmCheckBox.map(val => val.value).includes(false)) {
            this.LoadingService.presentforever_with_text('יוצר ארנק..');
            this.new_seller.seller_birthdate = this.dateFormat(this.new_seller.seller_full_birthdate);
            this.new_seller.seller_social_id_issued = this.dateFormat(this.new_seller.seller_full_social_id_issued);
            this.submit_new_seller();
          }

        } else if (this.currentStep == 4) {
          this.PaymentService.pay_now_product('056538ed-563b-46d1-b001-a90e69b50f4b', this.PaymentService.premium_product_key);
        } else {
          this.currentStep++;
          this.currentForm = this.setup_steps[this.currentStep].form;
        }
      }
    } else {
      this.currentStep++;
      this.currentForm = this.setup_steps[this.currentStep].form;
    }
  }

  gif_status = '';


  current_invoice_supplier: any = {};

  select_invoice_supplier(type) {
    this.current_invoice_supplier = type;
    this.currentStep = 2;
  }

  profileSite() {
    this.new_seller.seller_site_url = 'https://app.point-app.net/b/' + this.shopS.shopData.Setting.code;
  }


  auth = {
    id: 'c8f03432-794b-4f2d-8120-faf718e6a9be',
    secret: 'VsFnHnTYfaY2GDVqDdPPug',
    username: '',
    password: '',
  }
  auth_changed(event, type) {
    this.auth[type] = event.target.value;
    this.currentStep = 4;

  }

  check_status = {
    status: 'try',
    txt: 'מנסה להתחבר'
  };
  message = '';
  check_auth() {
    this.check_status = {
      status: 'try',
      txt: 'מנסה להתחבר'
    };
    this.gif_status = '';
    // this.invoiceS.try_auth(this.current_invoice_supplier,this.auth,cb =>{
    //   console.log(cb);
    //   if(cb.status){
    //     this.gif_status = this.shopS.celebrating_gif_array[Math.floor(Math.random() * this.shopS.celebrating_gif_array.length)]
    //     this.check_status = {
    //       status : 'succses',
    //       txt: 'התחברנו! '
    //     }
    //     this.invoiceS.set_invoice_supplier(this.current_invoice_supplier.name);
    //   }else{ 
    //     this.gif_status = this.shopS.celebrating_gif_array[Math.floor(Math.random() * this.shopS.celebrating_gif_array.length)]

    //     this.check_status = {
    //       status : 'failed',
    //       txt: cb.message
    //     }
    //   }      


    // });
  }

  changeRadio(field, value) {
    console.log(field);
    console.log(value);

    this.new_seller[field] = value;
    console.log(this.new_seller);

  }
  currentForm;

  addzero(str) {
    let val = str.toString();
    if (val.length == 1) {
      val = '0' + val;
    }
    return val;
  }

  dateFormat(date) {
    let newDate = new Date(date);

    let newDate_day = this.addzero(newDate.getUTCDate());
    let newDate_month = this.addzero(newDate.getUTCMonth() + 1);
    let newDate_year = newDate.getUTCFullYear();

    // let fixedDate = newDate_year + '-' + newDate_fixedMonth + '-' + newDate_fixedDay;
    let fixedDate = newDate_day + '/' + newDate_month + '/' + newDate_year;
    // let fixedDate = newDate_fixedDay + '/' + newDate_fixedMonth + '/' + newDate_year;

    return fixedDate;
  }

  onChange(field, value?) {
    if (value) {
      this.new_seller[field.name] = value;
    }
    if (field.valid) {
      if (field.name == 'master_card_costumer_number') {
        this.new_seller['haveAccount'] = true; // ADJUST
      }

      this.shopS.shopData.Wallet['wallet_setup'] = this.new_seller;
      this.shopS.shopData.Wallet['wallet_setup_status'] = 'start';
      this.walletS.afDb.object('/BarberShop/' + this.shopS.shopData.Setting.BarberID + '/Wallet').update({ wallet_setup: this.new_seller, wallet_setup_status: 'start' });
      console.log(this.new_seller);
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.setup_steps[0].form = this.personalForm;
      this.setup_steps[1].form = this.businessForm;
      this.setup_steps[2].form = this.bankForm;
    }, 100);

    if (this.shopS.shopData.Wallet) {
      this.new_seller = this.shopS.shopData.Wallet.wallet_setup;
    } else {
      let userData = this.walletS.userService.getUserObj();
      let shopData = this.shopS.shopData;

      console.log('user-token', this.walletS.userService.userToken)
      console.log('this.shopS.shopData', this.shopS.shopData)
      console.log('userData', userData)

      this.new_seller = {
        // knowns
        seller_id: this.shopS.shopData.Setting.BarberID,
        seller_first_name: userData.fname,
        seller_last_name: userData.lname,
        seller_email: userData.email,
        seller_phone: userData.phone.replaceAll('+972', '0'),
        seller_merchant_name: this.shopS.shopData.Setting.name,
        seller_contact_email: userData.email,
        seller_contact_phone: userData.phone.replaceAll('+972', '0'),
        // need check
        seller_person_business_type: 1321,
        seller_address_city: '',
        seller_address_street: '',
        seller_address_street_number: '',

        // unknowns
        seller_site_url: '', // אולי להכין להם סוג של מיני סייט ???
        seller_inc: '', // - סוג העוסק 
        seller_inc_code: '', // מספר עוסק 
        seller_birthdate: '', // <- need to Get?
        seller_social_id: '',
        seller_bank_code: '',
        seller_bank_branch: '',
        seller_bank_account_number: '',
        seller_gender: '',
        seller_social_id_issued: ''

      }
      // const UserDoc = this.walletS.Firestore.collection('Wallet-Setup').doc(this.shopS.shopData.Setting.BarberID);
      if (!this.shopS.shopData.Wallet) {
        this.shopS.shopData['Wallet'] = {
          wallet_setup: null,
          wallet_setup_status: null,
          files_submit: false,
          documents: []
        }
      }

      this.shopS.shopData.Wallet['wallet_setup'] = this.new_seller;
      this.shopS.shopData.Wallet['wallet_setup_status'] = 'open';
      this.walletS.afDb.object('/BarberShop/' + this.shopS.shopData.Setting.BarberID + '/Wallet').update({ wallet_setup: this.new_seller, wallet_setup_status: 'open', files_submit: false, documents: [] });
      // this.walletS.send_to_api('type','dsa',cb=>{

      //   console.log( 'cb', cb   )
      //   console.log( 'userData', userData   )
      //   console.log( 'shopData', shopData   )
      // })

    }// end of else Wallet


    console.log(this.new_seller);


  }

  dismissModal() {
    this.ModalController.dismiss();
  }



}
