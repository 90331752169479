import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ModalController } from '@ionic/angular';
import { CryptoService } from "../../../app/crypto.service";
import { ToastService } from "../../../providers/toast-service";
import { LoadingService } from "../../../providers/loading.service";
import { Http, Response } from '@angular/http';
import { BehaviorSubject } from 'rxjs';
import { promise } from 'protractor';
import { userService } from '../../../ShopService/user.service';
import { formatDate } from "@angular/common";
import { ShopService } from '../.././../ShopService/shop.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { PaynowComponent } from "../paynow/paynow.component";
import { AddCreditCardComponent } from "../add-credit-card/add-credit-card.component";

import { FingerprintAIO, FingerprintOptions } from "@ionic-native/fingerprint-aio/ngx";

import { ChangeCardSubscriptionComponent } from "../change-card-subscription/change-card-subscription.component";

import { AddCardIframeComponent } from "../add-card-iframe/add-card-iframe.component";
import * as querybase from 'querybase';
import { CardOptionsComponent } from '../card-options/card-options.component';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { AddCreditCardPaymeComponent } from '../add-credit-card-payme/add-credit-card-payme.component';
import { env } from 'src/app/app.module';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  point_premium_product = {
    id: 'pointPremium',
    name: 'פרמיום לפוינט',
    price: 79,
    payType: 'subscription',
    BarberId: "046b7827-7a6d-4a0a-9c98-32f497b5d976",
    BarberName: 'פוינט',
    BarberProfile: 'https://images.unsplash.com/photo-1593366299134-3ef7cd2abbb8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80',
    SubscriptionImg: 'https://i.ibb.co/hXrcCyT/Screen-Shot-2020-07-09-at-19-54-323231.png'
  }


  premium_product_key = "-MGXUUY-621pbguAGGHm";
  premium_year_product_key = "-MGXUUY-621pbguAGGHm";

  exclusive_product_key = "-MGXUUY-621pbguAGGHm";
  exclusive_year_product_key = "-MGXUUY-621pbguAGGHm";

  grace_days = 0;
  grace_untill;
  grace_to_give = 4;

  calc_days_grace() {

    let sub = {
      type: 'userid',
      new_type: 'user_id',
      data: this.userService.key
    };
    this.check_grace(sub);

    // this.getSubscription(sub);
  }


  currentProduct: any;
  user: any;
  mySubscriptionArray: any = [];



  sendAjax = new BehaviorSubject({});

  constructor(
    private afDb: AngularFireDatabase,
    private modalController: ModalController,
    private _http: Http,
    public CryptoService: CryptoService,
    public ToastService: ToastService,
    public LoadingService: LoadingService,
    public userService: userService,
    public shopS: ShopService,
    private faio: FingerprintAIO,
    public http: HttpClient,
    public Firestore: AngularFirestore,


  ) {

  }


  AuthFingerprintAIO(ccard) {
    this.faio.show({
      title: 'אישור התשלום', // (Android Only) | optional | Default: "<APP_NAME> Biometric Sign On"
      // subtitle: 'Coolest Plugin ever', // (Android Only) | optional | Default: null
      // description: '', // optional | Default: null
      fallbackButtonTitle: '', // optional | When disableBackup is false defaults to "Use Pin".
      // When disableBackup is true defaults to "Cancel"
      disableBackup: true,  // optional | default: false
    })
      .then((result: any) => {
        console.log(result);
        this.sendDataTo(ccard);
      })
      .catch((error: any) => {
        console.log(error);

        if (error == 'cordova_not_available') {
          this.sendDataTo(ccard);
        } else {
          if (error.code == -106 || error.code == -103 || error.code == -101 || error.code == -104) {
            this.sendDataTo(ccard);
          } else {
            this.shopS.logWithData(this.shopS.us.key, 'error-AuthFingerprintAIO', error);
            this.ToastService.showTostDanger('חובה לאשר');
          }
          // BIOMETRIC_UNKNOWN_ERROR = -100;
          // BIOMETRIC_UNAVAILABLE = -101;
          // BIOMETRIC_AUTHENTICATION_FAILED = -102;
          // BIOMETRIC_SDK_NOT_SUPPORTED = -103;
          // BIOMETRIC_HARDWARE_NOT_SUPPORTED = -104;
          // BIOMETRIC_PERMISSION_NOT_GRANTED = -105;
          // BIOMETRIC_NOT_ENROLLED = -106;
          // BIOMETRIC_INTERNAL_PLUGIN_ERROR = -107;
          // BIOMETRIC_DISMISSED = -108;
          // BIOMETRIC_PIN_OR_PATTERN_DISMISSED = -109;
          // BIOMETRIC_SCREEN_GUARD_UNSECURED = -110;
          // BIOMETRIC_LOCKED_OUT = -111;
          // BIOMETRIC_LOCKED_OUT_PERMANENT = -112;
          // BIOMETRIC_SECRET_NOT_FOUND = -113;


        }


      });

  }



  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////




  // addNewCard(data){
  //   this.afDb.list('/Users/' + this.user.key + '/Payments/cards/')
  //   .push(data)
  // }



  // getUserCard(){
  // return this.afDb.object('/Users/' + this.user.key + '/Payments/cards/')
  // .valueChanges() 

  // }

  deleteCard(card) {
    let user = this.userService.getUserObj();
    //console.log('/Users/' + user.key + '/Payments/cards/'+ card.key);
    //  console.log('deleteCard(userid,cardid)',userid,cardid);
    ///        Users/ABEx8GVpqbYnECpjrZKGrqzfHFA3/Payments/cards/-MKjW-XPIKrY3VCRGLeM
    //         /Users/ABEx8GVpqbYnECpjrZKGrqzfHFA3/Payments/cards/-MKjW-XPIKrY3VCRGLeM

    this.shopS.log(user.key, 'payment_deleteCard');



    this.afDb.list('/Users/' + user.key + '/Payments/cards/' + card.key).remove().finally().then(val => {
      //console.log(this.userCards,val);
      this.userCards = this.userCards.filter(el => el.key !== card.key);

      this.modalController.dismiss();
    });
    setTimeout(() => {
      if (card.defaultCard) {
        this.setDefaultByKey(this.userCards[0].key);
      }
    }, 1000);

  }

  updateCard(ccard, key) {
    let data = {}
    let user = this.userService.getUserObj();
    data[key] = ccard;
    this.afDb.object('/Users/' + user.key + '/Payments/cards/').update(data)
  }



  updateSubscriptionCard(ccard, key) {
    this.afDb.object('/Subscription/' + key).update({ ccard: this.CryptoService.Encrypt(ccard) });
    this.shopS.turnOnConfetti(3000);
    this.ToastService.showToast('הכרטיס הוחלף בהצלחה');
    this.CardSubscriptionModal.dismiss();
  }





  setDefaultByKey(key) {
    let newDefault = this.userCards.find(o => o.key === key);
    newDefault.defaultCard = true;
    this.updateCard(this.CryptoService.Encrypt(newDefault), newDefault.key);

  }

  changeDefault(key) {
    // console.log('key',key);
    // console.log('userCards',this.userCards);

    let newDefault = this.userCards.find(o => o.key === key);

    let oldDefault = this.userCards.find(o => o.defaultCard === true);

    newDefault.defaultCard = true;
    if (oldDefault) {
      oldDefault.defaultCard = false;
      this.updateCard(this.CryptoService.Encrypt(oldDefault), oldDefault.key);

    }
    newDefault.defaultCard = true
    // console.log('oldDefault',oldDefault);
    // console.log('newDefault',newDefault);


    this.updateCard(this.CryptoService.Encrypt(newDefault), newDefault.key);


  }


  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////






  // get user cards
  userCards: any = [];

  UserCardsSubscribe: any;
  getUserCards() {
    let default_counter = 0;
    //  if(this.checkUseridNotEmpty()){
    let user = this.userService.getUserObj();
    // this.afDb.database.ref('/Users/' + ccard.user.key + '/Payments').child('cards').push(card).catch;
    //console.log(this.user.key)
    this.UserCardsSubscribe = this.afDb.object('/Users/' + user.key + '/Payments/cards/')
      .valueChanges().subscribe((res: any) => {
        console.log(res);

        //  get the keys
        // let keys = this.getkeys(res);
        // console.log('keys',keys)

        //
        res = this.json2array(res);

        this.userCards = [];

        res.forEach((element, index) => {
          let dat = JSON.parse(this.CryptoService.Decrypt(element));
          // dat['key'] = keys[index];
          //console.log('element',this.CryptoService.Decrypt(element));
          console.log('dat', index, dat);


          this.userCards.push(dat);


          // if(res.length == 1){
          //   dat.defaultCard = true;
          // //  this.changeDefault(key);
          // }

          if (dat.defaultCard) {
            this.cardSelected(index);
            default_counter++;
          } else {
            this.userCards[index]['active'] = false;
          }


        });// end of foreach

        if (default_counter == 0) {
          console.log('default_counter', default_counter);
          this.cardSelected(0);
          if (this.userCards[0]) {
            this.changeDefault(this.userCards[0].key);
            this.userCards[0].defaultCard = true;
          }
        }
        console.log('this.userCards', this.userCards);




        // I WANT THE SUBSCRIBE TO END WHEN MODAL IS DISMISS
        //UserCardsSubscribe.unsubscribe();
      });// end of subscribe

    // }else{// if empty retry after 5 sec
    //   setTimeout(() => {
    //     this.getUserCards()
    //   }, 3000);
    //}



  }




  //currentCardIndex = 0;
  selectedCard: any;
  cardSelected(index) {
    //  console.log('this.userCards',this.userCards[index])
    if (this.userCards[index]) {
      this.userCards[index]['active'] = true;
      this.selectedCard = this.userCards[index];
    }



  }

  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////


  get_total_revenue_from_key(val) {
    //console.log('val');
    var totalSum = 0;
    val.forEach(element => {

      totalSum += (element.transaction_price / 100);

    });


    return totalSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '₪';

  }


  // getUserHistory(){
  //   return this.afDb.object('/Users/' + this.user.key + '/Payments/history/')
  //   .valueChanges() 

  //   }



  barbers_obj: any = {};
  subscription_doc_array: any = []

  document_array = [];
  set_document_array(val) {
    var document_obj = {};
    var self = this;
    console.log('sdasadsd', this.subscription_doc_array);


    val = val.concat(this.subscription_doc_array);


    val.forEach(element => {
      // console.log(element);

      console.log('element.transaction_created_at', element.transaction_created_at);
      console.log('Date', new Date(element.transaction_created_at.replace(' ', 'T')));
      console.log('getMonth', new Date(element.transaction_created_at.replace(' ', 'T')).getMonth());


      if (this.barbers_obj[element.seller_id]) {
        element['Barber'] = this.barbers_obj[element.seller_id];
        element['Barber_logo'] = this.barbers_obj[element.seller_id].logo;
        element['Barber_name'] = this.barbers_obj[element.seller_id].name;

      } else {

        this.afDb.database.ref('/BarberShop/' + element.seller_id + '/Setting')
          .once('value').then(res => {
            // .on("value", function(res) {
            self.barbers_obj[element.seller_id] = res.val();
            element['Barber'] = this.barbers_obj[element.seller_id];
            element['Barber_logo'] = this.barbers_obj[element.seller_id].logo;
            element['Barber_name'] = this.barbers_obj[element.seller_id].name;



          });

      }


      // add document
      if (document_obj[(new Date(element.transaction_created_at.replace(' ', 'T')).getMonth() + 1) + '.' + new Date(element.transaction_created_at.replace(' ', 'T')).getFullYear()]) {
        document_obj[(new Date(element.transaction_created_at.replace(' ', 'T')).getMonth() + 1) + '.' + new Date(element.transaction_created_at.replace(' ', 'T')).getFullYear()].push(element);
      } else {
        document_obj[(new Date(element.transaction_created_at.replace(' ', 'T')).getMonth() + 1) + '.' + new Date(element.transaction_created_at.replace(' ', 'T')).getFullYear()] = [];
        document_obj[(new Date(element.transaction_created_at.replace(' ', 'T')).getMonth() + 1) + '.' + new Date(element.transaction_created_at.replace(' ', 'T')).getFullYear()].push(element);
      }

    });



    console.log('this.shopS.json2array(document_obj)', this.shopS.json2array(document_obj))
    let arr = this.shopS.json2array(document_obj);
    arr.reverse();
    this.document_array = arr;
    console.log('this.document_array', this.document_array)
  }


  // get user cards
  userHistory: any = [];

  UserHistorySubscribe: any;
  getUserHistorys() {

    //  if(this.checkUseridNotEmpty()){
    let user = this.userService.getUserObj();
    this.get_all_transactions({
      transaction_id: user.key
    }, res => {
      //   });


      //   this.UserHistorySubscribe = this.afDb.object('/Users/' + user.key + '/Payments/history/')
      //   .valueChanges().subscribe((res:any)=>{

      // //  let keys = this.getkeys(res);
      //   res = this.json2array(res);

      this.userHistory = [];
      this.set_document_array(res);

      res.forEach((element, index) => {
        let dat = element;
        // dat['key'] = keys[index];

        this.userHistory.push(dat);

      });// end of foreach


      console.log('this.userHistory', this.userHistory);

      // I WANT THE SUBSCRIBE TO END WHEN MODAL IS DISMISS
    });// end of subscribe


  }





  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////

  // addBalanceToBiz(bizid,amount){
  //   console.log('bizid',bizid);
  //   console.log('amount',amount);

  //   this.afDb.database.ref('/BarberShop/'+bizid+'/Payments/amount').once('value').then(res=>{
  //   let newAmount;
  //     console.log('res',res.val())
  //     if(res.val() == null){
  //       console.log('null!!!!');
  //       newAmount = 0 + amount;
  //     }else{
  //       newAmount = res.val() + amount;
  //     }

  //     this.afDb.database.ref('/BarberShop/'+bizid+'/Payments').update({
  //       amount:  newAmount
  //     });

  //  // this.afDb.list('/BarberShop/'+bizid+'/Payments/amount').valueChanges().subscribe({

  //   });
  // }

  // addPayment(){
  //   let data = {
  //     product: this.currentProduct,
  //     BarberId: this.currentProduct.BarberId,
  //     userid: this.user.key,
  //     username: this.user.fname + ' ' + this.user.lname,
  //     startDate: new Date().toUTCString(),
  //     active: true
  //   }


  //   console.log('data',data)

  //   // this.afDb.list('/Users/' + userid + '/payment/subscription/')
  //   // .push(data)

  //   this.afDb.list('/BarberShop/'+data.BarberId+'/Payments/history/').push(data);
  //   this.afDb.list('/Users/'+data.userid+'/Payments/history/').push(data);

  // }

  convertLocalDateToUTCIgnoringTimezone(date: Date) {
    const timestamp = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0, 0, 0, 0
    );

    return new Date(timestamp);
  }






  addMonths(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }


  // SubscribeScheduler(){


  //   let currentDateForSubscribeScheduler:any = new Date();
  //   currentDateForSubscribeScheduler.setHours(0, 0, 0);  
  //   // currentDateForReminder.setMinutes(0)
  //   // currentDateForReminder.setHours(0);
  //   console.log('currentDateForReminder',currentDateForSubscribeScheduler.toUTCString());

  //   let todayUTC = this.convertLocalDateToUTCIgnoringTimezone(new Date())
  //   console.log('todayUTC',todayUTC.toUTCString());

  //   let plusMonth = this.addMonths(todayUTC, 1).toUTCString();
  //   console.log('plusMonth',plusMonth);

  //   this.afDb.database.ref('/Subscription').orderByChild('nextPayment').equalTo(plusMonth).once('value').then(ress => {
  //     console.log(ress.val())

  //     let subscribeArray = this.json2array(ress.val());
  //     console.log('subscribeArray',subscribeArray)

  //     subscribeArray.forEach((element,index) => {
  //       console.log(element);

  //       if(true){

  //         this.afDb.database.ref('/Subscription/'+element.key).update({
  //           nextPayment: this.addMonths(todayUTC, 2).toUTCString()
  //         });
  //         console.log({date: todayUTC});
  //         this.afDb.database.ref('/Subscription/'+element.key+'/history/').push({success: true,date: todayUTC.toUTCString()})

  //       }else{

  //         this.afDb.database.ref('/Subscription/'+element.key).update({
  //           payment: 'failed'
  //         });

  //       }

  //     });




  //   });

  // var today = new Date();
  // var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
  // console.log('currentDateForReminder',myToday.toUTCString());
  //  var newww =  this.convertLocalDateToUTCIgnoringTimezone(new Date());
  //  console.log('newww',newww.toUTCString());
  // Date.UTC(myToday)
  // this.afDb.database.ref('/BarberShop/'+bizid+'/Payments/amount').once('value').then(res=>{
  //   let newAmount;
  //     console.log('res',res.val())
  //     if(res.val() == null){
  //       console.log('null!!!!');
  //       newAmount = 0 + amount;
  //     }else{
  //       newAmount = res.val() + amount;
  //     }

  //     this.afDb.database.ref('/BarberShop/'+bizid+'/Payments').update({
  //       amount:  newAmount
  //     });

  //  // this.afDb.list('/BarberShop/'+bizid+'/Payments/amount').valueChanges().subscribe({

  //   });

  //}



  // async local(){
  //   //this.SubscribeScheduler();

  //   let todayUTC = this.convertLocalDateToUTCIgnoringTimezone(new Date())
  //   console.log('todayUTC',todayUTC.toUTCString());

  //   await this._http.post(this.shopS.serverDev+'/SubscribeScheduler','').subscribe((respone:any) => {
  //     console.log('respone: ', respone);
  //     respone = respone['_body'];
  //     let decrypted = this.CryptoService.Decrypt(respone)
  //   //  console.log(JSON.parse(JSON.parse(decrypted)))
  //    let res = JSON.parse(decrypted);
  //     console.log('res-service',res);

  //   });





  // let ccard = {
  //   amount: 7900,
  //   cardExpiration: "1122",
  //   cardNo: "4580808080808000",
  //   cardId: "4580808080808000",
  //   cardType: "visa",
  //   cvv: "123",
  //   defaultCard: false,
  //   fullName: "sda asd",
  //   type: "token",
  //   tz: "123213123",
  //   user: this.user,
  //   currentProduct:this.currentProduct
  // }
  // console.log('ccard.currentProduct',ccard.currentProduct)
  //this.addBalanceToBiz(ccard.currentProduct.BarberId,ccard.amount)


  // let encrypted =  this.CryptoService.Encrypt(ccard);


  // await this._http.post(this.shopS.serverDev+'/Payments',encrypted).subscribe((respone:any) => {
  //   console.log('respone: ', respone);
  //   respone = respone['_body'];
  //   let decrypted = this.CryptoService.Decrypt(respone)
  // //  console.log(JSON.parse(JSON.parse(decrypted)))
  //  let res = JSON.parse(decrypted);
  //   console.log('res-service',res);

  // });

  //}
  paymentStatus = new BehaviorSubject(null);


  endFuncFromServer(cb, data) {
    this.LoadingService.dismiss();
    // console.log('ccard',ccard);
    // console.log('res',res);
    // if(cb.sale_status == "completed"){

    switch (cb.res.sale_status) {

      case 'subscriptionSuccess':
        this.shopS.turnOnConfetti(3000);
        this.shopS.log('', 'payment_subscriptionSuccess');
        this.ToastService.showToast('המנוי חויב ונוסף בהצלחה');
        this.paymentStatus.next('success');
        this.payModal.dismiss();
        break;

      case 'completed':
        this.shopS.turnOnConfetti(3000);
        this.ToastService.showToast('התשלום בוצע בהצלחה');
        this.paymentStatus.next('success');
        this.shopS.log('', 'payment_paySuccess');
        this.payModal.dismiss();
        break;

      case 'failed':
        this.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');
        this.paymentStatus.next('fail');
        this.shopS.log(cb, 'payment_payerror');

        break;

      default:
        if (cb.res.payme_status == "success") {
          this.shopS.turnOnConfetti(3000);
          this.shopS.log('', 'payment_subscriptionSuccess');
          this.ToastService.showToast('המנוי חויב ונוסף בהצלחה');
          this.paymentStatus.next('success');
          this.payModal.dismiss();


        } else {

          this.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');
          this.paymentStatus.next('fail');
          this.shopS.log(cb, 'payment_payerror');
        }

        break;
    }



  }




  async sendDataTo(ccard) {



    let user = this.userService.getUserObj();
    let gift = false;

    if (!this.currentProduct.BarberID) {
      this.currentProduct.BarberID = this.currentProduct.BarberId;
    }
    let data: any;
    let sale_type_on_api = 'generate-sale';

    if (this.currentProduct.payType == "subscription") {
      sale_type_on_api = 'generate-subscription';
      var today: any = new Date();

      if (this.currentProduct.key == '-MGXUUY-621pbguAGGHm' && ((this.shopS.shopData.Setting.gift_options && !this.shopS.shopData.Setting.gift_options.used) || !this.shopS.shopData.Setting.gift_options)) {
        gift = true;

        if (this.shopS.shopData.Setting.gift_options && this.shopS.shopData.Setting.gift_options.days) {
          today.setDate(today.getDate() + this.shopS.shopData.Setting.gift_options.days);
        } else {
          today.setDate(today.getDate() + this.shopS.configDATA.gift_options.days);
        }

        if (this.shopS.shopData.Setting.gift_options && this.shopS.shopData.Setting.gift_options.price) {
          this.currentProduct.price = this.shopS.shopData.Setting.gift_options.price;
        }
      }

      var dd: any = today.getDate();
      if (dd > 28) {
        // var d = new Date(today);
        // var d = new Date('2021-12-29');

        today.setMonth(today.getMonth() + 1, 1);
        console.log('today:', today)
        dd = today.getDate();
        // alert('larger then 28')
      }


      var mm: any = today.getMonth() + 1;

      var yyyy = today.getFullYear();






      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }


      today = dd + '/' + mm + '/' + yyyy;
      let sub_start_date = today;
      console.log(today);


      delete user.Devices
      delete user.Payments
      delete user.History
      delete user.meetings
      data = {
        sub_price: parseFloat(this.currentProduct.price) * 100,
        seller_id: this.currentProduct.BarberID,
        seller_payme_id: this.currentProduct.seller_payme_id,
        // product_name: this.currentProduct.name,
        sub_description: this.currentProduct.name,
        sub_currency: "ILS",
        sub_callback_url: env.cloudfunctions + "/paymentResposePayme",
        sale_send_notification: false,
        sale_email: user.email,
        sale_name: user.fname + user.lname,
        sale_phone: user.phone,
        sale_mobile: user.phone,
        sale_payment_method: "credit-card",
        language: "he",
        buyer_key: ccard.cardNo,
        notification: user.notification,
        sub_iteration_type: this.currentProduct.sub_iteration_type,
        sub_start_date: sub_start_date,
        subscription_id: user.key,
        product: this.currentProduct,
        product_id: this.currentProduct.key,
        currentBuyer: {
          BarberID: this.shopS.shopData.Setting.BarberID,
          user: user
        }
      }

    } else {
      sale_type_on_api = 'generate-sale';
      delete user.Payments
      delete user.Devices
      delete user.History
      delete user.meetings

      console.log(this.currentProduct);

      data = {
        sale_price: parseFloat(this.currentProduct.price) * 100,
        seller_id: this.currentProduct.BarberID,
        product_name: this.currentProduct.name,
        currency: "ILS",
        sub_callback_url: env.cloudfunctions + "/paymentResposePayme",
        // sale_return_url:"http://127.0.0.1:3000/api/collect",
        sale_send_notification: false,
        sale_email: user.email,
        sale_name: user.fname + user.lname,
        sale_phone: user.phone,
        installments: "1",
        sale_payment_method: "credit-card",
        language: "he",
        buyer_key: ccard.cardNo,
        notification: user.notification ? user.notification : null,
        transaction_id: user.key,
        product: this.currentProduct,
        product_id: this.currentProduct.key,
        currentBuyer: {
          BarberID: this.shopS.shopData.Setting.BarberID,
          user: user
        }


      }





    }
    console.log('data_sent_to_api', sale_type_on_api, data)

    // let encrypted =  this.CryptoService.Encrypt(data);

    this.LoadingService.presentforever();

    this.send_to_api(sale_type_on_api, data, cb => {
      console.log('cb', cb)

      if (cb.status) {

        this.endFuncFromServer(cb, data);
        if (gift && cb.res.payme_status == 'success') {
          this.shopS.setGiftOptions();
        }


      } else {
        this.paymentStatus.next('fail');
        this.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');
        this.LoadingService.dismiss();

      }

    })



    // await this._http.post(this.shopS.serverDev+'/payAPI',encrypted).subscribe((respone:any) => {
    //   console.log('respone: ', respone);
    //   respone = respone['_body'];
    //   let decrypted = this.CryptoService.Decrypt(respone)
    // //  console.log(JSON.parse(JSON.parse(decrypted)))
    //  let res = JSON.parse(decrypted);
    //   console.log('res-service',res);


    //   this.endFuncFromServer(ccard,res);




    // },(error:any) => {
    //   this.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');
    //   this.LoadingService.dismiss();

    // } );

  }




  // async sendDataTo_old_creditguard(ccard){



  //   let user = this.userService.getUserObj();


  //   ccard['currentProduct'] = this.currentProduct;
  //   ccard['user'] = {
  //     BarberID: user.BarberID,
  //     ProfilePic: user.ProfilePic,
  //     email: user.email,
  //     fname: user.fname,
  //     lname: user.lname,
  //     key: user.key,
  //     notification: user.notification,
  //     phone: user.phone,
  //     shopOwner: user.shopOwner,
  //   } ;

  // console.log('ccard',ccard)


  //   let encrypted =  this.CryptoService.Encrypt(ccard);

  //   this.LoadingService.presentforever();

  //   await this._http.post(this.shopS.serverDev+'/payAPI',encrypted).subscribe((respone:any) => {
  //     console.log('respone: ', respone);
  //     respone = respone['_body'];
  //     let decrypted = this.CryptoService.Decrypt(respone)
  //   //  console.log(JSON.parse(JSON.parse(decrypted)))
  //    let res = JSON.parse(decrypted);
  //     console.log('res-service',res);


  //     this.endFuncFromServer(ccard,res);




  //   },(error:any) => {
  //     this.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');
  //     this.LoadingService.dismiss();

  //   } );

  // }




  // // send data to server 
  // async sendData(ccard){
  // console.log(ccard);
  // let encrypted =  this.CryptoService.Encrypt(ccard);

  // this.LoadingService.presentforever();

  //   await this._http.post('https://webc.co.il/pointnot/payment/',encrypted).subscribe((respone:any) => {
  //     console.log('respone: ', respone);
  //     respone = respone['_body'];
  //     let decrypted = this.CryptoService.Decrypt(respone)
  //  //   console.log(JSON.parse(JSON.parse(decrypted)))
  //     let res = JSON.parse(JSON.parse(decrypted));
  //     console.log('res-service',res);

  //     this.handleResult(res,ccard)

  //   });

  //  // return returnVal;

  //   }



  //   handleResult(res,ccard){
  //     console.log(res,ccard);


  //     this.LoadingService.dismiss();


  // if(res.status != '0'){

  //   this.ToastService.showTostDanger(res.message);


  // }else{

  //   // is ok 

  //   this.shopS.turnOnConfetti(3000);  


  //   switch (ccard.type) {
  //     case 'verify':
  //       this.newCard(res,ccard);
  //       this.ToastService.showToast('הכרטיס אומת ונוסף בהצלחה');
  //       this.NewCardModal.dismiss();
  //     break;

  //     case 'token':
  //       this.newPayComing(res,ccard);
  //       this.ToastService.showToast('שולם בהצלחה');
  //       this.payModal.dismiss();
  //     break;

  //     default:
  //       break;
  //   }


  // }








  //     }


  miki2() {


    let self = this;
    this.afDb.database.ref('/Users/').orderByChild('phone').equalTo('0507551130')
      .once('value').then(res => {
        //  .on("value", function(res) {
        console.log(res.key)
        console.log(self.json2array(res.val()));

      });

  }





  iframeUrl = '';
  transaction_id: any = '';
  transaction_data: any = {}
  iframe() {
    let user = this.userService.getUserObj();
    let ccard = {
      userid: user.key,
      type: 'getIframe',
    }
    let encrypted = this.CryptoService.Encrypt(ccard);

    return this._http.post(this.shopS.serverDev + '/Payments_Get_iframe', encrypted)
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  send_to_api(type, data, cb?) {
    if (firebase.auth().currentUser) {

      let user = this.shopS.us.getUserObj();


      firebase.auth().currentUser.getIdToken().then(token => {
        this.userService.userToken = token;
        var reqHeader = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.userService.userToken
        });

        let encrypted = this.CryptoService.Encrypt({
          type: type,
          data: data,
          user_id: user.key
        });
        console.log({
          type: type,
          data: data,
          user_id: user.key
        });

        this.http.post(this.shopS.serverDev + '/payAPI', encrypted, { headers: reqHeader }).subscribe((response: any) => {
          console.log('response', response);
          if (response.status) {
            cb(response);
          } else {
            // alert('problem')
            this.LoadingService.dismiss();
            this.paymentStatus.next('fail');
            this.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');
            this.shopS.log(response, 'payment_payerror');
          }
        }, err => {
          this.LoadingService.dismiss();
          this.paymentStatus.next('fail');
          this.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');
          this.shopS.log(err, 'payment_payerror');

        })// end of http post
      });// end of token

    } else {
      cb({ status: 'no auth' })
    }

  }



  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////


  new_iframe(type, data, cb) {
    this.send_to_api(type, data, val => {
      cb(val);
    })
  }


  async sendToIframe() {

    this.new_card_iframe_modal();

  }


  deleteSubscription(sub) {
    console.log('sub', sub);
    this.LoadingService.presentforever();

    this.send_to_api('hide-subscription', sub, cb => {
      console.log('cb', cb)

      if (cb.status) {
        this.mySubscriptionArray = this.mySubscriptionArray.filter(el => el.sub_payme_id !== sub.sub_payme_id);

        this.ToastService.showToast('נמחק בהצלחה');
        this.LoadingService.dismiss();



      } else {
        this.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');
        this.LoadingService.dismiss();

      }

    })
  }



  set_new_transaction_iframe(user_key) {
    // let user = this.userService.getUserObj();

    console.log('transaction_id',this.transaction_id)
    // admin.database().ref('/BarberShop/'+data.BarberId+'/Payments/history/').child(BarberHistoyKey).set(data);
    this.afDb.database.ref('/Transaction/').child(this.transaction_id).set({
      transaction_id: this.transaction_id,
      user_id: user_key,
      open_time: new Date().toString(),
      last_update_time: new Date().toString(),
      status: 'open',
      data: this.transaction_data
    });

  }

  listen_new_transaction_subscribe;

  listen_new_transaction_iframe() {
    // console.log('transaction_id',this.transaction_id)
    // admin.database().ref('/BarberShop/'+data.BarberId+'/Payments/history/').child(BarberHistoyKey).set(data);
    this.listen_new_transaction_subscribe = this.afDb.object('/Transaction/' + this.transaction_id).valueChanges().subscribe(val => {
      let newChange: any = val;
      console.log('newChange-child_changed', newChange);

      switch (newChange.status) {
        case 'open':

          break;
        // case '004':

        // break;

        case 'success':
          // הצלחה
          this.shopS.turnOnConfetti(3000);
          this.ToastService.showToast('הכרטיס אומת ונוסף בהצלחה');
          this.shopS.log('', 'payment_newCard_success');

          this.card_iframe_modal.dismiss();
          this.getUserCards();

          break;


        default:
          // סירוב
          this.shopS.log('', 'payment_newCard_fail');

          // this.card_iframe_modal.dismiss();
          // this.ToastService.showTostDanger('  קיבלנו סירוב מחברת האשראי נסו שנית');
          // this.sendToIframe();
          break;
      }




    });


  }







  async miki() {


    this.sendToIframe();

    //this.afDb.database.ref('/Users/').orderByKey().equalTo('anchorKey').limitToFirst(6); + "\uf8ff"

    ////this.afDb.database.ref('BarberShop/056538ed-563b-46d1-b001-a90e69b50f4b/').child('Meetings').orderByChild('meetDateFormat').startAt('01-06-2020').endAt('31-06-2020').once('value').then(res=>{
    // this.afDb.database.ref('BarberShop/37559da5-97df-4daa-9384-f40343b59383/Meetings').orderByKey().startAt("2020-08").once('value').then(res=>{


    // console.log(res.key)
    // console.log(this.getkeys(res.val()))

    // console.log(self.json2array(res.val()));

    // });



    //   this.afDb.database.ref('/Users/').orderByChild('phone').equalTo('0507551130')
    //   .once('value').then(res=>{
    // //  .on("value", function(res) {
    //     console.log(res.key)
    //   console.log(self.json2array(res.val()));

    //   });

  }

  load_transactions = true;
  load_subscriptions = true;

  transList: any;

  mySubscriptionArray_history: any = [];


  async get_transactions(val) {
    var self = this;
    this.mySubscriptionArray = [];
    this.mySubscriptionArray_history = [];
    // val['data'] = '1'+ val['data']; 
    console.log('//////////////////get_translations: ', val)
    const transCollectio1n = this.Firestore.collection('Transaction', ref => ref.where(val.type, '==', val.data)).snapshotChanges().subscribe((val: any) => {
      val.map(item => {
        console.log('!!!!!!!transCollectio1n!!!!!!!!item', item);
        const data = item.payload.doc.data() as any;
        const id = item.payload.doc.id;



        console.log('!!!!!! item ' + id, data);
        console.log('!!!!!! item ', self.mySubscriptionArray);
        let indexFound = self.mySubscriptionArray.findIndex(o => o.sub_payme_id == id);
        console.log('indexFound:', indexFound)


        if (data.type == "subscription") {
          let ccard = self.userCards.find(o => o.cardId == data.cardKey);
          data['ccard'] = ccard;



          if (data.deleted) {
            if (indexFound == -1) {
              if (data['sub_next_date']) {
                data['sub_next_date'] = new Date(data['sub_next_date'].replace(' ', 'T'));
                if (data['sub_prev_date']) {
                  data['sub_prev_date'] = new Date(data['sub_prev_date'].replace(' ', 'T'));
                }
              }
              self.mySubscriptionArray_history.push(data);
            } else {
              self.mySubscriptionArray_history[indexFound] = data;
            }
          } else {
            if (indexFound == -1) {
              if (data['sub_next_date']) {
                console.log('$$$$$$ ----------------------- sub_next_date:', data['sub_next_date']);
                data['sub_next_date'] = new Date(data['sub_next_date'].replace(' ', 'T'));
                if (data['sub_prev_date']) {
                  data['sub_prev_date'] = new Date(data['sub_prev_date'].replace(' ', 'T'));
                }

                console.log('$$$$$$ ----------------------- sub_next_date:', data['sub_next_date']);
              }
              // data['sub_prev_date'] = new Date('2021.07.22').toISOString();;
              self.mySubscriptionArray.push(data);
            } else {
              self.mySubscriptionArray[indexFound] = data;
            }
          }
        }


      })
      // console.log('!!!!!!!transCollectio1n!!!!!!!!val',val);
    });




    const User_Transaction = this.Firestore.collection('Transaction', ref => ref.where(val.type, '==', val.data)).get()

    User_Transaction.subscribe(queriedItems => {
      console.log(queriedItems);

      if (queriedItems.docs) {
        self.subscription_doc_array = [];

        queriedItems.docs.forEach((doc) => {
          let doc_data = doc.data();
          let doc_id = doc.id;
          console.log(doc_data, ' => ', doc_id);
          if (doc_data.type == "subscription") {
            // let ccard = self.userCards.find(o => o.cardId == doc_data.cardKey);
            // doc_data['ccard']  = ccard;
            // if(doc_data.deleted){
            //   self.mySubscriptionArray_history.push(doc_data);
            // }else{
            //   self.mySubscriptionArray.push(doc_data);

            // }
            if (doc_data.history) {
              doc_data.history.forEach(item => {
                console.log('????????????? sub_transactionsub_transac ', item);

                if (item.notify_type == 'sub-iteration-success') {
                  console.log('sub-iteration-success', item);
                  let sub_transaction = {
                    seller_id: item.seller_id,
                    sale_description: item.sub_description,
                    transaction_created_at: item.sub_prev_date,
                    data: item,
                    transaction_price: parseInt(item.sub_price)
                  }

                  console.log('!!!!!! sub_transactionsub_transactionsub_transactionsub_transaction ', sub_transaction);

                  self.subscription_doc_array.push(sub_transaction)
                }

              });
            }// end of if history



          }
        })
      } else {
        self.load_transactions = false;
        self.load_subscriptions = false;
        console.log('no transactions')
      }
      // })
      // }))
    });




    // this.afDb.database.ref('/Subscription/').orderByChild(val.type).equalTo(val.data)
    // .on("value", function(res) {

    //   console.log('////////////////////////////////////////////////!!!!!!!!!!!!!',res.val())

    // });

  }







  // async get_transactions(val){
  //   var self = this;
  //   console.log('//////////////////get_translations: ',val)

  //   const User_Transaction = await this.Firestore.collection('Transaction', ref => ref.where(val.type, '==', val.data)).get();
  //   // .doc(this.shopS.shopData.Setting.BarberID);
  //   console.log('User_Transaction: ',User_Transaction)
  //   User_Transaction.subscribe(queriedItems => {
  //     console.log(queriedItems);   

  //     if(queriedItems.docs){

  //       queriedItems.docs.forEach((doc) => {
  //         let doc_data =  doc.data();
  //         let doc_id = doc.id;
  //         console.log(doc_data, ' => ', doc_id);
  //         if(doc_data.type == "subscription"){
  //           self.mySubscriptionArray.push(doc_data);
  //         }
  //       }) 

  //     }else{
  //       self.load_transactions = false;
  //       self.load_subscriptions = false;
  //       console.log('no transactions')
  //     }

  //   });



  //   this.afDb.database.ref('/Subscription/').orderByChild(val.type).equalTo(val.data)
  //   .on("value", function(res) {

  //     console.log('////////////////////////////////////////////////!!!!!!!!!!!!!',res.val())

  //   });

  // }

  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////

  check_grace(val) {
    var self = this;
    const User_Transaction = this.Firestore.collection('Transaction').doc(this.shopS.shopData.Setting.Premium.subid).get().subscribe(doc => {
      let data: any;
      if (doc.exists) {
        console.log('Document data:', doc.data());
        let doc_data = doc.data();
        console.log('--------------', doc_data);
        let pay_day: any = new Date(doc_data.point_preminum_next_date);
        let today: any = new Date();

        console.log('pppppppppppppppppp', pay_day);

        self.grace_untill = self.addDays(pay_day, self.grace_to_give)

        console.log('self.grace_untill', self.grace_untill)
        console.log('today', today)
        self.grace_days = Math.ceil((pay_day - today) / 8.64e7); // 31
        console.log('self.grace_days', self.grace_days)



      }
    });


    // , ref => ref.where(val.new_type, '==', val.data)).get()
    // User_Transaction.subscribe(queriedItems => {
    //   console.log('------s--------',queriedItems);   

    //   if(queriedItems.docs){

    //     queriedItems.docs.forEach((doc) => {
    //       let doc_data =  doc.data();
    //       console.log('--------------',doc_data);
    //     });

    //   }

    // });


  }

  getSubscription(val) {
    console.log('getSubscription', val);
    let self = this;



    const User_Transaction = this.Firestore.collection('Transaction', ref => ref.where(val.new_type, '==', val.data)).get()

    User_Transaction.subscribe(queriedItems => {
      console.log('------s--------', queriedItems);

      if (queriedItems.docs) {

        queriedItems.docs.forEach((doc) => {
          let doc_data = doc.data();
          console.log('--------------', doc_data);
        });




      }



    });





    this.afDb.database.ref('/Subscription/').orderByChild(val.type).equalTo(val.data)

      //.once('value').then(res=>{
      .on("value", function (res) {
        // this.mySubscriptionArray =   this.json2array(res.val()) ;
        //console.log('res.val()',res.val())

        self.mySubscriptionArray = [];
        //  get the keys
        // let keys = self.getkeys(res.val());
        //console.log('keys',keys)
        //document.getElementById('tranzila_sim_legend').innerHTML='http://p38029-63-16015.s63.upress.link/?wc-api=WC_Gateway_Tranzila'; document.getElementById('tranzila_simulation').setAttribute('action','http://p38029-63-16015.s63.upress.link/?wc-api=WC_Gateway_Tranzila') ;
        console.log('res.val()', res.val())

        let data = self.json2array(res.val());
        console.log('data', data)

        data.forEach((element, index) => {
          let dat = element;
          //dat['key'] = keys[index];
          //console.log('element',this.CryptoService.Decrypt(element));
          // console.log('dat',index,dat);

          console.log('dat', element);
          console.log('dat', self.shopS.shopData['Setting']['Premium']['status']);
          console.log('dat', self.shopS.shopData['Setting']['Premium']['status']);
          console.log('dat', element.product.key);
          console.log('dat', self.premium_product_key);



          if ((element.payment == 'trail' || self.shopS.shopData['Setting']['Premium']['status'] == 'fail') && element.product.key == self.premium_product_key) {
            // alert('this is trail');
            let pay_day: any = new Date(element.nextPayment);
            let today: any = new Date();

            console.log('pppppppppppppppppp', pay_day);

            self.grace_untill = self.addDays(pay_day, self.grace_to_give)

            console.log('self.grace_untill', self.grace_untill)
            console.log('today', today)
            self.grace_days = Math.ceil((pay_day - today) / 8.64e7); // 31
            console.log('self.grace_days', self.grace_days)

          }

          if (element.ccard == 'nocard') {
            dat['ccard'] = 'nocard'

          } else {
            dat['ccard'] = JSON.parse(self.CryptoService.Decrypt(element.ccard));;

          }


          //if(dat.active){
          if (!dat.deleted) {

            self.mySubscriptionArray.push(dat);
          }
          // }

        });// end of foreach


        console.log('self.mySubscriptionArray', self.mySubscriptionArray)

      });
  }



  addDays(date, days) {
    return new Date(date.getTime() + days * 1000 * 60 * 60 * 24);
  }


  trannsaction_array: any = [];

  get_all_transactions(data, cbb) {
    this.send_to_api('get-transactions', data, cb => {
      console.log('get-transactions cb:', cb)
      // this.trannsaction_array = cb.items;
      // this.ToastService.showToast('המנוי בוטל בהצלחה');
      // this.shopS.log('','subscription_canceled');
      if (cb.res.items) {
        cbb(cb.res.items)

      }
    })
  }


  disableSubscription(sub) {
    // this.afDb.object('/Subscription/' + key + '/').update({
    //   active:false,
    //   ccard: 'nocard'
    // });
    console.log('disableSubscription', sub)
    this.send_to_api('cancel-subscription', sub, cb => {
      console.log('cbcbcbcb', cb)
      if (cb.status) {
        this.ToastService.showToast('המנוי בוטל בהצלחה');
        this.shopS.log('', 'subscription_canceled');
        let index = this.mySubscriptionArray.findIndex(sub => sub.sub_payme_id == cb.res.sub_payme_id);
        this.mySubscriptionArray[index].sub_status = "5"
      } else {
        this.shopS.log(this.user.username, 'subscription_canceled_err', cb);

      }

    })
  }


  enableSubscription(key) {
    this.afDb.object('/Subscription/' + key + '/').update({
      active: true
    });

  }








  //     addSubscription(){
  // //   console.log('addSubscription',ccard);
  // //      console.log(this.currentProduct)
  //       let data = {
  //         product: this.currentProduct,
  //         BarberId: this.currentProduct.BarberId,
  //         userid: this.user.key,
  //         username: this.user.fname + ' ' + this.user.lname,
  //         startDate: new Date().toUTCString(),
  //         nextPayment: this.addMonths(new Date(), 1).toUTCString(),
  //         active: true
  //       }


  //       console.log('data',data)
  //       console.log('this.user',this.user)

  //       // this.afDb.list('/Users/' + userid + '/payment/subscription/')
  //       // .push(data)

  //      this.afDb.list('/Subscription/').push(data)

  //     }


  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////





  // newPayComing(res,ccard){
  // console.log(res,ccard);


  // if(ccard.payType == 'subscription'){

  //   this.addPayment()
  //   this.addSubscription()

  // }

  // if(ccard.payType == 'onetime'){

  //   this.addPayment()

  // }


  // }

  // newCard(res,ccard){
  //   let card = {
  //     cardId: res.cardId,
  //     cardExpiration: ccard.cardExpiration,
  //     cardType: ccard.cardType,
  //     fullName: ccard.fullName,
  //     tz: ccard.tz,
  //     cardDisplay: this.createDotsDisplay(res.cardId),
  //     defaultCard: ccard.defaultCard,
  //     userid: this.user.key
  //   }
  //   console.log('card-before',card);

  //   card = this.CryptoService.Encrypt(card);

  //   console.log('card-after',card);
  //   console.log('this.user.key',this.user.key);
  //   this.addNewCard(card);
  // }


  monthsList = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];
  get_year_from_key(key) {
    // console.log(key)
    let s = key.split('-');
    return s[0];
  }

  get_month_from_key(key) {
    let s = key.split('-');
    return this.monthsList[parseInt(s[1]) - 1];
  }

  allevents = []


  createMonth(dataMeets) {
    var dataMeet = "";
    var arrayOfMeet = [];
    var objec = {
      "dates": "",
      "meets": []
    }

    console.log("------->dataMeets", dataMeets);
    var r = 1;
    var relemntscount = dataMeets.length;

    dataMeets.forEach(element2 => {

      var i = 1;
      var elmLenth = Object.keys(element2).length - 1;
      Object.keys(element2).forEach(key => {



        if (typeof element2[key] != "string") {


          var xs = element2[key];





          console.log("elemnts length ", i, elmLenth)


          var nameDates = this.get_month_from_key(element2[key].meetDate) + " ," + this.get_year_from_key(element2[key].meetDate)


          if (objec.dates == nameDates) {

            objec.meets.push(xs);


            console.log("samessss");
            if (i == elmLenth && r == relemntscount) {
              this.allevents.push(objec);
            }

          } else {
            if (objec.dates) {
              this.allevents.push(objec);
            }

            objec = {
              "dates": nameDates,
              "meets": []
            }


            objec.meets.push(xs);
          }

        }

        i++;
      });


      // var nameOF=this.get_month_from_key(element.meetDate)+" ,"+ this.get_year_from_key(element.meetDate);
      // this.historyMeet[nameOF].push(element);
      r++;
    });

    console.log("historyMeet", this.allevents);

    return objec;

  }



  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////


  pay_now_service(service) {
    let val = service;
    val['BarberID'] = this.shopS.shopData.Setting.BarberID;
    this.payNowModal(val)
  }




  pay_now_product(BarberId, productid) {
    console.log('this.mySubscriptionArray', this.mySubscriptionArray);




    this.afDb.object('/Products/' + BarberId + '/' + productid).valueChanges().subscribe((val: any) => {
      console.log('val', val);
      if (productid == this.premium_product_key) {
        val['premium'] = this.shopS.shopData.Setting.BarberID;
      }


      val['key'] = productid;

      let exist_in_user = this.mySubscriptionArray.find(o => o.product.key === val.key);
      if (exist_in_user) {
        //   alert('exist!!');
        val['renew_subscription'] = exist_in_user.key;

      } else {
        val['renew_subscription'] = false;
      }



      this.payNowModal(val)
    });

  }





  //////// open pay now modal
  payModal: any;
  async payNowModal(product, checkMeetAvailable?): Promise<HTMLIonModalElement> {
    this.currentProduct = product;
    console.log('product', product);
    this.shopS.log('', 'payment_payNowModal');

    this.payModal = await this.modalController.create({
      component: PaynowComponent,
      swipeToClose: false,
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalForPayments modalGreyBG',
      componentProps: { checkMeetAvailable },
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
      // Get the top-most ion-modal
    });
    await this.payModal.present();
    const { data } = await this.payModal.onWillDismiss();
    if (data) {
      if (data.dismissed == "finish") {
      }
    }
    return this.payModal;

  }

  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////




  currentCardEdit;

  CardEditdModal: any;
  async CardEditModal(card): Promise<HTMLIonModalElement> {
    console.log('card', card);
    this.currentCardEdit = card;
    this.CardEditdModal = await this.modalController.create({
      component: CardOptionsComponent,
      swipeToClose: true,
      cssClass: 'smallmodaledit',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
      // Get the top-most ion-modal
    });
    await this.CardEditdModal.present();
    const { data } = await this.CardEditdModal.onWillDismiss();
    if (data) {
      if (data.dismissed == "finish") {
      }
    }
    return this.CardEditdModal;
  }




  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////







  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////




  currentSubscriptionEdit;

  CardSubscriptionModal: any;
  async changeCardSubscriptionModal(subscription): Promise<HTMLIonModalElement> {
    console.log('subscription', subscription);
    this.currentSubscriptionEdit = subscription;
    this.CardSubscriptionModal = await this.modalController.create({
      component: ChangeCardSubscriptionComponent,
      swipeToClose: true,
      cssClass: 'modalForPaymentsChangeSubscriptionCard',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
      // Get the top-most ion-modal
    });
    await this.CardSubscriptionModal.present();
    const { data } = await this.CardSubscriptionModal.onWillDismiss();
    if (data) {
      if (data.dismissed == "finish") {
      }
    }
    return this.CardSubscriptionModal;
  }




  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////





  card_iframe_modal: any;
  async new_card_iframe_modal(): Promise<HTMLIonModalElement> {
    this.shopS.log('', 'payment_new_card_iframe_modal');

    console.log('asdasdas')
    this.card_iframe_modal = await this.modalController.create({
      component: AddCardIframeComponent,
      swipeToClose: true,
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalForPayments',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
      // Get the top-most ion-modal
    });
    await this.card_iframe_modal.present();
    const { data } = await this.card_iframe_modal.onWillDismiss();
    console.log('data', data)
    this.listen_new_transaction_subscribe.unsubscribe();
    if (data) {
      if (data.dismissed == "finish") {
      }
    }
    return this.card_iframe_modal;
  }





  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////





  NewCardModal: any;
  // async addNewCardModal_new(): Promise<HTMLIonModalElement> {
  //   console.log('asdasdas')
  //      this.NewCardModal = await this.modalController.create({
  //        component: AddCreditCardComponent,
  //        swipeToClose: true,
  //        cssClass: 'modalForPayments',
  //      //  presentingElement: this.routerOutlet.nativeEl,
  //        mode:"ios",
  //       // Get the top-most ion-modal
  //      });
  //       await this.NewCardModal.present();
  //      const { data } = await this.NewCardModal.onWillDismiss();
  //      if(data){
  //      if(data.dismissed == "finish"){
  //      }
  //  }
  //      return this.NewCardModal;
  //    }


  addNewCardModal() {
    this.sendToIframe()
  }



  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////
  /////////////////////////////////////////////////
  ////////////////////////////////////////////////


  async addNewCardModal_new(): Promise<HTMLIonModalElement> {
    console.log('asdasdas')
    this.NewCardModal = await this.modalController.create({
      component: AddCreditCardPaymeComponent,
      swipeToClose: true,
      cssClass: 'modalForPayments',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",
      // Get the top-most ion-modal
    });
    await this.NewCardModal.present();
    const { data } = await this.NewCardModal.onWillDismiss();
    if (data) {
      if (data.dismissed == "finish") {
      }
    }
    return this.NewCardModal;
  }










  // handeling functions



  // checkUseridNotEmpty(){
  //   if(this.userService.key == ''){   
  //     return false
  //   }else{
  //     return true
  //   }
  // }




  // createDotsDisplay(data){
  //   let four =   data.slice(-4);
  //   return '●●●●●'+four;
  //   }


  json2array(json) {
    var result = [];
    if (json) {
      var keys = Object.keys(json);
      //  console.log('keys',keys);
      keys.forEach(function (key) {

        if (key != 'key') {
          //  json[key].key = key;
          result.push(json[key]);
        }
      });
    }
    return result;
  }


  getkeys(json) {
    var result = [];
    if (json) {
      var keys = Object.keys(json);
      //  console.log('keys',keys);
      keys.forEach(function (key) {

        if (key != 'key') {
          //  json[key].key = key;
          result.push(key);
        }
      });
    }
    return result;
  }


}
