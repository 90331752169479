import { UserSearchModalComponent } from './../../app/user-search-modal/user-search-modal.component';
import { ToastService } from './../../providers/toast-service';
import { userService } from './../../ShopService/user.service';
import { meetService } from './../../ShopService/meetS.service';
import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';
import { Router } from '@angular/router';
import { NavController, ModalController } from '@ionic/angular';
import { NotificationService } from '../../app/notification.service';
import { LoadingService } from 'src/providers/loading.service';
import { AlertExample } from 'src/providers/alert.service';


@Component({
  selector: 'app-add-line-admin',
  templateUrl: './add-line-admin.page.html',
  styleUrls: ['./add-line-admin.page.scss'],
})
export class AddLineAdminPage implements OnInit {

  constructor(
    public shopS: ShopService,
    private router: Router,
    private navCtrl: NavController,
    private meetService: meetService,
    private modalController: ModalController,
    private LoadingService: LoadingService,
    private alertExample: AlertExample
  ) {
    this.selectedDate = new Date(this.shopS.currentMeet.date);
  }
  appDate;

  lang = 'he';
  dateForView = [];
  days = {
    "en": ["ראשון", "שניה", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
    "he": ["א׳", "ב׳", "ג׳", "ד׳", "ה׳", "ו׳", "ש׳"]
  }
  monthNames = {
    'en': ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    'he': ["ינואר", "פבואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"],
  };

  selectedDate: any;
  viewTitle;
  today;
  dateSelected;


  meetType;
  BarberID;
  newMeetsComplete = [];
  limitCal = 90;
  cheakData;
  completMeet;
  charDate;
  cheak;
  allMeets = [];
  day;
  a;
  fir;
  sec;
  daysName = ["יום ראשון", "יום שני", "יום שלישי", "יום רבעי", "יום חמישי", "יום שישי", "יום שבת"];
  // calander parms

  eventSource;

  isToday: boolean;
  calendar = {
    autoSelect: false,
    noEventsLabel: `בחר תור`,
    showEventDetail: false,
    mode: 'month',
    currentDate: new Date()
  }; // these are the variable used by the calendar.
  minDate = new Date().toISOString();
  array;
  // shopS.correctAppoin = "";

  ionViewWillEnter() {
    console.log("--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------")
    // this.shopS.auserDifine();
    // this.loadHours("");
    // this.daysFromRow(this.shopS.dateForView[0]);

  }

  ngOnInit() {
    this.shopS.meetForBuis.importUserId("localUserAdmin")
    console.log('this.shopS.dateForView', this.shopS.dateForView)
    this.dateForView = JSON.parse(JSON.stringify(this.shopS.dateForView))

    var newArray = this.dateForView.filter(function (el) {
      return el.active == true
    });

    // console.log('jsahkdjsahdkjsahdkjsahkjdhaskjd',newArray)
    let f = this.monthNames[this.lang];
    let data = { cal: new Date(newArray[0].cal) };
    this.viewTitle = f[data.cal.getMonth()] + ' ' + data.cal.getDate() + ', ' + data.cal.getFullYear();
    this.shopS.current_pick_day = this.formatDate(data.cal);
    this.shopS.getFreeHours(data.cal);
    this.shopS.timeZoneDiff = this.shopS.checkTimeZoneDiff();

  }

  newEvent() {
    //console.log('this.shopS.currentMeet',this.shopS.currentMeet);
  }


  changeMeetType(event, data) {
    //console.log('changeMeetType-data:',data);
    this.shopS.addLineFLAG = false;
    this.navCtrl.navigateForward(['tabsAdmin/about']);
  }

  async openUsersModal() {

    var modal = await this.modalController.create({
      component: UserSearchModalComponent,
      swipeToClose: false,
      cssClass: 'modalLookLikeNativMoredown',
      mode: "ios",
      backdropDismiss: true

      //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal 
      // Get the top-most ion-modal

    });



    await modal.present();
    modal.onDidDismiss().then(res => {
      console.log(res);
      if (res.data) {

        this.shopS.currentMeet.user = res.data;
        this.shopS.meetForBuis.importUserId(res.data.key)
      }
    })
    return modal;
  }

  changeUser(event, data) {
    this.shopS.openModal(UserSearchModalComponent, null, false, null, modal => {
      modal.onDidDismiss().then(res => {
        if (res.data) {
          this.shopS.currentMeet.user = res.data;
          this.shopS.meetForBuis.importUserId(res.data.key)
        }
      })
    });
    return;

    //console.log('changeUser-data:',data);
    // TODO: open search focus
    //this.shopS.searchFocusFlag = true;
    this.shopS.addLineFLAG = false;
    this.router.navigate(['tabsAdmin/clients']);
  }

  pushMeetType(x) {
    // console.log('this.shopS.currentMeet',this.shopS.currentMeet);
    // console.log("pushMeetType",x.detail.value,this.shopS.shopData.MeetingType[x.detail.value],this.shopS.shopData.MeetingType);
    this.shopS.shopData.MeetingType[x.detail.value].key = x.detail.value;
    this.shopS.currentMeet.meetType = this.shopS.shopData.MeetingType[x.detail.value];
    this.meetService.meetType = this.shopS.shopData.MeetingType[x.detail.value];
    console.log(this.shopS.currentMeet)
    // this.shopS.changeViewHouers(this.shopS.currentMeet.date.cal);
    this.shopS.getFreeHours(this.shopS.currentMeet.date.cal);
    this.shopS.meetForBuis.importMeetType(x.detail.value);
  }

  daysFromRow(data) {
    this.dateForView.forEach(x => {
      x.active = x == data ? true : false;
    });
    // data.active = true;
    console.log('a', data);
    data.cal = new Date(data.cal)
    // let date = data ? data : this.shopS.dateForView[0];
    //The selected day is
    this.shopS.currentMeet.date = data;
    this.shopS.correctAppoin = "";
    console.log('daysFromRow-data:', data);
    console.log('daysFromRow-data.cal:', data.cal);
    let f = this.monthNames[this.lang];
    // this.selectedDate = data.cal;
    // this.shopS.dateSelected = data.cal;
    this.viewTitle = f[data.cal.getMonth()] + ' ' + data.cal.getDate() + ', ' + data.cal.getFullYear();

    // this.shopS.currentMeet.date = data.cal;


    this.shopS.current_pick_day = this.formatDate(data.cal);

    // this.shopS.calendar.currentDate = data.cal;
    this.appDate = this.formatDate(this.shopS.calendar.currentDate);

    //console.log("this.shopS.currentMeet",this.shopS.currentMeet,data);
    // console.log("data.cal",data.cal)
    // this.shopS.changeViewHouers(data.cal);
    this.shopS.getFreeHours(data.cal);
    //before change 
    // this.loadHours(data)
  }


  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
  }
  daysForViewFunc() {
    this.shopS.dateForView = [];
    //console.log("dateForView: ", i,this.shopS.dateForView)
    var today = new Date()
    var dayToday = today.getDay();
    var limitMeet = parseInt(this.shopS.shopData.Setting.limitMeetAdmin);
    if (!limitMeet) {
      limitMeet = 31;
    }

    //console.log(" limitMeet" ,limitMeet)
    for (var i = 0; i < limitMeet; i++) {


      this.days.he[dayToday];
      var calDate = today.toString();

      if (i == 0) {
        this.shopS.dateForView.push(
          {
            "name": this.days.he[dayToday],
            "date": today.getDate(),
            'cal': new Date(calDate),
            'active': true
          });
      } else {
        this.shopS.dateForView.push(
          {
            "name": this.days.he[dayToday],
            "date": today.getDate(),
            'cal': new Date(calDate),
            'active': false
          });
      }


      if (dayToday != 6) {
        dayToday++;
      } else {
        dayToday = 0;
      }

      today.setDate(today.getDate() + 1);
    }


  }



  public trackItem(index: number, item) {
    return item.trackId;
  }

  loadHours(event) {


    this.shopS.correctAppoin = "";
    this.shopS.dateSelected = new Date(this.shopS.currentMeet.date);
    this.selectedDate = new Date(this.shopS.currentMeet.date);


    // var calendarformat;

    if (this.meetService.meetToChange) {
      var newDate = new Date(this.meetService.meetToChange.Date);
      this.calendar.currentDate = newDate;
      this.appDate = this.formatDate(newDate);
    } else {


      this.appDate = this.formatDate(this.shopS.calendar.currentDate);
      //console.log("this.shopS.calendar.currentDate",this.shopS.currentMeet,this.shopS.calendar.currentDate, this.appDate);


    }



    var today = new Date()
    var dayToday = today.getDay();
    // if (event) {
    //   var nDate = event.cal;
    //   nDate.setHours(0, 0, 0, 0);
    //   this.isToday = today.getTime() === nDate.getTime();
    //   this.charDate = (nDate.getDate() + "-" + (nDate.getMonth() + 1) + "-" + nDate.getFullYear());
    //   this.day = nDate.getDay();
    //   dayToday = nDate.getDay();
    // } else {
    // this.today = new Date();
    this.shopS.dateSelected = new Date();
    // }


    let f = this.monthNames[this.lang];
    // var BarberID = localStorage.getItem("BarberID");

    //console.log(f,today) 
    this.viewTitle = f[today.getMonth()] + ', ' + today.getFullYear();
    this.selectedDate = today;
    //console.log('this.currentMeet:',this.shopS.currentMeet);
    var self = this;

    if (this.shopS.currentMeet.date.name == undefined) {
      this.shopS.currentMeet.date =
      {
        "name": dayToday,
        "date": today.getDate(),
        'cal': new Date(this.shopS.calendar.currentDate)
      };
    }




    setTimeout(x => {
      //console.log("this.shopS.dateForView :" ,this.shopS.dateForView)
      if (this.shopS.dateForView.length == 0) {
        self.daysForViewFunc();
      }



      self.day = dayToday;
      if (self.day != 7) {
        // מחזיר את השעות עבודה של העסק
        var data = this.shopS.shopData.WorkDays;


        self.meetService.dayWork = data[this.day].dayName;
        self.meetService.startWork = data[this.day].timeStart;
        self.meetService.endWork = data[this.day].timeEnd;
        //console.log("2");


        self.meetService.shopId = self.shopS.shopData.BarberId;
        //יוצר מערך שלש עות עבודה לי הלוז
        self.meetService.arrayOfHouers();


        // מחזיר את כל התורים שיש באותו יום
        self.sec = self.meetService.getDateTafus(self.appDate).valueChanges()
          .subscribe(data2 => {


            if (data2 != null) {
              // מחסיר את התורים מהתורים הריקים
              self.meetService.meetType = this.shopS.currentMeet.meetType.meetTime;
              self.meetService.getEmptyMeets(data2);
              // this.completMeet=this.meetService.nWday;
            } else {
              // this.completMeet = this.meetService.getCompleteMeets();

            }

            self.completMeet = self.meetService.getCompleteMeets();
            self.splitTheHouers(data2);
            self.meetService.cleanService();

          })



      }
    }, 1000)
  }

  closeModal() {
    this.shopS.addLineFLAG = false;
  }
  // changeViewHouers(event) {
  //   var nDate=event.cal;
  //   //console.log("onCurrentDateChanged ")
  //   var today = new Date();
  //   today.setHours(0, 0, 0, 0);
  //   nDate.setHours(0, 0, 0, 0);
  //   this.isToday = today.getTime() === nDate.getTime();
  //   this.charDate = (nDate.getDate() + "-" + (nDate.getMonth() + 1) + "-" + nDate.getFullYear());
  //   this.day = nDate.getDay();
  //   //this.meetService.cleanService();
  //   // var SendDate = new Date(nDate.getFullYear(), nDate.getMonth(), nDate.getDate())

  //   if (this.day != 7) {
  //   // מחזיר את השעות עבודה של העסק

  //     var data = this.shopS.shopData.WorkDays;

  //         this.meetService.dayWork = data[this.day].dayName;
  //         this.meetService.startWork = data[this.day].timeStart;
  //         this.meetService.endWork = data[this.day].timeEnd;



  //   this.meetService.shopId=localStorage.getItem("BarberID");
  //         //יוצר מערך שלש עות עבודה לי הלוז
  //         this.meetService.arrayOfHouers();
  //         //   //this.meetService.cleanService();
  //         //   var SendDate = new Date(event.getFullYear(), event.getMonth(), event.getDate())
  //         //   this.day = event.getDay();
  //         //   if (this.day != 7) {
  //         //       // מחזיר את השעות עבודה של העסק
  //         //       this.meetService.getShopScez().valueChanges()
  //         //           .subscribe((data: any) => {
  //         //               //console.log("getShopScez : ", data);
  //         //               //console.log("1")
  //         //               this.meetService.dayWork = data[this.day].dayName;
  //         //               this.meetService.startWork = data[this.day].timeStart;
  //         //               this.meetService.endWork = data[this.day].timeEnd;
  //         //               //console.log("2")

  //         // מחזיר את כל התורים שיש באותו יום
  //         this.sec = this.meetService.getDateTafus(this.appDate).valueChanges()
  //           .subscribe(data2 => {
  //             //console.log("getDateTafus : ", data2, "Date : ", this.appDate)
  //             //console.log("haim haim haim haim")

  //             if (data2 != null) {
  //               // מחסיר את התורים מהתורים הריקים
  //               this.meetService.meetType=this.shopS.currentMeet.meetType.meetTime;
  //               this.meetService.getEmptyMeets(data2);
  //               // this.completMeet=this.meetService.nWday;
  //             } else {
  //               // this.completMeet = this.meetService.getCompleteMeets();

  //             }

  //             this.completMeet = this.meetService.getCompleteMeets();
  //             this.splitTheHouers(data2);
  //             this.meetService.cleanService();



  //             //                   })



  //             //           })



  //             //       // this.meetService.arrayOfHouers();
  //             //       // //console.log(this.meetService.workDayTime);
  //             //       //  //console.log(this.meetService.dayWork +" "+ this.meetService.startWork +" "+this.meetService.endWork)

  //             //       //  if(this.meetService.getShopScez(SendDate)!=undefined)
  //             //       //    this.meetService.arrayOfHouers();

  //           })



  //   }
  // }

  splitTheHouers(tfusim) {
    console.log("tfusim tfusim tfusim", tfusim);

    this.newMeetsComplete = [];
    //console.log("completMeet", this.completMeet, tfusim)
    this.completMeet.forEach(element => {
      element.min.forEach(a => {
        var h = this.addzero(element.houer);
        var m = this.addzero(a);
        this.newMeetsComplete.push(h + ":" + m)
      });

    });
    // מוחק את התורים התפוסים

    if (tfusim) {
      var reversTime = this.meetService.meetType / 5;
      //console.log("tfusim", tfusim, this.newMeetsComplete);



      for (var t = 0; t < tfusim.length; t++) {
        for (var i = 0; i < this.newMeetsComplete.length; i++) {
          if (tfusim[t].startTimeView == this.newMeetsComplete[i]) {

            if ((this.meetService.meetToChange) && (this.meetService.meetToChange.startTimeView == tfusim[t].startTimeView)) {



            } else {


              //console.log(tfusim[t].startTimeView, this.newMeetsComplete[i], "match")
              var forword = tfusim[t].meetTime / 5;
              //forword
              //console.log(forword, "forword");
              for (var w = 0; w < forword; w++) {
                this.newMeetsComplete[i + w] = "x";
              }
              //console.log(reversTime, "backword");
              //backword
              for (var n = 0; n < reversTime; n++) {
                this.newMeetsComplete[i - n] = "x";
              }


            }
          }
        }
      }

      for (var f = this.newMeetsComplete.length - 1; f >= 0; f--) {
        if (this.newMeetsComplete[f] === "x") {
          this.newMeetsComplete.splice(f, 1);
        }
      }


      this.allMeets = this.newMeetsComplete;


    }


  }
  // splitTheHouers(tfusim) {
  //   this.newMeetsComplete = []
  //   //console.log("completMeet", this.completMeet, tfusim)
  //   this.completMeet.forEach(element => {
  //     element.min.forEach(a => {
  //       var h = this.addzero(element.houer);
  //       var m = this.addzero(a);
  //       this.newMeetsComplete.push(h + ":" + m)
  //     });

  //   });
  //   // מוחק את התורים התפוסים

  //   if (tfusim) {
  //     tfusim.forEach(used => {

  //       var removeMeet = used.startTimeView;
  //       var meetSkip = used.meetTime / 5;
  //       for (var i = 0; i < this.newMeetsComplete.length; i++) {
  //         if (this.newMeetsComplete[i] == removeMeet) {
  //           // בדיקה האם קיים תור להחלפלה
  //           if (this.meetService.meetToChange) {
  //             //console.log(this.meetService.meetToChange.startTimeView, this.newMeetsComplete[i])
  //             if (this.meetService.meetToChange.startTimeView == this.newMeetsComplete[i]) {

  //             } else {
  //               if (i - (meetSkip - 1) < 0) {
  //                 this.newMeetsComplete.splice(0, ((meetSkip * 2) - 1) + (i - (meetSkip - 1)));

  //               } else {
  //                 this.newMeetsComplete.splice(i - (meetSkip - 1), (meetSkip * 2) - 1);

  //               }
  //             }
  //           } else {


  //             if (i - (meetSkip - 1) < 0) {
  //               this.newMeetsComplete.splice(0, ((meetSkip * 2) - 1) + (i - (meetSkip - 1)));

  //             } else {
  //               this.newMeetsComplete.splice(i - (meetSkip - 1), (meetSkip * 2) - 1);

  //             }
  //           }

  //         }

  //       }




  //     });
  //   }


  // }

  addzero(x) {
    x = x.toString();
    if (x.length == 1) {
      x = "0" + x;
    }
    return (x)
  }
  activeFlag = '33:00';
  itemSelected(comp) {
    if (comp.hour_view) {
      if (comp.hour_view == this.shopS.correctAppoin) {
        this.shopS.correctAppoin = "";
      } else {
        this.shopS.correctAppoin = comp.hour_view;
        this.shopS.meetForBuis.setDate(comp.timestamp, comp.hour_view);
      }
    }
  }

  itemSelectedTime() {
    // 
    //console.log(x, y);
    this.LoadingService.present();
    // בודק האם התור קיים
    this.shopS.meetForBuis.newKey();
    this.shopS.meetForBuis.importUserId(this.shopS.currentMeet.user.key);
    this.shopS.meetForBuis.importMeetType(this.shopS.currentMeet.meetType);
    // this.shopS.meetForBuis.importStartDate(this.shopS.currentMeet.date.cal);
    // this.shopS.meetForBuis.importViewStart(this.shopS.correctAppoin);
    this.shopS.meetForBuis.importBarber(this.shopS.shopData.Setting.BarberID, this.shopS.shopData.Setting.name);
    this.shopS.meetForBuis.importMoreInfo(this.shopS.moreInfo);
    this.shopS.meetForBuis.CreateNewMeet();
    this.shopS.meetForBuis.defineFlowAuto();
    this.shopS.meetForBuis.importMeetStatus(1);
    this.shopS.meetForBuis.showClass();

    // console.log(this.shopS.meetForBuis.exportMeetAdmin());

    this.shopS.setMeet();

    // if (this.shopS.itemSelectedTimeAdmin()) {
    // this.meetService.cleanService();
    // // matanEdit
    // //this.navCtrl.setRoot(HomePage);
    // this.shopS.correctAppoin = "";
    // this.shopS.addLineFLAG = !this.shopS.addLineFLAG;
    // this.ToastService.torOk();
    // this.notiS.arrangeJSONforNotificationFROMmeet(this.shopS.meetForBuis.exportMeetAdmin(), 'ZimonToClient');
    // this.shopS.meetForBuis.resetCLass();
    // this.router.navigate(['tabsAdmin/calendar']);
    // }
  }


  convertDate(inputFormat) {
    //console.log('inputFormat', inputFormat);
    var today: any = new Date(inputFormat);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '-' + mm + '-' + yyyy;


  }

  checkMeet(e) {
    e.preventDefault();
    let hours: any = this.shopS.correctAppoin.split(':')[0];
    let minutes: any = this.shopS.correctAppoin.split(':')[1];
    let start = new Date(this.shopS.currentMeet.date.cal).setHours(hours, minutes);

    this.shopS.checkMeetAvailable(start, this.shopS.currentMeet.meetType.meetTime, null, null, available => {
      if (available) {
        this.itemSelectedTime();
      } else {
        this.alertExample.alertConfirm('שימו לב', '', 'קיים כבר תור בזמן זה, האם ברצונכם להמשיך?', 'המשך בכל זאת', 'ביטול', res => {
          if (res) {
            this.itemSelectedTime();
          } else {
            this.shopS.daysFromRow(this.shopS.currentMeet.date);
          }
        });
      }
    });
  }

}
function isA(ad) {
  return ad.local == 2;
}

