import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../ShopService/shop.service';

import { $ } from 'protractor';
import { ModalController } from '@ionic/angular';
import { PremiumGiftComponent } from 'src/components/premium-gift/premium-gift.component';

@Component({
  selector: 'app-tabsAdmin',
  templateUrl: './tabsAdmin.page.html',
  styleUrls: ['./tabsAdmin.page.scss'],
})
export class TabsAdminPage implements OnInit {

  constructor(
    public shopS: ShopService,
    public modalController: ModalController

  ) { }




  ngOnInit() {
    // console.log('tabs admin PAGE');
    // console.log('this.shopS.SelectedMeet.event',this.shopS.SelectedMeet.event);
    this.shopS.AdminFLAG = true;
    // document.addEventListener('backbutton', () => {
    //   console.log('tabsAdmin - - - - backbutton action!');

    //   //  alert('khkjh');
    //   }, false);

    // this.shopS.userStatus();



  }


  async openGiftModal() {
    this.shopS.dismissModalController();
    const modal = await this.modalController.create({
      component: PremiumGiftComponent,
      swipeToClose: true,
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'modalLookLikeNativ',
      mode: "ios",

    });

    await modal.present();
    return modal;
  }

  toggleModal2(event) {

    event.stopPropagation();
    this.shopS.addLineFLAG = !this.shopS.addLineFLAG;
  }

  closeModal() {
    this.shopS.addLineFLAG = false;
  }

  addLineClick(ev) {
    ev.stopPropagation();
  }

  toggleModal(event) {
  //   event.stopPropagation();

  //   this.shopS.daysForViewFunc();
  //   this.shopS.addLineFLAG = !this.shopS.addLineFLAG;
  //   // const peopleArray = Object.keys(this.shopS.shopData.MeetingType).map(i => this.shopS.shopData.MeetingType[i])
  //   const peopleArray: any = Object.entries(this.shopS.shopData.MeetingType).map(([key, value]) => ({ key, value }));
  //   peopleArray[0].value.key = peopleArray[0].key;
  //   if (this.shopS.currentMeet.meetType.name == "דוגמא") {
  //     this.shopS.currentMeet.meetType = peopleArray[0].value;
  //     // this.shopS.currentMeet.user=this.shopS.Auser;

  //   }
  //   this.shopS.selectType = Object.entries(this.shopS.shopData.MeetingType).map(([key, value]) => ({ key, value }));
  //   // console.log("this.shopS.selectType",this.shopS.selectType)
  //   // this.shopS.selectType=Object.values(this.shopS.shopData.MeetingType);
  //   // console.log('toggleModal=======this.shopS.addLineFLAG',peopleArray[0],this.shopS.currentMeet,this.shopS.addLineFLAG);



  }



}
