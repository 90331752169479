import { meetService } from './../../ShopService/meetS.service';
import { BuissnesPageComponent } from './../../app/buissnes-page/buissnes-page.component';
//  import { Keyboard } from '@ionic-native/keyboard/ngx';
import { SourceImage } from 'ng2-image-compress';
import { AlertController, Platform, MenuController } from '@ionic/angular';
import { AlertExample } from './../../../src/providers/alert.service';
// import { Keyboard } from '@ionic-native/keyboard';


import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { TabsPage } from '../tabs/tabs';
import { AngularFireDatabase } from '@angular/fire/database';
import { userService } from '../../ShopService/user.service';
import { ShopService } from '../../ShopService/shop.service';
import { ModalController } from '@ionic/angular';
import { ModalPage } from '../modal/modal.page';

import { AuthService } from './../../providers/auth-service';




@Component({
  selector: 'addBusiness',
  templateUrl: 'addBusiness.page.html',
  styleUrls: ['./addBusiness.page.scss']
})


export class AddBusinessPage implements OnInit, OnDestroy {
  public businessCodeForm: FormGroup;
  public freeShop: any = {};

  public showBusinessNotFound: boolean = false;

  pageStatus;
  constructor(
    private angularFD: AngularFireDatabase,
    public us: userService,
    public modalController: ModalController,
    private alertController: AlertController,
    private meetS: meetService,
    private alert: AlertExample,
    private authService: AuthService,
    public shopS: ShopService,
    private router: Router,
    private Platform: Platform,
    private fb: FormBuilder,
    private menuController: MenuController
  ) { }



  ngOnInit() {

    // console.log("buis on init")
    this.generateBusinessForm();
    // this.shopS.saveAllShop();

    this.pageStatus = this.shopS.shopData.Setting.BarberID;

    console.log("this.us.superNew ", this.us.superNew)

    //   document.addEventListener('backbutton', () => {
    //     console.log('addBussnies - - - - backbutton action!');


    // if(this.us.superNew == false){
    //   this.goBack("tabs");
    // }else{

    //   this.goBack("login");
    // }

    //  }, false);



    // console.log("this.shopS.shopData.Setting,",this.pageStatus);


  }

  // async presentModal() {
  //   const modal = await this.modalController.create({
  //     component: BuissnesPageComponent,
  //     mode: 'ios',
  //   });

  //   await modal.present();
  //   const { data } = await modal.onWillDismiss();
  //   // console.log("sss",data);
  //   if (data.dismissed != false) {
  //     this.getBarModal(data.dismissed)
  //   }

  // }
  // getBarModal(data) {

  //   console.log(data);
  //   this.freeShop.Setting = data;
  //   this.presentAlertFound(data.name)

  // }
  // getBar(data) {

  //   console.log(data);
  //   this.freeShop = data;
  //   this.presentAlertFound(data.Setting.name)

  // }
  ionViewDidLeave() {
    console.log('ionViewDidLeave');
    this.shopS.disableBtn = false;

  }
  ionViewDidEnter() {
    this.menuController.enable(false)
    console.log('ionViewDidEnter');

    this.clearFormValue();
    this.pageStatus = this.shopS.shopData.Setting.BarberID;
    console.log("this.shopS.shopData.Setting,", this.pageStatus);
    this.shopS.disableBtn = true;

  }

  generateBusinessForm() {
    this.businessCodeForm = this.fb.group({
      businessCode: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(5)])]
    });
  }

  getOneShop() {
    this.shopS.getShopDetils('Arie-israeli').valueChanges().subscribe((result) => {
      // console.log(result);
    }, (error) => {
      // console.log(error);
    });
  }
  userRedirect() {
    this.us.registrationdate();
    this.newUserClientFLAG = true;
    this.shopS.buttonHide = true;
    localStorage.setItem('type', 'user');
    this.authService.checkType(this.us.user);
    // this.router.navigate(['tabs/home'], { replaceUrl: true });
  }
  addBusinessPageAdmin() {
    this.us.registrationdate();
    localStorage.setItem('type', 'admin');
    this.authService.checkType(this.us.user);

    // this.router.navigate(['admin-register'], { replaceUrl: true })
  }
  // call this func when user type 5 symbols code. Check each time when he type something.
  // onInputValid() {
  //   if (this.businessCodeForm.valid) {

  //     console.log(this.businessCodeForm.value);
  //     this.shopS.getFreeShopNew(this.businessCodeForm.value.businessCode).subscribe(res => {
  //       if (res) {
  //         this.showBusinessNotFound = false;
  //         this.freeShop.Setting = res;
  //         this.presentAlertFound("")
  //       } else {
  //         this.showBusinessNotFound = true;
  //       }

  //       console.log("res", res)
  //     })
  //     return;

  //     this.freeShop = this.shopS.getFreeShop(this.businessCodeForm.value);
  //     if (!(Object.keys(this.freeShop).length === 0)) {
  //       //  this.keyboard.hide();
  //       console.log("found found");
  //       this.presentAlertFound("")
  //       this.showBusinessNotFound = false;
  //     }
  //   } else {
  //     this.freeShop = {};
  //     if (this.businessCodeForm.value.businessCode !== '') {
  //       this.showBusinessNotFound = true;
  //     } else {
  //       this.showBusinessNotFound = false;
  //     }
  //   }
  // }

  goBack(toNav) {
    this.newUserClientFLAG = false;
    if (toNav == "login") {
      if (this.us.superNew == false) {

        this.presentLogOut();


      } else {
        this.us.superNew = false;
      }
    } else {
      if (toNav == "tabs") {
        this.router.navigate(['tabs/home']);

      } else {
        this.router.navigate(['/' + toNav + '']);

      }

    }

  }

  newUserClientFLAG = false;

  modalBack() {
    console.log('back to im client or biz');
    this.us.superNew = false;
    this.newUserClientFLAG = false;

  }

  modalStart(x) {
    this.us.superNew = true;
    if (x == "user") {
      this.userRedirect();
    } else {
      this.addBusinessPageAdmin();
    }
  }
  // updateUser() {
  //   const currUser = this.us.getUserObj();
  //   console.log('currUser', currUser);
  //   if (this.freeShop.Setting.BarberID) {
  //     console.log('currUser', currUser);

  //     var BarberID = this.freeShop.Setting.BarberID;
  //   } else {
  //     return;
  //   }
  //   this.shopS.configDATA.default.userProfile
  //   // https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/male_avatar_323b.svg



  //   let businessCodes = [];
  //   console.log('currUser.BarberID-before', currUser.BarberID);



  //   // if (currUser.BarberID) {
  //   //   console.log('currUser.BarberID-inside',currUser.BarberID);
  //   //   businessCodes = currUser.BarberID;
  //   // }
  //   // console.log('currUser.BarberID-after',currUser.BarberID);
  //   // console.log('businessCodes-after',businessCodes);



  //   businessCodes.push(BarberID);
  //   // this.us.addUserOnShop(BarberID,currUser);
  //   // console.log(BarberID,this.us.user,this.us.user.key,"------xxxxxx");
  //   this.shopS.updateUserNewToBarber(BarberID, this.us.user, this.us.user.key);

  //   // console.log(BarberID,this.us.user,this.us.user.key)
  //   this.us.updateShopId(BarberID).then((result) => {
  //     console.log(result);



  //     console.log("login - logout")
  //     // this.us.checkUserExciting();
  //     this.us.getUserDetalisByUid(this.us.userUid).snapshotChanges().subscribe((res) => {
  //       this.us.updateBarberID(businessCodes);
  //       this.authService.logOutForLogin();

  //       // this.us.saveUserData(newUser.payload.val(), this.us.userUid);
  //       //  this.router.navigate(['tabs']);
  //     });

  //   });



  // }

  saveBuisCode(code) {
    this.goBack("tabs");
    return;
    this.shopS.updateUserNewToBarber(code, this.us.user, this.us.user.key);
    this.us.updateShopId(code);
    // this.router.navigate(['tabs/home']);
  }
  // submitBusinessCode() {
  //   var fullname = this.us.user.fname + " " + this.us.user.lname;
  //   // console.log("------ >>>>> ",this.businessCodeForm.value,this.us.user);
  //   // console.log("*****>",this.freeShop)
  //   if (this.freeShop.Setting) {


  //     this.meetS.alertNotiToBarber({ BarberID: this.freeShop.Setting.BarberID, userFullName: fullname, BizName: this.freeShop.Setting.name }, 'newClientToBiz', false);
  //     this.updateUser();
  //   }
  //   // this.shopS.updateUserToBarber(this.us.user,this.us.user.key)
  // }


  clearFormValue() {
    this.businessCodeForm.reset();
    this.showBusinessNotFound = false;
  }

  ngOnDestroy() {
    this.clearFormValue();
  }


  // async presentAlertFound(buisName) {
  //   var textT = "<img class='imgalert' src=" + this.freeShop.Setting.logo + ">"
  //   textT += "האם תרצה להתחבר לעסק ?";


  //   const alert = await this.alertController.create({
  //     header: 'עסק נמצא!',
  //     subHeader: this.freeShop.Setting.name,

  //     animated: true,
  //     keyboardClose: true,
  //     message: textT,
  //     buttons: [
  //       {
  //         text: 'לא',
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         handler: (blah) => {
  //           console.log('Confirm Cancel: blah');
  //         }
  //       }, {
  //         text: 'כן התחבר!',

  //         handler: () => {
  //           this.submitBusinessCode();
  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  // }



  async presentLogOut() {



    const alert = await this.alertController.create({
      header: 'התנתקות',
      subHeader: "",

      animated: true,
      keyboardClose: true,
      message: "האם אתה בטוח שתרצה להתנתק ?",
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'כן',

          handler: () => {
            this.authService.logout();
          }
        }
      ]
    });

    await alert.present();
  }

}
