import { ModalController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';
import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { ExclusivePopoverComponent } from '../exclusive-popover/exclusive-popover.component';


@Component({
  selector: 'app-admin-history',
  templateUrl: './admin-history.component.html',
  styleUrls: ['./admin-history.component.scss'],
})
export class AdminHistoryComponent implements OnInit {

  constructor(public shopS: ShopService,
    private modalController: ModalController,
    private plt: Platform,
    private alertController: AlertController

  ) { }
  historyMeet: any = [];

  ngOnInit() {



  }


  closeModal() {
    this.modalController.dismiss()
  }


  ionViewDidEnter() {
    // this.loadHistoryByAndBarber();
    this.shopS.getBusinessHistory().then(history => {
      history.sort(this.compare);
      this.createMonth(history.reverse());
    });
  }

  get_year_from_key(key) {
    // console.log(key)
    let s = key.split('-');
    return s[2];
  }
  get_month_from_key(key) {
    // console.log(key)
    let s = key.split('-');
    return this.monthsList[parseInt(s[1]) - 1];
  }
  monthsList = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];


  // loadHistoryByAndBarber() {
  //   this.shopS.loadHistoryByAndBarber().then(res => {
  //     this.shopS.loadHistoryByAndBarberEvent().then(eve => {
  //       console.log("all my history ", res.val());
  //       var beforeSortEvent = this.shopS.json2array(eve.val());
  //       var beforeSort = this.shopS.json2array(res.val());
  //       var bar = [];
  //       beforeSort.forEach(element => {
  //         Object.keys(element).forEach(key => {
  //           if (typeof element[key] != "string") {
  //             element[key]["userData"] = this.shopS.shopData.Users[element[key].uId];
  //             bar.push(element[key]);
  //           }
  //         });
  //       });

  //       beforeSortEvent.forEach(element => {
  //         Object.keys(element).forEach(key => {
  //           if (typeof element[key] != "string") {
  //             bar.push(element[key]);
  //           }
  //         });
  //       });

  //       bar.sort(this.compare);
  //       // this.historyMeet=beforeSort.reverse();
  //       this.createMonth(bar.reverse());
  //     });
  //   })
  // }
  // loadHistory(){
  //   console.log("user s",this.userS.user.key);
  //   var loadUserHistory = this.shopS.loadUserHistory(this.userS.user.key).subscribe(res=>{
  //     console.log("all my history ",res);
  //     if(res){


  //       let beforeSort=this.shopS.json2array(res);

  //       beforeSort.sort(this.compare);

  //       this.createMonth(beforeSort.reverse())

  //     }else{
  //       this.historyMeet = [];
  //     }
  //     // this.historyMeet=beforeSort.reverse();
  //     console.log("this.historyMeet ",this.historyMeet);

  //     loadUserHistory.unsubscribe();
  //   })
  // }
  displayHistory = [];

  createMonth(dataMeets) {
    var objec = {
      "dates": "",
      "meets": []
    }

    console.log("------->dataMeets", dataMeets);
    var r = 1;

    dataMeets.forEach((element, index) => {
      if (element.meetDate) {

        if (objec.dates == this.get_month_from_key(element.meetDate) + " ," + this.get_year_from_key(element.meetDate)) {
          objec.meets.push(element);

          if (r == dataMeets.length) {
            this.historyMeet.push(objec);
          }
        } else {
          if (objec.dates) {
            this.historyMeet.push(objec);
          }

          objec = {
            "dates": this.get_month_from_key(element.meetDate) + " ," + this.get_year_from_key(element.meetDate),
            "meets": []
          }
          objec.meets.push(element);
        }

        r++;
      }
    });

    this.loading = false;
    console.log("historyMeet", this.historyMeet);
    this.lazyLoad();
  }

  loading = true;

  lazyLoad() {
    this.loading_more = true;
    let finish = false;
    let added = 0;
    this.historyMeet.forEach((month, ind) => {
      if (!finish) {
        if (!this.displayHistory.find(s => s.dates == month.dates)) {
          this.displayHistory.push({ dates: month.dates, meets: [] });
        }
        month.meets.forEach(meet => {
          if (added < 50 && !this.displayHistory[ind].meets.find(m => m.key == meet.key)) {
            this.displayHistory[ind].meets.push(meet);
            added++;
          }
          if (added >= 50) {
            finish = true;
            return;
          }
        });
      }
    });

    setTimeout(() => {
      this.loading_more = false;
    }, 200);

    console.log("displayHistory", this.displayHistory);
  }

  loading_more = false;

  scroll(e) {
    // console.log((e.target.scrollHeight - e.target.offsetHeight) + ' / ' + e.target.scrollTop);
    if ((e.target.scrollHeight - e.target.offsetHeight) - e.target.scrollTop <= 500 && !this.loading_more) {
      this.lazyLoad();
    }
  }

  compare(a, b) {
    // Use toUpperCase() to ignore character casing

    // var d1=this.convertDate(a.startDate);
    // var d2=this.convertDate(b.startDate);

    const bandA = new Date(a.Date);
    const bandB = new Date(b.Date);

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  convertDate(inputFormat) {

    var today: any = new Date(inputFormat);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '-' + mm + '-' + yyyy;

  }











  exportHistory() {
    if (this.shopS.shopData.Setting.Premium.exclusive) {
      console.log('export History to excel!', this.displayHistory);
      if (this.plt.is('cordova')) {
        this.shopS.log(this.shopS.us.key, 'export History to excel')
        this.showAlert();
  
      } else {
        let arrCsv = []
        this.historyMeet.forEach((val, index) => {
          let arr = val.meets
          // console.log('arr', arr);
  
          arr.forEach((el, index2) => {
            let meetStatus = "התור מאושר", name = "", phone = "";
            if (el.meetStatus == 5) {
              meetStatus = "התור בוטל"
            }
            if (el.userData) {
              if (el.userData.fullname) {
                name = el.userData.fullname;
              } else if (el.userData.fname) {
                name = el.userData.fname + " " + el.userData.lname;
              }
              if(el.userData.phone){
              if (el.userData.phone[0] == "+") {
                phone = "(" + el.userData.phone.slice(0, 4) + ")" + el.userData.phone.slice(4);
              } else {
                phone = el.userData.phone;
              }
            }else{
              phone = ''
            }
  
            }
            let obj = {
              "שם": name,
              "טלפון": phone,
              "תאריך": el.meetDate,
              "שעת התחלה": el.startTimeView,
              "שעת סיום": el.endTimeView,
              "שירות": el.meetType.name || el.eventName || "",
              "מחיר": el.price,
              "אורך התור": el.meetTime,
              "סטטוס תור": meetStatus
  
            }
            // console.log('obj', obj);
            arrCsv.push(obj);
          });
  
        });
        console.log('arrCsv', arrCsv);
        this.dataInCSV(arrCsv);
      }
    } else {
      this.shopS.openModal(ExclusivePopoverComponent, 'modalLookfullWidth', false, null);
    }
  }

  dataInCSV(arrCsv) {
    console.log('arrCsv', arrCsv);
    let csvContent = this.saveDataInCSV(arrCsv);
    var hiddenElement = document.createElement('a');
    var universalBOM = "\uFEFF";
    hiddenElement.href = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(universalBOM + csvContent);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'היסטורית תורים ' + '.csv';
    hiddenElement.click();

  }

  saveDataInCSV(data) {
    if (data.length == 0) {
      return '';
    }

    let propertyNames = Object.keys(data[0]);
    let rowWithPropertyNames = propertyNames.join(',') + '\n';
    let csvContent = rowWithPropertyNames;
    let rows = [];

    data.forEach((item) => {
      let values = [];
      propertyNames.forEach((key) => {
        let val = item[key];
        values.push(val);
      });
      rows.push(values.join(','));
    });
    csvContent += rows.join('\n');
    console.log(csvContent);

    return csvContent;
  }



  showAlert() {

    var textT = '<ul class="aaaa" style="">';
    var text = 'ניתן ליצא היסטוריה לאקסל דרך המחשב, נתראה שם!';

    console.log('textT', textT, text);
    textT += text + '</ul>';
    this.alertModal(textT);

  }

  async alertModal(textT) {

    const alert = await this.alertController.create({
      header: 'יצוא היסטוריה לאקסל',
      message: textT,
      buttons: [
        {
          text: 'סבבה',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cancel Cancel: Cancel');
            return false;
          }
        },
        // {
        //   text: 'אישור ושמירה',
        //   handler: () => {

        //     console.log('Okay: Okay');
        //     //confirm order 

        //   }
        // }
      ]
    });
    await alert.present();

  }



}

