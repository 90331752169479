import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksComponent } from './tasks.component';
import { TasksService } from "./tasks-service/tasks.service";
import { TaskViewComponent } from "./task-view/task-view.component";

@NgModule({
  declarations: [TasksComponent,TaskViewComponent],
  providers:[TasksService],
  imports: [
    CommonModule
  ],entryComponents:[TasksComponent,TaskViewComponent],
  exports:[TasksComponent]
})
export class TasksModule { }
