import { Injectable } from '@angular/core';
import {NgxImageCompressService} from 'ngx-image-compress';

@Injectable({
  providedIn: 'root'
})
export class ImageCompressService {

  constructor(private imageCompress: NgxImageCompressService) { }


  imgResultBeforeCompress:string;
  imgResultAfterCompress:string;
 
  // compressFile() {
  
  //   this.imageCompress.uploadFile().then(({image, orientation}) => {
  //     console.log('image',image)
  //     console.log('orientation',orientation)
  //     this.imgResultBeforeCompress = image;
  //     console.warn('Size in bytes was:', this.imageCompress.byteCount(image));
      
  //     this.imageCompress.compressFile(image, orientation, 50, 50).then(
  //       result => {
  //         this.imgResultAfterCompress = result;
  //         console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
  //       }
  //     );
      
  //   });
    
  // }



 async compressFile2(image, orientation) {
  



      console.log('image',image)
      console.log('orientation',orientation)
      this.imgResultBeforeCompress = image;
      console.warn('Size in bytes was:', this.imageCompress.byteCount(image));
      
      return this.imageCompress.compressFile(image, orientation, 50, 50).then(
        result => {
          this.imgResultAfterCompress = result;

          console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));

          return result;

        }
      );
      

    
  }



  dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

  // async file(file){

  //   var self = this;

  //  // let or = this.getOrientation(file);
  //   let or = -1;

  //   console.log('getOrientation',or);

  //   var reader = new FileReader();
  //   reader.onloadend = function() {
  //    return self.compressFile2(reader.result, or).then(res => {
  //       return res;
  //     });
  //   }
  //   reader.readAsDataURL(file);
  



  //   // this.encodeImageFileAsURL(file,or).then(res => {

  //   //   return res;

  //   // });




 

  // }



async encodeImageFileAsURL(file,orientation) {
  var reader = new FileReader();

  var self = this;

  reader.onloadend = function() {
   console.log('RESULT', reader.result);

  return  self.compressFile2(reader.result, orientation);


  }
  reader.readAsDataURL(file);
}












   getOrientation(file) {
    /** @type {?} */
    const reader:any = new FileReader();
    try {
        reader.onload = (/**
         * @param {?} $event
         * @return {?}
         */
        function ($event) {
            /** @type {?} */
            const view = new DataView( (reader.result));
            if (view.getUint16(0, false) !== 0xFFD8) {
                return -2;
            }
            /** @type {?} */
            const length = view.byteLength;
            /** @type {?} */
            let offset = 2;
            while (offset < length) {
                /** @type {?} */
                const marker = view.getUint16(offset, false);
                offset += 2;
                if (marker === 0xFFE1) {
                    if (view.getUint32(offset += 2, false) !== 0x45786966) {
                        return -1;
                    }
                    /** @type {?} */
                    const little = view.getUint16(offset += 6, false) === 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    /** @type {?} */
                    const tags = view.getUint16(offset, little);
                    offset += 2;
                    for (let i = 0; i < tags; i++) {
                        if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                            return view.getUint16(offset + (i * 12) + 8, little);
                        }
                    }
                }
                else if ((marker & 0xFF00) !== 0xFF00) {
                    break;
                }
                else {
                    offset += view.getUint16(offset, false);
                }
            }
            return -1;
        });
        reader.readAsArrayBuffer(file);
    }
    catch (e) {
        return 0;
    }
}





















}