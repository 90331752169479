import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';
import { InvoiceServiceService } from "../invoice-service/invoice-service.service";

@Component({
  selector: 'app-invoice-popup',
  templateUrl: './invoice-popup.component.html',
  styleUrls: ['./invoice-popup.component.scss'],
})
export class InvoicePopupComponent implements OnInit {

  @Input() firstName: string;
  @Input() lastName: string;
  @Input() middleInitial: string;


  constructor(
    public shopS:ShopService,
    public invoiceS:InvoiceServiceService,
    public modalController: ModalController) { }
  // document_succsess_popup = false;
  // invoice_loader = true;
  // loader_message = 'מפיק מסמך';

  ngOnInit() {
    console.log(this.firstName,this.lastName);
    console.log(this.invoiceS.invoiceObj);

// setTimeout(() => {
//   this.loader_message = 'חותם את המסמך'
// }, 6000);

// setTimeout(() => {
  
//   this.shopS.turnOnConfetti(10000);
//   this.invoice_loader = false;
//   this.document_succsess_popup = true;


// }, 12000);


  }


  dismissModal() {
    this.invoiceS.loader_message = 'מפיק מסמך'
    if (this.modalController) {
      this.modalController.dismiss({
        'dismissed': false
      }).then(() => { this.modalController = null; });
    }
  }

}
