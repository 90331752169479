import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AlertExample } from 'src/providers/alert.service';
import { LoadingService } from 'src/providers/loading.service';
import { ShopService } from 'src/ShopService/shop.service';
import { WalletServiceService } from '../wallet-service/wallet-service.service';

@Component({
  selector: 'app-wallet-view-doc',
  templateUrl: './wallet-view-doc.component.html',
  styleUrls: ['./wallet-view-doc.component.scss'],
})
export class WalletViewDocComponent implements OnInit {

  constructor(
    public walletS: WalletServiceService,
    public shopS: ShopService,
    public alertS: AlertExample,
    public alertController: AlertController,
    private LoadingService: LoadingService,
    public modalController: ModalController

  ) { }

  ngOnInit() {
    console.log('view_init');

    if (this.walletS.view_doc.withdrawal_created) {
      let withdraw_j = this.walletS.view_doc.withdrawal_created;
      withdraw_j = {
        month: new Date(this.walletS.view_doc.withdrawal_created).getMonth(),
        year: new Date(this.walletS.view_doc.withdrawal_created).getFullYear()
      }
      // console.log('------------withdraw_j',withdraw_j);
      if (withdraw_j.month < 10) {
        withdraw_j.month = '0' + withdraw_j.month;
      }
      // this.walletS.current_seller.seller_monthly_invoices[]
      // console.log('------------withdraw_j',withdraw_j);

      console.log(this.walletS.current_seller.seller_monthly_invoices);
      let ddd = this.shopS.json2array(this.walletS.current_seller.seller_monthly_invoices);
      let filterdd = ddd.filter(x => x.invoice_reference == withdraw_j.year + '-' + withdraw_j.month);
      //  console.log('ddd',ddd);
      //  console.log('filterdd',filterdd);
      this.walletS.view_doc.seller_monthly_invoices = filterdd;

      console.log('this.walletS.view_doc', this.walletS.view_doc);


      // var date = new Date(this.walletS.view_doc.withdrawal_created);
      // var result = date.toLocaleDateString("en-GB", { // you can use undefined as first argument
      //   year: "numeric",
      //   month: "2-digit",
      //   day: "2-digit",
      // });

      // console.log('------------withdraw',result);



    } else {
      let id = this.walletS.view_doc.sale_payme_id;
      if (this.walletS.view_doc.type == 'payment request' && this.walletS.view_doc.paymentRespone) {
        let obj = this.shopS.json2array(this.walletS.view_doc.paymentRespone)
        id = obj[0].payme_sale_id;
      }
      if (id) {
        this.shopS.getTransaction(id).once('value').then(res => {
          let tran = res.val();
          console.log(tran);
          
          if (tran.invoice) {
            if (tran.invoice.invoice_supplier == 'Icount') {

              this.walletS.view_doc['invoice'] = tran.invoice.doc_url;
            }
            if (tran.invoice.invoice_supplier == 'Greeninvoice') {
              this.walletS.view_doc['invoice'] = tran.invoice.url.origin;
            }
          } else {

          }
          console.log('TRAN', tran);
        })
      }
    }
  }

  triangles = Array(100).fill(0);


  dismissModal() {
    if (this.modalController) {
      this.modalController.dismiss({
        'dismissed': false
      }).then(() => { this.modalController = null; });
    }
  }



  click_on_link(link) {
    console.log('.click_on_link[link]', link)
    window.open(link, '_blank');
  }


  refund() {
    this.LoadingService.presentforever();
    let data = {
      seller_payme_id: this.walletS.shopS.shopData.Wallet.wallet_id,
      payme_sale_id: this.walletS.view_doc.sale_payme_id,
      sale_refund_amount: this.walletS.view_doc.sale_price
    }
    this.walletS.send_to_api('refund-sale', data, val => {
      this.LoadingService.dismiss();
      val = val.res;
      if (val.sale_status == 'refunded') {
        this.walletS.presentAlert2(' ', 'העסקה זוכתה בהצלחה');
        this.walletS.view_doc.sale_status = 'refunded';
      } else {
        this.walletS.presentAlert2(' ', 'התקבלה שגיאה, נסה שנית מאוחר יותר');
      }
      console.log(val);
    });


  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: 'זיכוי עסקה',
      message: 'פעולה זו תזכה את העסקה במלואה והכסף יוחזר לקונה',
      buttons: [
        {
          text: 'ביטול',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'אישור',
          handler: () => {
            this.refund();
          }
        }
      ]
    });

    await alert.present();
  }




  presentPayment() {
    let obj = this.shopS.json2array(this.walletS.view_doc.paymentRespone);
     console.log(obj,this.walletS.sales_array[0].find(m => m.sale_payme_id == obj[0].payme_sale_id));
     
    this.walletS.view_doc =this.walletS.sales_array[0].find(m => m.sale_payme_id == obj[0].payme_sale_id);
    console.log('presentPayment this.walletS.view_doc',this.walletS.view_doc);
    
  }

}
