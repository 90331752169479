import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertExample } from 'src/providers/alert.service';
import { PaymentService } from '../payment-service/payment.service';

@Component({
  selector: 'app-card-options',
  templateUrl: './card-options.component.html',
  styleUrls: ['./card-options.component.scss'],
})
export class CardOptionsComponent implements OnInit {

  constructor(public PaymentService:PaymentService, private AlertExample:AlertExample,private ModalController:ModalController) { }

  ngOnInit() {}


  dismissModal(){
    this.ModalController.dismiss()
  }


  async presentAlertConfirm(card) {

  //  console.log(card)

       
    let isCardUseForSubscribe = this.PaymentService.mySubscriptionArray.find(o => o.ccard.key === card.key);
console.log('isCardUseForSubscribe',isCardUseForSubscribe)
    if(isCardUseForSubscribe){
      console.log('exist');
      const alert = await this.AlertExample.alertController.create({
        header: 'כרטיס משויך למנוי',
        message: 'לא ניתן למחוק כרטיס שמשויך למנוי פעיל',
        buttons: [
          {
            text: 'הבנתי',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
            }
          }
        ]
      });
      await alert.present();




    }else{
      console.log('not exist');

    const alert = await this.AlertExample.alertController.create({
      header: 'מחיקת כרטיס',
      message: 'בטוחים שתרצו למחוק ?',
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'כן',
          
          handler: () => {
            this.PaymentService.deleteCard(card);

          }
        }
      ]
    });
    await alert.present();
}



}






async changeDefault(key){
  const alert = await this.AlertExample.alertController.create({
    header: 'הגדרת כרטיס',
    message: 'בטוחים שתרצו להגדיר כרטיס זה כברירת מחדל ?',
    buttons: [
      {
        text: 'לא',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {
          console.log('Confirm Cancel: blah');
        }
      }, {
        text: 'כן שנה הגדרת כרטיס!',
        
        handler: () => {
          this.PaymentService.changeDefault(key);
        }
      }
    ]
  });
  await alert.present();

}

















}
