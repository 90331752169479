import { Component, NgZone, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl } from "@angular/forms";
import { } from 'google-maps';
import { ShopService } from './../../../src/ShopService/shop.service';

import { MapsAPILoader } from '@agm/core';
import { WalletServiceService } from '../wallet/wallet-service/wallet-service.service';

@Component({
    selector: 'app-googlemaps',
    templateUrl: './googlemaps.component.html',
    styleUrls: ['./googlemaps.component.scss'],
})
export class GooglemapsComponent implements OnInit {
    public latitude: number;
    public longitude: number;
    public searchControl: FormControl;
    public zoom: number;
    @Input('type') type;
    maptyles = [
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#444444"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#46bcec"
                },
                {
                    "visibility": "on"
                }
            ]
        }
    ]

    @ViewChild("search", { static: true })
    public searchElementRef;

    constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, public shopS: ShopService, private walletS: WalletServiceService
    ) {
        this.zoom = 2;
        this.latitude = 39.8282;
        this.longitude = -98.5795;

        //create search FormControl
        this.searchControl = new FormControl();

        //set current position
        // this.setCurrentPosition();

    }
    focusText(t) {
        console.log(t);
    }
    BlourText(t) {
        console.log(t);

    }

    search: any = '';

    blurInput(ev) {
        ev.target.value = this.shopS.shopData.Wallet['wallet_setup'].address_full;
    }

    ngOnInit() {
        console.log("GOOOOOOOOOOOOGLE", this.type);

        //set google maps defaults
        this.zoom = 10;
        this.latitude = 32.0143684;
        this.longitude = 34.7502401;

        //create search FormControl
        this.searchControl = new FormControl();

        //set current position
        // this.setCurrentPosition();

        //load Places Autocomplete
        this.mapsAPILoader.load().then((val) => {
            // console.log('val', val)
            // let nativeHomeInputBox = document.getElementById('txtHome').getElementsByTagName('input')[0];
            let nativeHomeInputBox: any = document.getElementById('txtHome');

            var options = {
                types: ["address"]
            };

            console.log('nativeHomeInputBox', nativeHomeInputBox)
            let autocomplete = new google.maps.places.Autocomplete(nativeHomeInputBox, options);
            console.log('autocomplete', autocomplete);
            
            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    //get the place result
                    let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                    console.log(place);

                    //verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    //set latitude, longitude and zoom
                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.zoom = 16;
                    console.log('this.latitude', this.latitude)
                    console.log('this.longitude', this.longitude)
                    console.log('this.zoom', this.zoom)
                    console.log('autocomplete', autocomplete)
                    console.log('nativeHomeInputBox.value', nativeHomeInputBox.value);

                    // if (this.walletAddress) {
                    // this.shopS.locationSet.address = nativeHomeInputBox.value;
                    // }


                    this.shopS.locationSet.address = nativeHomeInputBox.value;
                    this.shopS.locationSet.latitude = place.geometry.location.lat();
                    this.shopS.locationSet.longitude = place.geometry.location.lng();
                    this.shopS.locationSet.isLocation = false;

                    place.address_components.forEach(comp => {
                        comp.types.forEach(type => {
                            if (type == 'street_number') {
                                this.shopS.locationSet.street_number = comp.long_name;
                            } else if (type == 'route') {
                                this.shopS.locationSet.street = comp.long_name;
                            } else if (type == 'locality') {
                                this.shopS.locationSet.city = comp.long_name;
                            } else if (type == 'country') {
                                this.shopS.locationSet.country = comp.long_name;
                            }
                        });
                    });

                    if (this.type == 'wallet') {
                        this.shopS.shopData.Wallet['wallet_setup'].address_full = this.shopS.locationSet.address;
                        this.shopS.shopData.Wallet['wallet_setup'].seller_address_city = this.shopS.locationSet.city;
                        this.shopS.shopData.Wallet['wallet_setup'].seller_address_street = this.shopS.locationSet.street;
                        this.shopS.shopData.Wallet['wallet_setup'].seller_address_street_number = this.shopS.locationSet.street_number;
                        this.shopS.shopData.Wallet['wallet_setup_status'] = 'start';
                        this.walletS.afDb.object('/BarberShop/' + this.shopS.shopData.Setting.BarberID + '/Wallet').update({ wallet_setup: this.shopS.shopData.Wallet['wallet_setup'], wallet_setup_status: 'start' });
                        console.log(this.shopS.shopData.Wallet);
                    }

                });
            });
        });
    }



    private setCurrentPosition() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 12;
            });
        }
    }

}
