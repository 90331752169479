import { SAdminRegComponent } from './../../app/s-admin-reg/s-admin-reg.component';
import { LoadingService } from 'src/providers/loading.service';
import { AuthService } from './../../../src/providers/auth-service';
import { userService } from './../../../src/ShopService/user.service';
import { ShopService } from './../../../src/ShopService/shop.service';
import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, Platform, MenuController, ModalController } from '@ionic/angular';
import { TabsPage } from '../pages/tabs/tabs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { ConfigService } from "../../app/config.service";








@Component({
  selector: 'app-admin-register',
  templateUrl: './admin-register.page.html',
  styleUrls: ['./admin-register.page.scss'],
})
export class AdminRegisterPage implements OnInit {
  codeArray = [];
  viewNumber = [];
  isReg = true;
  codeNumber = [];
  stage1 = false;
  stage2 = false;
  stage3 = false;
  email = '';


  bizTypeChose = true;
  CodeNumberIs = "";
  registrationForm: FormGroup;
  todo = {
    fname: '',
    lname: '',
    email: '',
    pass: '',
    gender: '',
    myDate: '',
    userManager: ''
  };
  uid;
  userRef;
  userRef2;

  userdata;



  newBiz = {
    "History": {},
    "Holidays": {},
    "MeetingType": {
      "-KiadQ7abrQ9H4NGNsXF": {
        "approve": false,
        "color": "#434a54",
        "flow": false,
        "gender": "female",
        "info": "987654",
        "meetTime": "30",
        "name": "כללי",
        "pic": "40",
        "price": "0",
        "reminder": "20",
        "hidden": true



      }
    },
    "Meetings": {
    },
    "Posts": {},
    "Rating": {
      "Avg": 4,
      "Total": 4,
      "Vote": 1
    },
    'Users': {
      'localUserAdmin': {
        local: '2',
        fname: "",
        lname: "",
        phone: "",
        ProfilePic: 'close',
        key: "localUserAdmin"
      }
    },
    "Setting": {
      "buisStatus": "new",
      "Premium": {
        "CalendarTime": 7,
        "History": false,
        "HistoryOption": {},
        "Reminders": false,
        "UserData": "Basic",
        "TeamManagement": "false",
        "CustomerBaner": false,
        "WebView": false,
        "CartridgeMeet": 4,
        "Statistics": "Basic",
        "status": 'Basic',
        "repeat": 'Basic',
        "train": "Basic",
        "zoom": 'Basic',
        "cancelmeetok": 'Basic',
        "cancelation": false
      },
      "SignedUpOn": new Date(),
      "regDateFullDate": "",
      "Message": "הודעה",
      "about": "אודות העסק",
      "coronaHealthPopup": "true",
      "addres": "",
      "buisType": "",
      "BarberID": "",
      "bussniesPostImg": "",
      "code": '12345',
      "color": "#3897ad",
      "cover": "https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/jing.jpg?alt=media&token=3c7eaa0d-9039-42f5-b41e-860f35b90108",
      "daysOrderBlock": "1",
      "timeStep": "15",
      "history": "false",
      "searchByText": true,
      "codeVisible": true,
      "historyStaus": "11-21-2019",
      "limitMeet": 7,
      "limitMeetAdmin": 7,
      "trainMeet": false,
      "latitude": 0,
      "longitude": 0,
      "logo": "https://firebasestorage.googleapis.com/v0/b/webcut-2001a.appspot.com/o/icon.png?alt=media&token=9663ccd6-2c4e-415a-ae30-975e3fa2eb8f",
      "meetTime": "30",
      "name": "",
      "periodic": 3,
      "phone": "",
      "photos": ["photo1.png"],
      "profilePic": "pro.png",
      "template": 1,
      "userManager": "",
      "meetCounter": 0,
      "regDate": "",
    },
    "WorkDays": [{
      "dayName": "א׳",
      "timeEnd": "18:00",
      "timeStart": "08:00"
    }, {
      "dayName": "ב׳",
      "timeEnd": "18:00",
      "timeStart": "08:00"
    }, {
      "dayName": "ג׳",
      "timeEnd": "18:00",
      "timeStart": "08:00"
    }, {
      "dayName": "ד׳",
      "timeEnd": "18:00",
      "timeStart": "08:00"
    }, {
      "dayName": "ה׳",
      "timeEnd": "18:00",
      "timeStart": "08:00"
    }, {
      "dayName": "ו׳",
      "timeEnd": "18:00",
      "timeStart": "08:00"
    }, {
      "dayName": "ש׳",
      "timeEnd": "16:00",
      "timeStart": "14:00"
    }],
    "id": 1,
    "bfs": ""
  }


  constructor(
    public navCtrl: NavController,
    private alertController: AlertController,
    public shopS: ShopService,
    private afDb: AngularFireDatabase,
    private router: Router,
    private us: userService,
    private auth: AuthService,
    private loadingService: LoadingService,
    public configS: ConfigService,
    private menuController: MenuController,
    public modalController: ModalController

  ) {


    this.menuController.enable(false);
  }


  async presentModal() {
    const modal = await this.modalController.create({
      component: SAdminRegComponent,
      mode: 'ios',
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'my-custom-modal-css',
    });

    return await modal.present();
  }


  ngOnInit() {
    this.email = this.us.user.email;
    this.shopS.loaderoff = false;

    // console.log('new_open_premium',this.shopS.configDATA.new_open_premium)
    // console.log('currentVersion',this.shopS.configDATA.currentVersion)
    if ((this.shopS.configDATA.new_open_premium == this.shopS.configDATA.currentVersion) && (this.shopS.configDATA.currentVersion != undefined) ) {
      // console.log('GIVE PREMIUM true' );
      this.newBiz.Setting.Premium = {
        CalendarTime: 120,
        History: true,
        HistoryOption: {},
        Reminders: true,
        UserData: "premium",
        TeamManagement: "premium",
        CustomerBaner: true,
        WebView: true,
        CartridgeMeet: 40,
        Statistics: "premium",
        train: "premium",
        cancelation: true,
        status: 'premium',
        repeat: 'premium',
        zoom: 'premium',
        cancelmeetok: 'premium'
      }
    }
    // else{
    //   // console.log('GIVE PREMIUM false' );

    // }

    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.newBiz.Setting.phone = this.userdata.phone;
    // document.addEventListener('backbutton', () => {
    //   console.log('admin register - - - - backbutton action!');

    //   this.goBack();


    //     }, false);

    this.codeCollect();

    setTimeout(() => {
      this.getBarberNewCode();
    }, 1000);





    this.shopS.log(this.userdata.key, 'biz_register_start');




  }


  add_barber_to_user(){
    if(!this.us.user.bizArray){
      this.us.user.bizArray = [];
    }
    this.us.user.bizArray.push(this.uid);
    this.us.addBizArray(this.us.user.bizArray).then(res => {
    })
  }


  // Validators.pattern(/^0\d([\d]{0,1})([-]{0,1})\d{7}$/)
  ionViewDidEnter() {
    console.log("presentModal")
    this.presentModal();
  }

  showFormValue() {
    // console.log(this.registrationForm.value);
    // console.log(this.registrationForm.valid);
  }

  goBack() {
    console.log('go back');
    this.us.superNew = false;
    // console.log(this.shopS.previousUrl);
    // // return;
    // if (this.shopS.previousUrl) {
    //   this.router.navigate([this.shopS.previousUrl], { replaceUrl: true });
    // } else {
    //   localStorage.setItem('type', 'user');
    //   this.auth.checkType(this.us.user);
    // }

    let type = localStorage.getItem('type');
    if (type == 'user') {
      this.router.navigate(['/tabs/home'], { replaceUrl: true });
    } else {
      localStorage.setItem('type', 'user');
      this.auth.checkType(this.us.user);
    }
  }
  // collectAndSave(data) {
  //   let finalData = {
  //     BarberID: '',
  //     uid: ''
  //   };
  //   for (let key in data) {
  //     if (key === 'BarberID') {
  //       finalData.BarberID = data[key];
  //     }
  //   }
  //   finalData.uid = this.uid;
  //   this.localStorageService.saveToLocalStorage('user', finalData);
  // }

  newPageOne() {
    this.stage1 = true;
  }

  newPageTwo() {
    this.stage1 = false;
    this.stage2 = true;



    this.newBiz.Setting.addres = this.shopS.locationSet.address;
    this.newBiz.Setting.latitude = this.shopS.locationSet.latitude;
    this.newBiz.Setting.longitude = this.shopS.locationSet.longitude;

    console.log('this.shopS.locationSet', this.shopS.locationSet)
    console.log('this.newBiz.Setting', this.newBiz.Setting)

  }


  newtPage() {
    this.stage2 = false;
    this.stage3 = true;

    // this.shopS.confettiFLAG = false;
    this.shopS.turnOnConfetti(5300);

    // setTimeout(() => {
    //   // this.shopS.confettiFLAG = true;
    //   this.shopS.turnOnConfetti();
    // }, 5300);

  }

  // newGuid() {
  //   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
  //     var r = Math.random() * 16 | 0,
  //       v = c == 'x' ? r : (r & 0x3 | 0x8);
  //     return v.toString(16);
  //   });
  // }
  newGuid() {
    let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });

    let time = new Date().getTime();
    return guid + time;
  }

  validateEmail() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
      return true;
    } else {
      return false;
    }
  }

  validte() {
    console.log('newBiz.Setting.buisType', this.newBiz.Setting.buisType);
    var phonec = this.newBiz.Setting.phone;
    //  phonec.Validators.pattern(/^[0][5][0|2|3|4|5|9]{1}[-]{0,1}[0-9]{3}[-]{0,1}[0-9]{4}$/);
    if ((this.newBiz.Setting.name) && (this.newBiz.Setting.phone) && (this.us.PhoneValidtionIsrael(phonec) && this.validateEmail())
    ) {
      this.isReg = false;
    } else {
      this.isReg = true;
    }
  }


  confiemNew() {
    this.register();

    // var text4='                 בלחיצה על אישור תחל תקופת ניסיון של 30 יום ללא תשלום, בסוף התקופה תתבקשו להזין פרטי תשלום ניתן לבטל את המנוי בכל שלב.';
    // this.aleryByText2(text4);

  }

  async alertGoBack(str) {
    const alert = await this.alertController.create({
      header: 'חזרה לדף משתמש',
      subHeader: 'תהליך יצירת העסק יתאפס, האם ברצונך להמשיך?',
      message: str,
      buttons: [
        {
          text: 'ביטול',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return false;
          }
        }, {
          text: 'כן',
          handler: () => {
            if (!this.us.user.registrationdate) {
              this.us.registrationdate();
            }
            this.goBack()
          }
        }
      ]
    });

    await alert.present();
  }


  async aleryByText2(str) {
    const alert = await this.alertController.create({
      header: 'פתיחת עסק חדש',
      subHeader: '',
      message: str,
      buttons: [
        {
          text: 'ביטול',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return false;
          }
        }, {
          text: 'כן אני רוצה!',
          handler: () => {
            this.register()
          }
        }
      ]
    });

    await alert.present();
  }

  chooseBizType(type) {
    this.shopS.buisType = type;

    this.newBiz.Setting.buisType = type;
    this.bizTypeChose = false;
    console.log(this.shopS.buisType)

  }


  register() {
    this.loadingService.presentforever();



    console.log("register admin")
    // console.log('this.newBiz',this.newBiz);
    // this.loadingService.presentAFTERREG();

    var userData: any = JSON.parse(localStorage.getItem("userdata"));

    // console.log(userData);

    //  this.registrationForm.value.ProfilePic="https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/male_avatar_323b.svg"

    // this.afAuth.auth.createUserWithEmailAndPassword(
    //   this.registrationForm.value.email,
    //   this.registrationForm.value.pass
    // ).then(authState => {
    //   authState.user.sendEmailVerification();
    //    this.uid = authState.user.uid;
    this.uid = this.newGuid();

    ///   this.shopS.buisType= this.newBiz.Setting.buisType;

    //  console.log(this.shopS.configDATA.default.cover)
    //  console.log(this.shopS.buisType)
    //  console.log(this.shopS.configDATA.default.cover[this.shopS.buisType])
    this.newBiz.Setting.cover = this.shopS.configDATA.default.cover[this.shopS.buisType];
    this.newBiz.Setting.logo = this.shopS.configDATA.default.profile;
    let v = new Date();
    this.newBiz.Setting.regDateFullDate = v.toString(); 0
    this.newBiz.Setting.regDate = v.getFullYear() + "-" + (v.getMonth() + 1) + "-" + v.getDate();


    this.newBiz.Setting.BarberID = this.uid;
    this.userRef = this.afDb.object('/BarberShop/' + this.uid);

    // this.userRef2 = this.afDb.object('/Users/' + userData.key + '/shopOwner/id/');

    // console.log(userData.key);

    this.us.updateShopId(this.uid).then((result) => {
      // console.log(result);
    })

    this.newBiz.Setting.userManager = userData.key;

    this.shopS.resetShopData();
    this.shopS.shopData = this.newBiz;
    this.shopS.tutrialModeskip = true;
    this.us.registrationdate();
    this.afDb.object('/Users/' + userData.key).update({ email: this.email, shopOwnerKey: { id: this.uid } }).then(() => {
      this.us.user.email = this.email;
      this.afDb.object('/Codes/' + this.newBiz.Setting.code).set(this.newBiz.Setting).then(res => { })
      this.userRef.set(this.newBiz).then((result) => {
        console.log(result);

        // this.AlertExample.presentAlertNewAdmin();
        this.shopS.tutrialModeskip = false;
        // this.shopS.CloseLiveSub(this.shopS.shopData.Setting.BarberID);

        this.shopS.shopID = this.uid;
        this.loadingService.dismiss();
        // this.auth.logOutForLogin();
        this.loadingService.presentAFTERREG();
        this.us.user.shopOwnerKey = { id: this.uid };
        this.add_barber_to_user();

        var Barber_obj = {
          BarberID: this.newBiz.Setting.BarberID,
          BuisType: this.newBiz.Setting.buisType,
          BuisName: this.newBiz.Setting.name,
          lat: this.newBiz.Setting.latitude,
          lng: this.newBiz.Setting.longitude,
          searchByText: this.newBiz.Setting.searchByText,
          BuisCode: this.newBiz.Setting.code,
          actionType:'setGeoLocation'
        }

        Barber_obj = this.shopS.cryptoService.Encrypt(Barber_obj);

        this.shopS.http.post(this.shopS.serverDev + '/Geo', Barber_obj).subscribe(val => {
          console.log('val ', val);
        });        // this.shopS.http.post('')

        // //haim change
        // this.loadingService.present();
        // this.auth.logOutForLogin();

      }, (error) => {
        this.loadingService.dismiss();


      });
    }, (error) => {
      this.loadingService.dismiss();




    });

    // this.afDb.object('/Users/' + userData.key + '/shopOwner/id/').set(this.uid).then((result) => {

    //   // this.shopS.updateUserAdmin(userData.key, this.uid);

    //   this.afDb.object('/Codes/' + this.newBiz.Setting.code).set(this.newBiz.Setting).then(res => { })
    //   this.userRef.set(this.newBiz).then((result) => {
    //     console.log(result);

    //     // this.AlertExample.presentAlertNewAdmin();
    //     this.shopS.tutrialModeskip = false;
    //     this.shopS.CloseLiveSub(this.shopS.shopData.Setting.BarberID);

    //     this.shopS.shopID = this.uid;
    //     this.loadingService.dismiss();
    //     // this.auth.logOutForLogin();
    //     this.loadingService.presentAFTERREG();


    //     var Barber_obj = {
    //       BarberID: this.newBiz.Setting.BarberID,
    //       BuisType: this.newBiz.Setting.buisType,
    //       BuisName: this.newBiz.Setting.name,
    //       lat: this.newBiz.Setting.latitude,
    //       lng: this.newBiz.Setting.longitude,
    //       searchByText: this.newBiz.Setting.searchByText,
    //       BuisCode: this.newBiz.Setting.code
    //     }

    //     Barber_obj = this.shopS.cryptoService.Encrypt(Barber_obj);

    //     this.shopS.http.post(this.shopS.serverDev + '/Geo', Barber_obj).subscribe(val => {
    //       console.log('val ', val);
    //     });        // this.shopS.http.post('')

    //     // //haim change
    //     // this.loadingService.present();
    //     // this.auth.logOutForLogin();

    //   }, (error) => {
    //     this.loadingService.dismiss();


    //   });

    // }, (error) => {
    //   this.loadingService.dismiss();




    // });


    // this.userRef2.set(this.uid).then((result) => {

    //   alert('  יוזר - עודכן בהצלחה');

    // }, (error) => {
    //   alert('נכשל');


    // }); 


    // this.userRef.set(this.newBiz).then((result) => {



    // }, (error) => {



    // }); 
    //     if (this.uid) {
    //       this.userRef.set(this.registrationForm.value).then((result) => {
    //       }, (error) => {
    //         // this.toastService.showToast('SOME ERROR');
    //       });
    //       // console.log(this.userRef.snapshotChanges());
    //       this.userRef.snapshotChanges().subscribe( (result) => {
    //         this.collectAndSave(result);
    //         this.router.navigate(['/addBusiness'], {replaceUrl: true});
    //       });

    //       // if (user !== null) {
    //       //   const uid = user.i
    //       // }

    //       // this.navCtrl.pop();
    //     }
    //   }).catch( async (error) => {
    //     if (error.message.includes('The email address is already in use')) {
    //       const alert = await this.alertCtrl.create({
    //         header: 'התראה',
    //         message: 'המייל כבר קיים במערכת',
    //         buttons: ['סבבה']
    //       });

    //       await alert.present();
    //     }
    //   });
    // }
    //  console.log(this.todo)
  }



  getBarberNewCode() {
    this.codeNumber = [];
    var a = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    var n;
    var r = [];
    for (n = 1; n <= 5; ++n) {
      var i = Math.floor((Math.random() * (10 - n)) + 1);
      r.push(a[i]);
      this.codeNumber.push(a[i]);
      a[i] = a[10 - n];
    }

    // this.codeNumber=this.codeNumber.slice().reverse();
    this.viewNumber = this.codeNumber.slice().reverse();
    var newCode = this.codeNumber.toString();
    newCode.replace(/','/g, '')

    newCode = newCode.replace(/,/g, '')


    this.shopS.http.post(this.shopS.serverDev + '/check_buis_code', newCode).subscribe(val => {
      console.log('check_buis_codecheck_buis_codecheck_buis_codecheck_buis_code', val)
      if (val) {
        this.getBarberNewCode();
      } else {
        this.newBiz.Setting.code = newCode;
        this.CodeNumberIs = newCode;
      }


    }, err => {

      console.log('err', err)
    });

    // return r;

    // console.log(x,"xxxxxxxxxxxx")


  }


  codeCollect() {
    var xCode = this.shopS.getAllCode("0").subscribe(x => {

      x.forEach(code => {
        var CheckS: any = code;

        if (CheckS.code) {


          this.codeArray.push(CheckS.code);

        }
      })


      xCode.unsubscribe();
    })
  }

}
