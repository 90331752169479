
export class PremiumClass {
    CalendarTime:Number;
    History:Boolean;
    HistoryOption:object;
    Reminders:Boolean;
    UserData:string;
    TeamManagement:string;
    CustomerBaner:boolean;
    WebView:boolean;
    CartridgeMeet:Number;
    Statistics:string;
    train:string



    LoadPremium(preObj){

        this.CalendarTime=preObj.CalendarTime;
        this.History=preObj.History;
        this.HistoryOption=preObj.HistoryOption;
        this.Reminders=preObj.Reminders;
        this.UserData=preObj.UserData;
        this.TeamManagement=preObj.TeamManagement;
        this.CustomerBaner=preObj.CustomerBaner;
        this.WebView=preObj.WebView; 
        this.CartridgeMeet=preObj.CartridgeMeet;
        this.Statistics=preObj.Statistics;

    }



    basicUsege(){
    this.CalendarTime=30;
    this.History=false;
    this.HistoryOption={};
    this.Reminders=false;
    this.UserData="Basic";
    this.TeamManagement="false";
    this.CustomerBaner=false;
    this.WebView=false;
    this.CartridgeMeet=10;
    this.Statistics="Basic";
    this.train="close";
    }
   
  exportPremium(){
        return {
            CalendarTime:120,
            History:true,
            HistoryOption:{},
            Reminders:true,
            UserData:"premium",
            TeamManagement:"premium",
            CustomerBaner:"premium",
            WebView:true,
            CartridgeMeet:40,
            Statistics:"premium",
            train:"premium",
            cancelation:true,
            status: 'premium',
            repeat:'premium',
            zoom:'premium',
            cancelmeetok:'premium'
        }
    }
    exportBasic(){
        return {
            CalendarTime: 31,
            History: false,
            HistoryOption: {},
            Reminders: false,
            UserData: "Basic",
            TeamManagement: "false",
            CustomerBaner: false,
            WebView: false,
            CartridgeMeet: 3,
            Statistics: "Basic",
            status: 'Basic',
            repeat: 'Basic',
            zoom: 'Basic',
            train: "Basic",
            cancelmeetok: "Basic"
        }
    }
    

}


 