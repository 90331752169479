import { ShopService } from './../../ShopService/shop.service';
import { Component, OnInit,ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalPage } from '../modal/modal.page';
import { Searchbar } from 'ionic-angular';

@Component({
  selector: 'app-buissnes-page',
  templateUrl: './buissnes-page.component.html',
  styleUrls: ['./buissnes-page.component.scss'],
})
export class BuissnesPageComponent implements OnInit {
  buisness:any;
  buisness2:any;
  serachInput='';
  searchText="";
  @ViewChild('searchBar',{static: false}) myInput ;
  constructor(
    private shopS:ShopService,
    private modalController:ModalController
  ) { }



  ionViewDidEnter(){
    setTimeout(() => {
      console.log('setFocus',this.myInput);
      this.myInput.setFocus();
    },150);

  }

  ngOnInit() {


    var getAllShops=this.shopS.getAllShops().subscribe(res=>{
     var allbis:any=res;
     var nb=[];
      console.log(res)
      allbis.forEach(element => {
        if(element.Setting){

        
        if(element.Setting.searchByText == false){

        }else{
          nb.push(element)
        }
      }
      });
      this.buisness=nb;
getAllShops.unsubscribe();
    })

  
  }
  dismissModal() {
    if (this.modalController) {
      this.modalController.dismiss({
        'dismissed': false
      }).then(() => { this.modalController = null; });
    }
  }
  dismissModalSelected(x) {
    if (this.modalController) {
      this.modalController.dismiss({
        'dismissed':x
      }).then(() => { this.modalController = null; });
    }
  }
  searc(){
    this.buisness2=[];
   

var self=this;
if(this.searchText.length > 2){
let getAllShopsStartAT= this.shopS.getAllShopsStartAT().orderByChild("name").startAt(this.searchText).endAt(this.searchText+"\uf8ff")
.once('value').then(res=>{

   self.buisness2 = self.shopS.json2array(res.val())

 })
}
 console.log(this.buisness2);
  return 
    if(this.searchText.length > 2){
    for(var i =0 ;i<this.buisness.length;i++){
  
      if(this.buisness[i].Setting){

        if(this.buisness[i].Setting.name){
        if(this.buisness[i].Setting.name.includes(this.searchText)){
          this.buisness2.push(this.buisness[i]);
          
        }
      }}
    }
  }



  }
  onInputValid(){
    this.buisness2=[]

var self=this;

for(var i =0 ;i<this.buisness.length;i++){

  if(this.buisness[i].Setting.name){


  if(this.buisness[i].Setting.name){
  if(this.buisness[i].Setting.name.includes(self.serachInput)){
    self.buisness2.push(this.buisness[i])
  }
}
}}

    // this.buisness2=this.buisness.find(this.isName(self))
  }
  isName(x,self) { 
    console.log(x,self.serachInput)
    return x.Setting.name.includes(this.serachInput);
  }
}

