import { Component, OnInit } from '@angular/core';
import { WalletServiceService } from '../wallet-service/wallet-service.service';
import { ShopService } from '../../../ShopService/shop.service';
import { LoadingService } from '../../../providers/loading.service';
import { PopoverController } from '@ionic/angular';
import { WalletDocumentSendPopoverComponent } from '../wallet-document-send-popover/wallet-document-send-popover.component';

@Component({
  selector: 'app-wallet-document-send',
  templateUrl: './wallet-document-send.component.html',
  styleUrls: ['./wallet-document-send.component.scss'],
})
export class WalletDocumentSendComponent implements OnInit {

  constructor(
    public shopS: ShopService,
    public walletS: WalletServiceService,
    public popoverCtrl: PopoverController,
    private loadingService: LoadingService,
  ) { }

  ngOnInit() {}


  typeSetting;
  currentImg = {
    Social_Id: '', 
    Bank_Account: '',
    Corporate_Certificate: '',
    Processing_Agreement: ''
  };
  message;
  myFiles = {
    Social_Id: '',
    Bank_Account: '',
    Corporate_Certificate: '',
    Processing_Agreement: ''

  };


  isPopoverOpen = {
    Social_Id: false,
    Bank_Account: false,
    Corporate_Certificate: false,
    Processing_Agreement: false
  }


 async click_on_upload_doc(type,ev?){
      // ev.stopPropagation();
    console.log('type',type)
    console.log('.documents[type]',this.shopS.shopData.Wallet.documents[type])
    if(this.shopS.shopData.Wallet.documents[type] && !this.walletS.current_document_popover){
      if(!this.walletS.current_seller.seller_approved){

     
      this.walletS.current_document = type;
      const popover = await this.popoverCtrl.create({
        component: WalletDocumentSendPopoverComponent,
        cssClass: 'my-custom-class',
        event: ev,
        translucent: true,
        mode: 'md',
      });
      popover.onDidDismiss().then(() => {
        console.log('popover.onDidDismiss');
        this.walletS.current_document_popover = false;
      })
      await popover.present();
    }else{
       window.open(this.shopS.shopData.Wallet.documents[type].url, '_blank');
    }
     

    }else{
      document.getElementById(type).click();
    }
  }
  
  preview(event, where) {
    this.typeSetting = where;
    this.currentImg[where] = event;
    console.log('this.currentImg:', this.currentImg);
    var filess = event.srcElement.files;
    // console.log('filess:',filess);

    if (filess.length === 0)
      return;

    var mimeType = filess[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(filess[0]);
    reader.onload = (_event) => {
      console.log('reader.result', reader.result);
      console.log('this.mySetting', this.myFiles);
      this.myFiles[where] = reader.result;
      this.shopS.UploadFlags[where] = true;
      this.uploadThatImg(where);
    }
  }




  uploadThatImg(type) {
    console.log('uploadThatImg', this.currentImg, this.typeSetting);
    this.loadingService.presentforever();
    console.log(type);

    console.log(this.currentImg[type]);

    let result = this.shopS.uploadFile(this.currentImg[type], '/Wallet/documents/', type).then(resullt => {
      // this.loadingService.dismiss();
      // console.log('resullt',resullt);
      this.shopS.shopData.Wallet['files_submit'] = false;
      this.walletS.afDb.object('/BarberShop/' + this.shopS.shopData.Setting.BarberID + '/Wallet').update({ files_submit: false });

    }).catch((e) => {
      this.walletS.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');

      this.loadingService.dismiss();
    });
    console.log('result:', result);
  }





  send_documents() {
    // console.log(this.shopS.shopData.Wallet.documents);
    // console.log(this.shopS.json2array(this.shopS.shopData.Wallet.documents));
    let data_to_send = {
      seller_id: this.walletS.shopS.shopData.Setting.BarberID,
      seller_files: this.shopS.json2array(this.shopS.shopData.Wallet.documents)
    };
    console.log(data_to_send);
    this.walletS.LoadingService.presentforever();
    this.walletS.send_to_api('upload-seller-files', data_to_send, value => {
      console.log('upload-seller-files', value)
      value = value.res;
      // this.sales
      if (value['status_code'] == 0) {
        this.walletS.LoadingService.dismiss();
        this.walletS.ToastService.showToast('המסמכים נשלחו בהצלחה');
        this.shopS.shopData.Wallet['files_submit'] = true;
        this.walletS.afDb.object('/BarberShop/' + this.shopS.shopData.Setting.BarberID + '/Wallet').update({ files_submit: true });
        this.walletS.current_toast_message.toast_data = JSON.parse(JSON.stringify(this.walletS.toast_message.toast_ongoing_data))
        this.walletS.current_toast_message.toast_data_inline = JSON.parse(JSON.stringify(this.walletS.toast_message.toast_ongoing_data_inline))

        this.shopS.shopData.Wallet.documents
        let self = this;
        var keys = Object.keys(this.shopS.shopData.Wallet.documents);
        keys.forEach(function (key) {
          self.shopS.shopData.Wallet.documents[key]['doc_status'] = 'sent';
          self.walletS.afDb.object('/BarberShop/' + self.shopS.shopData.Setting.BarberID + '/Wallet/documents/'+key).update({ doc_status:  'sent' });
        });
  
      



        // ok
        // this.subscriptions = val.items;
      } else {
        this.walletS.LoadingService.dismiss();
        this.walletS.ToastService.showTostDanger('משהו השתבש נסו שוב מאוחר יותר');

      }
    });

  }
}
