import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { GoogleCalendarPageRoutingModule } from './google-calendar-routing.module';
import { NgCalendarModule } from 'ionic2-calendar';
import { GoogleApiModule, NgGapiClientConfig, NG_GAPI_CONFIG } from "ng-gapi";
import { HttpClientModule } from '@angular/common/http';

import { GoogleCalendarPage } from './google-calendar.page';
import { GoogleCalendarService } from './google-calendar-service';
// import { BuisIndexComponent } from '../buis-index/buis-index.component';
// import { SheetResource } from './SheetResource';
import { MainModule } from '../main/main.module';

// import { AgmCoreModule } from '@agm/core';
// import { GooglemapsComponent } from '../googlemaps/googlemaps.component';
// import { UserAddressComponent } from '../user-address/user-address.component';
// import { BuisProfileComponent } from '../buis-profile/buis-profile.component';


let gapiClientConfig: NgGapiClientConfig = {
    client_id: '353723050664-2g0ia6bld3jrn4gbfea2l6tlhsq4huao.apps.googleusercontent.com',
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    // ux_mode: "redirect",
    // redirect_uri: "https://http://localhost:8100",
    scope: [
        'https://www.googleapis.com/auth/calendar',
        'https://www.googleapis.com/auth/calendar.events'
    ].join(" ")
};

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgCalendarModule,
        IonicModule,
        GoogleCalendarPageRoutingModule,
        HttpClientModule,
        GoogleApiModule.forRoot({
            provide: NG_GAPI_CONFIG,
            useValue: gapiClientConfig
        }),
        // AgmCoreModule.forRoot({
        //     // apiKey:"AIzaSyC51cc96KCUJWHCjal_9ZC740MFL2c4e2A"
        //     apiKey: "AIzaSyDJYGsppHU_r_BjvfYFw-lwaQsbPqVV2zw",
        //     // libraries: ['places']
        // })
        MainModule,
    ],
    declarations: [
        GoogleCalendarPage,
        // BuisIndexComponent,
        // GooglemapsComponent,
        // UserAddressComponent
    ],
    bootstrap: [GoogleCalendarPage],
    providers: [GoogleCalendarService
        // , SheetResource
    ],
    // exports: [GooglemapsComponent]
})

export class GoogleCalendarPageModule { }
