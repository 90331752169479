

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {Platform } from '@ionic/angular';

// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';

// import { File } from '@ionic-native/file/ngx';
// import { FileOpener } from '@ionic-native/file-opener/ngx';
//import { Ionic4DatepickerModalComponent } from 'ionic4-datepicker';
import { ModalController } from '@ionic/angular';
import { UserSearchModalComponent } from 'src/app/user-search-modal/user-search-modal.component';
import { ServiceSearchModalComponent } from "src/app/service-search-modal/service-search-modal.component";

import { InvoiceServiceService } from "../invoice-service/invoice-service.service";

import { InvoiceTemplateService } from "../invoice-template-service/invoice-template.service";


import { AlertExample } from 'src/providers/alert.service';

import { ShopService } from "src/ShopService/shop.service";

// import * as jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';

import { InvoicePopupComponent } from "../invoice-popup/invoice-popup.component";
import { InvoiceMigrateService } from '../invoice-migrate/invoice-migrate.service';
@Component({
  selector: 'app-invoice-new-document',
  templateUrl: './invoice-new-document.component.html',
  styleUrls: ['./invoice-new-document.component.scss'],
})
export class InvoiceNewDocumentComponent implements OnInit {


  @ViewChild('invoice',{static:false}) invoice: ElementRef;
  constructor(public invoiceS:InvoiceServiceService, private plt: Platform, 
    // private file: File, private fileOpener: FileOpener,
        public modalCtrl: ModalController,
    public templateS:InvoiceTemplateService,
    private AlertExample:AlertExample,
    public shopS:ShopService,
    public invoiceM: InvoiceMigrateService
    
    ) { }


    async alert_new_document_confirm() {
      console.log(this.invoiceS.invoiceObj);
      
      const alert = await this.AlertExample.alertController.create({
        header: '?בטוחים שתרצו לבטל',
        message: 'תמיד אפשר לשמור כטיוטה',
        buttons: [
          {
            text: 'אני מתחרט',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('save draft');
              
            }
          }, {
            text: ' הפקת '+this.invoiceS.invoiceObj.document_counter.name,
            
            handler: () => {
             this.new_invoice()
            }
          }
        ]
      });
  
      await alert.present();
    }




    async alert_exit_or_draft() {
      const alert = await this.AlertExample.alertController.create({
        header: '?בטוחים שתרצו לבטל',
        message: 'תמיד אפשר לשמור כטיוטה',
        buttons: [
          // {
          //   text: 'שמירת טיוטה',
          //   role: 'cancel',
          //   cssClass: 'secondary',
          //   handler: (blah) => {
          //     console.log('save draft');
          //     this.save_invoice_draft()
          //   }
          // },
           {
            text: 'ביטול המסמך',
            
            handler: () => {
             console.log('quit');
             this.dismissModal()
            }
          }
        ]
      });
  
      await alert.present();
    }
  



    dismissModal() {
      if (this.modalCtrl) {
        this.modalCtrl.dismiss({
          'dismissed': false
        }).then(() => { this.modalCtrl = null; });
      }
    }


    selectedType(c){
      console.log('cc',c);
      this.invoiceS.invoiceObj.document_counter = c;
    }



    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [day, month, year].join('.');
  }
  


    VAT_amount = 0.17;
    // datePickerObj = {
    //   dateFormat: 'YYYY-MM-DD'
    // };
    selectedDate;

  datePickerObj: any = {
   // inputDate: this.formatDate(new Date()), // default new Date()
    fromDate: new Date(), // default null
   // toDate: '22.07.2020', // default null
    showTodayButton: true, // default true
    closeOnSelect: true, // default false
 //   disableWeekDays: [4], // default []
    mondayFirst: false, // default false
    setLabel: 'בחירה',  // default 'Set'
    todayLabel: 'היום', // default 'Today'
    closeLabel: 'סגור', // default 'Close'
    disabledDates: '', // default []
    titleLabel: 'בחירת תאריך', // default null
    monthsList: ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"],
    weeksList: ["א׳", "ב׳", "ג׳", "ד׳", "ה׳", "ו׳", "ש׳"],
    dateFormat: 'DD.MM.YYYY', // default DD MMM YYYY
    clearButton : false , // default true
    momentLocale: 'iw-IL', // Default 'en-US'
    yearInAscending: true, // Default false
    btnCloseSetInReverse: true, // Default false
    btnProperties: {
      expand: 'block', // Default 'block'
      fill: '', // Default 'solid'
      size: '', // Default 'default'
      disabled: '', // Default false
      strong: '', // Default false
      color: '' // Default ''
    },
    arrowNextPrev: {
      nextArrowSrc: 'assets/images/arrow_right.svg',
      prevArrowSrc: 'assets/images/arrow_left.svg'
    }, // This object supports only SVG files.
    highlightedDates: [
    ], // Default [],
    // isSundayHighlighted : {
    //  fontColor: '#ee88bf' // Default null
    // } // Default {}
  };
  


















  
async open_inoice_popup(){

  this.invoiceS.modal_inoice_popup = await this.modalCtrl.create({
    component: InvoicePopupComponent,
    swipeToClose: false,
    cssClass: 'popupModal',
    backdropDismiss:true,
    mode:"ios",
    componentProps: {
      'firstName': 'Douglas',
      'lastName': 'Adams',
      'middleInitial': 'N'
    }
  });

  
  await this.invoiceS.modal_inoice_popup.present();
  this.invoiceS.modal_inoice_popup.onDidDismiss().then(res=>{
    console.log(res);
    this.invoiceS.document_succsess_popup = false;
    this.invoiceS.invoice_loader = true;
    
    if(res.data){

  }
  })
  return this.invoiceS.modal_inoice_popup;
}







// downloadPDF(){
//   let doc = new jsPDF();

//   let specialElementHamdler = {
//     '#editor': function(element,renderer){
//       return true
//     }
//   };

//   //autoTable(doc, { html: '#tab_customers' })

//   let invoicer = this.invoice.nativeElement;
//   console.log(invoicer.innerHTML)
  
//   doc.fromHTML(invoicer.innerHTML,0,0,{
//     'width': 100,
//     'elementHandlers': specialElementHamdler
//   }, function (dispose) {
//     doc.save('thisMotion.pdf');
//     });

//  // doc.save('name.pdf')

// }



// invoiceObj = {
//   date: {
//     date_of_issue : '',
//     date_to_pay: '',
//     date_of_creation: ''
//   },
//   lang: 'he',
//   currency: '₪',
//   client: {
//     name: '',
//     client_id: '',
//     client_email: ''
//   },
//   discription: '',
//   services: [],
//   notes: '',
//   footer_note: '',
// }


  letterObj = {
    to: '',
    from: '',
    text: ''
  }

  //pdfObj = null;

   
 



  // async createPdf(data) {

  //   let current_doc_template:any = this.templateS.getTemplate(data);

  //   current_doc_template.defaultStyle = {
  //     font: 'Rubik',
  //     alignment: 'right',
  //   }

  //   pdfMake.fonts = {
  //     Roboto: {
  //       normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
  //       bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
  //       italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
  //       bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  //     },
  //     Rubik: {
  //       normal: 'https://cdn.jsdelivr.net/npm/rubik-font@0.0.3/fonts/Rubik-Regular.ttf',
  //       bold: 'https://cdn.jsdelivr.net/npm/rubik-font@0.0.3/fonts/Rubik-Bold.ttf',
  //       italics: 'https://cdn.jsdelivr.net/npm/rubik-font@0.0.3/fonts/Rubik-Italic.ttf',
  //       bolditalics: 'https://cdn.jsdelivr.net/npm/rubik-font@0.0.3/fonts/Rubik-BoldItalic.ttf'

  //     },
      
  //   }
  //   let pdfObj = pdfMake.createPdf(current_doc_template);
  //   if (this.plt.is('cordova')) {
  //     this.pdfObj.getBuffer((buffer) => {
  //       var blob = new Blob([buffer], { type: 'application/pdf' });

  //       // Save the PDF to the data Directory of our App
  //       this.file.writeFile(this.file.dataDirectory, 'myletter.pdf', blob, { replace: true }).then(fileEntry => {
  //         // Open the PDf with the correct OS tools
  //         this.fileOpener.open(this.file.dataDirectory + 'myletter.pdf', 'application/pdf');
  //       })
  //     });
  //   } else {
  //     // On a browser simply use download!
  //     pdfObj.download();
  //   }
  // }

  // downloadPdf() {

  //   if (this.plt.is('cordova')) {
  //     this.pdfObj.getBuffer((buffer) => {
  //       var blob = new Blob([buffer], { type: 'application/pdf' });

  //       // Save the PDF to the data Directory of our App
  //       this.file.writeFile(this.file.dataDirectory, 'myletter.pdf', blob, { replace: true }).then(fileEntry => {
  //         // Open the PDf with the correct OS tools
  //         this.fileOpener.open(this.file.dataDirectory + 'myletter.pdf', 'application/pdf');
  //       })
  //     });
  //   } else {
  //     // On a browser simply use download!
  //     this.pdfObj.download();
  //   }
  // }







  async  createFile(fileUrl){


    fetch(fileUrl)
    .then(res => res.blob()) // Gets the response and returns it as a blob
    .then(blob => {
      // Here's where you get access to the blob
      // And you can use it for whatever you want
      // Like calling ref().put(blob)
  
      // Here, I use it to make an image appear on the page
      let objectURL = URL.createObjectURL(blob);
      let myImage = new Image();
      myImage.src = objectURL;
      return myImage;
  });
  

    // let response = await fetch(fileUrl);
    // let data = await response.blob();
    // let metadata = {
    //   type: 'image/jpeg'
    // };
    // return new File([data], "test.jpg", metadata);
    // // ... do something with the file or return it
  }
  
  async getFile(url){
    let blob = await fetch(url).then(r => r.blob());
    return blob;
  }

  ngOnInit() {

   // console.log('invoiceObj',this.invoiceS.invoiceObj)

    this.invoiceS.invoiceObj.date.date_of_issue = this.formatDate(new Date());
    this.invoiceS.invoiceObj.date.date_to_pay =  this.formatDate(new Date());


    this.create_quantitly_Array();
    if(this.invoiceS.invoiceObj.services.length > 0){
      this.set_totals();
    }
  //  var logo =  this.createFile(this.invoiceData.company.logo)
  //var d =  this.getFile(this.invoiceData.company.logo);
  //var d =  this.getFile('https://mldb3is0vfpx.i.optimole.com/w:220/h:123/q:auto/https://webc.co.il/wp-content/uploads/2019/04/whiteLogo-510x298-1.png');
  
    //  console.log('logo',logo)
  //  console.log('d',d)



    //var logo = new File(this.invoiceData.company.logo);
  //  var reader = new FileReader();
   // reader.readAsDataURL(logo); 
   // reader.onload = (_event) => { 
   //   console.log('reader.result',reader.result);
   // };  

  }








////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
totalVAT:any = 0;
totalPrice:any = 0;
changed(c,type,event){
  console.log(c,event)
  if(type == 'price'){
    c.price = parseInt(event);
  }
  if(type == 'name'){
    c.name = event;
  }
  this.set_totals();

}




set_totals(){
  this.totalAmount =  this.invoiceS.invoiceObj.services.map(item => (item.price*item.quantity)).reduce((prev, next) => prev + parseInt(next) );


this.totalPrice = this.totalAmount - (this.totalAmount * this.VAT_amount);
this.totalVAT = this.totalAmount - this.totalPrice;

}



 async prepare_Obj(){
  
  this.invoiceS.invoiceObj['total_amount'] = this.totalAmount ;
  this.invoiceS.invoiceObj['total_vat'] = this.totalVAT;
  this.invoiceS.invoiceObj['total_price'] = this.totalPrice;
 
  this.invoiceS.invoiceObj['document_counter_type_key'] = this.invoiceS.invoiceObj.document_counter.key;

  this.invoiceS.invoiceObj.document_counter.counter = this.invoiceS.invoiceObj.document_counter.counter + 1;


  this.invoiceS.invoiceObj['date_of_issue'] = new Date( this.invoiceS.to_valid_date(this.invoiceS.invoiceObj.date.date_of_issue) ).getTime();

  this.invoiceS.invoiceObj['date_to_pay'] = new Date( this.invoiceS.to_valid_date(this.invoiceS.invoiceObj.date.date_to_pay) ).getTime();

  this.invoiceS.invoiceObj['date_of_creation'] =  new Date().getTime();

 

  console.log('this.invoiceS.invoiceObj',this.invoiceS.invoiceObj)
  return true;  

  }






  new_invoice(){

    this.prepare_Obj().then(res => {
      // save that 
      // console.log('now',this.invoiceS.invoiceObj)

      // this.invoiceS.document_add(this.invoiceS.invoiceObj);

      // this.update_document_counting_num();

      // this.invoiceS.invoiceObj.settings = this.invoiceS.userSettings;
      this.invoiceM.new_doc(this.shopS.shopData.Setting.invoice_supplier,this.invoiceS.invoiceObj,cb=>{
        console.log('this.invoiceM.new_doc',cb);
        console.log('this.invoiceS.invoiceObj',this.invoiceS.invoiceObj);
        this.invoiceS.create_new_doc(cb);
        this.open_inoice_popup();

      })
     
    })

   
    
  }


// update_document_counting_num(){
//   this.invoiceS.document_count[this.invoiceS.invoiceObj.document_counter.key].counter = this.invoiceS.invoiceObj.document_counter.counter;
//   console.log('this.invoiceS.document_count',this.invoiceS.document_count);

//   if(!this.invoiceS.document_count[this.invoiceS.invoiceObj.document_counter.key].didhappen){
   
//     this.invoiceS.document_count[this.invoiceS.invoiceObj.document_counter.key].didhappen = true;
//   }

// this.invoiceS.document_counting_update(this.invoiceS.document_count[this.invoiceS.invoiceObj.document_counter.key])
  

// }


  save_invoice_draft(){

    // save that 
    this.invoiceS.document_add_draft(this.invoiceS.invoiceObj);

    this.invoiceS.invoiceObj.settings = this.invoiceS.userSettings;

    console.log('this.invoiceS.invoiceObj',this.invoiceS.invoiceObj);

    //this.createPdf(this.invoiceS.invoiceObj);
    

  }











////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////






// UserSelected:any = {
//   fname:'',
//   lname: '',
//   name:''
// };



  async openUsersModal(){
    
    var modal = await this.modalCtrl.create({
      component: UserSearchModalComponent,
      swipeToClose: false,
      cssClass: 'modalLookLikeNativMoredown',
      backdropDismiss:true,
      mode:"ios"

    //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal

      
     // Get the top-most ion-modal

    });
  
    

    await modal.present();
    modal.onDidDismiss().then(res=>{
      console.log(res);
      if(res.data){
        res.data['meetings'] = [];
        this.invoiceS.invoiceObj.client = res.data;
     // this.UserSelected=res.data;
    }
    })
    return modal;
}








////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////




currentServices:any = [];

async openServiceModal(){
    
  var modal = await this.modalCtrl.create({
    component: ServiceSearchModalComponent,
    swipeToClose: true,
    cssClass: 'modalLookLikeNativMoredown',
    backdropDismiss:true,
    mode:"ios"

  //   presentingElement: await this.modalController.getTop() // Get the top-most ion-modal

    
   // Get the top-most ion-modal

  });

  

  await modal.present();
  modal.onDidDismiss().then(res=>{
    console.log(res);
    if(res.data){
      this.create_quantitly_Array();

      res.data['quantity'] = 1;
      this.invoiceS.invoiceObj.services.push(res.data);
      this.set_totals();
  
  }
  })
  return modal;
}

add_empty_service(){
  this.create_quantitly_Array();
  this.invoiceS.invoiceObj.services.push({id:this.shopS.newGuid(),name: '',price: null,quantity:1});
  console.log('this.invoiceS.invoiceObj',this.invoiceS.invoiceObj)
}
totalAmount;





quantity_Array = []

create_quantitly_Array(){
  if(this.quantity_Array.length == 0){
    for (var i = 0; i < 100; i++) {
      this.quantity_Array[i] = i + 1; //This populates the array.  +1 is necessary because arrays are 0 index based and you want to store 1-100 in it, NOT 0-99.
  }
  console.log('this.quantity_Array',this.quantity_Array)

  }
}




remove_service_from_list(s){
  console.log('s',s)

 let index =  this.invoiceS.invoiceObj.services.findIndex(x => x.id ==s.id);

 this.invoiceS.invoiceObj.services.splice(index,1);

 // console.log('id',id)
}




async presentAlertConfirm(s) {

  const alert = await this.AlertExample.alertController.create({
    header: 'מחיקת שורה',
    message: 'בטוחים שתרצו למחוק ?',
    buttons: [
      {
        text: 'לא',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {
          console.log('Confirm Cancel: blah');
        }
      }, {
        text: 'כן בטח!',
        
        handler: () => {
          this.remove_service_from_list(s)

        }
      }
    ]
  });
  await alert.present();


}







}
