// import { ToastController } from '@ionic/angular';
import { Observable } from 'rxjs-compat';
import { OrderButtonComponent } from './../components/order-button/order-button.component';
// import { AddBusinessPage } from './../../src2/pages/addBusiness/addBusiness.page';
import { LoadingService } from 'src/providers/loading.service';

// import { IonRouterOutlet } from '@ionic/angular';
// import { LocalStorageService } from './../providers/local-storage.service';
// import { formatDate } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Platform, NavController, MenuController, AlertController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Router, Event, NavigationEnd } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ChangeUserModal } from '../modals/changeUser/changeUser.modal';

import { AboutModalComponent } from "../app/about-modal/about-modal.component";
// import { SplashScreenComponent } from './splash-screen/splash-screen.component';
// import { ContactModalComponent } from "../components/contact-modal/contact-modal.component";
import { ConfigService } from "./config.service";
// import { AppsflyerService } from "./appsflyer.service";
import { environment } from '../environments/environment';
import { Network } from '@ionic-native/network/ngx';


import { NetworkStatusAngularService } from 'network-status-angular';



import { userService } from '../ShopService/user.service';
import { ShopService } from '../ShopService/shop.service';

// SERVICES
import { AuthService } from '../providers/auth-service';
import { ConnectionService } from 'ng-connection-service';


import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';

import { BehaviorSubject } from 'rxjs';
// import { analytics } from 'firebase';

import { PremiumService } from './premium/premium-service/premium.service';
import { filter } from 'rxjs/operators';
export enum ConnectionStatusEnum {
  Online,
  Offline
}
@Component({
  selector: 'app-root',
  templateUrl: 'app.html'

})
export class MyApp implements OnInit, OnDestroy {

  private status: BehaviorSubject<ConnectionStatusEnum> = new BehaviorSubject(ConnectionStatusEnum.Offline);


  connectionSatus = true;
  isConnected = true;
  noInternetConnection: boolean;

  @ViewChild(NavController, { static: false }) nav: NavController;
  //  @ViewChild(NavController) nav: NavController;

  showTopBar = false;
  BuissData: any;
  rootPage;
  wow;
  UserImg;
  disconnectSubscription;
  connectSubscription;
  uID;
  shopSub;
  LoaderFlag = true;
  exportWithConsole = false;
  starsCount;
  networkAlert;
  shopId;
  ProfilePic;
  login;
  logOutStatus = false;
  consoleShow = false;
  inital_connectionSatus = false;
  userData;
  localUserObject;
  firebase;
  pages: Array<{ title: string, component: any }>;
  isIphone = false;
  showWebAlert = true;

  constructor(
    //  public routerOutlet:IonRouterOutlet,

    private platform: Platform,
    public us: userService,
    private router: Router,
    // public AppsflyerService: AppsflyerService,
    private orderButtonComponent: OrderButtonComponent,
    private LoadingService: LoadingService,
    public shopS: ShopService,
    public authService: AuthService,

    private premiumService: PremiumService,
    private modalController: ModalController,
    private ConfigService: ConfigService,
    private MenuController: MenuController,
    public authState: AuthService,
    private alertCtrl: AlertController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private networkStatusAngularService: NetworkStatusAngularService,
    public network: Network,


    private oneSignal: OneSignal,
    private connectionService: ConnectionService,

  ) {



    console.log('Hello NetworkProvider Provider');
    console.log('navigator.onLine', navigator.onLine);
    this.connectionSatus = navigator.onLine;
    this.inital_connectionSatus = navigator.onLine;
    // if(!navigator.onLine){
    //   this.connectionSatus=true;
    // }
    this.previousStatus = ConnectionStatusEnum.Online;
    let self = this;
    this.networkStatusAngularService.status.subscribe(status => {
      if (status == false) {

        this.connectionSatus = false;
      } else {
        this.shopS.http.get(this.shopS.serverDev + '/checkServerConnection').subscribe((response: any) => {
          if (!self.inital_connectionSatus) {
            setTimeout(() => {

              window.location.reload();
            }, 8000)
          } else {

            self.connectionSatus = true;
          }
          console.log('response!!!!!', response);
        }
          , (err: any) => {

            self.connectionSatus = false;
            console.log('response err!!!!!', err);

          });

      }
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!" + status); // returns true if it is online or false if it is offline
    });

    // stop connect watch
    // connectSubscription.unsubscribe();



    platform.ready().then((x) => {



      this.initializeNetworkEvents();
      let status = this.network.type !== 'none' ? ConnectionStatusEnum.Online : ConnectionStatusEnum.Offline;
      this.status.next(status);


      this.connectionService.monitor().subscribe(isConnected => {
        this.isConnected = isConnected;
        if (this.isConnected) {
          this.noInternetConnection = false;
        }
        else {
          this.shopS.http.get(this.shopS.serverDev + '/checkServerConnection').subscribe((response: any) => {
            if (!self.inital_connectionSatus) {
              setTimeout(() => {

                window.location.reload();
              }, 8000)
            } else {

              self.noInternetConnection = true;
            }
            console.log('response!!!!!', response);
          }
            , (err: any) => {

              self.noInternetConnection = false;
              console.log('response err!!!!!', err);

            });
          // this.noInternetConnection = true;
        }

      }, (error) => { console.log(error); });


      console.log('Hello NetworkProvider Provider');

      this.previousStatus = ConnectionStatusEnum.Online;

      this.networkStatusAngularService.status.subscribe(status => {
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!" + status); // returns true if it is online or false if it is offline
      });

      this.statusBar.styleDefault();

      setTimeout(() => {
        this.statusBar.overlaysWebView(true);
      }, 500);
      // console.log("public platform: Platform ",x)
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      this.isIphone = true;
      this.ConfigService.getTemplate();


      this.initializeApp()
      let plat = this.platform.platforms();
      console.log('plat', plat)

      this.checkPlatform();
      // this.fnBrowserDetect();

      console.log('this.shopS.platform', this.shopS.platform)
      this.routerSub();

      // end of back button navigation
      this.localUserObject = this.us.getUserObj();

    });









  }


  public initializeNetworkEvents() {

    // this.network.onDisconnect().subscribe(() => {
    //   if (this.status.getValue() === ConnectionStatusEnum.Online) {
    //     console.log('WE ARE OFFLINE');
    //     this.updateNetworkStatus(ConnectionStatusEnum.Offline);
    //   }
    // });

    this.network.onConnect().subscribe(() => {
      if (this.status.getValue() === ConnectionStatusEnum.Offline) {
        console.log('WE ARE ONLINE');
        this.updateNetworkStatus(ConnectionStatusEnum.Online);
      }
    });
  }


  loadingwifi() {

    this.LoadingService.present_for(2500);
  }


  private async updateNetworkStatus(status: ConnectionStatusEnum) {
    this.status.next(status);

    let connection = status == ConnectionStatusEnum.Offline ? 'Offline' : 'Online';
    // let toast = this.ToastController.create({
    //   message: `You are now ${connection}`,
    //   duration: 3000,
    //   position: 'bottom'
    // });
    // toast.then(toast => toast.present());


    // alert(`You are now ${connection}`)
  }

  public onNetworkChange(): Observable<ConnectionStatusEnum> {
    return this.status.asObservable();
  }

  public getCurrentNetworkStatus(): ConnectionStatusEnum {
    return this.status.getValue();
  }

  previousStatus;
  initializeApp(): void {
    /* Check networkStatus */
    let self = this;
    this.set_user_ver()
    this.checkInternetConnection();

    this.network.onDisconnect().subscribe(() => {
      if (this.previousStatus === ConnectionStatusEnum.Online) {

        console.log('network was disconnected :-(');
        // this.showAlertNoInternet("no internt", "you are offline", "");

        //this.eventCtrl.publish('network:offline');
        // self.networkShow = true;
        // document.getElementById("internetCon").classList.add("intenetShow");
        console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")

      }


      this.previousStatus = ConnectionStatusEnum.Offline;
    });
    this.network.onConnect().subscribe(() => {

      // this.showAlertNoInternet("internt", "you areeeeeeee connected", "");
      if (this.previousStatus === ConnectionStatusEnum.Offline) {
        //  this.eventCtrl.publish('network:online');
        // self.networkShow = false;
        // document.getElementById("internetCon").classList.remove("intenetShow");

      }

      console.log("++++++++++++0000000+yessssssss+++++++++++++++++++++++")

      this.previousStatus = ConnectionStatusEnum.Online;
    });



  }

  reRender() { }

  checkInternetConnection() {
    this.disconnectSubscription = this.network.onDisconnect().subscribe(async () => {
      console.log('network was disconnected :-(');
      // this.networkAlert = await this.util.createAlert('No Internet',     false, 'Please Check you internet Connection and try again',{
      // text: '',
      // role: '',
      // cssClass: 'secondary',
      // handler: async () => {}
      // });
      // this.networkAlert.present();
    });
    this.connectSubscription = this.network.onConnect().subscribe(() => {
      console.log("sadsadsdsad")
      // console.log('network connected!');
      // if(this.networkAlert) {
      // this.networkAlert.dismiss();
      // this.checkUser();
      // }
    });
  }
  registerBackButton() {
    // start of back button navigation

    this.platform.backButton.subscribeWithPriority(9999, () => {
      let view = this.router.url;
      //  console.log('this.platform.backButton.subscribeWithPriority Preesed!!!',view);

      switch (view) {
        case '/socialLogin':
          //  console.log('switch Preesed!!!',view);

          this.router.navigate(['login'], { replaceUrl: true });
          break;
        // case '/login':
        //   //   console.log('switch Preesed!!!',view);

        //   this.router.navigate(['socialLogin'], { replaceUrl: true });
        //   break;
        case '/Setting':
          //    console.log('switch Preesed!!!',view);

          if (this.MenuController.isOpen()) {
            this.MenuController.close(); this.shopS.menuTogglle('close')
          } else {
            this.router.navigate(['/tabs/home'], { replaceUrl: true })
          }
          break;
        case '/signUp':
          //   console.log('switch Preesed!!!',view);

          this.router.navigate(['login'], { replaceUrl: true });
          break;
        case '/addBusiness':
          //    console.log('switch Preesed!!!',view);
          this.router.navigate(['addBusiness'], { replaceUrl: true });
          break;
        case '/tabs/tabs/home': //tabs client deshboard
          //  console.log('switch Preesed!!!',view);

          this.router.navigate(['Business'], { replaceUrl: true });
          break;
        case '/tabs/home': //tabs client deshboard
          //  console.log('switch Preesed!!!',view);

          this.router.navigate(['tabs/home'], { replaceUrl: true });
          break;
        // case '/Business':
        //   //   console.log('switch Preesed!!!',view);

        //   this.router.navigate(['tabs/home'], { replaceUrl: true });
        //   break;
        case '/about':
          //   console.log('switch Preesed!!!',view);

          if (this.shopS.AdminFLAG == true) {
            this.router.navigate(['/tabsAdmin/calendar'], { replaceUrl: true })

          } else {
            this.shopS.changeButtonState(0);
            this.clearSeletion();
            this.router.navigate(['tabs/home'], { replaceUrl: true });

          }
          break;
        case '/AppointmentPage':
          //  console.log('switch Preesed!!!',view);

          // nav on Page
          break;
        case '/admin-register':
          //   console.log('switch Preesed!!!',view);
          this.router.navigate(['admin-register'], { replaceUrl: true });

          // this.us.superNew=false;
          // this.router.navigate(['addBusiness'],{replaceUrl:true});
          break;
        case '/tutorial':
          //   console.log('switch Preesed!!!',view);
          this.router.navigate(['tutorial'], { replaceUrl: true });

          //   console.log('tutorialtutorial');
          break;
        case '/tabsAdmin/clients':
          //   console.log('switch Preesed!!!',view);

          if (this.MenuController.isOpen()) {
            this.MenuController.close(); this.shopS.menuTogglle('close')
          } else {
            this.router.navigate(['tabsAdmin/calendar'], { replaceUrl: true });
          }
          break;
        case '/tabsAdmin/about':
          //    console.log('switch Preesed!!!',view);

          if (this.MenuController.isOpen()) {
            this.MenuController.close(); this.shopS.menuTogglle('close')
          } else {
            this.router.navigate(['tabsAdmin/calendar'], { replaceUrl: true });
          }
          break;
        case '/tabsAdmin/business':
          //    console.log('switch Preesed!!!',view);

          if (this.MenuController.isOpen()) {
            this.MenuController.close(); this.shopS.menuTogglle('close')
          } else {
            this.router.navigate(['tabsAdmin/calendar'], { replaceUrl: true });
          }
          break;
        case '/tabsAdmin/calendar':
          //  console.log('switch Preesed!!!',view);

          if (this.MenuController.isOpen()) {
            this.MenuController.close(); this.shopS.menuTogglle('close')
          } else {
            // this.router.navigate(['tabsAdmin/calendar'])

          }
          break;

        default:
          console.log('nothing to swich back button');
          break;
      }


      // Navigate wherever you want here
    });
  }// end of func



  set_user_ver() {
    this.authState.isconnect.subscribe(val => {
      console.log('authState.isconnect', val);
      if (val) {


        console.log('USERKEY', this.us.user)
        console.log('currentVersion', this.ConfigService.currentVersion)
        if (this.us.user.currentVersion != this.ConfigService.currentVersion) {
          console.log('UPDATE');
          this.ConfigService.shopS.update_User_currentVersion(this.ConfigService.currentVersion, this.us.user.key)

        }
      }

    })
  }



  currentEnv = 'prod';
  checkIpad() {
    if (this.platform.is('ipad')) {
      this.shopS.isIpad = true;
    }
  }
  // fnBrowserDetect() {
  //   let userAgent = window.navigator.userAgent;
  //   let browserName;

  //   if (userAgent.match(/chrome|chromium|crios/i)) {
  //     browserName = "chrome";
  //     this.shopS.isBrowser = true;
  //   } else if (userAgent.match(/firefox|fxios/i)) {
  //     browserName = "firefox";
  //     this.shopS.isBrowser = true;
  //   } else if (userAgent.match(/safari/i)) {
  //     browserName = "safari";
  //     this.shopS.isBrowser = true;
  //   } else if (userAgent.match(/opr\//i)) {
  //     browserName = "opera";
  //     this.shopS.isBrowser = true;
  //   } else if (userAgent.match(/edg/i)) {
  //     browserName = "edge";
  //     this.shopS.isBrowser = true;
  //   } else {
  //     browserName = "No browser detection";
  //     this.shopS.isBrowser = false;
  //   }
  // }

  checkPlatform() {
    if (this.platform.is('android')) {
      this.shopS.platform = 'android';
      this.shopS.mainPlatform = 'android';
      document.body.className = 'app_version android';
    } else if (this.platform.is('ios')) {
      this.shopS.platform = 'ios';
      this.shopS.mainPlatform = 'ios';
      document.body.className = 'app_version ios';
    } else {
      this.shopS.platform = 'desktop';
      this.shopS.mainPlatform = 'desktop';
      document.body.className = 'desktop_version';
      // this.shopS.desktopMenu_bol = true;
    }
    this.checkResulotion();
    this.checkIpad();
  }

  clearConsole() {
    var log = document.getElementById('console');
    log.innerHTML = '';
  }

  checkResulotion() {
    var newWidth = window.innerWidth;
    // var newHeight = window.innerHeight;
    // let ratio = newWidth / newHeight;
    // if (ratio < 0.7) {
    if (newWidth < 768) {
      var isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
      this.shopS.platform = isSafari ? 'ios' : 'android';
      document.body.className = 'app_version ' + this.shopS.platform;
    } else {
      this.shopS.platform = 'desktop';
      document.body.className = 'desktop_version';
      // this.shopS.desktopMenu_bol = true;
    }
  }

  ngOnInit() {
    let self = this;
    // if (this.currentEnv == 'prod' || true) {
    //   console['error'] = (function (method, verb, log) {
    //     if (!(verb.toString().includes('overlay does not exist') || verb.toString().includes('cordova_not_available'))) {
    //       let obj = {
    //         method: method,
    //         verb: verb,
    //         log: log
    //       }
    //       self.shopS.logWithData(self.us.user, 'error-log', obj);
    //       console['warn']('<-----*****----- ERROR!!! -----*****-----> ' + verb + ' ------ ' + method + ' ------ ' + log);
    //     }
    //   });
    // }


    //  console.log("stam loading");
    window.addEventListener('resize', () => {
      this.checkResulotion();
      this.checkIpad()
    });

    setTimeout(() => {

      this.LoaderFlag = false;

    }, 10000);


    // this.shopS.LoaderFlag = false;


    //loading improvment
    setTimeout(() => {
      this.setupPush();
      // start app flayer analitics
      // this.AppsflyerService.startAppsflayer();
      this.registerBackButton();


    }, 10000);



    // console.log(this.shopS.shopData,this.us.user);
    this.currentEnv = environment.currentEnv;

    setTimeout(() => {
      if (this.currentEnv != "prod" && this.exportWithConsole) {
        var log = document.getElementById('console');

        ['log', 'debug', 'info', 'warn', 'error'].forEach(function (verb) {


          console[verb] = (function (method, verb, log) {
            let logsCounter = 0;
            return function () {
              try {
                method.apply(console, arguments);

                var msg = document.createElement('div');
                msg.classList.add(verb);
                msg.style.cursor = 'pointer';
                msg.innerHTML = '<span style="color: red;">' + verb + '</span>' + ': ';
                let objects = [];
                for (let i = 0; i < arguments.length; i++) {
                  if (typeof (arguments[i] == 'object')) {
                    var object = document.createElement('div');
                    object.classList.add('console_logs_hide');
                    object.classList.add('console_logs');
                    object.classList.add('console_logs_' + verb + '_' + logsCounter);
                    object.innerHTML = 'object' + ': ' + JSON.stringify(arguments[i]);
                  }
                  objects.push(object);
                  msg.innerHTML += arguments[i];
                }
                if (objects.length > 0) {
                  let elementsArr = document.getElementsByClassName('console_logs_' + verb + '_' + logsCounter);
                  msg.addEventListener('click', () => {
                    for (let i = 0; i < elementsArr.length; i++) {
                      elementsArr[i].classList.toggle('console_logs_hide');
                    }
                  })
                }
                log.appendChild(msg);
                objects.forEach(object => {
                  log.appendChild(object)
                })
                logsCounter++;
              }
              catch (error) {
              }
            };
          })(console[verb], verb, log);
        });
      }
    }, 3000);

    this.shopS.changeButtonState(-1);
  }//// end of on init











  async presentModalAboutUs(): Promise<HTMLIonModalElement> {

    const modal = await this.modalController.create({
      component: AboutModalComponent,
      swipeToClose: true,
      cssClass: 'modalLookLikeNativ',
      //  presentingElement: this.routerOutlet.nativeEl,
      mode: "ios",

      // Get the top-most ion-modal

    });


    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data) {
      if (data.dismissed == "finish") {

      }
    }
    return modal;

  }















  goBackAddBussnies(toNav) {
    //console.log('toNav',toNav);

    if (toNav == "login") {
      if (this.us.superNew == false) {
        this.authState.logout();

      } else {
        this.us.superNew = false;
      }
    } else {
      if (toNav == "tabs") {
        this.router.navigate(['tabs/home'], { replaceUrl: true });

      } else {
        this.router.navigate(['/' + toNav + ''], { replaceUrl: true });

      }

    }

  }



  logout() {
    this.logOutStatus = true;


    this.shopS.changeButtonState(-1);
    console.log('logout');
    this.authState.logout();


    // this.shopS.shopData="";
    // this.shopS.shopData={};


    this.MenuController.close(); this.shopS.menuTogglle('close')


    // if (this.authState.BARBERliveDataFlow) {
    //   this.authState.BARBERliveDataFlow.unsubscribe();
    // }
    this.shopS.shopData.Setting.BarberID = "";
    if (this.userData) {
      this.userData.unsubscribe();

    }
    if (this.shopSub) {
      this.shopSub.unsubscribe();

    }
    localStorage.clear();


  }

  goToNextPage() {
    this.MenuController.close(); this.shopS.menuTogglle('close')
    // this.menuClosed();
    // this.shopS.changeButtonState(0);
    setTimeout(() => {
      this.shopS.userOrder.stage = 0;

      this.orderButtonComponent.goToAbout()
    }, 500);


  }
  menuClosed() {
    //  console.log("------- close ----------- 4444444");
    if (this.shopS.disableBtn) {

    } else {
      if (this.logOutStatus == false) {
        this.shopS.changeButtonState(0);
      } else {
        this.logOutStatus = false;
      }

    }

  }
  menuOpen() {
    // console.log("------- open ----------- 3333333");
    this.shopS.changeButtonState(-1);

  }

  goToPayments() {
    this.router.navigate(['payments'], { replaceUrl: true });
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }


  goToStatistics() {

    if (this.shopS.shopData.Setting.Premium.Statistics == "premium") {
      this.router.navigate(['statistics'], { replaceUrl: true });
      this.MenuController.close(); this.shopS.menuTogglle('close')

    } else {
      this.premiumService.PremiumLock("go");
    }


  }


  goToInvoice() {
    this.router.navigate(['invoice'], { replaceUrl: true });
    this.MenuController.close(); this.shopS.menuTogglle('close')

  }

  goToBuis() {
    this.router.navigate(['Business'], { replaceUrl: true });
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }
  goToBuisA() {

    this.router.navigate(['tabsAdmin/business'], { replaceUrl: true });
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }

  homees() {
    this.router.navigate(['tabs/home'], { replaceUrl: true });
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }
  // openChangeUserModal() {

  //   this.MenuController.close();      this.shopS.menuTogglle('close')
  // }
  // changeBuiss() {

  //   // this.nav.navigateRoot('addBusiness');
  //   this.us.superNew = true;
  //   this.router.navigate(['addBusiness'], { replaceUrl: true });
  //   this.shopS.changeButtonState(-1);
  //   this.MenuController.close(); this.shopS.menuTogglle('close')
  //   setTimeout(() => {
  //     this.shopS.changeButtonState(-1);
  //   }, 1000);
  // }

  changeEnv() {
    let platform = this.shopS.environment.platform == 'web' ? 'app' : 'web';
    localStorage.setItem('app_env', platform);
    this.shopS.environment.platform = platform;
  }

  async openChangeUserModal() {
    console.log(this.shopS.platform);

    const modal = await this.modalController.create({
      component: ChangeUserModal,
      mode: 'ios',
      cssClass: this.shopS.platform == 'desktop' ? 'desktopModalFromBottom' : 'my-custom-modal-css',
    });
    this.MenuController.close(); this.shopS.menuTogglle('close')
    return await modal.present();
  }

  dismissChageUserModal() {
    this.modalController.dismiss();
  }
  changePage(x) {
    this.nav.navigateForward(this.pages[x].component);
  }
  ngOnDestroy() {
    if (this.userData) {
      this.userData.unsubscribe();

    }
    if (this.shopSub) {
      this.shopSub.unsubscribe();

    }
  }

  checkCodeUndifined(codeVisible) {
    //  console.log('codeVisible',codeVisible);
    return false;
  }

  toggleModal() {
    // this.shopS.confettiFLAG = !this.shopS.confettiFLAG;
    this.shopS.turnOnConfetti(2500);
    this.shopS.alertModal = false;
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }





  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit('4b5b5a65-0dea-4565-bd02-54bb3673a8ef', '474725772867');
    // this.oneSignal.setLogLevel({logLevel: 6, visualLevel: 6});
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
    console.log(this.oneSignal);

    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      this.showAlert(title, msg, additionalData.button);
    });

    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!

      // let additionalData = data.notification.payload.additionalData;

      // this.showAlert('Notification opened', 'You already read this before', additionalData.button);
    });
    //    alert(JSON.stringify(this.oneSignal));
    //NONE, FATAL, ERROR, WARN, INFO, DEBUG, VERBOSE
    this.oneSignal.endInit();

  }

  async showAlert(title, msg, button) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `סגור`,
          handler: () => {
            // E.g: Navigate to a specific screen
          }
        }
      ]
    })
    alert.present();
  }

  async showAlertNoInternet(title, msg, button) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `סגור`,
          handler: () => {
            // E.g: Navigate to a specific screen
          }
        }
      ]
    })
    alert.present();
  }


  public innerWidth: any;

  routerSub() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        console.log('event', event.url)
        this.shopS.previousUrl = this.shopS.currentUrl;
        this.shopS.currentUrl = event.url;
        if (event.url == '/tabs') {
          this.router.navigate(['/tabs/home'], { replaceUrl: true })
        }
        console.log('PREV - ', this.shopS.previousUrl);
        console.log('CURRENT - ', this.shopS.currentUrl);

      }
    });
  }

  // desktop_mode() {
  //   //if((this.shopS.platform == 'desktop' ) && (window.innerWidth > 700)){


  //   this.router.events.subscribe((event: Event) => {

  //     if (event instanceof NavigationEnd) {
  //       // Hide loading indicator
  //       console.log('event', event.url)

  //       this.shopS.currentUrl = event.url;
  //       if (event.url == '/tabs') {
  //         this.router.navigate(['/tabs/home'], { replaceUrl: true })

  //       }

  //       // if ((event.url == '/socialLogin') || (event.url == '/login') || (event.url == '/signUp')) {
  //       //   this.shopS.desktop_menu = false;

  //       // } else {
  //       //   this.shopS.desktop_menu = true;
  //       // }


  //     }

  //   })




  //   //this.MenuController.open();



  //   //}// end of if desktop
  // }// end of func





  toggleModalSetting(event) {
    event.stopPropagation();
    this.router.navigate(['tabsAdmin/business'], { replaceUrl: true });



    // this.shopS.calendarSettingFLAG = !this.shopS.calendarSettingFLAG;
    // console.log('toggleModal=======this.shopS.calendarSettingFLAG',this.shopS.calendarSettingFLAG);
    this.MenuController.close(); this.shopS.menuTogglle('close')
    setTimeout(() => {
      this.shopS.AdminEditMode = false;

    }, 500);

  }

  toggleModalShare(event) {
    event.stopPropagation();
    this.shopS.SharemodalFLAG = !this.shopS.SharemodalFLAG;
    // console.log('toggleModal=======this.shopS.SharemodalFLAG',this.shopS.SharemodalFLAG);
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }

  // toggleExplainer(event) {
  //   event.stopPropagation();
  //   this.shopS.explainerFLAF = !this.shopS.explainerFLAF;
  //   // console.log('toggleModal=======this.shopS.SharemodalFLAG',this.shopS.SharemodalFLAG);
  //   this.MenuController.close(); this.shopS.menuTogglle('close')
  // }



  toggleModalUserShareToBiz(event) {
    event.stopPropagation();
    this.shopS.UserShareToBizFLAG = !this.shopS.UserShareToBizFLAG;
    // console.log('toggleModal=======this.shopS.UserShareToBizFLAG',this.shopS.UserShareToBizFLAG);
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }


  toggleModalContact(event) {
    event.stopPropagation();
    this.shopS.contactUS = !this.shopS.contactUS;
    console.log('toggleModal=======this.shopS.contactUS', this.shopS.contactUS);
    this.MenuController.close(); this.shopS.menuTogglle('close')
  }


  clearSeletion() {
    // console.log(this.shopS.shopData.MeetingType);


    for (var key in this.shopS.shopData.MeetingType) {
      if (this.shopS.shopData.MeetingType.hasOwnProperty(key)) {
        // console.log(key + " -> " + this.shopS.shopData.MeetingType[key]);
        this.shopS.shopData.MeetingType[key].active = false;
      }
    }


    for (var val of this.shopS.shopData.MeetingType) {
      val.active = false;
      // console.log(val); // prints values: 10, 20, 30, 40
    }
  }

  downloadApp() {
    this.showWebAlert = false;
    this.shopS.downloadApp();
  }


}