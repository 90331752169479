import { Component, OnInit,ViewChild } from '@angular/core';
import { PaymentService } from "./payment-service/payment.service";
import { AlertExample } from './../../providers/alert.service';
import { SuperTabsContainer } from '@ionic-super-tabs/angular';

import { PaymenyHistoryPage } from "./paymeny-history/paymeny-history.page";
import { ControlPosition } from '@agm/core';
import { PremiumService } from '../premium/premium-service/premium.service';
import { ShopService } from 'src/ShopService/shop.service';
@Component({
  selector: 'app-payments',
  templateUrl: './payments.page.html',
  styleUrls: ['./payments.page.scss'],
})
export class PaymentsPage implements OnInit {
 // @ViewChild(SuperTabsContainer,{static:false}) SuperTabsContainer: SuperTabsContainer;

  constructor(
    public PaymentService:PaymentService,
    private AlertExample:AlertExample,
    public premiumService:PremiumService,
    public shopS: ShopService
  ) { }

  tabIndex = 1;
  onTabChange(event){
    console.log(event);
    if(event.detail.changed){
      this.tabIndex = event.detail.index;
      console.log('this.tabIndex',this.tabIndex);

    }
  }

  History = PaymenyHistoryPage;

  config  = {
    sideMenu: 'right',
    dragThreshold: 150,
    shortSwipeDuration: 180,
    allowElementScroll: false,
    scrollable: true,

  };

  changeVal(event){
    console.log('event',event.detail.value)
  }

  toggleAccordion(thatSub){
    console.log('thatSub:',thatSub)
    thatSub['flip'] = !thatSub['flip'];
   // this.PaymentService.mySubscriptionArray[key]
  }




  open_card_options(card){
    console.log(card);
    this.PaymentService.CardEditModal(card);
   
  }

  


  async presentAlertConfirmSubscription(sub) {
let key = sub.key;
    console.log(key)

    console.log(sub)

    const alert = await this.AlertExample.alertController.create({
      header: 'בטוח שברצונך לבטל?',
      message: 'המנוי שלך יסתיים בתאריך '+  this.PaymentService.shopS.convertDateDOTS(sub.sub_next_date)  +', החיוב הבא יבוטל',
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'כן ביטול מנוי!',
          
          handler: () => {
            this.PaymentService.disableSubscription(sub);

          }
        }
      ]
    });
    await alert.present();


}










check_if_date_in_the_past(date)
{
  if(date > new Date()){
    return true;
  }else{
    return false;
  }
}



async presentAlertConfirmSubscriptionDelete(subscription) {

  console.log(subscription)

  let sub_next = new Date(subscription.sub_next_date);
  // let sub_next = new Date();
  let today = new Date();
  if(sub_next > today ){
    console.log('sdasdasd');
    const alert = await this.AlertExample.alertController.create({
      header: 'מחיקת המנוי',
      message: 'ניתן למחוק את המנוי רק לאחר שיסתיים',
      buttons: [
        {
          text: 'הבנתי',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });
    await alert.present();
  


  }else{

  


  const alert = await this.AlertExample.alertController.create({
    header: 'מחיקת המנוי',
    message: 'בטוחים שתרצו למחוק את המנוי ?',
    buttons: [
      {
        text: 'לא',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {
          console.log('Confirm Cancel: blah');
        }
      }, {
        text: 'כן מחקו לי את המנוי',
        
        handler: () => {
          
          this.PaymentService.deleteSubscription(subscription);

        }
      }
    ]
  });
  await alert.present();


}// else end

}




 
async presentAlertConfirmSubscriptionRenew(subscription) {

  console.log(subscription)
 

  const alert = await this.AlertExample.alertController.create({
    header: 'חידוש ממנוי',
    message: 'בטוחים שתרצו לחדש את המנוי ?',
    buttons: [
      {
        text: 'לא',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {
          console.log('Confirm Cancel: blah');
        }
      }, {
        text: 'כן חדש מנוי!',
        
        handler: () => {
          if(new Date(subscription.nextPayment) < new Date()){
            console.log('true');
            this.PaymentService.pay_now_product('056538ed-563b-46d1-b001-a90e69b50f4b',this.PaymentService.premium_product_key)
        
          }else{
            console.log('false');
            this.PaymentService.changeCardSubscriptionModal(subscription);

            //this.PaymentService.enableSubscription(subscription.key);

          }

        }
      }
    ]
  });
  await alert.present();


}













     async presentAlertConfirm(card) {

      console.log(card)

         
      let isCardUseForSubscribe = this.PaymentService.mySubscriptionArray.find(o => o.ccard.key === card.key);
console.log('isCardUseForSubscribe',isCardUseForSubscribe)
      if(isCardUseForSubscribe){
        console.log('exist');
        const alert = await this.AlertExample.alertController.create({
          header: 'כרטיס משויך למנוי',
          message: 'לא ניתן למחוק כרטיס שמשויך למנוי פעיל',
          buttons: [
            {
              text: 'הבנתי',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel: blah');
              }
            }
          ]
        });
        await alert.present();




      }else{
        console.log('not exist');

      const alert = await this.AlertExample.alertController.create({
        header: 'מחיקת כרטיס',
        message: 'בטוחים שתרצו למחוק ?',
        buttons: [
          {
            text: 'לא',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
            }
          }, {
            text: 'כן',
            
            handler: () => {
              this.PaymentService.deleteCard(card);
  
            }
          }
        ]
      });
      await alert.present();
}



  }

  ngOnInit(){
    
  }
  ionViewDidEnter() {
 //   this.SuperTabsContainer.enableTabsSwipe(false);
//this.SuperTabsContainer.swipeEnabled(false)
    this.PaymentService.getUserCards();

    let user = this.PaymentService.userService.getUserObj();
    let sub ={
      type: 'userid',
      data: user.key
    };
    this.PaymentService.user = user;
    // this.PaymentService.getSubscription(sub);

    let new_sub ={
      type: 'user_id',
      data: user.key
    };
 

    this.PaymentService.get_transactions(new_sub);
 
  }


  async changeDefault(key){
    const alert = await this.AlertExample.alertController.create({
      header: 'הגדרת כרטיס',
      message: 'בטוחים שתרצו להגדיר כרטיס זה כברירת מחדל ?',
      buttons: [
        {
          text: 'לא',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'כן שנה הגדרת כרטיס!',
          
          handler: () => {
            this.PaymentService.changeDefault(key);
          }
        }
      ]
    });
    await alert.present();

  }
  


  ngOnDestroy() {
    this.PaymentService.UserCardsSubscribe.unsubscribe();
    // this.PaymentService.UserHistorySubscribe.unsubscribe();
  }


}
