import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { ShopService } from 'src/ShopService/shop.service';

@Component({
  selector: 'app-welcome-screen',
  templateUrl: './welcome-screen.component.html',
  styleUrls: ['./welcome-screen.component.scss'],
})
export class WelcomeScreenComponent implements OnInit {
  @ViewChild('theSlides', { static: true }) theSlides: IonSlides

  slideOpts = {
    mode: "ios",
    initialSlide: 0,
    speed: 500,
    // loop: true,
  };
  constructor(
    public shopS: ShopService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }
  step = 1;
  laptop_start_animation = true;

  ngOnInit() {
    this.shopS.loaderoff = false;
  }

  laptop_start_animation_interval = null;

  animation_page(i) {
    switch (i) {
      case 1:
        let speed = 7500;
        if (!this.laptop_start_animation_interval) {
          this.laptop_start_animation_interval = setInterval(() => {
            if (this.step == i) {
              this.laptop_start_animation = !this.laptop_start_animation;
            }
          }, speed);
        }
        break;

      default:
        break;
    }

  }
  slider_change(type, event, element) {
    // console.log(type,event,element);
    let realIndex = element.el.swiper.realIndex;
    console.log('realIndex:', element.el.swiper.realIndex);
    this.step = realIndex;

    if (realIndex == 1) {
      this.animation_page(realIndex)
      this.laptop_start_animation = false;
    } else {
      this.laptop_start_animation = true;
    }
    this.changeDetectorRef.detectChanges();

    // this.theSlides.getActiveIndex().then(slide =>{
    //   console.log(slide);
    //   this.step = slide

    // })
  }
  step_change(num) {

    this.step = num;
  }

  closed = false;
  fadeOut = {
    screen: false,
    slider: false,
  }

  closeWelcome() {
    // this.closed = true;
    this.closed = !this.closed;
    console.log("Closed");
    this.changeDetectorRef.detectChanges();
    // setTimeout(() => {
    this.fadeOut.slider = true;
    this.fadeOut.screen = true;
    this.changeDetectorRef.detectChanges();

    setTimeout(() => {
      // this.shopS.dismissModalController();
      localStorage.setItem('welcome_screen', 'true');
      this.shopS.welcome_screen.next('true');

    }, 700);


    // }, 00);



  }

}
